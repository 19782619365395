export var getOption = function (data, isShowCount) {
    if (isShowCount === void 0) { isShowCount = true; }
    return {
        tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}单 ({d}%)'
        },
        color: ['#4ECCCB', '#48A1FF', '#58CC74', '#FBD44B', '#F4657C', '#975FE5'],
        series: [
            {
                type: 'pie',
                radius: ['40%', '60%'],
                itemStyle: {
                    borderRadius: 0,
                    borderColor: '#fff',
                    borderWidth: 5
                },
                label: {
                    show: true,
                    position: 'left',
                    formatter: "{b}: {d}%" + (isShowCount ? ',{c}单' : '')
                },
                // emphasis: {
                //   label: {
                //     show: true,
                //     fontSize: '40',
                //     fontWeight: 'bold'
                //   }
                // },
                labelLine: {
                    show: true
                },
                data: data
            }
        ]
    };
};
export var getProPortionData = function (data) {
    return data.map(function (item) {
        return {
            name: item.platform,
            value: item.counts
        };
    });
};
