import React, { useContext, useRef, useEffect } from 'react';
import 'echarts/dist/extension/dataTool';
import 'echarts/map/js/china';
import 'echarts/map/js/world';
import 'echarts/dist/extension/bmap';
import ReactECharts from 'echarts-for-react';
import { HeapMapWrapper } from './styled';
import { Wrapper, Title, ConditionFilter } from '../styled';
import IconHeapMapUrl from '@assets/images/icon-location.png';
import ReportContext from '../../context';
import { ReportModule } from '../../types/emnu';
import StoreSelect from '../common/StoreSelect';
import CitySelect from '../common/CitySelect';
import OrderPlatformSelect from '../common/OrderPlatformSelect';
import DateFilter from '../common/DateFilter';
import { getOption } from './helper';
var OrderHeatMap = function (props) {
    var _a = useContext(ReportContext), changeStore = _a.changeStore, changeOrderPlatform = _a.changeOrderPlatform, changeDate = _a.changeDate;
    var bmapRef = useRef({});
    var echartsRef = useRef();
    var handleStoreChange = function (storeId) {
        changeStore(ReportModule.OrderHeapMap, storeId);
    };
    var handleOrderPlatformChange = function (platformId) {
        changeOrderPlatform(ReportModule.OrderHeapMap, platformId);
    };
    var handleDateChange = function (dateRange) {
        changeDate(ReportModule.OrderHeapMap, dateRange);
    };
    var getCityName = function () {
        var city = props.cities.find(function (city) { return city.code === props.cityId; });
        return city.name;
    };
    useEffect(function () {
        if (props.cities.length && props.cityId) {
            bmapRef.current.centerAndZoom(getCityName());
        }
    }, [props.cities, props.cityId]);
    useEffect(function () {
        var echartsInstance = echartsRef.current.getEchartsInstance();
        var bmap = echartsInstance
            .getModel()
            .getComponent('bmap')
            .getBMap();
        // bmap.addControl(new window.BMap.MapTypeControl())
        bmap.addControl(new window.BMap.NavigationControl());
        bmapRef.current = bmap;
        bmap.setMinZoom(12);
        bmap.setMaxZoom(18);
        // bmap.addEventListener('zoomend', () => {})
        // bmap.addEventListener('dragend', () => {})
    }, []);
    return (React.createElement(Wrapper, null,
        React.createElement(ConditionFilter, null,
            React.createElement(CitySelect, { data: props.cities, value: props.cityId, onChange: function (data) { return props.onCityChange(data); } }),
            React.createElement(StoreSelect, { value: props.storeId, data: props.stores, onChange: handleStoreChange }),
            React.createElement(OrderPlatformSelect, { value: props.platformId, data: props.orderPlatforms, onChange: handleOrderPlatformChange }),
            React.createElement(DateFilter, { name: "orderHeapMap", onChange: handleDateChange })),
        React.createElement(HeapMapWrapper, null,
            React.createElement(Title, { icon: IconHeapMapUrl }, "\u8BA2\u5355\u5730\u7406\u5206\u5E03\u56FE\uFF08\u6839\u636E\u6240\u6709\u8BA2\u5355\u7684\u6536\u8D27\u4EBA\u5730\u5740\u7EDF\u8BA1\uFF09\u00A0"),
            React.createElement(ReactECharts, { ref: echartsRef, style: {
                    margin: '20px 0',
                    height: '640px'
                }, option: getOption(props.data) }))));
};
export default React.memo(OrderHeatMap);
