import { ORDER_STATUS_MAP, ORDER_SUB_STATUS_MAP } from '../constants';
import { EVENTS } from '../event';
export var emitOrderChange = function (_a) {
    var tabStatus = _a.tabStatus, orderEvent = _a.orderEvent, orderId = _a.orderId, nextStatus = _a.nextStatus, _b = _a.otherParams, otherParams = _b === void 0 ? {} : _b;
    switch (tabStatus) {
        case ORDER_STATUS_MAP.TO_BE_CONFIRMED:
        case ORDER_STATUS_MAP.TO_BE_SHIPPING:
        case ORDER_STATUS_MAP.WAIT_GRAB:
        case ORDER_STATUS_MAP.IN_THE_DISTRIBUTION:
        case ORDER_SUB_STATUS_MAP.CANCELED_APPLY:
        case ORDER_SUB_STATUS_MAP.REFUND_APPLY:
        case ORDER_SUB_STATUS_MAP.URGE_APPLY:
            orderEvent.emit(EVENTS.DELETE_ORDERS_ITEM, { orderId: orderId, otherParams: otherParams });
            break;
        case ORDER_STATUS_MAP.ALL:
            orderEvent.emit(EVENTS.UPDATE_ORDER_STATUS, {
                orderId: orderId,
                status: nextStatus,
                otherParams: otherParams
            });
            break;
        default:
            break;
    }
};
