export var deliverReceiptMockData = {
    cancelOrder: {
        status: 0
    },
    originalPrice: '0.05',
    reservationOrder: 1,
    totalPrice: '66.88',
    description: ' ',
    goods: [
        { total: '0.02', quantity: 2, price: '0.01', name: '香蕉' },
        {
            total: '0.02',
            quantity: 2,
            price: '0.01',
            name: '苹果苹果苹果苹果苹果苹果'
        }
    ],
    orderStatus: 0,
    platform: 'meituan',
    orderTime: 1572334199000,
    reducedPrice: '0',
    consigneePhone: '["15222009637"]',
    storeCoordinate: '116.289166,40.054767',
    storeName: '从门店-3',
    id: '637',
    deliverFee: '0.01',
    consigneeAddress: '色金拉 (昌都大鹅茶餐厅)@#西藏自治区林芝市墨脱县色金拉',
    packageFee: '0.02',
    deliverTime: 0,
    isBigAmount: 0,
    consignee: 'Eric(先生)',
    printNum: 0,
    receiveCoordinate: '116.289166,40.054767',
    platformDaySn: 6,
    platformId: '32865950879878056',
    sort: '餐饮',
    storeId: '110',
    retreatOrder: { status: 0 },
    isAutoOrder: 0,
    daySn: 15,
    status: 0,
    invoiceTitle: '',
    invoiceId: '',
    meituanCode: '121231'
};
