// const frefix = '-stable-26114304'
var frefix = '';
//global-config 全局修改环境：新接口 老接口 ws
export var envIsUse = 'test';
export var env;
if (process.env.NODE_ENV === 'production') {
    if (window.location.href.includes('dev')) {
        env = 'development';
    }
    else if (window.location.href.includes('.test')) {
        env = 'newtest';
    }
    else if (window.location.href.includes('test')) {
        env = 'test';
    }
    else {
        // env = 'production'
        env = 'production';
    }
}
else {
    // env = 'local'
    env = envIsUse;
}
export function getProductionUrl(domain) {
    return function () {
        if (env === 'development') {
            return "https://" + domain + "-dev.danyisong.com";
        }
        else if (env === 'test') {
            if (window.location.href.includes('.test')) {
                return "https://" + domain + frefix + ".test.danyisong.com";
            }
            else {
                return "https://" + domain + "-test.danyisong.com";
            }
        }
        else if (env === 'newtest') {
            return "https://" + domain + "-b.test.danyisong.com";
        }
        else {
            return "https://" + domain + ".iyisong.net";
        }
    };
}
function getOldServiceUrl() {
    if (env === 'development') {
        return "https://easyex-dev.danyisong.com";
    }
    else if (env === 'test') {
        if (window.location.href.includes('.test')) {
            return "https://easyex" + frefix + ".test.danyisong.com";
        }
        else {
            return "https://easyex-test.danyisong.com";
        }
    }
    else if (env === 'newtest') {
        return "https://easyex-b.test.danyisong.com";
    }
    else {
        return "https://www.iyisong.net";
    }
}
export var config = {
    merchant: {
        // local: 'http://172.29.1.51:9002',
        // local: 'http://172.29.5.135:9002',
        local: '',
        development: getProductionUrl('merchant')(),
        test: getProductionUrl('merchant')(),
        newtest: getProductionUrl('merchant')(),
        production: getProductionUrl('merchant')()
    },
    delivery: {
        local: '',
        // local: 'http://172.29.2.113:9001',
        development: getProductionUrl('express')(),
        test: getProductionUrl('express')(),
        newtest: getProductionUrl('express')(),
        production: getProductionUrl('express')()
    },
    order: {
        local: '',
        // local: 'http://172.29.2.113:8080',
        development: getOldServiceUrl(),
        test: getOldServiceUrl(),
        newtest: getOldServiceUrl(),
        production: getOldServiceUrl()
    },
    upload: {
        // local: 'http://172.29.5.135:9003',
        local: '',
        development: getProductionUrl('file')(),
        test: getProductionUrl('file')(),
        newtest: getProductionUrl('file')(),
        production: getProductionUrl('file')()
    },
    oldService: {
        local: '',
        development: getOldServiceUrl(),
        test: getOldServiceUrl(),
        newtest: getOldServiceUrl(),
        production: getOldServiceUrl()
    },
    coupon: {
        local: '',
        development: getProductionUrl('coupon')(),
        test: getProductionUrl('coupon')(),
        newtest: getProductionUrl('coupon')(),
        production: getProductionUrl('coupon')()
    },
    money: {
        local: '',
        development: getProductionUrl('payment')(),
        test: getProductionUrl('payment')(),
        newtest: getProductionUrl('payment')(),
        production: getProductionUrl('payment')()
    }
};
export var getBaseUrl = function (service) {
    if (config[service]) {
        return config[service][env];
    }
    return '';
};
