var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import DownloadBgImageUrl from '@assets/images/bg-download.png';
import IconDesktopUrl from '@assets/images/icon-desktop.png';
import IconNextActive from '@assets/images/icon-next-active.png';
import IconNext from '@assets/images/icon-next.png';
import IconPrevActive from '@assets/images/icon-prev-active.png';
import IconPrev from '@assets/images/icon-prev.png';
import LogoUrl from '@assets/images/logo.png';
import AndriodCode from '@assets/images/andriod.png';
import DevQRImage from '@assets/images/dev-qr.png';
import TestQRImage from '@assets/images/test-qr.png';
import ProdQRImage from '@assets/images/prod-qr.png';
import CustomServiceImage from '@assets/images/custom-service.png';
import KfQrImage from '@assets/images/kf-qrcode.png';
export var Header = styled.div.withConfig({ displayName: "Header", componentId: "sc-s5g44h" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  z-index: 10;\n  box-sizing: border-box;\n  position: sticky;\n  top: 0;\n  display: flex;\n  align-items: center;\n  padding: 17px 10% 16px;\n  width: 100%;\n  background: #fff;\n"], ["\n  z-index: 10;\n  box-sizing: border-box;\n  position: sticky;\n  top: 0;\n  display: flex;\n  align-items: center;\n  padding: 17px 10% 16px;\n  width: 100%;\n  background: #fff;\n"])));
export var Menu = styled.ul.withConfig({ displayName: "Menu", componentId: "sc-a9fdt6" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  margin: 0;\n  list-style: none;\n"], ["\n  display: flex;\n  margin: 0;\n  list-style: none;\n"])));
export var MenuItem = styled.li.withConfig({ displayName: "MenuItem", componentId: "sc-1smnuh3" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 0 40px;\n  font-size: 16px;\n  a {\n    color: #333;\n    &:hover {\n      color: #01cccc;\n    }\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 0 40px;\n  font-size: 16px;\n  a {\n    color: #333;\n    &:hover {\n      color: #01cccc;\n    }\n  }\n"])));
export var Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-1qbp87v" })(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
export var ProduceName = styled.div.withConfig({ displayName: "ProduceName", componentId: "sc-1uihwsg" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-right: 15%;\n  padding-left: 54px;\n  height: 48px;\n  font-size: 20px;\n  line-height: 48px;\n  color: #01cccc;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-size: 48px;\n  background-position: left;\n"], ["\n  margin-right: 15%;\n  padding-left: 54px;\n  height: 48px;\n  font-size: 20px;\n  line-height: 48px;\n  color: #01cccc;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-size: 48px;\n  background-position: left;\n"])), LogoUrl);
export var WelcomeText = styled.h2.withConfig({ displayName: "WelcomeText", componentId: "sc-1s1ywby" })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-bottom: 30px;\n  font-size: 40px;\n  color: #fff;\n"], ["\n  margin-bottom: 30px;\n  font-size: 40px;\n  color: #fff;\n"])));
export var DownloadContent = styled.div.withConfig({ displayName: "DownloadContent", componentId: "sc-1r3ninp" })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  box-sizing: border-box;\n  padding: 120px 0 0 54%;\n  height: 720px;\n  color: #fff;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n"], ["\n  box-sizing: border-box;\n  padding: 120px 0 0 54%;\n  height: 720px;\n  color: #fff;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n"])), DownloadBgImageUrl);
export var IntroduceContent = styled.div.withConfig({ displayName: "IntroduceContent", componentId: "sc-1j22cbu" })(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 0 14%;\n  font-size: 20px;\n  color: #333;\n  text-align: center;\n"], ["\n  padding: 0 14%;\n  font-size: 20px;\n  color: #333;\n  text-align: center;\n"])));
export var Title = styled.h3.withConfig({ displayName: "Title", componentId: "sc-3wm12p" })(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin: 60px 0 28px;\n  color: #333;\n  font-size: 40px;\n  text-align: center;\n"], ["\n  margin: 60px 0 28px;\n  color: #333;\n  font-size: 40px;\n  text-align: center;\n"])));
export var Content = styled.p.withConfig({ displayName: "Content", componentId: "sc-303gxa" })(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin: 0;\n  color: #333;\n  font-size: 20px;\n  line-height: 30px;\n  text-align: left;\n"], ["\n  margin: 0;\n  color: #333;\n  font-size: 20px;\n  line-height: 30px;\n  text-align: left;\n"])));
export var Text = styled.p.withConfig({ displayName: "Text", componentId: "sc-oktnnd" })(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-bottom: 20px;\n  font-size: 20px;\n  line-height: 26px;\n  margin-right: 70px;\n"], ["\n  margin-bottom: 20px;\n  font-size: 20px;\n  line-height: 26px;\n  margin-right: 70px;\n"])));
export var ApplyButton = styled.a.withConfig({ displayName: "ApplyButton", componentId: "sc-2foyjt" })(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: inline-block;\n  margin: 50px 0 60px;\n  width: 216px;\n  height: 58px;\n  border: 1px solid #01cccc;\n  border-radius: 29px;\n  font-size: 20px;\n  color: #01cccc;\n  line-height: 58px;\n  &:hover {\n    color: #01cccc;\n  }\n"], ["\n  display: inline-block;\n  margin: 50px 0 60px;\n  width: 216px;\n  height: 58px;\n  border: 1px solid #01cccc;\n  border-radius: 29px;\n  font-size: 20px;\n  color: #01cccc;\n  line-height: 58px;\n  &:hover {\n    color: #01cccc;\n  }\n"])));
export var CarouseWrapper = styled.div.withConfig({ displayName: "CarouseWrapper", componentId: "sc-109uszn" })(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var CarousePrev = styled.span.withConfig({ displayName: "CarousePrev", componentId: "sc-1ud6u1j" })(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  z-index: 2;\n  position: absolute;\n  top: 306px;\n  left: 10%;\n  width: 68px;\n  height: 68px;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  cursor: pointer;\n  &:hover {\n    background-image: url(", ");\n  }\n"], ["\n  z-index: 2;\n  position: absolute;\n  top: 306px;\n  left: 10%;\n  width: 68px;\n  height: 68px;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  cursor: pointer;\n  &:hover {\n    background-image: url(", ");\n  }\n"])), IconPrev, IconPrevActive);
export var CarouseNext = styled.span.withConfig({ displayName: "CarouseNext", componentId: "sc-1pddbpw" })(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  z-index: 2;\n  position: absolute;\n  top: 306px;\n  right: 10%;\n  width: 68px;\n  height: 68px;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  cursor: pointer;\n  &:hover {\n    background-image: url(", ");\n  }\n"], ["\n  z-index: 2;\n  position: absolute;\n  top: 306px;\n  right: 10%;\n  width: 68px;\n  height: 68px;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  cursor: pointer;\n  &:hover {\n    background-image: url(", ");\n  }\n"])), IconNext, IconNextActive);
export var CarouselItem = styled.div.withConfig({ displayName: "CarouselItem", componentId: "sc-hzk7az" })(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  padding: 34px 0;\n  background: rgba(177, 240, 229, 0.1);\n  text-align: center;\n"], ["\n  padding: 34px 0;\n  background: rgba(177, 240, 229, 0.1);\n  text-align: center;\n"])));
export var CarouselImage = styled.img.withConfig({ displayName: "CarouselImage", componentId: "sc-1qitaxf" })(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  margin: 0 auto;\n  height: 615px;\n"], ["\n  margin: 0 auto;\n  height: 615px;\n"])));
export var ApplyFormContent = styled.div.withConfig({ displayName: "ApplyFormContent", componentId: "sc-9j6spj" })(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  padding: 0 0 60px;\n"], ["\n  padding: 0 0 60px;\n"])));
export var ApplyFormWrapper = styled.div.withConfig({ displayName: "ApplyFormWrapper", componentId: "sc-bzv0qk" })(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-top: 92px;\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-top: 92px;\n"])));
export var ApplyForm = styled.div.withConfig({ displayName: "ApplyForm", componentId: "sc-zs180x" })(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  margin-top: 42px;\n"], ["\n  margin-top: 42px;\n"])));
export var ApplyFormTitle = styled.h4.withConfig({ displayName: "ApplyFormTitle", componentId: "sc-v10pu5" })(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  margin: 0;\n  font-size: 20px;\n  color: #333;\n"], ["\n  margin: 0;\n  font-size: 20px;\n  color: #333;\n"])));
export var ApplyImage = styled.img.withConfig({ displayName: "ApplyImage", componentId: "sc-2il6i" })(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  margin-right: 66px;\n  height: 383px;\n"], ["\n  margin-right: 66px;\n  height: 383px;\n"])));
export var FormField = styled.div.withConfig({ displayName: "FormField", componentId: "sc-1vylmi7" })(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n"], ["\n  display: flex;\n  flex-direction: column;\n"])));
export var FormLabel = styled.label.withConfig({ displayName: "FormLabel", componentId: "sc-11t1grt" })(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  margin-bottom: 5px;\n  font-size: 18px;\n  color: #2e3c47;\n"], ["\n  margin-bottom: 5px;\n  font-size: 18px;\n  color: #2e3c47;\n"])));
export var FormInput = styled.input.withConfig({ displayName: "FormInput", componentId: "sc-yi2d3q" })(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  box-sizing: border-box;\n  padding: 15px 0;\n  width: 290px;\n  border: none;\n  border-bottom: 1px solid #e1e1e1;\n  outline: none;\n"], ["\n  box-sizing: border-box;\n  padding: 15px 0;\n  width: 290px;\n  border: none;\n  border-bottom: 1px solid #e1e1e1;\n  outline: none;\n"])));
export var SMSInputWrapper = styled.div.withConfig({ displayName: "SMSInputWrapper", componentId: "sc-1etsql5" })(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var SMSPostButton = styled.a.withConfig({ displayName: "SMSPostButton", componentId: "sc-wge79g" })(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n  position: absolute;\n  top: 15px;\n  right: 0;\n  font-size: 14px;\n  color: #4592ff;\n"], ["\n  position: absolute;\n  top: 15px;\n  right: 0;\n  font-size: 14px;\n  color: #4592ff;\n"])));
export var FormTextArea = styled.textarea.withConfig({ displayName: "FormTextArea", componentId: "sc-ihh2yt" })(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n  box-sizing: border-box;\n  padding: 15px 0;\n  width: 100%;\n  border: none;\n  border-bottom: 1px solid #e1e1e1;\n  outline: none;\n  resize: none;\n"], ["\n  box-sizing: border-box;\n  padding: 15px 0;\n  width: 100%;\n  border: none;\n  border-bottom: 1px solid #e1e1e1;\n  outline: none;\n  resize: none;\n"])));
export var ApplyFormSubmit = styled.button.withConfig({ displayName: "ApplyFormSubmit", componentId: "sc-136lbvm" })(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n  margin-top: 62px;\n  width: 350px;\n  height: 45px;\n  border: none;\n  background: #01cccc;\n  font-size: 15px;\n  color: #fff;\n  outline: none;\n  cursor: pointer;\n"], ["\n  margin-top: 62px;\n  width: 350px;\n  height: 45px;\n  border: none;\n  background: #01cccc;\n  font-size: 15px;\n  color: #fff;\n  outline: none;\n  cursor: pointer;\n"])));
export var ButtonGroup = styled.div.withConfig({ displayName: "ButtonGroup", componentId: "sc-1nya4d1" })(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  display: flex;\n  .andriod {\n    background: url(", ") no-repeat;\n    width: 150px;\n    height: 150px;\n    background-size: 100% 100%;\n    margin-top: 10px;\n  }\n  .btn {\n    display: flex;\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  .andriod {\n    background: url(", ") no-repeat;\n    width: 150px;\n    height: 150px;\n    background-size: 100% 100%;\n    margin-top: 10px;\n  }\n  .btn {\n    display: flex;\n    flex-direction: column;\n  }\n"])), AndriodCode);
export var DownloadButton = styled.button.withConfig({ displayName: "DownloadButton", componentId: "sc-nnkswy" })(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  margin-top: 18px;\n  margin-right: 20px;\n  width: 216px;\n  height: 48px;\n  border: none;\n  outline: none;\n  background-color: #dcc250;\n  background-repeat: no-repeat;\n  background-position: 41px center;\n  background-size: 24px 22px;\n  background-image: url(", ");\n  font-size: 20px;\n  color: #fff;\n  line-height: 48px;\n  text-align: left;\n  text-indent: 72px;\n  cursor: pointer;\n"], ["\n  margin-top: 18px;\n  margin-right: 20px;\n  width: 216px;\n  height: 48px;\n  border: none;\n  outline: none;\n  background-color: #dcc250;\n  background-repeat: no-repeat;\n  background-position: 41px center;\n  background-size: 24px 22px;\n  background-image: url(", ");\n  font-size: 20px;\n  color: #fff;\n  line-height: 48px;\n  text-align: left;\n  text-indent: 72px;\n  cursor: pointer;\n"])), IconDesktopUrl);
export var CodeButton = styled.button.withConfig({ displayName: "CodeButton", componentId: "sc-13z29k9" })(templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n  margin-top: 18px;\n  margin-right: 20px;\n  width: 150px;\n  height: 48px;\n  border: none;\n  outline: none;\n  background-color: #dcc250;\n  background-repeat: no-repeat;\n  background-position: 41px center;\n  background-size: 24px 22px;\n  /* background-image: url(", "); */\n  font-size: 20px;\n  color: #fff;\n  line-height: 48px;\n  text-align: center;\n  cursor: pointer;\n"], ["\n  margin-top: 18px;\n  margin-right: 20px;\n  width: 150px;\n  height: 48px;\n  border: none;\n  outline: none;\n  background-color: #dcc250;\n  background-repeat: no-repeat;\n  background-position: 41px center;\n  background-size: 24px 22px;\n  /* background-image: url(", "); */\n  font-size: 20px;\n  color: #fff;\n  line-height: 48px;\n  text-align: center;\n  cursor: pointer;\n"])), IconDesktopUrl);
export var RedirectToWebButton = styled.a.withConfig({ displayName: "RedirectToWebButton", componentId: "sc-1squq2d" })(templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n  display: inline-block;\n  margin-top: 18px;\n  width: 216px;\n  height: 48px;\n  background-color: #fff;\n  font-size: 20px;\n  color: #01cccc;\n  line-height: 48px;\n  text-align: center;\n"], ["\n  display: inline-block;\n  margin-top: 18px;\n  width: 216px;\n  height: 48px;\n  background-color: #fff;\n  font-size: 20px;\n  color: #01cccc;\n  line-height: 48px;\n  text-align: center;\n"])));
export var Footer = styled.footer.withConfig({ displayName: "Footer", componentId: "sc-196s5zn" })(templateObject_34 || (templateObject_34 = __makeTemplateObject(["\n  display: flex;\n  height: 52px;\n  justify-content: center;\n  align-items: center;\n  font-size: 12px;\n  color: #969799;\n  background-color: #272e2d;\n"], ["\n  display: flex;\n  height: 52px;\n  justify-content: center;\n  align-items: center;\n  font-size: 12px;\n  color: #969799;\n  background-color: #272e2d;\n"])));
var QRCodeMap = {
    dev: DevQRImage,
    test: TestQRImage,
    prod: ProdQRImage
};
export var QRCode = styled.span.withConfig({ displayName: "QRCode", componentId: "sc-mvpwbr" })(templateObject_35 || (templateObject_35 = __makeTemplateObject(["\n  background: ", ";\n  width: 150px;\n  height: 150px;\n  background-size: 100% 100%;\n  margin-top: 10px;\n"], ["\n  background: ", ";\n  width: 150px;\n  height: 150px;\n  background-size: 100% 100%;\n  margin-top: 10px;\n"])), function (props) { return "url(" + QRCodeMap[props.env] + ") no-repeat"; });
export var CustomService = styled.div.withConfig({ displayName: "CustomService", componentId: "sc-1n6vjju" })(templateObject_36 || (templateObject_36 = __makeTemplateObject(["\n  position: fixed;\n  top: 50%;\n  right: 0;\n  transform: translate(0, -50%);\n  width: 69.5px;\n  height: 127px;\n  background-image: url(", ");\n  background-size: contain;\n  cursor: pointer;\n  &:hover {\n    &:after {\n      content: '';\n      display: block;\n      width: 240px;\n      height: 279px;\n      background-image: url(", ");\n      background-size: contain;\n      position: absolute;\n      right: 100%;\n      top: -50%;\n    }\n  }\n"], ["\n  position: fixed;\n  top: 50%;\n  right: 0;\n  transform: translate(0, -50%);\n  width: 69.5px;\n  height: 127px;\n  background-image: url(", ");\n  background-size: contain;\n  cursor: pointer;\n  &:hover {\n    &:after {\n      content: '';\n      display: block;\n      width: 240px;\n      height: 279px;\n      background-image: url(", ");\n      background-size: contain;\n      position: absolute;\n      right: 100%;\n      top: -50%;\n    }\n  }\n"])), CustomServiceImage, KfQrImage);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33, templateObject_34, templateObject_35, templateObject_36;
