var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import './index.less';
import SwitchImg from '@assets/images/order/switch.png';
import OneMore from '@assets/images/order/oneMore.png';
import lodash from 'lodash';
import { getAuth } from '@/container/AccountManagement/component';
import { useSelector } from 'react-redux';
var OrderSwitch = function (props) {
    var _a = __read(React.useState('地图拼单'), 2), switchStatus = _a[0], setSwitchStatus = _a[1];
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return (React.createElement("div", { className: "wrapper" }, getAuth("配送处理", menu)
        &&
            React.createElement("div", { className: "order-switch", onClick: lodash.debounce(tabSwitch, 500) },
                React.createElement("img", { src: SwitchImg }),
                "\u5207\u6362\u4E3A",
                React.createElement("span", { className: "text" }, switchStatus),
                switchStatus === '地图拼单' && (React.createElement("img", { src: OneMore, className: "oneMore" })))));
    function tabSwitch() {
        var text = switchStatus === '地图拼单' ? '列表模式' : '地图拼单';
        var textStyle = switchStatus === '地图拼单' ? 'map' : 'list';
        setSwitchStatus(text);
        props.tabSwitch(textStyle);
    }
};
export default OrderSwitch;
