var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useEffect } from 'react';
import { ReceiptTplWrapper, ReturnToPrintTpl, ReceiptMain, Buttons } from './styled';
import { Button, message } from 'antd';
import ReceiptForm from './components/ReceiptForm';
import { connect } from 'react-redux';
import ajax from '@utils/ajax';
import { StoreCode, CustomCode, ReceiptSizeCode } from '../../../app/enums/codes';
import { useHistory, useParams } from 'react-router';
import ReceiptPreview from '@components/ReceiptPreview';
var ReceiptTpl = function (props) {
    var _a;
    var _b;
    var history = useHistory();
    var id = useParams().id;
    var goBack = function () {
        history.push('/stores/print/template');
    };
    var validateForm = function () {
        if (!props.receiptName) {
            message.error('单据名称不能为空');
            return false;
        }
        return true;
    };
    var handleSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var codes, datas;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateForm()) {
                        return [2 /*return*/];
                    }
                    codes = __spread([
                        props.receiptSize
                    ], props.storeData, props.basicData, props.menuData, props.feeData, props.customData);
                    datas = codes.map(function (code) {
                        var data = {};
                        switch (code) {
                            case StoreCode.Logo:
                                data.imgUrl = '';
                                break;
                            case StoreCode.ReceiptName:
                                data.text = props.receiptName;
                                break;
                            case CustomCode.Image:
                                data.imgUrl = props.customUrl;
                                break;
                            case CustomCode.Text:
                                data.text = props.customText;
                                break;
                        }
                        return { code: code, data: data };
                    });
                    if (!id) return [3 /*break*/, 2];
                    return [4 /*yield*/, ajax.post('/printer/receipt/stencil/edit', {
                            id: id,
                            datas: datas
                        })];
                case 1:
                    _a.sent();
                    message.success('修改成功');
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, ajax.post('/printer/receipt/stencil/save', {
                        datas: datas
                    })];
                case 3:
                    _a.sent();
                    message.success('添加成功');
                    _a.label = 4;
                case 4:
                    history.go(-1);
                    return [2 /*return*/];
            }
        });
    }); };
    var widthMap = (_a = {},
        _a[ReceiptSizeCode.Small] = 440,
        _a[ReceiptSizeCode.Big] = 640,
        _a);
    useEffect(function () {
        props.fetchFormData(id);
    }, []);
    return (React.createElement(ReceiptTplWrapper, null,
        React.createElement(ReturnToPrintTpl, null,
            React.createElement("a", { onClick: goBack },
                React.createElement("span", { className: "back" }, "\u2190"),
                "\u8FD4\u56DE\u6253\u5370\u6A21\u7248")),
        React.createElement(ReceiptMain, null,
            React.createElement("div", { className: "receipt-preview", style: {
                    width: widthMap[props.receiptSize] + "px"
                } },
                React.createElement(ReceiptPreview, { formFieldMap: props.formFieldMap, receiptName: props.receiptName, receiptSize: props.receiptSize, storeData: props.storeData, basicData: props.basicData, menuData: props.menuData, feeData: props.feeData, customData: props.customData, customText: props.customText, customUrl: props.customUrl })),
            React.createElement(ReceiptForm, { list: (_b = props.formData) === null || _b === void 0 ? void 0 : _b.groups, receiptSize: props.receiptSize, storeData: props.storeData, basicData: props.basicData, menuData: props.menuData, feeData: props.feeData, receiptName: props.receiptName, customText: props.customText, customUrl: props.customUrl, customData: props.customData, addToStore: props.addToStore, removeFromStore: props.removeFromStore, addToCustom: props.addToCustom, removeFromCustom: props.removeFromCustom, setStore: props.setStore, setBasic: props.setBasic, setMenu: props.setMenu, setFee: props.setFee, setReceiptSize: props.setReceiptSize, setReceiptName: props.setReceiptName, setCustomText: props.setCustomText, setCustomUrl: props.setCustomUrl })),
        React.createElement(Buttons, null,
            React.createElement(Button, { type: "primary", onClick: handleSave }, "\u4FDD\u5B58"))));
};
var mapState = function (state) {
    var _a = state.customReceipt, formData = _a.formData, formFieldMap = _a.formFieldMap, receiptSize = _a.receiptSize, store = _a.store, basic = _a.basic, menu = _a.menu, fee = _a.fee, custom = _a.custom, receiptName = _a.receiptName, customText = _a.customText, customUrl = _a.customUrl;
    return {
        formData: formData,
        formFieldMap: formFieldMap,
        receiptSize: receiptSize,
        storeData: store,
        basicData: basic,
        menuData: menu,
        feeData: fee,
        customData: custom,
        receiptName: receiptName,
        customText: customText,
        customUrl: customUrl
    };
};
var mapDispatch = function (dispatch) { return ({
    fetchFormData: dispatch.customReceipt.fetchFormData,
    setBasic: dispatch.customReceipt.setBasic,
    setMenu: dispatch.customReceipt.setMenu,
    setFee: dispatch.customReceipt.setFee,
    setStore: dispatch.customReceipt.setStore,
    addToStore: dispatch.customReceipt.addToStore,
    removeFromStore: dispatch.customReceipt.removeFromStore,
    addToCustom: dispatch.customReceipt.addToCustom,
    removeFromCustom: dispatch.customReceipt.removeFromCustom,
    setReceiptSize: dispatch.customReceipt.setReceiptSize,
    setReceiptName: dispatch.customReceipt.setReceiptName,
    setCustomText: dispatch.customReceipt.setCustomText,
    setCustomUrl: dispatch.customReceipt.setCustomUrl
}); };
export default connect(mapState, mapDispatch)(ReceiptTpl);
