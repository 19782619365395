var aplus_queue = window.aplus_queue;
var sendPv = function () {
    if (aplus_queue) {
        aplus_queue.push({
            action: 'aplus.sendPV',
            arguments: [{
                    is_auto: false
                }, {
                    URL: window.location.href
                }]
        });
    }
};
export { sendPv };
