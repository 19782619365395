var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Form, Input, message } from 'antd';
import { ApplySmsCodeButton, InputSmsCode, SmsInputGroup } from '@components/Common';
import { getItem } from '@utils/localStorage';
import { requestForMerchant } from '@utils/request';
import { PHONE_REGEX } from '@utils/regex';
import { ResetPwdButton } from './styled';
var FormItem = Form.Item;
var SMS_MAX_COUNTDOWN = 60;
var ResetConfirmForm = /** @class */ (function (_super) {
    __extends(ResetConfirmForm, _super);
    function ResetConfirmForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            countdown: SMS_MAX_COUNTDOWN
        };
        _this.countdownTimer = 0;
        _this.handleGetSMSCode = function () {
            var validateFields = _this.props.form.validateFields;
            if (_this.state.countdown < SMS_MAX_COUNTDOWN)
                return false;
            validateFields(['phone'], function (errors, _a) {
                var phone = _a.phone;
                if (errors)
                    return false;
                requestForMerchant
                    .post('/merchant/account/sendSmsCodeForForgetPwd', { phone: phone })
                    .then(function (back) {
                    if (back.code === 0) {
                        _this.startCountdownTimer();
                    }
                    else {
                        message.error(back.err);
                    }
                });
            });
        };
        _this.handleNextStep = function () {
            _this.props.form.validateFields(function (errors, values) {
                if (errors)
                    return false;
                requestForMerchant
                    .post('/merchant/account/forgetPwdSmsCodeCheck', __assign(__assign({}, values), { smsCode: values.verificationCode }))
                    .then(function (_a) {
                    var data = _a.data, code = _a.code, err = _a.err;
                    if (code === 0) {
                        _this.props.onConfirm(data);
                    }
                    else {
                        message.error(err);
                    }
                })
                    .catch(function (e) {
                    console.log(e);
                });
            });
        };
        return _this;
    }
    ResetConfirmForm.prototype.startCountdownTimer = function () {
        var _this = this;
        this.countdownTimer = window.setInterval(function () {
            var currCountdown = _this.state.countdown;
            if (currCountdown && currCountdown <= SMS_MAX_COUNTDOWN) {
                _this.setState({ countdown: currCountdown - 1 });
            }
            else {
                clearInterval(_this.countdownTimer);
                _this.setState({ countdown: SMS_MAX_COUNTDOWN });
            }
        }, 1000);
    };
    ResetConfirmForm.prototype.componentWillUnmount = function () {
        clearInterval(this.countdownTimer);
    };
    ResetConfirmForm.prototype.render = function () {
        var countdown = this.state.countdown;
        var getFieldDecorator = this.props.form.getFieldDecorator;
        var _a = this.props.isChange, isChange = _a === void 0 ? false : _a;
        var lastChooseMerchant = getItem('lastChooseMerchant') && JSON.parse(getItem('lastChooseMerchant'));
        return (React.createElement(Form, null,
            React.createElement(FormItem, null, getFieldDecorator('phone', {
                initialValue: isChange ? lastChooseMerchant === null || lastChooseMerchant === void 0 ? void 0 : lastChooseMerchant.phone : '',
                rules: [
                    {
                        required: true,
                        pattern: PHONE_REGEX,
                        message: '请输入正确的手机号'
                    }
                ]
            })(React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u60A8\u7684\u624B\u673A\u53F7", disabled: isChange }))),
            React.createElement(FormItem, null, getFieldDecorator('verificationCode', {
                rules: [
                    {
                        required: true,
                        message: '请输入验证码'
                    }
                ]
            })(React.createElement(SmsInputGroup, null,
                React.createElement(InputSmsCode, { className: "input-sms-code", placeholder: "\u8BF7\u8F93\u5165\u60A8\u7684\u9A8C\u8BC1\u7801" }),
                React.createElement(ApplySmsCodeButton, { className: "btn-sms-code", onClick: this.handleGetSMSCode }, countdown && countdown < SMS_MAX_COUNTDOWN
                    ? countdown + "S"
                    : '获取验证码')))),
            React.createElement(ResetPwdButton, { type: "primary", onClick: this.handleNextStep, block: true }, "\u4E0B\u4E00\u6B65")));
    };
    return ResetConfirmForm;
}(React.Component));
var WrappedResetConfirmForm = Form.create()(ResetConfirmForm);
export default WrappedResetConfirmForm;
