var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Radio, TimePicker } from 'antd';
import moment from 'moment';
import { currentTime } from '@utils/time';
import { ReservationTimeWrapper } from './styled';
var RadioGroup = Radio.Group;
var RadioButton = Radio.Button;
var TimeFormat = 'HH:mm';
var range = function (start, end) {
    var result = [];
    for (var i = start; i < end; i++) {
        result.push(i);
    }
    return result;
};
var ReservationTime = /** @class */ (function (_super) {
    __extends(ReservationTime, _super);
    function ReservationTime(props) {
        var _this = _super.call(this, props) || this;
        _this.disabledHours = function () {
            return range(0, moment()
                .add(2, 'h')
                .add(10, 'm')
                .hour());
        };
        _this.disabledMinutes = function (selectedHour) {
            var limitTime = moment()
                .add(2, 'h')
                .add(10, 'm');
            var limitHour = limitTime.hour();
            var limitMinute = limitTime.minute();
            if (selectedHour === limitHour) {
                return range(0, limitMinute);
            }
            return [];
        };
        _this.handleDateChange = function (type) {
            switch (type) {
                case 0:
                    _this.setState({ type: type });
                    _this.triggerChange('date', moment().add(0, 'd'));
                    break;
                case 1:
                    _this.setState({ type: type });
                    _this.triggerChange('date', moment().add(1, 'd'));
                    break;
                case 2:
                    _this.setState({ type: type });
                    _this.triggerChange('date', moment().add(2, 'd'));
                    break;
                default:
                    break;
            }
        };
        _this.handleTimeChange = function (time) {
            _this.triggerChange('time', time);
        };
        _this.triggerChange = function (type, value) {
            var _a = _this.props, onChange = _a.onChange, triggerChange = _a.triggerChange;
            var time = _this.state.time;
            var newDate = moment(time).format('YYYY-MM-DD');
            var newTime = moment(time).format(TimeFormat);
            if (type === 'date') {
                newDate = moment(value).format('YYYY-MM-DD');
                if (moment().isSame(moment(value), 'd')) {
                    newTime = moment()
                        .add(2, 'h')
                        .add(10, 'm')
                        .format(TimeFormat);
                }
                else {
                    newTime = moment().format(TimeFormat);
                }
            }
            if (type === 'time') {
                newTime = moment(value).format(TimeFormat);
            }
            var newTimeStr = moment(newDate + " " + newTime);
            if (onChange) {
                _this.setState({ time: newTimeStr });
                onChange(newTimeStr);
                triggerChange && triggerChange();
            }
        };
        _this.state = {
            time: props.value || moment(currentTime(), TimeFormat),
            type: 0
        };
        return _this;
    }
    ReservationTime.getDerivedStateFromProps = function (nextProps) {
        if ('value' in nextProps) {
            var time = nextProps.value || moment(currentTime(), TimeFormat);
            return {
                time: time
            };
        }
        return null;
    };
    ReservationTime.prototype.componentDidMount = function () {
        this.setState({
            time: moment(moment().add(130, 'm').format('YYYY-MM-DD HH:mm:ss'))
        });
    };
    ReservationTime.prototype.render = function () {
        var _this = this;
        var _a = this.state, time = _a.time, type = _a.type;
        var width = this.props.width;
        return (React.createElement(ReservationTimeWrapper, { width: width },
            React.createElement(RadioGroup, { defaultValue: type, onChange: function (_a) {
                    var value = _a.target.value;
                    return _this.handleDateChange(value);
                }, buttonStyle: "solid" },
                React.createElement(RadioButton, { value: 0 }, "\u4ECA\u5929"),
                React.createElement(RadioButton, { value: 1 }, "\u660E\u5929")),
            React.createElement(TimePicker, { disabledHours: type === 0 ? this.disabledHours : function () { return []; }, disabledMinutes: type === 0 ? this.disabledMinutes : function () { return []; }, defaultValue: moment(time, TimeFormat), format: TimeFormat, value: moment(time, TimeFormat), onChange: this.handleTimeChange, allowClear: false })));
    };
    return ReservationTime;
}(React.Component));
export default ReservationTime;
