import React, { useEffect } from 'react';
import { notification } from 'antd';
var ChromeCheck = function () {
    var handleCheckChrome = function () {
        var args = {
            message: '浏览器更换提示',
            description: React.createElement("div", null,
                "\u4E3A\u66F4\u597D\u4F53\u9A8C\u4EA7\u54C1\u5B8C\u6574\u529F\u80FD\uFF0C\u8BF7\u4F7F\u7528",
                React.createElement("a", { href: "https://www.google.cn/intl/zh-CN/chrome/", target: '_blanck' }, "Chrome\u6D4F\u89C8\u5668"),
                " \u6253\u5F00\u672C\u9875\u9762 "),
            duration: 0
        };
        notification.warning(args);
    };
    useEffect(function () {
        if (window.navigator.userAgent.indexOf('Chrome') < 0) {
            handleCheckChrome();
        }
    }, []);
    return null;
};
export default ChromeCheck;
