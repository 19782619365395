var _a;
import { get } from 'lodash';
import { PrintCases } from '../../constants';
import { getPlatformText } from './common';
import { ORDER_PLATFORM_MAP } from '@utils/constants';
var genZhongWuTable = function (paperFormat, goods) {
    var zhongwuTableHead = '<TR><TD>品名</TD><TD>数量</TD><TD>金额</TD></TR>';
    var genTableGoods = goods
        .map(function (good) {
        return "<TR><TD>" + good.name + "</TD><TD>" + good.quantity + "</TD><TD>" + good.total + "</TD></TR>";
    })
        .join('');
    return zhongwuTableHead + genTableGoods;
};
export var ZhongWuCloudTpl = (_a = {},
    _a[PrintCases.Customer] = function (_a) {
        var paperFormat = _a.paperFormat, data = _a.data;
        var content = "<S2><C>" + data.storeName + "</C></S2><RN>" +
            "<S1><C>#\u987E\u5BA2\u8054#</C></S1><RN><RN>" +
            ("\u63A5\u5355\u5E73\u53F0\uFF1A" + getPlatformText(data.platform) + "<RN>") +
            ("\u8BA2\u5355\u53F7\uFF1A" + data.platformId + "<RN>") +
            ("\u5E73\u53F0\u6D41\u6C34\u53F7\uFF1A" + data.platformDaySn + "<RN>") +
            ("\u6613\u9001\u6D41\u6C34\u53F7\uFF1A" + data.daySn + "<RN>") +
            ("\u4E0B\u5355\u65F6\u95F4\uFF1A" + data.orderTime + "<RN>") +
            ("<S2>\u9001\u8FBE\u65F6\u95F4\uFF1A" + data.deliverTime + "</S2><RN>") +
            ("<S2>\u5730\u5740\uFF1A" + data.consigneeAddress + "</S2><RN>") +
            ("<S2>\u59D3\u540D\uFF1A" + data.consignee + "</S2><RN>") +
            ("<S2>\u7535\u8BDD\uFF1A" + data.consigneePhone + "</S2><RN>") +
            ("<S2>\u5907\u6CE8\uFF1A" + get(data, 'description', '') + "</S2><RN>") +
            ("<S2>\u53D1\u7968\uFF1A" + data.invoiceTitle + " " + data.invoiceId + "</S2><RN>") +
            (genZhongWuTable(paperFormat, data.goods) + "<RN><RN>") +
            ("\u9910\u76D2\u8D39\uFF1A" + data.packageFee + "\u5143<RN>") +
            ("\u914D\u9001\u8D39\uFF1A" + data.deliverFee + "\u5143<RN>") +
            ("<R>\u539F\u4EF7\uFF1A" + data.originalPrice + "\u5143</R><RN>") +
            ("<R>\u603B\u4F18\u60E0\u91D1\u989D\uFF1A" + data.reducedPrice + "\u5143</R><RN>") +
            ("<R>\u5728\u7EBF\u652F\u4ED8\uFF1A" + data.totalPrice + "\u5143</R><RN><RN>") +
            ("<BR>" + data.id + "</BR><RN>") +
            ("<C>\u6613\u9001\u8BA2\u5355\u53F7:" + data.id + "</C><RN>");
        if (data.platform === ORDER_PLATFORM_MAP.MEITUAN) {
            content +=
                "<BR>" + data.platformId + "</BR><RN>" +
                    ("<C>\u7F8E\u56E2\u8BA2\u5355\u53F7:" + data.platformId + "</C><RN>");
        }
        return content;
    },
    _a[PrintCases.Merchant] = function (_a) {
        var paperFormat = _a.paperFormat, data = _a.data;
        var content = "<S2><C>" + data.storeName + "</C></S2><RN>" +
            "<S1><C>#\u5546\u6237\u8054#</C></S1><RN><RN>" +
            ("\u63A5\u5355\u5E73\u53F0\uFF1A" + getPlatformText(data.platform) + "<RN>") +
            ("\u8BA2\u5355\u53F7\uFF1A" + data.platformId + "<RN>") +
            ("\u5E73\u53F0\u6D41\u6C34\u53F7\uFF1A" + data.platformDaySn + "<RN>") +
            ("\u6613\u9001\u6D41\u6C34\u53F7\uFF1A" + data.daySn + "<RN>") +
            ("\u4E0B\u5355\u65F6\u95F4\uFF1A" + data.orderTime + "<RN>") +
            ("\u9001\u8FBE\u65F6\u95F4\uFF1A" + data.deliverTime + "<RN>") +
            ("\u5730\u5740\uFF1A" + data.consigneeAddress + "<RN>") +
            ("\u59D3\u540D\uFF1A" + data.consignee + "<RN>") +
            ("\u7535\u8BDD\uFF1A" + data.consigneePhone + "<RN>") +
            ("<S2>\u5907\u6CE8\uFF1A" + get(data, 'description', '') + "</S2><RN>") +
            ("<S2>\u53D1\u7968\uFF1A" + data.invoiceTitle + " " + data.invoiceId + "</S2><RN>") +
            (genZhongWuTable(paperFormat, data.goods) + "<RN><RN>") +
            ("\u9910\u76D2\u8D39\uFF1A" + data.packageFee + "\u5143<RN>") +
            ("\u914D\u9001\u8D39\uFF1A" + data.deliverFee + "\u5143<RN>") +
            ("<R>\u539F\u4EF7\uFF1A" + data.originalPrice + "\u5143</R><RN>") +
            ("<R>\u603B\u4F18\u60E0\u91D1\u989D\uFF1A" + data.reducedPrice + "\u5143</R><RN>") +
            ("<R>\u5728\u7EBF\u652F\u4ED8\uFF1A" + data.totalPrice + "\u5143</R><RN><RN>") +
            ("<BR>" + data.id + "</BR><RN>") +
            ("<C>\u6613\u9001\u8BA2\u5355\u53F7:" + data.id + "</C><RN>");
        if (data.platform === ORDER_PLATFORM_MAP.MEITUAN) {
            content +=
                "<BR>" + data.platformId + "</BR><RN>" +
                    ("<C>\u7F8E\u56E2\u8BA2\u5355\u53F7:" + data.platformId + "</C><RN>");
        }
        return content;
    },
    _a[PrintCases.Producer] = function (_a) {
        var paperFormat = _a.paperFormat, data = _a.data;
        var content = "<S2><C>" + data.storeName + "</C></S2><RN>" +
            "<S1><C>#\u751F\u4EA7\u8054#</C></S1><RN>" +
            ("\u5E73\u53F0\u6D41\u6C34\u53F7\uFF1A" + data.platformDaySn + "<RN>") +
            ("\u6613\u9001\u6D41\u6C34\u53F7\uFF1A" + data.daySn + "<RN><RN>") +
            ("<H2>" + genZhongWuTable(paperFormat, data.goods) + "</H2><RN><RN>") +
            ("<S2>\u5907\u6CE8\uFF1A" + get(data, 'description', '') + "</S2><RN>");
        return content;
    },
    _a);
