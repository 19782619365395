import React from 'react';
import { DELIVERY_PLATFORM_TEXT_MAP, ORDER_PLATFORM_TEXT_MAP } from '@utils/constants';
import Tips from '../common/Tips';
import { TableTipTitle } from './styled';
export var incomeRankColumns = [
    {
        title: '门店名称',
        dataIndex: 'name'
    },
    {
        title: '已完成订单数',
        dataIndex: 'finishedOrderCount',
        sorter: function (a, b) { return a.finishedOrderCount - b.finishedOrderCount; }
    },
    {
        title: '订单实收客单价',
        dataIndex: 'averageOrderPrice',
        sorter: function (a, b) { return a.averageOrderPrice - b.averageOrderPrice; },
        render: function (text) { return text + "\u5143"; }
    },
    {
        title: '营业额',
        dataIndex: 'totalOrderFlowAmount',
        sorter: function (a, b) { return a.totalOrderFlowAmount - b.totalOrderFlowAmount; },
        render: function (text) { return text + "\u5143"; }
    },
    {
        title: '收入',
        dataIndex: 'totalIncomeOrderAmount',
        sorter: function (a, b) { return a.totalIncomeOrderAmount - b.totalIncomeOrderAmount; },
        render: function (text) { return text + "\u5143"; }
    },
    {
        title: function () {
            return (React.createElement(React.Fragment, null,
                React.createElement(Tips, { title: "\u4EC5\u7EDF\u8BA1\u4F7F\u7528\u6613\u9001\u53D1\u51FA\u7684\u914D\u9001\u5355\uFF0C\u91D1\u989D\u4E3A\u5B9E\u4ED8\u914D\u9001\u8D39\u603B\u989D\uFF1B\u4EC5\u505A\u6570\u636E\u5206\u6790\u53C2\u8003\u4F7F\u7528\uFF0C\u4E0D\u505A\u771F\u5B9E\u5BF9\u8D26\u4F9D\u636E" }),
                React.createElement(TableTipTitle, null, "\u8BA2\u5355\u914D\u9001\u8D39\u603B\u989D")));
        },
        dataIndex: 'totalDistributeAmount',
        sorter: function (a, b) { return a.totalDistributeAmount - b.totalDistributeAmount; },
        render: function (text) { return text + "\u5143"; }
    }
];
export var deliveryRankColumns = [
    {
        title: '门店名称',
        dataIndex: 'name'
    },
    {
        title: '配送费部分',
        dataIndex: 'distributeAmount',
        sorter: function (a, b) { return a.distributeAmount - b.distributeAmount; },
        render: function (text) { return text + "\u5143"; }
    },
    {
        title: '小费部分',
        dataIndex: 'tipsAmount',
        sorter: function (a, b) { return a.tipsAmount - b.tipsAmount; },
        render: function (text) { return text + "\u5143"; }
    },
    {
        title: '配送费总额',
        dataIndex: 'totalDistributeAmount',
        sorter: function (a, b) { return a.totalDistributeAmount - b.totalDistributeAmount; },
        render: function (text) { return text + "\u5143"; }
    },
    {
        title: '已完成配送单数',
        dataIndex: 'distributedOrderCount',
        sorter: function (a, b) { return a.distributedOrderCount - b.distributedOrderCount; },
        render: function (text) { return "" + text; }
    },
    {
        title: function () {
            return (React.createElement(React.Fragment, null,
                React.createElement(Tips, { title: "\u4EC5\u7EDF\u8BA1\u4F7F\u7528\u6613\u9001\u53D1\u51FA\u7684\u8BE5\u914D\u9001\u5E73\u53F0\u7684\u5DF2\u5B8C\u6210\u914D\u9001\u5355\uFF0C\u914D\u9001\u8D39\u603B\u989D/\u914D\u9001\u5355\u6570\uFF1B\u4EC5\u505A\u6570\u636E\u5206\u6790\u53C2\u8003\u4F7F\u7528\uFF0C\u4E0D\u505A\u771F\u5B9E\u5BF9\u8D26\u4F9D\u636E" }),
                React.createElement(TableTipTitle, null, "\u914D\u9001\u5355\u4EF7")));
        },
        dataIndex: 'averageDistributePrice',
        sorter: function (a, b) { return a.averageDistributePrice - b.averageDistributePrice; },
        render: function (text) { return text + "\u5143"; }
    }
];
export var orderPlatformRankColumns = [
    {
        title: '接单平台名称',
        dataIndex: 'platform',
        render: function (platform) { return "" + ORDER_PLATFORM_TEXT_MAP[platform]; }
    },
    {
        title: '全部订单数',
        dataIndex: 'totalOrderCount',
        sorter: function (a, b) { return a.totalOrderCount - b.totalOrderCount; }
    },
    {
        title: '已取消订单数',
        dataIndex: 'cancledOrderCount',
        sorter: function (a, b) { return a.cancledOrderCount - b.cancledOrderCount; }
    },
    {
        title: '已完成订单数',
        dataIndex: 'finishedOrderCount',
        sorter: function (a, b) { return a.finishedOrderCount - b.finishedOrderCount; }
    },
    {
        title: '订单实收客单价',
        dataIndex: 'averageIncomeRealPrice',
        sorter: function (a, b) { return a.averageIncomeRealPrice - b.averageIncomeRealPrice; },
        render: function (text) { return text + "\u5143"; }
    },
    {
        title: function () {
            return (React.createElement(React.Fragment, null,
                React.createElement(Tips, { title: "\u72B6\u6001\u4E3A\u5DF2\u5B8C\u6210\u7684\u8BA2\u5355\u7684\u5B9E\u9645\u6536\u5165\uFF08\u7F8E\u56E2\u4E5F\u53EB\u5546\u5BB6\u5E94\u6536\u6B3E\uFF09\u4E4B\u548C\uFF0C\u5B57\u6BB5\u683C\u5F0F\u4FDD\u7559\u5C0F\u6570\u70B9\u540E\u4E24\u4F4D\uFF1B\u4EC5\u505A\u6570\u636E\u5206\u6790\u53C2\u8003\u4F7F\u7528\uFF0C\u4E0D\u505A\u771F\u5B9E\u5BF9\u8D26\u4F9D\u636E" }),
                React.createElement(TableTipTitle, null, "\u6536\u5165")));
        },
        dataIndex: 'totalIncomeRealAmount',
        sorter: function (a, b) { return a.totalIncomeRealAmount - b.totalIncomeRealAmount; },
        render: function (text) { return text + "\u5143"; }
    }
];
export var deliveryPlatformColumns = [
    {
        title: '配送平台名称',
        dataIndex: 'platform',
        render: function (platform) { return "" + DELIVERY_PLATFORM_TEXT_MAP[platform]; }
    },
    {
        title: '全部配送单数',
        dataIndex: 'totalDistributedCount',
        sorter: function (a, b) { return a.totalDistributedCount - b.totalDistributedCount; }
    },
    {
        title: '已取消配送单数',
        dataIndex: 'cancleDistributedCount',
        sorter: function (a, b) { return a.cancleDistributedCount - b.cancleDistributedCount; }
    },
    {
        title: '已完成配送单数',
        dataIndex: 'finishedDistributedCount',
        sorter: function (a, b) { return a.finishedDistributedCount - b.finishedDistributedCount; }
    },
    {
        title: function () {
            return (React.createElement(React.Fragment, null,
                React.createElement(Tips, { title: "\u4EC5\u7EDF\u8BA1\u4F7F\u7528\u6613\u9001\u53D1\u51FA\u7684\u8BE5\u914D\u9001\u5E73\u53F0\u7684\u5DF2\u5B8C\u6210\u914D\u9001\u5355\uFF0C\u914D\u9001\u8D39\u603B\u989D/\u914D\u9001\u5355\u6570\uFF1B\u4EC5\u505A\u6570\u636E\u5206\u6790\u53C2\u8003\u4F7F\u7528\uFF0C\u4E0D\u505A\u771F\u5B9E\u5BF9\u8D26\u4F9D\u636E" }),
                React.createElement(TableTipTitle, null, "\u914D\u9001\u5355\u5BA2\u5355\u4EF7")));
        },
        dataIndex: 'averageDistributePrice',
        sorter: function (a, b) { return a.averageDistributePrice - b.averageDistributePrice; }
        // render: text => `${text}元`
    },
    {
        title: function () {
            return (React.createElement(React.Fragment, null,
                React.createElement(Tips, { title: "\u4EC5\u7EDF\u8BA1\u4F7F\u7528\u6613\u9001\u53D1\u51FA\u7684\u8BE5\u914D\u9001\u5E73\u53F0\u7684\u5DF2\u5B8C\u6210\u914D\u9001\u5355\uFF0C\u91D1\u989D\u4E3A\u5B9E\u4ED8\u914D\u9001\u8D39\u603B\u989D\uFF08\u542B\u5C0F\u8D39\uFF09\uFF1B\u4EC5\u505A\u6570\u636E\u5206\u6790\u53C2\u8003\u4F7F\u7528\uFF0C\u4E0D\u505A\u771F\u5B9E\u5BF9\u8D26\u4F9D\u636E" }),
                React.createElement(TableTipTitle, null, "\u914D\u9001\u8D39\u603B\u989D")));
        },
        dataIndex: 'totalDistributeAmount',
        sorter: function (a, b) { return a.totalDistributeAmount - b.totalDistributeAmount; },
        render: function (text) { return text + "\u5143"; }
    }
];
