var _a;
import { get } from 'lodash';
import { PrintCases } from '../../constants';
import { getGoodsColText } from '../../local';
import { getPlatformText } from './common';
var TextBold;
(function (TextBold) {
    TextBold[TextBold["False"] = 0] = "False";
    TextBold[TextBold["True"] = 1] = "True";
})(TextBold || (TextBold = {}));
var genTable = function (paperFormat, goods) { return getGoodsColText({ paperFormat: paperFormat, goods: goods }); };
var Text = function (_a) {
    var _b = _a.bold, bold = _b === void 0 ? TextBold.False : _b, _c = _a.width, width = _c === void 0 ? 1 : _c, _d = _a.height, height = _d === void 0 ? 1 : _d, data = _a.data;
    return "<Font# Bold=" + bold + " Width=" + width + " Height=" + height + ">" + data + "</Font#>";
};
var BarCode = function (data) {
    return "<BarCode# Type=1 Height=20>" + data + "</BarCode#>";
};
var H2 = function (data) { return Text({ width: 2, height: 2, data: data }); };
export var FeiYinCloudTpl = (_a = {},
    _a[PrintCases.Customer] = function (_a) {
        var paperFormat = _a.paperFormat, data = _a.data;
        var content = "<center>" + H2({ data: data.storeName }) + "\n" +
            ("<center>" + H2({ data: '#顾客联#' }) + "\n") +
            ("<left>\u63A5\u5355\u5E73\u53F0\uFF1A" + getPlatformText(data.platform) + "\n") +
            ("\u8BA2\u5355\u53F7\uFF1A" + data.platformId + "\n") +
            ("\u5E73\u53F0\u6D41\u6C34\u53F7\uFF1A" + data.platformDaySn + "\n") +
            ("\u6613\u9001\u6D41\u6C34\u53F7\uFF1A" + data.daySn + "\n") +
            ("\u4E0B\u5355\u65F6\u95F4\uFF1A" + data.orderTime + "\n") +
            (H2({ data: "\u9001\u8FBE\u65F6\u95F4\uFF1A" + data.deliverTime }) + "\n") +
            (H2({ data: "\u5730\u5740\uFF1A" + data.consigneeAddress }) + "\n") +
            (H2({ data: "\u59D3\u540D\uFF1A" + data.consignee }) + "\n") +
            (H2({ data: "\u7535\u8BDD\uFF1A" + data.consigneePhone }) + "\n") +
            (H2({ data: "\u5907\u6CE8\uFF1A" + get(data, 'description', '') }) + "\n") +
            (H2({ data: "\u53D1\u7968\uFF1A" + data.invoiceTitle + " " + data.invoiceId }) + "\n") +
            (genTable(paperFormat, data.goods) + "\n\n") +
            ("\u9910\u76D2\u8D39\uFF1A" + data.packageFee + "\u5143\n") +
            ("\u914D\u9001\u8D39\uFF1A" + data.deliverFee + "\u5143\n") +
            ("<right>\u539F\u4EF7\uFF1A" + data.originalPrice + "\u5143\n") +
            ("<right>\u603B\u4F18\u60E0\u91D1\u989D\uFF1A" + data.reducedPrice + "\u5143\n") +
            ("<right>\u5728\u7EBF\u652F\u4ED8\uFF1A" + data.totalPrice + "\u5143\n\n") +
            ("<center>" + BarCode(data.id) + "\n");
        return content;
    },
    _a[PrintCases.Merchant] = function (_a) {
        var paperFormat = _a.paperFormat, data = _a.data;
        var content = "<center>" + H2({ data: data.storeName }) + "\n" +
            ("<center>" + H2({ data: '#商户联#' }) + "\n") +
            ("<left>\u63A5\u5355\u5E73\u53F0\uFF1A" + getPlatformText(data.platform) + "\n") +
            ("\u8BA2\u5355\u53F7\uFF1A" + data.platformId + "\n") +
            ("\u5E73\u53F0\u6D41\u6C34\u53F7\uFF1A" + data.platformDaySn + "\n") +
            ("\u6613\u9001\u6D41\u6C34\u53F7\uFF1A" + data.daySn + "\n") +
            ("\u4E0B\u5355\u65F6\u95F4\uFF1A" + data.orderTime + "\n") +
            ("\u9001\u8FBE\u65F6\u95F4\uFF1A" + data.deliverTime + "\n") +
            ("\u5730\u5740\uFF1A" + data.consigneeAddress + "\n") +
            ("\u59D3\u540D\uFF1A" + data.consignee + "\n") +
            ("\u7535\u8BDD\uFF1A" + data.consigneePhone + "\n") +
            (H2({ data: "\u5907\u6CE8\uFF1A" + get(data, 'description', '') }) + "\n") +
            (H2({ data: "\u53D1\u7968\uFF1A" + data.invoiceTitle + " " + data.invoiceId }) + "\n") +
            (genTable(paperFormat, data.goods) + "\n\n") +
            ("\u9910\u76D2\u8D39\uFF1A" + data.packageFee + "\u5143\n") +
            ("\u914D\u9001\u8D39\uFF1A" + data.deliverFee + "\u5143\n") +
            ("<right>\u539F\u4EF7\uFF1A" + data.originalPrice + "\u5143\n") +
            ("<right>\u603B\u4F18\u60E0\u91D1\u989D\uFF1A" + data.reducedPrice + "\u5143\n") +
            ("<right>\u5728\u7EBF\u652F\u4ED8\uFF1A" + data.totalPrice + "\u5143\n\n") +
            ("<center>" + BarCode(data.id) + "\n");
        return content;
    },
    _a[PrintCases.Producer] = function (_a) {
        var paperFormat = _a.paperFormat, data = _a.data;
        var content = "<center>" + H2({ data: data.storeName }) + "\n" +
            ("<center>" + H2({ data: '#生产联#' }) + "\n") +
            ("<left>\u5E73\u53F0\u6D41\u6C34\u53F7\uFF1A" + data.platformDaySn + "\n") +
            ("\u6613\u9001\u6D41\u6C34\u53F7\uFF1A" + data.daySn + "\n\n") +
            (H2({ data: "" + genTable(paperFormat, data.goods) }) + "\n\n") +
            (H2({ data: "\u5907\u6CE8\uFF1A" + get(data, 'description', '') }) + "\n");
        return content;
    },
    _a);
