var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { PointDestOverlay, PointRiderOverlay, PointStoreOverlay, KnightLabelOverlay } from '@utils/map';
import { DeliveryContext } from '../context';
import { DeliveryMap } from './styled';
var BMap = window.BMap;
var DeliveryOrderMap = /** @class */ (function (_super) {
    __extends(DeliveryOrderMap, _super);
    function DeliveryOrderMap() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            knightLabelOverlay: null,
            knightOverlay: null
        };
        _this.initialMap = function (_a) {
            var knightGEO = _a.knightGEO, storeGEO = _a.storeGEO, receiveGEO = _a.receiveGEO;
            var knightPoint = new BMap.Point(knightGEO[0], knightGEO[1]);
            var storePoint = new BMap.Point(storeGEO[0], storeGEO[1]);
            var receivePoint = new BMap.Point(receiveGEO[0], receiveGEO[1]);
            _this.map = new BMap.Map('delivery-map');
            _this.map.addControl(new BMap.NavigationControl());
            _this.map.centerAndZoom(storePoint, 14);
            // this.addRiderOverlay(knightPoint)
            _this.pointArr = [storePoint, receivePoint];
            _this.map.setViewport(_this.pointArr);
            _this.addDestOverlay(receivePoint);
            _this.addStoreOverlay(storePoint);
            if (!!knightGEO.length) {
                _this.addRiderOverlay(knightPoint);
                _this.pointArr.push(knightPoint);
                _this.map.setViewport(_this.pointArr);
            }
        };
        return _this;
    }
    DeliveryOrderMap.prototype.componentDidUpdate = function (preProps) {
        if (preProps.knightCoordinate !== this.props.knightCoordinate ||
            preProps.knightLabelText !== this.props.knightLabelText) {
            // 更新骑手位置
            this.updateRiderPosition();
        }
    };
    DeliveryOrderMap.prototype.componentDidMount = function () {
        var knightCoordinate = this.props.knightCoordinate;
        var _a = this.context.distriOrder, 
        // knightCoordinate,
        storeCoordinate = _a.storeCoordinate, receiveCoordinate = _a.receiveCoordinate;
        var knightGEO = knightCoordinate ? knightCoordinate.split(',') : []; // 骑士坐标
        var storeGEO = storeCoordinate.split(','); // 门店坐标(发货坐标)
        var receiveGEO = receiveCoordinate.split(','); // 收货坐标
        this.initialMap({ knightGEO: knightGEO, storeGEO: storeGEO, receiveGEO: receiveGEO });
    };
    DeliveryOrderMap.prototype.updateRiderPosition = function () {
        var _a = this.props, knightLabelText = _a.knightLabelText, knightCoordinate = _a.knightCoordinate;
        this.map.removeOverlay(this.state.knightLabelOverlay);
        this.map.removeOverlay(this.state.knightOverlay);
        var knightGEO = knightCoordinate.split(',');
        var knightPoint = new BMap.Point(knightGEO[0], knightGEO[1]);
        if (knightLabelText) {
            this.addRiderLabelOverlay(knightPoint, knightLabelText);
        }
        this.addRiderOverlay(knightPoint);
        this.pointArr.push(knightPoint);
        this.map.setViewport(this.pointArr);
    };
    DeliveryOrderMap.prototype.addMaker = function (point) {
        var marker = new BMap.Marker(point);
        this.map.addOverlay(marker);
    };
    DeliveryOrderMap.prototype.addStoreOverlay = function (point) {
        this.map.addOverlay(new PointStoreOverlay(point));
    };
    DeliveryOrderMap.prototype.addRiderOverlay = function (point) {
        var knightOverlay = new PointRiderOverlay(point);
        this.map.addOverlay(knightOverlay);
        this.setState({
            knightOverlay: knightOverlay
        });
    };
    DeliveryOrderMap.prototype.addDestOverlay = function (point) {
        this.map.addOverlay(new PointDestOverlay(point));
    };
    DeliveryOrderMap.prototype.addRiderLabelOverlay = function (point, knightLabelText) {
        var knightLabelOverlay = new KnightLabelOverlay(point, knightLabelText);
        this.map.addOverlay(knightLabelOverlay);
        this.setState({
            knightLabelOverlay: knightLabelOverlay
        });
    };
    DeliveryOrderMap.prototype.render = function () {
        return React.createElement(DeliveryMap, { id: "delivery-map" });
    };
    return DeliveryOrderMap;
}(React.PureComponent));
export { DeliveryOrderMap };
DeliveryOrderMap.contextType = DeliveryContext;
