var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import { Button, Popconfirm } from 'antd';
import styled from 'styled-components';
var EditModeWrapper = styled.div.withConfig({ displayName: "EditModeWrapper", componentId: "sc-cgmef" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var EditModeText = styled.span.withConfig({ displayName: "EditModeText", componentId: "sc-d1kkxs" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
var bindStatusTextMap = [
    '平台接单',
    '系统手工接单',
    '系统自动接单',
    '收银系统接单'
];
var getOrderPlatformUnbindTips = function (storeName, platformName) {
    return "\u786E\u5B9A\u89E3\u9664" + storeName + "\u95E8\u5E97\u4E0E" + platformName + "\u7684\u7ED1\u5B9A\u5173\u7CFB\uFF1F\u89E3\u9664\u540E" + storeName + "\u95E8\u5E97\u5C06\u65E0\u6CD5\u4F7F\u7528" + platformName + "\u5E73\u53F0\u8FDB\u884C\u63A5\u5355\uFF01";
};
var columns = function (tableComp) {
    return [
        {
            title: '接单平台',
            dataIndex: 'platformName',
            key: 'name'
        },
        {
            title: '平台名称',
            dataIndex: 'store.platformStoreName',
            key: 'platformStoreName'
        },
        {
            title: '平台id',
            dataIndex: 'store.platformStoreId',
            key: 'platformStoreId'
        },
        {
            title: '绑定状态',
            dataIndex: 'bindingStatus',
            key: 'bindingStatus'
        },
        {
            title: '门店名称',
            dataIndex: 'store.name',
            key: 'storeName'
        },
        {
            title: '门店编号',
            dataIndex: 'store.numbering',
            key: 'storeNo'
        },
        {
            title: '所在城市',
            dataIndex: 'store.city',
            key: 'storeCity'
        },
        {
            title: '门店地址',
            dataIndex: 'store.address',
            key: 'storeAddress'
        },
        {
            title: '接单方式',
            dataIndex: 'mode',
            key: 'mode',
            render: function (text, record) {
                return (React.createElement(EditModeWrapper, null,
                    React.createElement(EditModeText, null, bindStatusTextMap[text])));
            }
        },
        {
            title: '操作',
            key: 'action',
            render: function (text, record) {
                return (React.createElement(React.Fragment, null,
                    React.createElement(Button, { style: { marginRight: '10px' }, size: "small", onClick: function () { return tableComp.handleModeEdit(record); } }, "\u4FEE\u6539\u63A5\u5355\u8BBE\u7F6E"),
                    React.createElement(Popconfirm, { title: getOrderPlatformUnbindTips(record.store.name, record.platformName), onConfirm: function () { return tableComp.handleDelete(record); }, overlayStyle: { maxWidth: '280px' }, cancelText: "\u53D6\u6D88", okText: "\u786E\u5B9A" },
                        React.createElement(Button, { size: "small" }, "\u89E3\u7ED1"))));
            }
        }
    ];
};
export default columns;
var templateObject_1, templateObject_2;
