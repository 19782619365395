var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import produce from 'immer';
import { get } from 'lodash';
import FormModal from '@components/FormModal';
import { ActionsContext } from '../context';
import { EditOrderForm } from '../form';
var getEditInitialValue = function (orderData) {
    var _a = __read(orderData.receiveCoordinate.split(','), 2), lng = _a[0], lat = _a[1];
    return {
        userName: orderData.consignee,
        phone: orderData.consigneePhone,
        address: orderData.consigneeAddress,
        remarks: orderData.description,
        geo: {
            lat: lat,
            lng: lng
        }
    };
};
export var getStoreRegion = function (storeData) {
    return get(storeData, 'city.province.name', '');
};
var EditOrder = /** @class */ (function (_super) {
    __extends(EditOrder, _super);
    function EditOrder() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            storeRegion: '北京市',
            confirmLoading: false,
            storeId: null,
        };
        // componentDidMount() {
        //   const { storeId } = this.context
        //   console.log(this.state.storeId,storeId);
        //   requestForOrder
        //     .get(
        //       `/api/store/details`,
        //       {
        //         id: storeId
        //       },
        //       {
        //         showError: false
        //       }
        //     )
        //     .then(({ data }: any) => {
        //       this.setState(
        //         produce(draft => {
        //           draft.storeRegion = getStoreRegion(data)
        //         })
        //       )
        //     })
        // }
        _this.toggleConfirmLoading = function () {
            _this.setState(produce(function (draft) {
                draft.confirmLoading = !_this.state.confirmLoading;
            }));
        };
        _this.handleDone = function (form) {
            _this.toggleConfirmLoading();
            form.validateFields(function (errors, values) {
                if (errors)
                    return false;
                var orderId = _this.context.id;
                var data = {
                    orderId: orderId,
                    userName: values.userName,
                    phone: "[\"" + values.phone.replace('转', ',') + "\"]",
                    address: values.address.label || values.address.key,
                    longitude: values.geo.lng,
                    latitude: values.geo.lat,
                    remarks: values.remarks
                };
                _this.props
                    .editOrderItem({
                    orderId: orderId,
                    data: data
                })
                    .then(function () {
                    message.success('修改订单成功');
                    _this.toggleConfirmLoading();
                    _this.props.toggleVisible(false);
                }, function () {
                    _this.toggleConfirmLoading();
                })
                    .finally(function () {
                    message.success('修改订单成功');
                    _this.props.toggleVisible(false);
                });
            });
        };
        return _this;
    }
    EditOrder.prototype.render = function () {
        var _this = this;
        var _a = this.state, storeRegion = _a.storeRegion, confirmLoading = _a.confirmLoading;
        var props = this.props;
        var orderData = this.context;
        return (React.createElement(React.Fragment, null, props.visible && React.createElement(FormModal, { title: "\u4FEE\u6539\u8BA2\u5355", visible: true, confirmLoading: confirmLoading, onOk: function () {
                var form = _this.form.props.form;
                _this.handleDone(form);
            }, onCancel: function () {
                props.toggleVisible(false);
            }, destroyOnClose: true },
            React.createElement(EditOrderForm
            // region={storeRegion}
            , { 
                // region={storeRegion}
                storeId: this.context.storeId, initialValue: getEditInitialValue(orderData), wrappedComponentRef: function (form) { return (_this.form = form); } }))));
    };
    return EditOrder;
}(React.Component));
var mapDispatch = function (dispatch) { return ({
    editOrderItem: dispatch.orders.editItemWithReq
}); };
export var EditOrderModal = connect(null, mapDispatch)(EditOrder);
EditOrder.contextType = ActionsContext;
