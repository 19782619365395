var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NOAuth from '../AccountManagement/component/noAuth';
import { Checkbox, message, Tabs, Modal } from 'antd';
import LogoWhiteUrl from '@assets/images/logo-white.png';
import { useToggle } from '@hooks';
import { APP_VERSION, isElectron } from '@utils/env';
import { getMENU } from '@/layout/DefaultLayout';
import ChromeCheck from '@/components/ChromeCheck';
import ResetPassword from '../Account/ResetPassword';
import PasswordLoginForm from './components/PasswordLoginForm';
import SmsLoginForm from './components/SmsLoginForm';
import MerchantModalList from '../Merchant/List/components/ModalList';
import { LoginButton, LoginWrapper, Logo, OtherControls, Panel, Title, UserAgreementWrapper, Version } from './styled';
import { useHistory } from 'react-router-dom';
var TabPane = Tabs.TabPane;
var LOGIN_TYPE = {
    PASSWORD: 'PASSWORD',
    SMS: 'SMS'
};
var mapState = function (state) { return ({
    user: state.user
}); };
var mapDispatch = function (dispatch) { return ({
    loginWithPassword: dispatch.user.loginWithPassword,
    loginWithSMS: dispatch.user.loginWithSMS,
    merchantListEffect: dispatch.merchant.merchantListEffect,
    chooseMerchantEffect: dispatch.merchant.chooseMerchantEffect,
    refMenuEffect: dispatch.merchant.refMenuEffect,
    getAccountStatus: dispatch.user.getAccountStatus
}); };
var Login = function () {
    var user = useSelector(mapState).user;
    var history = useHistory();
    var dispatch = useDispatch();
    var noAuthRef = React.useRef();
    var _a = mapDispatch(dispatch), loginWithPassword = _a.loginWithPassword, loginWithSMS = _a.loginWithSMS, merchantListEffect = _a.merchantListEffect, chooseMerchantEffect = _a.chooseMerchantEffect, refMenuEffect = _a.refMenuEffect, getAccountStatus = _a.getAccountStatus;
    var _b = __read(React.useState(LOGIN_TYPE.PASSWORD), 2), loginType = _b[0], setLoginType = _b[1];
    var _c = __read(React.useState({}), 2), selectMerchantItem = _c[0], setSelectMerchantItem = _c[1];
    var _d = __read(React.useState([]), 2), merchantList = _d[0], setMerchantList = _d[1];
    var _e = __read(useToggle(false), 3), visible = _e[0], show = _e[1], hide = _e[2];
    var _f = __read(useToggle(true), 3), isAgreementChecked = _f[0], setAgreementChecked = _f[1], setAgreementUnChecked = _f[2];
    var _g = __read(useToggle(false), 3), isLoginLoading = _g[0], setLoginLoading = _g[1], setLoginDone = _g[2];
    var _h = __read(useToggle(false), 3), isResetPwdVisible = _h[0], setResetPwdVisible = _h[1], setResetPwdHidden = _h[2];
    var passwordFormRef = React.createRef();
    var smsFormRef = React.createRef();
    useEffect(function () {
        if (user.token) {
            handleMerchantList();
        }
    }, []);
    var handleTabChange = function (type) {
        setLoginType(type);
    };
    var handleAgreementChange = function () {
        isAgreementChecked ? setAgreementUnChecked() : setAgreementChecked();
    };
    var handleResetPwd = function () {
        isResetPwdVisible ? setResetPwdHidden() : setResetPwdVisible();
    };
    var handleMerchantList = function () {
        merchantListEffect({
            params: {},
            callback: function (back) {
                if (back['err']) {
                    message.error(back['err']);
                    setLoginDone();
                    return;
                }
                var merchantList = back ? back['merchantList'] : [];
                setMerchantList(merchantList);
                message.success('登录成功');
                if (back && back['lastChooseMerchant'] && merchantList.length > 1) {
                    show();
                }
                else {
                    var MENULIST = getMENU(back['DEFAULTmenu']);
                    if (MENULIST.length > 0) {
                        history.replace(MENULIST[0].path);
                        //return window.location.href=MENULIST[0].path
                    }
                    else {
                        localStorage.clear();
                        setTimeout(function () {
                            noAuthRef.current(true);
                        }, 1000);
                    }
                }
            }
        });
    };
    var checkIsNewUser = function (phone) { return __awaiter(void 0, void 0, void 0, function () {
        var isNewUser;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccountStatus(phone)];
                case 1:
                    isNewUser = _a.sent();
                    if (isNewUser) {
                        Modal.info({
                            title: '温馨提示',
                            content: '该账号已迁移至新易送，请前往新易送后台登录',
                            centered: true,
                            okText: '前往登录',
                            maskClosable: true,
                            onOk: function () {
                                window.open('/easyweb/user/login');
                            }
                        });
                        return [2 /*return*/, true];
                    }
                    else {
                        return [2 /*return*/, false];
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function () {
        if (loginType === LOGIN_TYPE.PASSWORD) {
            var passwordForm = passwordFormRef.current.props.form;
            passwordForm.validateFields(function (errors, values) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (errors)
                                return [2 /*return*/, false];
                            return [4 /*yield*/, checkIsNewUser(values.phone)];
                        case 1:
                            if (_a.sent()) {
                                return [2 /*return*/];
                            }
                            setLoginLoading();
                            loginWithPassword(__assign(__assign({}, values), { setLoginDone: setLoginDone,
                                handleMerchantList: handleMerchantList }));
                            return [2 /*return*/];
                    }
                });
            }); });
        }
        else if (loginType === LOGIN_TYPE.SMS) {
            var smsForm = smsFormRef.current.props.form;
            smsForm.validateFields(function (errors, values) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (errors)
                                return [2 /*return*/, false];
                            return [4 /*yield*/, checkIsNewUser(values.phone)];
                        case 1:
                            if (_a.sent()) {
                                return [2 /*return*/];
                            }
                            setLoginLoading();
                            loginWithSMS(__assign(__assign({}, values), { setLoginDone: setLoginDone,
                                handleMerchantList: handleMerchantList }));
                            return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    var onSaveMerchant = function (chooseMerchantId) { return __awaiter(void 0, void 0, void 0, function () {
        var menu;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, refMenuEffect({
                        params: chooseMerchantId,
                        getMenuItem: getMENU
                    })];
                case 1:
                    menu = _a.sent();
                    getMenuItem(menu);
                    chooseMerchantEffect({
                        params: chooseMerchantId
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(LoginWrapper, null,
        React.createElement(ChromeCheck, null),
        React.createElement(Logo, { src: LogoWhiteUrl }),
        React.createElement(Title, null, "\u6613\u9001\u5546\u5BB6 SaaS \u7CFB\u7EDF"),
        React.createElement(Panel, null,
            React.createElement(Tabs, { onChange: handleTabChange, defaultActiveKey: loginType },
                React.createElement(TabPane, { tab: "\u8D26\u53F7\u5BC6\u7801", key: LOGIN_TYPE.PASSWORD },
                    React.createElement(PasswordLoginForm, { wrappedComponentRef: passwordFormRef, handleSubmit: handleSubmit })),
                React.createElement(TabPane, { tab: "\u624B\u673A\u9A8C\u8BC1", key: LOGIN_TYPE.SMS },
                    React.createElement(SmsLoginForm, { wrappedComponentRef: smsFormRef, handleSubmit: handleSubmit }))),
            React.createElement(LoginButton, { type: "primary", size: "large", onClick: handleSubmit, disabled: !isAgreementChecked, loading: isLoginLoading, block: true }, "\u767B\u5F55"),
            React.createElement(OtherControls, null,
                React.createElement(UserAgreementWrapper, null,
                    React.createElement(Checkbox, { onChange: handleAgreementChange, checked: isAgreementChecked },
                        "\u6211\u5DF2\u9605\u8BFB\u5E76\u540C\u610F",
                        React.createElement("a", { href: isElectron ? '#/agreement' : '/agreement' }, "\u300A\u6613\u9001\u5546\u6237\u534F\u8BAE\u300B"),
                        "\u53CA",
                        React.createElement("a", { href: isElectron ? '#/agreementPrivacy' : '/agreementPrivacy' }, "\u300A\u9690\u79C1\u534F\u8BAE\u300B"))),
                React.createElement("a", { onClick: handleResetPwd }, "\u5FD8\u8BB0\u5BC6\u7801"))),
        React.createElement(NOAuth, { ref: noAuthRef }),
        React.createElement(ResetPassword, { visible: isResetPwdVisible, onCancel: handleResetPwd }),
        isElectron ? React.createElement(Version, null, "version: " + APP_VERSION) : null,
        visible ? (React.createElement(MerchantModalList, { hide: hide, setSelectMerchantItem: setSelectMerchantItem, selectMerchantItem: selectMerchantItem, onSaveMerchant: onSaveMerchant, data: merchantList })) : null));
    function getMenuItem(MENULIST) {
        if (MENULIST.length > 0) {
            return (window.location.href = MENULIST[0].path);
        }
        else {
            localStorage.clear();
            setTimeout(function () {
                noAuthRef.current(true);
            }, 1000);
        }
    }
};
export default Login;
