export var ReceiptSizeCode;
(function (ReceiptSizeCode) {
    ReceiptSizeCode[ReceiptSizeCode["Small"] = 27] = "Small";
    ReceiptSizeCode[ReceiptSizeCode["Big"] = 28] = "Big";
})(ReceiptSizeCode || (ReceiptSizeCode = {}));
export var StoreCode;
(function (StoreCode) {
    StoreCode[StoreCode["Logo"] = 1] = "Logo";
    StoreCode[StoreCode["StoreName"] = 2] = "StoreName";
    StoreCode[StoreCode["ReceiptName"] = 3] = "ReceiptName";
})(StoreCode || (StoreCode = {}));
export var BasicCode;
(function (BasicCode) {
    BasicCode[BasicCode["Platform"] = 4] = "Platform";
    BasicCode[BasicCode["PlatformDaySn"] = 5] = "PlatformDaySn";
    BasicCode[BasicCode["PlatformId"] = 6] = "PlatformId";
    BasicCode[BasicCode["DaySn"] = 7] = "DaySn";
    BasicCode[BasicCode["OrderTime"] = 8] = "OrderTime";
    BasicCode[BasicCode["DeliverTime"] = 9] = "DeliverTime";
    BasicCode[BasicCode["Description"] = 10] = "Description";
    // InvoiceTitle = 11,
    // InvoiceId = 12,
    BasicCode[BasicCode["ConsigneeAddress"] = 13] = "ConsigneeAddress";
    BasicCode[BasicCode["ConsigneePhone"] = 14] = "ConsigneePhone";
    BasicCode[BasicCode["Consignee"] = 15] = "Consignee";
    BasicCode[BasicCode["Invoice"] = 31] = "Invoice";
    BasicCode[BasicCode["PreOrder"] = 36] = "PreOrder";
    BasicCode[BasicCode["PreOrderPerson"] = 37] = "PreOrderPerson";
    BasicCode[BasicCode["Bless"] = 35] = "Bless";
})(BasicCode || (BasicCode = {}));
export var MenuCode;
(function (MenuCode) {
    MenuCode[MenuCode["Name"] = 16] = "Name";
    MenuCode[MenuCode["Quantity"] = 17] = "Quantity";
    MenuCode[MenuCode["Total"] = 18] = "Total";
    MenuCode[MenuCode["Package"] = 34] = "Package";
    MenuCode[MenuCode["Spec"] = 32] = "Spec";
})(MenuCode || (MenuCode = {}));
export var FeeCode;
(function (FeeCode) {
    FeeCode[FeeCode["PackageFee"] = 19] = "PackageFee";
    FeeCode[FeeCode["DeliverFee"] = 20] = "DeliverFee";
    FeeCode[FeeCode["OriginalPrice"] = 21] = "OriginalPrice";
    FeeCode[FeeCode["ReducedPrice"] = 22] = "ReducedPrice";
    FeeCode[FeeCode["TotalPrice"] = 23] = "TotalPrice";
    FeeCode[FeeCode["Activity"] = 33] = "Activity";
})(FeeCode || (FeeCode = {}));
export var CustomCode;
(function (CustomCode) {
    CustomCode[CustomCode["Image"] = 24] = "Image";
    CustomCode[CustomCode["Text"] = 25] = "Text";
    CustomCode[CustomCode["Sign"] = 26] = "Sign";
    CustomCode[CustomCode["EasyBarCode"] = 29] = "EasyBarCode";
    CustomCode[CustomCode["MeituanBarCode"] = 30] = "MeituanBarCode";
})(CustomCode || (CustomCode = {}));
