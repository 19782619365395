var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import * as React from 'react';
import { Button, Col, Form, Input, InputNumber, Radio, Row } from 'antd';
import moment from 'moment';
import { IconFengniao, IconShansong, IconDada, IconShunFeng, IconUupt, IconMtpt } from '@components/Icon';
import ReservationTime from '@components/ReservationTime';
import { PlatformChooseWrapper, PlatformIconWrapper, PlatformInfo, PlatformInfoBigText, PlatformInfoText, PlatformItem, PlatformInfoWrapper, ErrorWrapper } from './styled';
import checkedIcon from '@assets/images/checked.png';
import uncheckedIcon from '@assets/images/unchecked.png';
import InsuranceComponent from '../components/InsuranceComponent';
import { initText } from './SendDeliveryCalculate';
import lodash from 'lodash';
var FormItem = Form.Item;
var RadioButton = Radio.Button;
var RadioGroup = Radio.Group;
var TextArea = Input.TextArea;
var formItemLayout = {
    labelCol: { span: 7 }
};
var PLATFORM_MAP = {
    SHANSONG: 'shansong',
    FENGNIAO: 'fengniao',
    DADA: 'dada',
    SHUNFENG: 'shunfeng',
    UUPT: 'uupt',
    MTPT: 'meituan_pt'
};
var PLATFORM_ICON_MAP = (_a = {},
    _a[PLATFORM_MAP.SHANSONG] = React.createElement(IconShansong, null),
    _a[PLATFORM_MAP.FENGNIAO] = React.createElement(IconFengniao, null),
    _a[PLATFORM_MAP.DADA] = React.createElement(IconDada, null),
    _a[PLATFORM_MAP.SHUNFENG] = React.createElement(IconShunFeng, null),
    _a[PLATFORM_MAP.UUPT] = React.createElement(IconUupt, null),
    _a[PLATFORM_MAP.MTPT] = React.createElement(IconMtpt, null),
    _a);
var DeliveryPlatform = function (_a) {
    var platform = _a.platform, amount = _a.amount, errorMsg = _a.errorMsg, errorType = _a.errorType, balanceAmount = _a.balanceAmount, _b = _a.reducedPrice, reducedPrice = _b === void 0 ? 0 : _b, selected = _a.selected, onClick = _a.onClick;
    var Icon = PLATFORM_ICON_MAP[platform] || null;
    var noBalance = errorType === 1;
    return (React.createElement(PlatformItem, null,
        React.createElement(PlatformInfoWrapper, { onClick: onClick, selected: selected },
            React.createElement(PlatformIconWrapper, { disabled: noBalance }, Icon),
            React.createElement(PlatformInfo, null,
                React.createElement(PlatformInfoBigText, { disabled: noBalance },
                    "\u5E94\u4ED8\uFF1A",
                    amount,
                    " \u5143"),
                React.createElement(PlatformInfoText, null,
                    "\u8D26\u6237\u4F59\u989D\uFF1A",
                    noBalance ? '余额不足' : balanceAmount + '元'),
                React.createElement(PlatformInfoText, null,
                    "\u4F18\u60E0\u91D1\u989D\uFF1A",
                    reducedPrice,
                    " \u5143")),
            React.createElement("img", { className: "img", alt: "", src: selected ? checkedIcon : uncheckedIcon })),
        errorMsg && React.createElement(ErrorWrapper, null, errorMsg)));
};
var SendDeliveryForm = /** @class */ (function (_super) {
    __extends(SendDeliveryForm, _super);
    function SendDeliveryForm(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            InsuranceText: initText,
            InsuranceVis: false
        };
        _this.triggerChange = function () {
            var _a = _this.props, triggerChange = _a.triggerChange, handleTipChange = _a.handleTipChange;
            var changeTimer = setImmediate(function () {
                var values = _this.props.form.getFieldsValue();
                triggerChange &&
                    triggerChange(values, function () {
                        handleTipChange(values.tip);
                    });
                clearImmediate(changeTimer);
            });
        };
        _this.handleTipChange = function (tip) {
            // const { handleTipChange } = this.props
            // handleTipChange(tip)
            // this.additionAmountforDetail(tip)
        };
        _this.handleSelectPlatformItem = function (item) {
            // 余额不足
            if (item.errorType === 1)
                return;
            var channelInit = _this.props.channelInit;
            var setFieldsValue = _this.props.form.setFieldsValue;
            var channelChecked = channelInit.channelChecked, handleUpdateChannelChecked = channelInit.handleUpdateChannelChecked;
            if (channelChecked.includes(item.platform)) {
                var currentPlatform_1 = channelChecked.filter(function (record) { return item.platform !== record; });
                handleUpdateChannelChecked(currentPlatform_1, function () {
                    // this.triggerChange()
                    setFieldsValue({ platform: currentPlatform_1 });
                });
            }
            else {
                channelChecked.push(item.platform);
                handleUpdateChannelChecked(Object.assign([], channelChecked), function () {
                    // this.triggerChange()
                    setFieldsValue({ platform: channelChecked });
                });
            }
        };
        _this.onCancel = function (value) {
            _this.setState({ InsuranceVis: false });
            var setFieldsValue = _this.props.form.setFieldsValue;
            if (isNaN(value) && _this.state.InsuranceText === '保价') {
                setFieldsValue({ InsuranceState: 1 });
            }
            if (value === '不保价') {
                setFieldsValue({ InsuranceState: 1 });
                _this.setState({ InsuranceText: initText });
                _this.declaredAmountforDetail(0);
            }
        };
        _this.declaredAmountforDetail = function (account) {
            var values = _this.props.form.getFieldsValue();
            _this.props.declaredAmountforDetail(__assign(__assign({}, values), { declaredAmount: account, additionAmount: values.tip }));
        };
        _this.additionAmountforDetail = function (account) {
            var values = _this.props.form.getFieldsValue();
            var declaredAmount = _this.state.InsuranceText === initText ? 0 : _this.state.InsuranceText;
            _this.props.declaredAmountforDetail(__assign(__assign({}, values), { additionAmount: account, declaredAmount: declaredAmount }));
        };
        _this.onOk = function (value) {
            _this.declaredAmountforDetail(value);
            _this.setState({ InsuranceText: value }, function () {
                _this.onCancel();
            });
        };
        _this.Insuranceref = React.createRef();
        return _this;
    }
    SendDeliveryForm.prototype.getText = function (text) {
        return text !== "保价" ? text + "元" : "保价";
    };
    SendDeliveryForm.prototype.render = function () {
        var _this = this;
        var _a = this.props, valuations = _a.valuations, channelInit = _a.channelInit, mapOrder = _a.mapOrder;
        var _b = this.props.form, getFieldDecorator = _b.getFieldDecorator, getFieldValue = _b.getFieldValue;
        var showDateTimePicker = getFieldValue('appointType') === 1;
        var channelChecked = channelInit.channelChecked, channelDispatchType = channelInit.channelDispatchType;
        return (React.createElement(React.Fragment, null,
            React.createElement(Form, __assign({}, formItemLayout),
                React.createElement(Row, { gutter: 2 },
                    React.createElement(Col, { span: mapOrder ? 24 : 12 },
                        React.createElement(FormItem, { label: "\u53D6\u4EF6\u65F6\u95F4" }, getFieldDecorator('appointType', {
                            initialValue: 0,
                            rules: [
                                {
                                    required: true
                                }
                            ]
                        })(React.createElement(RadioGroup, { onChange: this.triggerChange },
                            React.createElement(RadioButton, { value: 0 }, "\u7ACB\u5373\u53D6\u4EF6"),
                            React.createElement(RadioButton, { value: 1 }, "\u9884\u7EA6\u53D6\u4EF6"))))),
                    showDateTimePicker && mapOrder ? (React.createElement(Col, { span: mapOrder ? 24 : 12 },
                        React.createElement(FormItem, { label: "\u9884\u7EA6\u65F6\u95F4" }, getFieldDecorator('reservationTime', {
                            initialValue: moment(moment()
                                .add(2, 'h')
                                .add(10, 'm'), 'YYYY-MM-DD HH:mm'),
                            rules: [
                                {
                                    required: true,
                                    message: '请选择预约时间'
                                }
                            ]
                        })(React.createElement(ReservationTime, { width: "66%", triggerChange: this.triggerChange }))))) : null,
                    React.createElement(Col, { span: mapOrder ? 24 : 12 },
                        React.createElement(FormItem, { label: "\u5C0F\u8D39" }, getFieldDecorator('tip', {
                            initialValue: 0
                        })(React.createElement(InputNumber, { min: 0, formatter: function (value) { return value + "\u5143"; }, parser: function (value) { return (value ? value.replace('元', '') : ''); }, onChange: function (value) { return _this.additionAmountforDetail(Number(value)); } }))))),
                showDateTimePicker && !mapOrder ? (React.createElement(Row, { gutter: 2 },
                    React.createElement(Col, { span: 19, pull: 2 },
                        React.createElement(FormItem, { label: "\u9884\u7EA6\u65F6\u95F4" }, getFieldDecorator('reservationTime', {
                            initialValue: moment(moment()
                                .add(2, 'h')
                                .add(10, 'm'), 'YYYY-MM-DD HH:mm'),
                            rules: [
                                {
                                    required: true,
                                    message: '请选择预约时间'
                                }
                            ]
                        })(React.createElement(ReservationTime, { triggerChange: this.triggerChange })))))) : null,
                React.createElement(Row, { gutter: 2 },
                    React.createElement(Col, { span: mapOrder ? 24 : 12 },
                        React.createElement(FormItem, { label: "\u53D6\u4EF6\u5907\u6CE8", wrapperCol: { span: 16 } }, getFieldDecorator('remarks', {
                            initialValue: ''
                        })(React.createElement(TextArea, null))))),
                React.createElement(Row, { gutter: 2 },
                    React.createElement(Col, { span: mapOrder ? 24 : 12 },
                        React.createElement(FormItem, { label: "\u547C\u53EB\u65B9\u5F0F" }, getFieldDecorator('dispatchType', {
                            initialValue: 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择呼叫方式'
                                }
                            ]
                        })(React.createElement(RadioGroup, { onChange: this.triggerChange },
                            React.createElement(RadioButton, { value: 1 }, "\u4F4E\u4EF7\u4F18\u5148"),
                            React.createElement(RadioButton, { value: 2 }, "\u901F\u5EA6\u4F18\u5148")))))),
                this.props.order.storeType === 3 ? React.createElement(Row, { gutter: 2 },
                    React.createElement(Col, { span: mapOrder ? 24 : 12 },
                        React.createElement(FormItem, { label: "\u7269\u54C1\u4FDD\u4EF7" }, getFieldDecorator('InsuranceState', {
                            initialValue: 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择物品保价'
                                }
                            ]
                        })(React.createElement(RadioGroup, null,
                            React.createElement(RadioButton, { value: 1, onClick: function () {
                                    _this.setState({ InsuranceText: initText });
                                    _this.declaredAmountforDetail(0);
                                } }, "\u4E0D\u4FDD\u4EF7"),
                            React.createElement(RadioButton, { value: -1, onClick: function () {
                                    _this.setState({
                                        InsuranceVis: true
                                    });
                                } }, this.getText(this.state.InsuranceText))))))) : null,
                React.createElement("div", null,
                    React.createElement(FormItem, { label: "\u9009\u62E9\u914D\u9001\u5E73\u53F0", labelCol: {} }, getFieldDecorator('platform', {
                        initialValue: channelChecked,
                        rules: [
                            {
                                required: true,
                                message: '请选择配送平台'
                            }
                        ]
                    })(React.createElement(React.Fragment, null,
                        React.createElement(PlatformChooseWrapper, null, valuations && !!valuations.length
                            ? valuations.map(function (valuation, key) {
                                var platform = valuation.platform, amount = valuation.amount, balanceAmount = valuation.balanceAmount, reducedPrice = valuation.reducedPrice, errorMsg = valuation.errorMsg, errorType = valuation.errorType;
                                return (React.createElement(DeliveryPlatform, { key: key, platform: platform, amount: amount, balanceAmount: balanceAmount, reducedPrice: reducedPrice, errorMsg: errorMsg, errorType: errorType, selected: channelChecked.includes(platform), onClick: function () {
                                        return _this.handleSelectPlatformItem(valuation);
                                    } }));
                            })
                            : null),
                        this.props.isDisabled && (React.createElement(Button, { style: { background: '#01CCCC', color: 'white' }, onClick: function () {
                                if (_this.props.history) {
                                    _this.props.history.push('/stores/delivery_platform');
                                }
                            } }, "\u7ED1\u5B9A\u914D\u9001\u5E73\u53F0"))))))),
            React.createElement(InsuranceComponent, { ref: this.Insuranceref, visible: this.state.InsuranceVis, price: lodash.get(this.props, 'order.totalPrice', 0), onOk: this.onOk, onCancel: this.onCancel })));
    };
    return SendDeliveryForm;
}(React.Component));
// function is
export var WrappedSendDeliveryForm = Form.create({})(SendDeliveryForm);
