var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Form, Input, message } from 'antd';
import { ApplySmsCodeButton, InputSmsCode, SmsInputGroup } from '@components/Common';
import { requestForMerchant } from '@utils/request';
import { PHONE_REGEX } from '@utils/regex';
var FormItem = Form.Item;
var SMS_MAX_COUNTDOWN = 60;
var SmsLoginForm = /** @class */ (function (_super) {
    __extends(SmsLoginForm, _super);
    function SmsLoginForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            countdown: SMS_MAX_COUNTDOWN
        };
        _this.handleGetSMSCode = function () {
            var validateFields = _this.props.form.validateFields;
            if (_this.state.countdown < SMS_MAX_COUNTDOWN)
                return false;
            validateFields(['phone'], function (errors, _a) {
                var phone = _a.phone;
                if (errors)
                    return false;
                requestForMerchant
                    .post('/merchant/account/sendSmsCodeForLogin', { mobile: phone })
                    .then(function (back) {
                    if (back.code === 0) {
                        _this.startCountdownTimer();
                    }
                    else {
                        message.error(back.err);
                    }
                });
            });
        };
        return _this;
    }
    SmsLoginForm.prototype.startCountdownTimer = function () {
        var _this = this;
        var countdownTimer = setInterval(function () {
            var currCountdown = _this.state.countdown;
            if (currCountdown && currCountdown <= SMS_MAX_COUNTDOWN) {
                _this.setState({ countdown: currCountdown - 1 });
            }
            else {
                clearInterval(countdownTimer);
                _this.setState({ countdown: SMS_MAX_COUNTDOWN });
            }
        }, 1000);
    };
    SmsLoginForm.prototype.render = function () {
        var getFieldDecorator = this.props.form.getFieldDecorator;
        var countdown = this.state.countdown;
        var handleSubmit = this.props.handleSubmit;
        return (React.createElement(Form, null,
            React.createElement(FormItem, null, getFieldDecorator('phone', {
                rules: [
                    {
                        required: true,
                        pattern: PHONE_REGEX,
                        message: '请输入正确的手机号'
                    }
                ]
            })(React.createElement(Input, { placeholder: "\u624B\u673A\u53F7", onPressEnter: handleSubmit }))),
            React.createElement(FormItem, null, getFieldDecorator('verificationCode', {
                rules: [
                    {
                        required: true,
                        message: '请输入验证码'
                    }
                ]
            })(React.createElement(SmsInputGroup, null,
                React.createElement(InputSmsCode, { placeholder: "\u9A8C\u8BC1\u7801", onPressEnter: handleSubmit }),
                React.createElement(ApplySmsCodeButton, { onClick: this.handleGetSMSCode }, countdown && countdown < SMS_MAX_COUNTDOWN
                    ? countdown + "S"
                    : '获取验证码'))))));
    };
    return SmsLoginForm;
}(React.Component));
var WrappedSmsLoginForm = Form.create({})(SmsLoginForm);
export default WrappedSmsLoginForm;
