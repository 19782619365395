var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Form, Input } from 'antd';
var FormItem = Form.Item;
var TextArea = Input.TextArea;
var ReplyUrgedReason = /** @class */ (function (_super) {
    __extends(ReplyUrgedReason, _super);
    function ReplyUrgedReason() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ReplyUrgedReason.prototype.render = function () {
        var getFieldDecorator = this.props.form.getFieldDecorator;
        return (React.createElement(Form, null,
            React.createElement(FormItem, { label: "\u56DE\u590D" }, getFieldDecorator('content', {
                rules: [
                    {
                        required: true,
                        message: '请填写回复'
                    }
                ]
            })(React.createElement(TextArea, { placeholder: "\u8BF7\u8F93\u5165\u56DE\u590D\u5185\u5BB9" })))));
    };
    return ReplyUrgedReason;
}(React.Component));
export var ReplyUrgedReasonForm = Form.create({})(ReplyUrgedReason);
