var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import * as React from 'react';
import { Button, Form, Input, InputNumber, Table } from 'antd';
import styled from 'styled-components';
var AddGoodWrapper = styled.div.withConfig({ displayName: "AddGoodWrapper", componentId: "sc-1kveu88" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 5px;\n  text-align: right;\n"], ["\n  margin-bottom: 5px;\n  text-align: right;\n"])));
var EditableContext = React.createContext({});
var EditableCell = /** @class */ (function (_super) {
    __extends(EditableCell, _super);
    function EditableCell() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.getInput = function () {
            if (_this.props.inputType === 'number') {
                var inputNumberProps = {
                    min: 0
                };
                if (_this.props.ignoreDecimal) {
                    Object.assign(inputNumberProps, {
                        parser: function (value) { return Math.floor(Number(value)); }
                    });
                }
                return React.createElement(InputNumber, __assign({}, inputNumberProps));
            }
            return React.createElement(Input, null);
        };
        _this.renderCell = function (_a) {
            var getFieldDecorator = _a.getFieldDecorator;
            var _b = _this.props, editing = _b.editing, dataIndex = _b.dataIndex, title = _b.title, inputType = _b.inputType, record = _b.record, index = _b.index, children = _b.children, restProps = __rest(_b, ["editing", "dataIndex", "title", "inputType", "record", "index", "children"]);
            return (React.createElement("td", __assign({}, restProps), editing ? (React.createElement(Form.Item, { style: { margin: 0 } }, getFieldDecorator(dataIndex, {
                rules: [
                    {
                        required: true,
                        message: title + " \u5FC5\u586B"
                    }
                ],
                initialValue: record[dataIndex]
            })(_this.getInput()))) : (children)));
        };
        return _this;
    }
    EditableCell.prototype.render = function () {
        return (React.createElement(EditableContext.Consumer, null, this.renderCell));
    };
    return EditableCell;
}(React.Component));
var EditableTable = function (props) {
    var _a = __read(React.useState(-1), 2), editingKey = _a[0], setEditingKey = _a[1];
    var handleSave = function (form, key) {
        form.validateFields(function (error, row) {
            if (error) {
                return;
            }
            var newData = __spread(props.dataSource);
            var index = newData.findIndex(function (item) { return key === item.key; });
            if (index > -1) {
                var item = newData[index];
                newData.splice(index, 1, __assign(__assign({}, item), row));
            }
            else {
                newData.push(row);
            }
            setEditingKey(-1);
            props.onChange(newData);
        });
    };
    var handleDelete = function (index) {
        var dataSource = props.dataSource;
        var newData = dataSource.filter(function (item, key) { return index !== key; });
        props.onChange(newData);
    };
    var handleAddGood = function () {
        var newData = __spread(props.dataSource);
        var key = newData.length;
        var number = key + 1;
        newData.unshift({
            name: "\u5546\u54C1" + number,
            number: 1,
            amount: 0,
            key: key
        });
        setEditingKey(key);
        props.onChange(newData);
    };
    var components = {
        body: {
            cell: EditableCell
        }
    };
    var isEditing = function (record) { return record.key === editingKey; };
    var columns = props.columns
        .map(function (col) {
        if (!col.editable) {
            return col;
        }
        return __assign(__assign({}, col), { onCell: function (record) { return ({
                record: record,
                inputType: col.inputType,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                ignoreDecimal: Boolean(col.ignoreDecimal)
            }); } });
    })
        .concat({
        title: '操作',
        dataIndex: 'operation',
        width: '25%',
        render: function (text, record, index) {
            var editable = isEditing(record);
            return editable ? (React.createElement("span", null,
                React.createElement(EditableContext.Consumer, null, function (form) { return (React.createElement("a", { onClick: function () { return handleSave(form, record.key); }, style: { marginRight: 8 } }, "\u4FDD\u5B58")); }),
                "\u00A0 ",
                React.createElement("a", { onClick: function () { return setEditingKey(-1); } }, "\u53D6\u6D88"))) : (React.createElement(React.Fragment, null,
                React.createElement("a", { onClick: function () { return setEditingKey(record.key); } }, "\u7F16\u8F91"),
                " \u00A0",
                React.createElement("a", { onClick: function () { return handleDelete(index); } }, "\u5220\u9664")));
        }
    });
    return (React.createElement(EditableContext.Provider, { value: props.form },
        React.createElement(AddGoodWrapper, null,
            React.createElement(Button, { onClick: handleAddGood }, "\u589E\u52A0\u5546\u54C1")),
        React.createElement(Table, { components: components, dataSource: props.dataSource, columns: columns, pagination: false, bordered: true })));
};
var EditableFormTable = Form.create()(EditableTable);
export default EditableFormTable;
var templateObject_1;
