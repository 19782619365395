var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Form } from 'antd';
import { DELIVERY_PLATFORM_MAP } from '@utils/constants';
import { ShansongServiceOption } from './BindForm';
var formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 }
};
var EditServiceForm = /** @class */ (function (_super) {
    __extends(EditServiceForm, _super);
    function EditServiceForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderService = function () {
            var _a = _this.props.initialValue, platformType = _a.platformType, platformPrivate = _a.platformPrivate;
            var getFieldDecorator = _this.props.form.getFieldDecorator;
            var props = {
                getFieldDecorator: getFieldDecorator,
                initialValue: platformPrivate
            };
            switch (platformType) {
                case DELIVERY_PLATFORM_MAP.SHANSONG:
                    return React.createElement(ShansongServiceOption, __assign({}, props));
                default:
                    return null;
            }
        };
        return _this;
    }
    EditServiceForm.prototype.render = function () {
        return React.createElement(Form, __assign({}, formItemLayout), this.renderService());
    };
    return EditServiceForm;
}(React.PureComponent));
var WrappedEditServiceForm = Form.create()(EditServiceForm);
export default WrappedEditServiceForm;
