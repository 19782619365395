var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, InputNumber, message } from 'antd';
import { Formik } from 'formik';
import styled from 'styled-components';
import { FormFooter } from '@components/FormModal';
import { useToggle } from '@hooks';
import ajax from '@utils/ajax';
// const initialValues = {
//   isBigAmountReceive: false,
//   isBigAmountSend: false,
//   bigAmount: 0,
//   bigCommodityNumber: 0
// }
var editOrderStrategyRequest = function (data) {
    return ajax.post('/store/edit/order/strategy', data);
};
var FlexWrapper = styled.div.withConfig({ displayName: "FlexWrapper", componentId: "sc-1rzw16y" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var InputNumberSmall = styled(InputNumber).withConfig({ displayName: "InputNumberSmall", componentId: "sc-1qfw50y" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  && {\n    margin: 0 4px;\n    width: 100px;\n  }\n"], ["\n  && {\n    margin: 0 4px;\n    width: 100px;\n  }\n"])));
var ChooseGroup = styled.div.withConfig({ displayName: "ChooseGroup", componentId: "sc-1lrp48" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 10px;\n"], ["\n  margin-top: 10px;\n"])));
var Tips = styled.div.withConfig({ displayName: "Tips", componentId: "sc-ps0ole" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 10px;\n"], ["\n  margin-top: 10px;\n"])));
var Tip = styled.p.withConfig({ displayName: "Tip", componentId: "sc-1ayqd5v" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 0;\n"], ["\n  margin: 0;\n"])));
var mapDispatch = function (dispatch) { return ({
    getStores: dispatch.stores.fetchData
}); };
var EditOrderStrategyForm = function (props) {
    var initialValues = props.initialValues, toggleEditOrderStrategy = props.toggleEditOrderStrategy;
    var dispatch = useDispatch();
    var getStores = mapDispatch(dispatch).getStores;
    var _a = __read(useToggle(false), 3), confirmLoading = _a[0], startConfirmLoading = _a[1], stopConfirmLoading = _a[2];
    var onSubmit = function (values) {
        startConfirmLoading();
        var isBigAmountReceive = values.isBigAmountReceive ? 0 : 1;
        var isBigAmountSend = values.isBigAmountSend ? 0 : 1;
        var isShowPicture = values.isShowPicture ? 1 : 0;
        editOrderStrategyRequest({
            storeId: values.id,
            bigAmount: values.bigAmount,
            bigCommodityNumber: values.bigCommodityNumber,
            isBigAmountReceive: isBigAmountReceive,
            isBigAmountSend: isBigAmountSend,
            isShowPicture: isShowPicture
        }).then(function () {
            stopConfirmLoading();
            toggleEditOrderStrategy();
            message.success('编辑订单策略成功');
            getStores({});
        }, function () {
            stopConfirmLoading();
        });
    };
    return (React.createElement(Formik, { initialValues: initialValues, onSubmit: onSubmit }, function (_a) {
        var values = _a.values, setFieldValue = _a.setFieldValue, submitForm = _a.submitForm;
        return (React.createElement(React.Fragment, null,
            React.createElement("p", null,
                "\u95E8\u5E97\u4FE1\u606F\uFF1A",
                initialValues.name,
                " - ",
                initialValues.numbering),
            React.createElement("div", null,
                React.createElement(FlexWrapper, null,
                    "\u5927\u989D\u8BA2\u5355\u5B9A\u4E49\uFF1A \u8BA2\u5355\u91D1\u989D\u5927\u4E8E",
                    React.createElement(InputNumberSmall, { onChange: function (value) { return setFieldValue('bigAmount', value); }, value: values.bigAmount, min: 0 }),
                    "\u5143 \u6216 \u5546\u54C1\u6570\u91CF\u5927\u4E8E",
                    React.createElement(InputNumberSmall, { onChange: function (value) { return setFieldValue('bigCommodityNumber', value); }, value: values.bigCommodityNumber, min: 0 }),
                    "\u4E2A")),
            React.createElement(ChooseGroup, null,
                React.createElement(Checkbox, { onChange: function (_a) {
                        var checked = _a.target.checked;
                        return setFieldValue('isBigAmountReceive', checked);
                    }, checked: values.isBigAmountReceive }, "\u5927\u989D\u8BA2\u5355\u4E0D\u81EA\u52A8\u63A5\u5355"),
                React.createElement(Checkbox, { onChange: function (_a) {
                        var checked = _a.target.checked;
                        return setFieldValue('isBigAmountSend', checked);
                    }, checked: values.isBigAmountSend }, "\u5927\u989D\u8BA2\u5355\u4E0D\u81EA\u52A8\u914D\u9001"),
                React.createElement(Checkbox, { onChange: function (_a) {
                        var checked = _a.target.checked;
                        return setFieldValue('isShowPicture', checked);
                    }, checked: values.isShowPicture }, "\u8BA2\u5355\u8BE6\u60C5\u663E\u793A\u5546\u54C1\u56FE\u7247")),
            React.createElement(Tips, null,
                React.createElement(Tip, null, "\u6CE8\uFF1A"),
                React.createElement(Tip, null, "1\u3001\u95E8\u5E97\u521D\u59CB\u5316\u662F\u6240\u6709\u95E8\u5E97\u90FD\u662F \u624B\u5DE5\u63A5\u5355\u3001\u624B\u5DE5\u914D\u9001\u7684\u3002"),
                React.createElement(Tip, null, "2\u3001\u81EA\u52A8\u63A5\u5355\u3001\u81EA\u52A8\u914D\u9001\u6A21\u5F0F\u4E0B\uFF0C\u5927\u989D\u8BA2\u5355\u4E5F\u662F\u81EA\u52A8\u63A5\u5355\u3001\u81EA\u52A8\u914D\u9001\u7684\u3002"),
                React.createElement(Tip, null, "3\u3001\u81EA\u52A8\u63A5\u5355\u3001\u81EA\u52A8\u914D\u9001\u6A21\u5F0F\u4E0B\uFF0C\u52FE\u9009\u3010\u5927\u989D\u8BA2\u5355\u4E0D\u81EA\u52A8\u63A5\u5355\u3011\u3001\u3010\u5927\u989D\u8BA2\u5355\u4E0D\u81EA\u52A8\u914D\u9001\u3011\uFF0C\u9664\u5927\u989D\u8BA2\u5355\u5916\u7684\u8BA2\u5355\u624D\u662F\u81EA\u52A8\u63A5\u5355\u3001\u81EA\u52A8\u914D\u9001\u7684\u3002"),
                React.createElement(Tip, null, "4\u3001\u6709\u6536\u94F6\u7CFB\u7EDF\u540C\u65F6\u5BF9\u63A5\u7F8E\u56E2\u7684\u95E8\u5E97\uFF0C\u4E0D\u652F\u6301\u672C\u7CFB\u7EDF\uFF08\u81EA\u52A8\uFF09\u63A5\u5355\u3002")),
            React.createElement(FormFooter, { confirmLoading: confirmLoading, onCancel: toggleEditOrderStrategy, onOk: submitForm })));
    }));
};
export default EditOrderStrategyForm;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
