var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/*
 * @Author: hijack
 * @Date: 2021-06-18 20:26:48
 * @LastEditTime: 2021-07-14 15:08:41
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/container/Orders/form/SendDeliveryFormPlatform.tsx
 */
import React, { useEffect, useState } from 'react';
import { Col, Form, Input, InputNumber, Radio, Row, Icon as AIcon, message } from 'antd';
import moment from 'moment';
import { SendDeliveryCouponContext } from '../context';
import { requestForCoupon } from '@utils/request';
import ReservationTime from '@components/ReservationTime';
import CouponListModal from '../modal/CouponListModal';
import RechargeModal from '../modal/RechargeModal';
import { SBalance, SPayAmountWrapper } from './styled';
import OrderMap from '../components/OrderMap';
import { addMoneyInfo } from './SendDeliveryCalculate';
var FormItem = Form.Item;
var RadioButton = Radio.Button;
var RadioGroup = Radio.Group;
var TextArea = Input.TextArea;
var formItemLayout = {
    labelCol: { span: 7 }
};
var SendDeliveryFormPlatform = function (_a) {
    var order = _a.order, oneByMoreData = _a.oneByMoreData, globalDistance = _a.globalDistance, form = _a.form, triggerChange = _a.triggerChange, handleTipChange = _a.handleTipChange, platformInit = _a.platformInit, mapOrder = _a.mapOrder, setoneByMoreFormRef = _a.setoneByMoreFormRef;
    var _b = __read(useState(false), 2), visible = _b[0], setvisible = _b[1];
    var _c = __read(useState([]), 2), couponData = _c[0], setcouponData = _c[1];
    var _d = __read(useState(-1), 2), globalCouponId = _d[0], setglobalCouponId = _d[1];
    var _e = __read(useState('系统推荐最低价'), 2), globalCouponName = _e[0], setglobalCouponName = _e[1];
    var _f = __read(useState(true), 2), isGlobalCouponModal = _f[0], setisGlobalCouponModal = _f[1];
    var _g = __read(useState(false), 2), rechargeVisible = _g[0], setrechargeVisible = _g[1];
    useEffect(function () {
        if (oneByMoreData && oneByMoreData.length > 1) {
            handleTriggerChange();
            setoneByMoreFormRef(form);
        }
    }, [oneByMoreData]);
    var handleFetchCoupon = function (_a) {
        var expressPartnerId = _a.expressPartnerId, cityCode = _a.cityCode, callback = _a.callback;
        requestForCoupon
            .get('/coupon/listCanUseCoupons', {
            distance: globalDistance,
            cityCode: cityCode,
            expressPartnerId: expressPartnerId
        })
            .then(function (back) {
            if (back.data) {
                setcouponData(back.data);
                if (callback) {
                    callback();
                }
            }
            else {
                message.error(back.err);
            }
        });
    };
    var toggleVisible = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setvisible(!visible);
            return [2 /*return*/];
        });
    }); };
    var toggleRechargeVisible = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            addMoneyInfo();
            return [2 /*return*/];
        });
    }); };
    var handleOpenCouponModal = function (_a) {
        var isGlobalCouponModal = _a.isGlobalCouponModal, expressPartnerId = _a.expressPartnerId, cityCode = _a.cityCode;
        setisGlobalCouponModal(isGlobalCouponModal);
        handleFetchCoupon({
            expressPartnerId: expressPartnerId,
            cityCode: cityCode,
            callback: toggleVisible
        });
    };
    var handleTriggerChange = function () {
        var changeTimer = setImmediate(function () {
            var values = form.getFieldsValue();
            triggerChange && triggerChange(values);
            clearImmediate(changeTimer);
        });
    };
    var handleCalculate = function (_a) {
        var selectCouponItem = _a.selectCouponItem, callback = _a.callback;
        var values = form.getFieldsValue();
        if (isGlobalCouponModal) {
            setglobalCouponId(selectCouponItem.id);
            setglobalCouponName(selectCouponItem.name);
        }
        var useCoupon = true;
        var expressPartnerCouponInfos = [];
        if (selectCouponItem.id === 0) {
            useCoupon = false;
        }
        expressPartnerCouponInfos.push({
            expressPartnerId: (valuations && valuations[0].platform) || '-1',
            couponGroupId: selectCouponItem.id
        });
        triggerChange &&
            triggerChange({
                useCoupon: useCoupon,
                expressPartnerCouponInfos: expressPartnerCouponInfos
            }, function () {
                callback && callback();
            });
    };
    var maxPayAmount = platformInit.maxPayAmount, balance = platformInit.balance, valuations = platformInit.valuations;
    var getFieldDecorator = form.getFieldDecorator, getFieldValue = form.getFieldValue;
    var showDateTimePicker = getFieldValue('appointType') === 1;
    return (React.createElement(Form, __assign({}, formItemLayout),
        React.createElement(Row, { gutter: 2 },
            React.createElement(Col, { span: mapOrder ? 24 : 12 },
                React.createElement(FormItem, { label: "\u53D6\u4EF6\u65F6\u95F4" }, getFieldDecorator('appointType', {
                    initialValue: 0,
                    rules: [
                        {
                            required: true
                        }
                    ]
                })(React.createElement(RadioGroup, { onChange: handleTriggerChange },
                    React.createElement(RadioButton, { value: 0 }, "\u7ACB\u5373\u53D6\u4EF6"),
                    React.createElement(RadioButton, { value: 1 }, "\u9884\u7EA6\u53D6\u4EF6"))))),
            showDateTimePicker && OrderMap ? (React.createElement(Col, { span: 24 },
                React.createElement(FormItem, { label: "\u9884\u7EA6\u65F6\u95F4" }, getFieldDecorator('reservationTime', {
                    initialValue: moment(moment()
                        .add(2, 'h')
                        .add(10, 'm'), 'YYYY-MM-DD HH:mm'),
                    rules: [
                        {
                            required: true,
                            message: '请选择预约时间'
                        }
                    ]
                })(React.createElement(ReservationTime, { width: "66%", triggerChange: handleTriggerChange }))))) : null,
            React.createElement(Col, { span: mapOrder ? 24 : 12 },
                React.createElement(FormItem, { label: "\u5C0F\u8D39" }, getFieldDecorator('tip', {
                    initialValue: 0
                })(React.createElement(InputNumber, { min: 0, formatter: function (value) { return value + "\u5143"; }, parser: function (value) { return (value ? value.replace('元', '') : ''); }, onChange: handleTriggerChange }))))),
        showDateTimePicker && !OrderMap ? (React.createElement(Row, { gutter: 2 },
            React.createElement(Col, { span: mapOrder ? 24 : 19, pull: 2 },
                React.createElement(FormItem, { label: "\u9884\u7EA6\u65F6\u95F4" }, getFieldDecorator('reservationTime', {
                    initialValue: moment(moment()
                        .add(2, 'h')
                        .add(10, 'm'), 'YYYY-MM-DD HH:mm'),
                    rules: [
                        {
                            required: true,
                            message: '请选择预约时间'
                        }
                    ]
                })(React.createElement(ReservationTime, { triggerChange: handleTriggerChange })))))) : null,
        React.createElement(Row, { gutter: 2 },
            React.createElement(Col, { span: mapOrder ? 24 : 12 },
                React.createElement(FormItem, { label: "\u53D6\u4EF6\u5907\u6CE8", wrapperCol: { span: 16 } }, getFieldDecorator('remarks', {
                    initialValue: ''
                })(React.createElement(TextArea, null))))),
        React.createElement(Row, { gutter: 1 },
            React.createElement(Col, { span: mapOrder ? 24 : 12 },
                React.createElement(FormItem, { label: "\u5E73\u53F0\u4F18\u60E0\u5238", wrapperCol: { span: 16 } }, getFieldDecorator('platformCoupon', {
                    initialValue: { globalCouponId: globalCouponId }
                })(React.createElement("div", { onClick: function () {
                        return handleOpenCouponModal({
                            isGlobalCouponModal: true,
                            expressPartnerId: (valuations && valuations[0].platform) || '',
                            cityCode: (valuations && valuations[0].cityCode) || ''
                        });
                    } },
                    globalCouponName,
                    React.createElement(AIcon, { type: "right", style: { fontSize: '12px', color: '#333333' } })))))),
        React.createElement(Row, { gutter: 1 },
            React.createElement(Col, { span: mapOrder ? 24 : 12 },
                React.createElement(FormItem, null,
                    React.createElement(SPayAmountWrapper, null,
                        React.createElement("div", { className: "ant-col-7" }, "\u5E94\u4ED8\uFF1A"),
                        React.createElement("div", null,
                            React.createElement("span", null,
                                "(\u4F18\u60E0\uFF1A",
                                (valuations[0] && valuations[0].reducedPrice / 100) ||
                                    '',
                                ' ',
                                "\u5143)"),
                            (valuations[0] &&
                                (valuations[0].amount - valuations[0].reducedPrice) / 100) ||
                                '',
                            "\u5143"))))),
        React.createElement(SBalance, null,
            React.createElement("div", { className: "top" },
                React.createElement("div", { className: "lable" }, "\u6613\u9001\u4F59\u989D\uFF1A"),
                React.createElement("div", null,
                    balance / 100 || '',
                    "\u5143"),
                React.createElement("div", { className: "recharge", onClick: toggleRechargeVisible }, "\u53BB\u5145\u503C")),
            maxPayAmount > balance && React.createElement("div", { className: "red" }, "\u4F59\u989D\u4E0D\u8DB3\uFF0C\u8BF7\u5145\u503C")),
        React.createElement(SendDeliveryCouponContext.Provider, { value: {
                handleCalculate: handleCalculate,
                couponData: couponData,
                globalCouponId: globalCouponId,
                globalCouponName: globalCouponName,
                isGlobalCouponModal: isGlobalCouponModal
            } },
            React.createElement(CouponListModal, { visible: visible, toggleVisible: toggleVisible })),
        React.createElement(RechargeModal, { visible: rechargeVisible, toggleVisible: toggleRechargeVisible })));
};
export var WrappedSendDeliveryFormOneByMore = Form.create({})(SendDeliveryFormPlatform);
