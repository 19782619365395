var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ReceiptPreviewWrapper = styled.div.withConfig({ displayName: "ReceiptPreviewWrapper", componentId: "sc-1cutasn" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px 0;\n"], ["\n  padding: 20px 0;\n"])));
export var Custom = styled.div.withConfig({ displayName: "Custom", componentId: "sc-1joiogy" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: left;\n  font-size: 16px;\n  .desc {\n    margin: 20px 0 30px;\n  }\n  .sign {\n    margin-top: 10px;\n  }\n"], ["\n  text-align: left;\n  font-size: 16px;\n  .desc {\n    margin: 20px 0 30px;\n  }\n  .sign {\n    margin-top: 10px;\n  }\n"])));
var barCode = require('../../assets/images/barcode.png').default;
export var BarCode = styled.div.withConfig({ displayName: "BarCode", componentId: "sc-d9l9cv" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0 auto;\n  width: ", ";\n  height: 70px;\n  background: url(", ") no-repeat;\n  background-size: 100%;\n"], ["\n  margin: 0 auto;\n  width: ", ";\n  height: 70px;\n  background: url(", ") no-repeat;\n  background-size: 100%;\n"])), function (props) { return (props.size === 'big' ? '322px' : '250px'); }, barCode);
export var QRCode = styled.div.withConfig({ displayName: "QRCode", componentId: "sc-mebsqs" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 20px auto;\n  width: 200px;\n  height: 200px;\n  background: url(", ") no-repeat;\n  background-size: 100%;\n"], ["\n  margin: 20px auto;\n  width: 200px;\n  height: 200px;\n  background: url(", ") no-repeat;\n  background-size: 100%;\n"])), function (props) { return props.src; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
