var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Select } from 'antd';
// import { OrderPlatform } from '../context'
var Option = Select.Option;
var OrderPlatformSelect = function (_a) {
    var data = _a.data, props = __rest(_a, ["data"]);
    return (React.createElement(Select, __assign({ style: { width: '180px ', margin: '0 12px 20px 0' }, defaultValue: "" }, props),
        React.createElement(Option, { key: "0", value: "" }, "\u5168\u90E8\u63A5\u5355\u5E73\u53F0"),
        data.map(function (orderPlatform) { return (React.createElement(Option, { key: orderPlatform.code, value: orderPlatform.code }, orderPlatform.name)); })));
};
export default OrderPlatformSelect;
