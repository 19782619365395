var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { forwardRef, useEffect, useState } from 'react';
import { Modal, Form, Input, Button } from 'antd4';
import "./optionModal.less";
import MerchantComponent from './merchant';
import PermisoComponent from './permiso';
import CommonHeader from '@components/ModalHeader/commonModalHeader';
import { addStaff, getBelongStoreMsg, updStaff } from '@/apis/generalNumber';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { getMENU } from '@/layout/DefaultLayout';
import { useDispatch } from 'react-redux';
var mapDispatch = function (dispatch) { return ({
    updateMenuEffect: dispatch.merchant.updateMenuEffect,
}); };
var AddModal = function (props, ref) {
    var _a = __read(Form.useForm(), 1), form = _a[0];
    var history = useHistory();
    // const [visible,setVisible]=React.useState(-1000);
    var _b = __read(useState({}), 2), merchatData = _b[0], setMerchantData = _b[1];
    var updateMenuEffect = mapDispatch(useDispatch()).updateMenuEffect;
    var initValue = {
        userName: null,
        phone: null,
        belongStoreType: { belongStoreType: 0, belongStore: null },
        roleId: { roleId: 1, menuArray: [] },
    };
    useEffect(function () {
        getBelongStoreMsg().then(function (res) {
            var _a;
            setMerchantData(convertMerchantData(res));
            if ((_a = props.initData) === null || _a === void 0 ? void 0 : _a.id) {
                var editValue = __assign(__assign({}, props.initData), { belongStoreType: { belongStoreType: props.initData.belongStoreType, belongStore: props.initData.belongStore.split(",") }, roleId: { roleId: props.initData.roleId, menuArray: props.initData.menuArray.split(",") } });
                form.setFieldsValue(editValue);
                // console.log("编辑");
            }
            else {
                form.setFieldsValue(initValue);
                // console.log("添加");
            }
        });
    }, []);
    function convertMerchantData(data) {
        var newData = __assign({}, data);
        return {
            belongStoreCityMsgDtoList: JSON.parse(JSON.stringify(newData.belongStoreCityMsgDtoList).replaceAll("cityName", "name").replaceAll("cityId", "ID")),
            storeList: JSON.parse(JSON.stringify(newData.storeList).replaceAll("id", "ID"))
        };
    }
    // useImperativeHandle(ref,()=>setVisible)
    var merchantRef = React.useRef(null);
    var permisoRef = React.useRef(null);
    return (React.createElement("div", null, props.visible !== -1 &&
        React.createElement(Modal, { visible: true, className: 'modalClass', footer: null, closable: false, width: "70vw", destroyOnClose: true },
            React.createElement(CommonHeader, { title: getTitle(), onCancel: function () { return onCancel(); } }),
            React.createElement(Form, { form: form, labelAlign: 'left', labelCol: { span: 3 }, wrapperCol: { span: 20 } },
                React.createElement(Form.Item, { label: "\u4EBA\u5458\u59D3\u540D", name: "userName", rules: [{ required: true, message: "请输入人员姓名" }] },
                    React.createElement(Input, { placeholder: '\u771F\u5B9E\u59D3\u540D' })),
                React.createElement(Form.Item, { label: "\u767B\u5F55\u624B\u673A\u53F7", name: "phone", rules: [{ required: true, message: "请输入登录账号" }, { required: true, message: "请输入正确手机号", pattern: /^\d{11}$/, }] },
                    React.createElement(Input, { placeholder: '\u8BF7\u8F93\u5165\u5458\u5DE5\u624B\u673A\u53F7\uFF0C\u5C06\u4F5C\u4E3A\u767B\u5F55\u8D26\u53F7' })),
                React.createElement(Form.Item, { label: "\u6240\u5C5E\u95E8\u5E97", name: "belongStoreType", rules: [{ required: true, message: "请选择归属门店" }] },
                    React.createElement(MerchantComponent, { ref: merchantRef, merchatData: merchatData })),
                React.createElement(Form.Item, { label: "\u89D2\u8272\u6743\u9650", name: "roleId", rules: [{ required: true, message: "请选择角色权限" }] },
                    React.createElement(PermisoComponent, { ref: permisoRef, authPeople: (props === null || props === void 0 ? void 0 : props.authPeople) || [], visible: props.visible }))),
            React.createElement("div", { className: 'buttonstyle' },
                React.createElement(Button, { className: 'cancel', onClick: onCancel }, "\u53D6\u6D88"),
                React.createElement(Button, { className: 'onok', type: 'primary', onClick: function () { onVaild(); } }, "\u4FDD\u5B58")))));
    function getTitle() {
        return getText("人员账号");
    }
    function getText(str) {
        return (props.visible === 0 ? '添加' : '编辑') + str;
    }
    function onCancel() {
        // setVisible(-1);
        props.onCancel();
    }
    function onVaild() {
        var _this = this;
        form.validateFields().then(function () {
            var values = __assign(__assign({}, form.getFieldsValue()), { mobile: form.getFieldsValue().phone, belongStore: merchantRef.current.belongStore, belongStoreType: merchantRef.current.belongStoreType, menuArray: permisoRef.current.menuArray, roleId: permisoRef.current.roleId });
            delete values.phone;
            var params = props.visible === 0 ? values : __assign(__assign({}, values), { id: props.initData.id, accountId: props.initData.accountId });
            var funcHandle = props.visible === 0 ? addStaff : updStaff;
            funcHandle(params).then(function (res) { return __awaiter(_this, void 0, void 0, function () {
                var optionID, menu, DEFAULTmenu, newMenu;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(res.code === 0)) return [3 /*break*/, 6];
                            if (!(props.visible > 0)) return [3 /*break*/, 4];
                            optionID = JSON.parse(localStorage.getItem("lastChooseMerchant") || "{}").accountId;
                            if (!(Number(optionID) === props.initData.accountId)) return [3 /*break*/, 2];
                            menu = JSON.parse(localStorage.getItem("defaultMenu") || "[]");
                            DEFAULTmenu = menu.filter(function (item) {
                                return permisoRef.current.menuArray.indexOf(item.menuId) > -1;
                            });
                            localStorage.setItem('defaultMenu', JSON.stringify(DEFAULTmenu));
                            return [4 /*yield*/, updateMenuEffect(DEFAULTmenu)];
                        case 1:
                            _a.sent();
                            if (JSON.stringify(DEFAULTmenu).indexOf("账号管理") === -1) {
                                newMenu = getMENU(DEFAULTmenu);
                                if (newMenu.length > 0) {
                                    history.replace(newMenu[0].path);
                                }
                                else {
                                    props.noAuthRef.current(true);
                                }
                            }
                            return [3 /*break*/, 3];
                        case 2:
                            onCancel();
                            props.onRefresh();
                            _a.label = 3;
                        case 3: return [3 /*break*/, 5];
                        case 4:
                            onCancel();
                            props.onRefresh();
                            _a.label = 5;
                        case 5:
                            message.success(getText("成功"));
                            return [3 /*break*/, 7];
                        case 6:
                            message.error(res.err);
                            _a.label = 7;
                        case 7: return [2 /*return*/];
                    }
                });
            }); });
        });
    }
};
export default forwardRef(AddModal);
