var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { message } from 'antd';
import moment from 'moment';
import lodash from 'lodash';
import { StoreOverlayOrderMap, OrderLabelOverlay, } from '@utils/map';
import { ORDER_PLATFORM_MAP } from '@utils/constants';
import orderMapStoreIcon from '@assets/images/orderMapStore.png';
import SearchOneByMore from '../form/SearchOneByMore';
import { SOrderMapWrapper } from './styled';
import RoadType from './RoadType';
import plusIcon from '@assets/images/plus.png';
import minusIcon from '@assets/images/minus.png';
var BMap = window.BMap, BMAP_ANCHOR_TOP_RIGHT = window.BMAP_ANCHOR_TOP_RIGHT, BMAP_NAVIGATION_CONTROL_ZOOM = window.BMAP_NAVIGATION_CONTROL_ZOOM;
function useCallbackState(state) {
    var cbRef = useRef();
    var _a = __read(useState(state), 2), data = _a[0], setData = _a[1];
    useEffect(function () {
        cbRef.current && cbRef.current(data);
    }, [data]);
    return [
        data,
        function (val, callback) {
            cbRef.current = callback;
            setData(val);
        }
    ];
}
var map = null;
var pointArr = [];
var riding = null;
var polylines = [];
var OrderMap = function (props, ref) {
    var list = props.list, handleReceiverClick = props.handleReceiverClick, oneByMoreData = props.oneByMoreData, _a = props.initMapStore, initMapStore = _a === void 0 ? [] : _a, onSubmit = props.onSubmit, handleSwitchRoadType = props.handleSwitchRoadType, routeType = props.routeType, createOrderStoreId = props.createOrderStoreId;
    useEffect(function () {
        initialMap();
    }, [list]);
    useEffect(function () {
        if (map && riding) {
            handleDrawPath();
        }
    }, [oneByMoreData.length > 0 && document.getElementById(oneByMoreData[oneByMoreData.length - 1].id)]);
    var addOrderLabelOverlay = function (point, sn, time, distance, order, actived) {
        var labelOverlay = new OrderLabelOverlay({
            center: point,
            sn: sn,
            time: time,
            distance: distance,
            click: function () { return handleReceiverClick(order); },
            top: 24,
            actived: actived,
            orderId: order.id,
        });
        if (map) {
            map.addOverlay(labelOverlay);
        }
    };
    var renderDeliveryTime = function (order) {
        var _a;
        if (order.platform === ORDER_PLATFORM_MAP.EBAI &&
            order.reservationStartTime) {
            return moment(moment(order.deliverTime).valueOf()).format('HH:mm');
        }
        else if (((_a = order.selfFetch) === null || _a === void 0 ? void 0 : _a.code) === 0) {
            return '自取';
        }
        else {
            if (order.deliverTime.includes('立即送达')) {
                return '立即';
            }
            else {
                return moment(moment(order.deliverTime).valueOf()).format('HH:mm');
            }
        }
    };
    var initialMap = function () { return __awaiter(void 0, void 0, void 0, function () {
        var storeDatas, storeIds, j, storeGEO, storePoint, i, receiveGEO, receivePoint, j, storeGEO, storePoint, storeIcon;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    pointArr = [];
                    storeDatas = [];
                    storeIds = [];
                    map = new BMap.Map('orderMap', {
                        enableMapClick: false,
                    });
                    // map.disableDoubleClickZoom()
                    map.disablePinchToZoom();
                    map.enableScrollWheelZoom(); //开启后缩放地图，上面labelOverlay会有偏移：（已修复）
                    map.addControl(new BMap.NavigationControl({
                        anchor: BMAP_ANCHOR_TOP_RIGHT,
                        type: BMAP_NAVIGATION_CONTROL_ZOOM,
                        offset: new BMap.Size(30, 230)
                    }));
                    // map.setMapStyle({style:'light'})
                    if (initMapStore.length > 0 && list.length === 0) {
                        message.config({
                            top: 500,
                            duration: 3,
                            maxCount: 1,
                        });
                        message.info('暂无待配送订单');
                        for (j = 0; j < initMapStore.length; j++) {
                            if (initMapStore[j].latitudeLongitude) {
                                storeGEO = initMapStore[j].latitudeLongitude.split(',');
                                storePoint = new BMap.Point(storeGEO[0], storeGEO[1]);
                                // const storeIcon = new StoreOverlayOrderMap(
                                //   storePoint,
                                //   orderMapStoreIcon,
                                //   () => {}
                                // )
                                // map.addOverlay(storeIcon)
                                pointArr.push(storePoint);
                            }
                        }
                    }
                    for (i = 0; i < list.length; i++) {
                        receiveGEO = list[i].receiveCoordinate.split(',');
                        receivePoint = new BMap.Point(receiveGEO[0], receiveGEO[1]);
                        addOrderLabelOverlay(receivePoint, lodash.get(list[i], getSn(list[i].platform)).toString(), renderDeliveryTime(list[i]), list[i].distance + "km", list[i], false);
                        pointArr.push(receivePoint);
                        if (!storeIds.includes(list[i].storeId)) {
                            storeDatas.push(list[i]);
                            storeIds.push(list[i].storeId);
                        }
                    }
                    for (j = 0; j < storeDatas.length; j++) {
                        if (storeDatas[j].storeCoordinate) {
                            storeGEO = storeDatas[j].storeCoordinate.split(',');
                            storePoint = new BMap.Point(storeGEO[0], storeGEO[1]);
                            storeIcon = new StoreOverlayOrderMap(storePoint, orderMapStoreIcon, function () { });
                            map.addOverlay(storeIcon);
                            pointArr.push(storePoint);
                        }
                    }
                    return [4 /*yield*/, map.setViewport(pointArr)];
                case 1:
                    _a.sent();
                    riding = new BMap.DrivingRoute(map, {
                        renderOptions: {
                            map: map,
                            autoViewport: false
                        },
                        onMarkersSet: function (routes) {
                            for (var i = 0; i < routes.length; i++) {
                                if (i === 0 || i === (routes.length - 1)) {
                                    map.removeOverlay(routes[i].marker);
                                }
                                else {
                                    map.removeOverlay(routes[i].Wm);
                                }
                            }
                        },
                        // onSearchComplete: (results: any) => {
                        //   var plan = results.getPlan(0).kk;
                        //   // 获取方案的驾车线路
                        //   console.log(plan, results, 'results')
                        // },
                        onPolylinesSet: function (routes) {
                            //设置线条样式，线路分为几段
                            polylines = routes;
                            for (var i = 0; i < routes.length; i++) {
                                routes[i].getPolyline().setStrokeWeight(6);
                                routes[i].getPolyline().setStrokeOpacity(1);
                                routes[i].getPolyline().setStrokeColor("#5298ff");
                                routes[i].getPolyline().enableMassClear();
                            }
                        },
                    });
                    if (list.length === 0) {
                        // map.centerAndZoom(new BMap.Point(105.394062,36.831683), 5)
                        map.setZoom(5);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDrawPath = function () {
        var wayPoints = [];
        var startPoint = '';
        var endPoint = '';
        // debugger;
        oneByMoreData.map(function (item, index) {
            var _a, _b;
            (_a = document.getElementById(item.id)) === null || _a === void 0 ? void 0 : _a.removeAttribute('class');
            (_b = document
                .getElementById(item.id)) === null || _b === void 0 ? void 0 : _b.setAttribute('class', 'orderMapLabelActive');
            if (oneByMoreData.length === 1) {
                var startGEO = item.storeCoordinate.split(',');
                startPoint = new BMap.Point(startGEO[0], startGEO[1]);
                var endGEO = item.receiveCoordinate.split(',');
                endPoint = new BMap.Point(endGEO[0], endGEO[1]);
            }
            else {
                var startGEO = oneByMoreData[0].storeCoordinate.split(',');
                startPoint = new BMap.Point(startGEO[0], startGEO[1]);
                var endGEO = oneByMoreData[oneByMoreData.length - 1].receiveCoordinate.split(',');
                endPoint = new BMap.Point(endGEO[0], endGEO[1]);
                if (oneByMoreData[index - 1]) {
                    var pointGEO = oneByMoreData[index - 1].receiveCoordinate.split(',');
                    var wayPoint = new BMap.Point(pointGEO[0], pointGEO[1]);
                    wayPoints.push(wayPoint);
                }
            }
        });
        riding.disableAutoViewport();
        if (polylines.length && riding && riding.map) {
            for (var i = 0; i < polylines.length; i++) {
                try {
                    riding.map.removeOverlay(polylines[i].getPolyline());
                }
                catch (error) {
                    console.log(error);
                }
            }
        }
        // riding.map.clearOverlays()
        if (startPoint && endPoint && wayPoints.length === 0) {
            riding.search(startPoint, endPoint);
        }
        else if (startPoint && endPoint) {
            riding.search(startPoint, endPoint, { waypoints: wayPoints });
        }
    };
    return (React.createElement(SOrderMapWrapper, { plus: plusIcon, minus: minusIcon },
        React.createElement("div", { id: 'orderMap', className: "map" }),
        React.createElement(SearchOneByMore, { onSubmit: onSubmit, storeList: initMapStore, ref: ref, oneByMoreData: oneByMoreData, createOrderStoreId: createOrderStoreId }),
        React.createElement(RoadType, { routeType: routeType, handleSwitchRoadType: handleSwitchRoadType })));
};
export function getSn(platform) {
    //daySn
    // return (platform === 'manual' || platform === 'offline')?"platformDaySn":"platformDaySn"
    return "platformDaySn";
}
export default forwardRef(OrderMap);
