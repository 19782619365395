var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Button, message } from 'antd';
import ajax from '@utils/ajax';
import './button.less';
import { ORDER_STATUS_CODE_MAP } from '../constants';
import { ActionsContext } from '../context';
import { emitOrderChange } from './common';
import Reconfirm from './reconfirm';
import { getAuth } from '@/container/AccountManagement/component';
import { useSelector } from 'react-redux';
var agreeRefundOrCancelReq = function (_a) {
    var orderId = _a.orderId, platform = _a.platform;
    return ajax.post('/order/agree/refund', { orderId: orderId, platform: platform, type: 0 });
};
var AgreeRefundActioninit = /** @class */ (function (_super) {
    __extends(AgreeRefundActioninit, _super);
    function AgreeRefundActioninit(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClick = function () {
            var _a = _this.context, orderId = _a.id, platform = _a.platform, tabStatus = _a.tabStatus, orderEvent = _a.orderEvent;
            agreeRefundOrCancelReq({ orderId: orderId, platform: platform }).then(function () {
                var _a, _b;
                emitOrderChange({
                    tabStatus: tabStatus,
                    orderEvent: orderEvent,
                    orderId: orderId,
                    nextStatus: ORDER_STATUS_CODE_MAP.DONE
                });
                message.success('订单退款成功');
                //@ts-ignore
                (_b = (_a = _this.myref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.setVisible(false);
            });
        };
        _this.handleOk = function () {
            var _a, _b;
            //@ts-ignore
            (_b = (_a = _this.myref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.setVisible(true);
        };
        _this.myref = React.createRef();
        return _this;
    }
    AgreeRefundActioninit.prototype.render = function () {
        var content = this.props.text
            ? this.props.text
            : '同意后将退款，请确定是否同意';
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: this.handleOk, className: "orderbutton" }, "\u540C\u610F\u9000\u6B3E"),
            React.createElement(Reconfirm, { ref: this.myref, callback: this.handleClick, text: { title: '同意退款', content: content }, cancelText: "\u518D\u60F3\u60F3" })));
    };
    return AgreeRefundActioninit;
}(React.PureComponent));
AgreeRefundActioninit.contextType = ActionsContext;
export var AgreeRefundAction = function () {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("订单处理", menu) ? React.createElement(AgreeRefundActioninit, null) : null;
};
var AgreeRefundActionIN_THE_DISTRIBUTIONinit = function () {
    return (React.createElement(AgreeRefundActioninit, { text: '骑手已接单，取消后可能产生配送费，请确定是否同意' }));
};
export var AgreeRefundActionIN_THE_DISTRIBUTION = getAuth("订单处理") ? AgreeRefundActionIN_THE_DISTRIBUTIONinit : null;
var AgreeRefundActionDONEinit = function () {
    return React.createElement(AgreeRefundActioninit, { text: '该订单已完成配送，请确定是否同意' });
};
export var AgreeRefundActionDONE = function () {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("订单处理", menu) ? React.createElement(AgreeRefundActionDONEinit, null) : null;
};
