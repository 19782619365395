var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { Icon, message, Modal } from 'antd';
import logoEle from '@assets/images/logo-ele.png';
import ajax from '@utils/ajax';
import { Footer, Header, Logo, Main, StoreList, SubmitButton, Tips, Title, Wrapper } from './styled';
var PlatformEle = /** @class */ (function (_super) {
    __extends(PlatformEle, _super);
    function PlatformEle() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            id: null,
            stores: [],
            storeId: null,
            loading: true
        };
        _this.handleStoreClick = function (item) {
            var storeId = item.id;
            _this.setState({ storeId: storeId });
        };
        _this.renderStoreItem = function (item) {
            var storeId = _this.state.storeId;
            return (React.createElement("div", { className: "store-item", onClick: function () { return _this.handleStoreClick(item); } },
                item.name,
                storeId === item.id ? (React.createElement(Icon, { twoToneColor: "#0C9EFF", type: "check-circle", theme: "twoTone" })) : null));
        };
        _this.handleSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a, storeId, id, data_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.state, storeId = _a.storeId, id = _a.id;
                        if (!storeId)
                            return [2 /*return*/, message.error('请选择门店')];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, ajax
                                .post('/order/platform/choose/store', {
                                id: id,
                                storeId: storeId,
                                handleError: true
                            })
                                .then(function () {
                                message.success('接单平台绑定成功');
                                var closeTimer = setTimeout(function () {
                                    window.close();
                                    clearTimeout(closeTimer);
                                }, 1000);
                            })];
                    case 2:
                        _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        data_1 = _b.sent();
                        if (data_1.code === 1) {
                            Modal.success({
                                title: '绑定成功',
                                content: data_1.msg,
                                onOk: function () {
                                    var closeTimer = setTimeout(function () {
                                        window.close();
                                        clearTimeout(closeTimer);
                                    }, 1000);
                                }
                            });
                        }
                        else {
                            message.error(data_1.msg);
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        return _this;
    }
    PlatformEle.getDerivedStateFromProps = function (props, state) {
        if (props.data) {
            var _a = props.data, id = _a.id, stores = _a.stores;
            return Object.assign(state, { id: id, stores: stores, loading: false });
        }
        return state;
    };
    PlatformEle.prototype.render = function () {
        var _a = this.state, stores = _a.stores, loading = _a.loading;
        return (React.createElement(Wrapper, null,
            React.createElement(Header, null,
                React.createElement(Logo, { src: logoEle }),
                React.createElement(Title, null, "\u7ED1\u5B9A\u997F\u4E86\u4E48")),
            React.createElement(Main, null,
                React.createElement(Tips, null, "\u7CFB\u7EDF\u53D1\u73B0\u60A8\u5728\u997F\u4E86\u4E48\u5E73\u53F0\u4E0A\u6709\u591A\u4E2A\u95E8\u5E97\uFF0C\u8BF7\u9009\u62E9\u60A8\u8981\u7ED1\u5B9A\u7684\u5E97\u94FA"),
                React.createElement(StoreList, { dataSource: stores, loading: loading, renderItem: this.renderStoreItem, bordered: true }),
                React.createElement(Footer, null,
                    React.createElement(SubmitButton, { type: "primary", onClick: this.handleSubmit, block: true }, "\u5B8C\u6210")))));
    };
    return PlatformEle;
}(React.Component));
export default PlatformEle;
