var _a;
import { get } from 'lodash';
import { PrintPaperFormat } from '@models';
import { PrintCases } from '../../constants';
import { getPlatformText } from './common';
import { ORDER_PLATFORM_MAP } from '@utils/constants';
var splitGoodName = function (name, len) {
    var nameArr = [];
    var group = name.length / len;
    for (var i = 0; i < group; i++) {
        var nameItem = name.substr(0, len);
        name = name.replace(nameItem, '');
        nameArr.push(nameItem);
    }
    return nameArr;
};
var genYiLianCloudTable = function (paperFormat, goods) {
    var YiLianCloudTableHead = '<tr><td>品名</td><td>数量</td><td>金额</td></tr>';
    var genYiLianCloudTableGoods = function (paperFormat, goods) {
        var _a;
        var splitGoodNameLenMap = (_a = {},
            _a[PrintPaperFormat.Small] = 6,
            _a[PrintPaperFormat.Middle] = 8,
            _a[PrintPaperFormat.Large] = 10,
            _a);
        var goodsTplArr = goods.map(function (good) {
            var goodNameArr = splitGoodName(good.name, splitGoodNameLenMap[paperFormat]);
            return goodNameArr
                .map(function (name, index) {
                if (index === 0) {
                    return "<tr><td>" + name + "</td><td>" + good.quantity + "</td><td>" + good.total + "</td></tr>";
                }
                else {
                    return "<tr><td>" + name + "</td><td></td><td></td></tr>";
                }
            })
                .join('');
        });
        return goodsTplArr.join('');
    };
    return ('<table>' +
        YiLianCloudTableHead +
        genYiLianCloudTableGoods(paperFormat, goods) +
        '</table>');
};
export var YiLianCloudTpl = (_a = {},
    _a[PrintCases.Customer] = function (_a) {
        var paperFormat = _a.paperFormat, data = _a.data;
        var content = "<FS2><center>" + data.storeName + "</center></FS2>\r\n" +
            "<FS><center>#\u987E\u5BA2\u8054#</center></FS>\r\n" +
            ("\u63A5\u5355\u5E73\u53F0\uFF1A" + getPlatformText(data.platform) + "\r") +
            ("\u8BA2\u5355\u53F7\uFF1A" + data.platformId + "\r") +
            ("\u5E73\u53F0\u6D41\u6C34\u53F7\uFF1A" + data.platformDaySn + "\r") +
            ("\u6613\u9001\u6D41\u6C34\u53F7\uFF1A" + data.daySn + "\r") +
            ("\u4E0B\u5355\u65F6\u95F4\uFF1A" + data.orderTime + "\r") +
            ("<FS2>\u9001\u8FBE\u65F6\u95F4\uFF1A" + data.deliverTime + "</FS2>\r") +
            ("<FS2>\u5730\u5740\uFF1A" + data.consigneeAddress + "</FS2>\r") +
            ("<FS2>\u59D3\u540D\uFF1A" + data.consignee + "</FS2>\r") +
            ("<FS2>\u7535\u8BDD\uFF1A" + data.consigneePhone + "</FS2>\r") +
            ("<FS2>\u5907\u6CE8\uFF1A" + get(data, 'description', '') + "</FS2>\r\n") +
            ("<FS2>\u53D1\u7968\uFF1A" + data.invoiceTitle + " " + data.invoiceId + "</FS2>\r\n\n") +
            ("<FS>" + genYiLianCloudTable(paperFormat, data.goods) + "</FS>\r\n") +
            ("\u9910\u76D2\u8D39\uFF1A" + data.packageFee + "\u5143\r") +
            ("\u914D\u9001\u8D39\uFF1A" + data.deliverFee + "\u5143\r\n") +
            ("<right>\u539F\u4EF7\uFF1A" + data.originalPrice + "\u5143</right>\r") +
            ("<right>\u603B\u4F18\u60E0\u91D1\u989D\uFF1A" + data.reducedPrice + "\u5143</right>\r") +
            ("<right>\u5728\u7EBF\u652F\u4ED8\uFF1A" + data.totalPrice + "\u5143</right>\r\n") +
            ("<center><BR>" + data.id + "</BR></center>\r\n") +
            ("<center>\u6613\u9001\u8BA2\u5355\u53F7:" + data.id + "</center>\r\n");
        if (data.platform === ORDER_PLATFORM_MAP.MEITUAN) {
            content +=
                "<center><BR2>" + data.meituanCode + "</BR2></center>\r\n" +
                    ("<center>\u7F8E\u56E2\u8BA2\u5355\u53F7:" + data.meituanCode + "</center>\r\n");
        }
        return content;
    },
    _a[PrintCases.Merchant] = function (_a) {
        var paperFormat = _a.paperFormat, data = _a.data;
        var content = "<FS2><center>" + data.storeName + "</center></FS2>\r\n" +
            "<FS><center>#\u5546\u6237\u8054#</center></FS>\r\n" +
            ("\u63A5\u5355\u5E73\u53F0\uFF1A" + getPlatformText(data.platform) + "\r") +
            ("\u8BA2\u5355\u53F7\uFF1A" + data.platformId + "\r") +
            ("\u5E73\u53F0\u6D41\u6C34\u53F7\uFF1A" + data.platformDaySn + "\r") +
            ("\u6613\u9001\u6D41\u6C34\u53F7\uFF1A" + data.daySn + "\r") +
            ("\u4E0B\u5355\u65F6\u95F4\uFF1A" + data.orderTime + "\r") +
            ("\u9001\u8FBE\u65F6\u95F4\uFF1A" + data.deliverTime + "\r") +
            ("\u5730\u5740\uFF1A" + data.consigneeAddress + "\r") +
            ("\u59D3\u540D\uFF1A" + data.consignee + "\r") +
            ("\u7535\u8BDD\uFF1A" + data.consigneePhone + "\r") +
            ("<FS2>\u5907\u6CE8\uFF1A" + get(data, 'description', '') + "</FS2>\r\n") +
            ("<FS2>\u53D1\u7968\uFF1A" + data.invoiceTitle + " " + data.invoiceId + "</FS2>\r\n\n") +
            (genYiLianCloudTable(paperFormat, data.goods) + "\r\n") +
            ("\u9910\u76D2\u8D39\uFF1A" + data.packageFee + "\u5143\r") +
            ("\u914D\u9001\u8D39\uFF1A" + data.deliverFee + "\u5143\r\n") +
            ("<right>\u539F\u4EF7\uFF1A" + data.originalPrice + "\u5143</right>\r") +
            ("<right>\u603B\u4F18\u60E0\u91D1\u989D\uFF1A" + data.reducedPrice + "\u5143</right>\r") +
            ("<right>\u5728\u7EBF\u652F\u4ED8\uFF1A" + data.totalPrice + "\u5143</right>\r\n") +
            ("<center><BR>" + data.id + "</BR></center>\r\n") +
            ("<center>\u6613\u9001\u8BA2\u5355\u53F7:" + data.id + "</center>\r\n");
        if (data.platform === ORDER_PLATFORM_MAP.MEITUAN) {
            content +=
                "<center><BR2>" + data.meituanCode + "</BR2></center>\r\n" +
                    ("<center>\u7F8E\u56E2\u8BA2\u5355\u53F7:" + data.meituanCode + "</center>\r\n");
        }
        return content;
    },
    _a[PrintCases.Producer] = function (_a) {
        var paperFormat = _a.paperFormat, data = _a.data;
        var content = "<FS2><center>" + data.storeName + "</center></FS2>\r\n" +
            "<FS><center>#\u751F\u4EA7\u8054#</center></FS>\r\n" +
            ("\u5E73\u53F0\u6D41\u6C34\u53F7\uFF1A" + data.platformDaySn + "\r") +
            ("\u6613\u9001\u6D41\u6C34\u53F7\uFF1A" + data.daySn + "\r\n\n") +
            ("<FS>" + genYiLianCloudTable(paperFormat, data.goods) + "</FS>\r\n\n") +
            ("<FS2>\u5907\u6CE8\uFF1A" + get(data, 'description', '') + "</FS2>");
        return content;
    },
    _a);
