import { Howl } from 'howler';
var prefixSoundPath = function (soundFile) {
    return require("@assets/sound/" + soundFile).default;
};
var SOUNDS = {
    NEW_ORDER: prefixSoundPath('WAIT_CONFIRMsound.mp3'),
    NEW_ORDER_WITH_AUTO: prefixSoundPath('WAIT_DISTRIsound.mp3'),
    CANCEL_APPLY: prefixSoundPath('CANCEL_APPLYsound.mp3'),
    REFUND_APPLY: prefixSoundPath('REFUND_APPLYsound.mp3'),
    URGE_ORDER: prefixSoundPath('URGE_ORDERsound.mp3'),
    DELIVER_PERSON_RECEIVE_ORDER: prefixSoundPath('DELIVER_PERSON_RECEIVE_ORDERsound.mp3'),
    DELIVERY_ERROR: prefixSoundPath('DELIVER_FAILEDsound.mp3'),
    TIME_OUTsound: prefixSoundPath('TIME_OUT_UN_EXPRESSIONsound.mp3'),
    UN_GRAB_TENsound: prefixSoundPath('UN_GRAB_TENsound.mp3'),
    UN_GRAB_THIRTYsound: prefixSoundPath('UN_GRAB_THIRTYsound.mp3'),
    PRINTER_DISCONNECT: prefixSoundPath('006.mp3'),
    NETWORK_DISCONNECT: prefixSoundPath('007.mp3')
    // UN_GRAB_THIRTYsound: prefixSoundPath('UN_GRAB_THIRTYsound.mp3'),
};
export var newOrderSound = new Howl({ src: SOUNDS.NEW_ORDER });
export var printerDisconnectSound = new Howl({
    src: SOUNDS.PRINTER_DISCONNECT
});
export var networkDisconnectSound = new Howl({
    src: SOUNDS.NETWORK_DISCONNECT
});
export var UN_GRAB_THIRTYsound = new Howl({ src: SOUNDS.UN_GRAB_THIRTYsound });
export var TIME_OUTsound = new Howl({ src: SOUNDS.TIME_OUTsound });
export var UN_GRAB_TENsound = new Howl({ src: SOUNDS.UN_GRAB_TENsound });
export var DELIVER_PERSON_RECEIVE_ORDER = new Howl({
    src: SOUNDS.DELIVER_PERSON_RECEIVE_ORDER
});
export var newOrderWithAutoSound = new Howl({
    src: SOUNDS.NEW_ORDER_WITH_AUTO
});
export var cancelOrderApplySound = new Howl({ src: SOUNDS.CANCEL_APPLY });
export var refundOrderApplySound = new Howl({ src: SOUNDS.REFUND_APPLY });
export var urgeOrderSound = new Howl({ src: SOUNDS.URGE_ORDER });
export var deliveryErrorSound = new Howl({
    src: SOUNDS.DELIVERY_ERROR
});
