var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/*
 * @Author: hijack
 * @Date: 2021-07-07 20:05:26
 * @LastEditTime: 2021-07-08 17:03:45
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/container/Orders/modal/RechargeModal.tsx
 */
/*
 * @Author: hijack
 * @Date: 2021-06-19 13:56:34
 * @LastEditTime: 2021-06-22 20:02:12
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/container/Orders/modal/CouponListModal.tsx
 */
import React from 'react';
import styled from 'styled-components';
import FormModal from '@components/FormModal';
import { getEnv } from '@utils/env';
import { QRCode } from '../../Index/styled';
var SBody = styled.div.withConfig({ displayName: "SBody", componentId: "sc-1tjqqxk" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 10px 20px;\n  box-sizing: border-box;\n  .left {\n    flex-grow: 2;\n    display: flex;\n    flex-direction: column;\n    .top {\n      font-size: 14px;\n      font-weight: bold;\n      margin-bottom: 20px;\n    }\n    .list {\n      display: flex;\n      justify-content: space-between;\n      line-height: 35px;\n    }\n    .info {\n      color: #999;\n      margin-top: 15px;\n    }\n  }\n  .right {\n    margin-left: 50px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    img {\n      width: 200px;\n      height: 200px;\n    }\n    .text {\n      color: #999;\n    }\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 10px 20px;\n  box-sizing: border-box;\n  .left {\n    flex-grow: 2;\n    display: flex;\n    flex-direction: column;\n    .top {\n      font-size: 14px;\n      font-weight: bold;\n      margin-bottom: 20px;\n    }\n    .list {\n      display: flex;\n      justify-content: space-between;\n      line-height: 35px;\n    }\n    .info {\n      color: #999;\n      margin-top: 15px;\n    }\n  }\n  .right {\n    margin-left: 50px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    img {\n      width: 200px;\n      height: 200px;\n    }\n    .text {\n      color: #999;\n    }\n  }\n"])));
var RechargeModal = function (_a) {
    var visible = _a.visible, toggleVisible = _a.toggleVisible;
    var env = getEnv();
    return (React.createElement(FormModal, { className: "coupon-list-modal", title: "\u4F59\u989D\u5145\u503C\u3001\u67E5\u770B\u4F18\u60E0\u5238\u3001\u4EA4\u6613\u660E\u7EC6\u7B49\uFF0C\u8BF7\u79FB\u6B65\u524D\u5F80\u6613\u9001APP", 
        // okText="确 定"
        visible: visible, onOk: function () { }, onCancel: toggleVisible, confirmLoading: false, destroyOnClose: true, width: 740, bodybg: '#F3F5F7', footerNone: true },
        React.createElement(SBody, null,
            React.createElement("div", { className: "left" },
                React.createElement("div", { className: "top" }, "\u6536\u6B3E\u65B9\u4FE1\u606F"),
                React.createElement("div", { className: "list" },
                    React.createElement("div", null, "\u6536\u6B3E\u65B9\u6237\u540D\uFF1A"),
                    React.createElement("div", null, "\u5317\u4EAC\u6613\u9001\u79D1\u6280\u6709\u9650\u516C\u53F8")),
                React.createElement("div", { className: "list" },
                    React.createElement("div", null, "\u6536\u6B3E\u65B9\u5F00\u6237\u884C\uFF1A"),
                    React.createElement("div", null, "\u5317\u4EAC\u94F6\u884C\u4E2D\u5173\u6751\u8F6F\u4EF6\u56ED\u652F\u884C")),
                React.createElement("div", { className: "list" },
                    React.createElement("div", null, "\u6536\u6B3E\u65B9\u8D26\u6237\uFF1A"),
                    React.createElement("div", null, "20000039768700026946472")),
                React.createElement("div", { className: "info" },
                    "\u60A8\u9700\u6C47\u6B3E\u81F3\u6536\u6B3E\u65B9\u8D26\u6237\uFF0C\u6C47\u6B3E\u6210\u529F\u5E76\u6613\u9001\u5BA1\u6838\u901A\u8FC7\u540E\uFF0C\u4F59\u989D\u53CA\u4F18\u60E0\u5238\u5C06\u5373\u65F6\u5230\u8D26\u3002",
                    React.createElement("br", null),
                    "\u6613\u9001APP\u4E0B\u8F7D\u5730\u5740\uFF1Ahttp://static.iyisong.net/android/yisongshop.apk")),
            React.createElement("div", { className: "right" },
                React.createElement(QRCode, { env: env }),
                React.createElement("div", { className: "text" }, "\u6613\u9001APP\u626B\u7801\u4E0B\u8F7D")))));
};
export default RechargeModal;
var templateObject_1;
