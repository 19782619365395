import { ORDER_PLATFORM_MAP } from '@utils/constants';
var getPlatformAuthData = function (_a) {
    var type = _a.type, authData = _a.authData;
    switch (type) {
        case ORDER_PLATFORM_MAP.ELEME:
            return {
                code: authData.code
            };
        default:
            return {};
    }
};
export default getPlatformAuthData;
