import qs from 'qs';
import ajax from '@utils/ajax';
import { ORDER_PLATFORM_MAP } from './constants';
var eleOAuthUriGenerate = function (_a) {
    var key = _a.key, state = _a.state, redirectUri = _a.redirectUri, authUrl = _a.authUrl;
    var queryStr = qs.stringify({
        response_type: 'code',
        redirect_uri: redirectUri + "?type=" + ORDER_PLATFORM_MAP.ELEME,
        client_id: key,
        state: JSON.stringify(state),
        scope: 'all'
    });
    return authUrl + "?" + queryStr;
};
export var getOAuthUri = function (_a) {
    var platform = _a.platform, authInfo = _a.authInfo, redirectUri = _a.redirectUri, state = _a.state, isCash = _a.isCash;
    switch (platform) {
        case ORDER_PLATFORM_MAP.ELEME:
            return eleOAuthUriGenerate({
                state: state,
                key: authInfo.key,
                authUrl: authInfo.authUrl,
                redirectUri: redirectUri
            });
        case ORDER_PLATFORM_MAP.MEITUAN:
            ajax
                .post('/order/platform/binding', {
                storeId: state.storeId,
                mode: state.mode,
                type: ORDER_PLATFORM_MAP.MEITUAN,
                platformAuthData: {}
            })
                .then(function (_a) {
                var data = _a.data;
                console.log(data);
            });
            return isCash ? authInfo.authUrl2 : authInfo.authUrl;
        case ORDER_PLATFORM_MAP.EBAI:
            ajax
                .post('/order/platform/binding', {
                storeId: state.storeId,
                mode: state.mode,
                type: ORDER_PLATFORM_MAP.EBAI,
                platformAuthData: {
                    ebaiStoreName: state.ebaiStoreName,
                    ebaiStoreId: state.ebaiStoreId
                }
            })
                .then(function (_a) {
                var data = _a.data;
                console.log(data);
            });
            return authInfo.authUrl;
        default:
            return '';
    }
};
