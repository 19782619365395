var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { sendPv } from '@/utils/umeng';
import ResetPassword from './ResetPassword';
import { AccountContainer, AccountWrapper, Content, Item, ItemIcon, ResetPwdButton, Text, Title } from './styled';
var Account = /** @class */ (function (_super) {
    __extends(Account, _super);
    function Account() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            resetPwdVisible: false
        };
        _this.handleResetPwd = function () {
            _this.setState({
                resetPwdVisible: !_this.state.resetPwdVisible
            });
        };
        return _this;
    }
    Account.prototype.componentDidMount = function () {
        sendPv();
    };
    Account.prototype.render = function () {
        var state = this.state;
        return (React.createElement(AccountWrapper, null,
            React.createElement(AccountContainer, null,
                React.createElement(Item, null,
                    React.createElement(ItemIcon, { type: "lock" }),
                    React.createElement(Content, null,
                        React.createElement(Title, null, "\u4FEE\u6539\u5BC6\u7801"),
                        React.createElement(Text, null, "\u4FEE\u6539\u5BC6\u7801\u9700\u8981\u9A8C\u8BC1\u60A8\u7684\u624B\u673A\u53F7")),
                    React.createElement(ResetPwdButton, { onClick: this.handleResetPwd }, "\u4FEE\u6539"))),
            React.createElement(ResetPassword, { visible: state.resetPwdVisible, onCancel: this.handleResetPwd, isChange: true })));
    };
    return Account;
}(React.Component));
export default Account;
