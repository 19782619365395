var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import moment from 'moment';
import * as qs from 'querystring';
import { requestForOrder } from '@utils/request';
import ajax from '@utils/ajax';
import { ORDER_PLATFORM_MAP } from '@utils/constants';
import { defaultFormatNOY } from '@utils/time';
import { getChannelNumberCheck } from '@/apis/generalNumber';
export var CancelOrderStatus;
(function (CancelOrderStatus) {
    CancelOrderStatus[CancelOrderStatus["NotApply"] = 0] = "NotApply";
    CancelOrderStatus[CancelOrderStatus["WaitCheck"] = 5] = "WaitCheck";
    CancelOrderStatus[CancelOrderStatus["Canceled"] = 10] = "Canceled";
    CancelOrderStatus[CancelOrderStatus["Rejected"] = 15] = "Rejected";
})(CancelOrderStatus || (CancelOrderStatus = {}));
export var RetreatOrderStatus;
(function (RetreatOrderStatus) {
    RetreatOrderStatus[RetreatOrderStatus["NotApply"] = 0] = "NotApply";
    RetreatOrderStatus[RetreatOrderStatus["WaitCheck"] = 5] = "WaitCheck";
    RetreatOrderStatus[RetreatOrderStatus["Canceled"] = 10] = "Canceled";
    RetreatOrderStatus[RetreatOrderStatus["Rejected"] = 15] = "Rejected";
})(RetreatOrderStatus || (RetreatOrderStatus = {}));
export var DistributionExceptionStatus;
(function (DistributionExceptionStatus) {
    DistributionExceptionStatus[DistributionExceptionStatus["Normal"] = 0] = "Normal";
    DistributionExceptionStatus[DistributionExceptionStatus["Failed"] = 1] = "Failed";
    DistributionExceptionStatus[DistributionExceptionStatus["WaitSendTimeout"] = 2] = "WaitSendTimeout";
    DistributionExceptionStatus[DistributionExceptionStatus["DeliveryTimeout"] = 3] = "DeliveryTimeout";
    DistributionExceptionStatus[DistributionExceptionStatus["UnConfirmed"] = 4] = "UnConfirmed";
})(DistributionExceptionStatus || (DistributionExceptionStatus = {}));
function replaceConsigneePhone(phone) {
    return phone
        .replace(/\[/g, '')
        .replace(/\]/g, '')
        .replace(/\"/g, '');
}
export var transformConsigneePhone = function (_a) {
    var platform = _a.platform, consigneePhone = _a.consigneePhone;
    var phonePro = replaceConsigneePhone(consigneePhone);
    switch (platform) {
        case ORDER_PLATFORM_MAP.ELEME:
        case ORDER_PLATFORM_MAP.MEITUAN:
            var phone = isJSON(phonePro)
                ? !phonePro.includes('转') && JSON.parse(phonePro)
                : phonePro;
            return phone && phone.replace(',', ' 转 ');
        case ORDER_PLATFORM_MAP.OFFLINE:
        case ORDER_PLATFORM_MAP.MANUAL:
            return isJSON(phonePro) ? JSON.parse(phonePro) : phonePro;
        default:
            return phonePro;
    }
    // const phone = JSON.parse(consigneePhone).join('，')
    // return phone !== undefined ? phone.replace(',', '_') : ''
};
export var transformDeliverTime = function (time) {
    return time ? moment(time).format('MM-DD HH:mm') : '立即送达';
};
export function isJSON(str) {
    if (typeof str == 'string') {
        try {
            var obj = JSON.parse(str);
            if (typeof obj == 'object' && obj) {
                return true;
            }
            else {
                return false;
            }
        }
        catch (e) {
            // console.log('error：' + str + '!!!' + e)
            return false;
        }
    }
    console.log('It is not a string!');
}
export var transformOrderData = function (item, distance) {
    if (distance === void 0) { distance = ''; }
    var platform = item.platform, consigneePhone = item.consigneePhone, deliverTime = item.deliverTime, orderTime = item.orderTime;
    return Object.assign(item, {
        orderTime: defaultFormatNOY(orderTime),
        deliverTime: transformDeliverTime(deliverTime),
        consigneePhone: transformConsigneePhone({
            platform: platform,
            consigneePhone: consigneePhone
        }),
        distance: distance
    });
};
var transformListData = function (list, distanceObj) {
    return list.map(function (item) {
        return transformOrderData(item, distanceObj[item.id]);
    });
};
export var refOrdersItemAction = function (payload) { return ({
    type: 'orders/refreshItem',
    payload: payload
}); };
export var setOrdersCounterAction = function (payload) { return ({
    type: 'orders/setCounter',
    payload: payload
}); };
export var addOrdersItemAction = function (payload) { return ({
    type: 'orders/addItem',
    payload: payload
}); };
export var editOrderItemStatusAction = function (payload) { return ({
    type: 'orders/editItemStatus',
    payload: payload
}); };
export var deleteOrderItemAction = function (payload) { return ({
    type: 'orders/deleteItem',
    payload: payload
}); };
export var orders = {
    state: {
        data: {
            list: [],
            page: 1,
            pageSize: 10,
            total: 0,
            socketQueryParams: {} //socket 刷新需求的参数
        },
        loading: false,
        counter: {
            all: 0,
            toBeConfirmed: 0,
            toBeShipping: 0,
            waitGrab: 0,
            inTheDistribution: 0,
            done: 0,
            canceled: 0,
            distributionAbnormal: 0,
            refundApply: 0,
            canceledApply: 0,
            urgeApply: 0,
            waitPickUp: 0
        },
        isPushCount: false,
        // 1通用号一取多投  2 渠道  3两者都有
        onetoManyConfig: "2"
    },
    reducers: {
        setOnetoManyConfig: function (state, onetoManyConfig) {
            if (onetoManyConfig) {
                var channelOneToMany = onetoManyConfig.channelOneToMany;
                var platformOneToMany = onetoManyConfig.platformOneToMany;
                //platformOneToMany 平台通用号 1 channelOneToMany 渠道自有账号 2
                state.onetoManyConfig = (String(platformOneToMany) + String(channelOneToMany * 2));
                return state;
            }
            return state;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
            return state;
        },
        setData: function (state, payload) {
            state.data.list = payload.list;
            state.data.page = payload.page;
            state.data.pageSize = payload.pageSize;
            state.data.total = payload.total;
            return state;
        },
        setSocketQueryParams: function (state, payload) {
            // let newState = { ...state }
            // newState.data.socketQueryParams = payload
            state.data.socketQueryParams = payload;
            return state;
        },
        setCounter: function (state, payload) {
            state.counter.toBeConfirmed = payload.waitConfirm;
            state.counter.toBeShipping = payload.waitSend;
            state.counter.waitGrab = payload.waitGrab;
            state.counter.inTheDistribution = payload.sending;
            // state.counter.done = payload.completed
            // state.counter.canceled = payload.canceled
            state.counter.distributionAbnormal = payload.sendException;
            state.counter.refundApply = payload.refundApply;
            state.counter.canceledApply = payload.cancelApply;
            state.counter.waitPickUp = payload.waitPickUp;
            state.counter.urgeApply = payload.reminders;
            return state;
        },
        addItem: function (state, payload) {
            state.data.list = __spread([transformOrderData(payload)], state.data.list);
            state.data.total = state.data.total + 1;
            return state;
        },
        deleteItem: function (state, payload) {
            var list = state.data.list.filter(function (order) { return order.id !== payload.orderId; });
            state.data.list = list;
            state.data.total = state.data.total - 1;
            return state;
        },
        editItem: function (state, payload) {
            var data = payload.data;
            state.data.list.forEach(function (order, index) {
                if (order.id === payload.orderId) {
                    state.data.list[index] = __assign(__assign({}, state.data.list[index]), { consignee: data.userName, consigneePhone: data.phone, consigneeAddress: data.address, receiveCoordinate: data.longitude + "," + data.latitude, description: data.remarks });
                }
            });
            return state;
        },
        editItemStatus: function (state, payload) {
            state.data.list.forEach(function (order, index) {
                if (order.id === payload.orderId) {
                    // state.data.list[index]['status'] = payload.status
                    state.data.list[index] = __assign(__assign({}, state.data.list[index]), payload);
                }
            });
            return state;
        },
        setPushCount: function (state) {
            state.isPushCount = true;
            return state;
        }
    },
    effects: function (dispatch, a) { return ({
        fetchData: function (_a) {
            var _b = _a.page, page = _b === void 0 ? 1 : _b, _c = _a.pageSize, pageSize = _c === void 0 ? 10 : _c, queryParams = __rest(_a, ["page", "pageSize"]);
            return __awaiter(this, void 0, void 0, function () {
                var query, urgeApply, request;
                var _this = this;
                return __generator(this, function (_d) {
                    dispatch.orders.setLoading(true);
                    query = qs.stringify(__assign({ pageSize: pageSize, toPage: page }, queryParams));
                    dispatch.orders.setSocketQueryParams(__assign({ pageSize: pageSize, toPage: page }, queryParams));
                    urgeApply = queryParams.urgeApply;
                    if (urgeApply) {
                        request = function () { return ajax.get('/order/reminders'); };
                    }
                    else {
                        request = function (query) {
                            return requestForOrder.get("/api/order/list?" + query);
                        };
                    }
                    request(query).then(function (_a) {
                        var data = _a.data;
                        return __awaiter(_this, void 0, void 0, function () {
                            var orderIds, resData;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        orderIds = (urgeApply ? data : data.list).map(function (item) { return item.id; });
                                        return [4 /*yield*/, requestForOrder.post('api/map/store/receive/distance', {
                                                // params: { orderIds: JSON.stringify(orderIds) }
                                                orderIds: orderIds
                                            })];
                                    case 1:
                                        resData = (_b.sent()).data;
                                        if (urgeApply) {
                                            dispatch.orders.setData({
                                                list: data ? transformListData(data, resData.distanceData) : [],
                                                page: 1,
                                                total: data ? data.length : 0,
                                                pageSize: pageSize
                                            });
                                        }
                                        else {
                                            dispatch.orders.setData({
                                                list: transformListData(data.list, resData.distanceData),
                                                page: data.currentPage,
                                                total: data.total,
                                                pageSize: pageSize
                                            });
                                        }
                                        dispatch.orders.setLoading(false);
                                        return [2 /*return*/];
                                }
                            });
                        });
                    });
                    return [2 /*return*/];
                });
            });
        },
        editItemWithReq: function (_a) {
            var orderId = _a.orderId, data = _a.data;
            return __awaiter(this, void 0, void 0, function () {
                var error_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, ajax.post('/order/edit', data)];
                        case 1:
                            _b.sent();
                            dispatch.orders.editItem({
                                orderId: orderId,
                                data: __assign(__assign({}, data), { phone: JSON.parse(data.phone)
                                        .join('，')
                                        .replace(',', '_') })
                            });
                            return [2 /*return*/, Promise.resolve(data)];
                        case 2:
                            error_1 = _b.sent();
                            return [2 /*return*/, Promise.reject(error_1)];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        pushCountReq: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, ajax.post('/order/count/push', {})
                            //  await requestForOrder.post('/order/count/push', {})
                        ];
                        case 1:
                            _a.sent();
                            //  await requestForOrder.post('/order/count/push', {})
                            dispatch.orders.setPushCount();
                            return [2 /*return*/];
                    }
                });
            });
        },
        reqCounter: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, requestForOrder.get('/api/order/count/cycle', {})];
                        case 1:
                            res = _a.sent();
                            dispatch.orders.setCounter(res.data);
                            return [2 /*return*/];
                    }
                });
            });
        },
        reqSetOnetoManyConfig: function () {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getChannelNumberCheck()];
                        case 1:
                            res = _a.sent();
                            dispatch.orders.setOnetoManyConfig(res);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }); }
};
