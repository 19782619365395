var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Button } from 'antd';
import { CustomModal, FormFooterWrapper } from './styled';
var FormModal = function (props) { return (React.createElement(CustomModal, __assign({ cancelText: "\u53D6\u6D88", okText: "\u4FDD\u5B58", width: 600, maskClosable: false, centered: true }, props), props.children)); };
export default FormModal;
export var FormFooter = function (props) { return (React.createElement(FormFooterWrapper, null,
    React.createElement(Button, { onClick: function () { return props.onCancel(); } }, "\u53D6\u6D88"),
    React.createElement(Button, { type: "primary", loading: props.confirmLoading, onClick: function () { return props.onOk(); } }, "\u4FDD\u5B58"))); };
