var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Modal, Input, message } from 'antd';
import './index.less';
var InsuranceComponent = function (props, ref) {
    var _a = __read(useState(null), 2), price = _a[0], serPrice = _a[1];
    var _b = __read(useState(null), 2), realprice = _b[0], serrealPrice = _b[1];
    useEffect(function () {
        if (props.visible && Number(props.price) > 1000) {
            message.error('最大保价金额1000元！');
        }
        if (!price && Number(props.price) !== 0) {
            serPrice(Number(props.price) > 1000 ? 1000 : Number(props.price));
        }
    }, [props.price, props.visible]);
    useImperativeHandle(ref, function () { return realprice; });
    return (React.createElement(Modal, { visible: props.visible, cancelText: "取消", okText: "确定", width: 600, className: 'insuranceclass', footer: null, title: null, closable: false },
        React.createElement("div", { className: "title" },
            React.createElement("span", null, "\u7269\u54C1\u4FDD\u4EF7"),
            React.createElement("span", { onClick: function (e) { return props.onCancel("取消"); } },
                React.createElement("i", { className: "img" }))),
        React.createElement("div", { className: "content" },
            React.createElement("span", { className: 'accountmoney' },
                "\u4FDD\u4EF7\u91D1\u989D ",
                React.createElement("i", null, "(\u4E0A\u96501000\u5143)")),
            React.createElement(Input, { placeholder: "\u5EFA\u8BAE\u586B\u5199\u5B9E\u9645\u91D1\u989D", suffix: "\u5143", onChange: function (e) {
                    if (isNaN(e.target.value)) {
                        return message.error('请输入数字');
                    }
                    if (Number(e.target.value) > 1000) {
                        message.error('最大保价金额1000元！');
                    }
                    var value = Number(e.target.value) > 1000 ? 1000 : e.target.value;
                    serPrice(value);
                }, value: price })),
        React.createElement("div", { className: 'tip' },
            "\u6E29\u99A8\u63D0\u793A\uFF1A",
            React.createElement("br", null),
            "1\u3001\u5404\u8FD0\u529B\u5E73\u53F0\u4FDD\u8D39\u91D1\u989D\u4E0D\u540C\uFF0C\u6309\u5E73\u53F0\u8FD4\u56DE\u4FDD\u8D39\u4E14\u7EDF\u4E00\u8BA1\u5165\u5E94\u4ED8\u91D1\u989D\u3002",
            React.createElement("br", null),
            "2\u3001\u5EFA\u8BAE\u60A8\u6839\u636E\u7269\u54C1\u5B9E\u9645\u4EF7\u503C\u8FDB\u884C\u4FDD\u4EF7\u3002 ",
            React.createElement("br", null),
            "3\u3001\u8D2D\u4E70\u5373\u8868\u793A\u60A8\u8BA4\u540C\u5404\u8FD0\u529B\u5E73\u53F0\u7684\u300A\u4FDD\u4EF7\u534F\u8BAE\u300B;\u5404\u8FD0\u529B\u300A\u4FDD\u4EF7\u534F\u8BAE\u300B\u5185\u5BB9\u4E0D\u5C3D\u76F8\u540C,\u8BF7\u524D\u5F80\u5404\u8FD0\u529B\u5E73\u53F0\u67E5\u770B\u3002",
            React.createElement("br", null),
            "4\u3001\u76EE\u524D\u4FDD\u4EF7\u4EC5\u652F\u6301\u95EA\u9001\u3002"),
        React.createElement("div", { className: 'button' },
            React.createElement("div", { className: 'cancel', onClick: function (e) { e.stopPropagation(); props.onCancel("不保价"); } }, "\u4E0D\u4FDD\u4EF7"),
            React.createElement("div", { className: 'ok', onClick: function () { return onOk(price); } }, "\u4FDD\u4EF7"))));
    function onOk(current) {
        if (Number(current) === 0) {
            message.error('保价金额不能为空');
            return;
        }
        var reg = /^([0-9]+[\d]*(.[0-9]{1,2})?)$/;
        if (!reg.test(String(current))) {
            message.error('请输入合理数字,仅支持2位小数,且大于0');
            return;
        }
        serrealPrice(current);
        props.onOk(current);
    }
};
export default React.forwardRef(InsuranceComponent);
export function declaredAmountforRequest(price) {
    var priceNum = Number(price);
    return priceNum !== 0 ? priceNum * 100 : 0;
}
