import * as React from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import FormModal from '@components/FormModal';
import ajax from '@utils/ajax';
import { DELIVERY_PLATFORM_MAP } from '@utils/constants';
import EditServiceForm from './EditServiceForm';
var mapDispatch = function (dispatch) { return ({
    getDeliveryPlatform: dispatch.deliveryPlatforms.fetchData
}); };
var EditServiceModal = function (_a) {
    var visible = _a.visible, initialValue = _a.initialValue, toggleVisible = _a.toggleVisible;
    var dispatch = useDispatch();
    var getDeliveryPlatform = mapDispatch(dispatch).getDeliveryPlatform;
    var formRef = React.createRef();
    var handleSubmit = function () {
        var editServiceForm = formRef.current.props.form;
        var id = initialValue.id, store = initialValue.store, platform = initialValue.platformType;
        var storeId = store.id;
        editServiceForm.validateFields(function (errors, content) {
            if (errors)
                return false;
            if (platform === DELIVERY_PLATFORM_MAP.SHANSONG) {
                Object.assign(content, {
                    receiveCode: content.receiveCode ? 1 : 0,
                    takeCode: content.takeCode ? 1 : 0
                });
            }
            ajax
                .post('/distri/platform/edit/delivery', {
                id: id,
                storeId: storeId,
                platform: platform,
                content: content
            })
                .then(function () {
                message.success('修改配送服务成功');
                toggleVisible();
                getDeliveryPlatform({});
            });
        });
    };
    return (React.createElement(FormModal, { title: "\u4FEE\u6539\u914D\u9001\u670D\u52A1", visible: visible, onCancel: function () { return toggleVisible(); }, onOk: handleSubmit, destroyOnClose: true, okText: "\u4FDD\u5B58" },
        React.createElement(EditServiceForm, { wrappedComponentRef: formRef, initialValue: initialValue })));
};
export default EditServiceModal;
