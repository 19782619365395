var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Button } from 'antd';
import FormModal from '@components/FormModal';
import { printReceipt } from '@printer';
import { PrintChooseWrapper } from './styled';
var PrintChooseModal = /** @class */ (function (_super) {
    __extends(PrintChooseModal, _super);
    function PrintChooseModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // print = async (orderId: string, code: string, type: number) => {
        //   this.props.onCancel()
        //   if (type === PrinterTypes.CloudPrinter) {
        //     await printReceiptBy(orderId, code)
        //     message.success('打印成功')
        //   } else if (type === PrinterTypes.Bluetooth) {
        //     message.error('不能调用蓝牙打印机')
        //   }
        // }
        _this.print = function (_a) {
            var item = _a.item, printCase = _a.printCase, copies = _a.copies;
            return function () {
                _this.props.onCancel();
                var data = _this.props.orderData;
                var printerConfig = item.printerVO;
                printReceipt({
                    printerConfig: printerConfig,
                    printCase: printCase,
                    data: data,
                    copies: copies,
                    from: 'replenishPrint'
                });
            };
        };
        return _this;
    }
    PrintChooseModal.prototype.renderButtonGroup = function () {
        var _this = this;
        var cases = this.props.cases;
        return cases.map(function (item, key) {
            return (React.createElement(Button, { key: key, className: "print-btn", onClick: 
                // () => this.print(orderData.id, item.code, item.printerVO.type)
                _this.print({
                    item: item,
                    printCase: item.code,
                    copies: item.printNumber
                }) }, item.name));
        });
    };
    PrintChooseModal.prototype.render = function () {
        var _a = this.props, visible = _a.visible, onCancel = _a.onCancel;
        return (React.createElement(FormModal, { className: "print-choose-modal", title: "\u8BF7\u9009\u62E9\u6253\u5370\u5185\u5BB9", visible: visible, onCancel: onCancel, footer: null, destroyOnClose: true },
            React.createElement(PrintChooseWrapper, null, this.renderButtonGroup())));
    };
    return PrintChooseModal;
}(React.PureComponent));
export { PrintChooseModal };
