var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
/*
 * @Author: hijack
 * @Date: 2021-06-18 20:26:48
 * @LastEditTime: 2021-07-14 15:08:41
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/container/Orders/form/SendDeliveryFormPlatform.tsx
 */
import * as React from 'react';
import { Col, Form, Input, InputNumber, Radio, Row, Icon as AIcon, message } from 'antd';
import moment from 'moment';
import produce from 'immer';
import { SendDeliveryCouponContext } from '../context';
import { IconFengniao, IconShansong, IconDada, IconShunFeng, IconMtKsd, IconMtGsd, IconYsZs, IconYsKs, IconUupt } from '@components/Icon';
import { requestForCoupon } from '@utils/request';
import ReservationTime from '@components/ReservationTime';
import CouponListModal from '../modal/CouponListModal';
import RechargeModal from '../modal/RechargeModal';
import { PlatformChooseWrapper, PlatformIconWrapper, PlatformInfo, PlatformInfoBigText, PlatformInfoText, PlatformItem, PlatformInfoWrapper, ErrorWrapper, SBalance } from './styled';
import checkedIcon from '@assets/images/checked.png';
import uncheckedIcon from '@assets/images/unchecked.png';
import { addMoneyInfo } from './SendDeliveryCalculate';
var FormItem = Form.Item;
var RadioButton = Radio.Button;
var RadioGroup = Radio.Group;
var TextArea = Input.TextArea;
var formItemLayout = {
    labelCol: { span: 7 }
};
var PLATFORM_MAP = {
    SHANSONG: '1',
    FENGNIAO: '2',
    DADA: '4',
    SHUNFENG: '5',
    MEITUANKSD: '6',
    MEITUANGSD: '7',
    YSZS: '-11',
    YSKS: '-12',
    UUPT: '8'
};
var PLATFORM_ICON_MAP = (_a = {},
    _a[PLATFORM_MAP.SHANSONG] = React.createElement(IconShansong, null),
    _a[PLATFORM_MAP.FENGNIAO] = React.createElement(IconFengniao, null),
    _a[PLATFORM_MAP.DADA] = React.createElement(IconDada, null),
    _a[PLATFORM_MAP.SHUNFENG] = React.createElement(IconShunFeng, null),
    _a[PLATFORM_MAP.MEITUANKSD] = React.createElement(IconMtKsd, null),
    _a[PLATFORM_MAP.MEITUANGSD] = React.createElement(IconMtGsd, null),
    _a[PLATFORM_MAP.YSZS] = React.createElement(IconYsZs, null),
    _a[PLATFORM_MAP.YSKS] = React.createElement(IconYsKs, null),
    _a[PLATFORM_MAP.UUPT] = React.createElement(IconUupt, null),
    _a);
var DeliveryPlatform = function (_a) {
    var amount = _a.amount, errorMsg = _a.errorMsg, balanceAmount = _a.balanceAmount, _b = _a.reducedPrice, reducedPrice = _b === void 0 ? 0 : _b, selected = _a.selected, onClick = _a.onClick, distance = _a.distance, couponGroupName = _a.couponGroupName, couponGroupId = _a.couponGroupId, handleOpenCouponModal = _a.handleOpenCouponModal, platform = _a.platform;
    var Icon = PLATFORM_ICON_MAP[platform] || null;
    return (React.createElement(PlatformItem, null,
        React.createElement(PlatformInfoWrapper, { onClick: onClick, selected: selected },
            React.createElement(PlatformIconWrapper, null, Icon),
            React.createElement(PlatformInfo, null,
                React.createElement(PlatformInfoBigText, null,
                    "\u9884\u4F30\uFF1A",
                    (amount - reducedPrice) / 100 || '',
                    " \u5143"),
                React.createElement(PlatformInfoText, null,
                    "\u4F18\u60E0\uFF1A",
                    typeof reducedPrice === 'number' && reducedPrice / 100,
                    " \u5143")),
            React.createElement(PlatformInfo, { marginleft: "20px" },
                React.createElement(PlatformInfoText, { lineheight: "22px" },
                    "\u9884\u4F30",
                    distance || '',
                    "km"),
                React.createElement(PlatformInfoText, { onClick: function (e) {
                        e.stopPropagation();
                        handleOpenCouponModal({
                            isGlobalCouponModal: false,
                            couponGroupId: couponGroupId,
                            selectCouponPlatformId: platform
                        });
                    } },
                    couponGroupName, "\u3000",
                    couponGroupName && (React.createElement(AIcon, { type: "right", style: { fontSize: '12px', color: '#999999' } })))),
            React.createElement("img", { className: "img", alt: "", src: selected ? checkedIcon : uncheckedIcon })),
        errorMsg && React.createElement(ErrorWrapper, null, errorMsg)));
};
var SendDeliveryFormPlatform = /** @class */ (function (_super) {
    __extends(SendDeliveryFormPlatform, _super);
    function SendDeliveryFormPlatform() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            platformChecked: [''],
            visible: false,
            couponData: [],
            selectCouponPlatformId: '',
            couponGroupId: -1,
            couponGroupName: '',
            globalCouponId: -1,
            globalCouponName: '系统推荐最低价',
            isGlobalCouponModal: true,
            rechargeVisible: false
        };
        _this.handleFetchCoupon = function (_a) {
            var expressPartnerId = _a.expressPartnerId, callback = _a.callback;
            var _b = _this.props, order = _b.order, globalDistance = _b.globalDistance;
            var cityCode = order.cityCode;
            requestForCoupon
                .get('/coupon/listCanUseCoupons', {
                distance: globalDistance,
                cityCode: cityCode,
                expressPartnerId: expressPartnerId
            })
                .then(function (back) {
                if (back.data) {
                    _this.setState(produce(function (draft) {
                        draft.couponData = back.data;
                    }));
                    if (callback) {
                        callback();
                    }
                }
                else {
                    message.error(back.err);
                }
            });
        };
        _this.toggleVisible = function () { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.setState(produce(function (draft) {
                    draft.visible = !_this.state.visible;
                }));
                return [2 /*return*/];
            });
        }); };
        _this.toggleRechargeVisible = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                addMoneyInfo();
                return [2 /*return*/];
            });
        }); };
        _this.handleOpenCouponModal = function (_a) {
            var isGlobalCouponModal = _a.isGlobalCouponModal, couponGroupId = _a.couponGroupId, selectCouponPlatformId = _a.selectCouponPlatformId;
            _this.setState(produce(function (draft) {
                ;
                (draft.isGlobalCouponModal = isGlobalCouponModal),
                    (draft.couponGroupId = couponGroupId),
                    (draft.selectCouponPlatformId = selectCouponPlatformId);
            }));
            if (isGlobalCouponModal) {
                _this.handleFetchCoupon({
                    callback: _this.toggleVisible
                });
            }
            else {
                _this.handleFetchCoupon({
                    expressPartnerId: selectCouponPlatformId,
                    callback: _this.toggleVisible
                });
            }
        };
        _this.triggerChange = function () {
            var _a = _this.props, triggerChange = _a.triggerChange, handleTipChange = _a.handleTipChange;
            var changeTimer = setImmediate(function () {
                var values = _this.props.form.getFieldsValue();
                triggerChange && triggerChange(values);
                // triggerChange && triggerChange(values, () => {
                //   // handleTipChange(values.tip)
                // })
                clearImmediate(changeTimer);
            });
        };
        _this.handleCalculate = function (_a) {
            var selectCouponItem = _a.selectCouponItem, callback = _a.callback;
            var _b = _this.props, triggerChange = _b.triggerChange, handleTipChange = _b.handleTipChange;
            var _c = _this.state, isGlobalCouponModal = _c.isGlobalCouponModal, selectCouponPlatformId = _c.selectCouponPlatformId;
            var values = _this.props.form.getFieldsValue();
            var valuations = _this.props.valuations;
            if (isGlobalCouponModal) {
                _this.setState(produce(function (draft) {
                    ;
                    (draft.globalCouponId = selectCouponItem.id),
                        (draft.globalCouponName = selectCouponItem.name);
                }));
            }
            else {
                _this.setState(produce(function (draft) {
                    ;
                    (draft.couponGroupId = selectCouponItem.id),
                        (draft.couponGroupName = selectCouponItem.name);
                }));
            }
            var useCoupon = true;
            var expressPartnerCouponInfos = [];
            if (isGlobalCouponModal) {
                if (selectCouponItem.id === 0) {
                    useCoupon = false;
                }
                if (![0, -1].includes(selectCouponItem.id)) {
                    valuations.map(function (item) {
                        expressPartnerCouponInfos.push({
                            expressPartnerId: item.platform,
                            couponGroupId: selectCouponItem.id
                        });
                    });
                }
            }
            else {
                valuations.map(function (item) {
                    expressPartnerCouponInfos.push({
                        expressPartnerId: item.platform,
                        couponGroupId: item.platform === selectCouponPlatformId
                            ? [0].includes(selectCouponItem.id)
                                ? null
                                : selectCouponItem.id
                            : item.couponGroupId || null
                    });
                });
            }
            triggerChange &&
                triggerChange({
                    useCoupon: useCoupon,
                    expressPartnerCouponInfos: expressPartnerCouponInfos
                }, function () {
                    callback && callback();
                });
        };
        _this.handleTipChange = function (tip) {
            var handleTipChange = _this.props.handleTipChange;
            handleTipChange(tip * 100);
        };
        _this.handleSelectPlatformItem = function (item) {
            var _a = _this.props, handlePlatformChange = _a.handlePlatformChange, platformInit = _a.platformInit, valuations = _a.valuations;
            var setFieldsValue = _this.props.form.setFieldsValue;
            var platformChecked = platformInit.platformChecked, handleUpdatePlatformChecked = platformInit.handleUpdatePlatformChecked, handleUpdateMaxPayAmount = platformInit.handleUpdateMaxPayAmount;
            if (platformChecked.includes(item.platform)) {
                var currentPlatform_1 = platformChecked.filter(function (record) { return item.platform !== record; });
                var payAmount_1 = 0;
                valuations.map(function (record) {
                    if (currentPlatform_1.includes(record.platform) &&
                        record.amount - record.reducedPrice > payAmount_1) {
                        payAmount_1 = record.amount - record.reducedPrice;
                    }
                });
                handleUpdateMaxPayAmount(payAmount_1);
                handleUpdatePlatformChecked(currentPlatform_1, function () {
                    // this.triggerChange()
                    setFieldsValue({ platform: currentPlatform_1 });
                    handlePlatformChange(item);
                });
            }
            else {
                platformChecked.push(item.platform);
                var payAmount_2 = 0;
                valuations.map(function (record) {
                    if (platformChecked.includes(record.platform) &&
                        record.amount - record.reducedPrice > payAmount_2) {
                        payAmount_2 = record.amount - record.reducedPrice;
                    }
                });
                handleUpdateMaxPayAmount(payAmount_2);
                handleUpdatePlatformChecked(Object.assign([], platformChecked), function () {
                    // this.triggerChange()
                    setFieldsValue({ platform: platformChecked });
                    handlePlatformChange(item);
                });
            }
        };
        return _this;
    }
    SendDeliveryFormPlatform.prototype.render = function () {
        var _this = this;
        var _a = this.props, valuations = _a.valuations, platformInit = _a.platformInit, mapOrder = _a.mapOrder;
        var maxPayAmount = platformInit.maxPayAmount, balance = platformInit.balance;
        var _b = this.props.form, getFieldDecorator = _b.getFieldDecorator, getFieldValue = _b.getFieldValue;
        var showDateTimePicker = getFieldValue('appointType') === 1;
        var _c = this.state, visible = _c.visible, couponData = _c.couponData, selectCouponPlatformId = _c.selectCouponPlatformId, couponGroupId = _c.couponGroupId, couponGroupName = _c.couponGroupName, platformChecked = _c.platformChecked, globalCouponId = _c.globalCouponId, globalCouponName = _c.globalCouponName, isGlobalCouponModal = _c.isGlobalCouponModal, rechargeVisible = _c.rechargeVisible;
        return (React.createElement(Form, __assign({}, formItemLayout),
            React.createElement(Row, { gutter: 2 },
                React.createElement(Col, { span: mapOrder ? 24 : 12 },
                    React.createElement(FormItem, { label: "\u53D6\u4EF6\u65F6\u95F4" }, getFieldDecorator('appointType', {
                        initialValue: 0,
                        rules: [
                            {
                                required: true
                            }
                        ]
                    })(React.createElement(RadioGroup, { onChange: this.triggerChange },
                        React.createElement(RadioButton, { value: 0 }, "\u7ACB\u5373\u53D6\u4EF6"),
                        React.createElement(RadioButton, { value: 1 }, "\u9884\u7EA6\u53D6\u4EF6"))))),
                showDateTimePicker && mapOrder ? (React.createElement(Col, { span: 24 },
                    React.createElement(FormItem, { label: "\u9884\u7EA6\u65F6\u95F4" }, getFieldDecorator('reservationTime', {
                        initialValue: moment(moment()
                            .add(2, 'h')
                            .add(10, 'm'), 'YYYY-MM-DD HH:mm'),
                        rules: [
                            {
                                required: true,
                                message: '请选择预约时间'
                            }
                        ]
                    })(React.createElement(ReservationTime, { width: "66%", triggerChange: this.triggerChange }))))) : null,
                React.createElement(Col, { span: mapOrder ? 24 : 12 },
                    React.createElement(FormItem, { label: "\u5C0F\u8D39" }, getFieldDecorator('tip', {
                        initialValue: 0
                    })(React.createElement(InputNumber, { min: 0, formatter: function (value) { return value + "\u5143"; }, parser: function (value) { return (value ? value.replace('元', '') : ''); }, onChange: this.triggerChange }))))),
            showDateTimePicker && !mapOrder ? (React.createElement(Row, { gutter: 2 },
                React.createElement(Col, { span: mapOrder ? 24 : 19, pull: 2 },
                    React.createElement(FormItem, { label: "\u9884\u7EA6\u65F6\u95F4" }, getFieldDecorator('reservationTime', {
                        initialValue: moment(moment()
                            .add(2, 'h')
                            .add(10, 'm'), 'YYYY-MM-DD HH:mm'),
                        rules: [
                            {
                                required: true,
                                message: '请选择预约时间'
                            }
                        ]
                    })(React.createElement(ReservationTime, { triggerChange: this.triggerChange })))))) : null,
            React.createElement(Row, { gutter: 2 },
                React.createElement(Col, { span: mapOrder ? 24 : 12 },
                    React.createElement(FormItem, { label: "\u53D6\u4EF6\u5907\u6CE8", wrapperCol: { span: 16 } }, getFieldDecorator('remarks', {
                        initialValue: ''
                    })(React.createElement(TextArea, null))))),
            React.createElement(Row, { gutter: 1 },
                React.createElement(Col, { span: mapOrder ? 24 : 12 },
                    React.createElement(FormItem, { label: "\u5E73\u53F0\u4F18\u60E0\u5238", wrapperCol: { span: 16 } }, getFieldDecorator('platformCoupon', {
                        initialValue: { globalCouponId: globalCouponId }
                    })(React.createElement("div", { onClick: function () {
                            return _this.handleOpenCouponModal({
                                isGlobalCouponModal: true
                            });
                        } },
                        globalCouponName,
                        React.createElement(AIcon, { type: "right", style: { fontSize: '12px', color: '#333333' } })))))),
            React.createElement(SBalance, null,
                React.createElement("div", { className: "top" },
                    React.createElement("div", { className: "lable" }, "\u6613\u9001\u4F59\u989D\uFF1A"),
                    React.createElement("div", null,
                        balance / 100 || '',
                        "\u5143"),
                    React.createElement("div", { className: "recharge", onClick: this.toggleRechargeVisible }, "\u53BB\u5145\u503C")),
                maxPayAmount > balance && (React.createElement("div", { className: "red" }, "\u4F59\u989D\u4E0D\u8DB3\uFF0C\u8BF7\u5145\u503C"))),
            React.createElement("div", null,
                React.createElement(FormItem, { label: "\u9009\u62E9\u914D\u9001\u5E73\u53F0", labelCol: {} }, getFieldDecorator('platform', {
                    initialValue: platformInit.platformChecked,
                    rules: [
                        {
                            required: true,
                            message: '请选择配送平台'
                        }
                    ]
                })(React.createElement(PlatformChooseWrapper, null, valuations && !!valuations.length
                    ? valuations.map(function (valuation, key) {
                        var platform = valuation.platform, amount = valuation.amount, balanceAmount = valuation.balanceAmount, reducedPrice = valuation.reducedPrice, errorMsg = valuation.errorMsg, distance = valuation.distance, couponGroupName = valuation.couponGroupName, couponGroupId = valuation.couponGroupId;
                        return (React.createElement(DeliveryPlatform, { key: key, platform: platform, amount: amount, balanceAmount: balanceAmount, reducedPrice: reducedPrice, selected: platformInit.platformChecked.includes(platform), errorMsg: errorMsg, distance: distance, couponGroupName: couponGroupName, couponGroupId: couponGroupId, onClick: function () {
                                return _this.handleSelectPlatformItem(valuation);
                            }, handleOpenCouponModal: _this.handleOpenCouponModal }));
                    })
                    : null)))),
            React.createElement(SendDeliveryCouponContext.Provider, { value: {
                    handleCalculate: this.handleCalculate,
                    couponData: couponData,
                    selectCouponPlatformId: selectCouponPlatformId,
                    couponGroupId: couponGroupId,
                    couponGroupName: couponGroupName,
                    platformChecked: platformChecked,
                    globalCouponId: globalCouponId,
                    globalCouponName: globalCouponName,
                    isGlobalCouponModal: isGlobalCouponModal
                } },
                React.createElement(CouponListModal, { visible: visible, toggleVisible: this.toggleVisible })),
            React.createElement(RechargeModal, { visible: rechargeVisible, toggleVisible: this.toggleRechargeVisible })));
    };
    return SendDeliveryFormPlatform;
}(React.Component));
export var WrappedSendDeliveryFormPlatform = Form.create({})(SendDeliveryFormPlatform);
