var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { isNull } from 'lodash';
import moment from 'moment';
import { ORDER_STATUS_KEYS_MAP, ORDER_SUB_STATUS_KEYS_MAP, ORDER_SUB_STATUS_MAP, ORDER_STATUS_MAP_VALUE } from './constants';
var getRetreatOrderStatus = function (status) {
    return ((status === ORDER_SUB_STATUS_MAP.REFUND_APPLY &&
        ORDER_SUB_STATUS_KEYS_MAP[status]) ||
        0);
};
var getCancelOrderStatus = function (status) {
    return ((status === ORDER_SUB_STATUS_MAP.CANCELED_APPLY &&
        ORDER_SUB_STATUS_KEYS_MAP[status]) ||
        0);
};
var getDayTimeStamp = function (time) {
    var dayTime = time.format('YYYY-MM-DD');
    return moment(dayTime + " 00:00:00").valueOf();
};
var getOrderQuery = function (_a) {
    var status = _a.status, platform = _a.platform, userName = _a.userName, phone = _a.phone, platformDaySn = _a.platformDaySn, daySn = _a.daySn, orderTimed = _a.orderTimed, expectationTime = _a.expectationTime, orderTimeRange = _a.orderTimeRange, startOrderTime = _a.startOrderTime, endOrderTime = _a.endOrderTime, isBigAmount = _a.isBigAmount, reservationOrder = _a.reservationOrder, storeId = _a.storeId, printNum = _a.printNum, page = _a.page, pageSize = _a.pageSize;
    var baseQuery = {};
    if (platform) {
        Object.assign(baseQuery, { platform: platform });
    }
    if (userName) {
        Object.assign(baseQuery, { userName: userName });
    }
    if (phone) {
        Object.assign(baseQuery, { phone: phone });
    }
    if (platformDaySn) {
        Object.assign(baseQuery, { platformDaySn: platformDaySn });
    }
    if (daySn) {
        Object.assign(baseQuery, { daySn: daySn });
    }
    if (orderTimed) {
        Object.assign(baseQuery, { orderTimed: orderTimed });
    }
    if (expectationTime) {
        Object.assign(baseQuery, { expectationTime: expectationTime });
    }
    if (orderTimeRange) {
        var _b = __read(orderTimeRange, 2), start = _b[0], end = _b[1];
        if (start && end) {
            Object.assign(baseQuery, {
                startOrderTime: getDayTimeStamp(start),
                endOrderTime: getDayTimeStamp(end)
            });
        }
    }
    if (startOrderTime && endOrderTime) {
        Object.assign(baseQuery, {
            startOrderTime: startOrderTime,
            endOrderTime: endOrderTime
        });
    }
    if (isBigAmount && !isNull(isBigAmount)) {
        Object.assign(baseQuery, { isBigAmount: isBigAmount });
    }
    if (reservationOrder && !isNull(reservationOrder)) {
        Object.assign(baseQuery, { reservationOrder: reservationOrder });
    }
    if (ORDER_STATUS_KEYS_MAP.indexOf(status) > -1) {
        Object.assign(baseQuery, {
            orderStatus: ORDER_STATUS_MAP_VALUE[status]
        });
    }
    if (getRetreatOrderStatus(status)) {
        Object.assign(baseQuery, {
            retreatOrderStatus: getRetreatOrderStatus(status)
        });
    }
    if (getCancelOrderStatus(status)) {
        Object.assign(baseQuery, {
            cancelOrderStatus: getCancelOrderStatus(status)
        });
    }
    if (status === ORDER_SUB_STATUS_MAP.URGE_APPLY) {
        Object.assign(baseQuery, {
            urgeApply: 1
        });
    }
    if (status === ORDER_SUB_STATUS_MAP.DISTRIBUTION_ABNORMAL) {
        Object.assign(baseQuery, {
            exceptionStatus: 1
        });
    }
    if (storeId) {
        Object.assign(baseQuery, {
            storeId: storeId
        });
    }
    if (printNum) {
        Object.assign(baseQuery, {
            printNum: printNum
        });
    }
    if (page) {
        Object.assign(baseQuery, {
            page: page
        });
    }
    if (pageSize) {
        Object.assign(baseQuery, {
            pageSize: pageSize
        });
    }
    return baseQuery;
};
export default getOrderQuery;
