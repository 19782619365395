import * as React from 'react';
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Tabs } from 'antd';
import PrintCases from './PrintCases';
import Printers from './Printers';
import ReceiptTemplate from './ReceiptTemplate';
var TabPane = Tabs.TabPane;
var printTabs = [
    {
        key: 'PRINT_TEMPLATE',
        name: '打印模板',
        path: '/template',
        component: ReceiptTemplate
    },
    {
        key: 'PRINTER',
        name: '打印机',
        path: '/printers',
        component: Printers
    },
    {
        key: 'CASES',
        name: '打印方案',
        path: '/cases',
        component: PrintCases
    }
];
var PrinterManagement = function () {
    var match = useRouteMatch();
    var history = useHistory();
    var pathname = useLocation().pathname;
    var getFullPath = function (compPath) {
        return "" + match.path + compPath;
    };
    var handleTabChange = function (activeKey) {
        var activeTab = printTabs.filter(function (store) { return store.key === activeKey; })[0];
        var path = getFullPath(activeTab.path);
        history.push(path);
    };
    var defaultActiveTab = printTabs[0];
    var activeTab = printTabs.filter(function (store) { return pathname.includes(store.path); })[0];
    if (!activeTab) {
        history.replace(getFullPath(defaultActiveTab.path));
        return null;
    }
    return (React.createElement(Tabs, { type: "card", onChange: handleTabChange, activeKey: activeTab.key }, printTabs.map(function (item) {
        return (React.createElement(TabPane, { tab: item.name, key: item.key },
            React.createElement(Route, { path: getFullPath(item.path), component: item.component })));
    })));
};
export default PrinterManagement;
