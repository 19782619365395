var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { Formik } from 'formik';
import produce from 'immer';
import ajax from '@utils/ajax';
import { getChannelNumberCheck } from '@/apis/generalNumber';
var EOneByMoreOpen;
(function (EOneByMoreOpen) {
    EOneByMoreOpen[EOneByMoreOpen["CLOSE"] = 0] = "CLOSE";
    EOneByMoreOpen[EOneByMoreOpen["OPEN"] = 1] = "OPEN";
})(EOneByMoreOpen || (EOneByMoreOpen = {}));
var FormItem = Form.Item;
var Option = Select.Option;
var RangePicker = DatePicker.RangePicker;
var formItemLayout = {
    labelCol: {
        xl: {
            span: 8
        },
        xxl: {
            span: 6
        }
    },
    wrapperCol: {
        xl: {
            span: 16
        },
        xxl: {
            span: 18
        }
    }
};
var formItemStyles = {
    display: 'flex',
    marginBottom: '10px'
};
var ORDER_TIME_LIST = [5, 30]; // min
var EXPECTATION_TIME_LIST = [60, 120]; // min
var getPlatformListReq = function () {
    return ajax.get('/dictionary/list?group=orderPlatform');
};
export var searchFormInitValues = {
    platform: '',
    platformDaySn: '',
    phone: '',
    orderTimeRange: [undefined, undefined],
    daySn: '',
    userName: '',
    orderTimed: '',
    expectationTime: '',
    isBigAmount: '',
    reservationOrder: '',
    storeId: '',
    printNum: ''
};
var SearchForm = /** @class */ (function (_super) {
    __extends(SearchForm, _super);
    function SearchForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            platformList: [],
            collapse: true,
            oneByMoreOpen: false
        };
        _this.toggleCollapse = function () {
            _this.setState(produce(function (draft) {
                draft.collapse = !_this.state.collapse;
            }));
        };
        return _this;
    }
    SearchForm.prototype.componentDidMount = function () {
        var _this = this;
        getPlatformListReq().then(function (_a) {
            var data = _a.data;
            _this.setState(produce(function (draft) {
                draft.platformList = data || [];
            }));
        });
        this.setState(produce(function (draft) {
            draft.oneByMoreOpen = true;
        }));
        getChannelNumberCheck().then(function (_a) {
            var data = _a.data;
            if (data && (data.channelOneToMany + data.platformOneToMany) >= 1) {
                _this.setState(produce(function (draft) {
                    draft.oneByMoreOpen = true;
                }));
            }
        });
    };
    SearchForm.prototype.render = function () {
        var _this = this;
        var _a = this.state, platformList = _a.platformList, collapse = _a.collapse, oneByMoreOpen = _a.oneByMoreOpen;
        var _b = this.props, tabSwitch = _b.tabSwitch, tabContent = _b.tabContent, onSubmit = _b.onSubmit, storeList = _b.storeList;
        return (React.createElement(Formik, { initialValues: searchFormInitValues, onSubmit: function (values) {
                onSubmit(values);
            }, onReset: function (values, actions) {
                actions.setValues(searchFormInitValues);
                onSubmit(searchFormInitValues);
            } }, function (_a) {
            var values = _a.values, setFieldValue = _a.setFieldValue, handleReset = _a.handleReset, submitForm = _a.submitForm;
            return (React.createElement(Form, __assign({}, formItemLayout),
                tabContent === 'list' && (React.createElement(React.Fragment, null,
                    React.createElement(Row, { gutter: 16 },
                        React.createElement(Col, { span: 6 },
                            React.createElement(FormItem, { style: formItemStyles, label: "\u63A5\u5355\u5E73\u53F0" },
                                React.createElement(Select, { onChange: function (value) {
                                        return setFieldValue('platform', value);
                                    }, value: values.platform }, platformList.length !== 0 &&
                                    platformList.map(function (platform, key) {
                                        return (React.createElement(Option, { key: key, value: platform.code }, platform.value));
                                    })))),
                        React.createElement(Col, { span: 6 },
                            React.createElement(FormItem, { style: formItemStyles, label: "\u5E73\u53F0\u6D41\u6C34\u53F7" },
                                React.createElement(Input, { onChange: function (_a) {
                                        var value = _a.target.value;
                                        return setFieldValue('platformDaySn', value);
                                    }, value: values.platformDaySn }))),
                        React.createElement(Col, { span: 6 },
                            React.createElement(FormItem, { style: formItemStyles, label: "\u624B\u673A\u5C3E\u53F7" },
                                React.createElement(Input, { onChange: function (_a) {
                                        var value = _a.target.value;
                                        return setFieldValue('phone', value);
                                    }, value: values.phone }))),
                        React.createElement(Col, { span: 6 },
                            React.createElement(FormItem, { style: formItemStyles, label: "\u4E0B\u5355\u65E5\u671F" },
                                React.createElement(RangePicker, { format: "YYYY-MM-DD", onChange: function (value) {
                                        return setFieldValue('orderTimeRange', value);
                                    }, value: values.orderTimeRange })))),
                    React.createElement(Row, { gutter: 16, style: collapse ? { display: 'none' } : {} },
                        React.createElement(Col, { span: 6 },
                            React.createElement(FormItem, { style: formItemStyles, label: "\u6613\u9001\u6D41\u6C34\u53F7" },
                                React.createElement(Input, { onChange: function (_a) {
                                        var value = _a.target.value;
                                        return setFieldValue('daySn', value);
                                    }, value: values.daySn }))),
                        React.createElement(Col, { span: 6 },
                            React.createElement(FormItem, { style: formItemStyles, label: "\u6536\u8D27\u4EBA" },
                                React.createElement(Input, { placeholder: "\u4EC5\u652F\u6301\u59D3\u6C0F", onChange: function (_a) {
                                        var value = _a.target.value;
                                        return setFieldValue('userName', value);
                                    }, value: values.userName }))),
                        React.createElement(Col, { span: 6 },
                            React.createElement(FormItem, { style: formItemStyles, label: "\u4E0B\u5355\u65F6\u95F4" },
                                React.createElement(Select, { onChange: function (value) {
                                        return setFieldValue('orderTimed', value);
                                    }, value: values.orderTimed }, ORDER_TIME_LIST.map(function (time, key) {
                                    return (React.createElement(Option, { key: key, value: "" + time },
                                        "\u4E0B\u5355\u65F6\u95F4",
                                        time,
                                        "\u5206\u949F\u540E"));
                                })))),
                        React.createElement(Col, { span: 6 },
                            React.createElement(FormItem, { style: formItemStyles, label: "\u671F\u671B\u9001\u8FBE\u65F6\u95F4" },
                                React.createElement(Select, { onChange: function (value) { return setFieldValue('expectationTime', value); }, value: values.expectationTime }, EXPECTATION_TIME_LIST.map(function (time, key) {
                                    var hour = time / 60;
                                    return (React.createElement(Option, { key: key, value: "" + time },
                                        hour,
                                        "\u5C0F\u65F6\u5185"));
                                }))))),
                    React.createElement(Row, { gutter: 16, style: collapse ? { display: 'none' } : {} },
                        React.createElement(Col, { span: 6 },
                            React.createElement(FormItem, { style: formItemStyles, label: "\u5927\u989D\u8BA2\u5355" },
                                React.createElement(Select, { onChange: function (value) {
                                        return setFieldValue('isBigAmount', value);
                                    }, value: values.isBigAmount },
                                    React.createElement(Option, { value: "1" }, "\u662F"),
                                    React.createElement(Option, { value: "0" }, "\u5426")))),
                        React.createElement(Col, { span: 6 },
                            React.createElement(FormItem, { style: formItemStyles, label: "\u9884\u7EA6\u5355" },
                                React.createElement(Select, { onChange: function (value) { return setFieldValue('reservationOrder', value); }, value: values.reservationOrder },
                                    React.createElement(Option, { value: "0" }, "\u4E0D\u770B\u9884\u7EA6\u5355"),
                                    React.createElement(Option, { value: "1" }, "\u4EC5\u770B\u9884\u7EA6\u5355"),
                                    React.createElement(Option, { value: "2" }, "\u9884\u7EA6\u4ECA\u65E5"),
                                    React.createElement(Option, { value: "3" }, "\u9884\u7EA6\u660E\u65E5")))),
                        React.createElement(Col, { span: 6 },
                            React.createElement(FormItem, { style: formItemStyles, label: "\u5B9E\u4F53\u95E8\u5E97" },
                                React.createElement(Select, { onChange: function (value) {
                                        return setFieldValue('storeId', value);
                                    }, value: values.storeId }, storeList &&
                                    storeList.map(function (store, key) {
                                        return (React.createElement(Option, { key: key, value: store.id },
                                            store.name,
                                            " - ",
                                            store.numbering));
                                    })))),
                        React.createElement(Col, { span: 6 },
                            React.createElement(FormItem, { style: formItemStyles, label: "\u6253\u5370\u5F20\u6570" },
                                React.createElement(Input, { style: { width: '100%' }, onChange: function (_a) {
                                        var value = _a.target.value;
                                        return setFieldValue('printNum', value);
                                    }, value: values.printNum })))))),
                React.createElement(Row, { gutter: 24 },
                    React.createElement(Col, { span: 12, style: { marginTop: '3px', textAlign: 'left' } },
                        React.createElement(Button.Group, null,
                            React.createElement(Button, { type: tabContent === 'list' ? 'primary' : 'ghost', onClick: function () { return tabSwitch('list'); } }, "\u5217\u8868\u6A21\u5F0F"),
                            oneByMoreOpen && (React.createElement(Button, { type: tabContent === 'map' ? 'primary' : 'ghost', onClick: function () { return tabSwitch('map'); } }, "\u5730\u56FE\u6A21\u5F0F\uFF08\u4E00\u53D6\u591A\u6295\uFF0C\u4EC5\u5C55\u793A\u5F85\u914D\u9001\u8BA2\u5355\uFF09")))),
                    tabContent === 'list' && (React.createElement(Col, { span: 12, style: { marginTop: '3px', textAlign: 'right' } },
                        React.createElement(Button, { type: "link", onClick: _this.toggleCollapse }, collapse ? '展开' : '收起'),
                        React.createElement(Button, { type: "primary", onClick: function () { return submitForm(); } }, "\u641C\u7D22"),
                        React.createElement(Button, { style: { marginLeft: 8 }, onClick: handleReset }, "\u91CD\u7F6E"))))));
        }));
    };
    return SearchForm;
}(React.Component));
export default SearchForm;
