var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/*
 * @Author: hijack
 * @Date: 2021-06-07 16:13:33
 * @LastEditTime: 2021-06-09 15:59:53
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/container/Merchant/List/index.tsx
 */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import ListItem from './components/ListItem';
import './index.less';
var mapDispatch = function (dispatch) { return ({
    loginWithPassword: dispatch.user.loginWithPassword,
    loginWithSMS: dispatch.user.loginWithSMS,
    merchantListEffect: dispatch.merchant.merchantListEffect,
    chooseMerchantEffect: dispatch.merchant.chooseMerchantEffect,
}); };
var List = function (props) {
    var dispatch = useDispatch();
    var _a = __read(useState([]), 2), merchantList = _a[0], setMerchantList = _a[1];
    var _b = __read(React.useState({}), 2), selectMerchantItem = _b[0], setSelectMerchantItem = _b[1];
    var history = useHistory();
    useEffect(function () {
        dispatch({
            type: 'merchant/merchantListEffect',
            payload: {
                params: {
                    test: 2323,
                },
                callback: function (back) {
                    var merchantList = back ? back['merchantList'] : [];
                    setMerchantList(merchantList);
                },
            },
        });
    }, []);
    var onSaveMerchant = function () {
        if (selectMerchantItem && !!Object.keys(selectMerchantItem).length) {
            dispatch({
                type: 'merchant/chooseMerchantEffect',
                payload: {
                    params: __assign(__assign({}, selectMerchantItem), { chooseMerchantId: selectMerchantItem.merchantId }),
                    callback: function (back) {
                        console.log(back, 'back');
                    },
                },
            });
        }
    };
    var prefixCls = 'merchantList';
    return (React.createElement("div", { className: "" + prefixCls },
        React.createElement(ListItem, { data: merchantList, setSelectMerchantItem: setSelectMerchantItem, selectMerchantItem: selectMerchantItem }),
        React.createElement(Button, { className: prefixCls + "-save", onClick: onSaveMerchant }, "\u786E\u5B9A")));
};
export default List;
