/*
 * @Author: hijack
 * @Date: 2021-06-08 16:32:26
 * @LastEditTime: 2021-06-10 14:53:36
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/models/merchant.ts
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import HttpRequest from '@utils/axios';
import { getBaseUrl } from '@utils/apiConfig';
import { setItem } from '@utils/localStorage';
import { getDefaultMenuByRoleId } from '@/apis/generalNumber';
var request = new HttpRequest(getBaseUrl('merchant'));
export var merchant = {
    state: {
        merchantList: [],
        lastChooseMerchant: {},
        DEFAULTMENU: [],
        MENUDCR: [],
        MENUDOM: [],
    },
    reducers: {
        updateState: function (state, payload) {
            state = __assign(__assign({}, state), payload);
            return state;
        },
    },
    effects: function (dispatch) { return ({
        merchantListEffect: function (_a) {
            var params = _a.params, callback = _a.callback;
            return __awaiter(this, void 0, void 0, function () {
                var _b, data_1, err, menu, arrayCode_1, DEFAULTmenu, error_1;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _c.trys.push([0, 7, , 8]);
                            return [4 /*yield*/, request.post('/merchant/middlePage/chooseMerchant', __assign({}, params))];
                        case 1:
                            _b = _c.sent(), data_1 = _b.data, err = _b.err;
                            if (err) {
                                localStorage.clear();
                                callback({ err: err });
                            }
                            if (!(data_1 && data_1['merchantList'] && data_1.lastChooseMerchant)) return [3 /*break*/, 5];
                            if (!(data_1.merchantList.length === 1)) return [3 /*break*/, 4];
                            return [4 /*yield*/, getDefaultMenuByRoleId(data_1.merchantList[0].roleId)];
                        case 2:
                            menu = _c.sent();
                            dispatch.merchant.updateState({ MENUDCR: menu });
                            arrayCode_1 = data_1.merchantList[0].menuArray;
                            DEFAULTmenu = menu.filter(function (item) {
                                return arrayCode_1.split(",").indexOf(String(item.menuId)) > -1;
                            });
                            dispatch.merchant.updateState({ DEFAULTMENU: DEFAULTmenu });
                            setItem('defaultMenu', JSON.stringify(DEFAULTmenu));
                            return [4 /*yield*/, setItem('lastChooseMerchant', JSON.stringify(__assign(__assign({}, data_1.lastChooseMerchant), data_1.merchantList[0])))];
                        case 3:
                            _c.sent();
                            if (callback) {
                                callback(__assign(__assign({}, data_1), { DEFAULTmenu: DEFAULTmenu }));
                            }
                            _c.label = 4;
                        case 4:
                            data_1.merchantList.map(function (item) {
                                if (item.merchantId === data_1.lastChooseMerchant.lastChooseMerchantId) {
                                    setItem('lastChooseMerchant', JSON.stringify(__assign(__assign({}, data_1.lastChooseMerchant), item)));
                                }
                            });
                            return [3 /*break*/, 6];
                        case 5:
                            dispatch.merchant.updateState(__assign({}, data_1));
                            _c.label = 6;
                        case 6:
                            if (callback && data_1.merchantList.length !== 1) {
                                callback(data_1);
                            }
                            return [3 /*break*/, 8];
                        case 7:
                            error_1 = _c.sent();
                            console.log(error_1);
                            return [3 /*break*/, 8];
                        case 8: return [2 /*return*/];
                    }
                });
            });
        },
        chooseMerchantEffect: function (_a) {
            var params = _a.params, callback = _a.callback;
            return __awaiter(this, void 0, void 0, function () {
                var back, error_2;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, request.post('/merchant/middlePage/recordLastChooseMerchant', { chooseMerchantId: params.merchantId })];
                        case 1:
                            back = _b.sent();
                            if (back && callback) {
                                // console.log("callback",callback);
                                callback(back);
                            }
                            return [3 /*break*/, 3];
                        case 2:
                            error_2 = _b.sent();
                            console.log(error_2);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        refMenuEffect: function (_a) {
            var params = _a.params, getMenuItem = _a.getMenuItem;
            return __awaiter(this, void 0, void 0, function () {
                var menu, DEFAULTmenu, error_3;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 3, , 4]);
                            return [4 /*yield*/, getDefaultMenuByRoleId(params.roleId)];
                        case 1:
                            menu = _b.sent();
                            dispatch.merchant.updateState({ MENUDCR: menu });
                            DEFAULTmenu = menu.filter(function (item) {
                                return params.menuArray.split(",").indexOf(String(item.menuId)) > -1;
                            });
                            dispatch.merchant.updateState({ DEFAULTMENU: DEFAULTmenu });
                            setItem('defaultMenu', JSON.stringify(DEFAULTmenu));
                            return [4 /*yield*/, getMenuItem(DEFAULTmenu)
                                // await dispatch.merchant.getMenuArray(params,getMenuItem)
                            ];
                        case 2: return [2 /*return*/, _b.sent()
                            // await dispatch.merchant.getMenuArray(params,getMenuItem)
                        ];
                        case 3:
                            error_3 = _b.sent();
                            console.log(error_3);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        updateMenuEffect: function (DEFAULTmenu) {
            return __awaiter(this, void 0, void 0, function () {
                var error_4;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, dispatch.merchant.updateState({ DEFAULTMENU: DEFAULTmenu })];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            error_4 = _a.sent();
                            console.log(error_4);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
    }); }
};
