var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { getCloudPrinterStatus, getNetWorkPrinterStatus } from '@printer';
import { store } from '@store';
import ajax from '@utils/ajax';
import { loadSDK } from '@utils/lodopAdapt';
import { PrinterTypes } from '@printer';
export var PrinterStatus;
(function (PrinterStatus) {
    PrinterStatus[PrinterStatus["OFFLINE"] = 0] = "OFFLINE";
    PrinterStatus[PrinterStatus["ONLINE"] = 1] = "ONLINE";
    PrinterStatus[PrinterStatus["PAPEROUT"] = 2] = "PAPEROUT";
    PrinterStatus[PrinterStatus["UNKNOWN"] = 3] = "UNKNOWN";
})(PrinterStatus || (PrinterStatus = {}));
export var PrintPaperFormat;
(function (PrintPaperFormat) {
    PrintPaperFormat[PrintPaperFormat["Small"] = 1] = "Small";
    PrintPaperFormat[PrintPaperFormat["Middle"] = 2] = "Middle";
    PrintPaperFormat[PrintPaperFormat["Large"] = 3] = "Large";
    PrintPaperFormat[PrintPaperFormat["SizeA4"] = 4] = "SizeA4";
})(PrintPaperFormat || (PrintPaperFormat = {}));
var setPrinterStatusAction = function (payload) { return ({
    type: 'printers/setStatus',
    payload: payload
}); };
var detectPrinterStatus = function (printer) {
    var customCallback = function (id, status) {
        store.dispatch(setPrinterStatusAction({ id: id, status: status }));
    };
    var onlineCallback = function () {
        store.dispatch(setPrinterStatusAction({
            id: printer.id,
            status: PrinterStatus.ONLINE
        }));
    };
    var offlineCallback = function () {
        store.dispatch(setPrinterStatusAction({
            id: printer.id,
            status: PrinterStatus.OFFLINE
        }));
    };
    switch (printer.type) {
        case PrinterTypes.LocalNetwork:
            getNetWorkPrinterStatus({ address: printer.ip })
                .then(onlineCallback)
                .catch(offlineCallback);
            break;
        case PrinterTypes.LocalUSB:
        case PrinterTypes.LocalUSBA4:
            // const [vid, pid] = printer.usb.split(',')
            try {
                // getUSBPrinterStatus({ vid, pid })
                //   .then(onlineCallback)
                //   .catch(offlineCallback)
                loadSDK(function () {
                    var win = window;
                    var usbPrinter = [];
                    var LODOP = win.getCLodop();
                    LODOP.On_Return = function (TaskID, Value) {
                        var list = JSON.parse(Value).list;
                        list.map(function (item) {
                            usbPrinter.push(item.name);
                        });
                        usbPrinter.includes(printer.usb)
                            ? onlineCallback()
                            : offlineCallback();
                    };
                    LODOP.GET_VALUE('AO_PRINT_JSON_PrinterList', 0);
                });
            }
            catch (error) {
                offlineCallback();
            }
            break;
        case PrinterTypes.CloudPrinter:
            var _a = printer.cloudPrinter, platform = _a.type, printerAuthData = __rest(_a, ["type"]);
            getCloudPrinterStatus({
                platform: platform,
                printerAuthData: printerAuthData
            })
                .then(function (status) {
                customCallback(printer.id, status);
            })
                .catch(offlineCallback);
            break;
        default:
            customCallback(printer.id, PrinterStatus.UNKNOWN);
            break;
    }
};
export var printers = {
    state: {
        data: {
            list: [],
            page: 1,
            pageSize: 10,
            total: 0
        },
        loading: false
    },
    reducers: {
        setLoading: function (state, loading) {
            state.loading = loading;
            return state;
        },
        setData: function (state, payload) {
            state.data.list = payload.list;
            state.data.page = payload.page;
            state.data.pageSize = payload.pageSize;
            state.data.total = payload.total;
            return state;
        },
        setStatus: function (state, payload) {
            state.data.list.forEach(function (printer, index) {
                if (printer.id === payload.id) {
                    state.data.list[index]['status'] = payload.status;
                }
            });
            return state;
        },
        addItem: function (state, payload) {
            state.data.list = __spread([payload], state.data.list);
            state.data.total = state.data.total + 1;
            return state;
        },
        refreshItem: function (state, payload) {
            // fetchData()
            // state.data.list = [payload, ...state.data.list]
            // state.data.total = state.data.total + 1
            return state;
        },
        deleteItem: function (state, id) {
            state.data.list = state.data.list.filter(function (printer) { return printer.id !== id; });
            return state;
        }
    },
    effects: function (dispatch) { return ({
        fetchData: function (_a) {
            var _b = _a.page, page = _b === void 0 ? 1 : _b, _c = _a.pageSize, pageSize = _c === void 0 ? 10 : _c;
            return __awaiter(this, void 0, void 0, function () {
                var data, usbMap_1;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            dispatch.printers.setLoading(true);
                            return [4 /*yield*/, ajax.get("/printer/list?toPage=" + page + "&pageSize=" + pageSize)];
                        case 1:
                            data = (_d.sent()).data;
                            dispatch.printers.setData(data);
                            dispatch.printers.setLoading(false);
                            if (navigator.platform.indexOf('Win') > -1) {
                                usbMap_1 = [];
                                data.list.map(function (record) {
                                    if (record &&
                                        (record.type === PrinterTypes.LocalUSB || record.type === PrinterTypes.LocalUSBA4)) {
                                        usbMap_1.push(record);
                                    }
                                    else {
                                        detectPrinterStatus(record);
                                    }
                                });
                                usbMap_1.map(function (usb, index) {
                                    setTimeout(function () {
                                        detectPrinterStatus(usb);
                                    }, (index + 1) * 3000);
                                });
                            }
                            else {
                                data.list.forEach(detectPrinterStatus);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        },
        deleteItemWithReq: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, ajax.post("/printer/delete", { id: id })];
                        case 1:
                            _a.sent();
                            dispatch.printers.deleteItem(id);
                            return [2 /*return*/, Promise.resolve()];
                    }
                });
            });
        }
    }); }
};
