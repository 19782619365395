var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Col, Row, Select } from 'antd';
import produce from 'immer';
import ajax from '@utils/ajax';
var Option = Select.Option;
var LocationTypes;
(function (LocationTypes) {
    LocationTypes[LocationTypes["Province"] = 0] = "Province";
    LocationTypes[LocationTypes["Region"] = 1] = "Region";
    LocationTypes[LocationTypes["County"] = 2] = "County";
})(LocationTypes || (LocationTypes = {}));
var getCityByCode = function (code) {
    if (code === void 0) { code = '0'; }
    return ajax.get("/city/list/by/code?code=" + code);
};
var genOptions = function (data) {
    if (!data) {
        return null;
    }
    return data.map(function (item) {
        return (React.createElement(Option, { key: item.code, value: item.code }, item.name));
    });
};
// const formatObject = (rest: object, current: object) => {
//   Object.keys(rest).map(keyName => {
//     if (rest[keyName]) {
//       current[keyName] = rest[keyName]
//     }
//   })
//   return rest
// }
var CitySelect = /** @class */ (function (_super) {
    __extends(CitySelect, _super);
    function CitySelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            provinceList: [],
            regionList: [],
            countyList: [],
            province: '',
            region: '',
            county: ''
        };
        _this.getRegion = function () {
            var _a = _this.state, province = _a.province, provinceList = _a.provinceList, region = _a.region, regionList = _a.regionList;
            if (!province || !region) {
                return null;
            }
            var provinceSelected = provinceList.filter(function (item) { return item.code === province; });
            var regionSelected = regionList.filter(function (item) { return item.code === region; });
            var provinceText = provinceSelected[0]['name'];
            var regionText = regionSelected[0]['name'];
            if (regionText !== '市辖区' && regionText !== '县') {
                return regionText;
            }
            else {
                return provinceText;
            }
        };
        _this.getLocationText = function () {
            var _a = _this.state, province = _a.province, provinceList = _a.provinceList, region = _a.region, regionList = _a.regionList, county = _a.county, countyList = _a.countyList;
            if (!province || !region) {
                return null;
            }
            var provinceSelected = provinceList.filter(function (item) { return item.code === province; });
            var regionSelected = regionList.filter(function (item) { return item.code === region; });
            var countySelected = countyList.filter(function (item) { return item.code === county; });
            var provinceText = provinceSelected[0]['name'];
            var regionText = regionSelected[0]['name'];
            var countyText = countySelected[0]['name'];
            return (provinceText + "," + regionText + "," + countyText).replace(/,市辖区/g, '');
        };
        _this.handleChange = function (_a) {
            var type = _a.type, value = _a.value;
            var state = _this.state;
            switch (type) {
                case LocationTypes.Province:
                    _this.triggerChange({ province: value, region: '', county: '' });
                    getCityByCode(value).then(function (_a) {
                        var regionList = _a.data;
                        return _this.setState(produce(function (draft) {
                            draft.regionList = regionList;
                        }));
                    });
                    break;
                case LocationTypes.Region:
                    _this.triggerChange({
                        province: state.province,
                        region: value,
                        county: ''
                    });
                    getCityByCode(value).then(function (_a) {
                        var countyList = _a.data;
                        return _this.setState(produce(function (draft) {
                            draft.countyList = countyList;
                        }));
                    });
                    break;
                case LocationTypes.County:
                    _this.triggerChange({
                        province: state.province,
                        region: state.region,
                        county: value
                    });
                    break;
                default:
                    break;
            }
        };
        _this.triggerChange = function (changedValue) {
            var onChange = _this.props.onChange;
            if (onChange) {
                // const { province, region, county } = this.state
                onChange(changedValue);
            }
        };
        return _this;
    }
    CitySelect.getDerivedStateFromProps = function (nextProps) {
        if ('value' in nextProps) {
            return nextProps.value;
        }
        return {
            province: '',
            region: '',
            county: ''
        };
    };
    CitySelect.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.state, province = _a.province, region = _a.region;
        getCityByCode().then(function (_a) {
            var provinceList = _a.data;
            _this.setState(produce(function (draft) {
                draft.provinceList = provinceList;
            }));
        });
        if (province) {
            getCityByCode(province).then(function (_a) {
                var regionList = _a.data;
                return _this.setState(produce(function (draft) {
                    draft.regionList = regionList;
                }));
            });
        }
        if (region) {
            getCityByCode(region).then(function (_a) {
                var countyList = _a.data;
                return _this.setState(produce(function (draft) {
                    draft.countyList = countyList;
                }));
            });
        }
    };
    CitySelect.prototype.render = function () {
        var _this = this;
        var state = this.state;
        return (React.createElement(Row, { gutter: 4 },
            React.createElement(Col, { span: 8 },
                React.createElement(Select, { value: state.province, placeholder: "\u9009\u62E9\u7701\u4EFD", onChange: function (value) {
                        _this.handleChange({ type: LocationTypes.Province, value: value });
                    } }, genOptions(state.provinceList))),
            React.createElement(Col, { span: 8 },
                React.createElement(Select, { value: state.region, placeholder: "\u9009\u62E9\u5E02\u533A", onChange: function (value) {
                        _this.handleChange({ type: LocationTypes.Region, value: value });
                    } }, genOptions(state.regionList))),
            React.createElement(Col, { span: 8 },
                React.createElement(Select, { value: state.county, placeholder: "\u9009\u62E9\u533A\u53BF", onChange: function (value) {
                        _this.handleChange({ type: LocationTypes.County, value: value });
                    } }, genOptions(state.countyList)))));
    };
    return CitySelect;
}(React.Component));
export default CitySelect;
