var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Button, Input } from 'antd';
import produce from 'immer';
import styled from 'styled-components';
import FormModal from '@components/FormModal';
var BMap = window.BMap;
var GeoReviseModal = styled.div.withConfig({ displayName: "GeoReviseModal", componentId: "sc-hoyfwp" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
var GeoReviseMap = styled.div.withConfig({ displayName: "GeoReviseMap", componentId: "sc-v2r3kg" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 454px;\n"], ["\n  width: 100%;\n  height: 454px;\n"])));
var GeoReviseBar = styled.div.withConfig({ displayName: "GeoReviseBar", componentId: "sc-1ibzk0n" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  top: 10px;\n  left: 50%;\n  display: flex;\n  align-items: center;\n  margin-left: -250px;\n  padding: 0 25px;\n  width: 500px;\n  height: 50px;\n  border-radius: 2px;\n  background: #fff;\n"], ["\n  position: absolute;\n  top: 10px;\n  left: 50%;\n  display: flex;\n  align-items: center;\n  margin-left: -250px;\n  padding: 0 25px;\n  width: 500px;\n  height: 50px;\n  border-radius: 2px;\n  background: #fff;\n"])));
var GeoInfo = styled.div.withConfig({ displayName: "GeoInfo", componentId: "sc-y4l99f" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  .ant-input {\n    margin-right: 10px;\n    flex: 1;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  .ant-input {\n    margin-right: 10px;\n    flex: 1;\n  }\n"])));
var GeoRevise = /** @class */ (function (_super) {
    __extends(GeoRevise, _super);
    function GeoRevise() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            geoPoint: {
                lng: 0,
                lat: 0
            }
        };
        _this.initialMap = function (_a) {
            var lng = _a.lng, lat = _a.lat;
            var point = new BMap.Point(lng, lat);
            _this.map = new BMap.Map('geo-revise-map');
            _this.map.addControl(new BMap.NavigationControl({ offset: new BMap.Size(480, 230) }));
            _this.map.centerAndZoom(point, 18);
            _this.map.addEventListener('click', _this.handleMapClick);
            _this.addMaker(point);
        };
        _this.handleMapClick = function (e) {
            var _a = e.point, lng = _a.lng, lat = _a.lat;
            var point = new BMap.Point(lng, lat);
            _this.addMaker(point);
            _this.setState(produce(function (draft) {
                draft.geoPoint = {
                    lng: lng,
                    lat: lat
                };
            }));
        };
        _this.handleGeoReviseDone = function () {
            var props = _this.props;
            var state = _this.state;
            props.onOk(state.geoPoint);
        };
        return _this;
    }
    GeoRevise.prototype.componentWillReceiveProps = function (nextProps) {
        var _this = this;
        var visible = nextProps.visible, geoPoint = nextProps.geoPoint;
        if (visible) {
            this.setState(produce(function (draft) {
                draft.geoPoint.lng = geoPoint.lng;
                draft.geoPoint.lat = geoPoint.lat;
            }));
            this.createMap = setImmediate(function () {
                _this.initialMap(__assign({}, geoPoint));
            });
        }
    };
    GeoRevise.prototype.componentWillUnmount = function () {
        clearImmediate(this.createMap);
    };
    GeoRevise.prototype.addMaker = function (point) {
        var marker = new BMap.Marker(point);
        this.map.removeOverlay(this.marker);
        this.map.addOverlay(marker);
        this.marker = marker;
    };
    GeoRevise.prototype.render = function () {
        var props = this.props;
        var state = this.state;
        var _a = state.geoPoint, lng = _a.lng, lat = _a.lat;
        return (React.createElement(FormModal, { title: "\u5730\u56FE\u6821\u6B63", footer: null, visible: props.visible, onCancel: props.onCancel, destroyOnClose: true },
            React.createElement(GeoReviseModal, null,
                React.createElement(GeoReviseMap, { id: "geo-revise-map" }),
                React.createElement(GeoReviseBar, null,
                    React.createElement(GeoInfo, null,
                        React.createElement("span", null, "\u7ECF\u5EA6\uFF1A"),
                        React.createElement(Input, { value: lng, disabled: true }),
                        React.createElement("span", null, "\u7EAC\u5EA6\uFF1A"),
                        React.createElement(Input, { value: lat, disabled: true })),
                    React.createElement(Button, { type: "primary", onClick: this.handleGeoReviseDone }, "\u786E\u8BA4\u5750\u6807")))));
    };
    return GeoRevise;
}(React.Component));
export default GeoRevise;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
