var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import FormModal from '@components/FormModal';
import { useToggle } from '@hooks';
import { getItem } from '@utils/localStorage';
import EditStoreForm from './EditStoreForm';
var mapDispatch = function (dispatch) { return ({
    addStoresItem: dispatch.stores.addItemWithReq,
    editStoresItem: dispatch.stores.editItemWithReq
}); };
var EditStoreModal = function (props) {
    var dispatch = useDispatch();
    var _a = __read(useToggle(false), 3), confirmLoading = _a[0], startConfirmLoading = _a[1], stopConfirmLoading = _a[2];
    var formRef = React.useRef();
    var handleCancel = function () {
        props.toggleVisible();
    };
    var handleSubmit = function (resubmit) {
        var _a = mapDispatch(dispatch), addStoresItem = _a.addStoresItem, editStoresItem = _a.editStoresItem;
        var initialValue = props.initialValue;
        var editStoreForm = formRef.current.props.form;
        var _b = formRef.current.state, geo = _b.geo, locationText = _b.locationText;
        editStoreForm.validateFields(function (errors, values) { return __awaiter(void 0, void 0, void 0, function () {
            var place, address, lastChooseMerchant, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (errors) {
                            return [2 /*return*/, false];
                        }
                        if (resubmit === true) {
                            if (!values.idCardFaceImg || !values.idCardNationalEmblemImg) {
                                message.error('请上传负责人身份证照片');
                                return [2 /*return*/, false];
                            }
                            if (!values.businessLicenceImg || !values.storeFacadeImg) {
                                message.error('请上传营业执照与店铺门面照');
                                return [2 /*return*/, false];
                            }
                            if (!values.holdingIdCardImg || !values.holdingBusinessLicenceImg) {
                                message.error('请上传手持身份证和手持营业执照');
                                return [2 /*return*/, false];
                            }
                        }
                        place = formRef.current.getPlaceByUid(values.address);
                        address = place ? place.name : values.address;
                        lastChooseMerchant = getItem('lastChooseMerchant') &&
                            JSON.parse(getItem('lastChooseMerchant'));
                        data = {
                            merchantId: lastChooseMerchant.merchantId,
                            name: values.name,
                            phone: values.phone.replace(/ /g, ''),
                            type: Number(values.type),
                            address: address,
                            houseNumber: values.detailsAddress,
                            idCardFaceImg: values.idCardFaceImg,
                            idCardNationalEmblemImg: values.idCardNationalEmblemImg,
                            businessLicenceImg: values.businessLicenceImg,
                            storeFacadeImg: values.storeFacadeImg,
                            houseLogoImg: values.logoUrl,
                            holdingIdCardImg: values.holdingIdCardImg,
                            holdingBusinessLicenceImg: values.holdingBusinessLicenceImg,
                            chargeName: values.chargeName,
                            chargeIdCardNumber: values.chargeIdCardNumber,
                            unifiedSocialCreditCode: values.unifiedSocialCreditCode,
                            promotionNumber: values.promotionNumber,
                            city: locationText,
                            cityCode: values.city.county,
                            latitudeLongitude: geo.lng + "," + geo.lat,
                            foodLicenseImg: values.foodLicenseImg,
                            medicalCertImg: values.medicalCertImg,
                            medicalOrgImg: values.medicalOrgImg,
                            medicalBusinessImg: values.medicalBusinessImg,
                            drugBusiLiceImg: values.drugBusiLiceImg,
                            tabaBusiLiceImg: values.tabaBusiLiceImg,
                        };
                        startConfirmLoading();
                        if (!initialValue.id) return [3 /*break*/, 2];
                        Object.assign(data, { storeId: initialValue.id });
                        return [4 /*yield*/, editStoresItem(data)];
                    case 1:
                        _a.sent();
                        message.success('门店修改成功');
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, addStoresItem(data)];
                    case 3:
                        _a.sent();
                        message.success('门店添加成功');
                        _a.label = 4;
                    case 4:
                        stopConfirmLoading();
                        props.toggleVisible();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    return (React.createElement(FormModal, { title: props.isEditMode ? '编辑门店' : '添加门店', visible: props.visible, confirmLoading: confirmLoading, onCancel: handleCancel, onOk: handleSubmit, destroyOnClose: true, width: 650 },
        React.createElement(EditStoreForm, { wrappedComponentRef: formRef, initialValue: props.initialValue, isEditMode: props.isEditMode, handleSubmit: handleSubmit })));
};
export default EditStoreModal;
