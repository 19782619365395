var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useContext } from 'react';
import FormModal from '@components/FormModal';
import { Button, Select, Input, Form, message } from 'antd';
import { updateSelfOrderStatus } from '@apis/order';
import { ActionsContext } from '../context';
import { useDispatch, useSelector } from 'react-redux';
var FormItem = Form.Item;
var mapState = function (state) { return ({
    selfDelivery: state.selfDelivery
}); };
export var DeliveryBySelfModal = function (props) {
    var dispatch = useDispatch();
    var selfDelivery = useSelector(mapState).selfDelivery;
    var context = useContext(ActionsContext);
    var _a = context.selfSend || {}, _b = _a.type, platform = _b === void 0 ? '-1' : _b, remark = _a.msg;
    if (!platform.trim() && selfDelivery.platforms.length) {
        // platform = selfDelivery.platforms[0].code
    }
    var hide = props.hide, onSend = props.onSend, onArrive = props.onArrive;
    var selectedVal = React.useRef(platform);
    var inputVal = remark;
    useEffect(function () {
        dispatch.selfDelivery.fetchPlatforms();
    }, []);
    function updateStatus(status) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (selectedVal.current === '-1') {
                    message.error('请先选择配送平台');
                    return [2 /*return*/, Promise.reject('请先选择配送平台')];
                }
                return [2 /*return*/, updateSelfOrderStatus({
                        orderId: context.id,
                        sendStatus: status,
                        code: 0,
                        type: selectedVal.current,
                        msg: inputVal
                    })];
            });
        });
    }
    function emitSend() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, updateStatus(20)];
                    case 1:
                        _a.sent();
                        onSend();
                        message.success('确认送出成功');
                        return [2 /*return*/];
                }
            });
        });
    }
    function emitArrive() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, updateStatus(50)];
                    case 1:
                        _a.sent();
                        onArrive();
                        message.success('确认送达成功');
                        return [2 /*return*/];
                }
            });
        });
    }
    function renderButtons() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { type: "primary", onClick: function () { return emitSend(); } }, "\u786E\u8BA4\u9001\u51FA"),
            React.createElement(Button, { type: "primary", onClick: function () { return emitArrive(); } }, "\u786E\u8BA4\u9001\u8FBE")));
    }
    function handleSelectChange(value) {
        selectedVal.current = value;
    }
    function handleInputChange(e) {
        inputVal = e.target.value;
    }
    return (React.createElement(FormModal, { title: "\u81EA\u914D\u9001", footer: renderButtons(), onCancel: hide, destroyOnClose: true, visible: true },
        React.createElement("p", null, "\u8BF7\u786E\u8BA4\u914D\u9001\u4FE1\u606F,\u786E\u8BA4\u540E\u4E0D\u53EF\u4FEE\u6539!"),
        React.createElement(Form, null,
            React.createElement(FormItem, null,
                React.createElement(Select
                // value={platform}
                , { 
                    // value={platform}
                    onChange: handleSelectChange, 
                    // defaultValue={platform || '-1'}
                    defaultValue: '-1', style: { width: 180 } },
                    React.createElement(Select.Option, { value: "-1" }, "\u8BF7\u9009\u62E9"),
                    selfDelivery.platforms.map(function (item) {
                        return (React.createElement(Select.Option, { key: item.code, value: item.code }, item.value));
                    })))),
        React.createElement(FormItem, null,
            React.createElement(Input.TextArea, { rows: 4, placeholder: "\u5EFA\u8BAE\u8F93\u5165\u5E97\u5458\u4FE1\u606F\uFF1A\u59D3\u540D\u548C\u7535\u8BDD\uFF0C\u4EE5\u4FBF\u8DDF\u8FDB\u540E\u7EED\u95EE\u9898!", onChange: handleInputChange }))));
};
