var localAppEnv = process.env.IS_ELECTRON
    ? require('../config').default.get('APP_ENV')
    : false;
export var APP_ENV = localAppEnv || process.env.APP_ENV || 'production';
export var APP_VERSION = process.env.APP_VERSION || 'dev';
export var isDev = APP_ENV === 'development';
export var isTest = APP_ENV === 'test';
export var isProd = APP_ENV === 'production';
export var isElectron = process.env.IS_ELECTRON;
export function getEnv() {
    var host = location.host;
    if (host.includes('easyex-dev')) {
        return 'dev';
    }
    else if (host.includes('easyex-test')) {
        return 'test';
    }
    else if (host.includes('iyisong')) {
        return 'prod';
    }
    return 'dev';
}
