import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Badge, Radio } from 'antd';
import { camelCase, get } from 'lodash';
import qs from 'querystring';
import { ORDER_STATUS_MAP, ORDER_TABS } from '../constants';
import { OrderStatusFilter } from './styled';
var TabNameWithCount = function (_a) {
    var name = _a.name, count = _a.count, isHot = _a.isHot;
    return (React.createElement(React.Fragment, null,
        name,
        !isNaN(isHot) && (React.createElement(Badge, { count: count, className: Boolean(isHot) ? '' : 'isHot' }))));
};
export var OrderStatusTabs = function (_a) {
    var onChange = _a.onChange;
    var counter = useSelector(function (state) { return state.orders.counter; });
    var search = useLocation().search;
    var defaultActiveKey = get(qs.parse(search.substr(1)), 'status', ORDER_STATUS_MAP.ALL);
    return (React.createElement(OrderStatusFilter, { defaultValue: defaultActiveKey, onChange: onChange }, ORDER_TABS.map(function (status) {
        var count = get(counter, camelCase(status.key));
        return (React.createElement(Radio.Button, { prefixCls: "btn-order-status", key: status.key, value: status.key },
            React.createElement(TabNameWithCount, { name: status.name, count: count, isHot: status.isHot || null })));
    })));
};
