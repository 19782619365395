var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Modal, Button } from 'antd';
import './index.less';
import React, { forwardRef, useImperativeHandle } from 'react';
var Reconfirm = function (props, ref) {
    var _a = __read(React.useState(false), 2), visible = _a[0], setVisible = _a[1];
    var _b = __read(React.useState(false), 2), confirmLoading = _b[0], setConfirmLoading = _b[1];
    var _c = __read(React.useState('Content of the modal'), 2), modalText = _c[0], setModalText = _c[1];
    useImperativeHandle(ref, function () {
        return {
            setVisible: setVisible
        };
    });
    var handleOk = function () {
        props.callback();
    };
    var handleCancel = function () {
        setVisible(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { className: "reconfirm-modal", title: null, visible: visible, closable: false, onOk: handleOk, confirmLoading: confirmLoading, onCancel: handleCancel, footer: null, width: 420 },
            React.createElement("div", { className: "title" },
                React.createElement("span", null, props.text.title || ''),
                React.createElement("span", { onClick: handleCancel },
                    React.createElement("i", { className: "img" }))),
            React.createElement("p", { className: "content" }, props.text.content),
            React.createElement("div", { className: "foot" },
                React.createElement(Button, { className: "cancel", onClick: handleCancel }, props.cancelText || '取消'),
                React.createElement(Button, { className: "confirm", onClick: handleOk }, "\u786E\u5B9A")))));
};
export default forwardRef(Reconfirm);
