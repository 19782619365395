var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button, Modal } from 'antd';
import styled from 'styled-components';
import { Colors } from '@assets/styles/variables';
export var ResetPwdModal = styled(Modal).withConfig({ displayName: "ResetPwdModal", componentId: "sc-1jl3vo5" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 40px;\n  .ant-input {\n    height: 48px;\n  }\n  .ant-form-item {\n    margin-bottom: 20px;\n  }\n"], ["\n  padding: 0 40px;\n  .ant-input {\n    height: 48px;\n  }\n  .ant-form-item {\n    margin-bottom: 20px;\n  }\n"])));
export var Content = styled.div.withConfig({ displayName: "Content", componentId: "sc-1u718c3" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 33px;\n"], ["\n  margin-bottom: 33px;\n"])));
export var Title = styled.h4.withConfig({ displayName: "Title", componentId: "sc-1jtqovr" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 5px;\n  color: ", ";\n  font-size: 20px;\n"], ["\n  margin-bottom: 5px;\n  color: ", ";\n  font-size: 20px;\n"])), Colors.Green95);
export var ResetPwdButton = styled(Button).withConfig({ displayName: "ResetPwdButton", componentId: "sc-hejr6j" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 20px;\n  height: 48px;\n  font-size: 18px;\n"], ["\n  margin-top: 20px;\n  height: 48px;\n  font-size: 18px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
