import { DELIVERY_PLATFORM_MAP } from '@utils/constants';
var getDeliveryAuthData = function (_a) {
    var type = _a.type, authData = _a.authData;
    switch (type) {
        case DELIVERY_PLATFORM_MAP.SHANSONG:
            return {
                code: authData.code
            };
        default:
            return {};
    }
};
export default getDeliveryAuthData;
