var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var DefaultLayoutHeader = styled.div.withConfig({ displayName: "DefaultLayoutHeader", componentId: "sc-hlxnxg" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 12px 13px;\n  background: #fff;\n  text-align: right;\n  font-size: 14px;\n  color: #333;\n"], ["\n  padding: 12px 13px;\n  background: #fff;\n  text-align: right;\n  font-size: 14px;\n  color: #333;\n"])));
export var Time = styled.span.withConfig({ displayName: "Time", componentId: "sc-1cpksp3" })(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
export var User = styled.span.withConfig({ displayName: "User", componentId: "sc-a7p3ej" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0 8px 0 75px;\n"], ["\n  margin: 0 8px 0 75px;\n"])));
export var LogoutButton = styled.a.withConfig({ displayName: "LogoutButton", componentId: "sc-qz87pt" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: 8px;\n  color: #333;\n  text-decoration: none;\n"], ["\n  margin-left: 8px;\n  color: #333;\n  text-decoration: none;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
