import React from 'react';
import { Button } from 'antd';
import './index.less';
import { getSn } from '../OrderMap';
var OrderMapCalculateBottom = function (_a) {
    var handleClick = _a.handleClick, loading = _a.loading, oneByMoreData = _a.oneByMoreData, _b = _a.totalDistance, totalDistance = _b === void 0 ? 0 : _b;
    var daySns = [];
    oneByMoreData && oneByMoreData.map(function (item, index) {
        daySns.push(React.createElement("div", { key: item.id },
            React.createElement("span", null,
                "#",
                item[getSn(item.platform)]),
            index !== (oneByMoreData.length - 1) ? ">" : ''));
    });
    var prefixCls = 'orderMapCalculateBottom';
    return (React.createElement("div", { className: "" + prefixCls },
        React.createElement("div", { className: prefixCls + "-top" },
            React.createElement("div", { className: prefixCls + "-top-left" },
                "\u53D1\u5355\u987A\u5E8F",
                daySns),
            React.createElement("div", { className: prefixCls + "-top-right" },
                "\u7EA6",
                totalDistance,
                "km")),
        React.createElement(Button, { type: "primary", size: "large", className: prefixCls + "-bottom", onClick: handleClick, loading: loading }, "\u786E\u8BA4\u53D1\u5355")));
};
export default OrderMapCalculateBottom;
