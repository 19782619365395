var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Button, message } from 'antd';
import ajax from '@utils/ajax';
import './button.less';
import { ActionsContext } from '../context';
import Reconfirm from './reconfirm';
import { getAuth } from '@/container/AccountManagement/component';
import { useSelector } from 'react-redux';
var agreeRefundOrCancelReq = function (_a) {
    var orderId = _a.orderId;
    return ajax.post('/order/ignoreOrder', { orderId: orderId });
};
var IgnoreActioninit = /** @class */ (function (_super) {
    __extends(IgnoreActioninit, _super);
    function IgnoreActioninit(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClick = function () {
            var _a, _b;
            //@ts-ignore
            (_b = (_a = _this.myref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.setVisible(true);
        };
        _this.handleOk = function () {
            var orderId = _this.context.id;
            agreeRefundOrCancelReq({ orderId: orderId }).then(function () {
                var _a, _b;
                //   emitOrderChange({
                //     tabStatus,
                //     orderEvent,
                //     orderId,
                //     nextStatus: ORDER_STATUS_CODE_MAP.CANCELED
                //   })
                //@ts-ignore
                (_b = (_a = _this.myref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.setVisible(false);
                message.success('忽略配送成功');
            });
        };
        _this.myref = React.createRef();
        return _this;
    }
    IgnoreActioninit.prototype.render = function () {
        return (getAuth("订单处理") ?
            React.createElement(React.Fragment, null,
                React.createElement(Button, { onClick: this.handleClick, className: "orderbutton", type: "primary" }, "\u5FFD\u7565\u914D\u9001"),
                React.createElement(Reconfirm, { ref: this.myref, callback: this.handleOk, text: { title: '忽略配送', content: '是否确定忽略配送' } })) : null);
    };
    return IgnoreActioninit;
}(React.PureComponent));
IgnoreActioninit.contextType = ActionsContext;
export var IgnoreAction = function () {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("订单处理", menu) ? React.createElement(IgnoreActioninit, null) : null;
};
