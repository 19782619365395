var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as qs from 'querystring';
import { Button } from 'antd';
import { useToggle } from '@hooks';
import { DeliveryBySelfModal } from '../modal';
import { emitOrderChange } from './common';
import { ActionsContext } from '../context';
import { ORDER_STATUS_CODE_MAP, ORDER_STATUS_MAP } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import './button.less';
import { getAuth } from '@/container/AccountManagement/component';
var mapDispatch = function (dispatch) { return ({
    getOrders: dispatch.orders.fetchData
}); };
function getOrderQuery(search) {
    var query = search.includes('?') ? search.substr(1) : search;
    var orderQuery = qs.parse(query);
    orderQuery.orderStatus = ORDER_STATUS_CODE_MAP.IN_THE_DISTRIBUTION;
    return query;
}
var DeliveryBySelfActioninit = function (props) {
    var _a = __read(useToggle(false), 3), visible = _a[0], show = _a[1], hide = _a[2];
    var toggleVisible = visible ? hide : show;
    var _b = useContext(ActionsContext), orderId = _b.id, tabStatus = _b.tabStatus, orderEvent = _b.orderEvent;
    var dispatch = useDispatch();
    var getOrders = mapDispatch(dispatch).getOrders;
    var history = useHistory();
    function onSend() {
        if (tabStatus === ORDER_STATUS_MAP.IN_THE_DISTRIBUTION) {
            var orderQuery = getOrderQuery(history.location.search);
            getOrders(orderQuery);
            hide();
            return;
        }
        emitOrderChange({
            tabStatus: tabStatus,
            orderEvent: orderEvent,
            orderId: orderId,
            nextStatus: ORDER_STATUS_CODE_MAP.IN_THE_DISTRIBUTION
        });
    }
    function onArrive() {
        emitOrderChange({
            tabStatus: tabStatus,
            orderEvent: orderEvent,
            orderId: orderId,
            nextStatus: ORDER_STATUS_CODE_MAP.DONE
        });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { type: "primary", onClick: toggleVisible, className: "orderbutton" }, "\u81EA\u914D\u9001"),
        visible ? (React.createElement(DeliveryBySelfModal, { hide: hide, onSend: onSend, onArrive: onArrive })) : null));
};
export var DeliveryBySelfAction = function () {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("配送处理", menu) ? React.createElement(DeliveryBySelfActioninit, null) : null;
};
