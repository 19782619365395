var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory, withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import './index.less';
import LogoUrl from '@assets/images/newlogo.png';
import Dingdan from '@assets/images/menu/dingdan0.png';
import Dingdan1 from '@assets/images/menu/dingdan.png';
import mendian from '@assets/images/menu/mendian.png';
import mendian1 from '@assets/images/menu/mendian1.png';
import data from '@assets/images/menu/data.png';
import data1 from '@assets/images/menu/data1.png';
import password from '@assets/images/menu/password.png';
import password1 from '@assets/images/menu/password1.png';
import account from '@assets/images/menu/account.png';
import account1 from '@assets/images/menu/account1.png';
import { DefaultContent, DefaultMenu, DefaultMenuItem, DefaultSider, Logo, LogoWithText } from './styled';
import { DefaultLoginout } from '@/components/DefaultHeader';
import { getAuth } from '@/container/AccountManagement/component';
var DefaultLayout = function (_a) {
    var Component = _a.component, rest = __rest(_a, ["component"]);
    var history = useHistory();
    var selectedKeys = [rest.path.substr(1)];
    if (rest.path === '/receipt') {
        selectedKeys = ['stores'];
    }
    var user = useSelector(function (state) { return state.user; });
    var merchant = useSelector(function (state) { return state.merchant; });
    if (!user.token) {
        history.push('/login');
        return null;
    }
    return (React.createElement(Route, __assign({}, rest, { render: function (matchProps) { return (React.createElement("div", { className: "easyh5" },
            React.createElement(Layout, { style: { minHeight: '100vh' } },
                React.createElement(DefaultSider, { width: 120 },
                    React.createElement("div", { className: "menuwrapper" },
                        React.createElement(LogoWithText, null,
                            React.createElement(Logo, { src: LogoUrl })),
                        React.createElement(DefaultMenu, { theme: "dark", defaultSelectedKeys: selectedKeys, selectedKeys: selectedKeys, onClick: function (_a) {
                                var key = _a.key, rest = __rest(_a, ["key"]);
                                history.push("/" + key);
                            } }, getMENU().map(function (menu) {
                            return (React.createElement(DefaultMenuItem, { key: menu.key },
                                React.createElement("div", { className: getClassName(menu) },
                                    React.createElement("img", { src: getIcon(menu) }),
                                    React.createElement("span", null, menu.name))));
                        })),
                        React.createElement(DefaultLoginout, null))),
                React.createElement(Layout, null,
                    React.createElement(DefaultContent, null,
                        React.createElement(Component, __assign({}, matchProps))))))); } })));
    function isActive(menu) {
        return menu.key === selectedKeys[0];
    }
    function getClassName(menu) {
        return isActive(menu) ? 'innermenu active' : 'innermenu';
    }
    function getIcon(menu) {
        return isActive(menu) ? menu.iconActive : menu.icon;
    }
};
export function getMENU(defaultMenu) {
    var MENU_LIST = [
        {
            path: '/orders',
            key: 'orders',
            icon: Dingdan,
            name: '订单',
            iconActive: Dingdan1,
            secret: !getAuth("订单查看", defaultMenu)
        },
        {
            path: '/stores',
            key: 'stores',
            icon: mendian,
            name: '门店',
            iconActive: mendian1,
            secret: !getAuth("门店管理", defaultMenu)
        },
        {
            path: '/analyze',
            key: 'analyze',
            icon: data,
            iconActive: data1,
            name: '数据',
            secret: !getAuth("报表分析", defaultMenu)
        },
        {
            path: '/managementaccount',
            key: 'managementaccount',
            icon: account,
            iconActive: account1,
            name: '账号',
            secret: !getAuth("账号管理", defaultMenu)
        },
        {
            path: '/account',
            key: 'account',
            icon: password,
            iconActive: password1,
            name: '密码',
            secret: !getAuth("我的", defaultMenu)
        }
    ];
    return MENU_LIST.filter(function (item) { return !item.secret; });
}
export default withRouter(DefaultLayout);
