var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, forwardRef, useImperativeHandle, useMemo } from 'react';
import { EDeliveryHistoryState } from '../constants';
import { DeliveryOrderTabWrapper, SDistriStatus } from './styled';
var DeliveryOrderTab = function (props, ref) {
    var _a = __read(useState(props.defaultValue), 2), currentValue = _a[0], setCurrentValue = _a[1];
    var stateMap = useMemo(function () { return ['待呼叫', '呼叫中', '呼叫失败']; }, []);
    var handleClick = function (item) {
        if (item.value === currentValue)
            return;
        props.onChange(item.value);
        setCurrentValue(item.value);
    };
    useImperativeHandle(ref, function () {
        return {
            handleClick: handleClick,
        };
    });
    return (React.createElement(DeliveryOrderTabWrapper, null, props.data.map(function (item) {
        return (React.createElement("div", { key: item.value, className: "item " + (item.value === currentValue ? 'active' : ''), onClick: function () { return handleClick(item); } },
            item.name,
            typeof (item.state) === 'number' && (React.createElement(SDistriStatus, { active: item.value === currentValue },
                item.state === EDeliveryHistoryState.RUNNING && React.createElement("div", { className: "loading" }),
                item.state === EDeliveryHistoryState.FAIL ? item.errorMsg : stateMap[item.state]))));
    })));
};
export default forwardRef(DeliveryOrderTab);
