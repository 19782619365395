var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Button } from 'antd';
import { EditOrderModal } from '../modal';
import './button.less';
import { getAuth } from '@/container/AccountManagement/component';
import { useSelector } from 'react-redux';
var EditOrderActioninit = function () {
    // const [visible, show, hide] = useToggle(false)
    //
    var _a = __read(useState(false), 2), visible = _a[0], setVisible = _a[1];
    // const toggleVisible = () => (visible ? hide() : show())
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { onClick: function () {
                setVisible(true);
            }, className: "orderbutton", type: "primary" }, "\u4FEE\u6539\u8BA2\u5355"),
        React.createElement(EditOrderModal, { visible: visible, toggleVisible: setVisible })));
};
export var EditOrderAction = function () {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("订单处理", menu) ? React.createElement(EditOrderActioninit, null) : null;
};
