var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { DeliveryContext } from '../context';
import { PriceHighlightText, PriceText, TotalPrice } from './styled';
var notZero = function (str) { return Number(str) !== 0; };
var DeliveryOrderPriceGroup = /** @class */ (function (_super) {
    __extends(DeliveryOrderPriceGroup, _super);
    function DeliveryOrderPriceGroup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DeliveryOrderPriceGroup.prototype.render = function () {
        var _a = this.context.distriOrder, rewardAmount = _a.rewardAmount, mileageAmount = _a.mileageAmount, offerAmount = _a.offerAmount, shouldPayAmount = _a.shouldPayAmount, continuedRepeatAmount = _a.continuedRepeatAmount, overflowPriceAmount = _a.overflowPriceAmount, nightAmount = _a.nightAmount;
        return (React.createElement(TotalPrice, null,
            notZero(offerAmount) ? (React.createElement(PriceText, null,
                "\u5DF2\u4F18\u60E0\uFF1A",
                offerAmount,
                "\u5143")) : null,
            React.createElement(PriceText, null,
                "\u652F\u4ED8\u91D1\u989D\uFF1A",
                React.createElement(PriceHighlightText, null, shouldPayAmount),
                "\u5143")));
    };
    return DeliveryOrderPriceGroup;
}(React.PureComponent));
export { DeliveryOrderPriceGroup };
DeliveryOrderPriceGroup.contextType = DeliveryContext;
