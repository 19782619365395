var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import IconPointDest from '@assets/images/icon-point-dest.png';
import IconPointRider from '@assets/images/icon-point-rider.png';
import IconPointStore from '@assets/images/icon-point-store.png';
var BMap = window.BMap;
var createOverlay = function (_a) {
    var width = _a.width, height = _a.height, imgUrl = _a.imgUrl;
    var overlayEle = document.createElement('div');
    var styles = {
        position: 'absolute',
        width: width + "px",
        height: height + "px",
        backgroundImage: "url(" + imgUrl + ")",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        textAlign: 'center',
        lineHeight: '2.4'
    };
    for (var key in styles) {
        ;
        overlayEle.style[key] = styles[key];
    }
    return overlayEle;
};
var createText = function (_a) {
    var text = _a.text;
    var textEle = document.createElement('span');
    var styles = {
        fontSize: '13px',
        color: '#fff'
    };
    for (var key in styles) {
        ;
        textEle.style[key] = styles[key];
    }
    textEle.textContent = text;
    return textEle;
};
var BaseOverlay = /** @class */ (function (_super) {
    __extends(BaseOverlay, _super);
    function BaseOverlay(center, click) {
        if (click === void 0) { click = function () { }; }
        var _this = _super.call(this, center) || this;
        _this.center = center;
        _this.length = 40;
        _this.heigth = 0;
        _this.imgUrl = '';
        _this.text = '';
        _this.click = click;
        return _this;
    }
    BaseOverlay.prototype.initialize = function (map) {
        this.map = map;
        var overlayEle = createOverlay({
            width: this.length,
            height: this.heigth || this.length,
            imgUrl: this.imgUrl
        });
        var textEle = createText({ text: this.text });
        overlayEle.onclick = this.click;
        overlayEle.appendChild(textEle);
        map.getPanes().markerPane.appendChild(overlayEle);
        this.overlayEle = overlayEle;
        return overlayEle;
    };
    BaseOverlay.prototype.draw = function () {
        var position = this.map.pointToOverlayPixel(this.center);
        var styles = {
            top: position.y - this.length / 2 + "px",
            left: position.x - this.length / 2 + "px"
        };
        for (var key in styles) {
            ;
            this.overlayEle.style[key] = styles[key];
        }
    };
    return BaseOverlay;
}(BMap.Overlay));
var PointStoreOverlay = /** @class */ (function (_super) {
    __extends(PointStoreOverlay, _super);
    function PointStoreOverlay(center, click) {
        var _this = _super.call(this, center) || this;
        _this.center = center;
        _this.imgUrl = IconPointStore;
        _this.text = '店';
        _this.click = click;
        return _this;
    }
    return PointStoreOverlay;
}(BaseOverlay));
export { PointStoreOverlay };
var PointRiderOverlay = /** @class */ (function (_super) {
    __extends(PointRiderOverlay, _super);
    function PointRiderOverlay(center) {
        var _this = _super.call(this, center) || this;
        _this.center = center;
        _this.imgUrl = IconPointRider;
        _this.text = '';
        return _this;
    }
    return PointRiderOverlay;
}(BaseOverlay));
export { PointRiderOverlay };
var PointDestOverlay = /** @class */ (function (_super) {
    __extends(PointDestOverlay, _super);
    function PointDestOverlay(center, click, text) {
        if (text === void 0) { text = ""; }
        var _this = _super.call(this, center) || this;
        _this.center = center;
        _this.imgUrl = IconPointDest;
        _this.text = '收' + text;
        _this.click = click;
        return _this;
    }
    return PointDestOverlay;
}(BaseOverlay));
export { PointDestOverlay };
var KnightLabelOverlay = /** @class */ (function (_super) {
    __extends(KnightLabelOverlay, _super);
    function KnightLabelOverlay(center, text, click, top) {
        var _this = _super.call(this, center) || this;
        _this.text = '';
        _this.click = function () { };
        _this.center = center;
        _this.text = text;
        _this.click = click;
        _this.top = top || 45;
        return _this;
    }
    KnightLabelOverlay.prototype.initialize = function (map) {
        this.map = map;
        var divEle = document.createElement('div');
        divEle.className = 'knight-label';
        var textEle = document.createElement('span');
        textEle.textContent = this.text;
        divEle.onclick = this.click;
        divEle.appendChild(textEle);
        map.getPanes().markerPane.appendChild(divEle);
        this.overlayEle = divEle;
        return divEle;
    };
    KnightLabelOverlay.prototype.draw = function () {
        var position = this.map.pointToOverlayPixel(this.center);
        var _a = this.overlayEle, width = _a.offsetWidth, height = _a.offsetHeight;
        var styles = {
            top: position.y - height / 2 - this.top + "px",
            left: position.x - width / 2 + "px"
        };
        for (var key in styles) {
            this.overlayEle.style[key] = styles[key];
        }
    };
    return KnightLabelOverlay;
}(BMap.Overlay));
export { KnightLabelOverlay };
// 地图选单 market、label封装
var StoreOverlayOrderMap = /** @class */ (function (_super) {
    __extends(StoreOverlayOrderMap, _super);
    function StoreOverlayOrderMap(center, imgUrl, click) {
        if (imgUrl === void 0) { imgUrl = IconPointStore; }
        var _this = _super.call(this, center) || this;
        _this.center = center;
        _this.imgUrl = imgUrl;
        _this.heigth = 46;
        _this.text = '';
        _this.click = click;
        return _this;
    }
    return StoreOverlayOrderMap;
}(BaseOverlay));
export { StoreOverlayOrderMap };
var OrderLabelOverlay = /** @class */ (function (_super) {
    __extends(OrderLabelOverlay, _super);
    function OrderLabelOverlay(params) {
        var _this = _super.call(this, params.center) || this;
        _this.time = '';
        _this.sn = '';
        _this.distance = '';
        _this.click = function () { };
        _this.actived = false;
        _this.orderId = '';
        _this.center = params.center;
        _this.time = params.time;
        _this.sn = params.sn;
        _this.distance = params.distance;
        _this.click = params.click;
        _this.top = params.top || 45;
        _this.actived = params.actived;
        _this.orderId = params.orderId;
        _this.height = 0;
        _this.width = 0;
        return _this;
    }
    OrderLabelOverlay.prototype.initialize = function (map) {
        this.map = map;
        var divEle = document.createElement('div');
        divEle.className = this.actived ? 'orderMapLabelActive' : 'orderMapLabelDefault';
        divEle.innerHTML = '<div class="sn">' + this.sn + '</div>' +
            '<div class="text">' +
            '<div class="time">' +
            this.time +
            '</div>' +
            '<div class="distance">' +
            this.distance +
            '</div>' +
            '</div>';
        divEle.onclick = this.click;
        divEle.id = this.orderId;
        map.getPanes().markerPane.appendChild(divEle);
        this.overlayEle = divEle;
        var _a = this.overlayEle, offsetWidth = _a.offsetWidth, offsetHeight = _a.offsetHeight;
        this.height = offsetHeight;
        this.width = offsetWidth;
        return divEle;
    };
    OrderLabelOverlay.prototype.draw = function () {
        var position = this.map.pointToOverlayPixel(this.center);
        var styles = {
            top: position.y - this.height / 2 - this.top + "px",
            left: position.x - this.width / 2 + "px"
        };
        for (var key in styles) {
            this.overlayEle.style[key] = styles[key];
        }
    };
    return OrderLabelOverlay;
}(BMap.Overlay));
export { OrderLabelOverlay };
