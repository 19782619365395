require('moment/locale/zh-cn');
import moment from 'moment';
moment.locale('zh-cn');
export var currentTime = function () { return moment().format('YYYY-MM-DD HH:mm:ss'); };
export var currentTimeWithDay = function () {
    return moment().format('YYYY-MM-DD HH:mm:ss dddd');
};
export var defaultFormat = function (value) {
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
};
export var defaultFormatNOY = function (value) {
    return moment(value).format('MM-DD HH:mm:ss');
};
export function diff(time) {
    var h = moment().diff(time, 'hour');
    var m = moment().diff(time, 'minute');
    var s = moment().diff(time, 'seconds');
    var str = "";
    str += h.toString().padStart(2, '0') + ':';
    str += (m - h * 60).toString().padStart(2, '0') + ':';
    str += (s - m * 60).toString().padStart(2, '0');
    return str;
}
