var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CancelOrderAction, EditOrderAction, PrintAction, ReceiveOrderAction, RefuseOrderAction, SendDeliveryAction, CancelDeliveryOrderAction, CancelDeliveryOrderActionWAIT_GRAB, IgnoreAction, AppendTipAction, // 再次发货
DeliveryBySelfAction, SendDeliveryActionAGAINinit
// AppendTipAction
 } from './action';
import { ORDER_STATUS_MAP } from './constants';
export function getOrderActionsMap(order) {
    var _a, _b;
    var orderActionsMapDefault = (_a = {},
        _a[ORDER_STATUS_MAP.TO_BE_CONFIRMED] = [
            isNotManual(order) ? PrintAction : null,
            ReceiveOrderAction,
            RefuseOrderAction
        ],
        _a[ORDER_STATUS_MAP.TO_BE_SHIPPING] = [
            CancelOrderAction,
            EditOrderAction,
            DeliveryBySelfAction,
            isNotManual(order) ? PrintAction : null,
            IgnoreAction,
            getSendText(order['distriOrderId'])
        ],
        _a[ORDER_STATUS_MAP.WAIT_GRAB] = [
            isNotManual(order) ? PrintAction : null,
            CancelDeliveryOrderActionWAIT_GRAB,
            AppendTipAction
        ],
        //待取货
        _a[ORDER_STATUS_MAP.TO_BE_PICK] = [
            isSendSelf(order) ? DeliveryBySelfAction : null,
            CancelDeliveryOrderAction,
            isNotManual(order) ? PrintAction : null
        ],
        _a[ORDER_STATUS_MAP.IN_THE_DISTRIBUTION] = [
            isSendSelf(order) ? DeliveryBySelfAction : null,
            CancelDeliveryOrderAction,
            isNotManual(order) ? PrintAction : null
        ],
        _a[ORDER_STATUS_MAP.DONE] = [],
        _a[ORDER_STATUS_MAP.CANCELED] = [],
        _a);
    var orderActionsMapMeituan = __assign(__assign({}, orderActionsMapDefault), (_b = {}, _b[ORDER_STATUS_MAP.TO_BE_CONFIRMED] = [PrintAction], _b));
    return order.platform === 'meituan' && order.orderPlatformChannel === 1
        ? orderActionsMapMeituan
        : orderActionsMapDefault;
}
function getSendText(id) {
    return id && id.trim() !== '' ? SendDeliveryActionAGAINinit : SendDeliveryAction;
}
export function isSendSelf(order) {
    return order.selfSend && order.selfSend.code === 0;
}
function isNotManual(order) {
    return order.platform !== 'manual';
}
