import React from 'react';
import { Modal } from 'antd';
var installShowed = false;
var updateShowed = false;
var installCLodopModal = function () {
    if (!installShowed) {
        installShowed = true;
        Modal.info({
            title: '易送打印服务提示',
            content: React.createElement("div", { color: '#FF00FF' },
                "\u6613\u9001\u6253\u5370\u670D\u52A1\u672A\u5B89\u88C5\u542F\u52A8\uFF0C\u70B9\u51FB\u8FD9\u91CC",
                React.createElement("a", { href: "https://easyex-package.oss-cn-beijing.aliyuncs.com/release/Yisong_Setup_for_Win32NT_4.127.exe", target: "_blank" }, "\u4E0B\u8F7D\u6267\u884C\u5B89\u88C5"),
                "\uFF08\u82E5\u6B64\u524D\u5DF2\u5B89\u88C5\u8FC7\uFF0C\u53EF",
                React.createElement("a", { href: 'CLodop.protocol:setup', target: '_self' }, "\u70B9\u8FD9\u91CC\u76F4\u63A5\u518D\u6B21\u542F\u52A8"),
                "\uFF09 \uFF0C\u6210\u529F\u540E\u8BF7\u5237\u65B0\u672C\u9875\u9762\u3002",
                React.createElement("br", null),
                "\u67E5\u770B",
                React.createElement("a", { href: "/printHelper", target: "_blank" }, "\u5B89\u88C5\u8BF4\u660E")),
            centered: true
        });
    }
};
var updateCLodopModal = function () {
    if (!updateShowed) {
        updateShowed = true;
        Modal.info({
            title: '易送打印服务提示',
            content: React.createElement("div", { color: '#FF00FF' },
                "\u6613\u9001\u6253\u5370\u670D\u52A1\u9700\u5347\u7EA7\uFF01\u70B9\u51FB\u8FD9\u91CC",
                React.createElement("a", { href: "https://easyex-package.oss-cn-beijing.aliyuncs.com/release/Yisong_Setup_for_Win32NT_4.127.exe", target: "_blank" }, "\u6267\u884C\u5347\u7EA7"),
                ",\u5347\u7EA7\u540E\u8BF7\u5237\u65B0\u9875\u9762\u3002",
                React.createElement("br", null),
                "\u67E5\u770B",
                React.createElement("a", { href: "/printHelper", target: "_blank" }, "\u5B89\u88C5\u8BF4\u660E")),
            centered: true
        });
    }
};
export { installCLodopModal, updateCLodopModal };
