/*
 * @Author: 孙家奇
 * @Date: 2021-06-29 11:18:22
 * @Last Modified by: 孙家奇
 * @Last Modified time: 2021-11-19 17:08:35
 *          自有派单   手动派单
 *  扣款账号 单选       多选
 *  运力偏好 都可设置   选择扣款账号 自有运力账号不可设置
 */
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Row, Col, Radio } from 'antd';
import React, { useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import ModalPlatFrom from './modalPlatfrom';
import { EBizType, EBindTag } from '../../../constance';
import './index.less';
import { getgeneralNumberCheck } from '@/apis/generalNumber';
var DeliveryComponent = function (props, ref) {
    var channelStrategyData = props.channelStrategyData, platformStrategyData = props.platformStrategyData, strategyData = props.strategyData;
    var _a = __read(useState(false), 2), deliveryVisible = _a[0], setdeliveryVisible = _a[1];
    var _b = __read(useState(), 2), editBizPlatform = _b[0], seteditBizPlatform = _b[1];
    var _c = __read(useState(strategyData.bizType), 2), bizType = _c[0], setbizType = _c[1];
    var _d = __read(useState(channelStrategyData || []), 2), channelData = _d[0], setchannelData = _d[1];
    var _e = __read(useState(platformStrategyData || []), 2), platformData = _e[0], setplatformData = _e[1];
    var _f = __read(useState(true), 2), IsHasGeneralNumbers = _f[0], setIsHasGeneralNumbers = _f[1];
    useEffect(function () {
        setchannelData(channelStrategyData || []);
        setplatformData(platformStrategyData || []);
        getgeneralNumberCheck().then(function (res) {
            setIsHasGeneralNumbers(res);
        });
    }, [channelStrategyData, platformStrategyData]);
    var handleSaveModalPlatform = function (data, type) {
        if (type === EBizType.CHANNEL) {
            setchannelData(data);
        }
        else if (type === EBizType.PLATFORM) {
            setplatformData(data);
        }
    };
    var handleDeliveryClick = function (type) {
        seteditBizPlatform(type);
        setdeliveryVisible(true);
    };
    var handleFormatPlatformToString = function (params, bizType) {
        var tempArr = [];
        if (params && !!params.length) {
            params.map(function (item) {
                if (item.bindTag === EBindTag.BIND) {
                    tempArr.push(item.expressPlatformName);
                }
            });
            return tempArr.length > 0 ? tempArr.join(', ') : '暂无偏好,点击可设置';
        }
        else {
            return bizType === EBizType.CHANNEL
                ? '请先绑定自有运力'
                : '请完善店铺证照，以便运力平台审核';
        }
    };
    useImperativeHandle(ref, function () {
        return {
            channelData: channelData,
            platformData: platformData,
            bizType: bizType
        };
    });
    var handleUpdatEBizType = function (v) {
        var handleUpdateStrategyTypeVisible = props.handleUpdateStrategyTypeVisible;
        handleUpdateStrategyTypeVisible(v.target.value);
        setbizType(v.target.value);
    };
    return (React.createElement("div", { style: { marginBottom: '32px' } },
        React.createElement(Radio.Group, { name: "bizType", onChange: handleUpdatEBizType, value: bizType },
            React.createElement(Row, null,
                React.createElement(Col, { span: 8, style: {
                        borderTopLeftRadius: '5px',
                        color: 'rgb(217,217,217)'
                    }, className: "EditDeliveryStrategyForm_platform" }, "\u6263\u6B3E\u8D26\u53F7"),
                React.createElement(Col, { span: !IsHasGeneralNumbers ? 16 : 8, className: "EditDeliveryStrategyForm_platform" },
                    React.createElement(Radio, { value: EBizType.CHANNEL }, "\u81EA\u6709\u8D26\u53F7")),
                IsHasGeneralNumbers && (React.createElement(Col, { span: 8, style: {
                        borderTopRightRadius: '5px'
                    }, className: "EditDeliveryStrategyForm_platform" },
                    React.createElement(Radio, { value: EBizType.PLATFORM }, "\u901A\u7528\u53F7"))),
                React.createElement(Col, { span: 8, className: "EditDeliveryStrategyForm_platform", style: { color: 'rgb(217,217,217)' } }, "\u8FD0\u529B\u504F\u597D"),
                React.createElement(Col, { span: !IsHasGeneralNumbers ? 16 : 8, className: "EditDeliveryStrategyForm_platform", onClick: function () { return handleDeliveryClick(EBizType.CHANNEL); }, style: {
                        color: 'rgb(32,138,239)',
                        cursor: 'pointer',
                        lineHeight: '15px',
                        flexDirection: 'column',
                        display: 'flex',
                        justifyContent: 'center'
                    } },
                    React.createElement("div", null, handleFormatPlatformToString(channelData, EBizType.CHANNEL))),
                IsHasGeneralNumbers && (React.createElement(Col, { span: 8, className: "EditDeliveryStrategyForm_platform", style: {
                        color: 'rgb(32,138,239)',
                        cursor: 'pointer',
                        lineHeight: '15px',
                        flexDirection: 'column',
                        display: 'flex',
                        justifyContent: 'center'
                    }, onClick: function () { return handleDeliveryClick(EBizType.PLATFORM); } },
                    React.createElement(React.Fragment, null, handleFormatPlatformToString(platformData, EBizType.PLATFORM)))),
                React.createElement(Col, { span: 8, style: {
                        borderBottomLeftRadius: '5px',
                        color: 'rgb(217,217,217)'
                    }, className: "EditDeliveryStrategyForm_platform" }, "\u8BF4\u660E"),
                React.createElement(Col, { span: !IsHasGeneralNumbers ? 16 : 8, className: "EditDeliveryStrategyForm_platform", style: {
                        lineHeight: '15px',
                        paddingTop: '15px'
                    } }, "\u6240\u6709\u95E8\u5E97\u5DF2\u7ED1\u5B9A\u7684\u8FD0\u529B\u5E73\u53F0\uFF0C\u4E0D\u53EF\u4FEE\u6539"),
                IsHasGeneralNumbers && (React.createElement(Col, { span: 8, style: {
                        borderBottomRightRadius: '5px',
                        lineHeight: '15px'
                    }, className: "EditDeliveryStrategyForm_platform" }, "\u6240\u6709\u6613\u9001\u5E73\u53F0\u652F\u6301\u7684\u8FD0\u529B\u5E73\u53F0,\u53EF\u4FEE\u6539\u8FD0\u529B\u504F\u597D\uFF0C\u4F46\u6240\u5728\u5546\u6237\u5FC5\u987B\u5BA1\u6838\u901A\u8FC7\u624D\u80FD\u751F\u6548")))),
        React.createElement(ModalPlatFrom, { deliveryVisible: deliveryVisible, setdeliveryVisible: setdeliveryVisible, channelData: channelData, platformData: platformData, handleSaveModalPlatform: handleSaveModalPlatform, editBizPlatform: editBizPlatform })));
};
export default forwardRef(DeliveryComponent);
