import React, { useContext } from 'react';
import { Wrapper, ConditionFilter } from '../styled';
import RankTitle from './RankTitle';
import { RankItem, RankTableSection, TableRemark } from './styled';
import { Table } from 'antd';
import { incomeRankColumns, deliveryRankColumns, orderPlatformRankColumns, deliveryPlatformColumns } from './tableHelper';
import DateFilter from '../common/DateFilter';
import { ReportModule } from '../../types/emnu';
import ReportContext from '../../context';
var Rank = function (props) {
    var data = props.data;
    var changeDate = useContext(ReportContext).changeDate;
    var handleDateChange = function (dateRange) {
        changeDate(ReportModule.Rank, dateRange);
    };
    return (React.createElement(Wrapper, null,
        React.createElement(ConditionFilter, null,
            React.createElement(DateFilter, { name: "rank", onChange: handleDateChange })),
        React.createElement(RankItem, null,
            React.createElement(RankTitle, null, "\u95E8\u5E97\u6536\u5165\u6392\u884C\u699C"),
            React.createElement(RankTableSection, null,
                React.createElement(Table, { columns: incomeRankColumns, dataSource: data.storeIncomeSeniority, bordered: true, rowKey: "index" }))),
        React.createElement(RankItem, null,
            React.createElement(RankTitle, null,
                "\u95E8\u5E97\u914D\u9001\u6392\u884C\u699C",
                React.createElement(TableRemark, null, "\u8BA1\u7B97\u516C\u5F0F\uFF1A\u914D\u9001\u8D39\u90E8\u5206+\u5C0F\u8D39\u90E8\u5206=\u914D\u9001\u8D39\u603B\u989D=\u5DF2\u5B8C\u6210\u914D\u9001\u5355\u6570*\u914D\u9001\u5355\u4EF7")),
            React.createElement(RankTableSection, null,
                React.createElement(Table, { columns: deliveryRankColumns, dataSource: data.storeDistributeSeniority, bordered: true, rowKey: "index" }))),
        React.createElement(RankItem, null,
            React.createElement(RankTitle, null,
                "\u63A5\u5355\u5E73\u53F0\u6392\u884C\u699C",
                React.createElement(TableRemark, null, "\u8BA1\u7B97\u516C\u5F0F\uFF1A\u5168\u90E8\u8BA2\u5355\u6570=\u5DF2\u53D6\u6D88\u8BA2\u5355\u6570+\u5DF2\u5B8C\u6210\u8BA2\u5355\u6570\uFF0C\u5DF2\u5B8C\u6210\u8BA2\u5355\u6570*\u8BA2\u5355\u5B9E\u6536\u5BA2\u5355\u4EF7=\u6536\u5165")),
            React.createElement(RankTableSection, null,
                React.createElement(Table, { columns: orderPlatformRankColumns, dataSource: data.orderPlatformSeniority, bordered: true, rowKey: "index" }))),
        React.createElement(RankItem, null,
            React.createElement(RankTitle, null,
                "\u914D\u9001\u5E73\u53F0\u6392\u884C\u699C",
                React.createElement(TableRemark, null, "\u8BA1\u7B97\u516C\u5F0F\uFF1A\u5168\u90E8\u914D\u9001\u5355\u6570=\u5DF2\u53D6\u6D88\u914D\u9001\u5355\u6570+\u5DF2\u5B8C\u6210\u914D\u9001\u5355\u6570\uFF0C\u5DF2\u5B8C\u6210\u914D\u9001\u5355\u6570*\u914D\u9001\u5355\u5BA2\u5355\u4EF7=\u914D\u9001\u8D39\u603B\u989D")),
            React.createElement(RankTableSection, null,
                React.createElement(Table, { columns: deliveryPlatformColumns, dataSource: data.distributePlatformSeniority, bordered: true, rowKey: "index" })))));
};
export default Rank;
