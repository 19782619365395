var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * @Author: hijack
 * @Date: 2021-06-15 17:01:25
 * @LastEditTime: 2021-07-13 10:25:01
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/container/Orders/components/DeliveryOrderMapPlatform.tsx
 */
import * as React from 'react';
import { PointDestOverlay, PointRiderOverlay, PointStoreOverlay, KnightLabelOverlay } from '@utils/map';
import { PlatformDeliveryContext } from '../context';
import { DeliveryMap } from './styled';
import lodash from 'lodash';
var BMap = window.BMap;
var DeliveryOrderMapPlatform = /** @class */ (function (_super) {
    __extends(DeliveryOrderMapPlatform, _super);
    function DeliveryOrderMapPlatform() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            knightLabelOverlay: null,
            knightOverlay: null
        };
        _this.initialMap = function (_a) {
            var knightGEO = _a.knightGEO, storeGEO = _a.storeGEO, receiveGEO = _a.receiveGEO;
            var knightPoint = new BMap.Point(knightGEO[0], knightGEO[1]);
            var storePoint = new BMap.Point(storeGEO[0], storeGEO[1]);
            _this.map = new BMap.Map('delivery-map');
            _this.map.addControl(new BMap.NavigationControl());
            _this.map.centerAndZoom(storePoint, 14);
            _this.pointArr = [storePoint];
            if (receiveGEO && receiveGEO.length === 1) {
                var receivePoint = new BMap.Point(lodash.get(receiveGEO, '[0].lng'), lodash.get(receiveGEO, '[0].lat'));
                _this.addDestOverlay(receivePoint);
                _this.pointArr.push(receivePoint);
            }
            else if (receiveGEO && receiveGEO.length > 1) {
                receiveGEO.map(function (item) {
                    var point = new BMap.Point(item.lng, item.lat);
                    _this.addDestOverlay(point, String(item.infoIndex));
                    _this.pointArr.push(point);
                });
            }
            _this.map.setViewport(_this.pointArr);
            _this.addStoreOverlay(storePoint);
            if (!!knightGEO.length) {
                _this.addRiderOverlay(knightPoint);
                _this.pointArr.push(knightPoint);
                _this.map.setViewport(_this.pointArr);
            }
        };
        return _this;
    }
    DeliveryOrderMapPlatform.prototype.componentDidUpdate = function (preProps) {
        if (preProps.knightCoordinate !== this.props.knightCoordinate ||
            preProps.knightLabelText !== this.props.knightLabelText) {
            // 更新骑手位置
            this.updateRiderPosition();
        }
    };
    DeliveryOrderMapPlatform.prototype.componentDidMount = function () {
        var knightCoordinate = this.props.knightCoordinate;
        var _a = this.context.deliveryDetail, 
        // knightCoordinate,
        storeCoordinate = _a.storeCoordinate, receiveCoordinate = _a.receiveCoordinate, detailTaskInfo = _a.detailTaskInfo;
        var knightGEO = knightCoordinate ? knightCoordinate.split(',') : []; // 骑士坐标
        var storeGEO = [detailTaskInfo.sender.lng, detailTaskInfo.sender.lat]; // 门店坐标(发货坐标)[]
        var receiveGEO = detailTaskInfo.receiverLists; // 收货坐标
        this.initialMap({ knightGEO: knightGEO, storeGEO: storeGEO, receiveGEO: receiveGEO });
    };
    DeliveryOrderMapPlatform.prototype.updateRiderPosition = function () {
        var _a = this.props, knightLabelText = _a.knightLabelText, knightCoordinate = _a.knightCoordinate;
        this.map.removeOverlay(this.state.knightLabelOverlay);
        this.map.removeOverlay(this.state.knightOverlay);
        var knightGEO = knightCoordinate.split(',');
        var knightPoint = new BMap.Point(knightGEO[0], knightGEO[1]);
        if (knightLabelText) {
            this.addRiderLabelOverlay(knightPoint, knightLabelText);
        }
        this.addRiderOverlay(knightPoint);
        this.pointArr.push(knightPoint);
        this.map.setViewport(this.pointArr);
    };
    DeliveryOrderMapPlatform.prototype.addMaker = function (point) {
        var marker = new BMap.Marker(point);
        this.map.addOverlay(marker);
    };
    DeliveryOrderMapPlatform.prototype.addStoreOverlay = function (point) {
        this.map.addOverlay(new PointStoreOverlay(point));
    };
    DeliveryOrderMapPlatform.prototype.addRiderOverlay = function (point) {
        var knightOverlay = new PointRiderOverlay(point);
        this.map.addOverlay(knightOverlay);
        this.setState({
            knightOverlay: knightOverlay
        });
    };
    DeliveryOrderMapPlatform.prototype.addDestOverlay = function (point, text) {
        if (text === void 0) { text = ''; }
        this.map.addOverlay(new PointDestOverlay(point, function () { return false; }, text));
    };
    DeliveryOrderMapPlatform.prototype.addRiderLabelOverlay = function (point, knightLabelText) {
        var knightLabelOverlay = new KnightLabelOverlay(point, knightLabelText);
        this.map.addOverlay(knightLabelOverlay);
        this.setState({
            knightLabelOverlay: knightLabelOverlay
        });
    };
    DeliveryOrderMapPlatform.prototype.render = function () {
        return React.createElement(DeliveryMap, { id: "delivery-map" });
    };
    return DeliveryOrderMapPlatform;
}(React.PureComponent));
export { DeliveryOrderMapPlatform };
DeliveryOrderMapPlatform.contextType = PlatformDeliveryContext;
