import React from 'react';
import { OrderListWrapper } from './styled';
import OrderItem from './OrderItem/index';
var OrderList = function (props) {
    var current = props.current, list = props.list, onChange = props.onChange;
    return (React.createElement(OrderListWrapper, null, list.map(function (order, index) {
        return (React.createElement(OrderItem, { key: order.id + index, order: order, current: current, onChange: onChange }));
    })));
};
export default OrderList;
