var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { message, Modal } from 'antd';
import ajax from '@utils/ajax';
import { requestForOrder } from '@utils/request';
import { emitOrderChange } from '../action/common';
import { ORDER_STATUS_CODE_MAP, EDeliveryType } from '../constants';
import { ActionsContext, DeliveryContext } from '../context';
import { CancelOrderButton } from './styled';
import Reconfirm from '../action/reconfirm';
import '@/container/Orders/action/button.less';
import styled from 'styled-components';
import { getAuth } from '@/container/AccountManagement/component';
import { useSelector } from 'react-redux';
var cancelOrderReq = function (_a) {
    var orderId = _a.orderId, platform = _a.platform, _b = _a.isForceCancel, isForceCancel = _b === void 0 ? false : _b;
    return ajax.post('/order/cancel/send', { orderId: orderId, isForceCancel: isForceCancel, platform: platform });
};
var cancelOrderPlatform = function (orderId) {
    return requestForOrder.post('/api/platform/transport/cancelTrans', {
        orderId: orderId
    });
};
var cancelOneByMoreConfirm = function (distriOrderId) {
    return requestForOrder.get('/api/platform/order/preCancelExpressOrder', {
        distriOrderId: distriOrderId
    });
};
var Exmodal = styled(Modal).withConfig({ displayName: "Exmodal", componentId: "sc-p93p3" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-modal-confirm-body {\n    background-color: red;\n  }\n"], ["\n  .ant-modal-confirm-body {\n    background-color: red;\n  }\n"])));
var DeliveryOrderActionsinit = /** @class */ (function (_super) {
    __extends(DeliveryOrderActionsinit, _super);
    function DeliveryOrderActionsinit(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClick = function () {
            var _a, _b;
            //@ts-ignore
            (_b = (_a = _this.myref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.setVisible(true);
        };
        _this.myref = React.createRef();
        return _this;
    }
    DeliveryOrderActionsinit.prototype.render = function () {
        var _this = this;
        var _a = this.props, toggleVisible = _a.toggleVisible, platformDelivery = _a.platformDelivery;
        var _b = this.context.order, order = _b === void 0 ? {} : _b;
        var payAccountType = order.payAccountType;
        var closeAndChangeStatus = function (_a) {
            var orderId = _a.orderId, orderEvent = _a.orderEvent, tabStatus = _a.tabStatus;
            toggleVisible();
            emitOrderChange({
                orderId: orderId,
                orderEvent: orderEvent,
                tabStatus: tabStatus,
                nextStatus: ORDER_STATUS_CODE_MAP.TO_BE_SHIPPING
            });
        };
        var handleOk = function (_a) {
            var orderId = _a.orderId, orderEvent = _a.orderEvent, tabStatus = _a.tabStatus, distriOrderId = _a.distriOrderId;
            if (platformDelivery || payAccountType === EDeliveryType.Platform) {
                var canclePlatform_1 = function (orderIds) {
                    cancelOrderPlatform(orderId).then(function (back) {
                        if (back.code === 0) {
                            message.success('取消配送成功');
                            if (orderIds) {
                                toggleVisible();
                                orderIds.map(function (id) {
                                    emitOrderChange({
                                        orderId: String(id),
                                        orderEvent: orderEvent,
                                        tabStatus: tabStatus,
                                        nextStatus: ORDER_STATUS_CODE_MAP.TO_BE_SHIPPING
                                    });
                                });
                            }
                            else {
                                closeAndChangeStatus({ orderId: orderId, orderEvent: orderEvent, tabStatus: tabStatus });
                            }
                        }
                        else {
                            message.error(back.msg);
                        }
                    });
                };
                if (distriOrderId) {
                    cancelOneByMoreConfirm(distriOrderId).then(function (backdata) {
                        if (backdata.code === 0 && backdata.data.flag === 1) {
                            var platformText_1 = '';
                            var orderList_1 = backdata.data.orderList;
                            var orderIds_1 = [];
                            orderList_1.map(function (item, index) {
                                ;
                                (platformText_1 += item.platformName + "#" + item.platformDaySn + (index === orderList_1.length - 1 ? '' : '、')),
                                    orderIds_1.push(item.orderId);
                            });
                            Exmodal.confirm({
                                title: '确定取消？',
                                content: "\u8BE5\u914D\u9001\u5355\u4E3A\u901A\u7528\u53F7\u4E00\u53D6\u591A\u6295\u5355\uFF0C\u53D6\u6D88\u8BE5\u914D\u9001\u5355\u5C06\u4F1A\u540C\u65F6\u53D6\u6D88" + platformText_1 + "\u7684\u914D\u9001",
                                centered: true,
                                onOk: function () {
                                    canclePlatform_1(orderIds_1);
                                }
                            });
                        }
                        else {
                            canclePlatform_1();
                        }
                    });
                }
                else {
                    canclePlatform_1();
                }
            }
            else {
                //TODO：渠道一取多投-取消配送
                // const p = this.props.isAllcanel ? '' : (distriOrder.platform:"")
                // if (this.props.isMerchant && (status === 2 || status === 5)) {
                //   return Modal.warning({
                //     title: '提示',
                //     content: '已经开始配送，无法取消，如有需要请联系客服400-612-6688'
                //   })
                // }
                cancelOrderReq({ orderId: orderId, platform: '' }).then(function (_a) {
                    var data = _a.data;
                    switch (data.code) {
                        case 4:
                            Modal.warning({
                                title: '提示',
                                content: '已经开始配送，无法取消，如有需要请联系客服400-612-6688'
                            });
                            break;
                        case 0:
                            message.success('取消配送成功');
                            closeAndChangeStatus({ orderId: orderId, orderEvent: orderEvent, tabStatus: tabStatus });
                            break;
                        case 1:
                        case 3:
                            Modal.confirm({
                                title: '取消呼叫',
                                content: data.msg,
                                centered: true,
                                onOk: function () {
                                    cancelOrderReq({
                                        orderId: orderId,
                                        platform: '',
                                        isForceCancel: true
                                    }).then(function () {
                                        return closeAndChangeStatus({ orderId: orderId, orderEvent: orderEvent, tabStatus: tabStatus });
                                    });
                                }
                            });
                            break;
                        case 2:
                            message.error(data.msg);
                            break;
                        default:
                            break;
                    }
                });
            }
        };
        var title = this.props.text ? this.props.text.title : '取消配送';
        var content = this.props.text
            ? this.props.text.content
            : '骑手已接单，取消后可能产生配送费，请确定是否取消';
        return (React.createElement(ActionsContext.Consumer, null, function (_a) {
            var orderId = _a.id, orderEvent = _a.orderEvent, tabStatus = _a.tabStatus, distriOrderId = _a.distriOrderId;
            return (React.createElement(React.Fragment, null,
                React.createElement(CancelOrderButton, { onClick: function () {
                        title !== '取消呼叫'
                            ? handleOk({
                                orderId: orderId,
                                orderEvent: orderEvent,
                                tabStatus: tabStatus,
                                distriOrderId: distriOrderId
                            })
                            : handleOk({
                                orderId: orderId,
                                orderEvent: orderEvent,
                                tabStatus: tabStatus,
                                distriOrderId: distriOrderId
                            });
                    }, className: "orderbutton", type: "primary" }, title),
                React.createElement(Reconfirm, { ref: _this.myref, callback: function () {
                        return handleOk({
                            orderId: orderId,
                            orderEvent: orderEvent,
                            tabStatus: tabStatus,
                            distriOrderId: distriOrderId
                        });
                    }, text: { title: title, content: content }, cancelText: "\u518D\u60F3\u60F3" })));
        }));
    };
    return DeliveryOrderActionsinit;
}(React.PureComponent));
export { DeliveryOrderActionsinit };
DeliveryOrderActionsinit.contextType = DeliveryContext;
export var DeliveryOrderActions = function (props) {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth('配送处理', menu) ? (React.createElement(DeliveryOrderActionsinit, __assign({}, props))) : null;
};
var templateObject_1;
