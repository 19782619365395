var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { getAllRole, getAllStaff, getDelStaff } from '@/apis/generalNumber';
import { sendPv } from '@/utils/umeng';
import { Button, Table, message, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import NOAuth from './component/noAuth';
import OptionModal from './component/optionsModal';
import './index.less';
var AccountManagement = function () {
    var modalRef = React.useRef();
    var noAuthRef = React.useRef();
    var _a = __read(useState([]), 2), dataSource = _a[0], setDataSource = _a[1];
    var _b = __read(useState([]), 2), authPeople = _b[0], setAuthPeople = _b[1];
    var _c = __read(useState(null), 2), initData = _c[0], setData = _c[1];
    var _d = __read(useState(-1), 2), visible = _d[0], setVisible = _d[1];
    useEffect(function () {
        initDataFunc();
        sendPv();
    }, []);
    function initDataFunc() {
        getAllStaff().then(function (res) {
            if (res.code === 999999) {
                message.error(res.err);
            }
            if (res.data) {
                var data = res.data;
                if (data && Array.isArray(data.authDtoList)) {
                    setDataSource(data.authDtoList);
                }
            }
        });
        getAllRole().then(function (res) {
            setAuthPeople(res);
        });
    }
    var columns = [
        {
            title: '员工姓名',
            dataIndex: 'userName',
            key: 'userName',
            align: "center",
        },
        {
            title: '登录手机号',
            dataIndex: 'phone',
            key: 'phone',
            align: "center",
        },
        {
            title: '所属门店',
            dataIndex: 'belongStoreStr',
            key: 'belongStoreStr',
            align: "center"
        },
        {
            title: '角色',
            dataIndex: 'roleName',
            key: 'roleName',
            align: "center"
        },
        {
            title: '操作',
            dataIndex: 'option',
            key: 'option',
            align: "center",
            render: function (text, record, index) {
                return (React.createElement("div", { className: 'option' },
                    React.createElement("div", { className: 'edit', onClick: function () {
                            setVisible(record.id);
                            setData(record);
                        } }, "\u7F16\u8F91"),
                    React.createElement("div", null,
                        React.createElement(Popconfirm, { title: "\u5220\u9664\u540E\u4EBA\u5458\u4E0D\u53EF\u767B\u5F55\u8BE5\u5546\u6237\u7CFB\u7EDF?", onConfirm: function () { return confirm(record); }, okText: "\u786E\u5B9A", cancelText: "\u53D6\u6D88" }, "\u5220\u9664"))));
            }
        },
    ];
    return (React.createElement("div", { className: "accountmanagement" },
        React.createElement("div", { className: 'header' },
            React.createElement(Button, { onClick: function () { setVisible(0); setData(null); } },
                React.createElement("span", { className: "addicon" }, "+"),
                "\u6DFB\u52A0\u4EBA\u5458\u8D26\u53F7")),
        React.createElement("div", { className: "content" },
            React.createElement(Table, { dataSource: dataSource, columns: columns, pagination: false, bordered: true, rowKey: "id" })),
        visible >= 0 && React.createElement(OptionModal, { onCancel: function () { setVisible(-1); }, visible: visible, ref: modalRef, authPeople: authPeople, onRefresh: initDataFunc, initData: initData, noAuthRef: noAuthRef }),
        React.createElement(NOAuth, { ref: noAuthRef })));
    function confirm(people) {
        var id = people.id, accountId = people.accountId, belongStoreType = people.belongStoreType, belongStore = people.belongStore;
        var isbelongStoreAll = belongStore === "" || belongStore === "null";
        getDelStaff({ authId: id, accountId: accountId, belongStoreType: belongStoreType, belongStore: isbelongStoreAll ? [] : belongStore.split(",").map(function (item) { return Number(item); }) }).then(function (res) {
            if (res.code === 999999) {
                message.error(res.err);
            }
            else {
                if (accountId === JSON.parse(localStorage.getItem("lastChooseMerchant") || "{}").accountId) {
                    localStorage.clear();
                    window.location.href = "/login";
                }
            }
            initDataFunc();
        });
    }
};
export default AccountManagement;
