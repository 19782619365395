import EventEmitter from 'wolfy87-eventemitter';
export var bindOrderPlatformEvent = new EventEmitter();
export var BindOrderPlatformEvents;
(function (BindOrderPlatformEvents) {
    BindOrderPlatformEvents["Success"] = "SUCCESS";
})(BindOrderPlatformEvents || (BindOrderPlatformEvents = {}));
(function (BindOrderPlatformEvents) {
    BindOrderPlatformEvents.onSuccess = function (callback) {
        bindOrderPlatformEvent.addListener(BindOrderPlatformEvents.Success, callback);
    };
})(BindOrderPlatformEvents || (BindOrderPlatformEvents = {}));
