import ajax from '@utils/ajax';
export var reqSelfPlatforms = function () {
    return ajax({
        url: '/dictionary/list',
        params: { group: 'selfSend' }
    });
};
export var updateSelfOrderStatus = function (params) {
    return ajax({
        url: '/order/self/send',
        method: 'post',
        data: {
            orderId: params.orderId,
            sendStatus: params.sendStatus,
            code: params.code,
            type: params.type,
            msg: params.msg
        }
    });
};
export var pickBySelf = function (orderId, remarks) {
    return ajax.post('/order/orderSelfFetch', {
        orderId: orderId,
        remarks: remarks
    });
};
