var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { message } from 'antd';
import ajax from '@utils/ajax';
import { ActionsContext } from '../context';
import { PrintChooseModal } from '../modal';
import { PrintButton } from './styled';
import './button.less';
import { getAuth } from '@/container/AccountManagement/component';
import { useSelector } from 'react-redux';
var getPrintCases = function (storeId) {
    return ajax.get("/printer/scheme/list?storeId=" + storeId);
    // return ajax.get('/printer/receipt/stencil/list').then(res => {
    //   return res.data.receipts.map((receiptData: any) => {
    //     const group = receiptData.groups.find((group: any) => {
    //       return group.code === FormType.Store
    //     })
    //     const receipt = group.receiptFields.find((receipt: any) => {
    //       return receipt.code === StoreCode.ReceiptName
    //     })
    //     return {
    //       code: receiptData.code,
    //       name: receipt.data.text
    //     }
    //   })
    // })
};
var getPrintTemplate = function () {
    return ajax.get('/printer/receipt/stencil/list');
};
var PrintActioninit = /** @class */ (function (_super) {
    __extends(PrintActioninit, _super);
    function PrintActioninit() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            visible: false,
            cases: []
        };
        _this.toggleVisible = function () {
            var _a = _this.context, storeId = _a.storeId, platform = _a.platform;
            if (platform === 'manual') {
                message.error('手工下单的订单暂不支持打印');
                return;
            }
            var visible = _this.state.visible;
            if (!visible) {
                getPrintCases(storeId).then(function (_a) {
                    var data = _a.data;
                    if (!data.list.length) {
                        return message.error('该门店未添加打印方案');
                    }
                    var cases = data.list[0].printTypes;
                    getPrintTemplate().then(function (res) {
                        _this.setState({
                            cases: cases,
                            visible: !visible
                        });
                    });
                });
            }
            else {
                _this.setState({
                    visible: !visible
                });
            }
        };
        return _this;
    }
    PrintActioninit.prototype.render = function () {
        var _a = this.state, visible = _a.visible, cases = _a.cases;
        var orderData = this.context;
        return (React.createElement(React.Fragment, null,
            React.createElement(PrintButton
            // icon="printer"
            , { 
                // icon="printer"
                onClick: this.toggleVisible, className: "orderbutton" }, "\u8865\u6253\u5C0F\u7968"),
            React.createElement(PrintChooseModal, { orderData: orderData, onCancel: this.toggleVisible, visible: visible, cases: cases })));
    };
    return PrintActioninit;
}(React.Component));
PrintActioninit.contextType = ActionsContext;
export var PrintAction = function () {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("补打印", menu) ? React.createElement(PrintActioninit, null) : null;
};
