import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { message } from 'antd';
import { createBrowserHistory, createHashHistory } from 'history';
import { get } from 'lodash';
import { store } from '@store';
import { isElectron } from '@utils/env';
import { networkDisconnectNotify } from '@utils/notify';
import { networkDisconnectSound } from '@utils/sound';
import AccountPage from './container/Account';
import AgreementPage from './container/Agreement';
import AgreementPrivacyPage from './container/AgreementPrivacy';
import ReportPage from './container/Report';
import AccountManagement from './container/AccountManagement/index';
// import BindDeliveryPage from './container/Bind/delivery/index_copy'
import BindPlatformPage from './container/Bind/platform';
import IndexPage from './container/Index';
import LoginPage from './container/Login';
// import OrdersPage from './container/Orders'
import OrdersPage from './container/Orders/OrderList';
import StoresPage from './container/Stores';
import DefaultLayout from './layout/DefaultLayout';
import { socketConnect } from './socket';
import SelectStores from './container/Bind/delivery';
import ReceiptTpl from './container/ReceiptTpl';
import ReceiptTplA4 from './container/ReceiptTplA4';
import Dada from './container/Bind/delivery/dada';
import FengNiao from './container/Bind/delivery/fengniao';
import MerchantList from './container/Merchant/List';
import PrintHelper from './container/Helper/printer';
var ipcRenderer = get(window, 'electron.ipcRenderer', false);
export var history = isElectron ? createHashHistory() : createBrowserHistory();
var App = function () {
    React.useEffect(function () {
        var token = store.getState().user.token;
        if (token) {
            var pathname = history.location.pathname;
            if (!window.orderSocket &&
                pathname.indexOf('/bind/') === -1 &&
                pathname !== '/') {
                window.orderSocket = socketConnect({ token: token });
            }
        }
        if (isElectron) {
            window.addEventListener('offline', function () {
                networkDisconnectSound.play();
                networkDisconnectNotify({});
            });
            if (process.env.NODE_ENV !== 'development') {
                ipcRenderer.send('checking-for-update');
            }
            ipcRenderer.once('autoUpdater-canUpdate', function (event, info) {
                console.log("\u53D1\u73B0\u6709\u65B0\u7248\u672C v" + info.version + "\uFF0C\u662F\u5426\u66F4\u65B0?");
            });
            ipcRenderer.on('autoUpdater-downloaded', function (info) {
                console.log('更新下载完成', info);
                message.info('更新下载完成，3秒后将自动安装');
            });
            ipcRenderer.on('autoUpdater-error', function () {
                console.log('更新下载错误');
            });
        }
    });
    // console.log("isElectron",isElectron);
    return (React.createElement(Router, { history: history },
        React.createElement(Switch, null,
            isElectron ? (React.createElement(Route, { exact: true, path: "/", render: function () { return React.createElement(Redirect, { to: "/orders" }); } })) : (React.createElement(Route, { exact: true, path: "/", component: IndexPage })
            // <Route
            //   exact
            //   path="/"
            //   render={() => (window.location.href = 'http://www.baidu.com')}
            // />
            ),
            React.createElement(Route, { exact: true, path: "/bind/platform", component: BindPlatformPage }),
            React.createElement(Route, { exact: true, path: "/bind/delivery", component: SelectStores }),
            React.createElement(Route, { exact: true, path: "/bind/delivery/dada", component: Dada }),
            React.createElement(Route, { exact: true, path: "/bind/delivery/fengniao", component: FengNiao }),
            React.createElement(DefaultLayout, { path: "/routeMerchant/list", component: MerchantList }),
            React.createElement(DefaultLayout, { path: "/stores", component: StoresPage }),
            React.createElement(DefaultLayout, { path: "/orders", component: OrdersPage }),
            React.createElement(DefaultLayout, { path: "/analyze", component: ReportPage }),
            React.createElement(DefaultLayout, { path: "/managementaccount", component: AccountManagement }),
            React.createElement(DefaultLayout, { path: "/account", component: AccountPage }),
            React.createElement(Route, { path: "/agreement", component: AgreementPage }),
            React.createElement(Route, { path: "/agreementPrivacy", component: AgreementPrivacyPage }),
            React.createElement(Route, { path: "/printHelper", component: PrintHelper }),
            React.createElement(Route, { path: "/login", component: LoginPage }),
            React.createElement(DefaultLayout, { path: "/receipt/add", component: ReceiptTpl }),
            React.createElement(DefaultLayout, { path: "/receipt/:id", component: ReceiptTpl }),
            React.createElement(DefaultLayout, { path: "/receiptA4/add", component: ReceiptTplA4 }),
            React.createElement(DefaultLayout, { path: "/receiptA4/:code", component: ReceiptTplA4 }),
            React.createElement(Redirect, { from: "*", to: "/" }))));
};
export default App;
