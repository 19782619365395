var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Col } from 'antd';
import { last } from 'lodash';
import { DELIVERY_STATUS_TEXT_MAP } from '@utils/constants';
import { DeliveryContext } from '../context';
import { BaseInfo } from './styled';
export var getCurrentStatus = function (distriStatus) {
    if (!distriStatus)
        return null;
    var step = last(distriStatus);
    if (!step)
        return null;
    return DELIVERY_STATUS_TEXT_MAP[step.status];
};
var DeliveryOrderInfo = /** @class */ (function (_super) {
    __extends(DeliveryOrderInfo, _super);
    function DeliveryOrderInfo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DeliveryOrderInfo.prototype.render = function () {
        var distriOrder = this.context.distriOrder;
        var orderNo = distriOrder.orderNo, _a = distriOrder.status, status = _a === void 0 ? {} : _a, knightName = distriOrder.knightName, knightPhone = distriOrder.knightPhone;
        return (React.createElement(BaseInfo, { gutter: 2 },
            React.createElement(Col, { span: 8, className: "delivery-no-wrapper" },
                React.createElement("span", { className: "delivery-text" }, "\u914D\u9001\u5355\u53F7\uFF1A"),
                React.createElement("span", { className: "delivery-no" }, orderNo)),
            React.createElement(Col, { span: 5 },
                "\u72B6\u6001\uFF1A",
                status.name),
            React.createElement(Col, { span: 5 },
                "\u914D\u9001\u5458\uFF1A",
                knightName),
            React.createElement(Col, { span: 6 },
                "\u8054\u7CFB\u65B9\u5F0F\uFF1A",
                knightPhone)));
    };
    return DeliveryOrderInfo;
}(React.PureComponent));
export { DeliveryOrderInfo };
DeliveryOrderInfo.contextType = DeliveryContext;
