import React from 'react';
import { Row, Col, Button } from 'antd';
import { SelfDeliveryRow, CloseWrapper } from './styled';
import FormModal from '@components/FormModal';
import { useSelector, useDispatch } from 'react-redux';
var mapState = function (state) { return ({
    selfDelivery: state.selfDelivery
}); };
function transformCodeToText(list, platform) {
    var obj = list.find(function (item) {
        if (item.code === platform) {
            return true;
        }
    });
    return obj && obj.value || '';
}
export var ViewSelfDeliveryModal = function (props) {
    var platform = props.platform, remark = props.remark, hide = props.hide;
    var selfDelivery = useSelector(mapState).selfDelivery;
    var dispatch = useDispatch();
    React.useEffect(function () {
        dispatch.selfDelivery.fetchPlatforms();
    }, []);
    return (React.createElement(FormModal, { title: "\u67E5\u770B\u914D\u9001\u5355", onCancel: hide, visible: true, footer: false },
        React.createElement(SelfDeliveryRow, null,
            React.createElement(Row, null,
                React.createElement(Col, { span: 4 }, "\u914D\u9001\u5E73\u53F0:"),
                React.createElement(Col, { span: 20 }, "\u81EA\u914D\u9001"))),
        React.createElement(SelfDeliveryRow, null,
            React.createElement(Row, null,
                React.createElement(Col, { span: 4 }, "\u914D\u9001\u4FE1\u606F:"),
                React.createElement(Col, { span: 20 }, (selfDelivery === null || selfDelivery === void 0 ? void 0 : selfDelivery.platforms) && selfDelivery.platforms.length
                    ? transformCodeToText(selfDelivery.platforms, platform)
                    : ''))),
        React.createElement(SelfDeliveryRow, null,
            React.createElement(Row, null,
                React.createElement(Col, { span: 4 }),
                React.createElement(Col, { offset: 4, span: 20 }, remark))),
        React.createElement(CloseWrapper, null,
            React.createElement(Button, { type: "primary", onClick: hide }, "\u5173\u95ED"))));
};
