var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Checkbox, Form, Input } from 'antd';
import { isElectron } from '@utils/env';
var FormItem = Form.Item;
var PasswordLoginForm = /** @class */ (function (_super) {
    __extends(PasswordLoginForm, _super);
    function PasswordLoginForm() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PasswordLoginForm.prototype.componentDidMount = function () {
        if (isElectron) {
            var setFieldsValue = this.props.form.setFieldsValue;
            var config = require('../../../../config').default;
            var savedAccount = config.get('account');
            if (savedAccount) {
                setFieldsValue(__assign({}, savedAccount));
            }
        }
    };
    PasswordLoginForm.prototype.render = function () {
        var getFieldDecorator = this.props.form.getFieldDecorator;
        var handleSubmit = this.props.handleSubmit;
        return (React.createElement(Form, null,
            React.createElement(FormItem, null, getFieldDecorator('phone', {
                rules: [
                    {
                        required: true,
                        message: '请输入账号'
                    }
                ]
            })(React.createElement(Input, { placeholder: "\u8D26\u53F7", onPressEnter: handleSubmit }))),
            React.createElement(FormItem, { style: { marginBottom: 0 } }, getFieldDecorator('password', {
                rules: [
                    {
                        required: true,
                        message: '请输入密码'
                    }
                ]
            })(React.createElement(Input, { type: "password", placeholder: "\u5BC6\u7801", onPressEnter: handleSubmit }))),
            isElectron ? (React.createElement(FormItem, { style: { marginBottom: 0 } }, getFieldDecorator('saveAccountCheck', {
                valuePropName: 'checked',
                initialValue: true
            })(React.createElement(Checkbox, null, "\u8BB0\u4F4F\u8D26\u53F7\u5BC6\u7801")))) : null));
    };
    return PasswordLoginForm;
}(React.PureComponent));
var WrappedPasswordLoginForm = Form.create({})(PasswordLoginForm);
export default WrappedPasswordLoginForm;
