var _a, _b, _c;
export var ORDER_STATUS_MAP = {
    ALL: 'ALL',
    TO_BE_CONFIRMED: 'TO_BE_CONFIRMED',
    TO_BE_SHIPPING: 'TO_BE_SHIPPING',
    WAIT_GRAB: 'WAIT_GRAB',
    IN_THE_DISTRIBUTION: 'IN_THE_DISTRIBUTION',
    DONE: 'DONE',
    CANCELED: 'CANCELED',
    TIME_OUT_UN_EXPRESSION: 'TIME_OUT_UN_EXPRESSION',
    TO_BE_PICK: "WAIT_PICK_UP"
};
export var reconfirmORDER_AgreeRefund = {
    TO_BE_SHIPPING: '同意后将退款，请确定是否同意',
    WAIT_GRAB: '同意后将退款，请确定是否同意',
    IN_THE_DISTRIBUTION: '骑手已接单，取消后可能产生配送费，请确定是否同意',
    DONE: '该订单已完成配送，请确定是否同意'
};
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus[OrderStatus["WaitForConfirm"] = 0] = "WaitForConfirm";
    OrderStatus[OrderStatus["WaitForDelivery"] = 1] = "WaitForDelivery";
    OrderStatus[OrderStatus["WaitForTake"] = 50] = "WaitForTake";
    OrderStatus[OrderStatus["Deliverying"] = 2] = "Deliverying";
    OrderStatus[OrderStatus["Finish"] = 3] = "Finish";
    OrderStatus[OrderStatus["Cancel"] = 4] = "Cancel";
    OrderStatus[OrderStatus["Tobepick"] = 5] = "Tobepick";
})(OrderStatus || (OrderStatus = {}));
export var DeliveryStatus;
(function (DeliveryStatus) {
    // 待支付
    DeliveryStatus[DeliveryStatus["WaitingPay"] = 15] = "WaitingPay";
    // 派单中
    DeliveryStatus[DeliveryStatus["Sending"] = 20] = "Sending";
    // 取件中
    DeliveryStatus[DeliveryStatus["Receiveing"] = 30] = "Receiveing";
    // 已到店
    DeliveryStatus[DeliveryStatus["Arrived"] = 35] = "Arrived";
    // 送货中
    DeliveryStatus[DeliveryStatus["Delivering"] = 40] = "Delivering";
    // 已送达
    DeliveryStatus[DeliveryStatus["Compeleted"] = 50] = "Compeleted";
    // 已取消
    DeliveryStatus[DeliveryStatus["Canceled"] = 60] = "Canceled";
})(DeliveryStatus || (DeliveryStatus = {}));
export var EDeliveryProgressStatus;
(function (EDeliveryProgressStatus) {
    // 客户下配送单完成
    EDeliveryProgressStatus[EDeliveryProgressStatus["CU_PLACE"] = 1] = "CU_PLACE";
    // 客户支付完成
    EDeliveryProgressStatus[EDeliveryProgressStatus["CU_PAY"] = 2] = "CU_PAY";
    // 配送员抢单成功
    EDeliveryProgressStatus[EDeliveryProgressStatus["CO_GRAB"] = 30] = "CO_GRAB";
    // 配送员到店成功
    EDeliveryProgressStatus[EDeliveryProgressStatus["CO_INPLACE"] = 31] = "CO_INPLACE";
    // 配送员取件成功
    EDeliveryProgressStatus[EDeliveryProgressStatus["CO_PICKUP"] = 32] = "CO_PICKUP";
    // 配送员完成订单
    EDeliveryProgressStatus[EDeliveryProgressStatus["CO_FINISH"] = 33] = "CO_FINISH";
    // 配送员取消订单
    EDeliveryProgressStatus[EDeliveryProgressStatus["CO_ABORT"] = 34] = "CO_ABORT";
    // 系统取消三方配送单
    EDeliveryProgressStatus[EDeliveryProgressStatus["SYS_ABORT"] = 50] = "SYS_ABORT";
})(EDeliveryProgressStatus || (EDeliveryProgressStatus = {}));
export var ORDER_STATUS_MAP_VALUE = (_a = {},
    _a[ORDER_STATUS_MAP.TO_BE_CONFIRMED] = OrderStatus.WaitForConfirm,
    _a[ORDER_STATUS_MAP.TO_BE_SHIPPING] = OrderStatus.WaitForDelivery,
    _a[ORDER_STATUS_MAP.WAIT_GRAB] = OrderStatus.WaitForTake,
    _a[ORDER_STATUS_MAP.IN_THE_DISTRIBUTION] = OrderStatus.Deliverying,
    _a[ORDER_STATUS_MAP.DONE] = OrderStatus.Finish,
    _a[ORDER_STATUS_MAP.CANCELED] = OrderStatus.Cancel,
    _a[ORDER_STATUS_MAP.TO_BE_PICK] = OrderStatus.Tobepick,
    _a);
export var ORDER_SUB_STATUS_MAP = {
    DISTRIBUTION_ABNORMAL: 'DISTRIBUTION_ABNORMAL',
    REFUND_APPLY: 'REFUND_APPLY',
    CANCELED_APPLY: 'CANCELED_APPLY',
    URGE_APPLY: 'URGE_APPLY'
};
export var ORDER_STATUS_TEXT_MAP = (_b = {},
    _b[OrderStatus.WaitForConfirm] = '待确认',
    _b[OrderStatus.WaitForDelivery] = '待配送',
    _b[OrderStatus.WaitForTake] = '待抢单',
    _b[OrderStatus.Deliverying] = '配送中',
    _b[OrderStatus.Finish] = '已完成',
    _b[OrderStatus.Cancel] = '已取消',
    _b);
// export const ORDER_STATUS_KEYS_MAP = Object.values(ORDER_STATUS_MAP).slice(1)
export var ORDER_STATUS_KEYS_MAP = [
    'TO_BE_CONFIRMED',
    'TO_BE_SHIPPING',
    'IN_THE_DISTRIBUTION',
    'DONE',
    'CANCELED',
    'WAIT_PICK_UP'
];
ORDER_STATUS_KEYS_MAP[50] = ORDER_STATUS_KEYS_MAP[100] = 'WAIT_GRAB';
export var ORDER_STATUS_CODE_MAP = ORDER_STATUS_KEYS_MAP.reduce(function (acc, key, i) {
    acc[key] = i;
    return acc;
}, {});
export var ORDER_SUB_STATUS_KEYS_MAP = (_c = {},
    _c[ORDER_SUB_STATUS_MAP.REFUND_APPLY] = 5,
    _c[ORDER_SUB_STATUS_MAP.CANCELED_APPLY] = 5,
    _c);
export var ORDER_TABS = [
    {
        name: '全部',
        key: ORDER_STATUS_MAP.ALL
    },
    {
        name: '待确认',
        key: ORDER_STATUS_MAP.TO_BE_CONFIRMED,
        isHot: 1
    },
    {
        name: '待配送',
        key: ORDER_STATUS_MAP.TO_BE_SHIPPING,
        isHot: 1
    },
    {
        name: '待抢单',
        key: ORDER_STATUS_MAP.WAIT_GRAB,
        isHot: 1
    },
    {
        name: '待取货',
        key: ORDER_STATUS_MAP.TO_BE_PICK,
        isHot: 1
    },
    {
        name: '配送中',
        key: ORDER_STATUS_MAP.IN_THE_DISTRIBUTION,
        isHot: 1
    },
    {
        name: '异常',
        key: ORDER_SUB_STATUS_MAP.DISTRIBUTION_ABNORMAL,
        isHot: 1
    },
    {
        name: '退款',
        key: ORDER_SUB_STATUS_MAP.REFUND_APPLY,
        isHot: 1
    },
    {
        name: '取消单',
        key: ORDER_SUB_STATUS_MAP.CANCELED_APPLY,
        isHot: 1
    },
    {
        name: '催单',
        key: ORDER_SUB_STATUS_MAP.URGE_APPLY,
        isHot: 1
    },
    {
        name: '已完成',
        key: ORDER_STATUS_MAP.DONE
    },
    {
        name: '已取消',
        key: ORDER_STATUS_MAP.CANCELED
    }
];
export var ActionTips;
(function (ActionTips) {
    ActionTips["AGREE_CANCEL_SUCCESS"] = "\u540C\u610F\u53D6\u6D88\u6210\u529F";
    ActionTips["AGREE_REFUND_SUCCESS"] = "\u540C\u610F\u9000\u6B3E\u6210\u529F";
    ActionTips["CANCEL_ORDER_SUCCESS"] = "\u53D6\u6D88\u8BA2\u5355\u6210\u529F";
    ActionTips["DISAGREE_CANCEL_SUCCESS"] = "\u4E0D\u540C\u610F\u53D6\u6D88\u8BA2\u5355\u6210\u529F";
    ActionTips["DISAGREE_REFUND_SUCCESS"] = "\u4E0D\u540C\u610F\u9000\u6B3E\u6210\u529F";
    ActionTips["RECEIVE_SUCCESS"] = "\u63A5\u5355\u6210\u529F";
    ActionTips["REFUSE_SUCCESS"] = "\u62D2\u5355\u6210\u529F";
    ActionTips["REPLY_URGED_SUCCESS"] = "\u56DE\u590D\u50AC\u5355\u6210\u529F";
})(ActionTips || (ActionTips = {}));
export var EDeliveryType;
(function (EDeliveryType) {
    // 渠道配送
    EDeliveryType[EDeliveryType["Channel"] = 2] = "Channel";
    // 平台配送
    EDeliveryType[EDeliveryType["Platform"] = 1] = "Platform";
})(EDeliveryType || (EDeliveryType = {}));
export var EDeliveryCouponType;
(function (EDeliveryCouponType) {
    // 不使用
    EDeliveryCouponType[EDeliveryCouponType["Unuse"] = 0] = "Unuse";
    // 系统推荐
    EDeliveryCouponType[EDeliveryCouponType["Recommend"] = -1] = "Recommend";
})(EDeliveryCouponType || (EDeliveryCouponType = {}));
export var UseCoupon;
(function (UseCoupon) {
    UseCoupon[UseCoupon["Use"] = 2] = "Use";
    UseCoupon[UseCoupon["Unuse"] = 1] = "Unuse";
})(UseCoupon || (UseCoupon = {}));
export var EStrategyType;
(function (EStrategyType) {
    EStrategyType[EStrategyType["CHEAP"] = 1] = "CHEAP";
    EStrategyType[EStrategyType["SPEED"] = 2] = "SPEED";
})(EStrategyType || (EStrategyType = {}));
export var EDeliveryHistoryState;
(function (EDeliveryHistoryState) {
    EDeliveryHistoryState[EDeliveryHistoryState["WAIT"] = 0] = "WAIT";
    EDeliveryHistoryState[EDeliveryHistoryState["RUNNING"] = 1] = "RUNNING";
    EDeliveryHistoryState[EDeliveryHistoryState["FAIL"] = 2] = "FAIL";
})(EDeliveryHistoryState || (EDeliveryHistoryState = {}));
export var ERouteType;
(function (ERouteType) {
    ERouteType[ERouteType["Assign"] = 1] = "Assign";
    ERouteType[ERouteType["Recommend"] = 2] = "Recommend";
})(ERouteType || (ERouteType = {}));
