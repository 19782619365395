var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { ReceiptPreviewWrapper, Custom, QRCode, BarCode } from './styled';
import { BasicCode, MenuCode, StoreCode, CustomCode } from '../../enums/codes';
import getReceiptData from './receiptData';
import Receipt from '../Receipt';
import ReceiptSeperator from '../Seperator';
var ReceiptPreview = function (props) {
    var storeData = props.storeData, basicData = props.basicData, menuData = props.menuData, feeData = props.feeData, customData = props.customData;
    if (!basicData || !menuData || !feeData)
        return null;
    var values = __spread(storeData, basicData, menuData, feeData, customData);
    var renderGoods = function (goods) {
        return (React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    menuData.includes(MenuCode.Name) && React.createElement("th", null, "\u540D\u79F0"),
                    menuData.includes(MenuCode.Quantity) && React.createElement("th", null, "\u6570\u91CF"),
                    menuData.includes(MenuCode.Total) && React.createElement("th", null, "\u91D1\u989D"))),
            React.createElement("tbody", null,
                menuData.includes(MenuCode.Package) && (React.createElement("tr", null,
                    React.createElement("td", { colSpan: 3, align: "center", height: 50 },
                        React.createElement(ReceiptSeperator, null, "1\u53F7\u53E3\u888B")))), goods === null || goods === void 0 ? void 0 :
                goods.map(function (good, index) {
                    return (React.createElement("tr", { key: index },
                        menuData.includes(MenuCode.Name) && React.createElement("td", null, good.name),
                        menuData.includes(MenuCode.Quantity) && (React.createElement("td", null,
                            "x",
                            good.quantity)),
                        menuData.includes(MenuCode.Total) && React.createElement("td", null, good.price)));
                }),
                menuData.includes(MenuCode.Spec) && (React.createElement("tr", null,
                    React.createElement("td", null, "\u73CD\u73E0\u5976\u8336\u3010\u5927\u4EFD\u3011\u3010\u65E0\u7CD6\u3011\u3010\u70ED\u3011"),
                    React.createElement("td", null, "x2"),
                    React.createElement("td", null, "80"))),
                menuData.includes(MenuCode.Package) && (React.createElement("tr", null,
                    React.createElement("td", { colSpan: 3, align: "center", height: 50 },
                        React.createElement(ReceiptSeperator, null, "2\u53F7\u53E3\u888B")))), goods === null || goods === void 0 ? void 0 :
                goods.map(function (good, index) {
                    return (React.createElement("tr", { key: index },
                        menuData.includes(MenuCode.Name) && React.createElement("td", null, good.name),
                        menuData.includes(MenuCode.Quantity) && (React.createElement("td", null,
                            "x",
                            good.quantity)),
                        menuData.includes(MenuCode.Total) && React.createElement("td", null, good.price)));
                }))));
    };
    var renderCustomInfo = function () {
        return (React.createElement(Custom, null,
            customData.includes(CustomCode.EasyBarCode) ? (React.createElement(BarCode, { size: "small" })) : null,
            customData.includes(CustomCode.MeituanBarCode) ? (React.createElement(BarCode, { size: "big" })) : null,
            customData.includes(CustomCode.Image) && props.customUrl ? (React.createElement(QRCode, { src: props.customUrl })) : null,
            customData.includes(CustomCode.Text) ? (React.createElement("div", { className: "desc" }, props.customText)) : null,
            customData.includes(CustomCode.Sign) ? (React.createElement("div", { className: "sign" }, "\u5BA2\u6237\u7B7E\u6536\uFF1A")) : null));
    };
    var receiptName = storeData.includes(StoreCode.ReceiptName)
        ? props.receiptName
        : '';
    var receiptData = getReceiptData(values);
    receiptData.receiptName = receiptName;
    receiptData.isPreOrder = basicData.includes(BasicCode.PreOrder);
    return (React.createElement(ReceiptPreviewWrapper, null,
        React.createElement(Receipt, { data: receiptData, renderGoods: renderGoods(receiptData.goods), renderCustomInfo: renderCustomInfo() })));
};
export default ReceiptPreview;
