import React from 'react';
import { ReceiptFormWrapper, FormBox, FormTitle, FormRow } from './styled';
import { Radio, Checkbox, Input, Row, Col } from 'antd';
import UploadImage from '@components/UploadImage';
import { FormType } from '../../../../enums/receipt';
var FieldType;
(function (FieldType) {
    FieldType[FieldType["Checkbox"] = 1] = "Checkbox";
    FieldType[FieldType["Input"] = 2] = "Input";
    FieldType[FieldType["MultiInput"] = 3] = "MultiInput";
    FieldType[FieldType["SmallImage"] = 4] = "SmallImage";
    FieldType[FieldType["BigImage"] = 5] = "BigImage";
})(FieldType || (FieldType = {}));
var ReceiptForm = function (props) {
    var _a;
    var handleReceiptSize = function (e) {
        props.setReceiptSize(e.target.value);
    };
    var handleClickCheckbox = function (values, code) {
        if (code === FormType.Basic) {
            props.setBasic(values);
        }
        else if (code === FormType.Menu) {
            props.setMenu(values);
        }
        else if (code === FormType.Fee) {
            props.setFee(values);
        }
    };
    var handleComplexCheckBox = function (e, formType) {
        var code = e.target.value;
        if (formType === FormType.Store) {
            if (e.target.checked) {
                props.addToStore(code);
            }
            else {
                props.removeFromStore(code);
            }
        }
        else if (formType === FormType.Custom) {
            if (e.target.checked) {
                props.addToCustom(code);
            }
            else {
                props.removeFromCustom(code);
            }
        }
    };
    var uploadImg = function (key, url) {
        props.setCustomUrl(url);
    };
    var renderReceiptSize = function (data) {
        return (React.createElement(FormRow, null,
            React.createElement(Radio.Group, { buttonStyle: "solid", value: props.receiptSize, onChange: handleReceiptSize }, data.receiptFields.map(function (field) {
                return (React.createElement(Radio.Button, { key: field.code, value: field.code }, field.name));
            }))));
    };
    var renderCheckBox = function (data) {
        var value = [];
        if (data.code === FormType.Basic) {
            value = props.basicData;
        }
        else if (data.code === FormType.Menu) {
            value = props.menuData;
        }
        else if (data.code === FormType.Fee) {
            value = props.feeData;
        }
        return (React.createElement(FormRow, null,
            React.createElement(Checkbox.Group, { className: "group", value: value, onChange: function (values) { return handleClickCheckbox(values, data.code); } },
                React.createElement(Row, null, data.receiptFields.map(function (option) { return (React.createElement(Col, { span: 8, className: "col", key: option.code },
                    React.createElement(Checkbox, { value: option.code }, option.name))); })))));
    };
    var renderComplexForm = function (data) {
        var targetData = [];
        if (data.code === FormType.Store) {
            targetData = props.storeData;
        }
        else if (data.code === FormType.Custom) {
            targetData = props.customData;
        }
        return data.receiptFields.map(function (field) {
            if (field.type === FieldType.Checkbox) {
                return (React.createElement(FormRow, { key: field.code },
                    React.createElement(Checkbox, { value: field.code, checked: targetData.includes(field.code), onChange: function (e) { return handleComplexCheckBox(e, data.code); } }, field.name)));
            }
            else if (field.type === FieldType.Input) {
                return (React.createElement("div", { key: field.code },
                    React.createElement(FormRow, null,
                        React.createElement(Checkbox, { value: field.code, checked: targetData.includes(field.code), onChange: function (e) { return handleComplexCheckBox(e, data.code); } }, field.name)),
                    React.createElement(FormRow, null,
                        React.createElement("div", { className: "input-box" },
                            React.createElement(Input, { className: "custom", placeholder: "\u8BF7\u8F93\u5165\u5355\u636E\u540D\u79F0", value: props.receiptName, onChange: function (e) { return props.setReceiptName(e.target.value); } })))));
            }
            else if (field.type === FieldType.MultiInput) {
                return (React.createElement("div", { key: field.code },
                    React.createElement(FormRow, null,
                        React.createElement(Checkbox, { value: field.code, checked: targetData.includes(field.code), onChange: function (e) { return handleComplexCheckBox(e, data.code); } }, field.name)),
                    React.createElement(FormRow, null,
                        React.createElement("div", { className: "input-box" },
                            React.createElement(Input.TextArea, { className: "custom", rows: 4, placeholder: "\u6B64\u5904\u4E00\u822C\u662F\u586B\u5199\u5546\u6237\u5730\u5740\u3001\u8054\u7CFB\u7535\u8BDD\u3001\u4E8C\u7EF4\u7801 \u4F7F\u7528\u5907\u6CE8\u8BF4\u660E\u7B49", value: props.customText, onChange: function (e) { return props.setCustomText(e.target.value); } })))));
            }
            else if (field.type === FieldType.SmallImage ||
                field.type === FieldType.BigImage) {
                return (React.createElement("div", { key: field.code },
                    React.createElement(FormRow, null,
                        React.createElement(Checkbox, { value: field.code, checked: targetData.includes(field.code), onChange: function (e) { return handleComplexCheckBox(e, data.code); } }, field.name)),
                    React.createElement(FormRow, null,
                        React.createElement(UploadImage, { onChange: uploadImg, value: props.customUrl }, "\u4E0A\u4F20\u56FE\u7247"))));
            }
        });
    };
    var render = function (data) {
        switch (data.code) {
            case FormType.ReceiptSize:
                return renderReceiptSize(data);
            case FormType.Basic:
            case FormType.Menu:
            case FormType.Fee:
                return renderCheckBox(data);
            default:
                return renderComplexForm(data);
        }
    };
    return (React.createElement(ReceiptFormWrapper, null, (_a = props.list) === null || _a === void 0 ? void 0 : _a.map(function (item) {
        return (React.createElement(FormBox, { key: item.code },
            React.createElement(FormRow, { key: item.code },
                React.createElement(FormTitle, null, item.name),
                render(item))));
    })));
};
export default ReceiptForm;
