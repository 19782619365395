var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Form, Input, Select, message, Button } from 'antd';
import { InputSmsCode, SmsInputGroup } from '@components/Common';
import { requestForLocation } from '@utils/request';
import FormModal from '@components/FormModal';
import { PHONE_REGEX } from '@utils/regex';
var FormItem = Form.Item;
var Option = Select.Option;
var SMS_MAX_COUNTDOWN = 60;
var formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 }
};
var UUPTForm = /** @class */ (function (_super) {
    __extends(UUPTForm, _super);
    function UUPTForm(props) {
        var _this = _super.call(this, props) || this;
        _this.handleGetSMSCode = function () {
            var validateFields = _this.props.form.validateFields;
            if (_this.state.countdown < SMS_MAX_COUNTDOWN)
                return false;
            validateFields(['phone'], function (errors, _a) {
                var phone = _a.phone;
                if (errors)
                    return false;
                requestForLocation
                    .post('/api/uupt/sendSmsMsg', "mobile=" + phone)
                    .then(function (back) {
                    if (back.code === 0) {
                        _this.startCountdownTimer();
                    }
                    else {
                        message.error(back.err);
                    }
                });
            });
        };
        _this.handleCancel = function () {
            var onCancel = _this.props.onCancel;
            _this.setState({
                uuStoreList: [],
                countdown: SMS_MAX_COUNTDOWN,
                uuOpenId: '',
                buttonText: '下一步'
            });
            onCancel();
        };
        _this.submitUuptBind = function () {
            var _a = _this.props, ysStoreId = _a.ysStoreId, getDeliveryPlatform = _a.getDeliveryPlatform;
            var validateFields = _this.props.form.validateFields;
            var uuOpenId = _this.state.uuOpenId;
            validateFields(function (errors, values) {
                if (errors)
                    return false;
                var phone = values.phone, verificationCode = values.verificationCode, uuStore = values.uuStore;
                if (!uuOpenId && phone && verificationCode) {
                    // Content-Type: application/x-www-form-urlencoded
                    requestForLocation
                        .post('/api/uupt/getOpenId', "mobile=" + phone + "&smsCode=" + verificationCode + "&storeId=" + ysStoreId)
                        .then(function (back) {
                        if (back.code === 0 && back.data) {
                            _this.setState({
                                uuOpenId: back.data.openId
                            });
                            requestForLocation
                                .post('/api/uupt/getUuptStoreList', "openId=" + back.data.openId + "&storeId=" + ysStoreId)
                                .then(function (_a) {
                                var code = _a.code, data = _a.data;
                                if (code === 0) {
                                    _this.setState({
                                        uuStoreList: data.uuStoreList,
                                        buttonText: '绑定'
                                    });
                                }
                                else {
                                    message.error(back.err);
                                }
                            });
                        }
                        else {
                            message.error(back.err);
                        }
                    });
                }
                else if (uuOpenId && uuStore) {
                    requestForLocation
                        .post('/api/uupt/uuptConfirmAuth', "ysStoreId=" + ysStoreId + "&uuStoreId=" + uuStore.key + "&uuOpenId=" + uuOpenId + "&uuStoreName=" + uuStore.label)
                        .then(function (back) {
                        if (back.code === 0) {
                            message.success('绑定配送平台成功');
                            getDeliveryPlatform({});
                            _this.handleCancel();
                        }
                        else {
                            message.error(back.err);
                        }
                    });
                }
            });
        };
        _this.state = {
            uuStoreList: [],
            countdown: SMS_MAX_COUNTDOWN,
            uuOpenId: '',
            buttonText: '下一步'
        };
        return _this;
    }
    UUPTForm.prototype.startCountdownTimer = function () {
        var _this = this;
        var countdownTimer = setInterval(function () {
            var currCountdown = _this.state.countdown;
            if (currCountdown && currCountdown <= SMS_MAX_COUNTDOWN) {
                _this.setState({ countdown: currCountdown - 1 });
            }
            else {
                clearInterval(countdownTimer);
                _this.setState({ countdown: SMS_MAX_COUNTDOWN });
            }
        }, 1000);
    };
    UUPTForm.prototype.renderStoreList = function () {
        return this.state.uuStoreList.map(function (store) {
            return (React.createElement(Option, { key: store.uuStoreId, value: store.uuStoreId }, store.uuStoreName));
        });
    };
    UUPTForm.prototype.render = function () {
        var _a = this.state, countdown = _a.countdown, uuStoreList = _a.uuStoreList, uuOpenId = _a.uuOpenId, buttonText = _a.buttonText;
        var _b = this.props, form = _b.form, visible = _b.visible;
        var getFieldDecorator = form.getFieldDecorator;
        return (React.createElement(FormModal, { title: "\u7ED1\u5B9AUU\u8DD1\u817F", visible: visible, onCancel: this.handleCancel, onOk: this.submitUuptBind, okText: buttonText, destroyOnClose: true },
            React.createElement(Form, __assign({}, formItemLayout), !uuOpenId ? (React.createElement(React.Fragment, null,
                React.createElement(FormItem, { label: "\u624B\u673A\u53F7" }, getFieldDecorator('phone', {
                    rules: [
                        {
                            required: true,
                            pattern: PHONE_REGEX,
                            message: '请输入正确的手机号'
                        }
                    ]
                })(React.createElement(Input, { placeholder: "\u624B\u673A\u53F7" }))),
                React.createElement(FormItem, { label: "\u9A8C\u8BC1\u7801" }, getFieldDecorator('verificationCode', {
                    rules: [
                        {
                            required: true,
                            message: '请输入验证码'
                        }
                    ]
                })(React.createElement(SmsInputGroup, null,
                    React.createElement(InputSmsCode, { placeholder: "\u9A8C\u8BC1\u7801" }),
                    React.createElement(Button, { onClick: this.handleGetSMSCode }, countdown && countdown < SMS_MAX_COUNTDOWN
                        ? countdown + "S"
                        : '获取验证码')))))) : (React.createElement(FormItem, { label: "\u9009\u62E9UU\u95E8\u5E97" }, getFieldDecorator('uuStore', {
                initialValue: uuStoreList[0]
                    ? {
                        key: uuStoreList[0].uuStoreId,
                        label: uuStoreList[0].uuStoreName
                    }
                    : '',
                rules: [
                    {
                        required: true,
                        message: '请选择需要绑定的UU门店'
                    }
                ]
            })(React.createElement(Select, { labelInValue: true }, this.renderStoreList())))))));
    };
    return UUPTForm;
}(React.Component));
var WrappedUUPTForm = Form.create({})(UUPTForm);
export default WrappedUUPTForm;
