function normalizeData(data) {
    return data.reduce(function (memo, item) {
        memo.push([item.longitude, item.latitude, item.count]);
        return memo;
    }, []);
}
export var getOption = function (data) {
    var normalizedData = normalizeData(data);
    var center = [116.4073963, 39.9041999];
    if (data.length) {
        center = [data[0].longitude, data[0].latitude];
    }
    return {
        animation: false,
        bmap: {
            center: center,
            zoom: 12,
            roam: 'move'
        },
        visualMap: {
            show: true,
            top: 'top',
            right: '1%',
            min: 0,
            max: 5,
            seriesIndex: 0,
            calculable: true,
            inRange: {
                color: [
                    '#0A13CF',
                    '#01F1FE',
                    '#4AFEB9',
                    '#FBFF07',
                    '#FFAE03',
                    '#FFA807',
                    '#FF2C02'
                ]
            }
        },
        series: [
            {
                type: 'heatmap',
                coordinateSystem: 'bmap',
                data: normalizedData,
                pointSize: 5,
                blurSize: 6
            }
        ]
    };
};
