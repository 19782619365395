var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { message } from 'antd';
import { printReceipt } from '@printer';
import ajax from '@utils/ajax';
import { ORDER_STATUS_CODE_MAP } from '../constants';
import { ActionsContext } from '../context';
import { emitOrderChange } from './common';
import { ConfirmButton } from './styled';
import './button.less';
import { getAuth } from '@/container/AccountManagement/component';
import { useSelector } from 'react-redux';
var printAllReceipt = function (_a) {
    var cases = _a.cases, orderData = _a.orderData;
    cases.map(function (item) {
        printReceipt({
            printerConfig: item.printerVO,
            printCase: item.code,
            data: orderData,
            copies: item.printNumber
        });
    });
};
var getPrintCases = function (storeId) {
    return ajax.get("/printer/scheme/list?storeId=" + storeId);
};
export var getPrintCasesAndPrintAll = function (orderData) {
    getPrintCases(orderData.storeId).then(function (_a) {
        var data = _a.data;
        if (data.list.length) {
            var cases = data.list[0].printTypes;
            printAllReceipt({ cases: cases, orderData: orderData });
        }
        else {
            message.error('该门店未添加打印方案');
        }
    });
};
var receiveOrderReq = function (_a) {
    var orderId = _a.orderId, platform = _a.platform;
    return ajax.post('/order/receive', { orderId: orderId, platform: platform });
};
var ReceiveOrderActioninit = /** @class */ (function (_super) {
    __extends(ReceiveOrderActioninit, _super);
    function ReceiveOrderActioninit() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function () {
            var _a = _this.context, id = _a.id, platform = _a.platform, orderEvent = _a.orderEvent, tabStatus = _a.tabStatus, orderData = __rest(_a, ["id", "platform", "orderEvent", "tabStatus"]);
            receiveOrderReq({ orderId: id, platform: platform })
                .then(function () {
                message.success('接单成功');
                getPrintCasesAndPrintAll(__assign({ id: id, platform: platform }, orderData));
                emitOrderChange({
                    tabStatus: tabStatus,
                    orderEvent: orderEvent,
                    orderId: id,
                    nextStatus: ORDER_STATUS_CODE_MAP.TO_BE_SHIPPING
                });
            })
                .catch(function (error) {
                if (error.code === 10060) {
                    emitOrderChange({
                        tabStatus: tabStatus,
                        orderEvent: orderEvent,
                        orderId: id,
                        nextStatus: ORDER_STATUS_CODE_MAP.TO_BE_SHIPPING
                    });
                }
            });
        };
        return _this;
    }
    ReceiveOrderActioninit.prototype.render = function () {
        return (getAuth("订单处理") ?
            React.createElement(React.Fragment, null,
                React.createElement(ConfirmButton, { icon: "check", onClick: this.handleClick, className: "orderbutton" }, "\u63A5\u5355")) : null);
    };
    return ReceiveOrderActioninit;
}(React.PureComponent));
ReceiveOrderActioninit.contextType = ActionsContext;
export var ReceiveOrderAction = function () {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("订单处理", menu) ? React.createElement(ReceiveOrderActioninit, null) : null;
};
