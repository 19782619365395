import * as React from 'react';
import { Steps } from 'antd';
import { DELIVERY_STATUS_TEXT_MAP } from '@utils/constants';
import { CustomStepsWrapper } from './styled';
var Step = Steps.Step;
var initDistriStatus = Object.keys(DELIVERY_STATUS_TEXT_MAP)
    .slice(0, -1)
    .map(function (status) {
    return Object.assign({}, {
        status: Number(status),
        date: ''
    });
});
var customDot = function (dot) { return React.createElement(React.Fragment, null, dot); };
export var DeliveryOrderProgress = function (_a) {
    var data = _a.data;
    return (React.createElement(CustomStepsWrapper, null,
        React.createElement(Steps, { progressDot: customDot }, data && data.map(function (item) { return (React.createElement(Step, { status: item.isBright ? 'finish' : 'wait', key: item.status, title: item.name, description: item.date })); }))));
};
