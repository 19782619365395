var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Button, message } from 'antd';
import produce from 'immer';
import { cloneDeep } from 'lodash';
import ajax from '@utils/ajax';
import { requestForOrder, requestForMoney } from '@utils/request';
import { ORDER_STATUS_CODE_MAP, EDeliveryType, ORDER_SUB_STATUS_MAP } from '../constants';
import { ActionsContext } from '../context';
import { SendDeliveryModal } from '../modal';
import { emitOrderChange } from './common';
import './button.less';
import { declaredAmountforRequest } from '../components/InsuranceComponent';
import { getAuth } from '@/container/AccountManagement/component';
import UnbindTip from '../components/UnbindTip';
import { useSelector } from 'react-redux';
var getOrderDetail = function (_a) {
    var orderId = _a.orderId, _b = _a.isValuation, isValuation = _b === void 0 ? true : _b, _c = _a.isDistance, isDistance = _c === void 0 ? true : _c, _d = _a.appointType, appointType = _d === void 0 ? 0 : _d, _e = _a.reservationTime, reservationTime = _e === void 0 ? '' : _e, _f = _a.remarks, remarks = _f === void 0 ? '' : _f, _g = _a.dispatchType, dispatchType = _g === void 0 ? null : _g, _h = _a.declaredAmount, declaredAmount = _h === void 0 ? 0 : _h, _j = _a.additionAmount, additionAmount = _j === void 0 ? 0 : _j;
    var data = {
        orderId: orderId,
        isValuation: isValuation,
        isDistance: isDistance,
        dispatchType: dispatchType,
        valuation: {
            appointType: appointType,
            reservationTime: reservationTime,
            remarks: remarks
        },
        declaredAmount: declaredAmount,
        additionAmount: additionAmount
    };
    return ajax.post('/order/details', data);
};
var getTranPrice = function (payload) {
    return requestForOrder.post('/api/platform/order/getTranPrice', payload);
};
var reqWallentBalance = function () {
    return requestForMoney.get('/payment/wallet/balance');
};
var SendDeliveryActioninit = /** @class */ (function (_super) {
    __extends(SendDeliveryActioninit, _super);
    function SendDeliveryActioninit(props, ref) {
        var _a;
        var _this = _super.call(this, props) || this;
        _this.state = {
            visible: false,
            order: {},
            valuations: [],
            distance: 0,
            loading: false,
            currentTab: (((_a = _this.props.listObject) === null || _a === void 0 ? void 0 : _a.payAccountTypes) && _this.props.listObject.payAccountTypes[0]) || 2,
            balance: 0,
            platformChecked: [],
            maxPayAmount: 0,
            channelChecked: [],
            channelDispatchType: '',
            saveLoading: false
        };
        _this.valuations = [];
        _this.toggleLoading = function () {
            _this.setState(produce(function (draft) {
                draft.loading = !_this.state.loading;
            }));
        };
        _this.toggleVisible = function () {
            _this.setState(produce(function (draft) {
                draft.visible = !_this.state.visible;
            }));
        };
        _this.handleValuation = function (_a) {
            var _b = _a.useCoupon, useCoupon = _b === void 0 ? true : _b, _c = _a.expressPartnerCouponInfos, expressPartnerCouponInfos = _c === void 0 ? [] : _c, callback = _a.callback, additionAmount = _a.additionAmount, appointType = _a.appointType, appointPickUpTime = _a.appointPickUpTime, remarks = _a.remarks, currentTab = _a.currentTab;
            var orderId = _this.context.id;
            _this.setState(produce(function (draft) {
                draft.saveLoading = true;
            }));
            getTranPrice({
                orderId: orderId,
                payAccountType: currentTab || _this.state.currentTab,
                useCoupon: useCoupon,
                expressPartnerCouponInfos: expressPartnerCouponInfos,
                additionAmount: additionAmount,
                appointType: appointType,
                appointPickUpTime: appointPickUpTime,
                remarks: remarks
            })
                .then(function (_a) {
                var data = _a.data;
                if (data && !!Object.keys(data).length) {
                    _this.valuations = data.tranPriceList;
                    _this.setState(produce(function (draft) {
                        draft.order = __assign(__assign({}, _this.context), { cityCode: data.cityId });
                        draft.valuations = data.tranPriceList || [];
                        draft.distance = data.distance;
                    }), function () {
                        callback && callback(_this.valuations);
                    });
                }
                else {
                    _this.valuations = [];
                    _this.setState(produce(function (draft) {
                        draft.valuations = [];
                    }));
                }
                _this.setState(produce(function (draft) {
                    draft.saveLoading = false;
                }));
            })
                .catch(function () {
                _this.valuations = [];
                _this.setState(produce(function (draft) {
                    draft.valuations = [];
                    draft.saveLoading = false;
                }));
            });
        };
        _this.handleTipChange = function (tip) {
            var valuations = cloneDeep(_this.valuations);
            valuations.map(function (v) {
                v.amount = (Number(v.amount || 0) + tip).toFixed(2);
                return v;
            });
            _this.setState(produce(function (draft) {
                draft.valuations = valuations;
            }));
        };
        _this.declaredAmountforDetail = function (values, callback) {
            var reservationTime = values.reservationTime
                ? values.reservationTime.format('x')
                : '';
            _this.getOrderDetail({
                appointType: values.appointType,
                reservationTime: reservationTime,
                remarks: values.remarks,
                dispatchType: values.dispatchType,
                declaredAmount: values.declaredAmount,
                additionAmount: values.additionAmount,
                callback: function () {
                    callback && callback();
                }
            });
        };
        _this.handleFieldChange = function (values, callback) {
            var currentTab = _this.state.currentTab;
            var reservationTime = values.reservationTime
                ? values.reservationTime.format('x')
                : '';
            if (currentTab === EDeliveryType.Platform) {
                _this.handleValuation({
                    useCoupon: values.useCoupon,
                    expressPartnerCouponInfos: values.expressPartnerCouponInfos,
                    additionAmount: Math.abs(values.tip) * 100,
                    appointType: values.appointType,
                    appointPickUpTime: reservationTime,
                    remarks: values.remarks,
                    callback: function () {
                        callback && callback();
                    }
                });
            }
            else {
                _this.getOrderDetail({
                    appointType: values.appointType,
                    reservationTime: reservationTime,
                    remarks: values.remarks,
                    dispatchType: values.dispatchType,
                    callback: function () {
                        callback && callback();
                    }
                });
            }
        };
        _this.unbindReq = function () {
            requestForOrder.get("api/message/meituan/cancelMeituanMsg", { orderId: _this.context.id }).then(function (res) {
                var _a;
                if (res.data && ((_a = _this.unbindTipRef) === null || _a === void 0 ? void 0 : _a.current)) {
                    _this.unbindTipRef.current(res.data);
                }
                else {
                    _this.handleClick();
                }
            });
        };
        _this.handleClick = function () {
            var currentTab = _this.state.currentTab;
            _this.toggleLoading();
            if (currentTab === EDeliveryType.Platform) {
                _this.handleBalance();
                _this.handleValuation({
                    additionAmount: 0,
                    appointType: 0,
                    appointPickUpTime: '',
                    remarks: '',
                    callback: function (data) {
                        var platformIds = [];
                        var payAmount = 0;
                        data.map(function (item) {
                            if (item.checked) {
                                platformIds.push(item.platform);
                            }
                            if (item.amount - item.reducedPrice > payAmount) {
                                payAmount = item.amount - item.reducedPrice;
                            }
                        });
                        _this.handleUpdateMaxPayAmount(payAmount);
                        _this.handleUpdatePlatformChecked(platformIds);
                        _this.toggleLoading();
                        _this.toggleVisible();
                    }
                });
            }
            else {
                _this.getOrderDetail({
                    callback: function (data) {
                        var platformIds = [];
                        data.map(function (item) {
                            if (item.checked) {
                                platformIds.push(item.platform);
                            }
                        });
                        _this.handleUpdateChannelChecked(platformIds);
                        _this.toggleLoading();
                        _this.toggleVisible();
                    }
                });
            }
        };
        _this.handleSwitchTab = function (tab, callback) { return function () {
            _this.setState(produce(function (draft) {
                draft.currentTab = tab;
            }));
            if (tab === EDeliveryType.Platform) {
                _this.handleBalance();
                _this.handleValuation({
                    currentTab: tab,
                    callback: function (data) {
                        var platformIds = [];
                        var payAmount = 0;
                        data.map(function (item) {
                            if (item.checked) {
                                platformIds.push(item.platform);
                            }
                            if (item.amount - item.reducedPrice > payAmount) {
                                payAmount = item.amount - item.reducedPrice;
                            }
                        });
                        _this.handleUpdateMaxPayAmount(payAmount);
                        _this.handleUpdatePlatformChecked(platformIds);
                    }
                });
            }
            else {
                _this.getOrderDetail({
                    callback: function (data) {
                        var platformIds = [];
                        data.map(function (item) {
                            if (item.checked) {
                                platformIds.push(item.platform);
                            }
                        });
                        _this.handleUpdateChannelChecked(platformIds);
                    }
                });
            }
            if (callback) {
                callback(tab);
            }
        }; };
        _this.handleBalance = function () {
            reqWallentBalance().then(function (_a) {
                var data = _a.data, code = _a.code, err = _a.err;
                if (data && data.balance) {
                    _this.setState({
                        balance: data.balance
                    });
                }
            });
        };
        _this.handleUpdatePlatformChecked = function (platformChecked, callback) {
            _this.setState({
                platformChecked: platformChecked
            }, function () {
                if (callback) {
                    callback();
                }
            });
        };
        _this.handleUpdateMaxPayAmount = function (maxPayAmount) {
            _this.setState({
                maxPayAmount: maxPayAmount
            });
        };
        _this.handleUpdateChannelChecked = function (channelChecked, callback) {
            _this.setState({
                channelChecked: channelChecked
            }, function () {
                if (callback) {
                    callback();
                }
            });
        };
        _this.unbindTipRef = React.createRef();
        return _this;
    }
    SendDeliveryActioninit.prototype.getOrderDetail = function (_a) {
        var _this = this;
        var appointType = _a.appointType, reservationTime = _a.reservationTime, remarks = _a.remarks, dispatchType = _a.dispatchType, _b = _a.additionAmount, additionAmount = _b === void 0 ? 0 : _b, _c = _a.declaredAmount, declaredAmount = _c === void 0 ? 0 : _c, callback = _a.callback;
        var orderId = this.context.id;
        getOrderDetail({
            orderId: orderId,
            appointType: appointType,
            reservationTime: reservationTime,
            remarks: remarks,
            dispatchType: dispatchType,
            additionAmount: declaredAmountforRequest(additionAmount),
            declaredAmount: declaredAmountforRequest(declaredAmount),
        })
            .then(function (_a) {
            var data = _a.data;
            if (!data.order) {
                callback && callback('无此订单信息');
                return message.error('无此订单信息');
            }
            _this.valuations = data.valuations || [];
            _this.setState(produce(function (draft) {
                draft.order = data.order;
                draft.valuations = data.valuations || [];
                draft.distance = data.distance;
                draft.channelDispatchType = data.dispatchType;
            }), function () {
                callback && callback(_this.valuations);
            });
        }, function (err) {
            _this.valuations = [];
            _this.setState(produce(function (draft) {
                draft.valuations = [];
            }));
            callback && callback(err);
        })
            .catch(function () {
            _this.toggleLoading();
        });
    };
    SendDeliveryActioninit.prototype.render = function () {
        var _a = this.state, visible = _a.visible, distance = _a.distance, order = _a.order, valuations = _a.valuations, loading = _a.loading, currentTab = _a.currentTab, balance = _a.balance, platformChecked = _a.platformChecked, maxPayAmount = _a.maxPayAmount, channelChecked = _a.channelChecked, channelDispatchType = _a.channelDispatchType, saveLoading = _a.saveLoading;
        var _b = this.context, orderId = _b.id, tabStatus = _b.tabStatus, orderEvent = _b.orderEvent;
        var hideSendButton = this.props.hideSendButton;
        return (getAuth("配送处理") && !hideSendButton ?
            React.createElement(React.Fragment, null,
                React.createElement(SendDeliveryModal, { visible: visible, toggleVisible: this.toggleVisible, handleSwitchTab: this.handleSwitchTab, currentTab: currentTab, orderId: orderId, distance: distance, order: order, valuations: valuations, onChange: this.handleFieldChange, declaredAmountforDetail: this.declaredAmountforDetail, saveLoading: saveLoading, handleTipChange: this.handleTipChange, platformInit: {
                        platformChecked: platformChecked,
                        handleUpdatePlatformChecked: this.handleUpdatePlatformChecked,
                        balance: balance,
                        handleUpdateMaxPayAmount: this.handleUpdateMaxPayAmount,
                        maxPayAmount: maxPayAmount
                    }, channelInit: {
                        handleUpdateChannelChecked: this.handleUpdateChannelChecked,
                        channelChecked: channelChecked,
                        channelDispatchType: channelDispatchType
                    }, emitOrderChange: function (otherParams) {
                        if (otherParams === void 0) { otherParams = {}; }
                        emitOrderChange({
                            tabStatus: tabStatus,
                            orderEvent: orderEvent,
                            orderId: orderId,
                            nextStatus: ORDER_STATUS_CODE_MAP.IN_THE_DISTRIBUTION,
                            otherParams: otherParams
                        });
                    } }),
                React.createElement(Button, { loading: loading, onClick: this.unbindReq, className: "orderbuttonactive", type: "primary" }, window.location.search.indexOf(ORDER_SUB_STATUS_MAP['DISTRIBUTION_ABNORMAL']) !== -1 ||
                    window.location.search.indexOf('exceptionStatus=1') !== -1
                    ? '再次配送'
                    : this.props.text || '配送发单'),
                React.createElement(UnbindTip, { ref: this.unbindTipRef })) : null);
    };
    return SendDeliveryActioninit;
}(React.Component));
export { SendDeliveryActioninit };
SendDeliveryActioninit.contextType = ActionsContext;
// @ts-ignore
export var SendDeliveryAction = function (props) {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("配送处理", menu) ? React.createElement(SendDeliveryActioninit, __assign({}, props)) : null;
};
export var SendDeliveryActionAGAINinit = function (props) {
    return React.createElement(SendDeliveryActioninit, __assign({}, props, { text: '再次配送' }));
};
export var SendDeliveryActionAGAIN = function (props) {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("配送处理", menu) ? React.createElement(SendDeliveryActionAGAINinit, null) : null;
};
