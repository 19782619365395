var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import styled from 'styled-components';
import { Icon, message, Upload } from 'antd';
import produce from 'immer';
import ajax from '@utils/ajax';
import { requestForUpload } from '@utils/request';
var SInitText = styled.div.withConfig({ displayName: "SInitText", componentId: "sc-1ye9ss4" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 22px;\n  left: 50%;\n  transform: translate(-50%);\n  width: 100%;\n  font-size: 12px;\n  font-weight: 200;\n  color: #CCCCCC;\n  line-height: 17px;\n"], ["\n  position: absolute;\n  bottom: 22px;\n  left: 50%;\n  transform: translate(-50%);\n  width: 100%;\n  font-size: 12px;\n  font-weight: 200;\n  color: #CCCCCC;\n  line-height: 17px;\n"])));
var UploadButton = function (_a) {
    var loading = _a.loading;
    return (React.createElement("div", { className: "upload-button" },
        React.createElement(Icon, { type: loading ? 'loading' : 'plus' })));
};
var UploadImage = /** @class */ (function (_super) {
    __extends(UploadImage, _super);
    function UploadImage(props) {
        var _this = _super.call(this, props) || this;
        _this.handleChange = function (e) {
            var _a = e.file, response = _a.response, error = _a.error;
            if (error) {
                message.error(error);
            }
            if (response) {
                var pictureUrl = response.pictureUrl, pictureKey = response.pictureKey;
                _this.triggerChange({
                    url: pictureUrl,
                    key: pictureKey
                });
            }
        };
        _this.uploadRequest = function (_a) {
            var file = _a.file, onSuccess = _a.onSuccess, onError = _a.onError;
            return __awaiter(_this, void 0, void 0, function () {
                var formData;
                var _this = this;
                return __generator(this, function (_b) {
                    this.toggleLoading();
                    formData = new FormData();
                    formData.append('file', file);
                    requestForUpload
                        .post('/service/file/upload/uploadMerchantFile', formData)
                        .then(function (back) {
                        onSuccess({
                            pictureKey: back.data,
                            pictureUrl: back.data
                        });
                    }, function (error) {
                        onError(error);
                    })
                        .finally(function () {
                        _this.toggleLoading();
                    });
                    return [2 /*return*/];
                });
            });
        };
        _this.triggerChange = function (_a) {
            var url = _a.url, key = _a.key;
            var onChange = _this.props.onChange;
            if (onChange) {
                _this.setState(produce(function (draft) {
                    draft.url = url;
                    draft.key = key;
                }));
                onChange(key, url);
            }
        };
        _this.toggleLoading = function () {
            var state = _this.state;
            _this.setState(produce(function (draft) {
                draft.loading = !state.loading;
            }));
        };
        _this.state = {
            url: '',
            key: props.value,
            loading: false
        };
        return _this;
    }
    UploadImage.getDerivedStateFromProps = function (nextProps) {
        if ('value' in nextProps) {
            var key = nextProps.value;
            return {
                key: key
            };
        }
        return null;
    };
    UploadImage.prototype.componentDidMount = function () {
        var _this = this;
        var key = this.props.value;
        if (key === null || key === void 0 ? void 0 : key.includes('http')) {
            this.setState(produce(function (draft) {
                draft.url = key;
                draft.loading = false;
            }));
            return;
        }
        if (key && key.trim()) {
            this.toggleLoading();
            ajax.get("/oss/img?key=" + key).then(function (_a) {
                var url = _a.data;
                _this.setState(produce(function (draft) {
                    draft.url = url;
                    draft.loading = false;
                }));
            });
        }
    };
    UploadImage.prototype.render = function () {
        var state = this.state;
        var _a = this.props, width = _a.width, height = _a.height, initButtonBg = _a.initButtonBg, initText = _a.initText;
        return (React.createElement(Upload, { listType: "picture-card", showUploadList: false, onChange: this.handleChange, customRequest: this.uploadRequest, disabled: state.loading, accept: "image/jpg,image/jpeg,image/png" }, state.url && !state.loading ? (React.createElement("img", { style: {
                width: width ? width : '85px',
                height: height ? height : 'auto'
            }, src: state.url })) : initButtonBg ? (React.createElement(React.Fragment, null,
            React.createElement("img", { style: {
                    width: width ? width : '85px',
                    height: height ? height : 'auto'
                }, src: initButtonBg }),
            React.createElement(SInitText, null, initText))) : React.createElement(UploadButton, { loading: state.loading })));
    };
    return UploadImage;
}(React.Component));
export default UploadImage;
var templateObject_1;
