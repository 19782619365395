var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Form, Input, Select } from 'antd';
var FormItem = Form.Item;
var Option = Select.Option;
var TextArea = Input.TextArea;
var REFUSE_ORDER_REASONS = [
    '无法与用户联系',
    '商品已售完',
    '商家已经打烊',
    '超出配送范围',
    '不满足起送要求'
];
var RefuseOrderReason = /** @class */ (function (_super) {
    __extends(RefuseOrderReason, _super);
    function RefuseOrderReason() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            customReasonVisible: true
        };
        _this.setCustomReasonVisible = function (customReasonVisible) {
            _this.setState({ customReasonVisible: customReasonVisible });
        };
        return _this;
    }
    RefuseOrderReason.prototype.render = function () {
        var _this = this;
        var getFieldDecorator = this.props.form.getFieldDecorator;
        var customReasonVisible = this.state.customReasonVisible;
        return (React.createElement(Form, null,
            React.createElement(FormItem, { label: "\u8BF7\u9009\u62E9\u62D2\u5355\u7406\u7531" }, getFieldDecorator('reason', {
                initialValue: ''
            })(React.createElement(Select, { onChange: function (value) {
                    !value
                        ? _this.setCustomReasonVisible(true)
                        : _this.setCustomReasonVisible(false);
                } },
                REFUSE_ORDER_REASONS.map(function (reason, key) {
                    return (React.createElement(Option, { key: key, value: reason }, reason));
                }),
                React.createElement(Option, { value: "" }, "\u5176\u5B83")))),
            customReasonVisible ? (React.createElement(FormItem, { label: "\u5176\u5B83\u7406\u7531" }, getFieldDecorator('customReason', {
                rules: [
                    {
                        required: true,
                        message: '请填写拒绝理由'
                    }
                ]
            })(React.createElement(TextArea, null)))) : null));
    };
    return RefuseOrderReason;
}(React.Component));
export var RefuseOrderReasonForm = Form.create({})(RefuseOrderReason);
