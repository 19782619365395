var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
export var useToggle = function (defaultVisible) {
    if (defaultVisible === void 0) { defaultVisible = false; }
    var _a = __read(React.useState(defaultVisible), 2), visible = _a[0], setVisible = _a[1];
    var show = function () { return setVisible(true); };
    var hide = function () { return setVisible(false); };
    return [visible, show, hide];
};
