import * as qs from 'querystring';
import { addOrdersItemAction, DistributionExceptionStatus, setOrdersCounterAction, transformOrderData, userLogoutAction, deleteOrderItemAction, } from '@models';
import { store } from '@store';
import { ApiHost, getIsUseApiHost, ORDER_PLATFORM_TEXT_MAP } from '@utils/constants';
import { isElectron, isProd, isTest } from '@utils/env';
import { cancelOrderApplyNotify, deliveryOrderUnConfirmedNotify, refundOrderApplyNotify, urgeOrderNotify, DeliveryFailNotify, TIME_OUT_UN_EXPRESSIONNotify } from '@utils/notify';
import * as sounds from '@utils/sound';
import events from '@utils/events';
import { EEventNames } from '@utils/constants';
import { getPrintCasesAndPrintAll } from './container/Orders/action';
import { ORDER_STATUS_MAP, } from './container/Orders/constants';
import { bindOrderPlatformEvent, BindOrderPlatformEvents } from './container/Stores/components/BindOrderPlatform/event';
var SocketUrl;
(function (SocketUrl) {
    function withToken(token) {
        var host = ApiHost.Dev;
        var protocol = 'wss';
        if (isTest) {
            host = ApiHost.Test;
        }
        if (isProd) {
            host = ApiHost.Prod;
        }
        if (!isElectron) {
            host =
                window.location.href.indexOf('localhost') !== -1
                    ? getIsUseApiHost()
                    : location.host;
            // host = ApiHost.Test
            protocol = isProd ? 'wss' : 'ws';
        }
        var query = qs.stringify({ Authorization: token });
        return protocol + "://" + host + "/websocket?" + query + "&clientId=web";
    }
    SocketUrl.withToken = withToken;
})(SocketUrl || (SocketUrl = {}));
var SocketCloseCode;
(function (SocketCloseCode) {
    SocketCloseCode[SocketCloseCode["Timeout"] = 4001] = "Timeout";
})(SocketCloseCode || (SocketCloseCode = {}));
var addOrdersItem = function (payload) {
    return store.dispatch(addOrdersItemAction(payload));
};
var refOrdersItem = function (payload) {
    // 修复订单状态更新刷新地图选单
    var pageSize = payload.pageSize;
    if (!pageSize || pageSize !== 10000) {
        store.dispatch.orders.fetchData(payload);
    }
};
var deleteOrderItem = function (orderId) {
    return store.dispatch(deleteOrderItemAction({ orderId: orderId }));
};
var updateOrdersCounter = function (payload) {
    return store.dispatch(setOrdersCounterAction(payload));
};
var pushCount = function () { return store.dispatch.orders.pushCountReq(); };
var genCanAddOrderItem = function (search) {
    return function (type) { return search === ORDER_STATUS_MAP.ALL || search === type; };
};
var genPlaySound = function (currentSound) {
    return function (nextSound) {
        if (currentSound) {
            nextSound.stop(currentSound);
        }
        currentSound = nextSound.play();
    };
};
var attemptReconnectTimer = null;
var attemptReconnectTime = 0;
export var socketConnect = function (_a) {
    var token = _a.token;
    // const socket = 'current close'
    var socket = new WebSocket(SocketUrl.withToken(token));
    var currentSoundPlay = 0;
    var heartCheck = {
        timeout: 30 * 1000,
        timeoutObj: 0,
        serverTimeoutObj: 0,
        reset: function () {
            clearTimeout(this.timeoutObj);
            clearTimeout(this.serverTimeoutObj);
            return this;
        },
        start: function () {
            var self = this;
            this.timeoutObj = setTimeout(function () {
                socket.send('HeartBeat');
                self.serverTimeoutObj = setTimeout(function () {
                    socket.close(SocketCloseCode.Timeout);
                }, self.timeout);
            }, this.timeout);
        }
    };
    socket.addEventListener('open', function (event) {
        heartCheck.reset().start();
        // 通知后端开发推送订单计数
        pushCount();
    });
    socket.addEventListener('close', function (event) {
        var clearAfterClose = function () {
            heartCheck.reset();
            store.dispatch(userLogoutAction());
        };
        switch (event.code) {
            case 1006: // CLOSE_ABNORMAL
            case SocketCloseCode.Timeout:
                if (localStorage.getItem('token')) {
                    attemptReconnectTimer = setTimeout(function () {
                        if (!localStorage.getItem('token'))
                            return clearTimeout(Number(attemptReconnectTimer));
                        if (socket.readyState === 3 && attemptReconnectTime < 60) {
                            socketConnect({ token: token });
                            clearTimeout(Number(attemptReconnectTimer));
                            attemptReconnectTime++;
                        }
                        else {
                            clearAfterClose();
                        }
                    }, 1000 * 10);
                }
                break;
            default:
                clearAfterClose();
                break;
        }
    });
    socket.addEventListener('error', function () {
        console.error('socket connect has error.');
    });
    socket.addEventListener('message', function (event) {
        // store.order.data
        var socketQueryParams = store.getState().orders.data.socketQueryParams;
        heartCheck.reset().start();
        var resp = null;
        try {
            resp = JSON.parse(event.data)['data'];
        }
        catch (error) {
            resp = null;
        }
        if (!resp)
            return false;
        // const { type, data } = resp
        var status = qs.parse(location.search.substr(1)).status;
        var canAddOrderItem = genCanAddOrderItem(status);
        var playSound = genPlaySound(currentSoundPlay);
        switch (resp.type) {
            case EEventNames.WaitConfirm:
                // playSound(sounds.newOrderSound)
                sounds.newOrderSound.play();
                // newOrderNotify({})
                // if (canAddOrderItem(ORDER_STATUS_MAP.TO_BE_CONFIRMED)) {
                //   // refOrdersItem(data)
                //   deleteOrderItem(JSON.parse(event.data)['data']['data'].id)
                //   addOrdersItem(JSON.parse(event.data)['data']['data'])
                // } else {
                //   deleteOrderItem(JSON.parse(event.data)['data']['data'].id)
                // }
                refOrdersItem(socketQueryParams);
                break;
            case EEventNames.WaitDistri:
                // playSound(sounds.newOrderWithAutoSound)
                sounds.newOrderWithAutoSound.play();
                // newOrderWithAutoNotify({})
                var printData = transformOrderData(JSON.parse(event.data)['data']['data']);
                getPrintCasesAndPrintAll(printData);
                // if (JSON.parse(event.data)['data']['data'].isAutoOrder) {
                // }
                // if (canAddOrderItem(ORDER_STATUS_MAP.TO_BE_SHIPPING)) {
                //   deleteOrderItem(JSON.parse(event.data)['data']['data'].id)
                //   addOrdersItem(JSON.parse(event.data)['data']['data'])
                // } else {
                //   deleteOrderItem(JSON.parse(event.data)['data']['data'].id)
                // }
                refOrdersItem(socketQueryParams);
                break;
            case EEventNames.Distriing:
            case EEventNames.Completed:
            case EEventNames.Canceled:
            case EEventNames.Waitpickup:
                refOrdersItem(socketQueryParams);
                break;
            case EEventNames.DistriException:
                var platformText = ORDER_PLATFORM_TEXT_MAP[JSON.parse(event.data)['data']['data'].platform];
                switch (JSON.parse(event.data)['data']['data'].exceptionStatus) {
                    case DistributionExceptionStatus.UnConfirmed:
                        var notifyBody = platformText + " " + JSON.parse(event.data)['data']['data'].platformDaySn + " \u53F7\u8BA2\u5355\u5341\u5206\u949F\u65E0\u9A91\u624B\u63A5\u5355\uFF08\u6613\u9001 " + JSON.parse(event.data)['data']['data'].daySn + " \u53F7\uFF09\uFF0C\u8BF7\u68C0\u67E5\u3002";
                        deliveryOrderUnConfirmedNotify({
                            body: notifyBody
                        });
                        playSound(sounds.UN_GRAB_TENsound);
                        break;
                    default:
                        break;
                }
                // if (canAddOrderItem(ORDER_SUB_STATUS_MAP.DISTRIBUTION_ABNORMAL)) {
                //   deleteOrderItem(JSON.parse(event.data)['data']['data'].id)
                //   addOrdersItem(JSON.parse(event.data)['data']['data'])
                // } else {
                //   deleteOrderItem(JSON.parse(event.data)['data']['data'].id)
                // }
                refOrdersItem(socketQueryParams);
                break;
            //申请退款
            case EEventNames.RefundApply:
                var message = JSON.parse(event.data)['data'].message;
                refundOrderApplyNotify({ message: message });
                // playSound(sounds.refundOrderApplySound)
                sounds.refundOrderApplySound.play();
                // if (canAddOrderItem(ORDER_SUB_STATUS_MAP.REFUND_APPLY)) {
                //   addOrdersItem(JSON.parse(event.data)['data']['data'])
                // } else {
                //   deleteOrderItem(JSON.parse(event.data)['data']['data'])
                // }
                refOrdersItem(socketQueryParams);
                break;
            case EEventNames.CancelApply:
                cancelOrderApplyNotify({
                    message: JSON.parse(event.data)['data'].message
                });
                // playSound(sounds.cancelOrderApplySound)
                sounds.cancelOrderApplySound.play();
                // if (canAddOrderItem(ORDER_SUB_STATUS_MAP.CANCELED_APPLY)) {
                //   deleteOrderItem(JSON.parse(event.data)['data']['data'].id)
                //   addOrdersItem(JSON.parse(event.data)['data']['data'])
                // } else {
                //   deleteOrderItem(JSON.parse(event.data)['data']['data'].id)
                // }
                refOrdersItem(socketQueryParams);
                break;
            case EEventNames.UrgeOrder:
                urgeOrderNotify({ message: JSON.parse(event.data)['data'].message });
                // playSound(sounds.urgeOrderSound)
                sounds.urgeOrderSound.play();
                // if (canAddOrderItem(ORDER_SUB_STATUS_MAP.URGE_APPLY)) {
                //   deleteOrderItem(JSON.parse(event.data)['data']['data'].id)
                //   addOrdersItem(JSON.parse(event.data)['data']['data'])
                // } else {
                //   deleteOrderItem(JSON.parse(event.data)['data']['data'].id)
                // }
                refOrdersItem(socketQueryParams);
                break;
            case EEventNames.Count:
                // events.emit('MEITUAN_CANCEL_BIND', {})
                updateOrdersCounter(JSON.parse(event.data)['data']['data']);
                break;
            case EEventNames.MeituanBindingCheck:
                bindOrderPlatformEvent.emit(BindOrderPlatformEvents.Success);
                break;
            case EEventNames.DeliveryError:
                DeliveryFailNotify({ message: JSON.parse(event.data)['data'].message });
                // playSound(sounds.deliveryErrorSound)
                sounds.deliveryErrorSound.play();
                // if (canAddOrderItem('DELIVER_FAILED')) {
                //   deleteOrderItem(JSON.parse(event.data)['data']['data'].id)
                // } else if (canAddOrderItem('TO_BE_SHIPPING')) {
                //   addOrdersItem(JSON.parse(event.data)['data']['data'])
                // }
                refOrdersItem(socketQueryParams);
                break;
            case EEventNames.DELIVER_PERSON_RECEIVE_ORDER:
                // playSound(sounds.DELIVER_PERSON_RECEIVE_ORDER)
                sounds.DELIVER_PERSON_RECEIVE_ORDER.play();
                // DeliveryReceviedNotify({
                //   platform: JSON.parse(event.data)['data']['data'].platformName,
                //   orderNo: JSON.parse(event.data)['data']['data'].platformDaySn
                // })
                // if (canAddOrderItem(ORDER_STATUS_MAP.WAIT_GRAB)) {
                //   deleteOrderItem(JSON.parse(event.data)['data']['data'].id)
                // } else if (canAddOrderItem('TO_BE_SHIPPING')) {
                //   addOrdersItem(JSON.parse(event.data)['data']['data'])
                // }
                refOrdersItem(socketQueryParams);
                break;
            case EEventNames.Arrived:
                // DeliveryArrivedNotify({
                //   storeName: JSON.parse(event.data)['data']['data'].storeName
                // })
                // playSound(sounds.arrivedSound)
                break;
            case EEventNames.DELIVER_PERSON_PICKUP:
                refOrdersItem(socketQueryParams);
                // DeliveryArrivedNotify({
                //   storeName: JSON.parse(event.data)['data']['data'].storeName
                // })
                // playSound(sounds.arrivedSound)
                break;
            case 'REFRESH':
                // DeliveryArrivedNotify({
                //   storeName: JSON.parse(event.data)['data']['data'].storeName
                // })
                // playSound(sounds.arrivedSound)
                refOrdersItem(socketQueryParams);
                break;
            case EEventNames.FengNiaoToken:
                events.emit('fengToken', JSON.parse(event.data)['data']['data']);
                break;
            case EEventNames.FengNiaoBindSuccess:
                events.emit('bindFengniaoSuccess', {});
                break;
            case EEventNames.TIME_OUT_UN_EXPRESSION:
                // playSound(sounds.TIME_OUTsound)
                sounds.TIME_OUTsound.play();
                TIME_OUT_UN_EXPRESSIONNotify({
                    message: JSON.parse(event.data)['data'].message
                });
                refOrdersItem(socketQueryParams);
                // if (canAddOrderItem(ORDER_STATUS_MAP.TIME_OUT_UN_EXPRESSION)) {
                //   refOrdersItem({ orderStatus: 1 })
                // }
                break;
            case EEventNames.UN_GRAB_TEN:
                // playSound(sounds.UN_GRAB_TENsound)
                sounds.UN_GRAB_TENsound.play();
                TIME_OUT_UN_EXPRESSIONNotify({
                    message: JSON.parse(event.data)['data'].message
                });
                break;
            case EEventNames.UN_GRAB_THIRTY:
                // playSound(sounds.UN_GRAB_THIRTYsound)
                sounds.UN_GRAB_THIRTYsound.play();
                TIME_OUT_UN_EXPRESSIONNotify({
                    message: JSON.parse(event.data)['data'].message
                });
                // if (canAddOrderItem('WAIT_GRAB')) {
                //   deleteOrderItem(JSON.parse(event.data)['data']['data'].id)
                // } else if (canAddOrderItem('TO_BE_SHIPPING')) {
                //   addOrdersItem(JSON.parse(event.data)['data']['data'])
                // }
                refOrdersItem(socketQueryParams);
                break;
            case EEventNames.ChannelDeliveryDetailUpdate:
                events.emit(EEventNames.ChannelDeliveryDetailUpdate, JSON.parse(event.data)['data']['data']);
            case EEventNames.MEITUAN_CANCEL_BIND:
                console.log("MEITUAN_CANCEL_BIND");
                events.emit('MEITUAN_CANCEL_BIND', {});
                break;
            default:
                break;
        }
    });
    return socket;
};
