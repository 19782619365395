import * as React from 'react';
import { DistributionExceptionStatus } from '@models';
import { LargeTag, OrderTag, TimeOutTag } from './styled';
var OrderTags = function (order) {
    var tags = [];
    switch (order.exceptionStatus) {
        case DistributionExceptionStatus.Failed:
            tags.push(React.createElement(TimeOutTag, null, "\u914D\u9001\u5931\u8D25"));
            break;
        case DistributionExceptionStatus.WaitSendTimeout:
            tags.push(React.createElement(TimeOutTag, null, "\u8D85\u65F6\u672A\u914D\u9001"));
            break;
        case DistributionExceptionStatus.DeliveryTimeout:
            tags.push(React.createElement(TimeOutTag, null, "\u8D85\u65F6\u672A\u9001\u8FBE"));
            break;
        case DistributionExceptionStatus.UnConfirmed:
            tags.push(React.createElement(TimeOutTag, null, "\u914D\u9001\u672A\u63A5\u5355\u9884\u8B66"));
            break;
        default:
            break;
    }
    if (order.isBigAmount) {
        tags.push(React.createElement(LargeTag, null, "\u5927\u989D\u8BA2\u5355"));
    }
    if (!order.reservationOrder) {
        tags.push(React.createElement(OrderTag, null, "\u9884\u7EA6\u8BA2\u5355"));
    }
    if (order.selfSend && order.selfSend.code === 0) {
        tags.push(React.createElement(OrderTag, null, "\u81EA\u914D\u9001"));
    }
    return tags;
};
export default OrderTags;
