var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, message, Popconfirm } from 'antd';
import produce from 'immer';
import { get, isEqual } from 'lodash';
import { requestForOrder } from '@utils/request';
import { sendPv } from '@utils/umeng';
import EditDeliveryStrategyModal from './EditDeliveryStrategyModal';
import EditOrderStrategyModal from './EditOrderStrategyModal';
import EditStoreModal from './EditStoreModal';
import { StoresTable } from './styled';
import { getAuth } from '@/container/AccountManagement/component';
var DELIVERY_STRATEGY_MAPS = {
    1: '手动派单',
    2: '自动派单'
};
var GoTo = function (_a) {
    var children = _a.children, path = _a.path;
    var history = useHistory();
    var handleClick = function () {
        history.push(path);
    };
    return (React.createElement(Button, { size: "small", onClick: handleClick }, children));
};
var StoresManagement = /** @class */ (function (_super) {
    __extends(StoresManagement, _super);
    function StoresManagement() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            editStore: {
                visible: false,
                isEditMode: false,
                initialValue: {}
            },
            editDeliveryStrategy: {
                visible: false,
                initialValue: {
                    distriMethod: { type: 1, content: { distriPlatforms: [] } }
                }
            },
            editOrderStrategy: {
                visible: false,
                initialValue: {}
            }
        };
        _this.columns = [
            {
                title: '门店名称',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '门店编号',
                dataIndex: 'numbering',
                key: 'numbering'
            },
            {
                title: '所在城市',
                dataIndex: 'city',
                key: 'city'
            },
            {
                title: '门店地址',
                dataIndex: 'address',
                key: 'address'
            },
            {
                title: '接单平台',
                dataIndex: 'orderPlatform',
                key: 'orderPlatform',
                render: function (text) {
                    return React.createElement(Link, { to: '/stores/order_platform' }, text);
                }
            },
            {
                title: '配送平台',
                dataIndex: 'distributionPlatform',
                key: 'distributionPlatform',
                render: function (text) {
                    return React.createElement(Link, { to: '/stores/delivery_platform' }, text);
                }
            },
            {
                title: '打印机',
                dataIndex: 'printerNumber',
                key: 'printerNumber',
                render: function (text) {
                    return React.createElement(Link, { to: '/stores/print/printers' }, text);
                }
            },
            {
                title: '操作',
                key: 'action',
                render: function (text, record) {
                    return (React.createElement(React.Fragment, null,
                        getAuth("门店管理") &&
                            React.createElement(Button, { size: "small", onClick: function () { return _this.toggleEditStore(record); } }, "\u7F16\u8F91"),
                        "\u00A0",
                        getAuth("策略设置") &&
                            React.createElement(Button, { size: "small", onClick: function () { return _this.toggleEditOrderStrategy(record); } }, "\u8BA2\u5355\u7B56\u7565"),
                        "\u00A0",
                        getAuth("策略设置") &&
                            React.createElement(Button, { size: "small", onClick: function () { return _this.toggleEditDeliveryStrategy(record); } }, "\u914D\u9001\u7B56\u7565"),
                        "\u00A0",
                        getAuth("打印设置") &&
                            React.createElement(GoTo, { path: "/stores/print/cases" }, "\u6253\u5370\u65B9\u6848"),
                        "\u00A0",
                        getAuth("门店管理") &&
                            React.createElement(Popconfirm, { title: "\u786E\u8BA4\u5220\u9664\uFF1F", cancelText: "\u53D6\u6D88", okText: "\u786E\u5B9A", onConfirm: function () { return _this.handleStoreDelete(record.id); } },
                                React.createElement(Button, { size: "small" }, "\u5220\u9664"))));
                }
            }
        ];
        _this.toggleEditOrderStrategy = function (initialValue) {
            if (initialValue === void 0) { initialValue = {}; }
            var editOrderStrategy = _this.state.editOrderStrategy;
            _this.setState(produce(function (draft) {
                draft.editOrderStrategy = {
                    initialValue: initialValue,
                    visible: !editOrderStrategy.visible
                };
            }));
        };
        _this.toggleEditStore = function (initialValue) {
            if (initialValue === void 0) { initialValue = {}; }
            var editStore = _this.state.editStore;
            var getItemDetail = _this.props.getItemDetail;
            if (!editStore.visible && initialValue.id) {
                getItemDetail({
                    storeId: initialValue.id
                }).then(function (data) {
                    if (data) {
                        _this.setState(produce(function (draft) {
                            draft.editStore = {
                                initialValue: data,
                                isEditMode: !!get(initialValue, 'id'),
                                visible: !editStore.visible
                            };
                        }));
                    }
                    else {
                        message.error('店铺详情获取失败');
                    }
                });
            }
            else {
                _this.setState(produce(function (draft) {
                    draft.editStore = {
                        initialValue: initialValue,
                        isEditMode: !!get(initialValue, 'id'),
                        visible: !editStore.visible
                    };
                }));
            }
        };
        _this.toggleEditDeliveryStrategy = function (initialValue) {
            if (initialValue === void 0) { initialValue = {}; }
            var editDeliveryStrategy = _this.state.editDeliveryStrategy;
            _this.setState(produce(function (draft) {
                draft.editDeliveryStrategy = {
                    initialValue: __assign(__assign({ distriMethod: { type: 1, content: { distriPlatforms: [] } } }, initialValue), { id: initialValue.id }),
                    visible: !editDeliveryStrategy.visible
                };
            }));
        };
        _this.handleTableChange = function (pagination) {
            _this.props.getStores({
                page: pagination.current,
                pageSize: pagination.pageSize
            });
        };
        _this.handleStoreDelete = function (id) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.props.delStoresItem(id)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        _this.fetchInitValue = function (storeId) {
            requestForOrder.post('');
            // ajax.post('/store/getDistrManeuver', { storeId })
        };
        return _this;
    }
    StoresManagement.prototype.componentDidMount = function () {
        this.props.getStores({});
        sendPv();
    };
    StoresManagement.prototype.renderDeliveryStrategyColText = function (item) {
        var _a = item.distriMethod, type = _a.type, content = _a.content;
        switch (type) {
            case 1:
                var distriPlatformNames = content.distriPlatforms.map(function (item) { return item.name; });
                return __spread([DELIVERY_STRATEGY_MAPS[type]], distriPlatformNames).join('，');
            case 2:
                return [DELIVERY_STRATEGY_MAPS[type], content.distriPlatform.name].join('，');
            default:
                return '';
        }
    };
    StoresManagement.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    };
    StoresManagement.prototype.render = function () {
        var _this = this;
        var _a = this.state, editStore = _a.editStore, editDeliveryStrategy = _a.editDeliveryStrategy, editOrderStrategy = _a.editOrderStrategy;
        var props = this.props;
        return (React.createElement(React.Fragment, null,
            getAuth("门店管理") &&
                React.createElement(Button, { type: "primary", onClick: function () { return _this.toggleEditStore(); } }, "\u6DFB\u52A0\u95E8\u5E97"),
            React.createElement(EditStoreModal, { isEditMode: editStore.isEditMode, visible: editStore.visible, toggleVisible: this.toggleEditStore, initialValue: editStore.initialValue }),
            editDeliveryStrategy.visible && (React.createElement(EditDeliveryStrategyModal, { visible: editDeliveryStrategy.visible, initialValue: editDeliveryStrategy.initialValue, toggleVisible: this.toggleEditDeliveryStrategy })),
            React.createElement(EditOrderStrategyModal, { visible: editOrderStrategy.visible, initialValue: editOrderStrategy.initialValue, toggleVisible: this.toggleEditOrderStrategy }),
            React.createElement(StoresTable, { columns: this.columns, dataSource: props.dataSource, pagination: props.pagination, loading: props.listLoading, onChange: this.handleTableChange, rowKey: "id", bordered: true })));
    };
    return StoresManagement;
}(React.Component));
var mapState = function (state) {
    var _a = state.stores, data = _a.data, loading = _a.loading;
    return {
        dataSource: data.list,
        pagination: {
            current: data.page,
            pageSize: data.pageSize,
            total: data.total
        },
        listLoading: loading
    };
};
var mapDispatch = function (dispatch) { return ({
    getStores: dispatch.stores.fetchData,
    delStoresItem: dispatch.stores.deleteItemWithReq,
    getItemDetail: dispatch.stores.getItemDetailWithReq
}); };
export default connect(mapState, mapDispatch)(StoresManagement);
