var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { message } from 'antd';
import { get } from 'lodash';
import ajax from '@utils/ajax';
import { requestForLocation } from '@utils/request';
import { CloudPrinterPlatform } from '@utils/constants';
import { printerDisconnectNotify } from '@utils/notify';
import { printerDisconnectSound } from '@utils/sound';
import { cloudPrintLockReq, getCloudPrinterWorker } from './cloud';
import { PrinterTypes } from './constants';
import { getLocalPrinterWorker } from './local';
import { printQueue } from './queue';
var escpos = window.escpos;
var defaultPrintCallback = function (err, data) {
    if (err) {
        message.error(err);
    }
    else {
        ajax.post('/order/inc/print/num', {
            orderId: data.id,
            num: 1
        });
        message.success(data.daySn + "\u53F7\u8BA2\u5355\u6253\u5370\u5B8C\u6210");
    }
};
var printQueueAppendWorker = function (worker, copies) {
    for (var index = 0; index < copies; index++) {
        printQueue.push(worker);
    }
};
var printQueueAppendCloudWorker = function (_a) {
    var uuid = _a.uuid, worker = _a.worker, copies = _a.copies;
    for (var index = 0; index < copies; index++) {
        if (uuid) {
            cloudPrintLockReq({ uuid: uuid, copies: copies }).then(function (_a) {
                var data = _a.data;
                if (data.canPrint) {
                    printQueue.push(worker);
                }
            });
        }
        else {
            printQueue.push(worker);
        }
    }
};
// export const printReceipt: (args: PrintReceiptArgs) => void = ({
//   printerConfig,
//   printCase = '',
//   data,
//   copies = 1,
//   callback = defaultPrintCallback
// }) => {
//   const { type: printerType, paperFormat } = printerConfig
//   switch (printerType) {
//     case PrinterTypes.LocalNetwork:
//       const { ip } = printerConfig
//       try {
//         const device = new escpos.Network(ip)
//         const printer = new escpos.Printer(device)
//         const worker = getLocalPrinterWorker({
//           device,
//           printer,
//           paperFormat,
//           printCase,
//           data,
//           callback
//         })
//         printQueueAppendWorker(worker, copies)
//       } catch (error) {
//         printerDisconnectNotify({})
//         printerDisconnectSound.play()
//         message.error('打印机连接错误')
//         return callback && callback('打印机连接错误')
//       }
//       break
//     case PrinterTypes.LocalUSB:
//       const { usb } = printerConfig
//       const [vid, pid] = usb.split(',')
//       try {
//         const device = new escpos.USB(vid, pid)
//         const printer = new escpos.Printer(device)
//         const worker = getLocalPrinterWorker({
//           device,
//           printer,
//           paperFormat,
//           printCase,
//           data,
//           callback
//         })
//         printQueueAppendWorker(worker, copies)
//       } catch (error) {
//         printerDisconnectNotify({})
//         printerDisconnectSound.play()
//         message.error('打印机连接错误')
//         return callback && callback('打印机连接错误')
//       }
//       break
//     case PrinterTypes.CloudPrinter:
//       const { cloudPrinter } = printerConfig
//       const { type: platform, ...authData } = cloudPrinter
//       const worker = getCloudPrinterWorker({
//         authData,
//         platform,
//         paperFormat,
//         printCase,
//         data,
//         callback
//       })
//       const uuidList = get(data, 'uuids', [])
//       const printLockUUID = get(uuidList, `[${printCase - 1}]`, '')
//       printQueueAppendCloudWorker({
//         uuid: printLockUUID,
//         worker,
//         copies
//       })
//       break
//     default:
//       break
//   }
// }
export var printReceiptBy = function (orderId, printCaseId) {
    return ajax
        .post('/order/print', {
        orderId: orderId,
        receiptStencilId: printCaseId
    })
        .then(function () { return message.success('打印成功'); });
};
export var printReceipt = function (_a) {
    var printerConfig = _a.printerConfig, _b = _a.printCase, printCase = _b === void 0 ? '' : _b, data = _a.data, _c = _a.copies, copies = _c === void 0 ? 1 : _c, from = _a.from, _d = _a.callback, callback = _d === void 0 ? defaultPrintCallback : _d;
    var printerType = printerConfig.type, paperFormat = printerConfig.paperFormat;
    switch (printerType) {
        case PrinterTypes.LocalNetwork:
            var ip = printerConfig.ip;
            try {
                var device = new escpos.Network(ip);
                var printer = new escpos.Printer(device);
                var worker = getLocalPrinterWorker({
                    device: device,
                    printer: printer,
                    paperFormat: paperFormat,
                    printCase: printCase,
                    printerType: printerType,
                    data: data,
                    callback: callback
                });
                printQueueAppendWorker(worker, copies);
            }
            catch (error) {
                printerDisconnectNotify({});
                printerDisconnectSound.play();
                message.error('打印机连接错误');
                return callback && callback('打印机连接错误');
            }
            break;
        case PrinterTypes.LocalUSB:
        case PrinterTypes.LocalUSBA4:
            // const { usb } = printerConfig
            // const [vid, pid] = usb.split(',')
            try {
                // const device = new escpos.USB(vid, pid)
                // const printer = new escpos.Printer(device)
                var worker = getLocalPrinterWorker({
                    printerConfig: printerConfig,
                    paperFormat: paperFormat,
                    printCase: printCase,
                    printerType: printerType,
                    data: data,
                    callback: callback
                });
                printQueueAppendWorker(worker, copies);
            }
            catch (error) {
                printerDisconnectNotify({});
                printerDisconnectSound.play();
                message.error('打印机连接错误');
                return callback && callback('打印机连接错误');
            }
            break;
        case PrinterTypes.CloudPrinter:
            var id = data.id;
            if (from && from === 'replenishPrint') {
                printReceiptBy(id, printCase);
            }
            // 打印测试页
            if (from && from === 'printTest') {
                var cloudPrinter = printerConfig.cloudPrinter;
                var platform = cloudPrinter.type, authData = __rest(cloudPrinter, ["type"]);
                if (platform === CloudPrinterPlatform.XinYeYun) {
                    requestForLocation
                        .get("/api/printer/xinyeyunPrintCase?printId=" + printerConfig.id)
                        .then(function (back) {
                        if (back && back.code === 0) {
                            message.success('打印成功');
                        }
                        else {
                            message.error(back.msg);
                        }
                    });
                }
                else {
                    var worker = getCloudPrinterWorker({
                        authData: authData,
                        platform: platform,
                        paperFormat: paperFormat,
                        printCase: 1,
                        data: data,
                        callback: callback
                    });
                    var uuidList = get(data, 'uuids', []);
                    var printLockUUID = get(uuidList, "[" + (1 - 1) + "]", '');
                    printQueueAppendCloudWorker({
                        uuid: printLockUUID,
                        worker: worker,
                        copies: copies
                    });
                }
            }
            break;
        default:
            break;
    }
};
