/*
 * @Author: 孙家奇
 * @Date: 2021-06-29 17:34:03
 * @Last Modified by: 孙家奇
 * @Last Modified time: 2022-03-02 15:27:10
 * 传参
 *       手动派单            自动派单
 * 自有运力   []             自有运力数组
 * 易送    易送的运力数组      易送的数组
 * &       易送的运力数组
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spin, message, Modal } from 'antd';
import lodash from 'lodash';
import FormModal from '@components/FormModal';
import { useToggle } from '@hooks';
import { requestForDelivery } from '@utils/request';
import EditDeliveryStrategyForm from './EditDeliveryStrategyForm';
import { EAutoDispatchOpen, EBizType, EBindTag, EStrategyType } from '../../constance';
import { EditStrategyTips, EditStrategyTipsText, EditStrategyTipsTitle } from './styled';
var mapDispatch = function (dispatch) { return ({
    getStores: dispatch.stores.fetchData
}); };
var EditDeliveryStrategyModal = function (props) {
    var dispatch = useDispatch();
    var getStores = mapDispatch(dispatch).getStores;
    var _a = __read(useToggle(false), 3), confirmLoading = _a[0], startConfirmLoading = _a[1], stopConfirmLoading = _a[2];
    var _b = __read(useState({ requesiState: false }), 2), strategyData = _b[0], setstrategyData = _b[1];
    var _c = __read(useState([]), 2), channelStrategyData = _c[0], setchannelStrategyData = _c[1];
    var _d = __read(useState([]), 2), platformStrategyData = _d[0], setplatformStrategyData = _d[1];
    var formRef = React.useRef();
    var fetchDispatchStrategy = function (storeId, bizType) {
        requestForDelivery
            .post('/platform/strategy/queryDispatchStrategy', {
            storeId: storeId,
            bizType: bizType
        })
            .then(function (res) {
            if (bizType === EBizType.CHANNEL) {
                setchannelStrategyData(lodash.get(res, 'data.dispatchPlatform'));
            }
            else if (bizType === EBizType.PLATFORM) {
                setplatformStrategyData(lodash.get(res, 'data.dispatchPlatform'));
            }
            else {
                setstrategyData(__assign(__assign({}, lodash.get(res, 'data')), { requesiState: true }));
            }
        })
            .catch(function () {
            setstrategyData({
                requesiState: true
            });
        });
    };
    var updateExpressStrategy = function (data) {
        requestForDelivery
            .post('/platform/strategy/updateExpressStrategy', data)
            .then(function (back) {
            if (back.code === 0) {
                getStores({});
                props.toggleVisible();
            }
            else {
                message.error(back.err);
            }
            stopConfirmLoading();
        })
            .catch(function (err) {
            console.log(err);
        })
            .finally(function () {
            stopConfirmLoading();
        });
    };
    var handleSubmit = function () {
        formRef.current.props.form.validateFields(function (errors, values) {
            if (errors)
                return false;
            if (values.autoDispatchOpen === EAutoDispatchOpen.OPEN) {
                var deliveryRef = formRef.current.deliveryRef;
                var _a = deliveryRef.current, bizType = _a.bizType, channelData = _a.channelData, platformData = _a.platformData;
                var dispatchPlatform_1 = [];
                if (!bizType) {
                    return message.error('请选择扣款账号类型');
                }
                if (bizType) {
                    if (bizType === EBizType.CHANNEL) {
                        channelData.map(function (item) {
                            if (item.bindTag === EBindTag.BIND) {
                                dispatchPlatform_1.push(item.expressPlatformId);
                            }
                        });
                    }
                    else if (bizType === EBizType.PLATFORM) {
                        platformData.map(function (item) {
                            if (item.bindTag === EBindTag.BIND) {
                                dispatchPlatform_1.push(item.expressPlatformId);
                            }
                        });
                    }
                    if (!dispatchPlatform_1.length) {
                        return message.error('请选择运力');
                    }
                }
                var data_1 = {
                    storeId: lodash.get(props, 'initialValue.id'),
                    dispatchPlatform: dispatchPlatform_1,
                    bizType: bizType,
                    strategyType: values.strategyType
                        ? EStrategyType.SPEED
                        : EStrategyType.CHEAP,
                    autoType: 1,
                    appointOrderDispatchTime: values.appointOrderDispatchTime,
                    immidateOrderDispatchTime: values.immidateOrderDispatchTime,
                    closeAutoDispatch: values.autoDispatchOpen
                };
                Modal.confirm({
                    icon: null,
                    title: '温馨提示',
                    content: (React.createElement(React.Fragment, null,
                        React.createElement("div", null, "\u5728\u5F00\u542F\u81EA\u52A8\u53D1\u5355\u914D\u7F6E\u540E\u7684\u65B0\u8BA2\u5355\u624D\u4F1A\u81EA\u52A8\u547C\u53EB\u914D\u9001\uFF0C\u8BBE\u7F6E\u6253\u5F00\u4E4B\u524D\u7684\u8BA2\u5355\uFF0C\u4ECD\u9700\u624B\u52A8\u53D1\u8D77\u914D\u9001\u3002"))),
                    centered: true,
                    onOk: function () {
                        startConfirmLoading();
                        updateExpressStrategy(data_1);
                    }
                });
            }
            else {
                var data = {
                    storeId: lodash.get(props, 'initialValue.id'),
                    autoType: 1,
                    appointOrderDispatchTime: values.appointOrderDispatchTime,
                    immidateOrderDispatchTime: values.immidateOrderDispatchTime,
                    closeAutoDispatch: values.autoDispatchOpen
                };
                startConfirmLoading();
                updateExpressStrategy(data);
            }
        });
    };
    useEffect(function () {
        var id = lodash.get(props, 'initialValue.id');
        if (id) {
            fetchDispatchStrategy(id);
            fetchDispatchStrategy(id, EBizType.PLATFORM);
            fetchDispatchStrategy(id, EBizType.CHANNEL);
        }
    }, [props]);
    return (React.createElement(FormModal, { title: "\u7F16\u8F91\u914D\u9001\u7B56\u7565", visible: props.visible, confirmLoading: confirmLoading, onCancel: function () { return props.toggleVisible(); }, onOk: handleSubmit, destroyOnClose: true },
        strategyData.requesiState ? (React.createElement(EditDeliveryStrategyForm, { wrappedComponentRef: formRef, strategyData: strategyData, channelStrategyData: channelStrategyData, platformStrategyData: platformStrategyData })) : (React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
            React.createElement(Spin, null))),
        React.createElement(EditStrategyTips, null,
            React.createElement(EditStrategyTipsTitle, null, "\u8BF4\u660E"),
            React.createElement(EditStrategyTipsText, null, "1\u3001\u6BCF\u4E2A\u95E8\u5E97\u53EF\u4EE5\u6709\u81EA\u5DF1\u7684\u914D\u9001\u65B9\u5F0F\uFF1B"),
            React.createElement(EditStrategyTipsText, null, "2\u3001\u624B\u5DE5\u6D3E\u5355\u4E0B\uFF0C\u6307\u5B9A\u591A\u4E2A\u914D\u9001\u5E73\u53F0\uFF0C\u4F46\u9700\u8981\u6BCF\u4E2A\u8BA2\u5355\u64CD\u4F5C\u4E0B\u3010\u914D\u9001\u3011;"),
            React.createElement(EditStrategyTipsText, null, "3\u3001\u81EA\u52A8\u6D3E\u5355\u4E0B\uFF0C\u6307\u5B9A\u5355\u4E2A\u914D\u9001\u5E73\u53F0\uFF0C\u5916\u5356\u8BA2\u5355\u3010\u63A5\u5355\u3011\u540E\uFF0C\u81EA\u52A8\u547C\u53EB\u9A91\u624B\u3002"))));
};
export default EditDeliveryStrategyModal;
