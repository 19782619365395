var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { Input, Form, Button } from 'antd';
import { Formik } from 'formik';
import FormModal from '@components/FormModal';
var FormItem = Form.Item;
var TextArea = Input.TextArea;
var formItemLayout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 }
};
var formInitValues = {
    smartText: '',
};
var SmartAddressModal = function (_a) {
    var onSubmit = _a.onSubmit, visible = _a.visible, toggleVisible = _a.toggleVisible;
    var _b = __read(useState(null), 2), form = _b[0], setform = _b[1];
    return (React.createElement(FormModal, { title: "\u667A\u80FD\u5730\u5740\u586B\u5199", visible: visible, onCancel: function () { return toggleVisible(false); }, footer: null, destroyOnClose: true },
        React.createElement(Formik, { initialValues: formInitValues, onSubmit: function (values) {
                onSubmit(values);
            } }, function (_a) {
            var setFieldValue = _a.setFieldValue, submitForm = _a.submitForm;
            return (React.createElement(Form, __assign({}, formItemLayout, { style: { textAlign: 'center' } }),
                React.createElement(FormItem, null,
                    React.createElement(TextArea, { onChange: function (_a) {
                            var value = _a.target.value;
                            return setFieldValue('smartText', value);
                        }, rows: 10, placeholder: "\u5C06\u590D\u5236\u7684\u6536\u4EF6\u4FE1\u606F\u7C98\u8D34\u81F3\u6B64\uFF0C\u81EA\u52A8\u8BC6\u522B\u59D3\u540D\u3001\u7535\u8BDD\u548C\u5730\u5740\uFF0C\u5982\u5C0F\u95EA\uFF0C13800001111\uFF0C\u6D77\u6DC0\u533A\u4E07\u67F3\u8857\u9053X\u53F7\u9662X\u53F7\u697C(\u5982\u679C\u662F\u62CD\u7167\u5F55\u5165\uFF0C\u8BF7\u5C06\u5C0F\u7968\u653E\u5165\u53D6\u666F\u6846\u5185\u62CD\u6444)" })),
                React.createElement(Button, { type: "primary", loading: false, onClick: submitForm }, "\u7ACB\u5373\u8BC6\u522B")));
        })));
};
export default SmartAddressModal;
