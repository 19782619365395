var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import styled from 'styled-components';
import events from '@utils/events';
import FormModal from '@components/FormModal';
import { diff } from '@/utils/time';
import { DeliveryOrderActions, DeliveryOrderCodeGroup, DeliveryOrderDetail, DeliveryOrderInfo, DeliveryOrderMap, DeliveryOrderPriceGroup, DeliveryOrderProgress, DeliveryOrderReceiver } from '../components';
import { DeliveryContext } from '../context';
import DeliveryOrderTab from '../components/DeliveryOrderTab';
import { NoData, DeliveryError } from './styled';
import { DeliveryStatus, OrderStatus, EStrategyType } from '../constants';
import ajax from '@utils/ajax';
import { AppendTipAction, CancelDeliveryOrderActionWAIT_GRAB } from '../action';
function getRemainKM(params) {
    return ajax.get('/map/ride/distance', {
        params: params
    });
    // return new Promise(resolve => {
    //   resolve(2)
    // })
}
export function getKnightLatLng(distriOrderId) {
    return ajax.get('/deliver/order/knight/info', {
        params: { distriOrderId: distriOrderId }
    });
}
var Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1v22ulk" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  .append-tip-btn {\n    margin-top: 14px;\n    margin-right: 10px;\n  }\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  .append-tip-btn {\n    margin-top: 14px;\n    margin-right: 10px;\n  }\n  justify-content: flex-end;\n"])));
var SCallType = styled.div.withConfig({ displayName: "SCallType", componentId: "sc-19tq4z0" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 20px;\n  .time {\n    color: #333333;\n    font-weight: normal;\n    text-align: right;\n    margin-left: 24px;\n  }\n  .desc {\n    color: #01cccc;\n    font-weight: normal;\n  }\n"], ["\n  display: flex;\n  margin-bottom: 20px;\n  .time {\n    color: #333333;\n    font-weight: normal;\n    text-align: right;\n    margin-left: 24px;\n  }\n  .desc {\n    color: #01cccc;\n    font-weight: normal;\n  }\n"])));
var ActionsWrapper = styled.div.withConfig({ displayName: "ActionsWrapper", componentId: "sc-avnomd" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  margin-top: 14px;\n"], ["\n  flex: 1;\n  margin-top: 14px;\n"])));
var ViewDeliveryModal = /** @class */ (function (_super) {
    __extends(ViewDeliveryModal, _super);
    function ViewDeliveryModal(props, context) {
        var _this = _super.call(this, props) || this;
        _this.currentPlatform = '';
        _this.deliveryTimer = null;
        _this.state = {
            knightCoordinate: '',
            knightLabelText: '',
            timer: 0,
            deliveryTime: null
        };
        _this.refresh = function (val) {
            if (val === void 0) { val = _this.currentPlatform; }
            var _a = _this.context, changeDeliveryPlatformTab = _a.changeDeliveryPlatformTab, order = _a.order;
            _this.currentPlatform = val;
            changeDeliveryPlatformTab(val);
        };
        _this.handleSwitchTab = function (val) {
            _this.refresh(val);
        };
        _this.getAnotherPoint = function (status) {
            var distriOrder = _this.context.distriOrder;
            switch (status) {
                case DeliveryStatus.Receiveing:
                    return distriOrder.storeCoordinate;
                // 已到店
                case DeliveryStatus.Arrived:
                    return '';
                // 已取货
                case DeliveryStatus.Delivering:
                    return distriOrder.receiveCoordinate;
                // 已完成
                case DeliveryStatus.Compeleted:
                    return '';
                default:
                    return '';
            }
        };
        _this.getKnightText = function (status, km) {
            switch (status) {
                // 已接单
                case DeliveryStatus.Receiveing:
                    return "\u8DDD\u79BB\u5E97\u94FA" + km + "km";
                // 已到店
                case DeliveryStatus.Arrived:
                    return "\u9A91\u624B\u5DF2\u5230\u5E97";
                // 已取货
                case DeliveryStatus.Delivering:
                    return "\u8DDD\u5BA2\u6237" + km + "km";
                // 已完成
                case DeliveryStatus.Compeleted:
                    return "\u5DF2\u9001\u8FBE,\u8BA2\u5355\u5B8C\u6210";
                default:
                    return '';
            }
        };
        _this.updateLocation = function () { return __awaiter(_this, void 0, void 0, function () {
            var distriOrder, data, knightCoordinateStr, targetPoint, targetPointStr, res, text, text;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        distriOrder = this.context.distriOrder;
                        if (!distriOrder.id) return [3 /*break*/, 5];
                        return [4 /*yield*/, getKnightLatLng(distriOrder.id)];
                    case 1:
                        data = (_a.sent()).data;
                        knightCoordinateStr = data.lng + "," + data.lat;
                        this.setState({
                            knightCoordinate: knightCoordinateStr
                        });
                        targetPoint = this.getAnotherPoint(data.distriStatus.status);
                        if (!targetPoint) return [3 /*break*/, 4];
                        targetPointStr = targetPoint.split(',');
                        if (!(data.lat && data.lng)) return [3 /*break*/, 3];
                        return [4 /*yield*/, getRemainKM({
                                startLat: data.lat,
                                startLng: data.lng,
                                endLng: targetPointStr[0],
                                endLat: targetPointStr[1]
                            })];
                    case 2:
                        res = _a.sent();
                        text = this.getKnightText(data.distriStatus.status, res.data.distance);
                        this.setState({
                            knightLabelText: text
                        });
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        text = this.getKnightText(data.distriStatus.status, '');
                        this.setState({
                            knightLabelText: text
                        });
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        _this.handleDeliveryTimer = function () {
            _this.deliveryTimer = setInterval(function () {
                var callHistoryVO = _this.context.callHistoryVO;
                var createTime = callHistoryVO.createTime;
                if (createTime) {
                    _this.setState({
                        deliveryTime: diff(createTime)
                    });
                }
            }, 1000);
        };
        var distriOrder = context.distriOrder;
        _this.setState({
            knightCoordinate: distriOrder.knightCoordinate
        });
        _this.currentPlatform = distriOrder.platform;
        return _this;
    }
    ViewDeliveryModal.prototype.componentDidMount = function () {
        var _this = this;
        this.updateLocation();
        var timer = setInterval(function () {
            var distriOrder = _this.context.distriOrder;
            if (distriOrder.platform) {
                _this.updateLocation();
            }
        }, 10000);
        this.setState({
            timer: timer
        });
        this.handleDeliveryTimer();
        // 追加配送小费触发
        events.on('changeTip', function () {
            _this.refresh();
        });
    };
    ViewDeliveryModal.prototype.componentWillUnmount = function () {
        clearInterval(this.state.timer);
        clearInterval(Number(this.deliveryTimer));
    };
    ViewDeliveryModal.prototype.render = function () {
        var deliveryTime = this.state.deliveryTime;
        var _a = this.props, visible = _a.visible, toggleVisible = _a.toggleVisible;
        var _b = this.context, order = _b.order, distriOrder = _b.distriOrder, callHistoryVO = _b.callHistoryVO;
        var _c = callHistoryVO.historyList, historyList = _c === void 0 ? [] : _c, createTime = callHistoryVO.createTime, callMode = callHistoryVO.callMode, distriOrderStatus = callHistoryVO.distriOrderStatus;
        var tabs = historyList.map(function (p) { return ({
            name: p.name,
            value: p.platformCode,
            state: p.state,
            errorMsg: p.errorMsg
        }); });
        return (React.createElement(FormModal, { className: "send-delivery-modal", title: "\u914D\u9001\u5355\u8BE6\u60C5", visible: visible, onCancel: toggleVisible, footer: null, width: 840, destroyOnClose: true },
            callMode && (React.createElement(SCallType, null,
                React.createElement("span", { className: "call-type" }, "\u547C\u53EB\u65B9\u5F0F\uFF1A"),
                React.createElement("span", { className: "desc" }, callMode === EStrategyType.CHEAP ? '低价优先,依次呼叫' : '速度优先,同时呼叫'),
                createTime && distriOrderStatus === 20 && (React.createElement("span", { className: "time" }, deliveryTime)))),
            distriOrderStatus === 20 && (React.createElement(DeliveryOrderTab, { data: tabs, defaultValue: distriOrder.platform, onChange: this.handleSwitchTab })),
            !distriOrder.platform ? (React.createElement(NoData, null, "\u6682\u65E0\u6570\u636E")) : (React.createElement(React.Fragment, null,
                distriOrder.exceptionMsg && distriOrder.exceptionMsg.length && (React.createElement(DeliveryError, null, distriOrder.exceptionMsg.join(';'))),
                React.createElement(DeliveryOrderInfo, null),
                React.createElement(DeliveryOrderProgress, { data: distriOrder.distriStatus }),
                React.createElement(DeliveryOrderMap, { knightCoordinate: this.state.knightCoordinate, knightLabelText: this.state.knightLabelText }),
                React.createElement(DeliveryOrderCodeGroup, null),
                React.createElement(DeliveryOrderReceiver, { distance: distriOrder.distance }),
                React.createElement(DeliveryOrderDetail, null),
                React.createElement(Wrapper, null,
                    order.status === OrderStatus.WaitForTake &&
                        distriOrder.status && distriOrder.status.code !== 60 && (React.createElement(AppendTipAction, { className: "append-tip-btn", parentVisiable: visible })),
                    distriOrder.status && distriOrder.status.code < 50 && (React.createElement(ActionsWrapper, null, order.status === OrderStatus.WaitForTake ? React.createElement(CancelDeliveryOrderActionWAIT_GRAB, { toggleVisible: toggleVisible }) :
                        React.createElement(DeliveryOrderActions, { toggleVisible: toggleVisible }))),
                    React.createElement(DeliveryOrderPriceGroup, null))))));
    };
    return ViewDeliveryModal;
}(React.PureComponent));
export { ViewDeliveryModal };
ViewDeliveryModal.contextType = DeliveryContext;
var templateObject_1, templateObject_2, templateObject_3;
