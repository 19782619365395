var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var PrintChooseWrapper = styled.div.withConfig({ displayName: "PrintChooseWrapper", componentId: "sc-71bks3" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  .print-btn {\n    margin-bottom: 10px;\n    margin-right: 20px;\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  .print-btn {\n    margin-bottom: 10px;\n    margin-right: 20px;\n  }\n"])));
export var SelfDeliveryRow = styled.div.withConfig({ displayName: "SelfDeliveryRow", componentId: "sc-ajilzn" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 15px;\n"], ["\n  margin-bottom: 15px;\n"])));
export var CloseWrapper = styled.div.withConfig({ displayName: "CloseWrapper", componentId: "sc-1jjh0y1" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
export var NoData = styled.div.withConfig({ displayName: "NoData", componentId: "sc-ych3ho" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  padding: 50px;\n"], ["\n  display: flex;\n  justify-content: center;\n  padding: 50px;\n"])));
export var DeliveryError = styled.div.withConfig({ displayName: "DeliveryError", componentId: "sc-1iftt82" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-align: center;\n  color: #f5222d;\n  font-size: 14px;\n  margin: 14px auto;\n"], ["\n  text-align: center;\n  color: #f5222d;\n  font-size: 14px;\n  margin: 14px auto;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
