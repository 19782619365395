var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext } from 'react';
import { useToggle } from '@hooks';
import { Button } from 'antd';
import { AppendTipModal, AppendTipPlatformModal } from '../modal';
import { ActionsContext } from '../context';
import { EDeliveryType } from '../constants';
import './button.less';
import { getAuth } from '@/container/AccountManagement/component';
import { useSelector } from 'react-redux';
var AppendTipActioninit = function (props) {
    var _a = __read(useToggle(false), 3), visible = _a[0], show = _a[1], hide = _a[2];
    var context = useContext(ActionsContext);
    var toggleVisible = function () { return (visible ? hide() : show()); };
    var payAccountType = context.payAccountType;
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { type: "primary", onClick: toggleVisible, className: 'orderbuttonactive ' + props.className }, "\u8FFD\u52A0\u5C0F\u8D39"),
        payAccountType === EDeliveryType.Platform ? (React.createElement(AppendTipPlatformModal, { visible: visible, parentVisiable: props.parentVisiable, toggleVisible: toggleVisible })) : (React.createElement(AppendTipModal, { visible: visible, toggleVisible: toggleVisible, parentVisiable: props.parentVisiable }))));
};
export var AppendTipAction = function (props) {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("配送处理", menu) ? React.createElement(AppendTipActioninit, __assign({}, props)) : null;
};
