var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
export var InfoWrapper = styled.div.withConfig({ displayName: "InfoWrapper", componentId: "sc-1mpr9o9" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 45px;\n  .cannect {\n    font-size: 14px;\n    font-family: PingFangSC-Regular, PingFang SC;\n    color: #333333;\n    line-height: 14px;\n    margin-bottom: 20px;\n    margin-top: 30px;\n  }\n\n  .title {\n    font-size: 14px;\n    font-family: PingFangSC-Semibold, PingFang SC;\n    font-weight: 600;\n    color: #333333;\n    line-height: 21px;\n  }\n\n  .list {\n    font-size: 12px;\n    font-family: PingFangSC-Semibold, PingFang SC;\n    color: rgba(153, 153, 153, 1);\n    line-height: 21px;\n  }\n"], ["\n  padding: 0 45px;\n  .cannect {\n    font-size: 14px;\n    font-family: PingFangSC-Regular, PingFang SC;\n    color: #333333;\n    line-height: 14px;\n    margin-bottom: 20px;\n    margin-top: 30px;\n  }\n\n  .title {\n    font-size: 14px;\n    font-family: PingFangSC-Semibold, PingFang SC;\n    font-weight: 600;\n    color: #333333;\n    line-height: 21px;\n  }\n\n  .list {\n    font-size: 12px;\n    font-family: PingFangSC-Semibold, PingFang SC;\n    color: rgba(153, 153, 153, 1);\n    line-height: 21px;\n  }\n"])));
var infoData = {
    dada: [
        '达达提示【已开通其他渠道】的解决办法？',
        '操作步骤：',
        '1、登录达达APP',
        '2、点击头像，选择企业资料',
        '3、选择绑定第三方平台',
        '4、选择获取商户信息（绑定第三方订单管理系统）',
        '5、取消第三方系统授权',
        '6、再回到易送此页面绑定即可'
    ],
    shunfeng: [
        '顺丰APP-开发者工具-授权下【yisong-pro】',
        '顺丰APP-基本信息-找到6开头的13位顺丰店铺ID'
    ]
};
var TipInfo = function (_a) {
    var _b = _a.platform, platform = _b === void 0 ? '' : _b;
    var data = infoData[platform] || [];
    return (React.createElement(InfoWrapper, null,
        !!data.length && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "title" }, "\u00B7\u5C0F\u8D34\u58EB"),
            data.map(function (list) { return (React.createElement("div", { className: "list", key: list }, list)); }))),
        React.createElement("div", { className: "cannect" }, "\u7ED1\u5B9A\u9047\u5230\u95EE\u9898\uFF0C\u53EF\u4EE5\u8054\u7CFB\u6613\u9001\u5BA2\u670D\uFF1A15501240064")));
};
export default TipInfo;
var templateObject_1;
