var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { message, Modal } from 'antd';
import axios from 'axios';
import produce from 'immer';
import { requestForOrder } from '@utils/request';
import FormModal from '@components/FormModal';
import ajax from '../../../utils/ajax';
import { DeliveryOrderReceiver } from '../components';
import SendDeliveryTab from '../components/SendDeliveryTab';
import { DeliveryContext } from '../context';
import { WrappedSendDeliveryForm, WrappedSendDeliveryFormPlatform } from '../form';
import { EDeliveryType } from '../constants';
import { withRouter } from 'react-router-dom';
import { declaredAmountforRequest } from '../components/InsuranceComponent';
var SendDeliveryModalItem = /** @class */ (function (_super) {
    __extends(SendDeliveryModalItem, _super);
    function SendDeliveryModalItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            confirmLoading: false,
            distance: 0
        };
        _this.submitSendDelivery = function () {
            var validateFields = _this.form.props.form.validateFields;
            var _a = _this.props, order = _a.order, emitOrderChange = _a.emitOrderChange, onChange = _a.onChange, toggleVisible = _a.toggleVisible;
            _this.setState(produce(function (draft) {
                draft.confirmLoading = true;
            }));
            validateFields(function (errors, values) {
                var _a = _this.props, currentTab = _a.currentTab, valuations = _a.valuations, platformInit = _a.platformInit;
                var balance = platformInit.balance, maxPayAmount = platformInit.maxPayAmount;
                if (errors) {
                    _this.setState(produce(function (draft) {
                        draft.confirmLoading = false;
                    }));
                    return false;
                }
                var reservationTime = values.reservationTime
                    ? values.reservationTime.format('x')
                    : '';
                if (currentTab === EDeliveryType.Platform) {
                    var useCoupon = values.platformCoupon.globalCouponId === 0 ? 1 : 2;
                    var couponInfos_1 = [];
                    valuations.map(function (item) {
                        if (item.couponGroupId) {
                            couponInfos_1.push({
                                couponGroupId: item.couponGroupId,
                                partnerPlatform: item.platform
                            });
                        }
                    });
                    console.log(valuations, 'valuations');
                    if (valuations[0]["errMsg"]) {
                        _this.setState(produce(function (draft) {
                            draft.confirmLoading = false;
                        }));
                        return message.error(valuations[0]["errMsg"]);
                    }
                    if (balance < maxPayAmount) {
                        message.error('余额不足，请充值');
                        _this.setState(produce(function (draft) {
                            draft.confirmLoading = false;
                        }));
                        return false;
                    }
                    requestForOrder
                        .post('/api/platform/order/payMoney', {
                        orderId: order.id,
                        storeId: order.storeId,
                        platform: values.platform,
                        payAccountType: 1,
                        additionAmount: Math.abs(values.tip) * 100,
                        appointType: values.appointType,
                        appointPickUpTime: reservationTime,
                        remarks: values.remarks,
                        couponInfos: couponInfos_1,
                        couponType: useCoupon
                    }, {
                        showError: false
                    })
                        .then(function (_a) {
                        var data = _a.data, code = _a.code, err = _a.err;
                        if (code === 0) {
                            emitOrderChange({
                                distriOrderId: data.distriOrderId.trim()
                            });
                            message.success('下单成功');
                            _this.setState(produce(function (draft) {
                                draft.confirmLoading = false;
                            }));
                            toggleVisible();
                        }
                        else {
                            // message.error(err)
                        }
                    })
                        .catch(function (data) {
                        Modal.error({
                            title: '异常',
                            content: data.msg
                        });
                        _this.setState(produce(function (draft) {
                            draft.confirmLoading = false;
                        }));
                        toggleVisible();
                    });
                }
                else {
                    onChange(values, function () {
                        var _a, _b;
                        _this.sendSource = axios.CancelToken.source();
                        ajax
                            .post('/order/send', {
                            orderId: order.id,
                            storeId: order.storeId,
                            expressPlatfrom: values.platform,
                            tip: values.tip,
                            handleError: true,
                            dispatchType: values.dispatchType,
                            appointType: values.appointType,
                            appointPickUpTime: reservationTime,
                            remarks: values.remarks,
                            declaredAmount: declaredAmountforRequest(((_b = (_a = _this.form) === null || _a === void 0 ? void 0 : _a.Insuranceref) === null || _b === void 0 ? void 0 : _b.current) || 0)
                        }, {
                            cancelToken: _this.sendSource.token
                        })
                            .then(function () {
                            emitOrderChange();
                            message.success('下单成功');
                            _this.setState(produce(function (draft) {
                                draft.confirmLoading = false;
                            }));
                            toggleVisible();
                        })
                            .catch(function (data) {
                            Modal.error({
                                title: '异常',
                                content: data.msg
                            });
                            _this.setState(produce(function (draft) {
                                draft.confirmLoading = false;
                            }));
                            toggleVisible();
                        });
                    });
                }
            });
        };
        _this.handlePlatformChange = function (valuation) {
            _this.setState(produce(function (draft) {
                draft.distance = valuation.distance;
            }));
        };
        _this.handleModalCancel = function () {
            _this.setState(produce(function (draft) {
                draft.confirmLoading = false;
            }), function () {
                _this.sendSource && _this.sendSource.cancel();
                _this.props.toggleVisible();
            });
        };
        return _this;
    }
    SendDeliveryModalItem.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        var valuations = nextProps.valuations;
        if (valuations.length) {
            this.handlePlatformChange(valuations[0]);
        }
    };
    SendDeliveryModalItem.prototype.render = function () {
        var _this = this;
        var _a = this.props, order = _a.order, valuations = _a.valuations, visible = _a.visible, onChange = _a.onChange, handleTipChange = _a.handleTipChange, currentTab = _a.currentTab, handleSwitchTab = _a.handleSwitchTab, platformInit = _a.platformInit, channelInit = _a.channelInit, saveLoading = _a.saveLoading;
        var _b = this.state, confirmLoading = _b.confirmLoading, distance = _b.distance;
        var payAccountTypes = order.payAccountTypes;
        var isDisabled = getIsDisabled();
        var footerNone = getFooterNone();
        var currentPay = getCurrentPay(payAccountTypes);
        return (React.createElement(FormModal, { className: "send-delivery-modal", title: "\u4E0B\u914D\u9001\u5355", okText: "\u786E\u8BA4\u652F\u4ED8", visible: visible, onOk: this.submitSendDelivery, footerNone: footerNone, onCancel: this.handleModalCancel, confirmLoading: confirmLoading || saveLoading, destroyOnClose: true, width: 740 },
            React.createElement(SendDeliveryTab, { handleSwitchTab: handleSwitchTab, currentTab: currentTab, payAccountTypes: currentPay, formRef: this.form }),
            React.createElement(DeliveryContext.Provider, { value: { order: order, distriOrder: {} } },
                React.createElement(DeliveryOrderReceiver, { distance: distance })),
            currentTab === EDeliveryType.Platform ? (React.createElement(WrappedSendDeliveryFormPlatform, { wrappedComponentRef: function (form) { return (_this.form = form); }, valuations: valuations, triggerChange: onChange, handleTipChange: handleTipChange, handlePlatformChange: this.handlePlatformChange, globalDistance: this.props.distance, order: order, platformInit: platformInit })) : (React.createElement(WrappedSendDeliveryForm, { wrappedComponentRef: function (form) { return (_this.form = form); }, valuations: valuations, triggerChange: onChange, handleTipChange: handleTipChange, handlePlatformChange: this.handlePlatformChange, channelInit: channelInit, isDisabled: isDisabled, declaredAmountforDetail: this.props.declaredAmountforDetail, order: order, history: this.props.history }))));
        function getIsDisabled() {
            //没有自有账号
            return (payAccountTypes || []).indexOf(2) === -1;
        }
        function getCurrentPay(payAccountTypes) {
            if (getIsDisabled()) {
                //通用号 自有运力
                return [1, 2];
            }
            return payAccountTypes;
        }
        function getFooterNone() {
            return currentTab === 2 && getIsDisabled();
        }
    };
    return SendDeliveryModalItem;
}(React.Component));
export var SendDeliveryModal = withRouter(SendDeliveryModalItem);
