export var storeIdValidator = function (_a) {
    var _b = _a.stores, stores = _b === void 0 ? [] : _b;
    return function (rule, value, callback) {
        if (!stores.length) {
            return callback('请先添加门店');
        }
        if (!value) {
            return callback('请选择门店');
        }
        return callback();
    };
};
