var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button, Input } from 'antd';
import styled from 'styled-components';
export var SmsInputGroup = styled.div.withConfig({ displayName: "SmsInputGroup", componentId: "sc-q6argn" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
export var InputSmsCode = styled(Input).withConfig({ displayName: "InputSmsCode", componentId: "sc-ae2d0" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-right: 5px !important;\n"], ["\n  margin-right: 5px !important;\n"])));
export var ApplySmsCodeButton = styled(Button).withConfig({ displayName: "ApplySmsCodeButton", componentId: "sc-53kxf9" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 48px !important;\n"], ["\n  height: 48px !important;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
