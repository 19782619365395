var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * @Author: hijack
 * @Date: 2021-07-21 14:45:00
 * @LastEditTime: 2021-07-21 14:55:27
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/container/AgreementPrivacy/index.tsx
 */
import * as React from 'react';
import marked from 'marked';
import agreementFile from './help.md';
import { AgreementContent, AgreementWrapper } from './styled';
var PrintHelper = /** @class */ (function (_super) {
    __extends(PrintHelper, _super);
    function PrintHelper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            markdown: ''
        };
        return _this;
    }
    PrintHelper.prototype.componentDidMount = function () {
        this.setState({
            markdown: marked(agreementFile)
        });
    };
    PrintHelper.prototype.render = function () {
        return (React.createElement(AgreementWrapper, null,
            React.createElement(AgreementContent, { dangerouslySetInnerHTML: { __html: this.state.markdown } })));
    };
    return PrintHelper;
}(React.Component));
export default PrintHelper;
