var _a;
import { get } from 'lodash';
import { PrintCases } from '../../constants';
import { getGoodsColText } from '../../local';
import { getPlatformText } from './common';
import { createBarcodeContent } from './utils';
import { ORDER_PLATFORM_MAP } from '@utils/constants';
var genFeiETable = function (paperFormat, goods, bold) {
    if (bold === void 0) { bold = false; }
    var table = getGoodsColText({ goods: goods, paperFormat: paperFormat }).split('\n');
    if (bold) {
        return table.map(function (row) { return "<L>" + row + "</L>"; }).join('<BR>');
    }
    return table.join('<BR>');
};
var genBoldFeiETable = function (paperFormat, goods) { return getGoodsColText({ goods: goods, paperFormat: paperFormat }).split('\n'); };
export var FeiECloudTpl = (_a = {},
    _a[PrintCases.Customer] = function (_a) {
        var paperFormat = _a.paperFormat, data = _a.data;
        var content = "<CB>" + data.storeName + "</CB><BR>" +
            "<CB>#\u987E\u5BA2\u8054#</CB><BR><BR>" +
            ("\u63A5\u5355\u5E73\u53F0\uFF1A" + getPlatformText(data.platform) + "<BR>") +
            ("\u8BA2\u5355\u53F7\uFF1A" + data.platformId + "<BR>") +
            ("\u5E73\u53F0\u6D41\u6C34\u53F7\uFF1A" + data.platformDaySn + "<BR>") +
            ("\u6613\u9001\u6D41\u6C34\u53F7\uFF1A" + data.daySn + "<BR>") +
            ("\u4E0B\u5355\u65F6\u95F4\uFF1A" + data.orderTime + "<BR>") +
            ("<B>\u9001\u8FBE\u65F6\u95F4\uFF1A" + data.deliverTime + "</B><BR>") +
            ("<B>\u5730\u5740\uFF1A" + data.consigneeAddress + "</B><BR>") +
            ("<B>\u59D3\u540D\uFF1A" + data.consignee + "</B><BR>") +
            ("<B>\u7535\u8BDD\uFF1A" + data.consigneePhone + "</B><BR>") +
            ("<B>\u5907\u6CE8\uFF1A" + get(data, 'description', '') + "</B><BR>") +
            ("<B>\u53D1\u7968\uFF1A" + data.invoiceTitle + " " + data.invoiceId + "</B><BR><BR>") +
            (genFeiETable(paperFormat, data.goods) + "<BR><BR>") +
            ("\u9910\u76D2\u8D39\uFF1A" + data.packageFee + "\u5143<BR>") +
            ("\u914D\u9001\u8D39\uFF1A" + data.deliverFee + "\u5143<BR>") +
            ("<RIGHT>\u539F\u4EF7\uFF1A" + data.originalPrice + "\u5143</RIGHT>") +
            ("<RIGHT>\u603B\u4F18\u60E0\u91D1\u989D\uFF1A" + data.reducedPrice + "\u5143</RIGHT>") +
            ("<RIGHT>\u5728\u7EBF\u652F\u4ED8\uFF1A" + data.totalPrice + "\u5143</RIGHT>") +
            ("<C>" + createBarcodeContent(data.id) + "</C>") +
            ("<C>\u6613\u9001\u8BA2\u5355\u53F7:" + data.id + "</C>");
        if (data.platform === ORDER_PLATFORM_MAP.MEITUAN && data.meituanCode) {
            content +=
                "<C>" + createBarcodeContent(data.meituanCode) + "</C>" +
                    ("<C>\u7F8E\u56E2\u8BA2\u5355\u53F7:" + data.meituanCode + "</C><BR><BR>");
        }
        return content;
    },
    _a[PrintCases.Merchant] = function (_a) {
        var paperFormat = _a.paperFormat, data = _a.data;
        var content = "<CB>" + data.storeName + "</CB><BR>" +
            "<CB>#\u5546\u6237\u8054#</CB><BR><BR>" +
            ("\u63A5\u5355\u5E73\u53F0\uFF1A" + getPlatformText(data.platform) + "<BR>") +
            ("\u8BA2\u5355\u53F7\uFF1A" + data.platformId + "<BR>") +
            ("\u5E73\u53F0\u6D41\u6C34\u53F7\uFF1A" + data.platformDaySn + "<BR>") +
            ("\u6613\u9001\u6D41\u6C34\u53F7\uFF1A" + data.daySn + "<BR>") +
            ("\u4E0B\u5355\u65F6\u95F4\uFF1A" + data.orderTime + "<BR>") +
            ("<B>\u9001\u8FBE\u65F6\u95F4\uFF1A" + data.deliverTime + "</B><BR>") +
            ("<B>\u5730\u5740\uFF1A" + data.consigneeAddress + "</B><BR>") +
            ("<B>\u59D3\u540D\uFF1A" + data.consignee + "</B><BR>") +
            ("<B>\u7535\u8BDD\uFF1A" + data.consigneePhone + "</B><BR>") +
            ("<B>\u5907\u6CE8\uFF1A" + get(data, 'description', '') + "</B><BR>") +
            ("<B>\u53D1\u7968\uFF1A" + data.invoiceTitle + " " + data.invoiceId + "</B><BR><BR>") +
            (genFeiETable(paperFormat, data.goods) + "<BR><BR>") +
            ("\u9910\u76D2\u8D39\uFF1A" + data.packageFee + "\u5143<BR>") +
            ("\u914D\u9001\u8D39\uFF1A" + data.deliverFee + "\u5143<BR>") +
            ("<RIGHT>\u539F\u4EF7\uFF1A" + data.originalPrice + "\u5143</RIGHT>") +
            ("<RIGHT>\u603B\u4F18\u60E0\u91D1\u989D\uFF1A" + data.reducedPrice + "\u5143</RIGHT>") +
            ("<RIGHT>\u5728\u7EBF\u652F\u4ED8\uFF1A" + data.totalPrice + "\u5143</RIGHT>") +
            ("<C>" + createBarcodeContent(data.id) + "</C>") +
            ("<C>\u6613\u9001\u8BA2\u5355\u53F7:" + data.id + "</C>");
        if (data.platform === ORDER_PLATFORM_MAP.MEITUAN) {
            content +=
                "<C>" + createBarcodeContent(data.meituanCode) + "</C>" +
                    ("<C>\u7F8E\u56E2\u8BA2\u5355\u53F7:" + data.meituanCode + "</C><BR><BR>");
        }
        return content;
    },
    _a[PrintCases.Producer] = function (_a) {
        var paperFormat = _a.paperFormat, data = _a.data;
        var content = "<CB>" + data.storeName + "</CB><BR>" +
            "<CB>#\u751F\u4EA7\u8054#</CB><BR><BR>" +
            ("\u5E73\u53F0\u6D41\u6C34\u53F7\uFF1A" + data.platformDaySn + "<BR>") +
            ("\u6613\u9001\u6D41\u6C34\u53F7\uFF1A" + data.daySn + "<BR><BR>") +
            (genFeiETable(paperFormat, data.goods, true) + "<BR><BR>") +
            ("<B>\u5907\u6CE8\uFF1A" + get(data, 'description', '') + "</B><BR><BR>");
        return content;
    },
    _a);
