import React, { useContext, useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import { OrderSummary, OrderLineChart, IncomeFormula, FormulaItem, FormulaTitle, FormulaData, FormulaDataNumber, FormulaDataUnit, FormulaItemOperator } from './styled';
import { Wrapper, Title, ConditionFilter } from '../styled';
import StoreSelect from '../common/StoreSelect';
import OrderPlatformSelect from '../common/OrderPlatformSelect';
import DateFilter from '../common/DateFilter';
import IconIncomeUrl from '@assets/images/icon-income.png';
import IconHistogramUrl from '@assets/images/icon-histogram.png';
import Tips from '../common/Tips';
import FormulaCompare from './FormulaCompare';
import { getOption, getXData, getYData, getTodayXData, getYesterdayXData, getXDataInRange, getCustomXData } from './helper';
import ReportContext from '../../context';
import { ReportModule, DateRangeType } from '../../types/emnu';
var OrderSummaryAndLineChart = function (props) {
    var _a = useContext(ReportContext), changeStore = _a.changeStore, changeOrderPlatform = _a.changeOrderPlatform, changeDate = _a.changeDate;
    var xDataRef = useRef(getTodayXData());
    var orderSummary = props.orderSummary, orderLineChart = props.orderLineChart;
    var handleStoreChange = function (storeId) {
        changeStore(ReportModule.Order, storeId);
    };
    var handleOrderPlatformChange = function (platformId) {
        changeOrderPlatform(ReportModule.Order, platformId);
    };
    var handleDateChange = function (dateRange, dateRangeType) {
        if (dateRangeType === DateRangeType.Today) {
            xDataRef.current = getTodayXData();
        }
        else if (dateRangeType === DateRangeType.Yesterday) {
            xDataRef.current = getYesterdayXData();
        }
        else if (dateRangeType === DateRangeType.LastWeek) {
            // xDataRef.current = getXDataInBeforeRange(7)
            xDataRef.current = getXDataInRange(dateRange.beginTime, dateRange.endTime);
        }
        else if (dateRangeType === DateRangeType.LastMonth) {
            xDataRef.current = getXDataInRange(dateRange.beginTime, dateRange.endTime);
        }
        else if (dateRangeType === DateRangeType.LastQuarter) {
            xDataRef.current = getXDataInRange(dateRange.beginTime, dateRange.endTime);
        }
        else if (dateRangeType === DateRangeType.Custom) {
            // xDataRef.current =
            xDataRef.current = getCustomXData(dateRange.beginTime, dateRange.endTime);
        }
        changeDate(ReportModule.Order, dateRange, dateRangeType);
    };
    var xData = getXData(xDataRef.current);
    var _b = getYData(orderLineChart, xDataRef.current), cancled = _b.cancled, finished = _b.finished;
    return (React.createElement(Wrapper, null,
        React.createElement(ConditionFilter, null,
            React.createElement(StoreSelect, { data: props.stores, onChange: handleStoreChange }),
            React.createElement(OrderPlatformSelect, { data: props.orderPlatforms, onChange: handleOrderPlatformChange, value: props.platformId }),
            React.createElement(DateFilter, { name: "order", onChange: handleDateChange })),
        React.createElement(OrderSummary, null,
            React.createElement(Title, { icon: IconIncomeUrl }, "\u8BA2\u5355\u6536\u5165\u7EDF\u8BA1"),
            React.createElement("div", null,
                React.createElement(IncomeFormula, null,
                    React.createElement(FormulaItem, null,
                        React.createElement(FormulaTitle, null,
                            "\u5B8C\u6210\u8BA2\u5355\u6570 \u00A0 ",
                            React.createElement(Tips, { title: "\u72B6\u6001\u4E3A\u5DF2\u5B8C\u6210\u7684\u8BA2\u5355\u6570\u91CF" })),
                        React.createElement(FormulaData, null,
                            React.createElement(FormulaDataNumber, null, orderSummary.finishOrderCount),
                            React.createElement(FormulaDataUnit, null, "\u5355")),
                        React.createElement(FormulaCompare, { text: props.compareText, value: orderSummary.finishOrderGrowthRate })),
                    React.createElement(FormulaItemOperator, null),
                    React.createElement(FormulaItem, null,
                        React.createElement(FormulaTitle, null,
                            "\u5B9E\u4ED8\u5BA2\u5355\u4EF7 \u00A0 ",
                            React.createElement(Tips, { title: "\u8425\u4E1A\u989D\u00F7\u5B8C\u6210\u8BA2\u5355\u6570" })),
                        React.createElement(FormulaData, null,
                            React.createElement(FormulaDataNumber, null, orderSummary.averageOrderPrice),
                            React.createElement(FormulaDataUnit, null, "\u5143")),
                        React.createElement(FormulaCompare, { text: props.compareText, value: orderSummary.averageOrderPriceGrowthRate })),
                    React.createElement(FormulaItemOperator, null),
                    React.createElement(FormulaItem, null,
                        React.createElement(FormulaTitle, null,
                            "\u8425\u4E1A\u989D \u00A0",
                            React.createElement(Tips, { title: "\u72B6\u6001\u4E3A\u5DF2\u5B8C\u6210\u7684\u8BA2\u5355\u7684\u987E\u5BA2\u5B9E\u9645\u652F\u4ED8\u91D1\u989D\u4E4B\u548C" })),
                        React.createElement(FormulaData, null,
                            React.createElement(FormulaDataNumber, null, orderSummary.payTotalAmount),
                            React.createElement(FormulaDataUnit, null, "\u5143")),
                        React.createElement(FormulaCompare, { text: props.compareText, value: orderSummary.payTotalAmountGrowthRate })),
                    React.createElement(FormulaItemOperator, null),
                    React.createElement(FormulaItem, null,
                        React.createElement(FormulaTitle, null,
                            "\u6536\u5165 \u00A0",
                            React.createElement(Tips, { title: "\u72B6\u6001\u4E3A\u5DF2\u5B8C\u6210\u7684\u8BA2\u5355\u7684\u5B9E\u9645\u6536\u5165\uFF08\u7F8E\u56E2\u4E5F\u53EB\u5546\u5BB6\u5E94\u6536\u6B3E\uFF09\u4E4B\u548C" })),
                        React.createElement(FormulaData, null,
                            React.createElement(FormulaDataNumber, null, orderSummary.storeTotalIncome),
                            React.createElement(FormulaDataUnit, null, "\u5143")),
                        React.createElement(FormulaCompare, { text: props.compareText, value: orderSummary.storeTotalIncomeGrowthRate })),
                    React.createElement(FormulaItemOperator, null),
                    React.createElement(FormulaItem, null,
                        React.createElement(FormulaTitle, null,
                            "\u5E73\u53F0\u6263\u8D39 \u00A0 ",
                            React.createElement(Tips, { title: "\u8425\u4E1A\u989D-\u6536\u5165" })),
                        React.createElement(FormulaData, null,
                            React.createElement(FormulaDataNumber, null, orderSummary.platformDeduction),
                            React.createElement(FormulaDataUnit, null, "\u5143")),
                        React.createElement(FormulaCompare, { text: props.compareText, value: orderSummary.platformDeductionGrowthRate }))))),
        React.createElement(OrderLineChart, null,
            React.createElement(Title, { icon: IconHistogramUrl },
                "\u8BA2\u5355\u65F6\u95F4\u5206\u5E03\u56FE\uFF08\u6839\u636E\u4E0B\u5355\u65F6\u95F4\u6240\u5728\u65F6\u6BB5\u7EDF\u8BA1\uFF09\u00A0",
                React.createElement(Tips, { title: "\u6839\u636E\u4E0B\u5355\u65F6\u95F4\u6240\u5728\u65F6\u6BB5\u7EDF\u8BA1\u72B6\u6001\u4E3A\u5DF2\u5B8C\u6210\u3001\u5DF2\u53D6\u6D88\u7684\u8BA2\u5355\u6570" })),
            React.createElement(ReactECharts, { option: getOption(xData, cancled, finished) }))));
};
export default OrderSummaryAndLineChart;
