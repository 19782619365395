var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ReceiptFormWrapper = styled.div.withConfig({ displayName: "ReceiptFormWrapper", componentId: "sc-epdswj" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n  padding: 0 40px;\n  .custom {\n    margin-left: 24px;\n  }\n  .group {\n    width: 100%;\n  }\n  .input-box {\n    width: 336px;\n  }\n"], ["\n  flex: 1;\n  padding: 0 40px;\n  .custom {\n    margin-left: 24px;\n  }\n  .group {\n    width: 100%;\n  }\n  .input-box {\n    width: 336px;\n  }\n"])));
export var FormTitle = styled.div.withConfig({ displayName: "FormTitle", componentId: "sc-14vf5qp" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: #01cccc;\n  margin-bottom: 20px;\n  font-size: 16px;\n  font-weight: bold;\n"], ["\n  color: #01cccc;\n  margin-bottom: 20px;\n  font-size: 16px;\n  font-weight: bold;\n"])));
export var FormBox = styled.div.withConfig({ displayName: "FormBox", componentId: "sc-543fza" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 30px;\n  .col {\n    padding-bottom: 10px;\n  }\n"], ["\n  margin-bottom: 30px;\n  .col {\n    padding-bottom: 10px;\n  }\n"])));
export var FormRow = styled.div.withConfig({ displayName: "FormRow", componentId: "sc-694rg" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0 0 10px 33px;\n"], ["\n  margin: 0 0 10px 33px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
