import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import ReactECharts from 'echarts-for-react';
import StoreSelect from '../common/StoreSelect';
import DateFilter from '../common/DateFilter';
import { ConditionFilter, Wrapper, Title } from '../styled';
import IconPaperUrl from '@assets/images/icon-paper.png';
import { getOption, getProPortionData } from './helper';
import ReportContext from '../../context';
import { ReportModule } from '../../types/emnu';
var ChannelProportionPie = function (props) {
    var orderrProPortionData = getProPortionData(props.orderProportion);
    var platformProportionData = getProPortionData(props.platformProportion);
    var _a = useContext(ReportContext), changeStore = _a.changeStore, changeOrderPlatform = _a.changeOrderPlatform, changeDate = _a.changeDate;
    var handleStoreChange = function (storeId) {
        changeStore(ReportModule.Channel, storeId);
    };
    var handleDateChange = function (dateRange) {
        changeDate(ReportModule.Channel, dateRange);
    };
    return (React.createElement(Wrapper, null,
        React.createElement(ConditionFilter, null,
            React.createElement(StoreSelect, { data: props.stores, onChange: handleStoreChange }),
            React.createElement(DateFilter, { name: "proportion", onChange: handleDateChange })),
        React.createElement("div", null,
            React.createElement(Row, { gutter: 12 },
                React.createElement(Col, { span: 12 },
                    React.createElement(Title, { icon: IconPaperUrl }, "\u6E20\u9053\u8BA2\u5355\u5360\u6BD4\uFF08\u6839\u636E\u5DF2\u5B8C\u6210\u8BA2\u5355\u7684\u6570\u91CF\u7EDF\u8BA1\uFF1A\u6570\u91CF\u548C\u5360\u6BD4\uFF09"),
                    React.createElement(ReactECharts, { style: { width: '500px' }, option: getOption(orderrProPortionData) })),
                React.createElement(Col, { span: 12 },
                    React.createElement(Title, { icon: IconPaperUrl }, "\u6E20\u9053\u914D\u9001\u5360\u6BD4\uFF08\u6839\u636E\u8BA2\u5355\u4E0B\u5DF2\u5B8C\u6210\u914D\u9001\u5355\u7684\u6570\u91CF\u7EDF\u8BA1\uFF1A\u6570\u91CF\u548C\u5360\u6BD4\uFF09"),
                    React.createElement(ReactECharts, { style: { width: '500px' }, option: getOption(platformProportionData) }))))));
};
export default ChannelProportionPie;
