var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Form, InputNumber, Radio, Switch } from 'antd';
import DeliveryComponent from './DeliveryComponent';
import { EAutoDispatchOpen, EStrategyType, EBizType } from '../../constance';
var createFormField = Form.createFormField;
var FormItem = Form.Item;
var RadioGroup = Radio.Group;
var formItemLayout = {};
var EditDeliveryStrategyForm = /** @class */ (function (_super) {
    __extends(EditDeliveryStrategyForm, _super);
    function EditDeliveryStrategyForm(props) {
        var _this = _super.call(this, props) || this;
        _this.handleUpdateStrategyTypeVisible = function (type) {
            _this.setState({
                strategyTypeVisible: type === EBizType.CHANNEL
            });
        };
        _this.deliveryRef = React.createRef();
        _this.state = {
            strategyTypeVisible: props.strategyData.bizType === EBizType.CHANNEL
        };
        return _this;
    }
    EditDeliveryStrategyForm.prototype.render = function () {
        var _a = this.props, channelStrategyData = _a.channelStrategyData, platformStrategyData = _a.platformStrategyData, strategyData = _a.strategyData;
        var strategyTypeVisible = this.state.strategyTypeVisible;
        var _b = this.props.form, getFieldDecorator = _b.getFieldDecorator, getFieldValue = _b.getFieldValue;
        var isAuto = getFieldValue('autoDispatchOpen') === EAutoDispatchOpen.OPEN;
        return (React.createElement(Form, __assign({}, formItemLayout, { layout: "vertical" }),
            React.createElement(FormItem, { label: "\u95E8\u5E97\u540D\u79F0" }, strategyData.storeName),
            React.createElement(FormItem, { label: "\u914D\u9001\u65B9\u5F0F", style: { width: '100%' } }, getFieldDecorator('autoDispatchOpen', {})(React.createElement(RadioGroup, { style: { width: '100%' } },
                React.createElement(Radio.Button, { style: { width: '50%', textAlign: 'center' }, value: EAutoDispatchOpen.CLOSE }, "\u624B\u52A8\u6D3E\u5355"),
                React.createElement(Radio.Button, { style: { width: '50%', textAlign: 'center' }, value: EAutoDispatchOpen.OPEN }, "\u81EA\u52A8\u6D3E\u5355")))),
            isAuto && (React.createElement(React.Fragment, null,
                React.createElement(DeliveryComponent, { channelStrategyData: channelStrategyData, platformStrategyData: platformStrategyData, strategyData: strategyData, ref: this.deliveryRef, handleUpdateStrategyTypeVisible: this.handleUpdateStrategyTypeVisible }),
                strategyTypeVisible && (React.createElement(FormItem, { label: "\u547C\u53EB\u65B9\u5F0F" }, getFieldDecorator('strategyType', {
                    valuePropName: 'checked',
                    initialValue: strategyData.strategyType === EStrategyType.SPEED
                })(React.createElement(Switch, { checkedChildren: "\u901F\u5EA6\u4F18\u5148", unCheckedChildren: "\u4F4E\u4EF7\u4F18\u5148" })))))),
            React.createElement(FormItem, { label: "\u5373\u65F6\u5355\u8BBE\u7F6E" },
                React.createElement("span", null, "\u987E\u5BA2\u4E0B\u5355\u65F6\u95F4 "),
                getFieldDecorator('immidateOrderDispatchTime', {
                    initialValue: strategyData.immidateOrderDispatchTime || 0
                })(React.createElement(InputNumber, { style: { width: '80px' }, min: 0, precision: 0 })),
                React.createElement("span", null,
                    " ",
                    isAuto ? '分钟后自动派单' : '分钟后提醒派单')),
            React.createElement(FormItem, { label: "\u9884\u7EA6\u5355\u8BBE\u7F6E" },
                React.createElement("span", null, "\u9884\u7EA6\u9001\u8FBE\u65F6\u95F4 "),
                getFieldDecorator('appointOrderDispatchTime', {
                    initialValue: strategyData.appointOrderDispatchTime || 0
                })(React.createElement(InputNumber, { style: { width: '80px' }, min: 0, precision: 0 })),
                React.createElement("span", null,
                    " ",
                    isAuto ? '分钟前自动派单' : '分钟前提醒派单'))));
    };
    return EditDeliveryStrategyForm;
}(React.Component));
var mapPropsToFields = function (props) {
    var strategyData = props.strategyData;
    var _a = strategyData.autoDispatchOpen, autoDispatchOpen = _a === void 0 ? 0 : _a;
    var fields = {
        autoDispatchOpen: createFormField({
            value: autoDispatchOpen
        })
    };
    return fields;
};
var WrappedEditDeliveryStrategyForm = Form.create({
    mapPropsToFields: mapPropsToFields
})(EditDeliveryStrategyForm);
export default WrappedEditDeliveryStrategyForm;
