var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Typography } from 'antd';
import { IconDistance, IconLocation, IconSort, IconUser } from '@components/Icon';
import { DeliveryContext } from '../context';
import { AddressWrapper, CustomCol, CustomRow, ReceiverInfoWrapper } from './styled';
var Text = Typography.Text;
var DeliveryOrderReceiver = /** @class */ (function (_super) {
    __extends(DeliveryOrderReceiver, _super);
    function DeliveryOrderReceiver() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DeliveryOrderReceiver.prototype.render = function () {
        var _a = this.context.order, consignee = _a.consignee, consigneePhone = _a.consigneePhone, consigneeAddress = _a.consigneeAddress, sort = _a.sort;
        var distance = this.props.distance;
        return (React.createElement(ReceiverInfoWrapper, null,
            React.createElement(CustomRow, { gutter: 6 },
                React.createElement(CustomCol, { span: 14 },
                    React.createElement(IconUser, null),
                    React.createElement(Text, null,
                        consignee,
                        ' ')),
                React.createElement(CustomCol, { span: 10 },
                    React.createElement(IconSort, null),
                    React.createElement(Text, null, sort))),
            React.createElement(CustomRow, { gutter: 6 },
                React.createElement(CustomCol, { span: 14 },
                    React.createElement(IconLocation, null),
                    React.createElement(AddressWrapper, null,
                        React.createElement(Text, null, consigneeAddress))),
                React.createElement(CustomCol, { span: 10 },
                    React.createElement(IconDistance, null),
                    React.createElement(Text, null,
                        distance,
                        "\u516C\u91CC")))));
    };
    return DeliveryOrderReceiver;
}(React.PureComponent));
export { DeliveryOrderReceiver };
DeliveryOrderReceiver.contextType = DeliveryContext;
