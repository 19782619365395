import React from 'react';
import { ERouteType } from '../../constants';
import './index.less';
var RoadType = function (_a) {
    var routeType = _a.routeType, handleSwitchRoadType = _a.handleSwitchRoadType;
    var prefixCls = 'roadType';
    return (React.createElement("div", { className: "" + prefixCls },
        React.createElement("div", { className: prefixCls + "-" + (routeType === ERouteType.Assign ? 'active' : 'default'), onClick: handleSwitchRoadType(ERouteType.Assign) }, "\u6307\u5B9A\u8DEF\u7EBF"),
        React.createElement("div", { className: prefixCls + "-" + (routeType === ERouteType.Recommend ? 'active' : 'default'), onClick: handleSwitchRoadType(ERouteType.Recommend) }, "\u6700\u4F18\u8DEF\u7EBF")));
};
export default RoadType;
