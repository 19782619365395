var defaultReceiptData = {
    storeName: '测试门店',
    receiptName: '',
    platform: 'eleme',
    // 订单号
    platformId: '0',
    platformDaySn: 32,
    daySn: 1,
    orderTime: '2021-04-02 12:32:11',
    deliverTime: '立即送达',
    reservationStartTime: 123,
    consigneeAddress: '北京市海淀区上地九街百度大厦',
    consignee: '姜雪',
    consigneePhone: '13800138000',
    description: '多加个丸子，两双筷子',
    booker: "预订人信息",
    bless: '我是平台传过来的祝福语',
    invoiceTitle: '#发票抬头：此处是发票抬内容#,#发票税号：此处是发票税号内容#',
    invoiceId: '',
    goods: [
        {
            name: '宫保鸡丁',
            quantity: '1',
            price: '20'
        },
        {
            name: '京酱肉丝',
            quantity: '2',
            price: '80'
        }
    ],
    packageFee: '0',
    deliverFee: '0',
    originalPrice: '0',
    reducedPrice: '0',
    totalPrice: '0',
    activities: [
        '店铺满30.00减4.00元',
        '门店新客立减1.00元',
        '减配送费3.00元',
        '用户使用了代金券减2元',
        '用户使用了平台红包减2元'
    ],
    isPreOrder: false
};
export default defaultReceiptData;
