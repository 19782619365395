var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useRef } from 'react';
import { Button, Form, Input, message, Radio, Select, Row, Col } from 'antd';
import { ErrorMessage, Formik } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import * as yup from 'yup';
import { FormFooter } from '@components/FormModal';
import PlaceGeoSearch from '@components/PlaceGeoSearch';
import ReservationTime from '@components/ReservationTime';
import { useToggle } from '@hooks';
import { requestForOrder } from '@utils/request';
import SmartAddressModal from '../modal/SmartAddressModal';
import EditableFormTable from '../components/EditableFormTable';
var FormItem = Form.Item;
var Option = Select.Option;
var formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 }
};
var orderFormSchema = yup.object().shape({
    storeId: yup.string().required('请选择门店'),
    orderType: yup
        .mixed()
        .oneOf([0, 1])
        .required(),
    arrivalsTime: yup.string().nullable(),
    remarks: yup.string(),
    address: yup.string().required('请填写收货人地址'),
    longitude: yup.string().required(),
    latitude: yup.string().required(),
    phone: yup.string().required('请填写收货人电话'),
    userName: yup.string().required('请填写收货人姓名'),
    commodities: yup.array()
});
var getStoreRegion = function (storeId, storeList) {
    var store = storeList.filter(function (item) { return item.id === storeId; })[0];
    if (get(store, 'city.province.name', '')) {
        return get(store, 'city.province.name', '').includes('市')
            ? get(store, 'city.province.name', '')
            : get(store, 'city.region.name', '');
    }
    else if (store && store.city) {
        var locationText = store.city.split(',');
        return (locationText.filter(function (text) { return text.includes('市'); })[0] ||
            locationText[locationText.length - 1]);
    }
    else {
        return '';
    }
};
var columns = [
    {
        title: '品名',
        dataIndex: 'name',
        width: '45%',
        editable: true,
        inputType: 'string'
    },
    {
        title: '数量',
        dataIndex: 'number',
        width: '15%',
        editable: true,
        inputType: 'number',
        ignoreDecimal: true
    },
    {
        title: '金额',
        dataIndex: 'amount',
        width: '15%',
        editable: true,
        inputType: 'number'
    }
];
var CustomErrorMsg = styled.div.withConfig({ displayName: "CustomErrorMsg", componentId: "sc-ojgknk" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: -2px 0 -1px;\n  color: #f5222d;\n  line-height: initial;\n"], ["\n  margin: -2px 0 -1px;\n  color: #f5222d;\n  line-height: initial;\n"])));
var formItemStyles = {
    display: 'flex',
    marginBottom: '10px'
};
var handleFieldValue = null;
var formValues = {};
export var CreateOrderForm = function (props) {
    var oneByMoreData = props.oneByMoreData;
    var orderFormInitialValues = {
        storeId: oneByMoreData && oneByMoreData.length > 0
            ? Number(oneByMoreData[0].storeId)
            : props.storeList && props.storeList[0].id,
        orderType: 1,
        arrivalsTime: null,
        remarks: '',
        address: '',
        longitude: 0,
        latitude: 0,
        receiveAddressDetail: '',
        phone: '',
        userName: '',
        commodities: []
    };
    var _a = __read(useToggle(false), 3), confirmLoading = _a[0], startConfirmLoading = _a[1], stopConfirmLoading = _a[2];
    var toggleLoading = {
        startConfirmLoading: startConfirmLoading,
        stopConfirmLoading: stopConfirmLoading
    };
    var _b = __read(useState(false), 2), smartAddressVisible = _b[0], setSmartAddressVisible = _b[1];
    var placeGeoSearchRef = useRef();
    var handleSmartButton = function () {
        if (formValues.storeId) {
            setSmartAddressVisible(true);
        }
        else {
            message.error('请选择门店');
        }
    };
    var handleSmartFormat = function (_a) {
        var smartText = _a.smartText;
        var handleAddressSearch = (placeGeoSearchRef === null || placeGeoSearchRef === void 0 ? void 0 : placeGeoSearchRef.current).handleAddressSearch;
        requestForOrder.get('/api/city/address/smart', {
            input: smartText,
        }).then(function (back) {
            var _a;
            var code = back.code, data = back.data;
            if (code === 0 && data) {
                var address = data.address, detail = data.detail, name = data.name, phone = data.phone;
                setSmartAddressVisible(false);
                handleAddressSearch(address);
                (_a = document.getElementById("searchAddressDom")) === null || _a === void 0 ? void 0 : _a.click();
                if (handleFieldValue) {
                    handleFieldValue('receiveAddressDetail', detail);
                    handleFieldValue('phone', phone);
                    handleFieldValue('userName', name);
                }
            }
        });
    };
    return (React.createElement(Formik, { initialValues: orderFormInitialValues, validationSchema: orderFormSchema, onSubmit: function (values) {
            props.onSubmit(values, toggleLoading);
        } }, function (_a) {
        var values = _a.values, setFieldValue = _a.setFieldValue, submitForm = _a.submitForm;
        handleFieldValue = setFieldValue;
        formValues = values;
        return (React.createElement(Form, __assign({}, formItemLayout),
            React.createElement(FormItem, { label: "\u63A5\u5355\u5E73\u53F0", style: formItemStyles },
                React.createElement(Input, { value: "\u7EBF\u4E0B", disabled: true })),
            React.createElement(FormItem, { label: "\u5B9E\u4F53\u95E8\u5E97", required: true, style: formItemStyles },
                React.createElement(Select, { defaultValue: oneByMoreData && oneByMoreData.length > 0
                        ? Number(oneByMoreData[0].storeId)
                        : props.storeList && props.storeList[0].id, onChange: function (value) { return setFieldValue('storeId', value); }, disabled: oneByMoreData && oneByMoreData.length > 0 }, props.storeList.map(function (store) { return (React.createElement(Option, { key: store.id, value: store.id },
                    store.name,
                    " - ",
                    store.numbering)); })),
                React.createElement(ErrorMessage, { component: CustomErrorMsg, name: "storeId" })),
            React.createElement(FormItem, { label: "\u9001\u8FBE\u65F6\u95F4", required: true, style: formItemStyles },
                React.createElement(Radio.Group, { defaultValue: 1, buttonStyle: "solid", onChange: function (_a) {
                        var value = _a.target.value;
                        setFieldValue('orderType', value);
                        if (value) {
                            setFieldValue('arrivalsTime', null);
                        }
                        else {
                            setFieldValue('arrivalsTime', moment().valueOf());
                        }
                    } },
                    React.createElement(Radio.Button, { value: 1 }, "\u7ACB\u5373\u9001\u51FA"),
                    React.createElement(Radio.Button, { value: 0 }, "\u9884\u7EA6\u9001\u8FBE"))),
            values.orderType === 0 ? (React.createElement(FormItem, { label: "\u9884\u7EA6\u65F6\u95F4", required: true, style: formItemStyles },
                React.createElement(ReservationTime, { onChange: function (value) {
                        setFieldValue('arrivalsTime', moment(value).valueOf());
                    } }),
                React.createElement(ErrorMessage, { component: CustomErrorMsg, name: "arrivalsTime" }))) : null,
            React.createElement(FormItem, { label: "\u5730\u5740", required: true, style: formItemStyles },
                React.createElement(Row, { gutter: 8 },
                    React.createElement(Col, { span: 16 },
                        React.createElement(PlaceGeoSearch, { ref: placeGeoSearchRef, region: getStoreRegion(oneByMoreData && oneByMoreData.length > 0
                                ? Number(oneByMoreData[0].storeId)
                                : values.storeId, props.storeList), errorMessage: function () {
                                message.error('请选择门店');
                            }, onChange: function (_a) {
                                var location = _a.location, name = _a.name;
                                setFieldValue('address', name);
                                setFieldValue('latitude', location.lat);
                                setFieldValue('longitude', location.lng);
                            } }),
                        React.createElement(ErrorMessage, { component: CustomErrorMsg, name: "address" })),
                    React.createElement(Col, { span: 8 },
                        React.createElement(Button, { type: "primary", style: { width: '100%' }, onClick: handleSmartButton }, "\u667A\u80FD\u5730\u5740\u586B\u5199")))),
            React.createElement(FormItem, { label: "\u95E8\u724C\u53F7", style: formItemStyles },
                React.createElement(Row, { gutter: 8 },
                    React.createElement(Col, { span: 16 },
                        React.createElement(Input, { placeholder: "\u95E8\u724C\u53F7", onChange: function (_a) {
                                var value = _a.target.value;
                                return setFieldValue('receiveAddressDetail', value);
                            }, value: values.receiveAddressDetail })))),
            React.createElement(FormItem, { label: "\u7535\u8BDD", required: true, style: formItemStyles },
                React.createElement(Row, { gutter: 8 },
                    React.createElement(Col, { span: 16 },
                        React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u7535\u8BDD", maxLength: 11, onChange: function (_a) {
                                var value = _a.target.value;
                                return setFieldValue('phone', value);
                            }, value: values.phone }),
                        React.createElement(ErrorMessage, { component: CustomErrorMsg, name: "phone" })),
                    React.createElement(Col, { span: 2, style: { textAlign: 'center' } }, "\u8F6C"),
                    React.createElement(Col, { span: 6 },
                        React.createElement(Input, { placeholder: "\u5206\u673A\u53F7", onChange: function (_a) {
                                var value = _a.target.value;
                                return setFieldValue('extensionTelephone', value);
                            } })))),
            React.createElement(FormItem, { label: "\u59D3\u540D", required: true, style: formItemStyles },
                React.createElement(Row, { gutter: 8 },
                    React.createElement(Col, { span: 16 },
                        React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u59D3\u540D", onChange: function (_a) {
                                var value = _a.target.value;
                                return setFieldValue('userName', value);
                            }, value: values.userName }),
                        React.createElement(ErrorMessage, { component: CustomErrorMsg, name: "userName" })))),
            React.createElement(FormItem, { label: "\u5907\u6CE8", style: formItemStyles },
                React.createElement(Input, { onChange: function (_a) {
                        var value = _a.target.value;
                        return setFieldValue('remarks', value);
                    } })),
            React.createElement(EditableFormTable, { columns: columns, dataSource: values.commodities, onChange: function (value) {
                    setFieldValue('commodities', value);
                } }),
            React.createElement(FormFooter, { confirmLoading: confirmLoading, onCancel: props.onCancel, onOk: submitForm }),
            React.createElement(SmartAddressModal, { visible: smartAddressVisible, toggleVisible: setSmartAddressVisible, onSubmit: function (params) { return handleSmartFormat(params); } })));
    }));
};
var templateObject_1;
