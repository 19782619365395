var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import ajax from '@utils/ajax';
import { StoreCode, CustomCode } from '../enums/codes';
import { FormType } from '../enums/receipt';
var defaultCustomText = '此处一般是填写商户地址、联系电话、二维码使用备注说明等';
export var customReceipt = {
    state: {
        formData: {},
        receiptSize: 0,
        store: [],
        basic: [],
        menu: [],
        fee: [],
        custom: [],
        receiptName: '',
        customText: '',
        customUrl: defaultCustomText,
        formFieldMap: {}
    },
    reducers: {
        setFormData: function (state, payload) {
            state.formData = payload;
        },
        resetData: function (state, payload) {
            state.receiptName = '';
            state.customText = defaultCustomText;
            state.customUrl = '';
            // 默认选中的表单数据
            state.formData.groups.forEach(function (group) {
                switch (group.code) {
                    case FormType.ReceiptSize:
                        state.receiptSize = group.receiptFields[0].code;
                        break;
                    case FormType.Store:
                        state.store = group.receiptFields
                            .map(function (field) { return field.code; })
                            .filter(function (code) { return code !== StoreCode.Logo; });
                        break;
                    case FormType.Basic:
                        state.basic = group.receiptFields.map(function (field) { return field.code; });
                        break;
                    case FormType.Menu:
                        state.menu = group.receiptFields.map(function (field) { return field.code; });
                        break;
                    case FormType.Fee:
                        state.fee = group.receiptFields.map(function (field) { return field.code; });
                        break;
                    case FormType.Custom:
                        state.custom = group.receiptFields
                            .map(function (field) { return field.code; })
                            .filter(function (code) { return code !== CustomCode.Image; });
                        break;
                }
            });
        },
        setFormFieldMap: function (state, payload) {
            state.formFieldMap = payload;
        },
        setReceiptSize: function (state, payload) {
            state.receiptSize = payload;
        },
        setStore: function (state, payload) {
            state.store = payload;
        },
        addToStore: function (state, payload) {
            var newStore = __spread(state.store);
            newStore.push(payload);
            state.store = newStore;
        },
        removeFromStore: function (state, payload) {
            var newStore = state.store.filter(function (item) { return item !== payload; });
            state.store = newStore;
        },
        addToCustom: function (state, payload) {
            var newCustom = __spread(state.custom);
            newCustom.push(payload);
            state.custom = newCustom;
        },
        removeFromCustom: function (state, payload) {
            var newCustom = state.custom.filter(function (item) { return item !== payload; });
            state.custom = newCustom;
        },
        setBasic: function (state, payload) {
            state.basic = payload;
        },
        setMenu: function (state, payload) {
            state.menu = payload;
        },
        setFee: function (state, payload) {
            state.fee = payload;
        },
        setCustom: function (state, payload) {
            state.custom = payload;
        },
        setReceiptName: function (state, payload) {
            state.receiptName = payload;
        },
        setCustomText: function (state, payload) {
            state.customText = payload;
        },
        setCustomUrl: function (state, payload) {
            state.customUrl = payload;
        },
        setEditPageData: function (state, payload) {
            var receiptSize = payload.receiptSize, receiptName = payload.receiptName, storeData = payload.storeData, basicData = payload.basicData, menuData = payload.menuData, feeData = payload.feeData, customData = payload.customData, customText = payload.customText, customUrl = payload.customUrl;
            state.receiptSize = receiptSize;
            state.receiptName = receiptName;
            state.store = storeData;
            state.basic = basicData;
            state.menu = menuData;
            state.fee = feeData;
            state.custom = customData;
            state.customText = customText;
            state.customUrl = customUrl;
        }
    },
    effects: function (dispatch) { return ({
        fetchFormData: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                var data, dataMap;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, ajax.get("/printer/receipt/dictionary")];
                        case 1:
                            data = (_a.sent()).data;
                            dispatch.customReceipt.setFormData(data);
                            dataMap = {};
                            data.groups.forEach(function (group) {
                                group.receiptFields.forEach(function (field) {
                                    dataMap[field.code] = field.name;
                                });
                            });
                            dispatch.customReceipt.setFormFieldMap(dataMap);
                            if (id)
                                return [2 /*return*/];
                            // 默认选中的表单数据
                            data.groups.forEach(function (group) {
                                switch (group.code) {
                                    case FormType.ReceiptSize:
                                        dispatch.customReceipt.setReceiptSize(group.receiptFields[0].code);
                                        break;
                                    case FormType.Store:
                                        dispatch.customReceipt.setStore(group.receiptFields
                                            .map(function (field) { return field.code; })
                                            .filter(function (code) { return code !== StoreCode.Logo; }));
                                        break;
                                    case FormType.Basic:
                                        dispatch.customReceipt.setBasic(group.receiptFields.map(function (field) { return field.code; }));
                                        break;
                                    case FormType.Menu:
                                        dispatch.customReceipt.setMenu(group.receiptFields.map(function (field) { return field.code; }));
                                        break;
                                    case FormType.Fee:
                                        dispatch.customReceipt.setFee(group.receiptFields.map(function (field) { return field.code; }));
                                        break;
                                    case FormType.Custom:
                                        dispatch.customReceipt.setCustom(group.receiptFields
                                            .map(function (field) { return field.code; })
                                            .filter(function (code) { return code !== CustomCode.Image; }));
                                        break;
                                }
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
    }); }
};
