var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { requestForOrder } from '@/utils/request';
import moment from 'moment';
var getUnixTime = function (time) {
    return moment(moment(time).format('YYYY-MM-DD')).valueOf();
};
function getParams(params) {
    if (params === void 0) { params = {}; }
    var queryParams = {};
    queryParams.beginTime = params.beginTime || getUnixTime(moment());
    queryParams.endTime = params.endTime || moment().unix() * 1000;
    queryParams.storeIds = params.storeId;
    if (params.orderPlatformId) {
        queryParams.platforms = params.orderPlatformId;
    }
    return queryParams;
}
export var reqOrderSummary = function (params) {
    if (params === void 0) { params = {}; }
    return requestForOrder.get('/api/report/orderIncomStatistics', getParams(params));
    // return axios
    //   .get('/orderSummary.json', {
    //     params
    //   })
    //   .then(res => res.data.data)
};
export var reqOrderChartData = function (params) {
    return requestForOrder.get('/api/report/orderTimeCoverage', getParams(params));
    // return axios
    //   .get('/orderLineChart.json', {
    //     params
    //   })
    //   .then(res => res.data.data)
};
export var reqChannelProportionData = function (params) {
    return requestForOrder.get('/api/report/platformRatioMap', getParams(params));
    // return axios
    //   .get('/proportion.json', {
    //     params
    //   })
    //   .then(res => res.data.data)
};
export var reqRankData = function (params) {
    return requestForOrder.get('/api/report/storeSaleSeniority', getParams(params));
    // return axios
    //   .get('/rank.json', {
    //     params
    //   })
    // .then(res => res.data.data)
};
export var reqOrderHeapMap = function (params) {
    if (params === void 0) { params = {}; }
    return requestForOrder.get('/api/report/orderHeatMap', __assign(__assign({}, getParams(params)), { code: params.code }));
    // return axios
    //   .get('/heatMap.json', {
    //     params
    //   })
    //   .then(res => res.data.data)
};
export var reqPlatformsByStoreId = function (storeId) {
    return requestForOrder.get('/api/report/queryPlatformsByStoreId', { storeId: storeId });
};
// 城市列表
export var reqCityListByCode = function (code) {
    if (code === void 0) { code = '0'; }
    return requestForOrder.get('/api/report/queryCitys');
    // return ajax.get(`/city/list/by/code?code=${code}`)
};
export var reqStoresByCityCode = function (code) {
    return requestForOrder.get("/api/report/queryStoresByCityCode?code=" + code);
};
