var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState, useRef } from 'react';
import qs from 'qs';
import ajax from '@utils/ajax';
import { message, Icon } from 'antd';
import { useLocation } from 'react-router-dom';
import logoFengNiao from '@assets/images/icon-fn.png';
import { Wrapper, Header, Logo, Title, Main, StoreList, Tips, Footer, SubmitButton } from './styled';
import { socketConnect } from '@/socket';
export default function FengNiao(props) {
    var _this = this;
    var location = useLocation();
    var yisongStoreId = useRef('');
    var _a = __read(useState(''), 2), fengniaoStoreId = _a[0], setFengniaoStoreId = _a[1];
    var _b = __read(useState([]), 2), fengniaoList = _b[0], setFengniaoList = _b[1];
    var fengniaoMerchantId = useRef('');
    var handleBind = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ajax.post('/fengniao/fengniaoAuthConfirm', {
                        yisongStoreId: yisongStoreId.current,
                        fengniaoStoreId: fengniaoStoreId,
                        fengniaoMerchantId: fengniaoMerchantId.current
                    })];
                case 1:
                    _a.sent();
                    message.success('绑定成功', 1);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        try {
            var searchStr = location.search.slice(1);
            var _a = qs.parse(searchStr), 
            // token,
            fengNiaoId = _a.fengniaoMerchantId, yisongMerchantId = _a.yisongMerchantId, storeId = _a.yisongStoreId;
            var token = decodeURIComponent(searchStr).split("&").filter(function (item) { return item.includes("token="); })[0].replace("token=", '');
            fengniaoMerchantId.current = fengNiaoId;
            yisongStoreId.current = storeId;
            window.orderSocket = socketConnect({ token: token });
            ajax.defaults.headers['Authorization'] = token;
            ajax.defaults.headers['token'] = token;
            ajax
                .post('/fengniao/queryFengniaoUnionStores', {
                fengniaoMerchantId: fengniaoMerchantId.current,
                yisongMerchantId: yisongMerchantId
            })
                .then(function (res) {
                var fengniaoStore = res.data.fengniaoStore;
                setFengniaoList(fengniaoStore);
            });
        }
        catch (ex) {
            message.error('请求参数不合法');
        }
    }, []);
    var renderStoreItem = function (item) {
        return (React.createElement("div", { className: "store-item", onClick: function () { return setFengniaoStoreId(item.chain_store_id); } },
            React.createElement("span", null,
                item.name,
                "\u00A0\u00A0",
                item.storeRemark),
            fengniaoStoreId === item.chain_store_id ? (React.createElement(Icon, { twoToneColor: "#0C9EFF", type: "check-circle", theme: "twoTone" })) : null));
    };
    return (React.createElement(Wrapper, null,
        React.createElement(Header, null,
            React.createElement(Logo, { src: logoFengNiao }),
            React.createElement(Title, null, "\u7ED1\u5B9A\u8702\u9E1F")),
        React.createElement(Main, null,
            React.createElement(Tips, null, "\u7CFB\u7EDF\u53D1\u73B0\u60A8\u5728\u8702\u9E1F\u5E73\u53F0\u4E0A\u6709\u591A\u4E2A\u95E8\u5E97\uFF0C\u8BF7\u9009\u62E9\u60A8\u8981\u7ED1\u5B9A\u7684\u5E97\u94FA"),
            React.createElement(StoreList, { dataSource: fengniaoList, renderItem: renderStoreItem, bordered: true }),
            React.createElement(Footer, null,
                React.createElement(SubmitButton, { type: "primary", onClick: handleBind, block: true }, "\u5B8C\u6210")))));
}
