var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Form, Radio, Select, Input } from 'antd';
import produce from 'immer';
import { requestForMerchant } from '@utils/request';
import { getItem } from '@utils/localStorage';
import ajax from '@utils/ajax';
import { storeIdValidator } from '@utils/validator';
import styled from 'styled-components';
import BindTipInfo from './BindTipInfo';
import { getPrinterListByStoreId } from '../PrinterManagement/BindPrintCase';
var createFormField = Form.createFormField;
var FormItem = Form.Item;
var Option = Select.Option;
var RadioGroup = Radio.Group;
var formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 }
};
var platformStatusMap = ['未绑定', '已绑定', '暂不支持'];
var getPlatformByStoreReq = function (storeId) {
    return ajax.get("/order/platform/list?storeId=" + storeId);
};
var BindPlatform = /** @class */ (function (_super) {
    __extends(BindPlatform, _super);
    function BindPlatform(props) {
        var _this = _super.call(this, props) || this;
        _this.handleStoreChange = function (id) {
            _this.getPlatformByStore(id);
            var getFieldValue = _this.props.form.getFieldValue;
            if (getFieldValue('platform') === 'meituan') {
                _this.isBindCloudPrinterFunc(id);
            }
        };
        _this.handlePlatformChange = function (val) {
            var _a = _this.props.form, setFieldsValue = _a.setFieldsValue, getFieldValue = _a.getFieldValue;
            _this.setState(produce(function (draft) {
                draft.isEbai = val === 'ebai';
                draft.meituanModal = val === 'meituan';
            }));
            if (val === 'meituan') {
                setFieldsValue({ mode: '3' });
                _this.isBindCloudPrinterFunc(getFieldValue('storeId'));
            }
            else {
                setFieldsValue({ mode: '1' });
            }
        };
        _this.state = {
            storeList: [],
            platformList: [],
            redirectUri: '',
            isEditMode: props.isEditMode || false,
            isEbai: false,
            meituanModal: false,
            listLoadding: false,
            bindCloudPrinter: false
        };
        return _this;
    }
    BindPlatform.prototype.componentDidMount = function () {
        var _this = this;
        var validateFields = this.props.form.validateFields;
        var lastChooseMerchant = getItem('lastChooseMerchant') && JSON.parse(getItem('lastChooseMerchant'));
        requestForMerchant
            .post("/merchant/store/getAllStoreByMerchantId", {
            merchantId: lastChooseMerchant.merchantId
        })
            .then(function (_a) {
            var data = _a.data;
            _this.setState(produce(function (draft) {
                draft.storeList = data;
            }), function () {
                validateFields(['storeId']);
            });
            var defaultStoreId = data[0] && data[0].id;
            if (defaultStoreId) {
                _this.getPlatformByStore(defaultStoreId);
            }
        });
    };
    BindPlatform.prototype.getPlatformByStore = function (storeId) {
        var _this = this;
        this.setState(produce(function (draft) {
            draft.listLoadding = true;
        }));
        getPlatformByStoreReq(storeId).then(function (_a) {
            var data = _a.data;
            var list = data.list, redirectUri = data.redirectUri;
            _this.setState(produce(function (draft) {
                draft.platformList = list;
                draft.redirectUri = redirectUri;
                draft.listLoadding = false;
            }));
        });
    };
    BindPlatform.prototype.renderStoreList = function () {
        return this.state.storeList.map(function (store) {
            return (React.createElement(Option, { key: store.id, value: store.id },
                store.name,
                " - ",
                store.numbering));
        });
    };
    BindPlatform.prototype.renderPlatformList = function () {
        return this.state.platformList.map(function (platform) {
            var disabled = platform.status > 0;
            var statusText = platform.status > 0 ? "- " + platformStatusMap[platform.status] : '';
            return (React.createElement(Option, { key: platform.platform, value: platform.platform, disabled: disabled },
                platform.name,
                " ",
                statusText));
        });
    };
    BindPlatform.prototype.render = function () {
        var state = this.state;
        var initialValue = this.props.initialValue;
        var _a = this.props.form, getFieldDecorator = _a.getFieldDecorator, setFieldsValue = _a.setFieldsValue, getFieldValue = _a.getFieldValue;
        return (React.createElement("div", null,
            React.createElement(Form, __assign({}, formItemLayout),
                !state.isEditMode ? (React.createElement(React.Fragment, null,
                    React.createElement(FormItem, { label: "\u9009\u62E9\u95E8\u5E97" }, getFieldDecorator('storeId', {
                        initialValue: state.storeList[0] ? state.storeList[0].id : '',
                        rules: [
                            {
                                required: true,
                                validator: storeIdValidator({ stores: state.storeList })
                            }
                        ]
                    })(React.createElement(Select, { onChange: this.handleStoreChange }, this.renderStoreList()))),
                    React.createElement(FormItem, { label: "\u9009\u62E9\u5E73\u53F0" }, getFieldDecorator('platform', {
                        rules: [
                            {
                                required: true,
                                message: '请选择需要绑定的平台'
                            }
                        ]
                    })(React.createElement(Select, { onChange: this.handlePlatformChange, loading: state.listLoadding }, this.renderPlatformList()))))) : null,
                !state.isEditMode && state.isEbai ? (React.createElement(React.Fragment, null,
                    React.createElement(FormItem, { label: "\u7ED1\u5B9A\u5E97\u94FA" }, getFieldDecorator('ebaiStoreId', {
                        rules: [
                            {
                                required: true,
                                message: '请输入饿了么零售店铺ID'
                            }
                        ]
                    })(React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u997F\u4E86\u4E48\u96F6\u552E\u5E97\u94FAID" }))))) : null,
                state.isEditMode ? (React.createElement(React.Fragment, null,
                    React.createElement(FormItem, { label: "\u5F53\u524D\u95E8\u5E97" },
                        initialValue.storeName,
                        ", \u95E8\u5E97\u7F16\u53F7\uFF1A",
                        initialValue.editStoreId),
                    React.createElement(FormItem, { label: "\u5E73\u53F0\u4FE1\u606F" },
                        "\u5E73\u53F0\u540D\u79F0\uFF1A",
                        initialValue.platformStoreName,
                        ",\u5E73\u53F0id\uFF1A",
                        initialValue.platformStoreId))) : null,
                state.meituanModal && (React.createElement(FormItem, { label: "\u7ED1\u5B9A\u65B9\u5F0F" }, getFieldDecorator('bind', {
                    initialValue: '2'
                })(React.createElement(RadioGroup, { onChange: function (v) {
                        setFieldsValue({ mode: v.target.value === '2' ? '3' : '2' });
                    } },
                    React.createElement(WrapDiv, null,
                        React.createElement(Radio, { value: "2" }, "\u6709\u6536\u94F6\u7CFB\u7EDF"),
                        "\uFF08\u5DF2\u7ED1\u5B9A\u6536\u94F6\u7CFB\u7EDF\uFF0C\u9700\u8981\u540C\u65F6\u4F7F\u7528\uFF09",
                        React.createElement("div", null, "\u2460\u53EF\u4EE5\u4E0E\u5176\u4ED6\u6536\u94F6\u7CFB\u7EDF\u540C\u65F6\u7ED1\u5B9A"),
                        React.createElement("div", null, "\u2461\u4E0D\u652F\u6301\u672C\u7CFB\u7EDF\u624B\u5DE5\u63A5\u5355\u548C\u81EA\u52A8\u63A5\u5355"),
                        React.createElement("div", null, "\u2462\u65E0\u5F00\u7968\u4FE1\u606F\u3001\u795D\u798F\u8BED\u5B57\u6BB5")))))),
                React.createElement(FormItem, { label: "\u63A5\u5355\u65B9\u5F0F" }, getFieldDecorator('mode', {
                    rules: [
                        {
                            required: true
                        }
                    ]
                })(
                // getFieldValue('bind') === '2' ||
                this.isCash() ? (React.createElement(RadioGroup, null,
                    React.createElement(Radio, { value: "3" }, "\u6536\u94F6\u7CFB\u7EDF\u63A5\u5355"))) : (React.createElement(RadioGroup, null,
                    React.createElement(Radio, { value: "1" }, "\u7CFB\u7EDF\u624B\u5DE5\u63A5\u5355"),
                    React.createElement(Radio, { value: "2" }, "\u7CFB\u7EDF\u81EA\u52A8\u63A5\u5355")))))),
            React.createElement(BindTipInfo, { isEbai: state.isEbai, isCash: this.isCash() })));
    };
    BindPlatform.prototype.isCash = function () {
        var getFieldValue = this.props.form.getFieldValue;
        return getFieldValue('mode') === '3';
    };
    BindPlatform.prototype.isBindCloudPrinterFunc = function (id) {
        var _this = this;
        getPrinterListByStoreId(id).then(function (res) {
            var arr = res.data.list.filter(function (item) { return item.type === 3; });
            _this.setState(produce(function (draft) {
                draft.bindCloudPrinter = arr.length > 0;
            }));
        });
    };
    return BindPlatform;
}(React.Component));
var WrapDiv = styled.div.withConfig({ displayName: "WrapDiv", componentId: "sc-m2nbo9" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 10px;\n  > div {\n    padding-left: 24px;\n    color: #777777;\n  }\n  a {\n    padding-left: 24px;\n    text-decoration: underline;\n  }\n"], ["\n  margin-top: 10px;\n  > div {\n    padding-left: 24px;\n    color: #777777;\n  }\n  a {\n    padding-left: 24px;\n    text-decoration: underline;\n  }\n"])));
var mapPropsToFields = function (props) {
    var initialValue = props.initialValue;
    return {
        mode: createFormField({
            value: initialValue.mode
        })
    };
};
var WrappedBindPlatform = Form.create({ mapPropsToFields: mapPropsToFields })(BindPlatform);
export default WrappedBindPlatform;
var templateObject_1;
