var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { productTypes } from '@/utils/data';
import React from 'react';
import ReceiptSeperator from '../../components/Seperator';
import { Table } from './styled';
export var transformFullConsigneePhone = function (consigneePhone) {
    var phone = consigneePhone;
    try {
        phone = JSON.parse(consigneePhone);
    }
    catch (error) {
        phone = consigneePhone.replace(/,|_|，/, '转');
    }
    if (Array.isArray(phone) && phone.length) {
        var _a = __read(phone, 2), part1 = _a[0], part2 = _a[1];
        var parts = part1.split(',');
        if (parts.length === 1) {
            // return [`${part1}${part2 ? '转' + part2 : ''}`]
            return "" + part1 + (part2 ? '转' + part2 : '');
        }
        else {
            // return [`${parts[0]}转${parts[1]}`, part2]
            if (part2) {
                return part2.replace(/,|_|，/, '转');
            }
            else {
                return (part1 && part1.replace(/,|_|，/, '转')) || '';
            }
        }
    }
    else {
        return phone;
    }
};
export var renderGoods = function (goods, groupGoodsList, platform) {
    if (groupGoodsList === void 0) { groupGoodsList = []; }
    function getName(good) {
        var _a, _b, _c;
        if (platform === 'manual') {
            var _d = __read(good.name.split(','), 2), productType_1 = _d[0], weight = _d[1];
            var productTypeText = (_a = productTypes.find(function (t) { return t.value === productType_1; })) === null || _a === void 0 ? void 0 : _a.label;
            return productTypeText + "," + weight + "\u516C\u65A4\u5185";
        }
        var name = good.name;
        if ((_b = good.norm) === null || _b === void 0 ? void 0 : _b.length) {
            name += "\u3010" + good.norm + "\u3011";
        }
        if ((_c = good.property) === null || _c === void 0 ? void 0 : _c.length) {
            name += "\u3010" + good.property + "\u3011";
        }
        return name;
    }
    if (groupGoodsList.length) {
        return (React.createElement(Table, null,
            React.createElement("div", { className: "title" },
                React.createElement("span", { className: "name" }, "\u540D\u79F0"),
                React.createElement("span", { className: "count" }, "\u6570\u91CF"),
                React.createElement("span", { className: "money" }, "\u91D1\u989D")),
            React.createElement("div", { className: "body" }, groupGoodsList.map(function (eachGroup, index) {
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "line" },
                        React.createElement(ReceiptSeperator, null, eachGroup.groupName)),
                    eachGroup.groupGoods.map(function (good, index) {
                        var name = getName(good);
                        var bigger = name.length > 20;
                        return (React.createElement("div", { className: "row", key: index },
                            React.createElement("div", { className: "name", style: { width: bigger ? 'auto' : '' } }, getName(good)),
                            React.createElement("div", { style: {
                                    marginLeft: '230px',
                                    marginTop: !bigger ? '-22px' : '0'
                                } },
                                React.createElement("span", { className: "quantity" },
                                    "x",
                                    good.quantity),
                                React.createElement("span", { className: "price" }, good.price))));
                    })));
            })))
        // <table>
        //   <thead>
        //     <tr>
        //       <th style={{ width: '150px' }}>名称</th>
        //       <th>数量</th>
        //       <th>金额</th>
        //     </tr>
        //   </thead>
        //   <tbody>
        //     {groupGoodsList.map((eachGroup, index) => {
        //       return (
        //         <>
        //           <tr>
        //             <td colSpan={3} align="center" height={50}>
        //               <ReceiptSeperator>{eachGroup.groupName}</ReceiptSeperator>
        //             </td>
        //           </tr>
        //           {eachGroup.groupGoods.map((good: any, index: number) => {
        //             return (
        //               <tr key={index}>
        //                 <td>{getName(good)}</td>
        //                 <td>x{good.quantity}</td>
        //                 <td>{good.price}</td>
        //               </tr>
        //             )
        //           })}
        //         </>
        //       )
        //     })}
        //   </tbody>
        // </table>
        );
    }
    else {
        return (React.createElement("table", null,
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { style: { width: '220px' } }, "\u540D\u79F0"),
                    React.createElement("th", null, "\u6570\u91CF"),
                    React.createElement("th", null, "\u91D1\u989D"))),
            React.createElement("tbody", null, goods === null || goods === void 0 ? void 0 : goods.map(function (good, index) {
                return (React.createElement("tr", { key: index },
                    React.createElement("td", null, getName(good)),
                    React.createElement("td", { style: { textIndent: '7px' } },
                        "x",
                        good.quantity),
                    React.createElement("td", { style: { textIndent: '7px' } }, good.price)));
            }))));
    }
};
var getReasonText = function (_a) {
    var _b, _c;
    var cancelOrder = _a.cancelOrder, retreatOrder = _a.retreatOrder;
    if ((_b = cancelOrder.userReason) === null || _b === void 0 ? void 0 : _b.trim()) {
        return __assign({}, cancelOrder);
    }
    if ((_c = retreatOrder.userReason) === null || _c === void 0 ? void 0 : _c.trim()) {
        return __assign({}, retreatOrder);
    }
    return {
        status: 0,
        userReason: '',
        merchantReason: '',
        tripartiteReason: ''
    };
};
export var renderOther = function (order) {
    var _a = order.cancelOrder, cancelOrder = _a === void 0 ? {} : _a, _b = order.retreatOrder, retreatOrder = _b === void 0 ? {} : _b;
    var texts = [];
    if (getReasonText({ cancelOrder: cancelOrder, retreatOrder: retreatOrder }).userReason) {
        texts.push(React.createElement("p", { className: "reason-text", key: 1 }, getReasonText({ cancelOrder: cancelOrder, retreatOrder: retreatOrder }).userReason));
    }
    if (getReasonText({ cancelOrder: cancelOrder, retreatOrder: retreatOrder }).merchantReason) {
        texts.push(React.createElement("p", { className: "reason-text", key: 2 }, getReasonText({ cancelOrder: cancelOrder, retreatOrder: retreatOrder }).merchantReason));
    }
    if (getReasonText({ cancelOrder: cancelOrder, retreatOrder: retreatOrder }).tripartiteReason) {
        texts.push(React.createElement("p", { className: "reason-text", key: 3 }, getReasonText({ cancelOrder: cancelOrder, retreatOrder: retreatOrder }).tripartiteReason));
    }
    return texts;
};
