var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * @Author: hijack
 * @Date: 2021-06-03 15:48:33
 * @LastEditTime: 2021-06-30 20:06:46
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/utils/axios.ts
 */
import axios from 'axios';
import { message } from 'antd';
import { userLogoutAction } from '@models';
import { store } from '@store';
var HttpRequest = /** @class */ (function () {
    function HttpRequest(baseUrl) {
        this.baseUrl = baseUrl;
        this.baseUrl = baseUrl;
    }
    HttpRequest.prototype.getDefaultConfig = function () {
        var defaultConfig = {
            headers: {
                clientId: 'web',
                Authorization: localStorage.getItem('token'),
                token: localStorage.getItem('token')
            },
            externalConfig: {
                showError: true
            }
        };
        return defaultConfig;
    };
    HttpRequest.prototype.intercepters = function (axios, externalConfig) {
        axios.interceptors.request.use(function (config) {
            return config;
        });
        axios.interceptors.response.use(function (response) {
            var data = response.data;
            var requestData = {};
            try {
                requestData = JSON.parse(response.config.data || '{}');
            }
            catch (error) {
                console.log(error);
            }
            var handleError = requestData['handleError'];
            if (data.code !== 0 && handleError) {
                // Modal.error({
                //   title: '异常',
                //   content: data.msg
                // })
                return Promise.reject(data);
            }
            switch (data.code) {
                case 1:
                case 500:
                    if (externalConfig && externalConfig.showError) {
                        message.error(data.msg);
                    }
                    return Promise.reject(data);
                case 10000:
                case 100099:
                    message.error(data.msg);
                    if (location.pathname.indexOf('/bind/') === -1) {
                        store.dispatch(userLogoutAction());
                    }
                    return Promise.reject(data);
                default:
                    return data;
            }
        }, function (error) {
            message.error('网络错误');
            return Promise.reject(error);
        });
    };
    HttpRequest.prototype.request = function (config) {
        var defaultConfig = this.getDefaultConfig();
        var _axios = axios.create(__assign(__assign({ baseURL: this.baseUrl }, defaultConfig), config));
        var external = __assign({}, defaultConfig.externalConfig);
        if (config && config.externalConfig) {
            external = __assign(__assign({}, defaultConfig.externalConfig), config.externalConfig);
        }
        this.intercepters(_axios, external);
        return _axios.request(config);
    };
    HttpRequest.prototype.get = function (url, data, externalConfig) {
        if (externalConfig === void 0) { externalConfig = {}; }
        return this.request({
            method: 'get',
            url: url,
            params: data,
            externalConfig: externalConfig
        });
    };
    HttpRequest.prototype.post = function (url, data, externalConfig) {
        if (externalConfig === void 0) { externalConfig = {}; }
        return this.request({
            method: 'post',
            url: url,
            data: data,
            externalConfig: externalConfig
        });
    };
    HttpRequest.prototype.upload = function (url, formData) {
        return this.request({
            url: url,
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: formData
        });
    };
    return HttpRequest;
}());
export default HttpRequest;
