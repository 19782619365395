var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { message } from 'antd';
import HttpRequest from '@utils/axios';
import ajax from '@utils/ajax';
import { getBaseUrl } from '@utils/apiConfig';
import { isElectron } from '@utils/env';
import { removeUserInfo, setUserInfo, userInfo } from '@utils/localStorage';
import { socketConnect } from '../socket';
var request = new HttpRequest(getBaseUrl('merchant'));
var requestForNewWeb = new HttpRequest('');
export var userLogoutAction = function () { return ({ type: 'user/logout' }); };
export var user = {
    state: {
        userName: userInfo.userName,
        token: userInfo.token
    },
    reducers: {
        set: function (state, payload) {
            state.userName = payload.userName;
            state.token = payload.token;
            return state;
        }
    },
    effects: function (dispatch) { return ({
        loginWithPassword: function (_a) {
            var phone = _a.phone, password = _a.password, saveAccountCheck = _a.saveAccountCheck, setLoginDone = _a.setLoginDone, handleMerchantList = _a.handleMerchantList;
            return __awaiter(this, void 0, void 0, function () {
                var _b, data, code, err, config, error_1;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _c.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, request.post('/merchant/account/loginByPassword', {
                                    mobile: phone,
                                    password: password
                                })];
                        case 1:
                            _b = _c.sent(), data = _b.data, code = _b.code, err = _b.err;
                            if (code !== 0) {
                                message.error(err);
                                setLoginDone();
                                return [2 /*return*/, false];
                            }
                            ajax.defaults.headers.Authorization = data.token;
                            setUserInfo(__assign({}, data));
                            dispatch.user.set(__assign({}, data));
                            handleMerchantList();
                            // await message.success('登录成功')
                            if (isElectron) {
                                config = require('../config').default;
                                if (saveAccountCheck) {
                                    config.set('account', {
                                        phone: phone,
                                        password: password
                                    });
                                }
                                else {
                                    config.set('account', {
                                        phone: '',
                                        password: ''
                                    });
                                }
                            }
                            if (Notification.permission === 'default') {
                                Notification.requestPermission();
                            }
                            window.orderSocket = socketConnect({ token: data.token });
                            return [3 /*break*/, 3];
                        case 2:
                            error_1 = _c.sent();
                            setLoginDone();
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        loginWithSMS: function (_a) {
            var phone = _a.phone, verificationCode = _a.verificationCode, setLoginDone = _a.setLoginDone, handleMerchantList = _a.handleMerchantList;
            return __awaiter(this, void 0, void 0, function () {
                var _b, data, code, err, error_2;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _c.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, request.post('/merchant/account/loginBySmsCode', {
                                    mobile: phone,
                                    smsCode: verificationCode
                                })];
                        case 1:
                            _b = _c.sent(), data = _b.data, code = _b.code, err = _b.err;
                            if (code !== 0) {
                                message.error(err);
                                setLoginDone();
                                return [2 /*return*/, false];
                            }
                            ajax.defaults.headers.Authorization = data.token;
                            setUserInfo(__assign({}, data));
                            dispatch.user.set(__assign({}, data));
                            handleMerchantList();
                            // await message.success('登录成功')
                            if (Notification.permission === 'default') {
                                Notification.requestPermission();
                            }
                            window.orderSocket = socketConnect({ token: data.token });
                            return [3 /*break*/, 3];
                        case 2:
                            error_2 = _c.sent();
                            setLoginDone();
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
        logout: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    dispatch.user.set({ userName: '', token: '' });
                    removeUserInfo();
                    return [2 /*return*/];
                });
            });
        },
        // 新用户还是老用户
        getAccountStatus: function (phone) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, requestForNewWeb
                            .get('/merchantapi/applogin/userType', { phone: phone })
                            .then(function (res) {
                            if (res.code === 0) {
                                if (res.data === 2) {
                                    return true;
                                }
                            }
                            else {
                                return false;
                            }
                        })];
                });
            });
        }
    }); }
};
