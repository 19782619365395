var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useImperativeHandle } from 'react';
import CommonHeader from '@/components/ModalHeader/commonModalHeader';
import './index.less';
import { Button, Modal } from 'antd4';
import { requestForOrder } from '@/utils/request';
import { useHistory } from 'react-router-dom';
var UnbindTip = function (_, ref) {
    var history = useHistory();
    var _a = __read(React.useState([]), 2), data = _a[0], setData = _a[1];
    var _b = __read(React.useState(false), 2), visible = _b[0], setVisible = _b[1];
    var _c = __read(React.useState('calc(50% - 301px)'), 2), modalStyle = _c[0], setModalStyle = _c[1];
    var showModal = function (dataParam) {
        setVisible(true);
        var param = Array.isArray(dataParam) ? dataParam : [dataParam];
        setData(param);
        if (param.length === 1) {
            setModalStyle('calc(50% - 180px)');
        }
    };
    var handleCancel = function () {
        requestForOrder.post("/api/message/meituan/closeUnbindMsg").then(function (res) {
            setVisible(false);
        });
    };
    useImperativeHandle(ref, function () { return showModal; });
    return (React.createElement("div", null, visible &&
        React.createElement(Modal, { closable: false, visible: true, footer: null, className: 'commonModal unbindTip', style: { top: modalStyle } },
            React.createElement(CommonHeader, { title: "解绑提醒", onCancel: function () { handleCancel(); } }),
            React.createElement("div", { className: "wrapUnbindContent" },
                React.createElement("div", null, data.map(function (item, index) {
                    return (React.createElement("div", { className: 'unbindContent', key: index },
                        React.createElement("h3", null, "\u7F8E\u56E2\u89E3\u7ED1\u901A\u77E5"),
                        React.createElement("div", { className: 'info' },
                            React.createElement("p", null,
                                React.createElement("i", null, "\u5E73\u53F0\u95E8\u5E97\u540D\u79F0\uFF1A"),
                                React.createElement("span", null, item.platformStoreName)),
                            React.createElement("p", null,
                                React.createElement("i", null, "\u5E73\u53F0\u95E8\u5E97\u7F16\u53F7\uFF1A"),
                                React.createElement("span", null, item.storeNum)),
                            React.createElement("p", null,
                                React.createElement("i", null, "\u89E3\u7ED1\u65F6\u95F4\uFF1A"),
                                React.createElement("span", null, item.cancelTime))),
                        React.createElement(Button, { type: 'primary', onClick: function () {
                                requestForOrder.post("/api/message/meituan/closeUnbindMsg", {
                                    storeId: item.yisongStoreId
                                }).then(function () {
                                    history.push('/stores/order_platform');
                                });
                            } }, "\u53BB\u7ED1\u5B9A"),
                        React.createElement("div", { className: 'tip' }, "\u5982\u4F55\u7ED1\u5B9A\uFF1A\u70B9\u51FB\u3010\u53BB\u7ED1\u5B9A\u3011\uFF0C\u8F93\u5165\u7F8E\u56E2\u8D26\u53F7\u5BC6\u7801\u5373\u53EF\u7ED1\u5B9A\u3002")));
                }))))));
};
export default React.forwardRef(UnbindTip);
