var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import '@/container/Orders/action/button.less';
var PRINT_BTN_COLOR = '#2c98ff';
var CONFIRM_BTN_COLOR = '#01cccc';
var CANCEL_BTN_COLOR = '#F5222D';
var BaseActionButton = styled(Button).withConfig({ displayName: "BaseActionButton", componentId: "sc-gvlalu" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  && {\n    background-color: #f6f8fa;\n    /* border-color: ", "; */\n    border-radius: 22px;\n    color: #333333;\n    /* &:active,\n    &:hover,\n    &:focus {\n       background-color: ", ";\n      border-color: ", ";\n      color: #fff; \n    } */\n  }\n"], ["\n  && {\n    background-color: #f6f8fa;\n    /* border-color: ", "; */\n    border-radius: 22px;\n    color: #333333;\n    /* &:active,\n    &:hover,\n    &:focus {\n       background-color: ", ";\n      border-color: ", ";\n      color: #fff; \n    } */\n  }\n"])), function (props) { return props.color; }, function (props) { return props.color; }, function (props) { return props.color; });
export var PrintButton = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(Button, __assign({}, props, { color: PRINT_BTN_COLOR, type: "primary", className: "orderbutton" }), children));
};
export var ConfirmButton = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(Button, __assign({}, props, { color: CONFIRM_BTN_COLOR, type: "primary", className: "orderbutton" }), children));
};
export var CancelButton = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(Button, __assign({}, props, { color: CANCEL_BTN_COLOR, type: "primary", className: "orderbutton" }), children));
};
export var CreateOrderMap = styled.div.withConfig({ displayName: "CreateOrderMap", componentId: "sc-16nrri" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 137px;\n  height: 36px;\n  background: #ffffff;\n  border-radius: 22px;\n  font-size: 14px;\n  font-family: PingFangSC-Regular, PingFang SC;\n  font-weight: 400;\n  color: #01cccc;\n  line-height: 36px;\n  text-align: center;\n  :hover {\n    color: rgba(1, 204, 204, 1);\n    background: rgba(229, 249, 249, 1);\n  }\n"], ["\n  width: 137px;\n  height: 36px;\n  background: #ffffff;\n  border-radius: 22px;\n  font-size: 14px;\n  font-family: PingFangSC-Regular, PingFang SC;\n  font-weight: 400;\n  color: #01cccc;\n  line-height: 36px;\n  text-align: center;\n  :hover {\n    color: rgba(1, 204, 204, 1);\n    background: rgba(229, 249, 249, 1);\n  }\n"])));
var ShortcutButton = styled(Button).withConfig({ displayName: "ShortcutButton", componentId: "sc-1dtklux" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  && {\n    margin-right: 10px;\n    background-color: #01cccc;\n    border-radius: 22px;\n    height: 40px;\n    width: 110px;\n    color: #fff;\n    &:active,\n    &:hover,\n    &:focus {\n      background-color: #01c0c0;\n      border-color: #01c0c0;\n      color: #fff;\n    }\n  }\n"], ["\n  && {\n    margin-right: 10px;\n    background-color: #01cccc;\n    border-radius: 22px;\n    height: 40px;\n    width: 110px;\n    color: #fff;\n    &:active,\n    &:hover,\n    &:focus {\n      background-color: #01c0c0;\n      border-color: #01c0c0;\n      color: #fff;\n    }\n  }\n"])));
export var ScanBarCodeToSendButton = ShortcutButton;
export var CreateOrderButton = ShortcutButton;
var templateObject_1, templateObject_2, templateObject_3;
