import React from 'react';
import { ReceiptWrapper, PreviewRow, RectiptTop, Preview1, BoldRow, PreviewGoods, Fee1, Fee2, BigRow, Seperator } from './styled';
import defaultReceiptData from '../../utils/receiptData';
import { defaultFormat } from '../../utils/time';
import { ORDER_PLATFORM_TEXT_MAP, ORDER_PLATFORM_MAP } from '../../utils/constants';
import ReceiptSeperator from '../Seperator';
var Receipt = function (props) {
    var _a;
    var data = (_a = props.data) !== null && _a !== void 0 ? _a : defaultReceiptData;
    var renderPlatform = function () {
        var text = '';
        if (data.platform) {
            text += ORDER_PLATFORM_TEXT_MAP[data.platform];
        }
        if (data.platformDaySn) {
            text += " #" + data.platformDaySn + " ";
        }
        if (text) {
            text = "\u3010" + text + "\u3011";
        }
        if (data.isPreOrder || data.reservationOrder === 0) {
            text += '【预订单】';
        }
        if (text) {
            return React.createElement("h4", { className: "receipt-type" }, text);
        }
        return null;
    };
    var renderDeliverTime = function () {
        var _a;
        if (data.platform === ORDER_PLATFORM_MAP.EBAI &&
            data.reservationStartTime) {
            return (React.createElement(BigRow, null,
                "\u9001\u8FBE\u65F6\u95F4\uFF1A",
                data.deliverTime,
                "\u00A0\u5230\u00A0",
                data.reservationStartTime));
        }
        else if (((_a = data.selfFetch) === null || _a === void 0 ? void 0 : _a.code) === 0) {
            return React.createElement(BigRow, null,
                "\u5230\u5E97\u81EA\u53D6\uFF1A",
                data.deliverTime);
        }
        else {
            return React.createElement(BigRow, null,
                "\u9001\u8FBE\u65F6\u95F4\uFF1A",
                data.deliverTime);
        }
    };
    return (React.createElement(ReceiptWrapper, null,
        React.createElement(RectiptTop, null,
            React.createElement("h4", { className: "store-name" }, data.storeName),
            React.createElement("h4", { className: "receipt-type" }, data.receiptName || '商户联'),
            renderPlatform()),
        React.createElement(Preview1, null,
            data.platformId && (React.createElement(PreviewRow, null,
                "\u5E73\u53F0\u8BA2\u5355\u53F7\uFF1A",
                data.platformId)),
            data.daySn && React.createElement(PreviewRow, null,
                "\u6613\u9001\u6D41\u6C34\u53F7\uFF1A",
                data.daySn),
            data.orderTime && (React.createElement(PreviewRow, null,
                "\u4E0B\u5355\u65F6\u95F4\uFF1A",
                defaultFormat(data.orderTime))),
            React.createElement(Seperator, null),
            data.deliverTime && renderDeliverTime(),
            data.description && React.createElement(BoldRow, null,
                "\u5907\u6CE8\uFF1A",
                data.description),
            data.booker && React.createElement(BoldRow, null,
                "\u9884\u8BA2\u4EBA\uFF1A",
                data.booker),
            data.bless && React.createElement(BoldRow, null,
                "\u795D\u798F\u8BED\uFF1A",
                data.bless),
            React.createElement(Seperator, null),
            data.invoiceId && React.createElement(PreviewRow, null, data.invoiceTitle)),
        React.createElement(PreviewGoods, null, props.renderGoods && props.renderGoods),
        data.platform !== 'manual' && (React.createElement(React.Fragment, null,
            React.createElement(Fee1, null,
                React.createElement(ReceiptSeperator, null, "\u5176\u4ED6"),
                data.activities &&
                    data.activities.map(function (str, index) {
                        return React.createElement(PreviewRow, { key: index },
                            "[",
                            str,
                            "]");
                    }),
                data.packageFee && (React.createElement(PreviewRow, null,
                    "\u9910\u76D2\u8D39\uFF1A",
                    data.packageFee,
                    "\u5143")),
                data.deliverFee && (React.createElement(PreviewRow, null,
                    "\u914D\u9001\u8D39\uFF1A",
                    data.deliverFee,
                    "\u5143"))),
            React.createElement(Fee2, null,
                data.originalPrice && (React.createElement(PreviewRow, null,
                    "\u539F\u4EF7\uFF1A",
                    data.originalPrice,
                    "\u5143")),
                data.reducedPrice && (React.createElement(PreviewRow, null,
                    "\u603B\u4F18\u60E0\u91D1\u989D\uFF1A",
                    data.reducedPrice,
                    "\u5143")),
                data.totalPrice && (React.createElement(PreviewRow, null,
                    "\u5728\u7EBF\u652F\u4ED8\uFF1A",
                    data.totalPrice,
                    "\u5143"))))),
        data.consigneeAddress && React.createElement(BigRow, null,
            "\u5730\u5740 \uFF1A",
            data.consigneeAddress),
        React.createElement(BigRow, null,
            data.consigneePhone && data.consigneePhone,
            "\u00A0\u00A0",
            data.consignee && data.consignee),
        React.createElement(Seperator, { style: { marginTop: '20px' } },
            React.createElement("span", null, "\u672C\u5355\u5B8C")),
        props.renderOther && props.renderOther,
        props.renderCustomInfo && props.renderCustomInfo));
};
export default Receipt;
