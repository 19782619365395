var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import produce from 'immer';
import { has, isEqual } from 'lodash';
import FormModal from '@components/FormModal';
import { getOAuthUri } from '@utils/bindPlatform';
import BindPlatform from './BindPlatform';
import columns from './columns';
import { BindOrderPlatformEvents } from './event';
import { OrderPlatformTable } from './styled';
import { sendPv } from '@/utils/umeng';
var BindOrderPlatform = /** @class */ (function (_super) {
    __extends(BindOrderPlatform, _super);
    function BindOrderPlatform() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            bindPlatformVisible: false,
            isEditMode: false,
            editMode: '1',
            editStoreName: '',
            editStoreId: 0,
            platformStoreId: '',
            platformStoreName: '',
            platform: '',
            businessId: ''
        };
        _this.editId = '';
        _this.checkBindDoneTimer = 0;
        _this.checkReleaseDoneTimer = 0;
        _this.handleModeEdit = function (_a) {
            var id = _a.id, mode = _a.mode, store = _a.store, rest = __rest(_a, ["id", "mode", "store"]);
            _this.editId = id;
            _this.setState(produce(function (draft) {
                draft.isEditMode = true;
                draft.bindPlatformVisible = true;
                draft.editMode = mode;
                draft.editStoreName = store.name;
                draft.editStoreId = store.numbering;
                draft.platformStoreId = store.platformStoreId;
                draft.platformStoreName = store.platformStoreName;
                draft.platform = rest.platform;
                draft.businessId = rest.businessId;
            }));
        };
        _this.handleDelete = function (record) {
            var _a = _this.props, deleteOrderPlatform = _a.deleteOrderPlatform, getOrderPlatform = _a.getOrderPlatform;
            deleteOrderPlatform(record.id).then(function (_a) {
                var data = _a.data;
                if (has(data, 'platformDataVO.url')) {
                    if (_this.releaseTab == null || _this.releaseTab.closed) {
                        _this.releaseTab = window.open(data.platformDataVO.url);
                    }
                    else {
                        _this.releaseTab.focus();
                    }
                    _this.checkReleaseDoneTimer = window.setInterval(function () {
                        if (_this.releaseTab.closed) {
                            getOrderPlatform({});
                            clearInterval(_this.checkReleaseDoneTimer);
                        }
                    }, 1000);
                }
                else {
                    getOrderPlatform({});
                    message.success('接单平台解绑成功');
                }
            });
        };
        _this.togglePlatformBind = function (isEditMode) {
            if (isEditMode === void 0) { isEditMode = false; }
            _this.setState(produce(function (draft) {
                draft.bindPlatformVisible = !_this.state.bindPlatformVisible;
                draft.isEditMode = isEditMode;
            }));
        };
        _this.submitBindPlatform = function () {
            var editBindForm = _this.editBindForm.props.form;
            var _a = _this.editBindForm.state, redirectUri = _a.redirectUri, platformList = _a.platformList;
            var isEditMode = _this.state.isEditMode;
            var _b = _this.props, setOrderPlatformMode = _b.setOrderPlatformMode, user = _b.user, getOrderPlatform = _b.getOrderPlatform;
            editBindForm.validateFields(function (errors, values) {
                if (errors)
                    return false;
                if (isEditMode) {
                    setOrderPlatformMode({
                        id: _this.editId,
                        mode: values.mode
                    });
                    _this.togglePlatformBind();
                    return false;
                }
                _this.bindPlatformData = values;
                var storeId = values.storeId, platform = values.platform, mode = values.mode, ebaiStoreId = values.ebaiStoreId;
                var platformData = platformList.filter(function (_a) {
                    var platform = _a.platform;
                    return platform === values.platform;
                });
                var authInfo = platformData[0].privateData;
                if (!Object.keys(authInfo).length) {
                    return message.error('缺少绑定应用数据');
                }
                var OAuthUri = getOAuthUri({
                    redirectUri: redirectUri,
                    platform: platform,
                    authInfo: authInfo,
                    state: {
                        storeId: storeId,
                        mode: mode,
                        user: user.token,
                        // ebaiStoreName,
                        ebaiStoreId: ebaiStoreId
                    },
                    isCash: values.bind === '2'
                });
                _this.togglePlatformBind();
                if (_this.OAuthTab == null || _this.OAuthTab.closed) {
                    _this.OAuthTab = window.open(OAuthUri);
                }
                else {
                    _this.OAuthTab.focus();
                }
                _this.checkBindDoneTimer = window.setInterval(function () {
                    if (_this.OAuthTab.closed) {
                        getOrderPlatform({});
                        clearInterval(_this.checkBindDoneTimer);
                    }
                }, 1000);
            });
        };
        _this.handleTableChange = function (pagination) {
            _this.props.getOrderPlatform({
                page: pagination.current,
                pageSize: pagination.pageSize
            });
        };
        return _this;
    }
    BindOrderPlatform.prototype.shouldComponentUpdate = function (nextProps, nextState) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    };
    BindOrderPlatform.prototype.componentDidMount = function () {
        var _this = this;
        this.props.getOrderPlatform({});
        BindOrderPlatformEvents.onSuccess(function () {
            _this.props.getOrderPlatform({});
            if (_this.OAuthTab && !_this.OAuthTab.closed) {
                _this.OAuthTab.close();
            }
        });
        sendPv();
    };
    BindOrderPlatform.prototype.componentWillUnmount = function () {
        clearInterval(this.checkBindDoneTimer);
        clearInterval(this.checkReleaseDoneTimer);
    };
    BindOrderPlatform.prototype.render = function () {
        var _this = this;
        var state = this.state;
        var props = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { type: "primary", onClick: function () { return _this.togglePlatformBind(); } }, "\u7ED1\u5B9A\u63A5\u5355\u5E73\u53F0"),
            React.createElement(FormModal, { title: state.isEditMode ? '修改接单方式' : '绑定接单平台', visible: state.bindPlatformVisible, onCancel: function () { return _this.togglePlatformBind(); }, onOk: function () {
                    if (state.isEditMode && state.businessId === '16') {
                        return _this.togglePlatformBind();
                    }
                    _this.submitBindPlatform();
                }, destroyOnClose: true, okText: state.isEditMode ? '保存' : '下一步' },
                React.createElement(BindPlatform, { wrappedComponentRef: function (form) { return (_this.editBindForm = form); }, isEditMode: state.isEditMode, history: this.props.history, initialValue: {
                        mode: state.editMode,
                        storeName: state.editStoreName,
                        editStoreId: state.editStoreId,
                        platformStoreName: state.platformStoreName,
                        platformStoreId: state.platformStoreId,
                        platform: state.platform,
                        businessId: state.businessId
                    } })),
            React.createElement(OrderPlatformTable, { columns: columns(this), dataSource: props.dataSource, pagination: props.pagination, loading: props.listLoading, onChange: this.handleTableChange, rowKey: "id", bordered: true })));
    };
    return BindOrderPlatform;
}(React.Component));
export { BindOrderPlatform };
var mapState = function (state) {
    var _a = state.orderPlatforms, data = _a.data, loading = _a.loading;
    return {
        dataSource: data.list,
        pagination: {
            current: data.page,
            pageSize: data.pageSize,
            total: data.total
        },
        listLoading: loading,
        user: state.user
    };
};
var mapDispatch = function (dispatch) { return ({
    getOrderPlatform: dispatch.orderPlatforms.fetchData,
    setOrderPlatformMode: dispatch.orderPlatforms.setPlatformMode,
    deleteOrderPlatform: dispatch.orderPlatforms.deleteItemWithReq
}); };
export default connect(mapState, mapDispatch)(BindOrderPlatform);
