/*
 * @Author: hijack
 * @Date: 2021-06-10 16:17:34
 * @LastEditTime: 2021-07-08 14:04:17
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/utils/request.ts
 */
import { getBaseUrl } from './apiConfig';
import HttpRequest from './axios';
export var requestForLocation = new HttpRequest(getBaseUrl('oldService'));
export var requestForMerchant = new HttpRequest(getBaseUrl('merchant'));
export var requestForDelivery = new HttpRequest(getBaseUrl('delivery'));
export var requestForOrder = new HttpRequest(getBaseUrl('order'));
export var requestForUpload = new HttpRequest(getBaseUrl('upload'));
export var requestForCoupon = new HttpRequest(getBaseUrl('coupon'));
export var requestForMoney = new HttpRequest(getBaseUrl('money'));
