var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import './index.less';
import { requestForMerchant } from '@utils/request';
import { currentTimeWithDay } from '@utils/time';
import { userLogoutAction } from '@models';
import { store } from '@store';
import { DefaultLayoutHeader, LogoutButton, Time, User } from './styled';
var mapState = function (state) { return ({
    user: state.user
}); };
var CurrentTime = function () {
    var _a = __read(useState(currentTimeWithDay), 2), time = _a[0], setTime = _a[1];
    useEffect(function () {
        var timer = setInterval(function () { return setTime(currentTimeWithDay()); }, 1000);
        return function () {
            clearInterval(timer);
        };
    });
    return React.createElement(Time, null,
        "\u5F53\u524D\u65F6\u95F4: ",
        time);
};
var DefaultHeader = function () {
    var user = useSelector(mapState).user;
    var handleLogout = function () {
        store.dispatch(userLogoutAction());
        requestForMerchant.post('/merchant/account/logout', {});
        if (window.orderSocket) {
            window.orderSocket.close();
        }
    };
    return (React.createElement(DefaultLayoutHeader, null,
        React.createElement(CurrentTime, null),
        React.createElement(User, null,
            "\u60A8\u597D\uFF0C",
            user.userName),
        React.createElement(Divider, { type: "vertical" }),
        React.createElement(LogoutButton, { onClick: handleLogout }, "\u9000\u51FA\u767B\u5F55")));
};
export default DefaultHeader;
export var DefaultLoginout = function () {
    var user = useSelector(mapState).user;
    var handleLogout = function () {
        store.dispatch(userLogoutAction());
        requestForMerchant.post('/merchant/account/logout', {});
        localStorage.removeItem('defaultMenu');
        if (window.orderSocket) {
            window.orderSocket.close();
        }
    };
    return (React.createElement("div", { className: "logout" },
        React.createElement("div", { className: "button", onClick: handleLogout }, "\u9000\u51FA\u767B\u5F55"),
        React.createElement("div", { className: "text" },
            "\u60A8\u597D,",
            user.userName)));
};
