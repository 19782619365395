var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { productTypes } from '@/utils/data';
import './index.less';
import defaultReceiptData from '../../utils/receiptData';
import copy from 'copy-to-clipboard';
import { message } from 'antd';
import CommonHeader from '../ModalHeader/commonModalHeader';
var Receipt = function (props) {
    var _a, _b;
    var data = (_a = props.data) !== null && _a !== void 0 ? _a : defaultReceiptData;
    var goodsList = ((_b = props.renderGoods.groupGoodsList) === null || _b === void 0 ? void 0 : _b.length) ? props.renderGoods.groupGoodsList
        : [{ groupGoods: props.renderGoods.goods }];
    var Pop = function (props) {
        var _a = __read(useState(false), 2), vis = _a[0], setVis = _a[1];
        var width = window.innerWidth;
        var height = window.innerHeight;
        return (React.createElement("div", { onClick: function () { setVis(true); }, className: "ZcPop" },
            props.children,
            vis && React.createElement("div", null,
                React.createElement("div", { className: 'ZcPop-wrapper', style: { width: width, height: height } }),
                React.createElement("div", { className: 'ZcPop-modal' },
                    React.createElement(CommonHeader, { title: "商品图片", onCancel: function () { setVis(false); } }),
                    React.createElement("img", { src: props.imgUrl, style: { paddingTop: "10px" } })))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "receipt-title" }, "\u8BA2\u5355\u8BE6\u60C5"),
        React.createElement("div", { className: "receipt" },
            React.createElement("div", { className: "receipt-no" },
                React.createElement("div", { className: "receipt-no-title" }, "\u8BA2\u5355\u7F16\u53F7"),
                React.createElement("div", { className: "receipt-no-content" }, data.platformId),
                React.createElement("div", { className: "copy", onClick: function () {
                        copy(data.platformId || '');
                        message.success('复制成功');
                    } }, "\u590D\u5236")),
            goodsList[0].groupGoods.length !== 0 && (React.createElement("div", { className: "receipt-shop" },
                React.createElement("div", { className: "receipt-shop-title" }, "\u8BA2\u5355\u5546\u54C1"),
                React.createElement("div", { className: "receipt-shop-content" },
                    goodsList.map(function (good, index) {
                        return (React.createElement("div", { className: "content", key: index },
                            goodsList.length !== 1 && good.groupName && (React.createElement("div", { className: "content-title" }, good.groupName)),
                            good.groupGoods.map(function (item, itemindex) {
                                return (React.createElement("div", { className: "content-text", key: itemindex },
                                    item.picture && React.createElement(Pop, { imgUrl: item.picture },
                                        React.createElement("img", { src: item.picture, alt: "", width: "40px", height: "40px" })),
                                    React.createElement("div", { className: "name" }, getName(item)),
                                    React.createElement("div", { className: "count" },
                                        "x",
                                        item.quantity),
                                    React.createElement("div", { className: "money" },
                                        "\u00A5",
                                        item.price),
                                    item.visible && React.createElement("div", { className: "info" },
                                        React.createElement("img", { src: "", style: { "position": "absolute", width: "500px" } }))));
                            })));
                    }),
                    data.activities && data.activities.length !== 0 && (React.createElement("div", { className: "activity" },
                        "\u6D3B\u52A8\uFF1A",
                        data.activities.join('、')))))),
            React.createElement("div", { className: "receipt-money" },
                React.createElement("div", { className: "receipt-money-title" }, "\u8BA2\u5355\u91D1\u989D"),
                React.createElement("div", { className: "receipt-money-content" },
                    data.originalPrice && (React.createElement("div", { className: "content" },
                        React.createElement("div", { className: "text" }, "\u8BA2\u5355\u539F\u4EF7"),
                        React.createElement("div", { className: "money" },
                            "\u00A5",
                            data.originalPrice))),
                    data.packageFee && (React.createElement("div", { className: "content" },
                        React.createElement("div", { className: "text" }, "\u9910\u76D2\u8D39"),
                        React.createElement("div", { className: "money" },
                            "\u00A5",
                            data.packageFee))),
                    data.deliverFee && (React.createElement("div", { className: "content" },
                        React.createElement("div", { className: "text" }, "\u914D\u9001\u8D39"),
                        React.createElement("div", { className: "money" },
                            "\u00A5",
                            data.deliverFee))),
                    data.reducedPrice && (React.createElement("div", { className: "content" },
                        React.createElement("div", { className: "text" }, "\u603B\u4F18\u60E0\u91D1\u989D"),
                        React.createElement("div", { className: "money" },
                            "\u00A5",
                            data.reducedPrice))),
                    data.totalPrice && (React.createElement("div", { className: "result content" },
                        React.createElement("div", { className: "text" }, "\u5546\u5BB6\u5B9E\u6536"),
                        React.createElement("div", { className: "money" },
                            "\u00A5",
                            data.totalPrice))))),
            data.invoiceTitle !== '' && (React.createElement("div", { className: "receipt-info" },
                React.createElement("div", { className: "receipt-shop-title" }, "\u5F00\u7968\u4FE1\u606F"),
                React.createElement("div", { className: "receipt-no-wrapper receipt-info-wrapper" },
                    React.createElement("div", { className: "receipt-no" },
                        React.createElement("div", { className: "receipt-no-title" }, "\u53D1\u7968\u62AC\u5934"),
                        React.createElement("div", { className: "receipt-no-content" }, data.invoiceTitle || '-'),
                        React.createElement("div", { className: "copy", onClick: function () {
                                if (data.invoiceTitle === '') {
                                    message.error('发票抬头为空');
                                    return;
                                }
                                copy(data.invoiceTitle || '');
                                message.success('复制成功');
                            } }, "\u590D\u5236")),
                    React.createElement("div", { className: "receipt-no " },
                        React.createElement("div", { className: "receipt-no-title" }, "\u7A0E\u53F7"),
                        React.createElement("div", { className: "receipt-no-content" }, data.invoiceId || '-'),
                        React.createElement("div", { className: "copy", onClick: function () {
                                if (data.invoiceId === '') {
                                    message.error('税号为空');
                                    return;
                                }
                                copy(data.invoiceId || '');
                                message.success('复制成功');
                            } }, "\u590D\u5236"))))))));
    function getName(good) {
        var _a, _b, _c;
        if (props.renderGoods.platform === 'manual') {
            var _d = __read(good.name.split(','), 2), productType_1 = _d[0], weight = _d[1];
            var productTypeText = (_a = productTypes.find(function (t) { return t.value === productType_1; })) === null || _a === void 0 ? void 0 : _a.label;
            return productTypeText + "," + weight + "\u516C\u65A4\u5185";
        }
        var name = good.name;
        if ((_b = good.norm) === null || _b === void 0 ? void 0 : _b.length) {
            name += "\u3010" + good.norm + "\u3011";
        }
        if ((_c = good.property) === null || _c === void 0 ? void 0 : _c.length) {
            name += "\u3010" + good.property + "\u3011";
        }
        return name;
    }
};
export default Receipt;
