var _a, _b;
import { envIsUse } from './apiConfig';
/*
 * @Description: 注释说明
 * @version: 0.1
 * @developer: yanjian
 * @Date: 2021-04-29 15:06:22
 */
import meituanIcon from '@assets/images/order/meituan.png';
import elemeIcon from '@assets/images/order/ele.png';
import ebaiIcon from '@assets/images/order/ebai.png';
import manualIcon from '@assets/images/order/manual.png';
import offlineIcon from '@assets/images/order/offline.png';
// ELEME: 'eleme',
// MEITUAN: 'meituan',
// OFFLINE: 'offline',
// EBAI: 'ebai',
// MANUAL: 'manual'
export var OrderPlatformIcon = {
    meituan: meituanIcon,
    eleme: elemeIcon,
    offline: offlineIcon,
    ebai: ebaiIcon,
    manual: manualIcon
};
export var ORDER_PLATFORM_MAP = {
    ELEME: 'eleme',
    MEITUAN: 'meituan',
    OFFLINE: 'offline',
    EBAI: 'ebai',
    MANUAL: 'manual'
};
export var ORDER_PLATFORM_TEXT_MAP = (_a = {},
    _a[ORDER_PLATFORM_MAP.ELEME] = '饿了么',
    _a[ORDER_PLATFORM_MAP.MEITUAN] = '美团',
    _a[ORDER_PLATFORM_MAP.OFFLINE] = '线下',
    _a[ORDER_PLATFORM_MAP.EBAI] = '饿百零售',
    _a[ORDER_PLATFORM_MAP.MANUAL] = '手工下单',
    _a);
export var DELIVERY_PLATFORM_MAP = {
    SHANSONG: 'shansong',
    QS: 'kuaisong',
    DADA: 'dada',
    SHUNFENG: 'shunfeng',
    FengNiao: 'fengniao',
    UUPT: 'uupt',
    MTPT: 'meituan_pt'
};
export var DELIVERY_PLATFORM_TEXT_MAP = (_b = {},
    _b[DELIVERY_PLATFORM_MAP.SHANSONG] = '闪送',
    _b[DELIVERY_PLATFORM_MAP.DADA] = '达达',
    _b[DELIVERY_PLATFORM_MAP.SHUNFENG] = '顺丰',
    _b[DELIVERY_PLATFORM_MAP.FengNiao] = '蜂鸟',
    _b[DELIVERY_PLATFORM_MAP.UUPT] = 'uu跑腿',
    _b[DELIVERY_PLATFORM_MAP.MTPT] = '美团跑腿',
    _b);
export var DELIVERY_STATUS_TEXT_MAP = {
    20: '派单中',
    30: '取件中',
    40: '送货中',
    50: '已送达',
    60: '已取消'
};
export var ApiHost;
(function (ApiHost) {
    ApiHost["Dev"] = "easyex-dev.danyisong.com";
    ApiHost["Test"] = "easyex-test.danyisong.com";
    ApiHost["BTest"] = "easyex-b.test.danyisong.com";
    ApiHost["Prod"] = "iyisong.net";
})(ApiHost || (ApiHost = {}));
export function getIsUseApiHost() {
    var ApiHostisUsed = ApiHost.Test;
    switch (envIsUse) {
        case 'development':
            ApiHostisUsed = ApiHost.Dev;
            break;
        case 'test':
            ApiHostisUsed = ApiHost.Test;
            break;
        case 'newtest':
            ApiHostisUsed = ApiHost.BTest;
            break;
        case 'production':
            ApiHostisUsed = ApiHost.Prod;
            break;
        default:
    }
    return ApiHostisUsed;
}
export var CloudPrinterPlatform;
(function (CloudPrinterPlatform) {
    CloudPrinterPlatform["YiLianYun"] = "yilianyun";
    CloudPrinterPlatform["FeiEYun"] = "feieyun";
    CloudPrinterPlatform["ZhongWuYun"] = "zhongwuyun";
    CloudPrinterPlatform["FeiYinYun"] = "feiyinyun";
    CloudPrinterPlatform["XinYeYun"] = "xinyeyun";
    CloudPrinterPlatform["Unknown"] = "unkonwn";
})(CloudPrinterPlatform || (CloudPrinterPlatform = {}));
//https://alidocs.dingtalk.com/i/team/rkoz3w2pkje7Pz6L/docs/rkoz3RMJQvdR8G6L#
// 事件命名空间
export var EEventNames;
(function (EEventNames) {
    EEventNames["ChannelDeliveryDetailUpdate"] = "ADD_PLATFORM";
    EEventNames["WaitConfirm"] = "WAIT_CONFIRM";
    EEventNames["WaitDistri"] = "WAIT_DISTRI";
    EEventNames["Distriing"] = "DISTRIING";
    EEventNames["Completed"] = "COMPLETED";
    EEventNames["Canceled"] = "CANCELED";
    EEventNames["Waitpickup"] = "WAIT_PICKUP";
    EEventNames["DistriException"] = "DISTRI_EXCEPTION";
    EEventNames["RefundApply"] = "REFUND_APPLY";
    EEventNames["CancelApply"] = "CANCEL_APPLY";
    EEventNames["UrgeOrder"] = "URGE_ORDER";
    EEventNames["Count"] = "COUNT";
    EEventNames["MeituanBindingCheck"] = "MEITUAN_BINDING_CHECK";
    EEventNames["DeliveryError"] = "DELIVER_FAILED";
    EEventNames["DELIVER_PERSON_RECEIVE_ORDER"] = "DELIVER_PERSON_RECEIVE_ORDER";
    EEventNames["Arrived"] = "DELIVER_PERSON_ARRIVALS";
    EEventNames["FengNiaoToken"] = "FENGNIAO_AUTH_TOKEN";
    EEventNames["FengNiaoBindSuccess"] = "FENGNIAO_AUTH_CONFIRM";
    EEventNames["TIME_OUT_UN_EXPRESSION"] = "TIME_OUT_UN_EXPRESSION";
    EEventNames["UN_GRAB_TEN"] = "UN_GRAB_TEN";
    EEventNames["UN_GRAB_THIRTY"] = "UN_GRAB_THIRTY";
    EEventNames["DELIVER_PERSON_PICKUP"] = "DELIVER_PERSON_PICKUP";
    EEventNames["MEITUAN_CANCEL_BIND"] = "MEITUAN_CANCEL_BIND";
})(EEventNames || (EEventNames = {}));
//无COUNT 类型
export var EEventNamesArray = [
    'WAIT_CONFIRM',
    'WAIT_DISTRI',
    'DISTRIING',
    'COMPLETED',
    'CANCELED',
    'DISTRI_EXCEPTION',
    'REFUND_APPLY',
    'CANCEL_APPLY',
    'URGE_ORDER',
    'DELIVER_FAILED',
    'DELIVER_PERSON_RECEIVE_ORDER',
    'DELIVER_PERSON_ARRIVALS',
    'TIME_OUT_UN_EXPRESSION',
    'UN_GRAB_TEN',
    'UN_GRAB_THIRTY',
    'DELIVER_PERSON_PICKUP'
];
