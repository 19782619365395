var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Icon, message } from 'antd';
import logoEle from '@assets/images/shansong.png';
import ajax from '@utils/ajax';
import webConfig from '../../../webConfig';
import { Footer, Header, Logo, Main, StoreList, SubmitButton, Tips, Title, Wrapper } from './styled';
import { withRouter } from 'react-router-dom';
import getDeliveryAuthData from '@utils/getDeliveryAuthData';
import qs from 'qs';
var getStores = function (_a) {
    var type = _a.type, platformAuthData = _a.platformAuthData, storeId = _a.storeId;
    return ajax.post('/distri/platform/binding', {
        storeId: storeId,
        type: type,
        platformAuthData: platformAuthData
    });
};
var SelectStores = /** @class */ (function (_super) {
    __extends(SelectStores, _super);
    function SelectStores() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            platformStores: [],
            storeInfo: { name: '' },
            storeId: null,
            loading: true
        };
        _this.data = {};
        _this.handleStoreClick = function (item) {
            var storeId = item.storeId;
            _this.setState({ storeId: storeId });
        };
        _this.renderBindedItem = function (item) {
            var storeId = _this.state.storeId;
            return (React.createElement("div", { className: "store-item store-item-disabled", onClick: function () { return _this.handleStoreClick(item); } },
                React.createElement("span", null,
                    item.storeName,
                    "(\u5DF2\u7ED1\u5B9A\u6613\u9001\u95E8\u5E97:",
                    item.yisongStoreName,
                    ")"),
                storeId === item.storeId ? (React.createElement(Icon, { twoToneColor: "#0C9EFF", type: "check-circle", theme: "twoTone" })) : null));
        };
        _this.renderUnBindItem = function (item) {
            var storeId = _this.state.storeId;
            return (React.createElement("div", { className: "store-item", onClick: function () { return _this.handleStoreClick(item); } },
                React.createElement("span", null, item.storeName),
                storeId === item.storeId ? (React.createElement(Icon, { twoToneColor: "#0C9EFF", type: "check-circle", theme: "twoTone" })) : null));
        };
        _this.renderStoreItem = function (item) {
            return item.isAuth
                ? _this.renderBindedItem(item)
                : _this.renderUnBindItem(item);
        };
        _this.handleSubmit = function () {
            var storeId = _this.state.storeId;
            if (!storeId)
                return message.error('请选择门店');
            ajax
                .post('/distri/platform/shansong/choose/store', {
                yisongStoreId: _this.data.id,
                shansongStoreId: storeId
            })
                .then(function () {
                message.success('配送平台绑定成功');
                var closeTimer = setTimeout(function () {
                    window.close();
                    clearTimeout(closeTimer);
                }, 1000);
            });
        };
        _this.bind = function () {
            ajax
                .post('/deliver/shansong/sun/store/auth', {
                storeId: _this.data.id,
                code: _this.data.code,
                shansongStoreId: _this.data.issStoreId
            })
                .then(function () {
                message.success('配送平台绑定成功');
                var closeTimer = setTimeout(function () {
                    window.close();
                    clearTimeout(closeTimer);
                }, 1000);
            });
        };
        _this.parsedParams = function () {
            try {
                var searchStr = _this.props.location.search.slice(1);
                var _a = qs.parse(searchStr), type = _a.type, isAllStoreAuth = _a.isAllStoreAuth, issStoreId = _a.issStoreId, data = __rest(_a, ["type", "isAllStoreAuth", "issStoreId"]);
                var platformAuthData = getDeliveryAuthData({ type: type, authData: data });
                var _b = JSON.parse(data.state), receiveCode = _b.receiveCode, takeCode = _b.takeCode, storeId = _b.storeId, from = _b.from;
                var user = JSON.parse(decodeURIComponent(searchStr)
                    .split('&')
                    .filter(function (item) { return item.includes('state='); })[0]
                    .replace('state=', '')).user;
                _this.data = {
                    id: storeId,
                    type: type,
                    platformAuthData: platformAuthData,
                    user: user,
                    from: from,
                    code: platformAuthData.code,
                    isAllStoreAuth: isAllStoreAuth,
                    issStoreId: issStoreId
                };
            }
            catch (ex) {
                message.error('请求参数不合法');
            }
        };
        return _this;
    }
    SelectStores.prototype.componentWillMount = function () {
        this.parsedParams();
    };
    SelectStores.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.data, id = _a.id, type = _a.type, platformAuthData = _a.platformAuthData, user = _a.user, from = _a.from, isAllStoreAuth = _a.isAllStoreAuth;
        if (from === 'app') {
            var searchStr = this.props.location.search;
            var redirectUrl = '';
            var host = location.host;
            var appDomain = webConfig.appDomain;
            if (host.includes('easyex-dev')) {
                redirectUrl = appDomain.dev;
            }
            else if (host.includes('easyex-test')) {
                redirectUrl = appDomain.test;
            }
            else if (host.includes('iyisong')) {
                redirectUrl = appDomain.prod;
            }
            window.location.href =
                redirectUrl + "/easy/deliveryplatform/bind" + searchStr;
            return;
        }
        else if (from === 'qiwei') {
            var searchStr = this.props.location.search;
            var redirectUrl = '';
            var host = location.host;
            var qiweiDomain = webConfig.qiweiDomain;
            if (host.includes('easyex-dev')) {
                redirectUrl = qiweiDomain.dev;
            }
            else if (host.includes('easyex-test')) {
                redirectUrl = qiweiDomain.test;
            }
            else if (host.includes('iyisong')) {
                redirectUrl = qiweiDomain.prod;
            }
            window.location.href =
                redirectUrl + "/qiweiOrder/bindPlatform/bindCallbackPage/shansong" +
                    searchStr;
            return;
        }
        ajax.defaults.headers['Authorization'] = user;
        ajax.defaults.headers['token'] = user;
        if (isAllStoreAuth === 'true') {
            getStores({
                storeId: id,
                type: type,
                platformAuthData: platformAuthData
            }).then(function (res) {
                _this.setState({
                    platformStores: res.data.platformStores,
                    storeInfo: res.data.store,
                    loading: false
                });
            });
        }
        else {
            this.bind();
        }
    };
    SelectStores.prototype.render = function () {
        var _a = this.state, platformStores = _a.platformStores, storeInfo = _a.storeInfo, loading = _a.loading;
        return (React.createElement(Wrapper, null,
            React.createElement(Header, null,
                React.createElement(Logo, { src: logoEle }),
                React.createElement(Title, null, "\u7ED1\u5B9A\u95EA\u9001")),
            React.createElement(Main, null,
                React.createElement(Tips, null, "\u7CFB\u7EDF\u53D1\u73B0\u60A8\u5728\u95EA\u9001\u5E73\u53F0\u4E0A\u6709\u591A\u4E2A\u95E8\u5E97\uFF0C\u8BF7\u9009\u62E9\u60A8\u8981\u7ED1\u5B9A\u7684\u5E97\u94FA"),
                React.createElement(StoreList, { dataSource: platformStores, loading: loading, renderItem: this.renderStoreItem, bordered: true }),
                React.createElement(Footer, null,
                    React.createElement(SubmitButton, { type: "primary", onClick: this.handleSubmit, block: true }, "\u5B8C\u6210")))));
    };
    return SelectStores;
}(React.Component));
export default withRouter(SelectStores);
