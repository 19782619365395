import { installCLodopModal, updateCLodopModal } from '@components/CLodopModalInfo';
import { env } from './apiConfig';
var win = window;
var loadScript = function (src, callback, errback) {
    var script = document.createElement('script');
    var head = document.getElementsByTagName('head')[0];
    script.type = 'text/javascript';
    script.src = src;
    if (script.addEventListener) {
        script.addEventListener('load', function () {
            var win = window;
            var LODOP = win.getCLodop();
            LODOP.SET_LICENSES("北京易送科技有限公司", "5E8922863B8E4175F371162E393C53F6AE2", "北京易送科技有限公司", "ABE72FCABEF1CB42765C2B772159BF47828");
            LODOP.SET_LICENSES("THIRD LICENSE", "", "Beijing Yisong Technology Co., Ltd", "70C12778854E8B06243B46535B0C2FE6F33");
            if (callback) {
                callback();
            }
        }, false);
        script.addEventListener('error', function () {
            if (errback) {
                errback();
            }
        }, false);
    }
    else if (script.attachEvent) {
        script.attachEvent('onreadystatechange', function () {
            var target = win.event.srcElement;
            if (target && target.readyState === 'loaded') {
                var win_1 = window;
                var LODOP = win_1.getCLodop();
                LODOP.SET_LICENSES("北京易送科技有限公司", "5E8922863B8E4175F371162E393C53F6AE2", "北京易送科技有限公司", "ABE72FCABEF1CB42765C2B772159BF47828");
                LODOP.SET_LICENSES("THIRD LICENSE", "", "Beijing Yisong Technology Co., Ltd", "70C12778854E8B06243B46535B0C2FE6F33");
                if (callback) {
                    callback();
                }
            }
            if (target && target.readyState === 'errored') {
                if (errback) {
                    errback();
                }
            }
        });
    }
    head.appendChild(script);
};
var loadSDK = function (next) {
    var checkInstallCLodop = function () {
        if (!win.getCLodop) {
            installCLodopModal();
        }
        else {
            if (win.getCLodop.CVERSION < "4.1.2.7") {
                updateCLodopModal();
            }
        }
    };
    if (win.getCLodop === undefined) {
        if (env === 'local') {
            // loadScript('http://172.29.6.207:8000/CLodopFuncs.js', next, checkInstallCLodop)
            loadScript('http://172.29.5.237:8000/CLodopFuncs.js', next, checkInstallCLodop);
        }
        else if (env === 'test') {
            loadScript('https://localhost.lodop.net:8443/CLodopFuncs.js', next, checkInstallCLodop);
        }
        else {
            loadScript('https://localhost.lodop.net:8443/CLodopFuncs.js', next, checkInstallCLodop);
        }
    }
    else {
        if (next) {
            next();
        }
    }
};
export { loadSDK, };
