import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider, message } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
//  import * as Sentry from '@sentry/browser'
// import * as Sentry from '@sentry/react'
import App from './app';
// import sentryInit from './sentry'
import { store } from './store';
import { GlobalStyle } from './style';
if (process.env.NODE_ENV !== 'production') {
    var whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React);
}
var rootElement = document.getElementById('root');
// sentryInit(Sentry)
message.config({
    maxCount: 3
});
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(GlobalStyle, null),
    React.createElement(ConfigProvider, { locale: zh_CN },
        React.createElement(App, null))), rootElement);
