export var PrinterTypes;
(function (PrinterTypes) {
    PrinterTypes[PrinterTypes["LocalNetwork"] = 1] = "LocalNetwork";
    PrinterTypes[PrinterTypes["LocalUSB"] = 2] = "LocalUSB";
    PrinterTypes[PrinterTypes["CloudPrinter"] = 3] = "CloudPrinter";
    PrinterTypes[PrinterTypes["Bluetooth"] = 4] = "Bluetooth";
    PrinterTypes[PrinterTypes["LocalUSBA4"] = 6] = "LocalUSBA4";
})(PrinterTypes || (PrinterTypes = {}));
export var PrintCases;
(function (PrintCases) {
    PrintCases[PrintCases["Customer"] = 1] = "Customer";
    PrintCases[PrintCases["Merchant"] = 2] = "Merchant";
    PrintCases[PrintCases["Producer"] = 3] = "Producer";
})(PrintCases || (PrintCases = {}));
export var EPaperSize;
(function (EPaperSize) {
    EPaperSize[EPaperSize["Size580"] = 2] = "Size580";
    EPaperSize[EPaperSize["Size800"] = 3] = "Size800";
    EPaperSize[EPaperSize["SizeA4"] = 4] = "SizeA4";
})(EPaperSize || (EPaperSize = {}));
