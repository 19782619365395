import { message } from 'antd';
import axios from 'axios';
import { userLogoutAction } from '@models';
import { store } from '@store';
import { isDev, isElectron, isTest } from '@utils/env';
import { userInfo } from '@utils/localStorage';
import { ApiHost, getIsUseApiHost } from './constants';
var baseURL = (function () {
    if (location.hostname === 'localhost') {
        return "http://" + getIsUseApiHost() + "/api";
    }
    if (!isElectron) {
        return '/api';
    }
    if (isTest) {
        return "https://" + ApiHost.Test + "/api";
    }
    if (isDev) {
        // return `https://${ApiHost.Test}/api`
        return "http://" + ApiHost.Dev + "/api";
    }
    return "https://" + ApiHost.Prod + "/api";
})();
var ajax = axios.create({
    baseURL: baseURL,
    headers: {
        clientId: 'web',
        Authorization: userInfo.token,
        token: userInfo.token
    }
});
ajax.interceptors.response.use(function (response) {
    var data = response.data;
    var requestData = JSON.parse(response.config.data || '{}');
    var handleError = requestData['handleError'];
    if (data.code !== 0 && handleError) {
        // Modal.error({
        //   title: '异常',
        //   content: data.msg
        // })
        return Promise.reject(data);
    }
    switch (data.code) {
        case 1:
        case 500:
            message.error(data.msg);
            return Promise.reject(data);
        case 10000:
            message.error(data.msg);
            if (location.pathname.indexOf('/bind/') === -1) {
                store.dispatch(userLogoutAction());
            }
            return Promise.reject(data);
        default:
            return data;
    }
}, function (error) {
    message.error('网络错误');
    return Promise.reject(error);
});
export default ajax;
