export var productTypes = [
    '餐饮',
    '文件',
    '蛋糕',
    '鲜花',
    '零食',
    '其他',
    '生鲜',
    '数码',
    '商超'
].map(function (val, index) { return ({
    label: val,
    value: (index + 1).toString()
}); });
