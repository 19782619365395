var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { DatePicker, Divider } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { DateRangeType } from '../../types/emnu';
var RangePicker = DatePicker.RangePicker;
var format = 'YYYY-MM-DD';
var getUnixTime = function (time) {
    return moment(moment(time).format(format)).valueOf();
};
var getEndTime = function (unixTime) {
    return unixTime + (60 * 60 * 24 - 1) * 1000;
};
export var today = getUnixTime(moment());
export var yesterday = getUnixTime(moment().subtract(1, 'day'));
var lastThreeDay = getUnixTime(moment().subtract(2, 'day'));
var lastWeek = getUnixTime(moment().subtract(7, 'day'));
var lastMonth = getUnixTime(moment().subtract(30, 'day'));
var lastQuarter = getUnixTime(moment().subtract(90, 'day'));
var FilterList = styled.ul.withConfig({ displayName: "FilterList", componentId: "sc-51d82i" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n"], ["\n  display: flex;\n  align-items: center;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n"])));
var FilterItem = styled.li.withConfig({ displayName: "FilterItem", componentId: "sc-1188ok8" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  height: 35px;\n"], ["\n  display: flex;\n  align-items: center;\n  height: 35px;\n"])));
var FilterRadio = styled.input.withConfig({ displayName: "FilterRadio", componentId: "sc-1cecfhb" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: none;\n  &:checked + label {\n    color: #01cccc;\n  }\n"], ["\n  display: none;\n  &:checked + label {\n    color: #01cccc;\n  }\n"])));
var FilterLabel = styled.label.withConfig({ displayName: "FilterLabel", componentId: "sc-ktksew" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 0 10px;\n  font-size: 14px;\n  color: #333;\n  cursor: pointer;\n"], ["\n  padding: 0 10px;\n  font-size: 14px;\n  color: #333;\n  cursor: pointer;\n"])));
var DateFilter = function (props) {
    var _a = __read(React.useState(false), 2), showRangePicker = _a[0], setRangePicker = _a[1];
    var _b = __read(React.useState(DateRangeType.Today), 2), checked = _b[0], setChecked = _b[1];
    var radioGroupName = props.name;
    var genRadioId = function (id) { return radioGroupName + "-" + id; };
    return (React.createElement(FilterList, null,
        React.createElement(FilterItem, null,
            React.createElement(FilterRadio, { type: "radio", name: radioGroupName, id: genRadioId(DateRangeType.Today), value: DateRangeType.Today, defaultChecked: checked === DateRangeType.Today }),
            React.createElement(FilterLabel, { htmlFor: genRadioId(DateRangeType.Today), onClick: function () {
                    setChecked(DateRangeType.Today);
                    setRangePicker(false);
                    props.onChange({
                        beginTime: today,
                        endTime: moment().unix() * 1000
                    }, DateRangeType.Today);
                } }, "\u4ECA\u5929"),
            React.createElement(Divider, { type: "vertical" })),
        React.createElement(FilterItem, null,
            React.createElement(FilterRadio, { type: "radio", name: radioGroupName, id: genRadioId(DateRangeType.Yesterday), value: DateRangeType.Yesterday, defaultChecked: checked === DateRangeType.Yesterday }),
            React.createElement(FilterLabel, { htmlFor: genRadioId(DateRangeType.Yesterday), onClick: function () {
                    setChecked(DateRangeType.Yesterday);
                    setRangePicker(false);
                    props.onChange({
                        beginTime: yesterday,
                        endTime: getEndTime(yesterday)
                    }, DateRangeType.Yesterday);
                } }, "\u6628\u5929"))));
};
export default DateFilter;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
