import logger from 'redux-logger';
import { init } from '@rematch/core';
import immerPlugin from '@rematch/immer';
import { deliveryPlatforms, orderPlatforms, orders, printCases, printers, stores, user, selfDelivery, customReceipt, customReceiptA4, receiptTemplate, report, merchant } from './models';
var models = {
    deliveryPlatforms: deliveryPlatforms,
    orderPlatforms: orderPlatforms,
    printCases: printCases,
    printers: printers,
    stores: stores,
    user: user,
    orders: orders,
    selfDelivery: selfDelivery,
    customReceipt: customReceipt,
    customReceiptA4: customReceiptA4,
    receiptTemplate: receiptTemplate,
    report: report,
    merchant: merchant
};
var immer = immerPlugin();
var middlewares = [];
if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger);
}
export var store = init({
    models: models,
    redux: {
    // middlewares
    },
    plugins: [immer]
});
