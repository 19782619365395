import React from 'react';
import { transformConsigneePhone } from '@/models/orders';
import OrderActions from '../../OrderActions';
import OrderTags from '../../OrderTags';
import { OrderPlatformIcon, ORDER_PLATFORM_MAP } from '@utils/constants';
import moment from 'moment';
var OrderItem = function (_a) {
    var order = _a.order, current = _a.current, onChange = _a.onChange;
    var isYuyue = getisYuyue();
    var isYuyue1 = getisYuyue1();
    var handleClick = function (order) {
        onChange(order);
    };
    var renderDeliveryTime = function (order) {
        var _a;
        if (order.status === 3) {
            return getDONEtime(order);
        }
        else if (order.status === 4) {
            return getCANCELtime(order);
        }
        else {
            if (order.platform === ORDER_PLATFORM_MAP.EBAI &&
                order.reservationStartTime) {
                return (React.createElement(React.Fragment, null,
                    order.deliverTime,
                    "\u00A0\u5230\u00A0",
                    order.reservationStartTime,
                    " ",
                    isYuyue ? '前送达' : '',
                    React.createElement("span", { className: "time6" }, order.orderTime),
                    "\u4E0B\u5355"));
            }
            else if (((_a = order.selfFetch) === null || _a === void 0 ? void 0 : _a.code) === 0) {
                return (React.createElement(React.Fragment, null,
                    order.deliverTime,
                    isYuyue ? '前送达' : '',
                    React.createElement("span", { className: "time6" }, order.orderTime),
                    "\u4E0B\u5355"));
            }
            else {
                return (React.createElement(React.Fragment, null,
                    order.deliverTime,
                    isYuyue ? '前送达' : '',
                    ' ',
                    React.createElement("span", { className: "time6" }, order.orderTime),
                    "\u4E0B\u5355"));
            }
        }
    };
    return (React.createElement("div", { className: getClassName(), key: order.id, onClick: function (e) {
            e.stopPropagation();
            handleClick(order);
        } },
        isYuyue1 && React.createElement("div", { className: "inneryuyue" }, "\u9884"),
        React.createElement("div", { key: order.id + 'inner' },
            React.createElement("header", null,
                React.createElement("div", { className: "title-text" },
                    React.createElement("div", { className: "img" },
                        React.createElement("img", { src: OrderPlatformIcon[order.platform], style: { width: '34px' } })),
                    React.createElement("span", { className: "no" }, order.platformDaySn),
                    React.createElement("div", { className: "timer" },
                        React.createElement("div", null, renderDeliveryTime(order)),
                        React.createElement("div", null,
                            order.storeName,
                            "#",
                            order.daySn))),
                React.createElement("div", null,
                    React.createElement(OrderActions, { item: order }))),
            React.createElement("aside", null,
                React.createElement("div", { className: "basic" },
                    React.createElement("span", { className: "name" }, order.consignee),
                    React.createElement("i", null, transformConsigneePhone({
                        //@ts-ignore
                        platform: order.platform,
                        consigneePhone: order.consigneePhone
                    })),
                    React.createElement("strong", null, order.consigneeAddress),
                    React.createElement("span", { className: "km" }, order.distance ? " " + order.distance + "km" : '')),
                order.description.trim() !== '' && (React.createElement("div", { className: "remark" },
                    React.createElement("span", null, "\u5907\u6CE8\uFF1A"),
                    order.description)),
                order.booker && order.booker.trim() !== '' && (React.createElement("div", { className: "remark" },
                    React.createElement("span", null, "\u9884\u8BA2\u4EBA\uFF1A"),
                    order.booker)),
                React.createElement("div", { className: "order-tag" }, OrderTags(order).map(function (tag, index) {
                    return React.createElement("span", { key: index + order.id }, tag);
                }))))));
    function getClassName() {
        var className = 'order-item ';
        if (order.id === current) {
            className += ' active ';
        }
        if (isYuyue1) {
            className += ' yuyue';
        }
        return className;
    }
    function getisYuyue() {
        return order.deliverTime !== '立即送达';
    }
    function getisYuyue1() {
        // return order.deliverTime !== '立即送达'
        return order.reservationOrder === 0;
    }
    function getDONEtime(order) {
        var isIgnore = getisIgnore(order.ignoreDistriFlag);
        return (React.createElement(React.Fragment, null,
            !isIgnore ? getT(order.updateTime) : getT(order.ignoreDistriTime),
            ' ',
            React.createElement("span", { className: "time6" }, isIgnore ? '忽略配送' : '完成配送')));
    }
    function getisIgnore(ignoreDistriFlag) {
        return ignoreDistriFlag !== 0;
    }
    function getT(time) {
        return moment(time).format('MM-DD HH:mm:ss');
    }
    function getCANCELtime(order) {
        return (React.createElement(React.Fragment, null,
            getT(order.updateTime),
            React.createElement("span", { className: "time06" }, '取消配送')));
    }
};
export default OrderItem;
