var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/*
 * @Author: hijack
 * @Date: 2021-06-19 13:56:34
 * @LastEditTime: 2021-06-22 20:02:12
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/container/Orders/modal/CouponListModal.tsx
 */
import React, { useState, useContext, useCallback } from 'react';
import FormModal from '@components/FormModal';
import CouponListItem from '../components/CouponListItem';
import { SendDeliveryCouponContext } from '../context';
var CouponListModal = function (_a) {
    var visible = _a.visible, toggleVisible = _a.toggleVisible;
    var _b = useContext(SendDeliveryCouponContext), handleCalculate = _b.handleCalculate, couponGroupId = _b.couponGroupId, couponGroupName = _b.couponGroupName, globalCouponId = _b.globalCouponId, globalCouponName = _b.globalCouponName, isGlobalCouponModal = _b.isGlobalCouponModal;
    var _c = __read(useState(isGlobalCouponModal
        ? { id: globalCouponId, name: globalCouponName }
        : { id: couponGroupId, name: couponGroupName }), 2), checkedItem = _c[0], setcheckedItem = _c[1];
    var handleSave = useCallback(function () {
        handleCalculate({
            selectCouponItem: checkedItem,
            callback: toggleVisible
        });
    }, [checkedItem]);
    return (React.createElement(FormModal, { className: "coupon-list-modal", title: "\u9009\u62E9\u4F18\u60E0\u5238", okText: "\u786E \u5B9A", visible: visible, onOk: handleSave, onCancel: toggleVisible, confirmLoading: false, destroyOnClose: true, width: 740, bodybg: '#F3F5F7' },
        React.createElement(CouponListItem, { couponGroupId: couponGroupId, checkedItem: checkedItem, setcheckedItem: setcheckedItem })));
};
export default CouponListModal;
