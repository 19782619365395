var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import Hotkeys from 'react-hot-keys';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { message, Tooltip } from 'antd';
import * as qs from 'querystring';
import { requestForMerchant } from '@utils/request';
import { getItem } from '@utils/localStorage';
import FormModal from '@components/FormModal';
import { useToggle } from '@hooks';
import ajax from '@utils/ajax';
import { transformOrderData } from '@models';
import { ORDER_STATUS_MAP_VALUE } from '../constants';
import { CreateOrderForm } from '../form';
import getOrderQuery from '../getOrderQuery';
import { CreateOrderButton, CreateOrderMap } from './styled';
import { getAuth } from '@/container/AccountManagement/component';
var HotkeyTipsText = function () {
    if (window.navigator.platform.indexOf('Mac') !== -1) {
        return React.createElement("span", null, "\u5FEB\u6377\u952E\uFF1AOption + C");
    }
    if (window.navigator.platform.indexOf('Win') !== -1) {
        return React.createElement("span", null, "\u5FEB\u6377\u952E\uFF1AAlt + C");
    }
    return null;
};
var mapDispatch = function (dispatch) { return ({
    getOrders: dispatch.orders.fetchData
}); };
export var CreateOrderAction = function (props) {
    var search = useLocation().search;
    var dispatch = useDispatch();
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    var getOrders = mapDispatch(dispatch).getOrders;
    var _a = __read(useToggle(false), 3), visible = _a[0], show = _a[1], hide = _a[2];
    var _b = __read(React.useState([]), 2), storeList = _b[0], setStoreList = _b[1];
    var urlQuery = search.indexOf('?') >= 0
        ? qs.parse(search.substr(search.indexOf('?') + 1))
        : {};
    var orderQuery = getOrderQuery(urlQuery);
    var handleSubmit = React.useCallback(function (values, toggleLoading) {
        var callback = props.callback, oneByMoreData = props.oneByMoreData, _a = props.searchParams, searchParams = _a === void 0 ? {} : _a, handleUpdateCreateOrderStoreId = props.handleUpdateCreateOrderStoreId;
        // 商品信息改为非必填
        // if (!values.commodities.length) {
        //   return message.error('请添加商品信息')
        // }
        toggleLoading.startConfirmLoading();
        ajax.post('/order/create', values).then(function (back) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        message.success('新建订单成功');
                        toggleLoading.stopConfirmLoading();
                        hide();
                        if (!oneByMoreData) return [3 /*break*/, 2];
                        if (handleUpdateCreateOrderStoreId) {
                            handleUpdateCreateOrderStoreId(values.storeId);
                        }
                        return [4 /*yield*/, getOrders(__assign(__assign({}, searchParams), { storeId: values.storeId, pageSize: 10000, orderStatus: ORDER_STATUS_MAP_VALUE.TO_BE_SHIPPING }))];
                    case 1:
                        _a.sent();
                        setTimeout(function () {
                            if (back.code === 0 && back.data && callback) {
                                var formatData = transformOrderData(back.data);
                                callback(formatData);
                            }
                        }, 1000);
                        return [3 /*break*/, 3];
                    case 2:
                        getOrders(orderQuery);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); }, function () {
            setTimeout(function () {
                toggleLoading.stopConfirmLoading();
            }, 2000);
        });
    }, [orderQuery]);
    var fetchStoreList = function () { return __awaiter(void 0, void 0, void 0, function () {
        var lastChooseMerchant;
        return __generator(this, function (_a) {
            lastChooseMerchant = getItem('lastChooseMerchant') && JSON.parse(getItem('lastChooseMerchant'));
            requestForMerchant
                .post("/merchant/store/getAllStoreByMerchantId", {
                merchantId: lastChooseMerchant.merchantId
            })
                .then(function (_a) {
                var data = _a.data;
                setStoreList(data);
            });
            return [2 /*return*/];
        });
    }); };
    React.useEffect(function () {
        fetchStoreList();
    }, []);
    var handleShow = function () {
        props.oneByMoreData && props.oneByMoreData.length === 3
            ? message.info('一取多投一次最多支持三个订单')
            : show();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FormModal, { title: "\u521B\u5EFA\u8BA2\u5355", visible: visible, onCancel: hide, footer: null, destroyOnClose: true },
            React.createElement(CreateOrderForm, { storeList: storeList, onCancel: hide, onSubmit: handleSubmit, oneByMoreData: props.oneByMoreData })),
        getAuth("订单处理", menu) &&
            React.createElement(Hotkeys, { keyName: "alt+c,option+c", onKeyUp: handleShow },
                React.createElement(Tooltip, { placement: "top", title: HotkeyTipsText }, props.callback ? (React.createElement(CreateOrderMap, { onClick: handleShow }, "\u6DFB\u52A0\u6536\u4EF6\u4EBA")) : (React.createElement(CreateOrderButton, { type: "primary", icon: "plus", onClick: handleShow, style: props.callback ? { width: '100%' } : {} }, "\u521B\u5EFA\u8BA2\u5355"))))));
};
// export const CreateOrderAction=getAuth("订单处理")?CreateOrderActioninit:<></>
