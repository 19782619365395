var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import ResetConfirmForm from './ResetConfirmForm';
import ResetForm from './ResetForm';
import { Content, ResetPwdModal, Title } from './styled';
var ResetPassword = /** @class */ (function (_super) {
    __extends(ResetPassword, _super);
    function ResetPassword() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showResetForm: false,
            token: ''
        };
        _this.handleConfirm = function (data) {
            localStorage.setItem('token', data.token);
            _this.setState({
                showResetForm: true,
                token: data.token
            });
        };
        return _this;
    }
    ResetPassword.prototype.renderConfirmForm = function () {
        var isChange = this.props.isChange;
        return (React.createElement(React.Fragment, null,
            React.createElement(Content, null,
                React.createElement(Title, null, "\u4FEE\u6539\u5BC6\u7801"),
                React.createElement("p", null, "\u4FEE\u6539\u5BC6\u7801\u9700\u8981\u9A8C\u8BC1\u60A8\u7684\u624B\u673A\u53F7")),
            React.createElement(ResetConfirmForm, { onConfirm: this.handleConfirm, isChange: isChange })));
    };
    ResetPassword.prototype.renderResetForm = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(Content, null,
                React.createElement(Title, null, "\u8BBE\u7F6E\u65B0\u5BC6\u7801"),
                React.createElement("p", null, "\u5BC6\u7801\u957F\u5EA68-16\u4F4D\uFF0C\u9700\u5305\u542B\u6570\u5B57\u3001\u5927\u5C0F\u5199\u5B57\u6BCD\u548C\u7B26\u53F7\u4E09\u79CD\u4EE5\u4E0A\u7C7B\u578B")),
            React.createElement(ResetForm, { token: this.state.token, handleClose: this.props.onCancel })));
    };
    ResetPassword.prototype.render = function () {
        var state = this.state;
        var props = this.props;
        return (React.createElement(ResetPwdModal, { visible: props.visible, onCancel: props.onCancel, footer: null, centered: true }, state.showResetForm
            ? this.renderResetForm()
            : this.renderConfirmForm()));
    };
    return ResetPassword;
}(React.Component));
export default ResetPassword;
