var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useImperativeHandle, forwardRef, useState } from 'react';
import { Radio, Checkbox } from 'antd';
import './merchant.less';
import { getDefaultMenuByRoleId } from '@/apis/generalNumber';
var PermisoComponent = function (props, ref) {
    var _a = __read(React.useState(null), 2), roleId = _a[0], setRoleId = _a[1];
    var _b = __read(React.useState([]), 2), menuArray = _b[0], setMenuArray = _b[1];
    var _c = __read(useState([]), 2), PersimoRulesArr = _c[0], setPersimoRulesArr = _c[1];
    useEffect(function () {
        if (props.value && isNaN(props.value)) {
            setRoleId(props.value.roleId);
            if (props.value.roleId) {
                props.visible === 0 ? authForroleId(1) : initauthForroleId(props.value.roleId);
            }
        }
    }, [props.value]);
    useImperativeHandle(ref, function () { return { menuArray: menuArray, roleId: roleId }; });
    return (React.createElement("div", null,
        React.createElement(Radio.Group, { onChange: onChange, value: roleId }, props.authPeople.map(function (item, index) {
            return React.createElement(Radio, { key: item.id, value: item.id }, item.roleName);
        })),
        storeDom()));
    function onChange(e) {
        var _a, _b;
        setRoleId((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value);
        // props.onChange(e?.target?.value); 
        authForroleId((_b = e === null || e === void 0 ? void 0 : e.target) === null || _b === void 0 ? void 0 : _b.value);
        // props.onChange(null)
        // setMenuArray([])
    }
    function initauthForroleId(Id) {
        getDefaultMenuByRoleId(Id).then(function (res) {
            setPersimoRulesArr(res);
            var checedMenuArr = res.filter(function (item) {
                return props.value.menuArray.indexOf(String(item.menuId)) !== -1;
            }).map(function (item) { return item.menuId; });
            setMenuArray(checedMenuArr);
        });
    }
    function authForroleId(Id) {
        getDefaultMenuByRoleId(Id).then(function (res) {
            setPersimoRulesArr(res);
            var checedMenuArr = res.filter(function (item) { return item.tickStatus === 0; }).map(function (item) { return item.menuId; });
            setMenuArray(checedMenuArr);
        });
    }
    function storeDom() {
        return (React.createElement("div", { className: 'checkboxstyle' },
            React.createElement(Checkbox.Group, { onChange: onChangeStore, key: roleId, value: menuArray }, PersimoRulesArr === null || PersimoRulesArr === void 0 ? void 0 : PersimoRulesArr.map(function (item) {
                return (React.createElement(Checkbox, { disabled: roleId === 1, key: item.id, className: "longchar", value: item.menuId }, item.menuName));
            }))));
    }
    function onChangeStore(value) {
        setMenuArray(value);
        if (value.length === 0) {
            props.onChange(null);
        }
        else {
            props.onChange(roleId);
        }
    }
};
export default forwardRef(PermisoComponent);
