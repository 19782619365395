var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// import { reqOrderSummary, reqOrderChartData } from '@apis/report'
import { reqOrderSummary, reqOrderChartData, reqChannelProportionData, reqRankData, reqOrderHeapMap, reqCityListByCode, reqPlatformsByStoreId, reqStoresByCityCode } from '../apis/report';
import { reqAllStores } from '@apis/store';
import { ORDER_PLATFORM_TEXT_MAP, DELIVERY_PLATFORM_TEXT_MAP } from '@utils/constants';
export var report = {
    state: {
        stores: [],
        storesForOrderHeap: [],
        platformsForOrder: [],
        platformsForOrderHeap: [],
        orderSummary: {},
        orderLineChart: [],
        orderHeapMap: [],
        orderProportion: [],
        platformProportion: [],
        rank: {},
        cityList: []
    },
    reducers: {
        setOrderSummary: function (state, payload) {
            state.orderSummary = payload;
            return state;
        },
        setOrderLineChart: function (state, payload) {
            state.orderLineChart = payload;
            return state;
        },
        setStores: function (state, payload) {
            state.stores = payload;
            return state;
        },
        setPlatformsForOrder: function (state, payload) {
            state.platformsForOrder = payload;
            return state;
        },
        setPlatformsForOrderHeap: function (state, payload) {
            state.platformsForOrderHeap = payload;
            return state;
        },
        setStoresForOrderHeap: function (state, payload) {
            state.storesForOrderHeap = payload;
            return state;
        },
        setOrderProportion: function (state, payload) {
            payload = payload.map(function (item) {
                return __assign(__assign({}, item), { platform: ORDER_PLATFORM_TEXT_MAP[item.platform] });
            });
            state.orderProportion = payload;
            return state;
        },
        setPlatformProportion: function (state, payload) {
            payload = payload.map(function (item) {
                return __assign(__assign({}, item), { platform: DELIVERY_PLATFORM_TEXT_MAP[item.platform] });
            });
            state.platformProportion = payload;
            return state;
        },
        setRank: function (state, payload) {
            Object.keys(payload).forEach(function (key) {
                payload[key].forEach(function (item, index) {
                    item.index = index;
                });
            });
            state.rank = payload;
            return state;
        },
        setOrderHeapMap: function (state, payload) {
            state.orderHeapMap = payload;
            return state;
        },
        setCityList: function (state, payload) {
            state.cityList = payload;
            return state;
        }
    },
    //@ts-ignore
    effects: function (dispatch) { return ({
        fetchStores: function () {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, reqAllStores()];
                        case 1:
                            data = _a.sent();
                            dispatch.report.setStores(data);
                            return [2 /*return*/, data];
                    }
                });
            });
        },
        fetchPlatformsForOrder: function (storeId) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, reqPlatformsByStoreId(storeId)];
                        case 1:
                            data = (_a.sent()).data;
                            dispatch.report.setPlatformsForOrder(data);
                            return [2 /*return*/];
                    }
                });
            });
        },
        fetchPlatformsForOrderHeap: function (storeId) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!storeId) {
                                dispatch.report.setPlatformsForOrderHeap([]);
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, reqPlatformsByStoreId(storeId)];
                        case 1:
                            data = (_a.sent()).data;
                            dispatch.report.setPlatformsForOrderHeap(data);
                            return [2 /*return*/];
                    }
                });
            });
        },
        fetchOrderSummary: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, reqOrderSummary(params)];
                        case 1:
                            data = (_a.sent()).data;
                            dispatch.report.setOrderSummary(data);
                            return [2 /*return*/];
                    }
                });
            });
        },
        fetchOrderLineChart: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, reqOrderChartData(params)];
                        case 1:
                            data = (_a.sent()).data;
                            dispatch.report.setOrderLineChart(data);
                            return [2 /*return*/];
                    }
                });
            });
        },
        fetchChannelProportion: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, reqChannelProportionData(params)];
                        case 1:
                            data = (_a.sent()).data;
                            dispatch.report.setOrderProportion(data.orderPlatform);
                            dispatch.report.setPlatformProportion(data.distributePlatform);
                            return [2 /*return*/];
                    }
                });
            });
        },
        fetchRank: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, reqRankData(params)];
                        case 1:
                            data = (_a.sent()).data;
                            dispatch.report.setRank(data);
                            return [2 /*return*/];
                    }
                });
            });
        },
        fetchOrderHeapMap: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, reqOrderHeapMap(params)];
                        case 1:
                            data = (_a.sent()).data;
                            dispatch.report.setOrderHeapMap(data);
                            return [2 /*return*/];
                    }
                });
            });
        },
        fetchCityList: function (params) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, reqCityListByCode(params)];
                        case 1:
                            data = (_a.sent()).data;
                            dispatch.report.setCityList(data);
                            return [2 /*return*/, data];
                    }
                });
            });
        },
        fetchStoresForOrderHeap: function (code) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!code) {
                                dispatch.report.setStoresForOrderHeap([]);
                                dispatch.report.setPlatformsForOrderHeap([]);
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, reqStoresByCityCode(code)];
                        case 1:
                            data = (_a.sent()).data;
                            dispatch.report.setStoresForOrderHeap(data);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }); }
};
