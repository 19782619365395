import React from 'react';
import FormulaCompareValue from './FormulaCompareValue';
import { FormulaCompareContainer } from './styled';
var FormulaCompare = function (_a) {
    var text = _a.text, value = _a.value;
    return (React.createElement(FormulaCompareContainer, null,
        text,
        " ",
        React.createElement(FormulaCompareValue, { value: value })));
};
export default FormulaCompare;
