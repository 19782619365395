var localStorage = window.localStorage;
export var userInfo = {
    userName: localStorage.getItem('userName') || '',
    token: localStorage.getItem('token') || ''
};
export var setUserInfo = function (_a) {
    var userName = _a.userName, token = _a.token;
    localStorage.setItem('userName', userName);
    localStorage.setItem('token', token);
};
export var removeUserInfo = function () {
    localStorage.removeItem('userName');
    localStorage.removeItem('token');
};
export var setItem = function (name, val) {
    localStorage.setItem(name, val);
};
export var getItem = function (name) {
    return localStorage.getItem(name) || '';
};
