/*
 * @Description: 注释说明
 * @version: 0.1
 * @developer: yanjian
 * @Date: 2021-04-29 15:06:22
 */
import * as React from 'react';
import { Icon } from 'antd';
import DistanceSvg from '@assets/images/icon-distance.svg';
import FengniaoIcon from '@assets/images/fengniao.png';
import DadaIcon from '@assets/images/dada.png';
import ShunFentIcon from '@assets/images/shunfeng.png';
import MtKsdIcon from '@assets/images/meituanksd.png';
import MtGsdIcon from '@assets/images/meituangsd.png';
import YsZsIcon from '@assets/images/yszhuansong.png';
import YsKsIcon from '@assets/images/ks.png';
import YsIcon from '@assets/images/yisong.png';
import UuptIcon from '@assets/images/uupt.png';
import MtptIcon from '@assets/images/mtpt.png';
import LocationSvg from '@assets/images/icon-location.svg';
import ShansongIcon from '@assets/images/shansong.png';
import SortSvg from '@assets/images/icon-sort.svg';
import UserSvg from '@assets/images/icon-user.svg';
export var IconUser = function () { return React.createElement(Icon, { component: UserSvg }); };
export var IconSort = function () { return React.createElement(Icon, { component: SortSvg }); };
export var IconLocation = function () { return React.createElement(Icon, { component: LocationSvg }); };
export var IconDistance = function () { return React.createElement(Icon, { component: DistanceSvg }); };
export var IconShansong = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '34px' : _b, _c = _a.height, height = _c === void 0 ? '34px' : _c;
    return (React.createElement("img", { src: ShansongIcon, width: width, height: height }));
};
export var IconFengniao = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '34px' : _b, _c = _a.height, height = _c === void 0 ? '34px' : _c;
    return (React.createElement("img", { src: FengniaoIcon, width: width, height: height }));
};
export var IconDada = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '34px' : _b, _c = _a.height, height = _c === void 0 ? '34px' : _c;
    return (React.createElement("img", { src: DadaIcon, width: width, height: height }));
};
export var IconShunFeng = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '34px' : _b, _c = _a.height, height = _c === void 0 ? '34px' : _c;
    return (React.createElement("img", { src: ShunFentIcon, width: width, height: height }));
};
export var IconMtKsd = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '34px' : _b, _c = _a.height, height = _c === void 0 ? '34px' : _c;
    return (React.createElement("img", { src: MtKsdIcon, width: width, height: height }));
};
export var IconMtGsd = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '34px' : _b, _c = _a.height, height = _c === void 0 ? '34px' : _c;
    return (React.createElement("img", { src: MtGsdIcon, width: width, height: height }));
};
export var IconYsZs = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '34px' : _b, _c = _a.height, height = _c === void 0 ? '34px' : _c;
    return (React.createElement("img", { src: YsZsIcon, width: width, height: height }));
};
export var IconYsKs = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '34px' : _b, _c = _a.height, height = _c === void 0 ? '34px' : _c;
    return (React.createElement("img", { src: YsKsIcon, width: width, height: height }));
};
export var IconUupt = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '34px' : _b, _c = _a.height, height = _c === void 0 ? '34px' : _c;
    return (React.createElement("img", { src: UuptIcon, width: width, height: height }));
};
export var IconMtpt = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '34px' : _b, _c = _a.height, height = _c === void 0 ? '34px' : _c;
    return (React.createElement("img", { src: MtptIcon, width: width, height: height }));
};
export var IconYs = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '34px' : _b, _c = _a.height, height = _c === void 0 ? '34px' : _c;
    return (React.createElement("img", { src: YsIcon, width: width, height: height }));
};
