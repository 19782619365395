import React from 'react';
import { FlatValue, IncreaseValue, DecreaseValue } from './styled';
var FormulaCompareValue = function (_a) {
    var value = _a.value;
    if (value === 0) {
        return React.createElement(FlatValue, null, "\u6301\u5E73");
    }
    if (value > 0) {
        return React.createElement(IncreaseValue, null,
            value,
            "%");
    }
    if (value < 0) {
        return React.createElement(DecreaseValue, null,
            Math.abs(value),
            "%");
    }
    return null;
};
export default FormulaCompareValue;
