var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, message, Popconfirm, Table } from 'antd';
import produce from 'immer';
import { cloneDeep, get } from 'lodash';
import styled from 'styled-components';
import * as uuid from 'uuid';
import { transformOrderData } from '@models';
import { deliverReceiptMockData, printReceipt } from '@printer';
import { sendPv } from '@utils/umeng';
import BindPrinterModal from './BindPrinterModal';
var PRINTER_TYPES = {
    1: '网络',
    2: 'USB',
    3: '云打印机',
    4: '蓝牙打印机',
    6: 'A4打印机'
};
var PRINTER_PAPER_FORMAT = {
    1: '58 mm',
    2: '76 mm',
    3: '80 mm',
    4: 'A4'
};
var PRINTER_STATUS_MAPS = {
    0: '未连接',
    1: '已连接',
    2: '缺纸',
    3: '未知'
};
var PrintersTable = styled(Table).withConfig({ displayName: "PrintersTable", componentId: "sc-1tdrckb" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 20px;\n"], ["\n  margin-top: 20px;\n"])));
var Printers = /** @class */ (function (_super) {
    __extends(Printers, _super);
    function Printers() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            bindModalVisible: false,
            isEditPrinter: false,
            editPrinter: {
                isEditMode: false,
                visible: false,
                initialValue: {}
            }
        };
        _this.columns = [
            {
                title: '打印机名称',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '打印机类型',
                dataIndex: 'type',
                key: 'type',
                render: function (text) {
                    if (PRINTER_TYPES[text]) {
                        return PRINTER_TYPES[text];
                    }
                    return '未知类型';
                }
            },
            {
                title: 'IP 地址',
                dataIndex: 'ip',
                key: 'ip'
            },
            {
                title: '纸张格式',
                dataIndex: 'paperFormat',
                key: 'paperFormat',
                render: function (text) {
                    if (PRINTER_PAPER_FORMAT[text]) {
                        return PRINTER_PAPER_FORMAT[text];
                    }
                    return '未知纸张格式';
                }
            },
            {
                title: '打印机状态',
                dataIndex: 'status',
                key: 'status',
                render: function (text) {
                    return PRINTER_STATUS_MAPS[text] || '检测中';
                }
            },
            {
                title: '所在门店',
                key: 'store',
                render: function (text, record) {
                    var store = record.store;
                    return store.name + " - " + store.numbering;
                }
            },
            {
                title: '操作',
                key: 'action',
                render: function (text, record) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement(Button, { size: "small", onClick: function () { return _this.toggleEditPrinter(record); } }, "\u7F16\u8F91"),
                        "\u00A0",
                        React.createElement(Popconfirm, { title: "\u786E\u8BA4\u5220\u9664\uFF1F", cancelText: "\u53D6\u6D88", okText: "\u786E\u5B9A", onConfirm: function () { return _this.handleDelete(record.id); } },
                            React.createElement(Button, { size: "small" }, "\u5220\u9664")),
                        "\u00A0",
                        React.createElement(Button, { size: "small", onClick: function () { return _this.handlePrintTest(record); } }, "\u6253\u5370\u6D4B\u8BD5\u9875")));
                }
            }
        ];
        _this.handlePrintTest = function (printer) {
            if (printer.status !== 1)
                return message.error('打印机未连接或缺少纸张');
            // const { escpos } = window
            // const device = new escpos.Network(printer.ip)
            // const print = new escpos.Printer(device)
            var data = Object.assign(transformOrderData(cloneDeep(deliverReceiptMockData)), {
                uuids: [uuid.v4()]
            });
            printReceipt({
                printerConfig: printer,
                data: data,
                from: 'printTest'
            });
        };
        _this.handleDelete = function (id) {
            _this.props.deletePrinter(id).then(function () {
                message.success('打印机删除成功');
            });
        };
        _this.toggleEditPrinter = function (initialValue) {
            if (initialValue === void 0) { initialValue = {}; }
            var editPrinter = _this.state.editPrinter;
            _this.setState(produce(function (draft) {
                draft.editPrinter = {
                    initialValue: initialValue,
                    isEditMode: !!get(initialValue, 'id'),
                    visible: !editPrinter.visible
                };
            }));
        };
        _this.handleTableChange = function (pagination) {
            _this.props.getPrinterList({
                page: pagination.current,
                pageSize: pagination.pageSize
            });
        };
        return _this;
    }
    Printers.prototype.componentDidMount = function () {
        this.props.getPrinterList({});
        sendPv();
    };
    Printers.prototype.render = function () {
        var _this = this;
        var state = this.state;
        var props = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { type: "primary", onClick: function () { return _this.toggleEditPrinter(); } }, "\u6DFB\u52A0\u6253\u5370\u673A"),
            React.createElement(BindPrinterModal, { history: this.props.history, isEditMode: state.editPrinter.isEditMode, visible: state.editPrinter.visible, initialValue: state.editPrinter.initialValue, toggleVisible: this.toggleEditPrinter }),
            React.createElement(PrintersTable, { columns: this.columns, dataSource: props.dataSource, pagination: props.pagination, loading: props.listLoading, onChange: this.handleTableChange, rowKey: "id", bordered: true })));
    };
    return Printers;
}(React.Component));
var mapState = function (state) {
    var _a = state.printers, data = _a.data, loading = _a.loading;
    return {
        dataSource: data.list,
        listLoading: loading,
        pagination: {
            current: data.page,
            pageSize: data.pageSize,
            total: data.total
        }
    };
};
var mapDispatch = function (dispatch) { return ({
    getPrinterList: dispatch.printers.fetchData,
    deletePrinter: dispatch.printers.deleteItemWithReq
}); };
export default connect(mapState, mapDispatch)(Printers);
var templateObject_1;
