var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { Modal, Card, Button, Icon } from 'antd';
import lodash from 'lodash';
import { EBizType, EBindTag } from '../../../constance';
var ModalPlatFrom = function (_a) {
    var deliveryVisible = _a.deliveryVisible, setdeliveryVisible = _a.setdeliveryVisible, _b = _a.channelData, channelData = _b === void 0 ? [] : _b, _c = _a.platformData, platformData = _c === void 0 ? [] : _c, handleSaveModalPlatform = _a.handleSaveModalPlatform, editBizPlatform = _a.editBizPlatform;
    var _d = __read(useState([]), 2), currentDelivery = _d[0], setcurrentDelivery = _d[1];
    useEffect(function () {
        if (editBizPlatform === EBizType.CHANNEL) {
            setcurrentDelivery(channelData);
        }
        else {
            setcurrentDelivery(platformData);
        }
    }, [channelData, platformData, editBizPlatform]);
    var handleUpdateCurrentDelivery = function (item) {
        var tempCurrentDelivery = lodash.cloneDeep(currentDelivery);
        tempCurrentDelivery.map(function (record) {
            if (record.expressPlatformId === item.expressPlatformId) {
                record.bindTag = record.bindTag === EBindTag.BIND ? EBindTag.UNBIND : EBindTag.BIND;
                setcurrentDelivery(tempCurrentDelivery);
            }
        });
    };
    var handleSave = function () {
        handleSaveModalPlatform(currentDelivery, editBizPlatform);
        setdeliveryVisible(false);
    };
    var handleClose = function () {
        if (editBizPlatform === EBizType.CHANNEL) {
            setcurrentDelivery(channelData);
        }
        else {
            setcurrentDelivery(platformData);
        }
        setdeliveryVisible(false);
    };
    var renderPlatFrom = function (platfromChecked) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Card, { title: "\u5DF2\u9009\u5E73\u53F0", bordered: false }, platfromChecked.length !== 0 &&
                platfromChecked.map(function (item) {
                    return (lodash.get(item, 'bindTag', false) === EBindTag.BIND && (React.createElement(Button, { key: item.expressPlatformId, onClick: function () { return handleUpdateCurrentDelivery(item); } },
                        item.expressPlatformName,
                        React.createElement(Icon, { type: "close" }))));
                })),
            React.createElement(Card, { title: "\u672A\u9009\u5E73\u53F0", bordered: false }, platfromChecked.length !== 0 &&
                platfromChecked.map(function (item) {
                    return (lodash.get(item, 'bindTag', false) === EBindTag.UNBIND && (React.createElement(Button, { key: item.expressPlatformId, onClick: function () { return handleUpdateCurrentDelivery(item); } },
                        item.expressPlatformName,
                        " ",
                        React.createElement(Icon, { type: "plus" }))));
                }))));
    };
    return (React.createElement(Modal, { visible: deliveryVisible, key: 'ModalKey' + editBizPlatform, destroyOnClose: true, onCancel: handleClose, onOk: handleSave, maskClosable: false }, renderPlatFrom(currentDelivery)));
};
export default ModalPlatFrom;
