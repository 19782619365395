var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button, List } from 'antd';
import styled from 'styled-components';
var blueColor = '#0C9EFF';
export var Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-hy44n8" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100vh;\n  background: #f5f5f5;\n"], ["\n  height: 100vh;\n  background: #f5f5f5;\n"])));
export var Header = styled.header.withConfig({ displayName: "Header", componentId: "sc-uyq91a" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  padding: 0 110px;\n  height: 60px;\n  background: #fff;\n"], ["\n  box-sizing: border-box;\n  display: flex;\n  align-items: center;\n  padding: 0 110px;\n  height: 60px;\n  background: #fff;\n"])));
export var Logo = styled.img.withConfig({ displayName: "Logo", componentId: "sc-1lrsubv" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 121px;\n  height: 45px;\n"], ["\n  width: 121px;\n  height: 45px;\n"])));
export var Title = styled.h2.withConfig({ displayName: "Title", componentId: "sc-1dp7v4g" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0 0 0 10px;\n  color: rgb(12, 158, 255);\n  font-size: 24px;\n"], ["\n  margin: 0 0 0 10px;\n  color: rgb(12, 158, 255);\n  font-size: 24px;\n"])));
export var Main = styled.main.withConfig({ displayName: "Main", componentId: "sc-eq546l" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 20px auto;\n  padding: 30px 80px;\n  max-width: 1000px;\n  background: #fff;\n"], ["\n  margin: 20px auto;\n  padding: 30px 80px;\n  max-width: 1000px;\n  background: #fff;\n"])));
export var Tips = styled.h4.withConfig({ displayName: "Tips", componentId: "sc-n6ih2c" })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: #303133;\n  font-size: 18px;\n"], ["\n  color: #303133;\n  font-size: 18px;\n"])));
export var StoreList = styled(List).withConfig({ displayName: "StoreList", componentId: "sc-77s1o5" })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  && {\n    margin-bottom: 32px;\n    .store-item {\n      padding: 24px 20px;\n      border-bottom: 1px solid #e0e6ed;\n      cursor: pointer;\n    }\n    .store-item-disabled {\n      /* cursor: not-allowed;\n      color: #ccc; */\n    }\n  }\n"], ["\n  && {\n    margin-bottom: 32px;\n    .store-item {\n      padding: 24px 20px;\n      border-bottom: 1px solid #e0e6ed;\n      cursor: pointer;\n    }\n    .store-item-disabled {\n      /* cursor: not-allowed;\n      color: #ccc; */\n    }\n  }\n"])));
export var Footer = styled.footer.withConfig({ displayName: "Footer", componentId: "sc-1qn2f1n" })(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
export var SubmitButton = styled(Button).withConfig({ displayName: "SubmitButton", componentId: "sc-5acjxj" })(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  && {\n    width: 450px;\n    background: ", ";\n  }\n"], ["\n  && {\n    width: 450px;\n    background: ", ";\n  }\n"])), blueColor);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
