import moment from 'moment';
export var getOption = function (xAxisData, canceledYAxisData, completedYAxisData) {
    return {
        tooltip: {
            trigger: 'axis'
        },
        color: ['#48A1FF', '#58CC74'],
        icon: 'rect',
        textStyle: {
            color: '#333'
        },
        grid: {
            left: '3%'
        },
        legend: {
            right: '10%',
            data: ['已取消订单', '已完成订单']
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
                data: xAxisData,
                axisLabel: {
                    interval: 0,
                    rotate: 45,
                    margin: 8
                }
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: '已取消订单',
                type: 'line',
                // symbolSize: 0,
                lineStyle: {
                    color: '#48A1FF'
                },
                data: canceledYAxisData
            },
            {
                name: '已完成订单',
                type: 'line',
                // symbolSize: 0,
                data: completedYAxisData,
                lineStyle: {
                    color: '#58CC74'
                }
            }
        ]
    };
};
export var getXData = function (xData) {
    return xData.map(function (data) { return data.text; });
};
export var getYData = function (orderChartData, xData) {
    var cancled = xData.map(function (item) {
        var targetItem = orderChartData.find(function (data) { return data.date === item.time; });
        if (targetItem) {
            return targetItem.cancled;
        }
        else {
            return 0;
        }
    });
    var finished = xData.map(function (item) {
        var targetItem = orderChartData.find(function (data) { return data.date === item.time; });
        if (targetItem) {
            return targetItem.finished;
        }
        else {
            return 0;
        }
    });
    return {
        cancled: cancled,
        finished: finished
    };
};
export function getTodayXData() {
    var data = [];
    var i = 0;
    while (true) {
        var start = moment(moment().format('YYYY-MM-DD'));
        var timeMoment = start.add(i, 'hours');
        if (timeMoment > moment()) {
            break;
        }
        data.push({
            text: timeMoment.format('HH:mm'),
            time: (timeMoment.unix() * 1000).toString()
        });
        i++;
    }
    return data;
}
export function getYesterdayXData() {
    var data = [];
    for (var i = 0; i < 24; i++) {
        var start = moment(moment().format('YYYY-MM-DD')).subtract(1, 'days');
        var timeMoment = start.add(i, 'hours');
        data.push({
            text: timeMoment.format('HH:mm'),
            time: (timeMoment.unix() * 1000).toString()
        });
    }
    data.push({
        text: '24:00',
        time: moment(moment().format('YYYY-MM-DD')).toString()
    });
    return data;
}
export function getXDataInBeforeRange(range) {
    var data = [];
    var i = 0;
    for (var i_1 = 0; i_1 < range; i_1++) {
        var start = moment(moment().format('YYYY-MM-DD')).subtract(range, 'days');
        var timeMoment = start.add(i_1, 'days');
        data.push({
            text: timeMoment.format('YYYY-MM-DD'),
            time: (timeMoment.unix() * 1000).toString()
        });
    }
    return data;
}
export function getXDataInRange(beginTime, endTime) {
    var data = [];
    var i = 0;
    var time = moment(beginTime).add(i, 'days');
    while (time.unix() * 1000 < endTime) {
        data.push({
            text: time.format('YYYY-MM-DD'),
            time: (time.unix() * 1000).toString()
        });
        i++;
        time = moment(beginTime).add(i, 'days');
    }
    return data;
    // let i = 0
    // for (let i = 0; i < range; i++) {
    //   const start = moment(moment().format('YYYY-MM-DD')).subtract(range, 'days')
    //   const timeMoment = start.add(i, 'days')
    //   data.push({
    //     text: timeMoment.format('YYYY-MM-DD'),
    //     time: (timeMoment.unix() * 1000).toString()
    //   })
    // }
    // return data
}
export function getCustomXData(beginTime, endTime) {
    var todayTimeBegin = moment(moment().format('YYYY-MM-DD')).unix() * 1000;
    var todayTimeEnd = todayTimeBegin + 60 * 60 * 24 * 1000;
    var yesterdayTimeBegin = moment(moment().format('YYYY-MM-DD'))
        .subtract(1, 'days')
        .unix() * 1000;
    var yesterdayTimeEnd = yesterdayTimeBegin + 60 * 60 * 24 * 1000;
    if (beginTime === todayTimeBegin && endTime < todayTimeEnd) {
        return getTodayXData();
    }
    else if (beginTime === yesterdayTimeBegin && endTime < yesterdayTimeEnd) {
        return getYesterdayXData();
    }
    else {
        return getXDataInRange(beginTime, endTime);
    }
}
