var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import FormModal from '@components/FormModal';
import { useToggle } from '@hooks';
import ajax from '@utils/ajax';
import { PrinterTypes } from '../../../../printer/constants';
import BindPrinterForm from './BindPrinter';
var mapDispatch = function (dispatch) { return ({
    getPrinterList: dispatch.printers.fetchData
}); };
var BindPrinterModal = function (_a) {
    var isEditMode = _a.isEditMode, visible = _a.visible, initialValue = _a.initialValue, toggleVisible = _a.toggleVisible, history = _a.history;
    var dispatch = useDispatch();
    var getPrinterList = mapDispatch(dispatch).getPrinterList;
    var _b = __read(useToggle(false), 3), confirmLoading = _b[0], startConfirmLoading = _b[1], stopConfirmLoading = _b[2];
    var formRef = React.useRef();
    var handleSubmit = function () {
        var editPrinterForm = formRef.current.props.form;
        editPrinterForm.validateFields(function (errors, values) {
            if (errors)
                return false;
            if (isEditMode) {
                values.id = initialValue.id;
            }
            if (values.type === PrinterTypes.LocalUSBA4) {
                values.paperFormat = 4;
            }
            startConfirmLoading();
            ajax.post('/printer/save', values).then(function (res) {
                isEditMode
                    ? message.success('编辑打印机成功')
                    : message.success('添加打印机成功');
                stopConfirmLoading();
                getPrinterList({});
                toggleVisible();
                if (res.data) {
                    history.push({ pathname: '/stores/print/cases', state: { id: res.data.id } });
                }
            }, function () {
                stopConfirmLoading();
            });
        });
    };
    return (React.createElement(FormModal, { title: (isEditMode ? '编辑' : '添加') + "\u6253\u5370\u673A", visible: visible, onCancel: function () { return toggleVisible(); }, onOk: handleSubmit, confirmLoading: confirmLoading, destroyOnClose: true, okText: "\u4FDD\u5B58" },
        React.createElement(BindPrinterForm, { wrappedComponentRef: formRef, initialValue: initialValue })));
};
export default BindPrinterModal;
