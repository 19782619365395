var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ReceiptWrapper = styled.div.withConfig({ displayName: "ReceiptWrapper", componentId: "sc-1xrhg4z" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 20px;\n"], ["\n  padding: 0 20px;\n"])));
export var PreviewRow = styled.div.withConfig({ displayName: "PreviewRow", componentId: "sc-x2c8zn" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 5px;\n  line-height: 20px;\n"], ["\n  margin-bottom: 5px;\n  line-height: 20px;\n"])));
export var RectiptTop = styled.div.withConfig({ displayName: "RectiptTop", componentId: "sc-137i6wu" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: center;\n  /* padding: 44px 0 0; */\n  .store-name,\n  .receipt-type {\n    font-size: 20px;\n    margin: 0;\n  }\n"], ["\n  text-align: center;\n  /* padding: 44px 0 0; */\n  .store-name,\n  .receipt-type {\n    font-size: 20px;\n    margin: 0;\n  }\n"])));
export var Logo = styled.div.withConfig({ displayName: "Logo", componentId: "sc-11ynjl9" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 90px;\n  height: 90px;\n  background: url(", ") no-repeat;\n  background-size: 100%;\n  margin: 0 auto 15px;\n"], ["\n  width: 90px;\n  height: 90px;\n  background: url(", ") no-repeat;\n  background-size: 100%;\n  margin: 0 auto 15px;\n"])), function (props) { return props.src; });
export var Preview1 = styled.div.withConfig({ displayName: "Preview1", componentId: "sc-1khdd1o" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 10px;\n"], ["\n  margin-top: 10px;\n"])));
export var Preview2 = styled.div.withConfig({ displayName: "Preview2", componentId: "sc-1010fk4" })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 10px;\n"], ["\n  margin-top: 10px;\n"])));
export var PreviewGoods = styled.div.withConfig({ displayName: "PreviewGoods", componentId: "sc-1us9el3" })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 5px;\n  table {\n    width: 100%;\n    border-collapse: collapse;\n    th {\n      font-size: 16px;\n    }\n    tr {\n      height: 30px;\n    }\n  }\n"], ["\n  margin-top: 5px;\n  table {\n    width: 100%;\n    border-collapse: collapse;\n    th {\n      font-size: 16px;\n    }\n    tr {\n      height: 30px;\n    }\n  }\n"])));
export var BoldRow = styled.div.withConfig({ displayName: "BoldRow", componentId: "sc-ih6z86" })(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-bottom: 5px;\n  font-size: 18px;\n  font-weight: bold;\n"], ["\n  margin-bottom: 5px;\n  font-size: 18px;\n  font-weight: bold;\n"])));
export var BigRow = styled.div.withConfig({ displayName: "BigRow", componentId: "sc-dpy1ab" })(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-size: 18px;\n"], ["\n  font-size: 18px;\n"])));
export var RemainingRow = styled.div.withConfig({ displayName: "RemainingRow", componentId: "sc-1819d2z" })(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 50px;\n"], ["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 50px;\n"])));
export var Fee1 = styled.div.withConfig({ displayName: "Fee1", componentId: "sc-1ktdue7" })(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-top: 10px;\n  .other {\n    text-align: center;\n    height: 30px;\n  }\n"], ["\n  margin-top: 10px;\n  .other {\n    text-align: center;\n    height: 30px;\n  }\n"])));
export var Fee2 = styled.div.withConfig({ displayName: "Fee2", componentId: "sc-13ojnxf" })(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-top: 5px;\n  margin-bottom: 20px;\n  text-align: right;\n"], ["\n  margin-top: 5px;\n  margin-bottom: 20px;\n  text-align: right;\n"])));
export var Seperator = styled.div.withConfig({ displayName: "Seperator", componentId: "sc-14ddpkr" })(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  height: 30px;\n  position: relative;\n  > span {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    padding: 0 5px;\n    background: #fff;\n    transform: translate(-50%, -60%);\n  }\n  overflow: hidden;\n  &:before {\n    content: '----------------------------------------';\n  }\n  &:after {\n    content: '----------------------------------------';\n  }\n"], ["\n  height: 30px;\n  position: relative;\n  > span {\n    position: absolute;\n    left: 50%;\n    top: 50%;\n    padding: 0 5px;\n    background: #fff;\n    transform: translate(-50%, -60%);\n  }\n  overflow: hidden;\n  &:before {\n    content: '----------------------------------------';\n  }\n  &:after {\n    content: '----------------------------------------';\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
