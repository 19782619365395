var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Button, message } from 'antd';
import ajax from '@utils/ajax';
import './button.less';
import { ActionTips, ORDER_STATUS_CODE_MAP } from '../constants';
import { ActionsContext } from '../context';
import { emitOrderChange } from './common';
import Reconfirm from './reconfirm';
import { getAuth } from '@/container/AccountManagement/component';
import { useSelector } from 'react-redux';
var agreeRefundOrCancelReq = function (_a) {
    var orderId = _a.orderId, platform = _a.platform;
    return ajax.post('/order/agree/refund', { orderId: orderId, platform: platform, type: 1 });
};
var AgreeCancelActioninit = /** @class */ (function (_super) {
    __extends(AgreeCancelActioninit, _super);
    function AgreeCancelActioninit(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClick = function () {
            var _a = _this.context, orderId = _a.id, platform = _a.platform, orderEvent = _a.orderEvent, tabStatus = _a.tabStatus;
            agreeRefundOrCancelReq({ orderId: orderId, platform: platform }).then(function () {
                var _a, _b;
                emitOrderChange({
                    tabStatus: tabStatus,
                    orderEvent: orderEvent,
                    orderId: orderId,
                    nextStatus: ORDER_STATUS_CODE_MAP.CANCELED
                });
                message.success(ActionTips.AGREE_CANCEL_SUCCESS);
                //@ts-ignore
                (_b = (_a = _this.myref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.setVisible(false);
            });
        };
        _this.handleOk = function () {
            var _a, _b;
            //@ts-ignore
            (_b = (_a = _this.myref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.setVisible(true);
        };
        _this.myref = React.createRef();
        return _this;
    }
    AgreeCancelActioninit.prototype.render = function () {
        var content = this.props.text
            ? this.props.text
            : '同意后将取消订单，请确定是否同意';
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: this.handleOk, className: "orderbutton" }, "\u540C\u610F\u53D6\u6D88"),
            React.createElement(Reconfirm, { ref: this.myref, callback: this.handleClick, text: { title: '同意取消', content: content }, cancelText: "\u518D\u60F3\u60F3" })));
    };
    return AgreeCancelActioninit;
}(React.PureComponent));
AgreeCancelActioninit.contextType = ActionsContext;
export var AgreeCancelAction = function (props) {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("订单处理", menu) ? React.createElement(AgreeCancelActioninit, __assign({}, props)) : null;
};
var AgreeCancelActionIN_THE_DISTRIBUTIONinit = function () {
    return (React.createElement(AgreeCancelActioninit, { text: '骑手已接单，取消后可能产生配送费，请确定是否同意' }));
};
export var AgreeCancelActionIN_THE_DISTRIBUTION = function () {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("订单处理", menu) ? React.createElement(AgreeCancelActionIN_THE_DISTRIBUTIONinit, null) : null;
};
