var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * @Author: hijack
 * @Date: 2021-06-15 20:16:10
 * @LastEditTime: 2021-06-26 18:25:33
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/container/Orders/components/DeliveryOrderStatusPlatform.tsx
 */
import * as React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';
// import { AppendTipAction, CancelDeliveryOrderActionWAIT_GRAB } from '../action/index'
import { PlatformDeliveryContext } from '../context';
import { DeliveryStatus } from '../constants';
import { DeliveryOrderActions } from '.';
import lodash from 'lodash';
import { CancelDeliveryOrderActionWAIT_GRAB } from '../action/CancelDeliveryOrderAction';
import { AppendTipAction } from '../action';
var SPlatformContainer = styled.div.withConfig({ displayName: "SPlatformContainer", componentId: "sc-1rmqr1l" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  padding: 5px 0 25px 0;\n  flex-direction: column;\n  justify-content: flex-start;\n  .status-text {\n    font-size: 16px;\n    font-family: PingFangSC-Regular, PingFang SC;\n    font-weight: 400;\n    color: #333333;\n    line-height: 22px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n  }\n  .warning-text {\n    margin-top: 6px;\n    font-size: 12px;\n    font-family: PingFangSC-Regular, PingFang SC;\n    font-weight: 400;\n    color: #ff595a;\n    line-height: 17px;\n  }\n  .buttons {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    margin-top: 25px;\n    height: 32px;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  padding: 5px 0 25px 0;\n  flex-direction: column;\n  justify-content: flex-start;\n  .status-text {\n    font-size: 16px;\n    font-family: PingFangSC-Regular, PingFang SC;\n    font-weight: 400;\n    color: #333333;\n    line-height: 22px;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n  }\n  .warning-text {\n    margin-top: 6px;\n    font-size: 12px;\n    font-family: PingFangSC-Regular, PingFang SC;\n    font-weight: 400;\n    color: #ff595a;\n    line-height: 17px;\n  }\n  .buttons {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    margin-top: 25px;\n    height: 32px;\n  }\n"])));
var SCourierButton = styled.div.withConfig({ displayName: "SCourierButton", componentId: "sc-1ckzv63" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 32px;\n  background: #ffffff;\n  border-radius: 3px;\n  border: 1px solid #e1e1e1;\n  font-size: 14px;\n  font-family: PingFangSC-Regular, PingFang SC;\n  font-weight: 400;\n  color: #666666;\n  line-height: 32px;\n  text-align: center;\n  padding: 0 10px;\n"], ["\n  height: 32px;\n  background: #ffffff;\n  border-radius: 3px;\n  border: 1px solid #e1e1e1;\n  font-size: 14px;\n  font-family: PingFangSC-Regular, PingFang SC;\n  font-weight: 400;\n  color: #666666;\n  line-height: 32px;\n  text-align: center;\n  padding: 0 10px;\n"])));
var SGap = styled.div.withConfig({ displayName: "SGap", componentId: "sc-3jlwxj" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 20px;\n  height: 30px;\n"], ["\n  width: 20px;\n  height: 30px;\n"])));
var statusText = function (distriStatus) {
    switch (distriStatus) {
        case DeliveryStatus.WaitingPay:
            return '待支付';
            break;
        case DeliveryStatus.Arrived:
            return '配送员已到店';
            break;
        case DeliveryStatus.Canceled:
            return '配送单已取消';
            break;
        case DeliveryStatus.Compeleted:
            return '已送达';
            break;
        case DeliveryStatus.Delivering:
            return '送货中';
            break;
        case DeliveryStatus.Receiveing:
            return '取件中';
            break;
        case DeliveryStatus.Sending:
            return '生成配送单，正在呼叫配送员';
            break;
        default:
            return '';
            break;
    }
};
// 是否能取消配送
var judgeIfCanCancel = function (distribStatus) {
    return distribStatus < DeliveryStatus.Compeleted;
};
//是否能联系配送员
var judgeIfCanCall = function (distribStatus, phone) {
    return phone && distribStatus > DeliveryStatus.Sending;
};
//是否能添加小费
var judgeIfCanAppendTip = function (distribStatus) {
    return distribStatus === DeliveryStatus.Sending;
};
var DeliveryOrderStatusPlatform = /** @class */ (function (_super) {
    __extends(DeliveryOrderStatusPlatform, _super);
    function DeliveryOrderStatusPlatform() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DeliveryOrderStatusPlatform.prototype.render = function () {
        var deliveryDetail = this.context.deliveryDetail;
        console.log(this.context, 'this.context');
        var distriStatus = deliveryDetail.distriStatus, knightPhone = deliveryDetail.knightPhone, errMsg = deliveryDetail.errMsg, knightName = deliveryDetail.knightName, detailTaskInfo = deliveryDetail.detailTaskInfo;
        var isCanCancel = judgeIfCanCancel(distriStatus);
        var isCanCall = judgeIfCanCall(distriStatus, knightPhone);
        var isAppendTip = judgeIfCanAppendTip(distriStatus);
        var _a = this.props, toggleVisible = _a.toggleVisible, order = _a.order;
        var isMerchants = detailTaskInfo.receiverLists.length > 1;
        return (React.createElement(SPlatformContainer, null,
            React.createElement("div", { className: "status-text" },
                "\u914D\u9001\u5355\u72B6\u6001\uFF1A",
                statusText(distriStatus),
                React.createElement(Icon, { type: "right", style: { fontSize: '16px', color: '#333333' } })),
            errMsg && React.createElement("div", { className: "warning-text" }, errMsg.join(';')),
            (isCanCancel || isAppendTip || knightName) && (React.createElement("div", { className: "buttons" },
                isCanCancel && (React.createElement(React.Fragment, null,
                    lodash.get(order, 'status', 0) === 50 ? (React.createElement(CancelDeliveryOrderActionWAIT_GRAB, { toggleVisible: toggleVisible, platformDelivery: false, isMerchants: isMerchants })) : (React.createElement(DeliveryOrderActions, { toggleVisible: toggleVisible, platformDelivery: false, isMerchants: isMerchants })),
                    React.createElement(SGap, null))),
                isAppendTip && (React.createElement(React.Fragment, null,
                    React.createElement(AppendTipAction, { className: "append-tip-btn", platform: true, parentVisiable: true }),
                    React.createElement(SGap, null))),
                isCanCall && (React.createElement(SCourierButton, null,
                    "\u8054\u7CFB\u914D\u9001\u5458\uFF1A",
                    knightName,
                    " ",
                    knightPhone))))));
    };
    return DeliveryOrderStatusPlatform;
}(React.PureComponent));
export { DeliveryOrderStatusPlatform };
DeliveryOrderStatusPlatform.contextType = PlatformDeliveryContext;
var templateObject_1, templateObject_2, templateObject_3;
