var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import CommonHeader from '@/components/ModalHeader/commonModalHeader';
import { Button, Modal } from 'antd';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
var NOAuth = function (_, ref) {
    var _a = __read(React.useState(false), 2), visible = _a[0], setVisible = _a[1];
    var buttonStyle = { "width": "96px", "height": "40px", "fontSize": "16px" };
    var history = useHistory();
    useImperativeHandle(ref, function () { return setVisible; });
    return (React.createElement(React.Fragment, null, visible && React.createElement(Modal, { visible: true, footer: null, closable: false, className: 'commonModal' },
        React.createElement(CommonHeader, { title: "无操作权限", onCancel: function () {
                setVisible(false);
                history.replace('/');
            } }),
        React.createElement("div", { className: 'content', style: { "color": "#b8b8b8", "fontSize": "17px" } }, "\u8BF7\u8054\u7CFB\u7BA1\u7406\u5458\u8D26\u53F7138****1234"),
        React.createElement("div", { className: 'buttonstyle' },
            React.createElement(Button, { style: buttonStyle, className: 'onok', type: 'primary', onClick: function () { setVisible(false); history.replace('/'); } }, "\u786E\u5B9A")))));
};
export default forwardRef(NOAuth);
