var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { Button } from 'antd';
import { useToggle } from '@hooks';
import './button.less';
import { DisagreeCancelReasonModal } from '../modal';
import { getAuth } from '@/container/AccountManagement/component';
import { useSelector } from 'react-redux';
var DisagreeCancelActioninit = function () {
    var _a = __read(useToggle(false), 3), visible = _a[0], show = _a[1], hide = _a[2];
    var toggleVisible = function () { return (visible ? hide() : show()); };
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return (getAuth("订单处理", menu) ?
        React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: toggleVisible, className: "orderbuttonactive" }, "\u62D2\u7EDD\u53D6\u6D88"),
            React.createElement(DisagreeCancelReasonModal, { visible: visible, toggleVisible: toggleVisible })) : null);
};
export var DisagreeCancelAction = function () {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("订单处理", menu) ? React.createElement(DisagreeCancelActioninit, null) : null;
};
