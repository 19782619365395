var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect } from 'react';
import { Button, message } from 'antd';
import ReceiptForm from './components/ReceiptForm';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { get } from 'lodash';
import ajax from '@utils/ajax';
import { TemplateA4List } from '../../printer/local/templateHelperA4';
import { ReceiptTplWrapper, ReturnToPrintTpl, ReceiptMain, Buttons } from './styled';
var ReceiptTplA4 = function (props) {
    var history = useHistory();
    var code = useParams().code;
    var titleInfo = props.titleInfo, baseInfo = props.baseInfo, signInfo = props.signInfo, serviceInfo = props.serviceInfo, cacheInfo = props.cacheInfo, updateState = props.updateState;
    var goBack = function () {
        history.push('/stores/print/template');
    };
    var validateForm = function () {
        if (!get(titleInfo, 'billName.value', '') || !get(titleInfo, 'billName.checked', false)) {
            message.error('单据名称不能为空');
            return false;
        }
        return true;
    };
    var handleSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var template;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateForm()) {
                        return [2 /*return*/];
                    }
                    template = {
                        titleInfo: titleInfo,
                        baseInfo: baseInfo,
                        signInfo: signInfo,
                        serviceInfo: serviceInfo,
                        cacheInfo: cacheInfo,
                    };
                    if (!code) return [3 /*break*/, 2];
                    return [4 /*yield*/, ajax.post('/printer/a4/template', {
                            code: code,
                            template: template
                        })];
                case 1:
                    _a.sent();
                    message.success('修改成功');
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, ajax.post('/printer/a4/template', {
                        template: template
                    })];
                case 3:
                    _a.sent();
                    message.success('添加成功');
                    _a.label = 4;
                case 4:
                    history.go(-1);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        props.fetchFormData(code);
    }, []);
    return (React.createElement(ReceiptTplWrapper, null,
        React.createElement(ReturnToPrintTpl, null,
            React.createElement("a", { onClick: goBack },
                React.createElement("span", { className: "back" }, "\u2190"),
                "\u8FD4\u56DE\u6253\u5370\u6A21\u7248")),
        React.createElement(ReceiptMain, null,
            React.createElement(TemplateA4List, __assign({}, {
                titleInfo: titleInfo,
                baseInfo: baseInfo,
                signInfo: signInfo,
                serviceInfo: serviceInfo,
                cacheInfo: cacheInfo
            }, { code: code || 'add' })),
            React.createElement(ReceiptForm, { titleInfo: titleInfo, baseInfo: baseInfo, signInfo: signInfo, serviceInfo: serviceInfo, cacheInfo: cacheInfo, updateState: updateState })),
        React.createElement(Buttons, null,
            React.createElement(Button, { type: "primary", onClick: handleSave }, "\u4FDD\u5B58"))));
};
var mapState = function (state) {
    var _a = state.customReceiptA4, titleInfo = _a.titleInfo, baseInfo = _a.baseInfo, signInfo = _a.signInfo, serviceInfo = _a.serviceInfo, cacheInfo = _a.cacheInfo;
    return {
        titleInfo: titleInfo,
        baseInfo: baseInfo,
        signInfo: signInfo,
        serviceInfo: serviceInfo,
        cacheInfo: cacheInfo,
    };
};
var mapDispatch = function (dispatch) { return ({
    fetchFormData: dispatch.customReceiptA4.fetchFormData,
    updateState: dispatch.customReceiptA4.updateState,
}); };
export default connect(mapState, mapDispatch)(ReceiptTplA4);
