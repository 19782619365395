import React, { useEffect } from 'react';
import qs from 'qs';
import ajax from '@utils/ajax';
import { message } from 'antd';
import { useLocation } from 'react-router-dom';
export default function Dada(props) {
    var location = useLocation();
    useEffect(function () {
        try {
            var searchStr = location.search.slice(1);
            var _a = qs.parse(searchStr), ticket = _a.ticket, state = _a.state, sourceId = _a.sourceId, shopNo = _a.shopNo;
            // const { token } = JSON.parse(state as string)
            var token = JSON.parse(decodeURIComponent(searchStr).split("&").filter(function (item) { return item.includes("state="); })[0].replace("state=", '')).token;
            ajax.defaults.headers['Authorization'] = token;
            ajax.defaults.headers['token'] = token;
            ajax
                .post('/distri/platform/dada/auth/data/save', {
                ticket: ticket,
                state: state,
                sourceId: sourceId,
                shopNo: shopNo
            })
                .then(function (back) {
                if (back.code === 0) {
                    message.success('成功绑定达达');
                    var closeTimer_1 = setTimeout(function () {
                        window.close();
                        clearTimeout(closeTimer_1);
                    }, 1000);
                }
                else {
                    message.error(back.msg || back.err);
                }
            });
        }
        catch (ex) {
            message.error('请求参数不合法');
        }
    }, []);
    return React.createElement("div", null, "loading");
}
