export default {
    appDomain: {
        dev: 'https://easyapp-dev.danyisong.com',
        // dev: 'http://localhost:3000',
        test: 'https://easyapp-test.danyisong.com',
        prod: 'https://h5.iyisong.net'
    },
    qiweiDomain: {
        dev: 'https://easy-dev.danyisong.com',
        test: 'https://easy-test.danyisong.com',
        prod: 'https://easy.iyisong.net'
    }
};
