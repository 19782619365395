export function createBarcodeContent(str) {
    if (!str || typeof str !== 'string') {
        throw new Error('param str must be string');
    }
    var chr = '';
    var result = '';
    // 匹配字符集B
    var codeB = [
        '\x30',
        '\x31',
        '\x32',
        '\x33',
        '\x34',
        '\x35',
        '\x36',
        '\x37',
        '\x38',
        '\x39'
    ];
    // 匹配字符集C
    var codeC = [
        '\x00',
        '\x01',
        '\x02',
        '\x03',
        '\x04',
        '\x05',
        '\x06',
        '\x07',
        '\x08',
        '\x09',
        '\x0A',
        '\x0B',
        '\x0C',
        '\x0D',
        '\x0E',
        '\x0F',
        '\x10',
        '\x11',
        '\x12',
        '\x13',
        '\x14',
        '\x15',
        '\x16',
        '\x17',
        '\x18',
        '\x19',
        '\x1A',
        '\x1B',
        '\x1C',
        '\x1D',
        '\x1E',
        '\x1F',
        '\x20',
        '\x21',
        '\x22',
        '\x23',
        '\x24',
        '\x25',
        '\x26',
        '\x27',
        '\x28',
        '\x29',
        '\x2A',
        '\x2B',
        '\x2C',
        '\x2D',
        '\x2E',
        '\x2F',
        '\x30',
        '\x31',
        '\x32',
        '\x33',
        '\x34',
        '\x35',
        '\x36',
        '\x37',
        '\x38',
        '\x39',
        '\x3A',
        '\x3B',
        '\x3C',
        '\x3D',
        '\x3E',
        '\x3F',
        '\x40',
        '\x41',
        '\x42',
        '\x43',
        '\x44',
        '\x45',
        '\x46',
        '\x47',
        '\x48',
        '\x49',
        '\x4A',
        '\x4B',
        '\x4C',
        '\x4D',
        '\x4E',
        '\x4F',
        '\x50',
        '\x51',
        '\x52',
        '\x53',
        '\x54',
        '\x55',
        '\x56',
        '\x57',
        '\x58',
        '\x59',
        '\x5A',
        '\x5B',
        '\x5C',
        '\x5D',
        '\x5E',
        '\x5F',
        '\x60',
        '\x61',
        '\x62',
        '\x63'
    ];
    var length = str.length;
    var b = [
        '\x1b',
        '\x64',
        '\x02',
        '\x1d',
        '\x48',
        '\x32',
        '\x1d',
        '\x68',
        '\x50',
        '\x1d',
        '\x77',
        '\x02',
        '\x1d',
        '\x6b',
        '\x49',
        String(length + 2),
        '\x7b',
        '\x42'
    ];
    // 大于14个字符,且为纯数字的进来这个区间
    if (length > 14 && /^\d+$/.test(str)) {
        b[17] = '\x43';
        var j = 0;
        var key = 18;
        // 初始化数组长度
        var ss = Math.floor(length / 2);
        for (var i = 0; i < ss; i += 1) {
            var temp = str.substr(j, 2);
            var iindex = parseInt(temp, 10);
            j += 2;
            if (iindex === 0) {
                // 判断前面的为字符集B,此时不需要转换字符集
                if (b[key + i - 1] === '0' && b[key + i - 2]) {
                    b[key + i] = codeB[0];
                    b[key + i + 1] = codeB[0];
                    key += 1;
                    // 判断条形码开头前两位数都为0时转换字符集B
                }
                else if (b[key + i - 1] === 'C') {
                    b[key + i - 2] = '\x7b';
                    b[key + i - 1] = '\x42';
                    b[key + i] = codeB[0];
                    b[key + i + 1] = codeB[0];
                    key += 1;
                }
                else {
                    b[key + i] = '\x7b';
                    b[key + i + 1] = '\x42';
                    b[key + i + 2] = codeB[0];
                    b[key + i + 3] = codeB[0];
                    key += 3;
                }
                // 判断前面的为字符集B,此时要转换字符集C
            }
            else if (b[key + i - 1] === '0' &&
                b[key + i - 2] === '0' &&
                iindex !== 89 &&
                chr !== 'chr') {
                b[key + i] = '\x7b';
                b[key + i + 1] = '\x43';
                b[key + i + 2] = codeC[iindex];
                key += 2;
            }
            else {
                var chr = '';
                b[key + i] = codeC[iindex];
                if (iindex == 48)
                    var chr = 'chr';
            }
        }
        // 取得数组的最后一个元素的键
        var lastkey = b.length - 1;
        if (length % 2 > 0) {
            // 取得字符串的最后一个数字
            var lastnum = str.substr(-1);
            // 判断前面的为字符集B,此时不需要转换字符集
            if (b[lastkey] === '0' && b[lastkey - 1] === '0') {
                b.push(codeB[lastnum]);
            }
            else {
                b.push('\x7b', '\x42', codeB[lastnum]);
            }
        }
        // 得出条形码长度
        b[15] = String.fromCharCode(b.length - 16);
        result = b.join('');
        // 1-14个字符的纯数字和非纯数字的条形码进来这个区间，支持数字，大小写字母，特殊字符例如:  !@#$%^&*()-=+_
    }
    else {
        result = "\u001Bd\u0002\u001DH2\u001DhP\u001Dw\u0002\u001DkI" + String.fromCharCode(length + 2) + "{B" + str;
    }
    return result;
}
