var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { DeliveryContext } from '../context';
import { CodeGroup, CodeGroupText, CodeGroupTips, DeliveryCodeView } from './styled';
var DeliveryOrderCodeGroup = /** @class */ (function (_super) {
    __extends(DeliveryOrderCodeGroup, _super);
    function DeliveryOrderCodeGroup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DeliveryOrderCodeGroup.prototype.render = function () {
        var _a = this.context.distriOrder, takeCode = _a.takeCode, receiveCode = _a.receiveCode;
        return (React.createElement(DeliveryCodeView, null,
            React.createElement(CodeGroup, null,
                React.createElement(CodeGroupText, null,
                    "\u53D6\u4EF6\u7801\uFF1A",
                    takeCode),
                React.createElement(CodeGroupText, null,
                    "\u6536\u4EF6\u7801\uFF1A",
                    receiveCode)),
            React.createElement(CodeGroupTips, null,
                React.createElement(CodeGroupText, null, "\u865A\u62DF\u53F7\u53D1\u77ED\u4FE1\u65B9\u5F0F\uFF1A "),
                React.createElement(CodeGroupText, null, "\u5982\u60A8\u9700\u77ED\u4FE1\u8054\u7CFB\u865A\u62DF\u53F7\u7528\u6237\uFF0C\u8BF7\u5728\u6536\u4FE1\u4EBA\u53F7\u7801\u5904\u586B\u519911\u4F4D\u865A\u62DF\u624B\u673A\u53F7\u7801\uFF0C\u5728\u77ED \u4FE1\u7F16\u8F91\u6846\u91CC\u586B\u5199#\u5206\u673A\u53F7#+\u77ED\u4FE1\u5185\u5BB9\uFF0C\u77ED\u4FE1\u5FC5\u987B\u4EE5#\u5F00\u5934\uFF0C\u4E0D\u80FD\u51FA\u73B0\u7A7A\u683C"))));
    };
    return DeliveryOrderCodeGroup;
}(React.PureComponent));
export { DeliveryOrderCodeGroup };
DeliveryOrderCodeGroup.contextType = DeliveryContext;
