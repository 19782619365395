/*
 * @Author: hijack
 * @Date: 2021-06-29 16:29:15
 * @LastEditTime: 2021-07-15 16:56:40
 * @LastEditors: your name
 * @Description: 业务常量
 * @FilePath: /easyex-web/app/container/Stores/constance.ts
 */
export var PlatformName = {
    '-1': '易送',
    '1': '闪送平台',
    '2': '蜂鸟配送',
    '3': '蜂鸟配送',
    '4': '达达',
    '5': '顺丰',
    '6': '美团快速达',
    '7': '美团光速达',
    '8': 'uu跑腿'
};
export var PlatformStatus = {
    0: '待审核',
    1: '审核通过',
    2: '审核驳回'
};
export var EAutoDispatchOpen;
(function (EAutoDispatchOpen) {
    EAutoDispatchOpen[EAutoDispatchOpen["CLOSE"] = 0] = "CLOSE";
    EAutoDispatchOpen[EAutoDispatchOpen["OPEN"] = 1] = "OPEN";
})(EAutoDispatchOpen || (EAutoDispatchOpen = {}));
export var EBizType;
(function (EBizType) {
    EBizType[EBizType["PLATFORM"] = 1] = "PLATFORM";
    EBizType[EBizType["CHANNEL"] = 2] = "CHANNEL";
})(EBizType || (EBizType = {}));
export var EBindTag;
(function (EBindTag) {
    EBindTag[EBindTag["UNBIND"] = 0] = "UNBIND";
    EBindTag[EBindTag["BIND"] = 1] = "BIND";
})(EBindTag || (EBindTag = {}));
export var EStrategyType;
(function (EStrategyType) {
    EStrategyType[EStrategyType["CHEAP"] = 1] = "CHEAP";
    EStrategyType[EStrategyType["SPEED"] = 2] = "SPEED";
})(EStrategyType || (EStrategyType = {}));
