var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useImperativeHandle, forwardRef, useMemo } from 'react';
import { Radio, Checkbox } from 'antd';
import './merchant.less';
var MerchantComponent = function (props, ref) {
    //  const initCity=[{name:"北京",value:"1"},{name:"双江拉祜族佤族布朗族部落",value:"2"},
    //  {name:"广州",value:"3"},
    // ]
    var _a = __read(React.useState([]), 2), initCity = _a[0], setInitCity = _a[1];
    //  const initStore=[{name:"北京分店",value:"1"},{name:"双江拉祜族佤族布朗族分店",value:"2"},{name:"广州分店",value:"3"}]
    var _b = __read(React.useState([]), 2), initStore = _b[0], setInitStore = _b[1];
    var _c = __read(React.useState(null), 2), store = _c[0], setStore = _c[1];
    var _d = __read(React.useState(null), 2), belongStoreType = _d[0], setBelongStoreType = _d[1];
    var _e = __read(React.useState(null), 2), belongStore = _e[0], setBelongStore = _e[1];
    useEffect(function () {
        if (props.value && isNaN(props.value) && JSON.stringify(props.merchatData) !== "{}") {
            var belongStore_1 = props.merchatData.belongStoreCityMsgDtoList.map(function (item, index) {
                return JSON.stringify(item);
            });
            var icity = Array.from(new Set(belongStore_1)).map(function (item) { return JSON.parse(item); });
            var istore = props.merchatData.storeList;
            setInitCity(icity);
            setInitStore(istore);
            setBelongStoreType(props.value.belongStoreType);
            if (props.value.belongStoreType !== 0) {
                setStore(props.value.belongStoreType === 1 ? icity : istore);
                setBelongStore(props.value.belongStore);
            }
        }
    }, [props.value, props.merchatData]);
    useImperativeHandle(ref, function () { return { belongStoreType: belongStoreType, belongStore: belongStore }; });
    var storesDom = useMemo(function () { return storeDom(store); }, [store, belongStore]);
    return (React.createElement("div", null,
        React.createElement(Radio.Group, { onChange: onChange, value: belongStoreType },
            React.createElement(Radio, { value: 0 }, "\u5168\u90E8"),
            React.createElement(Radio, { value: 1 }, "\u6309\u57CE\u5E02"),
            React.createElement(Radio, { value: 2 }, "\u6309\u95E8\u5E97")),
        storesDom));
    function onChange(e) {
        var _a, _b;
        setBelongStoreType((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value);
        // onChange({belongStoreType:e?.target?.value,belongStore:null})
        setStoreFunc((_b = e === null || e === void 0 ? void 0 : e.target) === null || _b === void 0 ? void 0 : _b.value);
    }
    function setStoreFunc(belongStoreTypealias) {
        if (belongStoreTypealias === 0) {
            props.onChange(0);
            setStore(null);
        }
        else {
            props.onChange(null);
            setStore(belongStoreTypealias === 1 ? initCity : initStore);
        }
    }
    function storeDom(storeArray) {
        return (React.createElement(React.Fragment, null, Array.isArray(storeArray) && storeArray.length > 0 ?
            React.createElement("div", { className: 'checkboxstyle' },
                React.createElement(Checkbox.Group, { onChange: onChangeStore, key: belongStoreType, value: belongStore }, storeArray === null || storeArray === void 0 ? void 0 : storeArray.map(function (item, index) {
                    return (React.createElement(Checkbox, { key: item.ID + index, className: "longchar", value: String(item.ID) }, item.name));
                }))) : null));
    }
    function onChangeStore(value, checked) {
        setBelongStore(value);
        props.onChange(value.length > 0 ? belongStoreType : null);
    }
};
export default forwardRef(MerchantComponent);
