var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Input } from 'antd';
import { InputIPItem, InputIPWrapper } from './styled';
var DEFAULT_IP = '0.0.0.0';
var InputIP = /** @class */ (function (_super) {
    __extends(InputIP, _super);
    function InputIP(props) {
        var _this = _super.call(this, props) || this;
        _this.triggerChange = function (ip) {
            var onChange = _this.props.onChange;
            if (onChange) {
                _this.setState({ ip: ip });
                onChange(ip);
            }
        };
        _this.handleFieldChange = function (index, value) {
            var ipArr = _this.state.ip.split('.');
            ipArr[index] = value;
            var ip = ipArr.join('.');
            _this.triggerChange(ip);
        };
        _this.state = {
            ip: props.value || DEFAULT_IP
        };
        return _this;
    }
    InputIP.getDerivedStateFromProps = function (nextProps) {
        if ('value' in nextProps) {
            var ip = nextProps.value || DEFAULT_IP;
            return {
                ip: ip
            };
        }
        return null;
    };
    InputIP.prototype.renderFields = function () {
        var _this = this;
        var ipArr = this.state.ip.split('.');
        return new Array(4).fill('0').map(function (_, index) {
            return (React.createElement(InputIPItem, { key: index },
                React.createElement(Input, { maxLength: 3, value: ipArr[index], onChange: function (_a) {
                        var value = _a.target.value;
                        return _this.handleFieldChange(index, value);
                    } })));
        });
    };
    InputIP.prototype.render = function () {
        return React.createElement(InputIPWrapper, null, this.renderFields());
    };
    return InputIP;
}(React.Component));
export default InputIP;
