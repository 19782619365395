import React from 'react';
import { Row, Col, Button } from 'antd';
import { SelfDeliveryRow, CloseWrapper } from './styled';
import FormModal from '@components/FormModal';
import { useDispatch } from 'react-redux';
import { defaultFormat } from '@utils/time';
export var ViewSelfPickModal = function (props) {
    var remark = props.remark, time = props.time, hide = props.hide;
    var dispatch = useDispatch();
    React.useEffect(function () {
        dispatch.selfDelivery.fetchPlatforms();
    }, []);
    return (React.createElement(FormModal, { title: "\u67E5\u770B\u914D\u9001\u5355", onCancel: hide, visible: true, footer: false },
        React.createElement(SelfDeliveryRow, null,
            React.createElement(Row, null,
                React.createElement(Col, { span: 4 }, "\u81EA\u53D6\u65B9\u5F0F:"),
                React.createElement(Col, { span: 20 }, "\u5BA2\u6237\u5230\u5E97\u81EA\u53D6"))),
        React.createElement(SelfDeliveryRow, null,
            React.createElement(Row, null,
                React.createElement(Col, { span: 4 }, "\u5907\u6CE8:"),
                React.createElement(Col, { span: 20 }, remark))),
        React.createElement(SelfDeliveryRow, null,
            React.createElement(Row, null,
                React.createElement(Col, { span: 4 }, "\u64CD\u4F5C\u65F6\u95F4:"),
                React.createElement(Col, { span: 20 }, defaultFormat(time)))),
        React.createElement(CloseWrapper, null,
            React.createElement(Button, { type: "primary", onClick: hide }, "\u5173\u95ED"))));
};
