import qs from 'qs';
import { DELIVERY_PLATFORM_MAP } from './constants';
var shansongOAuthUriGenerate = function (_a) {
    var appId = _a.appId, state = _a.state, redirectUri = _a.redirectUri, authUrl = _a.authUrl, thirdStoreId = _a.thirdStoreId;
    var redirectUriEncoded = encodeURIComponent(redirectUri + "?type=" + DELIVERY_PLATFORM_MAP.SHANSONG);
    var queryStr = qs.stringify({
        response_type: 'code',
        redirect_uri: redirectUriEncoded,
        client_id: appId,
        state: JSON.stringify(state),
        thirdStoreId: thirdStoreId,
        scope: 'shop_open_api'
    });
    return authUrl + "?" + queryStr;
};
export var getDeliveryOAuthUri = function (_a) {
    var platform = _a.platform, authInfo = _a.authInfo, redirectUri = _a.redirectUri, state = _a.state, thirdStoreId = _a.thirdStoreId;
    switch (platform) {
        case DELIVERY_PLATFORM_MAP.SHANSONG:
            return shansongOAuthUriGenerate({
                appId: authInfo.appId,
                authUrl: authInfo.authUrl,
                state: state,
                redirectUri: redirectUri,
                thirdStoreId: thirdStoreId
            });
        default:
            return redirectUri;
    }
};
