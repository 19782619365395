var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/*
 * @Author: hijack
 * @Date: 2021-06-11 18:27:43
 * @LastEditTime: 2021-07-13 20:03:40
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/container/Orders/modal/ViewPlatformDeliveryModal.tsx
 */
import React from 'react';
import styled from 'styled-components';
import lodash from 'lodash';
import events from '@utils/events';
import FormModal from '@components/FormModal';
import { DeliveryOrderMapPlatform, DeliveryOrderStatusPlatform, DeliveryOrderInfoPlatform, DeliveryOrderProgressPlatform, DeliveryOrderLogPlatform } from '../components';
import { PlatformDeliveryContext } from '../context';
import DeliveryOrderTab from '../components/DeliveryOrderTab';
import { NoData } from './styled';
import { DeliveryStatus } from '../constants';
import ajax from '@utils/ajax';
import { requestForDelivery } from '@utils/request';
import { message } from 'antd';
function getRemainKM(params) {
    return ajax.get('/map/ride/distance', {
        params: params
    });
    // return new Promise(resolve => {
    //   resolve(2)
    // })
}
function getKnightLatLng(distriOrderId) {
    return requestForDelivery.get('/platform/transport/queryExpressOrderEta', {
        expressOrderId: distriOrderId,
    });
    // return new Promise(resolve => {
    //   resolve({
    //     lat: '116.289782',
    //     lng: '40.054676',
    //     distriStatus: {
    //       status: 30,
    //       name: ''
    //     }
    ///   })
    // })
}
var EDeliveryType;
(function (EDeliveryType) {
    EDeliveryType[EDeliveryType["ONLY"] = 1] = "ONLY";
    EDeliveryType[EDeliveryType["MORE"] = 2] = "MORE";
})(EDeliveryType || (EDeliveryType = {}));
var EOneByMoreOrderStatus;
(function (EOneByMoreOrderStatus) {
    EOneByMoreOrderStatus[EOneByMoreOrderStatus["PAID"] = 1] = "PAID";
    EOneByMoreOrderStatus[EOneByMoreOrderStatus["CANCEL"] = 2] = "CANCEL";
    EOneByMoreOrderStatus[EOneByMoreOrderStatus["FINISH"] = 3] = "FINISH";
})(EOneByMoreOrderStatus || (EOneByMoreOrderStatus = {}));
var tabs = [{
        name: '配送单',
        value: '1'
    }, {
        name: '配送进度跟踪',
        value: '2'
    }, {
        name: '配送单记录',
        value: '3'
    }];
var Wrapper = styled.div.withConfig({ displayName: "Wrapper", componentId: "sc-1wwph20" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  .append-tip-btn {\n    margin-top: 14px;\n    margin-right: 10px;\n  }\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  .append-tip-btn {\n    margin-top: 14px;\n    margin-right: 10px;\n  }\n  justify-content: flex-end;\n"])));
var ActionsWrapper = styled.div.withConfig({ displayName: "ActionsWrapper", componentId: "sc-15b9lfj" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  margin-top: 14px;\n"], ["\n  flex: 1;\n  margin-top: 14px;\n"])));
var ViewPlatformDeliveryModal = /** @class */ (function (_super) {
    __extends(ViewPlatformDeliveryModal, _super);
    function ViewPlatformDeliveryModal(props, context) {
        var _this = _super.call(this, props) || this;
        _this.currentPlatform = '1';
        //@ts-ignore
        _this.state = {
            knightCoordinate: '',
            knightLabelText: '',
            timer: 0,
            curruntExpressOrderId: ''
        };
        _this.refresh = function (val, expressOrderId) {
            if (val === void 0) { val = _this.currentPlatform; }
            if (expressOrderId === void 0) { expressOrderId = ''; }
            var _a = _this.context, handleChangeDeliveryPlatformTab = _a.handleChangeDeliveryPlatformTab, order = _a.order;
            _this.currentPlatform = val;
            handleChangeDeliveryPlatformTab(val, expressOrderId);
        };
        _this.handleSwitchTab = function (val) {
            var curruntExpressOrderId = _this.state.curruntExpressOrderId;
            _this.refresh(val, curruntExpressOrderId);
        };
        _this.handleLogCardClick = function (id) {
            var handleClick = _this.orderTabRef.current.handleClick;
            _this.setState({
                curruntExpressOrderId: id
            }, function () {
                handleClick({ value: '1' });
            });
        };
        _this.handleOneByMoreNextReceiver = function () {
            var deliveryDetail = _this.context.deliveryDetail;
            var receiverLists = lodash.get(deliveryDetail, 'detailTaskInfo.receiverLists', []);
            var nextReceiver = '';
            receiverLists.map(function (item) {
                if (nextReceiver &&
                    nextReceiver.infoIndex > item.infoIndex &&
                    item.infoStatus !== EOneByMoreOrderStatus.FINISH) {
                    nextReceiver = item;
                }
                else if (!nextReceiver && item.infoStatus !== EOneByMoreOrderStatus.FINISH) {
                    nextReceiver = item;
                }
            });
            return nextReceiver;
        };
        _this.getAnotherPoint = function (status) {
            var deliveryDetail = _this.context.deliveryDetail;
            switch (status) {
                case DeliveryStatus.Receiveing:
                    return deliveryDetail.storeCoordinate;
                // 已到店
                case DeliveryStatus.Arrived:
                    return '';
                // 已取货
                case DeliveryStatus.Delivering:
                    if (deliveryDetail && deliveryDetail.deliverType === EDeliveryType.ONLY) {
                        return deliveryDetail.receiveCoordinate;
                    }
                    else if (deliveryDetail && deliveryDetail.deliverType === EDeliveryType.MORE) {
                        var receiver = _this.handleOneByMoreNextReceiver();
                        return receiver ? receiver.lng + "," + receiver.lat : message.error('一取多投收件信息获取失败');
                    }
                // 已完成
                case DeliveryStatus.Compeleted:
                    return '';
                default:
                    return '';
            }
        };
        _this.getKnightText = function (status, km) {
            var deliveryDetail = _this.context.deliveryDetail;
            switch (status) {
                // 已接单
                case DeliveryStatus.Receiveing:
                    return "\u8DDD\u79BB\u5E97\u94FA" + km + "km";
                // 已到店
                case DeliveryStatus.Arrived:
                    return "\u9A91\u624B\u5DF2\u5230\u5E97";
                // 已取货
                case DeliveryStatus.Delivering:
                    if (deliveryDetail && deliveryDetail.deliverType === EDeliveryType.ONLY) {
                        return "\u8DDD\u5BA2\u6237" + km + "km";
                    }
                    else if (deliveryDetail && deliveryDetail.deliverType === EDeliveryType.MORE) {
                        var receiver = _this.handleOneByMoreNextReceiver();
                        return "\u8DDD\u3010\u5BA2\u6237" + receiver.infoIndex + "\u3011" + km + "km";
                    }
                // 已完成
                case DeliveryStatus.Compeleted:
                    return "\u5DF2\u9001\u8FBE,\u8BA2\u5355\u5B8C\u6210";
                default:
                    return '';
            }
        };
        _this.updateLocation = function () { return __awaiter(_this, void 0, void 0, function () {
            var deliveryDetail;
            var _this = this;
            return __generator(this, function (_a) {
                deliveryDetail = this.context.deliveryDetail;
                if (deliveryDetail.id && deliveryDetail.distriStatus !== 50) {
                    getKnightLatLng(deliveryDetail.id).then(function (back) { return __awaiter(_this, void 0, void 0, function () {
                        var data, knightCoordinateStr, targetPoint, targetPointStr, _a, lng, lat, res, text, text;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    data = back.data;
                                    if (!data) return [3 /*break*/, 4];
                                    knightCoordinateStr = data.knightCoordinate.trim() || '';
                                    this.setState({
                                        knightCoordinate: knightCoordinateStr
                                    });
                                    targetPoint = this.getAnotherPoint(data.expressOrderStatus);
                                    if (!targetPoint) return [3 /*break*/, 3];
                                    targetPointStr = targetPoint.split(',');
                                    _a = __read(knightCoordinateStr.split(','), 2), lng = _a[0], lat = _a[1];
                                    if (!(lat && lng && !lat.includes('NaN'))) return [3 /*break*/, 2];
                                    return [4 /*yield*/, getRemainKM({
                                            startLat: lat,
                                            startLng: lng,
                                            endLng: targetPointStr[0],
                                            endLat: targetPointStr[1]
                                        })];
                                case 1:
                                    res = _b.sent();
                                    text = this.getKnightText(data.expressOrderStatus, res.data.distance);
                                    this.setState({
                                        knightLabelText: text
                                    });
                                    _b.label = 2;
                                case 2: return [3 /*break*/, 4];
                                case 3:
                                    text = this.getKnightText(data.expressOrderStatus, '');
                                    this.setState({
                                        knightLabelText: text
                                    });
                                    _b.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); });
                }
                return [2 /*return*/];
            });
        }); };
        var deliveryDetail = context.deliveryDetail;
        _this.setState({
            knightCoordinate: deliveryDetail.knightCoordinate || ''
        });
        _this.orderTabRef = React.createRef();
        return _this;
    }
    ViewPlatformDeliveryModal.prototype.componentDidMount = function () {
        var _this = this;
        this.updateLocation();
        var timer = setInterval(function () {
            var deliveryDetail = _this.context.deliveryDetail;
            if (deliveryDetail.knightCoordinate && !deliveryDetail.knightCoordinate.includes('NaN')) {
                _this.updateLocation();
            }
        }, 10000);
        this.setState({
            timer: timer
        });
        // 追加配送小费触发
        events.on('changeTipPlatform', function () {
            // this.refresh()
        });
    };
    ViewPlatformDeliveryModal.prototype.componentWillUnmount = function () {
        clearInterval(this.state.timer);
    };
    ViewPlatformDeliveryModal.prototype.render = function () {
        var _a = this.props, visible = _a.visible, toggleVisible = _a.toggleVisible;
        var _b = this.context, deliveryDetail = _b.deliveryDetail, deliveryProgress = _b.deliveryProgress, deliveryLog = _b.deliveryLog;
        var detailKnightCoordinate = deliveryDetail.knightCoordinate && !deliveryDetail.knightCoordinate.includes('NaN')
            ? deliveryDetail.knightCoordinate
            : '';
        return (React.createElement(FormModal, { className: "send-delivery-modal", title: "\u914D\u9001\u5355\u8BE6\u60C5", visible: visible, onCancel: toggleVisible, footer: null, width: 840, destroyOnClose: true },
            React.createElement(DeliveryOrderTab, { data: tabs, defaultValue: this.currentPlatform, onChange: this.handleSwitchTab, ref: this.orderTabRef }),
            this.currentPlatform === '1' && (!deliveryDetail.id ? (React.createElement(NoData, null, "\u6682\u65E0\u6570\u636E")) : (React.createElement(React.Fragment, null,
                React.createElement(DeliveryOrderStatusPlatform, { toggleVisible: toggleVisible, order: this.context.order }),
                React.createElement(DeliveryOrderMapPlatform, { knightCoordinate: this.state.knightCoordinate || detailKnightCoordinate, knightLabelText: this.state.knightLabelText }),
                React.createElement(DeliveryOrderInfoPlatform, null)))),
            this.currentPlatform === '2' && (!deliveryProgress.length ? (React.createElement(NoData, null, "\u6682\u65E0\u6570\u636E")) : (React.createElement(React.Fragment, null,
                React.createElement(DeliveryOrderProgressPlatform, null)))),
            this.currentPlatform === '3' && (!deliveryLog.length ? (React.createElement(NoData, null, "\u6682\u65E0\u6570\u636E")) : (React.createElement(React.Fragment, null,
                React.createElement(DeliveryOrderLogPlatform, { handleLogCardClick: this.handleLogCardClick }))))));
    };
    return ViewPlatformDeliveryModal;
}(React.PureComponent));
export { ViewPlatformDeliveryModal };
ViewPlatformDeliveryModal.contextType = PlatformDeliveryContext;
var templateObject_1, templateObject_2;
