var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import qs from 'querystring';
import snakeCaseKeys from 'snakecase-keys';
import uuidV4 from 'uuid/v4';
import ajax from '@utils/ajax';
import { CloudPrinterPlatform } from '@utils/constants';
var PrinterStatus;
(function (PrinterStatus) {
    PrinterStatus[PrinterStatus["OFFLINE"] = 0] = "OFFLINE";
    PrinterStatus[PrinterStatus["ONLINE"] = 1] = "ONLINE";
    PrinterStatus[PrinterStatus["PAPEROUT"] = 2] = "PAPEROUT";
    PrinterStatus[PrinterStatus["UNKNOWN"] = 3] = "UNKNOWN";
})(PrinterStatus || (PrinterStatus = {}));
var getYiLianCloudPrinterStatus = function (printerData) { return __awaiter(void 0, void 0, void 0, function () {
    var data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ajax.post('/printer/yilianyun/printer/getprintstatus', printerData)];
            case 1:
                data = _a.sent();
                if (!data.body) {
                    return [2 /*return*/, PrinterStatus.UNKNOWN];
                }
                switch (data.body.state) {
                    case '0':
                        return [2 /*return*/, PrinterStatus.OFFLINE];
                    case '1':
                        return [2 /*return*/, PrinterStatus.ONLINE];
                    case '2':
                        return [2 /*return*/, PrinterStatus.PAPEROUT];
                    default:
                        return [2 /*return*/, PrinterStatus.UNKNOWN];
                }
                return [3 /*break*/, 3];
            case 2:
                error_1 = _a.sent();
                return [2 /*return*/, PrinterStatus.UNKNOWN];
            case 3: return [2 /*return*/];
        }
    });
}); };
var getFeiECloudPrinterStatus = function (printerData) { return __awaiter(void 0, void 0, void 0, function () {
    var data, result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, ajax.post('/proxy/post', {
                    url: 'https://api.feieyun.cn/Api/Open/',
                    contentType: '2',
                    param: __assign(__assign({}, printerData), { apiname: 'Open_queryPrinterStatus' })
                })];
            case 1:
                data = (_a.sent()).data;
                try {
                    result = JSON.parse(data);
                    if (result.ret) {
                        return [2 /*return*/, PrinterStatus.UNKNOWN];
                    }
                    switch (result.data) {
                        case '离线。':
                            return [2 /*return*/, PrinterStatus.OFFLINE];
                        case '在线，工作状态正常。':
                            return [2 /*return*/, PrinterStatus.ONLINE];
                        case '在线，工作状态不正常。':
                            return [2 /*return*/, PrinterStatus.PAPEROUT];
                        default:
                            return [2 /*return*/, PrinterStatus.UNKNOWN];
                    }
                }
                catch (error) {
                    return [2 /*return*/, PrinterStatus.UNKNOWN];
                }
                return [2 /*return*/];
        }
    });
}); };
var getZhongWuPrinterStatus = function (printerData) { return __awaiter(void 0, void 0, void 0, function () {
    var queryStr, data, _a, errNum, retData;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                queryStr = qs.stringify({
                    url: 'http://api.zhongwuyun.com/status',
                    param: JSON.stringify(printerData)
                });
                return [4 /*yield*/, ajax.get("/proxy/get/zhongwuyun?" + queryStr)];
            case 1:
                data = (_b.sent()).data;
                try {
                    _a = JSON.parse(data), errNum = _a.errNum, retData = _a.retData;
                    if (errNum) {
                        return [2 /*return*/, PrinterStatus.UNKNOWN];
                    }
                    switch (retData.status) {
                        case '0':
                            return [2 /*return*/, PrinterStatus.OFFLINE];
                        case '1':
                            return [2 /*return*/, PrinterStatus.ONLINE];
                        case '2':
                            return [2 /*return*/, PrinterStatus.PAPEROUT];
                        default:
                            return [2 /*return*/, PrinterStatus.UNKNOWN];
                    }
                }
                catch (error) {
                    return [2 /*return*/, PrinterStatus.UNKNOWN];
                }
                return [2 /*return*/];
        }
    });
}); };
var getFeiYinCloudPrinterStatus = function (printerData) { return __awaiter(void 0, void 0, void 0, function () {
    var device_no, access_token, queryStr, data, _a, device_status, paper_status;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                device_no = printerData.device_no, access_token = printerData.access_token;
                queryStr = qs.stringify({
                    url: "https://api.open.feyin.net/device/" + device_no + "/status?access_token=" + access_token
                });
                return [4 /*yield*/, ajax.get("/proxy/get/feiyinyun?" + queryStr)];
            case 1:
                data = (_b.sent()).data;
                try {
                    _a = JSON.parse(data), device_status = _a.device_status, paper_status = _a.paper_status;
                    switch (device_status) {
                        case 'offline':
                            return [2 /*return*/, PrinterStatus.OFFLINE];
                        case 'online':
                            switch (paper_status) {
                                case 'nomal':
                                    return [2 /*return*/, PrinterStatus.ONLINE];
                                case 'lack':
                                    return [2 /*return*/, PrinterStatus.PAPEROUT];
                                default:
                                    return [2 /*return*/, PrinterStatus.UNKNOWN];
                            }
                        default:
                            return [2 /*return*/, PrinterStatus.UNKNOWN];
                    }
                }
                catch (error) {
                    return [2 /*return*/, PrinterStatus.UNKNOWN];
                }
                return [2 /*return*/];
        }
    });
}); };
var getXinYeCloudPrinterStatus = function (printerData) { return __awaiter(void 0, void 0, void 0, function () {
    var back, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ajax.post('/printer/xinyeyun/printer/getprintstatus', printerData)];
            case 1:
                back = _a.sent();
                if (back.code !== 0) {
                    return [2 /*return*/, PrinterStatus.UNKNOWN];
                }
                switch (String(back.data)) {
                    case '0':
                        return [2 /*return*/, PrinterStatus.OFFLINE];
                    case '1':
                        return [2 /*return*/, PrinterStatus.ONLINE];
                    case '2':
                        return [2 /*return*/, PrinterStatus.PAPEROUT];
                    default:
                        return [2 /*return*/, PrinterStatus.UNKNOWN];
                }
                return [3 /*break*/, 3];
            case 2:
                error_2 = _a.sent();
                return [2 /*return*/, PrinterStatus.UNKNOWN];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getCloudPrinterStatus = function (_a) {
    var _b = _a.platform, platform = _b === void 0 ? CloudPrinterPlatform.YiLianYun : _b, printerAuthData = _a.printerAuthData;
    return __awaiter(void 0, void 0, void 0, function () {
        var _c, authData, state, feiEPrinterStatus, zhongWuPrinterStatus, feiYinPrinterStatus, xinYePrinterStatus;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _c = platform;
                    switch (_c) {
                        case CloudPrinterPlatform.YiLianYun: return [3 /*break*/, 1];
                        case CloudPrinterPlatform.FeiEYun: return [3 /*break*/, 3];
                        case CloudPrinterPlatform.ZhongWuYun: return [3 /*break*/, 5];
                        case CloudPrinterPlatform.FeiYinYun: return [3 /*break*/, 7];
                        case CloudPrinterPlatform.XinYeYun: return [3 /*break*/, 9];
                    }
                    return [3 /*break*/, 11];
                case 1:
                    authData = __assign(__assign({}, snakeCaseKeys(printerAuthData)), { id: uuidV4() });
                    return [4 /*yield*/, getYiLianCloudPrinterStatus(authData)];
                case 2:
                    state = _d.sent();
                    return [2 /*return*/, Promise.resolve(state)];
                case 3: return [4 /*yield*/, getFeiECloudPrinterStatus(printerAuthData)];
                case 4:
                    feiEPrinterStatus = _d.sent();
                    return [2 /*return*/, Promise.resolve(feiEPrinterStatus)];
                case 5: return [4 /*yield*/, getZhongWuPrinterStatus(printerAuthData)];
                case 6:
                    zhongWuPrinterStatus = _d.sent();
                    return [2 /*return*/, Promise.resolve(zhongWuPrinterStatus)];
                case 7: return [4 /*yield*/, getFeiYinCloudPrinterStatus(printerAuthData)];
                case 8:
                    feiYinPrinterStatus = _d.sent();
                    return [2 /*return*/, Promise.resolve(feiYinPrinterStatus)];
                case 9: return [4 /*yield*/, getXinYeCloudPrinterStatus(printerAuthData)];
                case 10:
                    xinYePrinterStatus = _d.sent();
                    return [2 /*return*/, Promise.resolve(xinYePrinterStatus)];
                case 11: return [2 /*return*/, Promise.resolve(PrinterStatus.UNKNOWN)];
            }
        });
    });
};
export var postYiLianCloudPrintTask = function (_a) {
    var authData = _a.authData, orderId = _a.orderId, content = _a.content, callback = _a.callback;
    return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, ajax.post('/printer/yilianyun/print/index', __assign(__assign({}, snakeCaseKeys(authData)), { id: uuidV4(), origin_id: orderId, content: content }))];
                case 1:
                    data = _b.sent();
                    if (Number(data.error)) {
                        callback(data.error_description);
                    }
                    else {
                        callback();
                    }
                    return [2 /*return*/];
            }
        });
    });
};
export var postFeiECloudPrintTask = function (_a) {
    var authData = _a.authData, content = _a.content, orderId = _a.orderId, callback = _a.callback;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, _b, ret, msg;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, ajax.post('/proxy/post', {
                        url: 'https://api.feieyun.cn/Api/Open/',
                        contentType: '2',
                        param: __assign(__assign({}, authData), { apiname: 'Open_printMsg', content: content })
                    })];
                case 1:
                    data = (_c.sent()).data;
                    try {
                        _b = JSON.parse(data), ret = _b.ret, msg = _b.msg;
                        if (ret) {
                            callback(msg);
                        }
                        else {
                            callback();
                        }
                    }
                    catch (error) {
                        callback('未知打印错误');
                    }
                    return [2 /*return*/];
            }
        });
    });
};
export var postZhongWuPrintTask = function (_a) {
    var authData = _a.authData, printdata = _a.printdata, orderId = _a.orderId, callback = _a.callback;
    return __awaiter(void 0, void 0, void 0, function () {
        var data, _b, errNum, retData;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, ajax.post('/proxy/post/zhongwuyun', {
                        url: 'http://api.zhongwuyun.com/',
                        param: __assign(__assign({}, authData), { printdata: printdata })
                    })];
                case 1:
                    data = (_c.sent()).data;
                    _b = JSON.parse(data), errNum = _b.errNum, retData = _b.retData;
                    if (!errNum) {
                        switch (retData.status) {
                            case '0':
                                callback('打印失败，打印机离线');
                                break;
                            case '1':
                                callback();
                                break;
                            case '2':
                                callback('打印失败，打印机缺纸或装纸不正确');
                                break;
                            default:
                                callback('打印失败');
                                break;
                        }
                    }
                    else {
                        callback('打印失败');
                    }
                    return [2 /*return*/];
            }
        });
    });
};
export var postFeiYinPrintTask = function (_a) {
    var authData = _a.authData, msgContent = _a.msgContent, orderId = _a.orderId, callback = _a.callback;
    return __awaiter(void 0, void 0, void 0, function () {
        var access_token, printParams, data, _b, errcode, errmsg;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    access_token = authData.access_token, printParams = __rest(authData, ["access_token"]);
                    return [4 /*yield*/, ajax.post('/proxy/post/feiyinyun', {
                            url: "https://api.open.feyin.net/msg?access_token=" + access_token,
                            param: __assign(__assign({}, printParams), { msg_content: msgContent })
                        })];
                case 1:
                    data = (_c.sent()).data;
                    try {
                        _b = JSON.parse(data), errcode = _b.errcode, errmsg = _b.errmsg;
                        if (errcode) {
                            callback(errmsg);
                        }
                        else {
                            callback();
                        }
                    }
                    catch (error) {
                        callback('未知打印错误');
                    }
                    return [2 /*return*/];
            }
        });
    });
};
export var cloudPrintLockReq = function (_a) {
    var uuid = _a.uuid, copies = _a.copies;
    return ajax.post('/printer/print/lock', { uuid: uuid, copies: copies });
};
