var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Icon, Tag } from 'antd';
import styled from 'styled-components';
import emptyDataIcon from '@assets/images/no-table-data.png';
export var OrderBox = styled.div.withConfig({ displayName: "OrderBox", componentId: "sc-pqgp1k" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"])));
export var OrderShortcutsWrapper = styled.div.withConfig({ displayName: "OrderShortcutsWrapper", componentId: "sc-9pczu5" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-top: 20px;\n  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.06);\n  background-color: #fff;\n  border-radius: 10px;\n  margin-bottom: 10px;\n  .header {\n    padding: 0 16px 20px 16px;\n    border-bottom: 1px solid #ebebeb;\n    display: flex;\n    justify-content: space-between;\n    .header-left {\n      display: flex;\n    }\n  }\n"], ["\n  padding-top: 20px;\n  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.06);\n  background-color: #fff;\n  border-radius: 10px;\n  margin-bottom: 10px;\n  .header {\n    padding: 0 16px 20px 16px;\n    border-bottom: 1px solid #ebebeb;\n    display: flex;\n    justify-content: space-between;\n    .header-left {\n      display: flex;\n    }\n  }\n"])));
export var OrderFiltersWrapper = styled.div.withConfig({ displayName: "OrderFiltersWrapper", componentId: "sc-zag5oe" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 10px 0;\n"], ["\n  margin: 10px 0;\n"])));
export var OrderContentWrapper = styled.div.withConfig({ displayName: "OrderContentWrapper", componentId: "sc-1160u0g" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 0;\n  flex-grow: 2;\n  display: flex;\n  flex-direction: column;\n"], ["\n  padding: 0;\n  flex-grow: 2;\n  display: flex;\n  flex-direction: column;\n"])));
export var OrderTableWrapper = styled.div.withConfig({ displayName: "OrderTableWrapper", componentId: "sc-1ggg6wh" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  padding-top: 15px;\n  .ant-table-wrapper {\n    flex: 1;\n    overflow: hidden;\n  }\n  .ant-table {\n    th,\n    td {\n      white-space: nowrap;\n    }\n    .table-row-highlight {\n      background: #e9f7f2;\n    }\n  }\n"], ["\n  display: flex;\n  padding-top: 15px;\n  .ant-table-wrapper {\n    flex: 1;\n    overflow: hidden;\n  }\n  .ant-table {\n    th,\n    td {\n      white-space: nowrap;\n    }\n    .table-row-highlight {\n      background: #e9f7f2;\n    }\n  }\n"])));
export var OrderCurrentViewReceipt = styled.div.withConfig({ displayName: "OrderCurrentViewReceipt", componentId: "sc-7fvwwy" })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 350px;\n  /* height: 100vh; */\n  overflow: auto;\n  padding: 20px 0;\n  /* position: fixed; */\n  background: #fff;\n  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);\n  .printer-template {\n    border: none;\n  }\n  .reason-text {\n    margin-bottom: 10px;\n    color: red;\n  }\n"], ["\n  width: 350px;\n  /* height: 100vh; */\n  overflow: auto;\n  padding: 20px 0;\n  /* position: fixed; */\n  background: #fff;\n  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);\n  .printer-template {\n    border: none;\n  }\n  .reason-text {\n    margin-bottom: 10px;\n    color: red;\n  }\n"])));
export var CloseReceiptButton = styled(Icon).withConfig({ displayName: "CloseReceiptButton", componentId: "sc-1xcbqk2" })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  font-size: 16px;\n"], ["\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  font-size: 16px;\n"])));
export var LargeTag = styled(Tag).withConfig({ displayName: "LargeTag", componentId: "sc-1csq0m5" })(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  && {\n    color: #01cccc;\n    background: rgba(1, 204, 204, 0.1);\n    border: 0;\n  }\n"], ["\n  && {\n    color: #01cccc;\n    background: rgba(1, 204, 204, 0.1);\n    border: 0;\n  }\n"])));
export var OrderTag = styled(Tag).withConfig({ displayName: "OrderTag", componentId: "sc-vkpinr" })(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  && {\n    color: #01cccc;\n    background: rgba(1, 204, 204, 0.1);\n    border: 0;\n  }\n"], ["\n  && {\n    color: #01cccc;\n    background: rgba(1, 204, 204, 0.1);\n    border: 0;\n  }\n"])));
export var DeliveryBySelfTag = styled(Tag).withConfig({ displayName: "DeliveryBySelfTag", componentId: "sc-1vprlly" })(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  && {\n    color: #198ffb;\n    background: rgb(236, 246, 255);\n    border: 0;\n  }\n"], ["\n  && {\n    color: #198ffb;\n    background: rgb(236, 246, 255);\n    border: 0;\n  }\n"])));
export var DistributionExceptionTag = styled(Tag).withConfig({ displayName: "DistributionExceptionTag", componentId: "sc-1b9n9uq" })(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  && {\n    color: #01cccc;\n    background: rgba(1, 204, 204, 0.1);\n  }\n"], ["\n  && {\n    color: #01cccc;\n    background: rgba(1, 204, 204, 0.1);\n  }\n"])));
export var TimeOutTag = styled(Tag).withConfig({ displayName: "TimeOutTag", componentId: "sc-pw5wsm" })(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  && {\n    color: #f68001;\n    background: rgba(246, 128, 1, 0.1);\n    border: 0;\n  }\n"], ["\n  && {\n    color: #f68001;\n    background: rgba(246, 128, 1, 0.1);\n    border: 0;\n  }\n"])));
export var OrderContent = styled.div.withConfig({ displayName: "OrderContent", componentId: "sc-18wq6sy" })(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  /* height: calc(100vh -416px) */\n  flex-grow: 2;\n  height: calc(100vh - 300px);\n  display: flex;\n  background: #f6f8fb;\n  .order-list {\n    margin-right: 10px;\n    flex: 1;\n    overflow: auto;\n    padding: 20px;\n    background: #f6f8fb;\n    box-shadow: 0px 2px 15px 0px rgba(0, 12, 40, 0.1);\n    border-radius: 10px;\n  }\n  .order-list::-webkit-scrollbar {\n    display: none;\n  }\n  .right-box {\n    overflow-y: auto;\n    background: #f6f8fb;\n    border-radius: 10px;\n    box-shadow: 0px 2px 15px 0px rgba(0, 12, 40, 0.1);\n    width: 350px;\n  }\n  .right-box::-webkit-scrollbar {\n    display: none;\n  }\n  .pager {\n    display: flex;\n    justify-content: flex-end;\n    margin: 10px 0;\n  }\n  .receipt {\n    width: 350px;\n  }\n"], ["\n  /* height: calc(100vh -416px) */\n  flex-grow: 2;\n  height: calc(100vh - 300px);\n  display: flex;\n  background: #f6f8fb;\n  .order-list {\n    margin-right: 10px;\n    flex: 1;\n    overflow: auto;\n    padding: 20px;\n    background: #f6f8fb;\n    box-shadow: 0px 2px 15px 0px rgba(0, 12, 40, 0.1);\n    border-radius: 10px;\n  }\n  .order-list::-webkit-scrollbar {\n    display: none;\n  }\n  .right-box {\n    overflow-y: auto;\n    background: #f6f8fb;\n    border-radius: 10px;\n    box-shadow: 0px 2px 15px 0px rgba(0, 12, 40, 0.1);\n    width: 350px;\n  }\n  .right-box::-webkit-scrollbar {\n    display: none;\n  }\n  .pager {\n    display: flex;\n    justify-content: flex-end;\n    margin: 10px 0;\n  }\n  .receipt {\n    width: 350px;\n  }\n"])));
export var OrderContentMap = styled.div.withConfig({ displayName: "OrderContentMap", componentId: "sc-1jw7ivo" })(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  /* height: calc(100vh -416px) */\n  flex-grow: 2;\n  height: calc(100vh - 300px);\n  display: flex;\n  background: #f2f2f2;\n  .order-list {\n    margin-right: 10px;\n    flex: 1;\n    overflow: auto;\n    background: #ffffff;\n    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);\n    border-radius: 10px;\n  }\n  .right-box {\n    overflow-y: auto;\n    background: #f6f8fb;\n    border-radius: 10px;\n    box-shadow: 0px 2px 15px 0px rgba(0, 12, 40, 0.1);\n    width: 350px;\n\n    &-blank {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      flex-direction: column;\n      height: 100%;\n\n      img {\n        width: 176px;\n        height: 116px;\n        margin-bottom: 20px;\n      }\n    }\n  }\n  .pager {\n    display: flex;\n    justify-content: flex-end;\n    margin: 10px 0;\n  }\n  .receipt {\n    width: 350px;\n  }\n"], ["\n  /* height: calc(100vh -416px) */\n  flex-grow: 2;\n  height: calc(100vh - 300px);\n  display: flex;\n  background: #f2f2f2;\n  .order-list {\n    margin-right: 10px;\n    flex: 1;\n    overflow: auto;\n    background: #ffffff;\n    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);\n    border-radius: 10px;\n  }\n  .right-box {\n    overflow-y: auto;\n    background: #f6f8fb;\n    border-radius: 10px;\n    box-shadow: 0px 2px 15px 0px rgba(0, 12, 40, 0.1);\n    width: 350px;\n\n    &-blank {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      flex-direction: column;\n      height: 100%;\n\n      img {\n        width: 176px;\n        height: 116px;\n        margin-bottom: 20px;\n      }\n    }\n  }\n  .pager {\n    display: flex;\n    justify-content: flex-end;\n    margin: 10px 0;\n  }\n  .receipt {\n    width: 350px;\n  }\n"])));
export var TipBox = styled.div.withConfig({ displayName: "TipBox", componentId: "sc-12maz7e" })(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  height: 100%;\n  width: 350px;\n  background: #fff;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  border-radius: 10px;\n  .tip {\n    background: rgba(0, 0, 0, 0.6);\n    width: 212px;\n    height: 53px;\n    border-radius: 4px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #fff;\n  }\n"], ["\n  height: 100%;\n  width: 350px;\n  background: #fff;\n  align-items: center;\n  justify-content: center;\n  display: flex;\n  border-radius: 10px;\n  .tip {\n    background: rgba(0, 0, 0, 0.6);\n    width: 212px;\n    height: 53px;\n    border-radius: 4px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #fff;\n  }\n"
    // interface EmptyDataProps {
    //   height: number
    // }
])));
// interface EmptyDataProps {
//   height: number
// }
export var EmptyData = styled.div.withConfig({ displayName: "EmptyData", componentId: "sc-1f1qi7h" })(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  height: 500px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  color: #666666;\n  &:before {\n    content: '';\n    display: flex;\n    background: url(", ") no-repeat;\n    width: 147px;\n    height: 87px;\n    margin-bottom: 20px;\n  }\n"], ["\n  height: 500px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  color: #666666;\n  &:before {\n    content: '';\n    display: flex;\n    background: url(", ") no-repeat;\n    width: 147px;\n    height: 87px;\n    margin-bottom: 20px;\n  }\n"])), emptyDataIcon);
export var Table = styled.div.withConfig({ displayName: "Table", componentId: "sc-mro9s9" })(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  .title {\n    font-size: 16px;\n    color: rgba(0, 0, 0, 0.65);\n    font-weight: bold;\n    display: flex;\n    .name {\n      width: 220px;\n    }\n    .count {\n    }\n    .money {\n      margin-left: 10px;\n    }\n  }\n  .line {\n    margin-bottom: 10px;\n  }\n  .row {\n    margin-bottom: 10px;\n    .name {\n      min-width: 220px;\n    }\n    .quantity {\n    }\n    .price {\n      margin-left: 20px;\n    }\n  }\n"], ["\n  .title {\n    font-size: 16px;\n    color: rgba(0, 0, 0, 0.65);\n    font-weight: bold;\n    display: flex;\n    .name {\n      width: 220px;\n    }\n    .count {\n    }\n    .money {\n      margin-left: 10px;\n    }\n  }\n  .line {\n    margin-bottom: 10px;\n  }\n  .row {\n    margin-bottom: 10px;\n    .name {\n      min-width: 220px;\n    }\n    .quantity {\n    }\n    .price {\n      margin-left: 20px;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
