export var ReportModule;
(function (ReportModule) {
    ReportModule[ReportModule["Order"] = 0] = "Order";
    ReportModule[ReportModule["Channel"] = 1] = "Channel";
    ReportModule[ReportModule["OrderHeapMap"] = 2] = "OrderHeapMap";
    ReportModule[ReportModule["Rank"] = 3] = "Rank";
})(ReportModule || (ReportModule = {}));
export var DateRangeType;
(function (DateRangeType) {
    DateRangeType["Today"] = "Today";
    DateRangeType["Yesterday"] = "Yesterday";
    DateRangeType["LastWeek"] = "LastWeek";
    DateRangeType["LastMonth"] = "LastMonth";
    DateRangeType["LastQuarter"] = "LastQuarter";
    DateRangeType["Custom"] = "Custom";
})(DateRangeType || (DateRangeType = {}));
