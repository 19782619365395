var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Radio, Tooltip, message } from 'antd';
import Form from 'antd/lib/form/Form';
import moment from 'moment';
import ReservationTime from '@components/ReservationTime';
import { requestForCoupon } from '@utils/request';
import { SendDeliveryCouponContext } from '../../context';
import DeliveryPlatform from './components/DeliveryPlatform';
import RechargeModal from '../../modal/RechargeModal';
import CouponListModal from '../../modal/CouponListModal';
import { EDeliveryType } from '../../constants';
import './index.less';
import timeIcon from '@assets/images/time.png';
import tipsIcon from '@assets/images/tips.png';
import couponIcon from '@assets/images/coupon.png';
import callTypeIcon from '@assets/images/callType.png';
import declaredAmountIcon from '@assets/images/insurance.png';
import remarkIcon from '@assets/images/comment.png';
import tableftBg from '@assets/images/tableft.png';
import tabrightBg from '@assets/images/tabright.png';
import lodash from 'lodash';
import InsuranceComponent from '../../components/InsuranceComponent';
import { Spin } from 'antd4';
var FormItem = Form.Item;
var RadioButton = Radio.Button;
var RadioGroup = Radio.Group;
var formItemLayout = {
    labelCol: { span: 0 }
};
function useCallbackState(state) {
    var cbRef = useRef();
    var _a = __read(useState(state), 2), data = _a[0], setData = _a[1];
    useEffect(function () {
        cbRef.current && cbRef.current(data);
    }, [data]);
    return [
        data,
        function (val, callback) {
            cbRef.current = callback;
            setData(val);
        }
    ];
}
export var initText = '保价';
var SendDeliveryCalculate = forwardRef(function (props, ref) {
    var _a;
    var insurance = useRef();
    var remarksTimer = useRef();
    useImperativeHandle(ref, function () { return ({
        form: props.form,
        declaredAmount: insurance.current || 0
    }); });
    var history = useHistory();
    var _b = __read(useState(false), 2), couponVisible = _b[0], setCouponVisible = _b[1];
    var _c = __read(useState(false), 2), rechargeVisible = _c[0], setrechargeVisible = _c[1];
    var _d = __read(useCallbackState({
        couponData: [],
        selectCouponPlatformId: '',
        couponGroupId: -1,
        couponGroupName: '',
        globalCouponId: -1,
        globalCouponName: '系统推荐最低价',
        isGlobalCouponModal: true
    }), 2), coupon = _d[0], setCoupon = _d[1];
    var _e = __read(useState(false), 2), InsuranceVis = _e[0], setInsurance = _e[1];
    var _f = __read(useState(initText), 2), text = _f[0], setText = _f[1];
    useEffect(function () {
        setCoupon(__assign(__assign({}, coupon), { globalCouponId: -1, globalCouponName: '系统推荐最低价' }));
    }, [props.oneByMoreData]);
    var _g = props.form, getFieldDecorator = _g.getFieldDecorator, getFieldValue = _g.getFieldValue, getFieldsValue = _g.getFieldsValue, setFieldsValue = _g.setFieldsValue;
    var handleTriggerChange = function () {
        var triggerChange = props.triggerChange, handleTipChange = props.handleTipChange;
        var changeTimer = setImmediate(function () {
            var values = getFieldsValue();
            triggerChange && triggerChange(values);
            // triggerChange && triggerChange(values, () => {
            //   // handleTipChange(values.tip)
            // })
            clearImmediate(changeTimer);
        });
    };
    var handleRemarksChange = function () {
        var triggerChange = props.triggerChange;
        try {
            clearTimeout(remarksTimer.current);
            remarksTimer.current = null;
            remarksTimer.current = setTimeout(function () {
                var values = getFieldsValue();
                triggerChange && triggerChange(values);
            }, 800);
        }
        catch (error) {
            console.log(error);
        }
    };
    var handleFetchCoupon = function (_a) {
        var _b;
        var expressPartnerId = _a.expressPartnerId, callback = _a.callback, couponParams = _a.couponParams;
        var valuations = props.valuations, totalDistance = props.totalDistance;
        var cityCode = (valuations && ((_b = valuations[0]) === null || _b === void 0 ? void 0 : _b.cityCode)) || '';
        requestForCoupon
            .get('/coupon/listCanUseCoupons', {
            distance: totalDistance,
            cityCode: cityCode,
            expressPartnerId: expressPartnerId
        })
            .then(function (back) {
            if (back.data) {
                setCoupon(__assign(__assign(__assign({}, coupon), couponParams), { couponData: back.data }));
                if (callback) {
                    callback();
                }
            }
            else {
                message.error(back.err);
            }
        });
    };
    var handleOpenCouponModal = function (_a) {
        var isGlobalCouponModal = _a.isGlobalCouponModal, couponGroupId = _a.couponGroupId, selectCouponPlatformId = _a.selectCouponPlatformId;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, setCoupon(__assign(__assign({}, coupon), { isGlobalCouponModal: isGlobalCouponModal, couponGroupId: couponGroupId || '', selectCouponPlatformId: selectCouponPlatformId || '' }), function (data) {
                            if (isGlobalCouponModal) {
                                handleFetchCoupon({
                                    callback: function () { return setCouponVisible(!couponVisible); },
                                    couponParams: {
                                        isGlobalCouponModal: isGlobalCouponModal,
                                        couponGroupId: couponGroupId || '',
                                        selectCouponPlatformId: selectCouponPlatformId || ''
                                    }
                                });
                            }
                            else {
                                handleFetchCoupon({
                                    expressPartnerId: selectCouponPlatformId,
                                    callback: function () { return setCouponVisible(!couponVisible); },
                                    couponParams: {
                                        isGlobalCouponModal: isGlobalCouponModal,
                                        couponGroupId: couponGroupId || '',
                                        selectCouponPlatformId: selectCouponPlatformId || ''
                                    }
                                });
                            }
                        })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    var handleCalculate = function (_a) {
        var selectCouponItem = _a.selectCouponItem, callback = _a.callback;
        var triggerChange = props.triggerChange;
        var isGlobalCouponModal = coupon.isGlobalCouponModal, selectCouponPlatformId = coupon.selectCouponPlatformId;
        var valuations = props.valuations;
        if (isGlobalCouponModal) {
            setCoupon(__assign(__assign({}, coupon), { globalCouponId: selectCouponItem.id, globalCouponName: selectCouponItem.name }));
        }
        else {
            setCoupon(__assign(__assign({}, coupon), { couponGroupId: selectCouponItem.id, couponGroupName: selectCouponItem.name }));
        }
        var useCoupon = true;
        var expressPartnerCouponInfos = [];
        if (isGlobalCouponModal) {
            if (selectCouponItem.id === 0) {
                useCoupon = false;
            }
            if (![0, -1].includes(selectCouponItem.id)) {
                valuations.map(function (item) {
                    expressPartnerCouponInfos.push({
                        expressPartnerId: item.platform,
                        couponGroupId: selectCouponItem.id
                    });
                });
            }
        }
        else {
            valuations.map(function (item) {
                expressPartnerCouponInfos.push({
                    expressPartnerId: item.platform,
                    couponGroupId: item.platform === selectCouponPlatformId
                        ? [0].includes(selectCouponItem.id)
                            ? null
                            : selectCouponItem.id
                        : item.couponGroupId || null
                });
            });
        }
        triggerChange &&
            triggerChange({
                useCoupon: useCoupon,
                expressPartnerCouponInfos: expressPartnerCouponInfos
            }, function () {
                callback && callback();
            });
    };
    var handleSelectPlatformItem = function (item) {
        var platformInit = props.platformInit;
        var setFieldsValue = props.form.setFieldsValue;
        var platformChecked = platformInit.platformChecked, handleUpdatePlatformChecked = platformInit.handleUpdatePlatformChecked;
        if (platformChecked.includes(item.platform)) {
            var currentPlatform_1 = platformChecked.filter(function (record) { return item.platform !== record; });
            handleUpdatePlatformChecked(currentPlatform_1, function () {
                // this.triggerChange()
                setFieldsValue({ platform: currentPlatform_1 });
            });
        }
        else {
            platformChecked.push(item.platform);
            handleUpdatePlatformChecked(Object.assign([], platformChecked), function () {
                // this.triggerChange()
                setFieldsValue({ platform: platformChecked });
            });
        }
    };
    var handleRechargeVisible = function () {
        addMoneyInfo();
        // setrechargeVisible(!rechargeVisible)
    };
    var handleBindDelivery = function () {
        history.push('/stores/delivery_platform');
    };
    var platformInit = props.platformInit, valuations = props.valuations, currentTab = props.currentTab, oneByMoreData = props.oneByMoreData, showSwitchTab = props.showSwitchTab, handleSwitchTab = props.handleSwitchTab;
    var couponData = coupon.couponData, selectCouponPlatformId = coupon.selectCouponPlatformId, couponGroupId = coupon.couponGroupId, couponGroupName = coupon.couponGroupName, platformChecked = coupon.platformChecked, globalCouponId = coupon.globalCouponId, globalCouponName = coupon.globalCouponName, isGlobalCouponModal = coupon.isGlobalCouponModal;
    var balance = platformInit.balance;
    var payAccountTypes = (oneByMoreData && ((_a = oneByMoreData[0]) === null || _a === void 0 ? void 0 : _a.payAccountTypes)) || '2';
    var showDateTimePicker = getFieldValue('appointType') === 1;
    var prefixCls = 'sendDeliveryCalculate';
    var tempPay = showSwitchTab
        ? payAccountTypes.length < 2
            ? [2, 1]
            : payAccountTypes
        : payAccountTypes;
    return (React.createElement("div", { className: prefixCls },
        React.createElement(Form, __assign({}, formItemLayout),
            React.createElement("div", { className: prefixCls + "-panel" },
                showSwitchTab && (React.createElement("div", { className: prefixCls + "-panel-tab", style: {
                        backgroundImage: tempPay.indexOf(currentTab) === 0
                            ? "url(" + tableftBg + ")"
                            : "url(" + tabrightBg + ")"
                    } }, tempPay.map(function (item) { return (React.createElement("div", { className: "\n                    " + prefixCls + "-panel-tab-item \n                    " + (currentTab === item && prefixCls + "-panel-tab-active") + "\n                  ", onClick: handleSwitchTab(item), key: item }, item === EDeliveryType.Channel ? '自有账号' : '通用号')); }))),
                React.createElement("div", { className: prefixCls + "-panel-content" },
                    React.createElement("div", { className: prefixCls + "-panel-list" },
                        React.createElement("div", { className: prefixCls + "-panel-list-left" },
                            React.createElement("img", { src: timeIcon, alt: "" }),
                            React.createElement("div", { className: prefixCls + "-panel-list-left-title" }, "\u53D6\u4EF6\u65F6\u95F4")),
                        React.createElement("div", { className: prefixCls + "-panel-list-right" },
                            React.createElement(FormItem, null, getFieldDecorator('appointType', {
                                initialValue: 0,
                                rules: [
                                    {
                                        required: true
                                    }
                                ]
                            })(React.createElement(RadioGroup, { onChange: handleTriggerChange, buttonStyle: "solid" },
                                React.createElement(RadioButton, { value: 0 }, "\u7ACB\u5373\u53D6\u4EF6"),
                                React.createElement(RadioButton, { value: 1 }, "\u9884\u7EA6\u53D6\u4EF6")))))),
                    showDateTimePicker ? (React.createElement("div", { className: prefixCls + "-panel-list" },
                        React.createElement("div", { className: prefixCls + "-panel-list-left" }),
                        React.createElement("div", { className: prefixCls + "-panel-list-right" },
                            React.createElement(FormItem, null, getFieldDecorator('reservationTime', {
                                initialValue: moment(moment()
                                    .add(2, 'h')
                                    .add(10, 'm'), 'YYYY-MM-DD HH:mm'),
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择预约时间'
                                    }
                                ]
                            })(React.createElement(ReservationTime, { triggerChange: handleTriggerChange })))))) : null,
                    React.createElement("div", { className: prefixCls + "-panel-list" },
                        React.createElement("div", { className: prefixCls + "-panel-list-left" },
                            React.createElement("img", { src: tipsIcon, alt: "" }),
                            React.createElement("div", { className: prefixCls + "-panel-list-left-title" }, "\u52A0\u5C0F\u8D39")),
                        React.createElement("div", { className: prefixCls + "-panel-list-right" },
                            React.createElement(FormItem, null, getFieldDecorator('tip', {
                                initialValue: ''
                            })(React.createElement(Input, { min: 0, type: "number", allowClear: true, suffix: React.createElement(Tooltip, { title: "\u5C0F\u8D39" },
                                    React.createElement("div", null, "\u5143")), onChange: handleTriggerChange, placeholder: "\u8F93\u5165\u5C0F\u8D39\u91D1\u989D" }))))),
                    currentTab === EDeliveryType.Platform ? (
                    // oneByMoreData.length > 1
                    React.createElement("div", { className: prefixCls + "-panel-list" },
                        React.createElement("div", { className: prefixCls + "-panel-list-left" },
                            React.createElement("img", { src: couponIcon, alt: "" }),
                            React.createElement("div", { className: prefixCls + "-panel-list-left-title" }, "\u4F18\u60E0\u5238")),
                        React.createElement("div", { className: prefixCls + "-panel-list-right", style: { cursor: 'pointer' } },
                            React.createElement(FormItem, null, getFieldDecorator('platformCoupon', {
                                initialValue: { globalCouponId: globalCouponId }
                            })(React.createElement("div", { className: prefixCls + "-panel-list-right-coupon", onClick: function () {
                                    return handleOpenCouponModal({
                                        isGlobalCouponModal: true
                                    });
                                } }, globalCouponName)))))) : null,
                    isInsuranceAmount() ? (React.createElement("div", { className: prefixCls + "-panel-list" },
                        React.createElement("div", { className: prefixCls + "-panel-list-left" },
                            React.createElement("img", { src: declaredAmountIcon, alt: "" }),
                            React.createElement("div", { className: prefixCls + "-panel-list-left-title" }, "\u7269\u54C1\u4FDD\u4EF7")),
                        React.createElement("div", { className: prefixCls + "-panel-list-right insurance" },
                            React.createElement(FormItem, null, getFieldDecorator('dispatchType2', {
                                initialValue: 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择保价方式'
                                    }
                                ]
                            })(React.createElement(RadioGroup, { buttonStyle: "solid" },
                                React.createElement(RadioButton, { value: 1, onClick: function () {
                                        setText(initText);
                                        declaredAmountforgetPrice(0);
                                    } }, "\u4E0D\u4FDD\u4EF7"),
                                React.createElement(RadioButton, { value: -1, onClick: function () {
                                        setInsurance(true);
                                    } }, text === initText ? initText : text + '元'))))))) : null,
                    currentTab === EDeliveryType.Channel &&
                        oneByMoreData.length === 1 && (React.createElement("div", { className: prefixCls + "-panel-list" },
                        React.createElement("div", { className: prefixCls + "-panel-list-left" },
                            React.createElement("img", { src: callTypeIcon, alt: "" }),
                            React.createElement("div", { className: prefixCls + "-panel-list-left-title" }, "\u547C\u53EB\u65B9\u5F0F")),
                        React.createElement("div", { className: prefixCls + "-panel-list-right" },
                            React.createElement(FormItem, null, getFieldDecorator('dispatchType', {
                                initialValue: 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择呼叫方式'
                                    }
                                ]
                            })(React.createElement(RadioGroup, { onChange: handleTriggerChange, buttonStyle: "solid" },
                                React.createElement(RadioButton, { value: 1 }, "\u4F4E\u4EF7\u4F18\u5148"),
                                React.createElement(RadioButton, { value: 2 }, "\u901F\u5EA6\u4F18\u5148"))))))),
                    React.createElement("div", { className: prefixCls + "-panel-list" },
                        React.createElement("div", { className: prefixCls + "-panel-list-left" },
                            React.createElement("img", { src: remarkIcon, alt: "" }),
                            React.createElement("div", { className: prefixCls + "-panel-list-left-title" }, "\u53D6\u4EF6\u5907\u6CE8")),
                        React.createElement("div", { className: prefixCls + "-panel-list-right" },
                            React.createElement(FormItem, null, getFieldDecorator('remarks', {
                                initialValue: ''
                            })(React.createElement(Input, { placeholder: "\u5907\u6CE8", onChange: handleRemarksChange }))))))),
            currentTab === EDeliveryType.Channel ? (React.createElement("div", { className: prefixCls + "-balance" },
                React.createElement("div", { className: prefixCls + "-balance-left" }, "\u9009\u62E9\u8FD0\u529B"),
                React.createElement("div", { className: prefixCls + "-balance-right", onClick: handleBindDelivery }, "\u7ED1\u5B9A\u8FD0\u529B"))) : (React.createElement("div", { className: prefixCls + "-balance" },
                React.createElement("div", { className: prefixCls + "-balance-left" },
                    "\u8D26\u6237\u4F59\u989D",
                    React.createElement("span", { style: { fontSize: '14px' } }, "\u00A5"),
                    React.createElement("span", { style: { paddingLeft: '5px' } }, balance / 100 || '')),
                React.createElement("div", { className: prefixCls + "-balance-right", onClick: handleRechargeVisible }, "\u7ACB\u5373\u5145\u503C"))),
            props.loading ? (React.createElement("div", { style: { textAlign: 'center' } },
                React.createElement(Spin, null))) : (React.createElement(FormItem, null, getFieldDecorator('platform', {
                initialValue: platformInit.platformChecked || [],
                rules: [
                    {
                        required: true,
                        message: '请选择配送平台'
                    }
                ]
            })(React.createElement("div", { style: { textAlign: 'center' } }, valuations && !!valuations.length
                ? valuations.map(function (valuation) {
                    var platform = valuation.platform, amount = valuation.amount, platformName = valuation.platformName, reducedPrice = valuation.reducedPrice, errMsg = valuation.errMsg, distance = valuation.distance, couponGroupName = valuation.couponGroupName, couponGroupId = valuation.couponGroupId;
                    return (React.createElement(DeliveryPlatform, { key: platform, platform: isQuickLySong() ? 'kuaisong' : platform, platformName: isQuickLySong() ? '快送' : platformName, amount: amount, reducedPrice: reducedPrice, selected: platformInit.platformChecked.includes(platform), errorMsg: errMsg, quickLySongText: isQuickLySong()
                            ? '快送与闪送联合经营并支持一取多投件'
                            : '', distance: distance, couponGroupName: couponGroupName, couponGroupId: couponGroupId, onClick: function () { return handleSelectPlatformItem(valuation); }, handleOpenCouponModal: handleOpenCouponModal, currentTab: currentTab, oneByMoreData: oneByMoreData }));
                })
                : '暂无配送平台'))))),
        React.createElement(RechargeModal, { visible: rechargeVisible, toggleVisible: handleRechargeVisible }),
        React.createElement(SendDeliveryCouponContext.Provider, { value: {
                handleCalculate: handleCalculate,
                couponData: couponData,
                selectCouponPlatformId: selectCouponPlatformId,
                couponGroupId: couponGroupId,
                couponGroupName: couponGroupName,
                platformChecked: platformChecked,
                globalCouponId: globalCouponId,
                globalCouponName: globalCouponName,
                isGlobalCouponModal: isGlobalCouponModal
            } },
            React.createElement(CouponListModal, { visible: couponVisible, toggleVisible: function () { return setCouponVisible(!couponVisible); } })),
        React.createElement(InsuranceComponent, { ref: insurance, visible: InsuranceVis, price: Number(lodash.get(oneByMoreData, '[0].totalPrice', 0)), onOk: onOk, onCancel: onCancel })));
    function onOk(value) {
        setText(value);
        onCancel(value);
        // handleSwitchTab(EDeliveryType.Channel)
        declaredAmountforgetPrice(value);
    }
    function declaredAmountforgetPrice(amount) {
        props.fn({ tab: 2, declaredAmount: amount });
    }
    function onCancel(value) {
        var setFieldsValue = props.form.setFieldsValue;
        setInsurance(false);
        if (isNaN(value) && text === '保价') {
            setFieldsValue({ dispatchType2: 1 });
        }
        if (value === '不保价') {
            setFieldsValue({ dispatchType2: 1 });
            setText(initText);
            declaredAmountforgetPrice(0);
        }
    }
    function isInsuranceAmount() {
        if (currentTab === EDeliveryType.Channel && oneByMoreData.length === 1) {
            return oneByMoreData[0].storeType === 3;
        }
        return false;
    }
    function isQuickLySong() {
        return currentTab === EDeliveryType.Channel && oneByMoreData.length > 1;
    }
});
export default Form.create()(SendDeliveryCalculate);
export function addMoneyInfo() {
    return message.info('请联系客服升级新产品，客服电话：18701369036');
}
