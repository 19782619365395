import { StoreCode, BasicCode, MenuCode, FeeCode, CustomCode } from '../../enums/codes';
export var dataMap = [
    [StoreCode.StoreName, 'storeName'],
    [StoreCode.ReceiptName, 'receiptName'],
    [BasicCode.Platform, 'platform'],
    [BasicCode.PlatformDaySn, 'platformDaySn'],
    [BasicCode.PlatformId, 'platformId'],
    [BasicCode.DaySn, 'daySn'],
    [BasicCode.OrderTime, 'orderTime'],
    [BasicCode.DeliverTime, 'deliverTime'],
    [BasicCode.Description, 'description'],
    [BasicCode.ConsigneeAddress, 'consigneeAddress'],
    [BasicCode.ConsigneePhone, 'consigneePhone'],
    [BasicCode.Consignee, 'consignee'],
    [BasicCode.Invoice, 'invoice'],
    [BasicCode.Bless, 'bless'],
    [BasicCode.PreOrder, 'preOrder'],
    [BasicCode.PreOrderPerson, 'booker'],
    [MenuCode.Name, 'name'],
    [MenuCode.Quantity, 'quantity'],
    [MenuCode.Total, 'total'],
    [MenuCode.Package, 'package'],
    [MenuCode.Spec, 'spec'],
    [FeeCode.PackageFee, 'packageFee'],
    [FeeCode.DeliverFee, 'deliverFee'],
    [FeeCode.OriginalPrice, 'originalPrice'],
    [FeeCode.ReducedPrice, 'reducedPrice'],
    [FeeCode.TotalPrice, 'totalPrice'],
    [FeeCode.Activity, 'activities'],
    [CustomCode.Image, 'image'],
    [CustomCode.Text, 'text'],
    [CustomCode.Sign, 'sign'],
    [CustomCode.EasyBarCode, 'easyBarCode'],
    [CustomCode.MeituanBarCode, 'meituanBarCode']
];
export default dataMap;
