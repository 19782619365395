var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Form, Input, message } from 'antd';
import PropTypes from 'prop-types';
import { requestForMerchant } from '@/utils/request';
import { isElectron } from '@utils/env';
import { PASSWORD_REGEX } from '@utils/regex';
import { ResetPwdButton } from './styled';
var FormItem = Form.Item;
var ResetForm = /** @class */ (function (_super) {
    __extends(ResetForm, _super);
    function ResetForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleReset = function () {
            var validateFields = _this.props.form.validateFields;
            var token = _this.props.token;
            validateFields(function (errors, values) {
                if (errors)
                    return false;
                var data = __assign(__assign({ token: token }, values), { newPassword: values.password });
                requestForMerchant.post('/merchant/account/changePassword', data).then(function () {
                    message.success('修改密码成功');
                    _this.props.handleClose();
                    if (isElectron) {
                        var config = require('../../../config').default;
                        config.set('account', { password: '' });
                    }
                    if (window.orderSocket) {
                        window.orderSocket.close();
                    }
                });
            });
        };
        _this.checkPwdStrong = function (rule, password, callback) {
            if (PASSWORD_REGEX.test(password)) {
                return callback();
            }
            return callback('密码不符合规则');
        };
        _this.checkRepeatPwd = function (rule, confirmPassword, callback) {
            var password = _this.props.form.getFieldValue('password');
            if (password === confirmPassword) {
                return callback();
            }
            callback('两次输入的密码不一致');
        };
        return _this;
    }
    ResetForm.prototype.render = function () {
        var getFieldDecorator = this.props.form.getFieldDecorator;
        return (React.createElement(Form, null,
            React.createElement(FormItem, null, getFieldDecorator('password', {
                rules: [
                    {
                        required: true,
                        validator: this.checkPwdStrong
                    }
                ]
            })(React.createElement(Input.Password, { placeholder: "\u8BF7\u8F93\u5165\u65B0\u5BC6\u7801" }))),
            React.createElement(FormItem, null, getFieldDecorator('confirmPassword', {
                rules: [
                    {
                        required: true,
                        validator: this.checkRepeatPwd
                    }
                ]
            })(React.createElement(Input.Password, { placeholder: "\u8BF7\u518D\u6B21\u8F93\u5165\u65B0\u5BC6\u7801" }))),
            React.createElement(ResetPwdButton, { type: "primary", block: true, onClick: this.handleReset }, "\u91CD\u7F6E\u5BC6\u7801")));
    };
    ResetForm.propTypes = {
        token: PropTypes.string.isRequired
    };
    ResetForm.defaultProps = {
        token: ''
    };
    return ResetForm;
}(React.PureComponent));
var WrappedResetForm = Form.create()(ResetForm);
export default WrappedResetForm;
