var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { DELIVERY_PLATFORM_TEXT_MAP } from '@utils/constants';
import { DeliveryContext } from '../context';
var OtherInfo = styled.div.withConfig({ displayName: "OtherInfo", componentId: "sc-6l5rb9" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: 1px solid #e8e8e8;\n"], ["\n  border-bottom: 1px solid #e8e8e8;\n"])));
var Line = styled(Row).withConfig({ displayName: "Line", componentId: "sc-rnh8jn" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 22px;\n"], ["\n  margin-bottom: 22px;\n"])));
var DeliveryOrderDetail = /** @class */ (function (_super) {
    __extends(DeliveryOrderDetail, _super);
    function DeliveryOrderDetail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DeliveryOrderDetail.prototype.render = function () {
        var _a = this.context.distriOrder, rewardAmount = _a.rewardAmount, takeTime = _a.takeTime, remarks = _a.remarks, platform = _a.platform, declaredAmount = _a.declaredAmount;
        var takeTimeFormat = takeTime
            ? moment(takeTime).format('YYYY-MM-DD HH:mm')
            : '立即取件';
        return (React.createElement(OtherInfo, null,
            React.createElement(Line, { gutter: 4 },
                React.createElement(Col, { span: 12 },
                    "\u914D\u9001\u5E73\u53F0\uFF1A",
                    DELIVERY_PLATFORM_TEXT_MAP[platform]),
                React.createElement(Col, { span: 12 },
                    "\u5C0F\u8D39\uFF1A",
                    rewardAmount,
                    "\u5143")),
            React.createElement(Line, { gutter: 4 },
                React.createElement(Col, { span: 12 },
                    "\u53D6\u4EF6\u65F6\u95F4\uFF1A",
                    takeTimeFormat),
                React.createElement(Col, { span: 12 },
                    "\u53D6\u4EF6\u5907\u6CE8\uFF1A",
                    remarks)),
            declaredAmount && Number(declaredAmount) !== 0 ?
                React.createElement(Line, { gutter: 4 },
                    React.createElement(Col, { span: 12 },
                        "\u7269\u54C1\u4FDD\u4EF7\uFF1A\u7269\u54C1\u4EF7\u503C",
                        declaredAmount || 0,
                        "\u5143")) : null));
    };
    return DeliveryOrderDetail;
}(React.PureComponent));
export { DeliveryOrderDetail };
DeliveryOrderDetail.contextType = DeliveryContext;
var templateObject_1, templateObject_2;
