import * as React from 'react';
import { Button, Popover } from 'antd';
import './button.less';
import { renderAction } from '../OrderActions';
export var MoreAction = function (props) {
    var action = props.action, tabStatus = props.tabStatus, orderEvent = props.orderEvent, item = props.item;
    var mapactions = content().props.children.filter(function (item) { return item !== null; });
    return (React.createElement(React.Fragment, null, mapactions.length > 0 &&
        React.createElement(Popover, { title: null, content: content(), trigger: 'hover' },
            React.createElement(Button, { className: "orderbutton", type: "primary" }, "\u66F4\u591A\u64CD\u4F5C"))));
    function content() {
        return renderAction(action, tabStatus, orderEvent, item);
    }
};
