var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import OrderSummaryAndLineChart from './components/OrderSummaryAndLineChart';
import ChannelProportionPie from './components/ChannelProportionPie';
import OrderHeatMap from './components/OrderHeatMap';
import reportContext from './context';
import { ReportWrapper } from './styled';
import Rank from './components/Rank';
import { ReportModule, DateRangeType } from './types/emnu';
import { sendPv } from '@/utils/umeng';
var Report = function (props) {
    var _a;
    var storeIdRef = useRef('');
    var platformIdRef = useRef('');
    var platformIdForOrderHeapRef = useRef('');
    var _b = __read(useState(''), 2), cityId = _b[0], setCityId = _b[1];
    var orderCondition = useRef({});
    var heapMapCondition = useRef({});
    var proportionCondition = useRef({});
    var rankCondition = useRef({});
    var _c = __read(useState('比前一日'), 2), compareText = _c[0], setCompareText = _c[1];
    var fetchOrderData = useCallback(function (condition) {
        orderCondition.current = __assign(__assign({}, orderCondition.current), condition);
        props.fetchOrderSummary(orderCondition.current);
        props.fetchOrderLineChart(orderCondition.current);
    }, []);
    var fetchChannelProportion = useCallback(function (condition) {
        proportionCondition.current = __assign(__assign({}, proportionCondition.current), condition);
        props.fetchChannelProportion(proportionCondition.current);
    }, []);
    var fetchRankData = useCallback(function (condition) {
        rankCondition.current = __assign(__assign({}, rankCondition.current), condition);
        props.fetchRank(rankCondition.current);
    }, []);
    var fetchOrderHeapMap = useCallback(function (condition) {
        heapMapCondition.current = __assign(__assign({}, heapMapCondition.current), condition);
        props.fetchOrderHeapMap(heapMapCondition.current);
    }, []);
    var changeCompareText = function (rangeType) {
        var text = '';
        switch (rangeType) {
            case DateRangeType.Today:
                text = '比前一日';
                break;
            case DateRangeType.Yesterday:
                text = '比前一日';
                break;
            case DateRangeType.LastWeek:
                text = '比前7日';
                break;
            case DateRangeType.LastMonth:
                text = '比前30日';
                break;
            case DateRangeType.LastQuarter:
                text = '比前90日';
                break;
            case DateRangeType.Custom:
                text = '比前一周期';
                break;
            default:
                text = '比前一日';
        }
        setCompareText(text);
    };
    var handleFnMap = (_a = {},
        _a[ReportModule.Order] = fetchOrderData,
        _a[ReportModule.Channel] = fetchChannelProportion,
        _a[ReportModule.Rank] = fetchRankData,
        _a[ReportModule.OrderHeapMap] = fetchOrderHeapMap,
        _a);
    var changeStore = function (module, storeId) {
        if (module === ReportModule.Order) {
            platformIdRef.current = '';
            props.fetchPlatformsForOrder(storeId);
        }
        else if (module === ReportModule.OrderHeapMap) {
            storeIdRef.current = storeId;
            platformIdForOrderHeapRef.current = '';
            props.fetchPlatformsForOrderHeap(storeId);
        }
        handleFnMap[module]({ storeId: storeId, orderPlatformId: '' });
    };
    var changeOrderPlatform = function (module, orderPlatformId) {
        if (module === ReportModule.Order) {
            platformIdRef.current = orderPlatformId;
        }
        else if (module === ReportModule.OrderHeapMap) {
            platformIdForOrderHeapRef.current = orderPlatformId;
        }
        handleFnMap[module]({
            orderPlatformId: orderPlatformId
        });
    };
    var changeDate = function (module, dateRange, rangeType) {
        if (rangeType) {
            changeCompareText(rangeType);
        }
        handleFnMap[module](__assign({}, dateRange));
    };
    var context = {
        changeStore: changeStore,
        changeOrderPlatform: changeOrderPlatform,
        changeDate: changeDate
    };
    var handleCityChange = function (code) {
        setCityId(code);
        storeIdRef.current = '';
        platformIdForOrderHeapRef.current = '';
        props.fetchStoresForOrderHeap(code);
        fetchOrderHeapMap({ code: code });
    };
    var init = function () { return __awaiter(void 0, void 0, void 0, function () {
        var p;
        return __generator(this, function (_a) {
            // 门店
            props.fetchStores();
            // 订单统计数据
            props.fetchOrderSummary();
            // 订单折线图数据
            props.fetchOrderLineChart();
            p = props.fetchCityList();
            p.then(function (cities) {
                if (cities.length === 0) {
                    return;
                }
                setCityId(cities[0].code);
                // 热力图的门店数据
                props.fetchStoresForOrderHeap(cities[0].code);
                // 热力图数据
                props.fetchOrderHeapMap({ code: cityId });
            });
            // 渠道占比
            props.fetchChannelProportion();
            // 排行数据
            props.fetchRank();
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        init();
        sendPv();
    }, []);
    return (React.createElement(ReportWrapper, null,
        React.createElement(reportContext.Provider, { value: context },
            React.createElement(OrderSummaryAndLineChart, { orderSummary: props.orderSummary, orderLineChart: props.orderLineChart, compareText: compareText, stores: props.stores, platformId: platformIdRef.current, orderPlatforms: props.platformsForOrder }),
            React.createElement(OrderHeatMap, { stores: props.storesForOrderHeap, storeId: storeIdRef.current, orderPlatforms: props.platformsForOrderHeap, platformId: platformIdForOrderHeapRef.current, data: props.orderHeapMap, cities: props.cities, cityId: cityId, onCityChange: handleCityChange }),
            React.createElement(ChannelProportionPie, { stores: props.stores, orderProportion: props.orderProportion, platformProportion: props.platformProportion }),
            React.createElement(Rank, { data: props.rankData }))));
};
var mapState = function (state) { return ({
    orderSummary: state.report.orderSummary,
    orderLineChart: state.report.orderLineChart,
    stores: state.report.stores,
    platformsForOrder: state.report.platformsForOrder,
    platformsForOrderHeap: state.report.platformsForOrderHeap,
    orderProportion: state.report.orderProportion,
    platformProportion: state.report.platformProportion,
    rankData: state.report.rank,
    orderHeapMap: state.report.orderHeapMap,
    cities: state.report.cityList,
    storesForOrderHeap: state.report.storesForOrderHeap
}); };
var mapDispatch = function (dispatch) { return ({
    fetchOrderSummary: dispatch.report.fetchOrderSummary,
    fetchOrderLineChart: dispatch.report.fetchOrderLineChart,
    fetchStores: dispatch.report.fetchStores,
    fetchPlatformsForOrder: dispatch.report.fetchPlatformsForOrder,
    fetchPlatformsForOrderHeap: dispatch.report.fetchPlatformsForOrderHeap,
    fetchChannelProportion: dispatch.report.fetchChannelProportion,
    fetchRank: dispatch.report.fetchRank,
    fetchOrderHeapMap: dispatch.report.fetchOrderHeapMap,
    fetchCityList: dispatch.report.fetchCityList,
    fetchStoresForOrderHeap: dispatch.report.fetchStoresForOrderHeap
}); };
export default connect(mapState, mapDispatch)(Report);
