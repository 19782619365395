var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var SeperatorWrapper = styled.div.withConfig({ displayName: "SeperatorWrapper", componentId: "sc-aifcr7" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  .text {\n    max-width: 120px;\n    padding: 0 10px;\n    text-align: center;\n    display: inline-block;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  .text {\n    max-width: 120px;\n    padding: 0 10px;\n    text-align: center;\n    display: inline-block;\n  }\n"])));
var ReceiptSeperator = function (props) {
    return (React.createElement(SeperatorWrapper, null,
        React.createElement("span", { className: "left" }, "----------"),
        React.createElement("span", { className: "text" }, props.children),
        React.createElement("span", { className: "right" }, "---------")));
};
export default ReceiptSeperator;
var templateObject_1;
