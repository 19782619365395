import { app } from 'electron';
import Store from 'electron-store';
var config = new Store({
    defaults: {
        APP_ENV: process.env.APP_ENV || 'production',
        account: {
            phone: '',
            password: ''
        }
    }
});
config.onDidChange('APP_ENV', function (newValue) {
    if (newValue) {
        app.relaunch();
        app.exit(0);
    }
});
export default config;
