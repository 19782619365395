var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import FormModal from '@components/FormModal';
import { useToggle } from '@hooks';
import ajax from '@utils/ajax';
import BindPrintCaseForm from './BindPrintCase';
var savePrintCase = function (printCase) {
    return ajax.post('/printer/scheme/save', printCase);
};
var mapDispatch = function (dispatch) { return ({
    getPrintCaseList: dispatch.printCases.fetchData
}); };
var BindPrintCaseModal = function (_a) {
    var isEditMode = _a.isEditMode, visible = _a.visible, initialValue = _a.initialValue, toggleVisible = _a.toggleVisible;
    var dispatch = useDispatch();
    var getPrintCaseList = mapDispatch(dispatch).getPrintCaseList;
    var _b = __read(useToggle(false), 3), confirmLoading = _b[0], startConfirmLoading = _b[1], stopConfirmLoading = _b[2];
    var formRef = React.createRef();
    var handleSubmit = function () {
        var editPrintCaseForm = formRef.current.props.form;
        editPrintCaseForm.validateFields(function (errors, values) {
            if (errors)
                return false;
            if (!values.printTypes)
                return message.error('请选择方案');
            startConfirmLoading();
            var printTypes = [];
            // values.printTypes.forEach((item: any) => {
            //   if (item.checked) {
            //     delete item.checked
            //     printTypes.push({ ...item })
            //   }
            // })
            for (var i in values.printTypes) {
                var item = values.printTypes[i];
                if (item.checked) {
                    delete item.checked;
                    printTypes.push(__assign({}, item));
                }
            }
            var data = Object.assign(values, {
                printTypes: printTypes
            });
            if (isEditMode) {
                data = Object.assign(data, {
                    storeId: initialValue.storeId,
                    id: initialValue.id
                });
            }
            savePrintCase(data).then(function () {
                isEditMode
                    ? message.success('修改打印方案成功')
                    : message.success('新增打印方案成功');
                stopConfirmLoading();
                toggleVisible();
                getPrintCaseList({});
            }, function () {
                stopConfirmLoading();
            });
        });
    };
    return (React.createElement(FormModal, { title: (isEditMode ? '编辑' : '添加') + "\u6253\u5370\u65B9\u6848", visible: visible, onCancel: function () { return toggleVisible(); }, onOk: handleSubmit, confirmLoading: confirmLoading, okText: "\u4FDD\u5B58", destroyOnClose: true },
        React.createElement(BindPrintCaseForm, { initialValue: initialValue, wrappedComponentRef: formRef })));
};
export default BindPrintCaseModal;
