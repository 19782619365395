var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import Hotkeys from 'react-hot-keys';
import { Button, Col, Form, Input, Row, Tooltip } from 'antd';
import FormModal from '@components/FormModal';
import { ActionsContext, ShortcutContext } from '../context';
import { SendDeliveryActioninit } from './SendDeliveryAction';
import { ScanBarCodeToSendButton } from './styled';
import { getAuth } from '@/container/AccountManagement/component';
var FormItem = Form.Item;
var formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 }
};
var HotkeyTipsText = function () {
    if (window.navigator.platform.indexOf('Mac') !== -1) {
        return React.createElement("span", null, "\u5FEB\u6377\u952E\uFF1AOption + D");
    }
    if (window.navigator.platform.indexOf('Win') !== -1) {
        return React.createElement("span", null, "\u5FEB\u6377\u952E\uFF1AAlt + D");
    }
    return null;
};
var BarCodeInput = /** @class */ (function (_super) {
    __extends(BarCodeInput, _super);
    function BarCodeInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.input = React.createRef();
        _this.focus = function () {
            _this.input.current.focus();
        };
        _this.reset = function () {
            _this.props.form.setFieldsValue({ barcode: '' });
            _this.input.current.focus();
        };
        return _this;
    }
    BarCodeInput.prototype.componentDidMount = function () {
        this.focus();
    };
    BarCodeInput.prototype.render = function () {
        var getFieldDecorator = this.props.form.getFieldDecorator;
        return (React.createElement(Form, __assign({}, formItemLayout, { onSubmit: this.props.onSubmit }),
            React.createElement(FormItem, { label: "\u8BA2\u5355\u53F7", style: { display: 'flex' } },
                React.createElement(Row, { gutter: 18 },
                    React.createElement(Col, { span: 18 }, getFieldDecorator('barcode', {
                        initialValue: '',
                        rules: [
                            {
                                required: true,
                                message: '请扫描条形码或手动输入条形码编号'
                            }
                        ]
                    })(React.createElement(Input, { ref: this.input }))),
                    React.createElement(Col, { span: 6 },
                        React.createElement(Button, { onClick: this.reset }, "\u91CD\u65B0\u626B\u7801"))))));
    };
    return BarCodeInput;
}(React.PureComponent));
var BarCodeInputForm = Form.create({})(BarCodeInput);
var ScanBarCodeToSendAction = /** @class */ (function (_super) {
    __extends(ScanBarCodeToSendAction, _super);
    function ScanBarCodeToSendAction() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            barCodeInputVisible: false,
            orderId: ''
        };
        _this.sendDelivery = React.createRef();
        _this.toggleBarCodeInput = function () {
            var barCodeInputVisible = _this.state.barCodeInputVisible;
            _this.setState({ barCodeInputVisible: !barCodeInputVisible });
        };
        _this.handleBarCodeSubmit = function (e) {
            e.preventDefault();
            var form = _this.barCodeInputForm.props.form;
            form.validateFields(function (errors, values) {
                if (errors)
                    return false;
                var barcode = values.barcode;
                var sendDelivery = _this.sendDelivery;
                _this.setState({
                    orderId: String(Number(barcode.slice(0, -1)))
                }, function () {
                    sendDelivery.getOrderDetail({
                        callback: function (error) {
                            if (error) {
                                _this.barCodeInputForm.reset();
                                return false;
                            }
                            _this.toggleBarCodeInput();
                            sendDelivery.toggleVisible();
                        }
                    });
                });
            });
        };
        return _this;
    }
    ScanBarCodeToSendAction.prototype.render = function () {
        var _this = this;
        var _a = this.state, barCodeInputVisible = _a.barCodeInputVisible, orderId = _a.orderId;
        return (React.createElement(React.Fragment, null,
            React.createElement(FormModal, { title: "\u626B\u7801\u53D1\u5355", visible: barCodeInputVisible, onCancel: this.toggleBarCodeInput, footer: null, destroyOnClose: true },
                React.createElement(BarCodeInputForm, { onSubmit: this.handleBarCodeSubmit, wrappedComponentRef: function (form) {
                        _this.barCodeInputForm = form;
                    } })),
            React.createElement(ShortcutContext.Consumer, null, function (_a) {
                var tabStatus = _a.tabStatus, orderEvent = _a.orderEvent;
                var providerValue = {
                    tabStatus: tabStatus,
                    orderEvent: orderEvent,
                    id: orderId
                };
                return (React.createElement(ActionsContext.Provider, { value: providerValue }, getAuth("配送处理", _this.props.menu) && React.createElement(SendDeliveryActioninit, { ref: function (instance) {
                        _this.sendDelivery = instance;
                    }, hideSendButton: true })));
            }),
            React.createElement(Hotkeys, { keyName: "alt+d,option+d", onKeyUp: this.toggleBarCodeInput },
                React.createElement(Tooltip, { placement: "top", title: HotkeyTipsText }, getAuth("配送处理", this.props.menu) && React.createElement(ScanBarCodeToSendButton, { type: "primary", icon: "scan", onClick: this.toggleBarCodeInput }, "\u626B\u7801\u53D1\u5355")))));
    };
    return ScanBarCodeToSendAction;
}(React.Component));
export { ScanBarCodeToSendAction };
