var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import EventEmitter from 'wolfy87-eventemitter';
import { deleteOrderItemAction, editOrderItemStatusAction } from '@models';
import { store } from '@store';
export var EVENTS = {
    UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',
    DELETE_ORDERS_ITEM: 'DELETE_ORDERS_ITEM'
};
export var initOrderEvent = function () {
    var _a;
    var orderEvent = new EventEmitter();
    orderEvent.addListeners((_a = {},
        _a[EVENTS.UPDATE_ORDER_STATUS] = function (_a) {
            var orderId = _a.orderId, status = _a.status, otherParams = _a.otherParams;
            var payload = __assign({ orderId: orderId, status: status }, otherParams);
            store.dispatch(editOrderItemStatusAction(payload));
        },
        _a[EVENTS.DELETE_ORDERS_ITEM] = function (_a) {
            var orderId = _a.orderId, otherParams = _a.otherParams;
            var payload = __assign({ orderId: orderId }, otherParams);
            store.dispatch(deleteOrderItemAction(payload));
        },
        _a));
    return orderEvent;
};
