var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useRef, useEffect } from 'react';
import { Form, Input } from 'antd';
import { AppendTipWrapper, TipButton, ButtonGroup } from './styled';
var AppendTipForm = function (props) {
    var _a = props.form, getFieldDecorator = _a.getFieldDecorator, setFieldsValue = _a.setFieldsValue;
    var moneysRef = useRef([2, 4, 6, 8, 10, 20, 50, 100, 200]);
    var _b = __read(useState(''), 2), money = _b[0], setMoney = _b[1];
    var handleChangeMoney = function (money) {
        setMoney(money.toString());
        // setFields('money', money.toString())
        setFieldsValue({
            money: money.toString()
        });
    };
    useEffect(function () {
        props.formRef(props.form);
    });
    return (React.createElement(AppendTipWrapper, null,
        React.createElement(ButtonGroup, null, moneysRef.current.map(function (m) {
            return (React.createElement(TipButton, { key: m, active: Number(money) === m, onClick: function () { return handleChangeMoney(m); } },
                m,
                "\u5143"));
        })),
        React.createElement(Form, null,
            React.createElement(Form.Item, null,
                React.createElement("div", { className: "input-row" }, getFieldDecorator('money', {
                    initialValue: '',
                    rules: [
                        {
                            required: true,
                            message: '金额不能为空'
                        },
                        {
                            // message: '金额不能小于零',
                            validator: function (rule, val) {
                                if (val < 0) {
                                    return Promise.reject('金额不能小于零');
                                }
                                return Promise.resolve();
                            }
                        }
                    ]
                })(React.createElement(Input, { type: "number", className: "input", placeholder: "\u5176\u4ED6\u91D1\u989D" })))))));
};
export default Form.create()(AppendTipForm);
