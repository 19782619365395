var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { connect } from 'react-redux';
import { Pagination, message } from 'antd';
import produce from 'immer';
import lodash, { find } from 'lodash';
import * as qs from 'querystring';
import { requestForMerchant, requestForOrder } from '@utils/request';
import { getItem } from '@utils/localStorage';
import { sendPv } from '@utils/umeng';
import { CancelOrderStatus, RetreatOrderStatus } from '@models';
import ChromeCheck from '@/components/ChromeCheck';
import { CreateOrderAction, ScanBarCodeToSendAction } from './action';
import { OrderStatusTabs } from './components';
import OrderMapCalculate from './components/OrderMapCalculate';
import { ERouteType, ORDER_STATUS_MAP, ORDER_STATUS_MAP_VALUE } from './constants';
import { OrderContext, ShortcutContext } from './context';
import { initOrderEvent } from './event';
import Receipt from '../../components/Receipt/indexNew';
import SearchForm from './form/Search';
import getOrderQuery from './getOrderQuery';
import { OrderContentWrapper, OrderFiltersWrapper, OrderShortcutsWrapper, OrderContent, OrderContentMap, TipBox, EmptyData, OrderBox } from './styled';
import OrderList from './components/OrderList';
import OrderMap from './components/OrderMap';
import { renderGoods, renderOther } from './helper';
import OrderSwitch from './components/OrderSwitch';
import OrderSearchHeader from './components/OrderSearchHeader';
import UnbindTip from './components/UnbindTip/index';
import blankImg from '@assets/images/blank.png';
import events from '@/utils/events';
var getOtherStatus = function (item) {
    if (item.retreatOrder.status === RetreatOrderStatus.WaitCheck) {
        return '退单申请';
    }
    if (item.cancelOrder.status === CancelOrderStatus.WaitCheck) {
        return '取消单申请';
    }
    return null;
};
var getOrderById = function (_a) {
    var list = _a.list, id = _a.id;
    return (find(list, function (item) { return item.id === id; }) || {});
};
var getStoreListReq = function () { return __awaiter(void 0, void 0, void 0, function () {
    var lastChooseMerchant, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                lastChooseMerchant = getItem('lastChooseMerchant') && JSON.parse(getItem('lastChooseMerchant'));
                return [4 /*yield*/, requestForMerchant.post("/merchant/store/getAllStoreByMerchantId", {
                        merchantId: lastChooseMerchant.merchantId,
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); };
var getMapStoreLis = function () { return __awaiter(void 0, void 0, void 0, function () {
    var lastChooseMerchant, data;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                lastChooseMerchant = getItem('lastChooseMerchant') && JSON.parse(getItem('lastChooseMerchant'));
                return [4 /*yield*/, requestForOrder.post("/api/platform/order/getStoreListCountDesc", {
                        merchantId: lastChooseMerchant.merchantId
                    })];
            case 1:
                data = (_a.sent()).data;
                return [2 /*return*/, data];
        }
    });
}); };
var Orders = /** @class */ (function (_super) {
    __extends(Orders, _super);
    function Orders() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tipBoxRef = React.createRef();
        _this.mapSearchRef = React.createRef();
        _this.orderMapCalculateRef = React.createRef();
        _this.unbindTipRef = React.createRef();
        _this.state = {
            currentViewId: '',
            status: 'ALL',
            timer: null,
            distribPlatforms: [],
            order: {},
            distriOrder: {},
            tipBoxHeight: window.screen.height - 416,
            scrollTop: 0,
            tabContent: 'list',
            oneByMoreData: [],
            oneByMoreOrderIds: [],
            initMapStore: [],
            storeList: [],
            mapStoreList: [],
            oneByMoreOpen: false,
            routeType: ERouteType.Assign,
            createOrderStoreId: '',
        };
        _this.handleSearch = function (values) {
            var _a;
            var _b = _this.props, history = _b.history, getOrders = _b.getOrders;
            var _c = _this.state, tabContent = _c.tabContent, mapStoreList = _c.mapStoreList;
            // todo，最好把这个校验放在后端，但是后端无奈解决不了
            if (values.platformDaySn &&
                values.platformDaySn.length &&
                values.platformDaySn.length > 10) {
                message.error('平台流水号输入过长');
                return;
            }
            if (values.daySn && values.daySn.length && values.daySn.length > 10) {
                message.error('易送流水号输入过长');
                return;
            }
            _this.setState(produce(function (draft) {
                draft.currentViewId = '';
                draft.oneByMoreData = [];
                draft.oneByMoreOrderIds = [];
            }));
            if (tabContent === 'list') {
                var orderQuery = getOrderQuery(__assign({ status: _this.state.status }, values));
                history.push({
                    search: qs.stringify(__assign(__assign({}, orderQuery), { status: _this.state.status }))
                });
                _this.search = orderQuery ? orderQuery : {};
                getOrders(orderQuery);
            }
            else {
                var orderQuery = getOrderQuery(__assign({ expectationTime: '120', 
                    //@ts-ignore
                    storeId: (mapStoreList && ((_a = mapStoreList[0]) === null || _a === void 0 ? void 0 : _a.id)) || '' }, values));
                getOrders(__assign(__assign({}, orderQuery), { pageSize: 10000, orderStatus: ORDER_STATUS_MAP_VALUE.TO_BE_SHIPPING }));
            }
        };
        _this.tabSwitch = function (val) {
            var getOrders = _this.props.getOrders;
            var mapStoreList = _this.state.mapStoreList;
            // ...this.search,
            var orderQuery = getOrderQuery(__assign({ pageSize: 10 }, _this.search));
            _this.setState(produce(function (draft) {
                draft.tabContent = val;
                draft.currentViewId = '';
                draft.oneByMoreData = [];
                draft.oneByMoreOrderIds = [];
                draft.createOrderStoreId = '';
            }), function () {
                var _a;
                if (val === 'list') {
                    _this.setState({
                        order: {}
                    }, function () {
                        getOrders(orderQuery);
                    });
                }
                else {
                    getOrders({
                        expectationTime: '120',
                        //@ts-ignore
                        storeId: (mapStoreList && ((_a = mapStoreList[0]) === null || _a === void 0 ? void 0 : _a.id)) || '',
                        pageSize: 10000,
                        orderStatus: ORDER_STATUS_MAP_VALUE.TO_BE_SHIPPING
                    });
                    getMapStoreLis().then(function (data) {
                        _this.setState(produce(function (draft) {
                            draft.mapStoreList = data;
                            draft.routeType = ERouteType.Assign;
                        }));
                    });
                }
            });
        };
        // async getDistriDetail(orderId: string, platformOrderId: string) {
        //   const { data } = await ajax.post('/order/distri/details', {
        //     orderId,
        //     platformOrderId
        //   })
        //   this.setState({
        //     order: data.order,
        //     distriOrder: data.distriOrder
        //   })
        // }
        _this.handleTableChange = function (page) {
            var orderQuery = getOrderQuery(__assign(__assign({}, _this.search), { page: page, pageSize: _this.props.pagination.pageSize }));
            _this.setState(produce(function (draft) {
                draft.currentViewId = '';
            }), function () {
                _this.props.getOrders(orderQuery);
            });
        };
        _this.renderGoods = function (goods, groupGoodsList, platform) {
            return renderGoods(goods, groupGoodsList, platform);
        };
        _this.renderOther = function (order) {
            return (React.createElement(React.Fragment, null, renderOther(order).map(function (item, index) {
                return item;
            })));
        };
        _this.renderOrderReceipt = function () {
            var _a = _this.state, currentViewId = _a.currentViewId, tabStatus = _a.status;
            if (!currentViewId)
                return null;
            var item = getOrderById({
                list: _this.props.dataSource,
                id: currentViewId
            });
            if (!item)
                return null;
            // item.receiptName = '商户联'
            if (!item.goods && !item.groupGoodsList)
                return null;
            return (React.createElement(Receipt, { renderGoods: {
                    goods: item.goods,
                    groupGoodsList: item.groupGoodsList,
                    platform: item.platform
                }, 
                // renderOther={this.renderOther(item)}
                data: item }));
        };
        _this.handleStatusChange = function (event) {
            var status = event.target.value;
            var _a = _this.props, history = _a.history, getOrders = _a.getOrders;
            // this.search = { ...this.search, status }
            _this.search = { status: status };
            var orderQuery = getOrderQuery(_this.search);
            _this.setState(produce(function (draft) {
                draft.status = status;
                draft.currentViewId = '';
                draft.tabContent = 'list';
            }), function () {
                history.push({
                    search: qs.stringify(_this.search)
                });
                getOrders(orderQuery);
            });
        };
        _this.handleTableOnRow = function (item) {
            return {
                onClick: function () {
                    _this.setState(produce(function (draft) {
                        draft.currentViewId = item.id;
                    }));
                }
            };
        };
        _this.handleTableRowClassName = function (item) {
            var currentViewId = _this.state.currentViewId;
            return item.id === currentViewId ? 'table-row-highlight' : '';
        };
        _this.handleOrderItemChange = function (item) {
            _this.setState({
                order: item
            });
            _this.setState(produce(function (draft) {
                draft.currentViewId = item.id;
            }));
        };
        _this.handleReceiverClick = function (record) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            var _j = _this.state, oneByMoreData = _j.oneByMoreData, oneByMoreOrderIds = _j.oneByMoreOrderIds;
            if (oneByMoreOrderIds.length === 0) {
                _this.setState(produce(function (draft) {
                    draft.oneByMoreData = [record];
                    draft.oneByMoreOrderIds = [record.id];
                    draft.currentViewId = record.id;
                }));
                (_a = document.getElementById(record.id)) === null || _a === void 0 ? void 0 : _a.removeAttribute('class');
                (_b = document
                    .getElementById(record.id)) === null || _b === void 0 ? void 0 : _b.setAttribute('class', 'orderMapLabelActive');
            }
            else if (oneByMoreOrderIds.includes(record.id)) {
                var index = oneByMoreOrderIds.indexOf(record.id);
                var tempIds_1 = JSON.parse(JSON.stringify(oneByMoreOrderIds));
                var tempData_1 = JSON.parse(JSON.stringify(oneByMoreData));
                tempIds_1.splice(index, 1);
                tempData_1.map(function (item, i) {
                    if (item.id === record.id) {
                        tempData_1.splice(i, 1);
                    }
                });
                _this.setState(produce(function (draft) {
                    draft.oneByMoreData = tempData_1;
                    draft.oneByMoreOrderIds = tempIds_1;
                    draft.currentViewId = record.id;
                }));
                (_c = document.getElementById(record.id)) === null || _c === void 0 ? void 0 : _c.removeAttribute('class');
                (_d = document
                    .getElementById(record.id)) === null || _d === void 0 ? void 0 : _d.setAttribute('class', 'orderMapLabelDefault');
                for (var i = 0; i < tempIds_1.length; i++) {
                    (_e = document.getElementById(tempIds_1[i])) === null || _e === void 0 ? void 0 : _e.removeAttribute('class');
                    (_f = document
                        .getElementById(tempIds_1[i])) === null || _f === void 0 ? void 0 : _f.setAttribute('class', 'orderMapLabelActive');
                }
                if (!tempIds_1.length) {
                    _this.setState(produce(function (draft) {
                        draft.currentViewId = '';
                    }));
                }
            }
            else if (!oneByMoreOrderIds.includes(record.id) &&
                oneByMoreOrderIds.length < 3) {
                if (oneByMoreData[0].storeId === record.storeId) {
                    var tempIds_2 = JSON.parse(JSON.stringify(oneByMoreOrderIds));
                    var tempData_2 = JSON.parse(JSON.stringify(oneByMoreData));
                    tempIds_2.push(record.id);
                    tempData_2.push(record);
                    _this.setState(produce(function (draft) {
                        draft.oneByMoreData = tempData_2;
                        draft.oneByMoreOrderIds = tempIds_2;
                        draft.currentViewId = record.id;
                    }));
                    for (var i = 0; i < tempIds_2.length; i++) {
                        (_g = document.getElementById(tempIds_2[i])) === null || _g === void 0 ? void 0 : _g.removeAttribute('class');
                        (_h = document
                            .getElementById(tempIds_2[i])) === null || _h === void 0 ? void 0 : _h.setAttribute('class', 'orderMapLabelActive');
                    }
                }
                else {
                    message.info('一取多投，只能选取同一发件地址的订单');
                }
            }
            else if (!oneByMoreOrderIds.includes(record.id) &&
                oneByMoreOrderIds.length === 3) {
                message.info('一取多投一次最多支持三个订单');
            }
        };
        _this.handleSortOneMoreData = function (orderIds) {
            var oneByMoreData = _this.state.oneByMoreData;
            if (orderIds && orderIds.length > 0) {
                var tempData_3 = [];
                orderIds.map(function (item) {
                    oneByMoreData.map(function (record) {
                        if (item === record.id) {
                            tempData_3.push(record);
                        }
                    });
                });
                _this.setState(produce(function (draft) {
                    draft.oneByMoreData = tempData_3;
                }));
            }
        };
        _this.handleRefreshOrderMap = function () {
            var getOrders = _this.props.getOrders;
            var _a = _this.mapSearchRef.current, current = _a === void 0 ? {} : _a;
            _this.setState(produce(function (draft) {
                draft.oneByMoreData = [];
                draft.oneByMoreOrderIds = [];
                draft.currentViewId = '';
                draft.routeType = ERouteType.Assign;
            }));
            getOrders(__assign(__assign({}, current), { pageSize: 10000, orderStatus: ORDER_STATUS_MAP_VALUE.TO_BE_SHIPPING }));
        };
        _this.handleCreateOrder = function (data) {
            if (data) {
                _this.handleReceiverClick(data);
            }
        };
        _this.handleSwitchRoadType = function (type) { return function () {
            var current = _this.orderMapCalculateRef.current;
            _this.setState(produce(function (draft) {
                draft.routeType = type;
            }));
            if (current && current.handleFieldChange) {
                current.handleFieldChange({ routeType: type });
            }
        }; };
        _this.handleUpdateCreateOrderStoreId = function (id) {
            _this.setState(produce(function (draft) {
                draft.createOrderStoreId = id;
            }));
        };
        return _this;
    }
    Orders.prototype.renderFilters = function () {
        var _a = this.state, tabContent = _a.tabContent, storeList = _a.storeList;
        return (React.createElement(OrderFiltersWrapper, null,
            React.createElement(SearchForm, __assign({}, this.search, { onSubmit: this.handleSearch, tabSwitch: this.tabSwitch, tabContent: tabContent, storeList: storeList }))));
    };
    Orders.prototype.componentDidMount = function () {
        var _this = this;
        this.props.reqOnetoManyConfig();
        this.UnbindRender();
        var _a = this.props, history = _a.history, search = _a.location.search;
        this.search =
            search.indexOf('?') >= 0
                ? qs.parse(search.substr(search.indexOf('?') + 1))
                : {};
        if (!this.search.status) {
            this.search.status = ORDER_STATUS_MAP.ALL;
        }
        this.setState(produce(function (draft) {
            draft.oneByMoreOpen = true;
        }));
        this.setState(produce(function (draft) {
            draft.status = _this.search.status;
            draft.timer = setInterval(function () {
                _this.props.reqCounter();
            }, 30000);
        }));
        history.push({ search: qs.stringify(this.search) });
        var orderQuery = getOrderQuery(__assign({}, this.search));
        this.props.getOrders(orderQuery);
        this.orderEvent = initOrderEvent();
        // this.state.timer = setInterval(() => {
        //   this.props.reqCounter()
        // }, 30000)
        // this.handleOrderItemChange(this.props.dataSource[0])
        getStoreListReq().then(function (data) {
            _this.setState(produce(function (draft) {
                draft.storeList = data;
            }));
        });
        getMapStoreLis().then(function (data) {
            _this.setState(produce(function (draft) {
                draft.mapStoreList = data;
            }));
        });
        sendPv();
    };
    Orders.prototype.componentWillUnmount = function () {
        window.clearInterval(Number(this.state.timer));
    };
    Orders.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (this.state.tabContent === 'list') {
            if (!prevProps.order && this.props.order) {
                this.handleOrderItemChange(this.props.order);
            }
            else if (prevProps.order &&
                this.props.order &&
                prevState.status !== this.state.status) {
                this.handleOrderItemChange(this.props.order);
            }
            else if (JSON.stringify(prevProps.dataSource) !==
                JSON.stringify(this.props.dataSource)) {
                if (this.props.dataSource.length > 0) {
                    this.handleOrderItemChange(this.props.dataSource[0]);
                }
            }
        }
    };
    Orders.prototype.render = function () {
        var _this = this;
        var _a;
        var _b = this.state, tabStatus = _b.status, tabContent = _b.tabContent, oneByMoreData = _b.oneByMoreData, storeList = _b.storeList, mapStoreList = _b.mapStoreList, oneByMoreOpen = _b.oneByMoreOpen, routeType = _b.routeType, oneByMoreOrderIds = _b.oneByMoreOrderIds, createOrderStoreId = _b.createOrderStoreId;
        var _c = this.props, dataSource = _c.dataSource, pagination = _c.pagination;
        var providerValue = {
            orderEvent: this.orderEvent,
            tabStatus: tabStatus
        };
        return (React.createElement(OrderBox, null,
            React.createElement(ChromeCheck, null),
            React.createElement(OrderShortcutsWrapper, null,
                React.createElement("div", { className: "header" },
                    React.createElement(ShortcutContext.Provider, { value: { tabStatus: tabStatus, orderEvent: this.orderEvent } },
                        React.createElement("div", { className: "header-left" },
                            React.createElement(ScanBarCodeToSendAction, { menu: this.props.menu }),
                            tabContent === 'list' && React.createElement(CreateOrderAction, null),
                            this.props.onetoManyConfig !== "00" && React.createElement(OrderSwitch, { tabSwitch: this.tabSwitch })),
                        tabContent !== 'map' && (React.createElement(OrderSearchHeader, { search: this.search, storeList: storeList, onSubmit: this.handleSearch, tabStatus: tabStatus })))),
                this.state.tabContent === 'list' && (React.createElement(OrderStatusTabs, { onChange: lodash.debounce(this.handleStatusChange, 300) }))),
            React.createElement(OrderContentWrapper, null,
                tabContent === 'list' ? (React.createElement(React.Fragment, null, dataSource.length > 0 ? (React.createElement(OrderContent, null,
                    React.createElement(OrderContext.Provider, { value: providerValue },
                        React.createElement("div", { className: "order-list" },
                            React.createElement("div", null,
                                React.createElement(OrderList, { current: this.state.currentViewId, list: dataSource, onChange: lodash.debounce(this.handleOrderItemChange, 100) }),
                                React.createElement(Pagination, { className: "pager", showQuickJumper: true, showSizeChanger: true, current: pagination.current, total: pagination.total, onChange: this.handleTableChange }))),
                        !this.state.currentViewId ? (React.createElement(TipBox, { height: this.state.tipBoxHeight, ref: this.tipBoxRef },
                            React.createElement("span", { className: "tip" }, "\u8BF7\u5148\u5728\u5DE6\u4FA7\u5217\u8868\u9009\u8BA2\u5355"))) : (React.createElement("div", { ref: this.tipBoxRef, className: "right-box" }, this.renderOrderReceipt()))))) : (React.createElement(EmptyData, null,
                    React.createElement("div", null, "\u6682\u65E0\u8BA2\u5355"))))) : null,
                tabContent === 'map' ? (React.createElement(OrderContentMap, null,
                    React.createElement(OrderContext.Provider, { value: providerValue },
                        React.createElement(OrderMap, { list: dataSource, handleReceiverClick: this.handleReceiverClick, oneByMoreData: oneByMoreData, initMapStore: mapStoreList, onSubmit: this.handleSearch, ref: this.mapSearchRef, orderMapCalculateRef: this.orderMapCalculateRef, handleSwitchRoadType: this.handleSwitchRoadType, routeType: routeType, createOrderStoreId: createOrderStoreId }),
                        !this.state.currentViewId ? (React.createElement("div", { ref: this.tipBoxRef, className: "right-box" },
                            React.createElement("div", { className: "right-box-blank" },
                                React.createElement("img", { src: blankImg, alt: '' }),
                                React.createElement("div", { style: { marginBottom: '20px' }, className: "tip" }, "\u8BF7\u9009\u62E9\u8BA2\u5355\u6216\u6DFB\u52A0\u6536\u4EF6\u4EBA"),
                                React.createElement(CreateOrderAction, { callback: function (data) { return _this.handleCreateOrder(data); }, oneByMoreData: oneByMoreData, searchParams: (_a = this.mapSearchRef) === null || _a === void 0 ? void 0 : _a.current, handleUpdateCreateOrderStoreId: this.handleUpdateCreateOrderStoreId })))) : (React.createElement("div", { ref: this.tipBoxRef, className: "right-box" },
                            React.createElement(OrderMapCalculate, { onetoManyConfig: this.props.onetoManyConfig, oneByMoreData: oneByMoreData, handleReceiverClick: this.handleReceiverClick, handleRefreshOrderMap: this.handleRefreshOrderMap, mapSearchRef: this.mapSearchRef, ref: this.orderMapCalculateRef, routeType: routeType, handleSortOneMoreData: this.handleSortOneMoreData, oneByMoreOrderIds: oneByMoreOrderIds })))))) : null),
            React.createElement(UnbindTip, { ref: this.unbindTipRef })));
    };
    Orders.prototype.UnbindReq = function () {
        var _this = this;
        requestForOrder.post("/api/message/meituan/getMeituanUnbindMsg").then(function (data) {
            if ((data === null || data === void 0 ? void 0 : data.data) && _this.unbindTipRef.current) {
                _this.unbindTipRef.current(data === null || data === void 0 ? void 0 : data.data);
            }
        });
    };
    Orders.prototype.UnbindRender = function () {
        var _this = this;
        this.UnbindReq();
        events.on("MEITUAN_CANCEL_BIND", function () {
            _this.UnbindReq();
        });
    };
    return Orders;
}(React.Component));
var mapState = function (state) {
    var _a = state.orders, data = _a.data, loading = _a.loading, isPushCount = _a.isPushCount, onetoManyConfig = _a.onetoManyConfig;
    var DEFAULTMENU = state.merchant.DEFAULTMENU;
    return {
        dataSource: data.list,
        listLoading: loading,
        isPushCount: isPushCount,
        menu: DEFAULTMENU,
        order: data.list.length !== 0 ? data.list[0] : null,
        pagination: {
            current: data.page,
            pageSize: data.pageSize,
            total: data.total,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: function (total) { return "\u5171 " + total + " \u6761"; }
        },
        socketQueryParams: data.socketQueryParams,
        onetoManyConfig: onetoManyConfig
    };
};
var mapDispatch = function (dispatch) { return ({
    getOrders: dispatch.orders.fetchData,
    reqCounter: dispatch.orders.reqCounter,
    reqOnetoManyConfig: dispatch.orders.reqSetOnetoManyConfig,
}); };
export default connect(mapState, mapDispatch)(Orders);
