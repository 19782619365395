var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { message } from 'antd';
import FormModal from '@components/FormModal';
import ajax from '@utils/ajax';
import { emitOrderChange } from '../action/common';
import { ORDER_STATUS_CODE_MAP } from '../constants';
import { ActionsContext } from '../context';
import { RefuseOrderReasonForm } from '../form';
var refuseOrderReq = function (_a) {
    var orderId = _a.orderId, platform = _a.platform, reason = _a.reason;
    return ajax.post('/order/refuse', { orderId: orderId, platform: platform, reason: reason });
};
var RefuseOrderReasonModal = /** @class */ (function (_super) {
    __extends(RefuseOrderReasonModal, _super);
    function RefuseOrderReasonModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleDone = function (form) {
            var _a = _this.context, orderId = _a.id, platform = _a.platform, tabStatus = _a.tabStatus, orderEvent = _a.orderEvent;
            form.validateFields(function (errors, values) {
                if (errors)
                    return false;
                var reason = values.reason, customReason = values.customReason;
                refuseOrderReq({
                    orderId: orderId,
                    platform: platform,
                    reason: reason || customReason
                }).then(function () {
                    emitOrderChange({
                        tabStatus: tabStatus,
                        orderEvent: orderEvent,
                        orderId: orderId,
                        nextStatus: ORDER_STATUS_CODE_MAP.CANCELED
                    });
                    _this.props.toggleVisible();
                    message.success('拒单成功');
                });
            });
        };
        return _this;
    }
    RefuseOrderReasonModal.prototype.render = function () {
        var _this = this;
        var props = this.props;
        return (React.createElement(FormModal, { title: "\u62D2\u7EDD\u8BA2\u5355", visible: props.visible, onOk: function () {
                var form = _this.form.props.form;
                _this.handleDone(form);
            }, onCancel: function () {
                props.toggleVisible();
            }, destroyOnClose: true },
            React.createElement(RefuseOrderReasonForm, { wrappedComponentRef: function (form) { return (_this.form = form); } })));
    };
    return RefuseOrderReasonModal;
}(React.PureComponent));
export { RefuseOrderReasonModal };
RefuseOrderReasonModal.contextType = ActionsContext;
