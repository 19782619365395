var _a, _b;
import React from 'react';
import { Icon as AIcon, Checkbox } from 'antd';
import { DELIVERY_PLATFORM_MAP } from '@utils/constants';
import { IconFengniao, IconShansong, IconDada, IconShunFeng, IconMtKsd, IconMtGsd, IconYsZs, IconYsKs, IconUupt, IconYs, IconMtpt } from '@components/Icon';
import { EDeliveryType } from '../../../../constants';
import './index.less';
var DELIVERY_PLATFORM_MAP_ID = {
    SHANSONG: '1',
    FENGNIAO: '2',
    DADA: '4',
    SHUNFENG: '5',
    MEITUANKSD: '6',
    MEITUANGSD: '7',
    YSZS: '-11',
    YSKS: '-12',
    UUPT: '8',
    YS: '-1'
};
var DELIVERY_PLATFORM_ICON_MAP = (_a = {},
    _a[DELIVERY_PLATFORM_MAP_ID.SHANSONG] = React.createElement(IconShansong, null),
    _a[DELIVERY_PLATFORM_MAP_ID.FENGNIAO] = React.createElement(IconFengniao, null),
    _a[DELIVERY_PLATFORM_MAP_ID.DADA] = React.createElement(IconDada, null),
    _a[DELIVERY_PLATFORM_MAP_ID.SHUNFENG] = React.createElement(IconShunFeng, null),
    _a[DELIVERY_PLATFORM_MAP_ID.MEITUANKSD] = React.createElement(IconMtKsd, null),
    _a[DELIVERY_PLATFORM_MAP_ID.MEITUANGSD] = React.createElement(IconMtGsd, null),
    _a[DELIVERY_PLATFORM_MAP_ID.YSZS] = React.createElement(IconYsZs, null),
    _a[DELIVERY_PLATFORM_MAP_ID.YSKS] = React.createElement(IconYsKs, null),
    _a[DELIVERY_PLATFORM_MAP_ID.UUPT] = React.createElement(IconUupt, null),
    _a[DELIVERY_PLATFORM_MAP_ID.YS] = React.createElement(IconYs, null),
    _a);
var DELIVERY_CHANNEL_ICON_MAP = (_b = {},
    _b[DELIVERY_PLATFORM_MAP.SHANSONG] = React.createElement(IconShansong, null),
    _b[DELIVERY_PLATFORM_MAP.QS] = React.createElement(IconYsKs, null),
    _b[DELIVERY_PLATFORM_MAP.FengNiao] = React.createElement(IconFengniao, null),
    _b[DELIVERY_PLATFORM_MAP.DADA] = React.createElement(IconDada, null),
    _b[DELIVERY_PLATFORM_MAP.SHUNFENG] = React.createElement(IconShunFeng, null),
    _b[DELIVERY_PLATFORM_MAP.UUPT] = React.createElement(IconUupt, null),
    _b[DELIVERY_PLATFORM_MAP.MTPT] = React.createElement(IconMtpt, null),
    _b);
var DeliveryPlatform = function (_a) {
    var amount = _a.amount, errorMsg = _a.errorMsg, _b = _a.reducedPrice, reducedPrice = _b === void 0 ? 0 : _b, selected = _a.selected, onClick = _a.onClick, couponGroupName = _a.couponGroupName, couponGroupId = _a.couponGroupId, handleOpenCouponModal = _a.handleOpenCouponModal, platform = _a.platform, platformName = _a.platformName, currentTab = _a.currentTab, quickLySongText = _a.quickLySongText;
    var Icon = null;
    if (currentTab === EDeliveryType.Channel) {
        Icon = DELIVERY_CHANNEL_ICON_MAP[platform];
    }
    else {
        Icon = DELIVERY_PLATFORM_ICON_MAP[platform];
    }
    var prefixCls = 'deliveryPlatformDefault';
    var wrapprefixCls = 'deliveryPlatformDefaultwrap';
    if (selected) {
        prefixCls = 'deliveryPlatformActive';
        wrapprefixCls = 'deliveryPlatformActivewrap';
    }
    // else if (selected) {
    //   prefixCls = 'deliveryPlatformDisabel'
    // }
    return (React.createElement("div", { className: "" + wrapprefixCls },
        React.createElement("div", { className: "" + prefixCls, onClick: function () {
                !Boolean(errorMsg) && onClick();
            } },
            React.createElement("div", { className: prefixCls + "-left" },
                Icon,
                React.createElement("div", { className: prefixCls + "-left-text" },
                    React.createElement("div", { className: prefixCls + "-left-text-name" }, platformName),
                    !quickLySongText && errorMsg ? (React.createElement("div", { className: prefixCls + "-left-text-info" },
                        React.createElement("span", { style: { color: 'red' } }, errorMsg.replace(/([a-zA-Z1-9]+)/g, '')))) : (''))),
            !errorMsg && (React.createElement("div", { className: prefixCls + "-right" },
                React.createElement("div", { className: prefixCls + "-right-content" },
                    React.createElement("div", { className: prefixCls + "-right-content-text" },
                        currentTab === EDeliveryType.Channel ? '应付' : '预估',
                        React.createElement("span", null, currentTab === EDeliveryType.Channel
                            ? amount / 100 || ''
                            : (amount - reducedPrice) / 100 || ''),
                        "\u5143"),
                    React.createElement("div", { className: prefixCls + "-right-content-text", onClick: function (e) {
                            e.stopPropagation();
                            handleOpenCouponModal({
                                isGlobalCouponModal: false,
                                couponGroupId: couponGroupId,
                                selectCouponPlatformId: platform
                            });
                        } },
                        "\u5DF2\u4F18\u60E0",
                        typeof reducedPrice === 'number' && reducedPrice / 100,
                        "\u5143",
                        couponGroupName && (React.createElement(AIcon, { type: "right", style: { fontSize: '12px', color: '#999999' } })))),
                React.createElement(Checkbox, { checked: selected, disabled: Boolean(errorMsg) })))),
        quickLySongText ? (React.createElement("div", { style: {
                color: '#999',
                fontSize: '12px',
                textAlign: 'left',
                textIndent: '24px',
                lineHeight: '10px'
            } }, quickLySongText)) : (''),
        quickLySongText && errorMsg ? (React.createElement("div", { className: prefixCls + "-left-text-info", style: { textIndent: '24px' } },
            React.createElement("span", { style: { color: 'red' } }, errorMsg.replace(/([a-zA-Z1-9]+)/g, '')))) : ('')));
};
export default DeliveryPlatform;
