var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Button, message } from 'antd';
import ajax from '@utils/ajax';
import { requestForDelivery } from '@utils/request';
import { ActionsContext, PlatformDeliveryContext } from '../context';
import { ViewSelfDeliveryModal, ViewSelfPickModal, ViewPlatformDeliveryModal, ViewDeliveryModalChannel } from '../modal';
import { EDeliveryType } from '../constants';
import './button.less';
import { getAuth } from '@/container/AccountManagement/component';
import { useSelector } from 'react-redux';
export function ChannelDetail(id, distriOrderId, expressOrderId) {
    return ajax.post("order/distriOrder/details", { "orderId": id, "distriOrderId": expressOrderId || distriOrderId.trim() });
}
var ViewDeliveryActioninit = /** @class */ (function (_super) {
    __extends(ViewDeliveryActioninit, _super);
    function ViewDeliveryActioninit() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            visible: false,
            platformVisible: false,
            order: {},
            distriOrder: {},
            deliveryDetail: {},
            deliveryProgress: [],
            deliveryLog: [],
            selfModalVisible: false,
            selfPickModelVisible: false,
            distribPlatforms: [],
            callHistoryVO: {}
        };
        _this.getDeliveryDetail = function (orderId, platformOrderId, platform) {
            return ajax.post('/order/pc/distri/details', {
                orderId: orderId,
                platformOrderId: platformOrderId,
                platform: platform
            });
        };
        _this.toggleVisible = function () {
            var visible = _this.state.visible;
            _this.setState({ visible: !visible });
        };
        _this.togglePlatformVisible = function () {
            var platformVisible = _this.state.platformVisible;
            _this.setState({ platformVisible: !platformVisible });
        };
        _this.handleFetchPlatformDelivery = function (expressOrderId) {
            var _a = _this.context, id = _a.id, distriOrderId = _a.distriOrderId;
            requestForDelivery
                .get("/platform/transport/getDistriDetail", {
                orderId: id,
                expressOrderId: expressOrderId || distriOrderId.trim()
            })
                .then(function (back) {
                var data = back.data;
                if (data) {
                    _this.setState({
                        deliveryDetail: data
                    }, function () {
                        _this.togglePlatformVisible();
                    });
                }
                else {
                    message.error(back.err);
                }
            });
        };
        _this.handleFetchChannelDelivery = function (expressOrderId, cb) {
            var _a = _this.context, id = _a.id, distriOrderId = _a.distriOrderId;
            ChannelDetail(id, distriOrderId, expressOrderId)
                .then(function (back) {
                var data = back.data;
                if (data) {
                    _this.setState({
                        deliveryDetail: data
                    }, function () {
                        cb && cb();
                        // if(!this.state.visible){
                        //   this.toggleVisible()
                        // }
                    });
                }
                else {
                    message.error(back.err);
                }
            });
        };
        _this.showDeliveryDetail = function () {
            var _a = _this.context, _b = _a.selfSend, selfSend = _b === void 0 ? {} : _b, _c = _a.selfFetch, selfFetch = _c === void 0 ? {} : _c, payAccountType = _a.payAccountType;
            // 自配送
            if (selfSend.code === 0) {
                _this.setSendOrderVisible(true);
                return;
            }
            // 到店自取 selfFetch
            if (selfFetch.code === 0) {
                _this.setState({
                    selfPickModelVisible: true
                });
                return;
            }
            if (payAccountType === EDeliveryType.Channel) {
                // TODO: 渠道配送单详情 接口更改成 类似 平台配送单详情
                _this.handleFetchChannelDelivery(undefined, function () {
                    _this.toggleVisible();
                });
            }
            else {
                _this.handleFetchPlatformDelivery();
            }
        };
        _this.setSendOrderVisible = function (visiable) {
            _this.setState({
                selfModalVisible: visiable
            });
        };
        _this.changeDeliveryPlatformTab = function (platform) {
            var _a = _this.context, id = _a.id, platformId = _a.platformId;
            _this.getDeliveryDetail(id, platformId, platform).then(function (_a) {
                var data = _a.data;
                _this.setState({
                    order: data.order,
                    distriOrder: data.distriOrder || {}
                });
            });
        };
        _this.handleChangeDeliveryChannelTab = function (val, expressOrderId) {
            var id = _this.context.id;
            console.log(_this.context, 'this.context');
            if (val === '1') {
                _this.handleFetchChannelDelivery();
            }
            else if (val === '2') {
                ajax
                    .post("/order/distri/tracks", {
                    orderId: expressOrderId || id || ''
                })
                    .then(function (back) {
                    var data = back.data;
                    if (data) {
                        _this.setState({
                            deliveryProgress: data
                        });
                    }
                    else {
                        message.error(back.err);
                    }
                });
            }
        };
        _this.handleChangeDeliveryPlatformTab = function (val, expressOrderId) {
            var _a = _this.context, id = _a.id, distriOrderId = _a.distriOrderId;
            if (val === '1') {
                requestForDelivery
                    .get("/platform/transport/getDistriDetail", {
                    orderId: id,
                    expressOrderId: expressOrderId || distriOrderId.trim()
                })
                    .then(function (back) {
                    var data = back.data;
                    if (data) {
                        _this.setState({
                            deliveryDetail: data
                        });
                    }
                    else {
                        message.error(back.err);
                    }
                });
            }
            else if (val === '2') {
                var id_1 = _this.state.deliveryDetail.id;
                requestForDelivery
                    .get("/platform/transport/getDistriTracks", {
                    expressOrderId: expressOrderId || id_1 || ''
                })
                    .then(function (back) {
                    var data = back.data;
                    if (data) {
                        _this.setState({
                            deliveryProgress: data
                        });
                    }
                    else {
                        message.error(back.err);
                    }
                });
            }
            else if (val === '3') {
                requestForDelivery
                    .get("/platform/transport/getDistriList", {
                    orderId: id
                })
                    .then(function (back) {
                    var data = back.data;
                    if (data) {
                        _this.setState({
                            deliveryLog: data
                        });
                    }
                    else {
                        message.error(back.err);
                    }
                });
            }
        };
        return _this;
    }
    ViewDeliveryActioninit.prototype.getDistribPlatforms = function (storeId) {
        var _this = this;
        //获取门店下已经绑定过的配送平台
        ajax.get("/distri/platform/list?status=1&storeId=" + storeId).then(function (res) {
            var distribPlatforms = res.data.list;
            _this.setState({
                distribPlatforms: distribPlatforms
            });
        });
    };
    ViewDeliveryActioninit.prototype.render = function () {
        var _this = this;
        var _a = this.state, visible = _a.visible, selfModalVisible = _a.selfModalVisible, selfPickModelVisible = _a.selfPickModelVisible, platformVisible = _a.platformVisible, deliveryDetail = _a.deliveryDetail, deliveryProgress = _a.deliveryProgress, deliveryLog = _a.deliveryLog;
        var _b = this.context, selfSend = _b.selfSend, selfFetch = _b.selfFetch, time = _b.time, id = _b.id, distriOrderId = _b.distriOrderId;
        var handleChangeDeliveryPlatformTab = this.handleChangeDeliveryPlatformTab;
        var handleFetchPlatformDelivery = this.handleFetchPlatformDelivery;
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: function () { return _this.showDeliveryDetail(); }, className: "orderbutton", type: "primary" }, "\u67E5\u770B\u914D\u9001\u5355"),
            visible ? (
            //DeliveryContext
            // TODO：渠道模式 配送单详情 弹框 修改成 平台配送单详情弹框 传参的参数结构 需要转换一下 或者换一个弹框 不提倡
            React.createElement(PlatformDeliveryContext.Provider, { value: {
                    order: this.context,
                    deliveryDetail: deliveryDetail,
                    orderDetail: { id: id, distriOrderId: distriOrderId },
                    deliveryProgress: deliveryProgress,
                    deliveryLog: deliveryLog,
                    handleChangeDeliveryPlatformTab: this.handleChangeDeliveryChannelTab,
                    handleFetchPlatformDelivery: handleFetchPlatformDelivery,
                } },
                React.createElement(ViewDeliveryModalChannel, { visible: visible, toggleVisible: this.toggleVisible }))) : null,
            platformVisible ? (React.createElement(PlatformDeliveryContext.Provider, { value: {
                    order: this.context,
                    deliveryDetail: deliveryDetail,
                    orderDetail: { id: id, distriOrderId: distriOrderId },
                    deliveryProgress: deliveryProgress,
                    deliveryLog: deliveryLog,
                    handleChangeDeliveryPlatformTab: handleChangeDeliveryPlatformTab,
                    handleFetchPlatformDelivery: handleFetchPlatformDelivery,
                } },
                React.createElement(ViewPlatformDeliveryModal, { visible: platformVisible, toggleVisible: this.togglePlatformVisible }))) : null,
            selfModalVisible ? (React.createElement(ViewSelfDeliveryModal, { platform: selfSend.type, remark: selfSend.msg, hide: function () { return _this.setSendOrderVisible(false); } })) : null,
            selfPickModelVisible ? (React.createElement(ViewSelfPickModal, { time: time, remark: selfFetch === null || selfFetch === void 0 ? void 0 : selfFetch.remarks, hide: function () { return _this.setState({ selfPickModelVisible: false }); } })) : null));
    };
    return ViewDeliveryActioninit;
}(React.Component));
ViewDeliveryActioninit.contextType = ActionsContext;
export var ViewDeliveryAction = function (props) {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("订单查看", menu) ? React.createElement(ViewDeliveryActioninit, __assign({}, props)) : null;
};
