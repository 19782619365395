var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Select } from 'antd';
var Option = Select.Option;
var StoreSelect = function (props) {
    var data = props.data;
    return (React.createElement(Select, __assign({ style: { width: '180px ', margin: '0 12px 20px 0' }, defaultValue: "" }, props),
        React.createElement(Option, { key: "0", value: "" }, "\u5168\u90E8\u95E8\u5E97"),
        data && Array.isArray(data) && data.map(function (store) { return (React.createElement(Option, { key: store.id, value: store.id }, store.name)); })));
};
export default StoreSelect;
