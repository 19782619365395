var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Form, Radio, Select, Switch, Input } from 'antd';
import produce from 'immer';
import styled from 'styled-components';
import { requestForMerchant } from '@utils/request';
import { getItem } from '@utils/localStorage';
import ajax from '@utils/ajax';
import { DELIVERY_PLATFORM_MAP } from '@utils/constants';
import { storeIdValidator } from '@utils/validator';
import BindTipInfo from './BindTipInfo';
export var BindShansongMethod;
(function (BindShansongMethod) {
    BindShansongMethod[BindShansongMethod["New"] = 0] = "New";
    BindShansongMethod[BindShansongMethod["Old"] = 1] = "Old";
})(BindShansongMethod || (BindShansongMethod = {}));
var FormItem = Form.Item;
var Option = Select.Option;
var formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 }
};
var platformStatusMap = ['未绑定', '已绑定', '暂不支持'];
var getDeliveryByStoreReq = function (storeId) {
    return ajax.get("/distri/platform/list?storeId=" + storeId);
};
var ServiceTips = styled.p.withConfig({ displayName: "ServiceTips", componentId: "sc-145kcyb" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 14px;\n  color: #999;\n  line-height: 20px;\n"], ["\n  font-size: 14px;\n  color: #999;\n  line-height: 20px;\n"])));
var ShansongServiceOption = /** @class */ (function (_super) {
    __extends(ShansongServiceOption, _super);
    function ShansongServiceOption() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ShansongServiceOption.prototype.render = function () {
        var _a = this.props, getFieldDecorator = _a.getFieldDecorator, initialValue = _a.initialValue;
        return (React.createElement(React.Fragment, null,
            React.createElement(FormItem, { label: "\u53D6\u4EF6\u7801" },
                getFieldDecorator('takeCode', {
                    initialValue: !!Number(initialValue.takeCode)
                })(React.createElement(Switch, { defaultChecked: !!Number(initialValue.takeCode), checkedChildren: "\u5F00", unCheckedChildren: "\u5173" })),
                React.createElement(ServiceTips, null, "\u53D6\u4EF6\u65F6\u9700\u8981\u9A8C\u8BC1\uFF0C\u5F53\u60A8\u5F00\u542F\u540E\uFF0C\u53D6\u4EF6\u65F6\u9700\u6821\u9A8C\u53D6\u4EF6\u7801\u624D\u53EF\u5B8C\u6210\u53D6\u4EF6\u3002")),
            React.createElement(FormItem, { label: "\u6536\u4EF6\u7801" },
                getFieldDecorator('receiveCode', {
                    initialValue: !!Number(initialValue.receiveCode)
                })(React.createElement(Switch, { defaultChecked: !!Number(initialValue.receiveCode), checkedChildren: "\u5F00", unCheckedChildren: "\u5173" })),
                React.createElement(ServiceTips, null, "\u9001\u8FBE\u65F6\u9700\u8981\u9A8C\u8BC1\uFF0C\u5F53\u60A8\u5F00\u542F\u540E\uFF0C\u9001\u8FBE\u65F6\u9700\u6821\u9A8C\u6536\u4EF6\u7801\u7801\u624D\u53EF\u5B8C\u6210\u914D\u9001\u3002"))));
    };
    return ShansongServiceOption;
}(React.Component));
export { ShansongServiceOption };
var BindPlatform = /** @class */ (function (_super) {
    __extends(BindPlatform, _super);
    function BindPlatform(props) {
        var _this = _super.call(this, props) || this;
        _this.handleStoreChange = function (storeId) {
            _this.getDeliveryByStore(storeId);
        };
        _this.handlePlatformChange = function (platform) {
            _this.setState(produce(function (draft) {
                draft.platform = platform;
            }));
            // 清空已绑定的门店
            if (_this.props.form.getFieldValue('masterStoreId')) {
                _this.props.form.setFieldsValue({ masterStoreId: '' });
            }
            var api = '';
            if (platform === DELIVERY_PLATFORM_MAP.SHANSONG) {
                api = '/distri/platform/shansong/store/list';
            }
            else if (platform === DELIVERY_PLATFORM_MAP.DADA) {
                api = '/distri/platform/dada/store/list';
            }
            else if (platform === DELIVERY_PLATFORM_MAP.FengNiao) {
                api = '/distri/platform/fengniao/store/list';
            }
            else if (platform === DELIVERY_PLATFORM_MAP.UUPT) {
                api = '/distri/platform/uupt/store/list';
            }
            if (api) {
                ajax.get(api).then(function (_a) {
                    var data = _a.data;
                    _this.setState(produce(function (draft) {
                        draft.bindingPlatformList = data.list || [];
                    }));
                });
            }
        };
        _this.state = {
            storeList: [],
            platformList: [],
            redirectUri: '',
            platform: '',
            bindingPlatformList: []
        };
        return _this;
    }
    BindPlatform.prototype.componentDidMount = function () {
        var _this = this;
        var validateFields = this.props.form.validateFields;
        var lastChooseMerchant = getItem('lastChooseMerchant') && JSON.parse(getItem('lastChooseMerchant'));
        requestForMerchant.post("/merchant/store/getAllStoreByMerchantId", {
            merchantId: lastChooseMerchant.merchantId
        }).then(function (_a) {
            var data = _a.data;
            _this.setState(produce(function (draft) {
                draft.storeList = data;
            }), function () {
                validateFields(['storeId']);
            });
            var defaultStoreId = data[0] && data[0].id;
            _this.getDeliveryByStore(defaultStoreId);
        });
    };
    BindPlatform.prototype.getDeliveryByStore = function (storeId) {
        var _this = this;
        getDeliveryByStoreReq(storeId).then(function (_a) {
            var data = _a.data;
            var list = data.list, redirectUri = data.redirectUri;
            _this.setState(produce(function (draft) {
                draft.platformList = list;
                draft.redirectUri = redirectUri;
            }));
        });
    };
    BindPlatform.prototype.renderStoreList = function () {
        return this.state.storeList.map(function (store) {
            return (React.createElement(Option, { key: store.id, value: store.id },
                store.name,
                " - ",
                store.numbering));
        });
    };
    BindPlatform.prototype.renderPlatformList = function () {
        return this.state.platformList.map(function (platform) {
            var disabled = platform.status > 0;
            var statusText = platform.status > 0 ? "- " + platformStatusMap[platform.status] : '';
            if (platform.name !== '顺丰') {
                return (React.createElement(Option, { key: platform.platform, value: platform.platform, disabled: disabled },
                    platform.name,
                    " ",
                    statusText));
            }
        });
    };
    BindPlatform.prototype.renderDeliveryOptions = function () {
        var platform = this.state.platform;
        var getFieldDecorator = this.props.form.getFieldDecorator;
        var props = {
            initialValue: {},
            getFieldDecorator: getFieldDecorator
        };
        switch (platform) {
            case DELIVERY_PLATFORM_MAP.SHANSONG:
                return React.createElement(ShansongServiceOption, __assign({}, props));
            default:
                return null;
        }
    };
    BindPlatform.prototype.render = function () {
        var state = this.state;
        var _a = this.props.form, getFieldDecorator = _a.getFieldDecorator, getFieldValue = _a.getFieldValue;
        return (React.createElement(Form, __assign({}, formItemLayout),
            React.createElement(FormItem, { label: "\u9009\u62E9\u95E8\u5E97" }, getFieldDecorator('storeId', {
                initialValue: state.storeList[0] ? state.storeList[0].id : '',
                rules: [
                    {
                        required: true,
                        validator: storeIdValidator({ stores: state.storeList })
                    }
                ]
            })(React.createElement(Select, { onChange: this.handleStoreChange }, this.renderStoreList()))),
            React.createElement(FormItem, { label: "\u9009\u62E9\u5E73\u53F0" }, getFieldDecorator('platform', {
                rules: [
                    {
                        required: true,
                        message: '请选择需要绑定的平台'
                    }
                ]
            })(React.createElement(Select, { onChange: this.handlePlatformChange }, this.renderPlatformList()))),
            getFieldValue('platform') && state.platform !== DELIVERY_PLATFORM_MAP.SHUNFENG ? (React.createElement(React.Fragment, null,
                React.createElement(FormItem, { label: "\u7ED1\u5B9A\u65B9\u5F0F" }, getFieldDecorator('method', {
                    initialValue: BindShansongMethod.New
                })(React.createElement(Radio.Group, null,
                    React.createElement(Radio, { value: BindShansongMethod.New }, "\u65B0\u8D26\u53F7"),
                    React.createElement(Radio, { value: BindShansongMethod.Old, disabled: state.bindingPlatformList.length === 0 }, "\u5176\u5B83\u95E8\u5E97\u5DF2\u7ED1\u5B9A\u7684\u8D26\u53F7")))),
                getFieldValue('method') === BindShansongMethod.Old ? (React.createElement(FormItem, { label: "\u5DF2\u7ED1\u5B9A\u7684\u95E8\u5E97" }, getFieldDecorator('masterStoreId', {
                    rules: [
                        {
                            required: getFieldValue('method') === BindShansongMethod.Old,
                            message: '请选择已绑定闪送的门店'
                        }
                    ]
                })(React.createElement(Select, null, state.bindingPlatformList.map(function (item, key) {
                    return (React.createElement(Option, { key: key, value: item.store.id },
                        item.store.name,
                        " - ",
                        item.store.numbering));
                }))))) : null)) : null,
            state.platform === DELIVERY_PLATFORM_MAP.SHUNFENG ? (React.createElement(FormItem, { label: "\u7ED1\u5B9A\u5E97\u94FA" }, getFieldDecorator('shunfengStoreId', {
                rules: [
                    {
                        required: getFieldValue('method') === BindShansongMethod.New,
                        message: '请输入顺丰店铺Id'
                    }
                ]
            })(React.createElement(Input, { placeholder: '\u5728\u8FD9\u91CC\u8F93\u5165\u987A\u4E30\u5E97\u94FAId' })))) : null,
            React.createElement(BindTipInfo, { platform: state.platform })));
    };
    return BindPlatform;
}(React.Component));
var WrappedBindPlatform = Form.create({})(BindPlatform);
export default WrappedBindPlatform;
var templateObject_1;
