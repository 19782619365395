var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Form, Input } from 'antd';
var FormItem = Form.Item;
var TextArea = Input.TextArea;
var DisagreeCancelReason = /** @class */ (function (_super) {
    __extends(DisagreeCancelReason, _super);
    function DisagreeCancelReason() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DisagreeCancelReason.prototype.render = function () {
        var getFieldDecorator = this.props.form.getFieldDecorator;
        return (React.createElement(Form, null,
            React.createElement(FormItem, { label: "\u62D2\u7EDD\u53D6\u6D88\u8BA2\u5355\u7406\u7531" }, getFieldDecorator('reason', {
                rules: [
                    {
                        required: true,
                        message: '请填写拒绝理由'
                    }
                ]
            })(React.createElement(TextArea, null)))));
    };
    return DisagreeCancelReason;
}(React.PureComponent));
export var DisagreeCancelReasonForm = Form.create({})(DisagreeCancelReason);
