var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CloudPrinterPlatform } from '@utils/constants';
import { postFeiECloudPrintTask, postFeiYinPrintTask, postYiLianCloudPrintTask, postZhongWuPrintTask } from './status';
import { FeiECloudTpl, YiLianCloudTpl, ZhongWuCloudTpl } from './template';
import { FeiYinCloudTpl } from './template/feiyin';
export var getCloudPrinterWorker = function (_a) {
    var authData = _a.authData, platform = _a.platform, printCase = _a.printCase, paperFormat = _a.paperFormat, data = _a.data, callback = _a.callback;
    switch (platform) {
        case CloudPrinterPlatform.YiLianYun:
            return function (queueCallback) { return __awaiter(void 0, void 0, void 0, function () {
                var content;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            content = YiLianCloudTpl[printCase]({ paperFormat: paperFormat, data: data });
                            return [4 /*yield*/, postYiLianCloudPrintTask({
                                    authData: authData,
                                    content: content,
                                    orderId: data.platformId,
                                    callback: function (err) {
                                        callback && callback(err, data);
                                    }
                                })];
                        case 1:
                            _a.sent();
                            queueCallback();
                            return [2 /*return*/];
                    }
                });
            }); };
        case CloudPrinterPlatform.FeiEYun:
            return function (queueCallback) { return __awaiter(void 0, void 0, void 0, function () {
                var content;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            content = FeiECloudTpl[printCase]({ paperFormat: paperFormat, data: data });
                            return [4 /*yield*/, postFeiECloudPrintTask({
                                    authData: authData,
                                    content: content,
                                    orderId: data.platformId,
                                    callback: function (err) {
                                        callback && callback(err, data);
                                    }
                                })];
                        case 1:
                            _a.sent();
                            queueCallback();
                            return [2 /*return*/];
                    }
                });
            }); };
        case CloudPrinterPlatform.ZhongWuYun:
            return function (queueCallback) { return __awaiter(void 0, void 0, void 0, function () {
                var printdata;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            printdata = ZhongWuCloudTpl[printCase]({ paperFormat: paperFormat, data: data });
                            return [4 /*yield*/, postZhongWuPrintTask({
                                    authData: authData,
                                    printdata: printdata,
                                    orderId: data.platformId,
                                    callback: function (err) {
                                        callback && callback(err, data);
                                    }
                                })];
                        case 1:
                            _a.sent();
                            queueCallback();
                            return [2 /*return*/];
                    }
                });
            }); };
        case CloudPrinterPlatform.FeiYinYun:
            return function (queueCallback) { return __awaiter(void 0, void 0, void 0, function () {
                var msgContent;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            msgContent = FeiYinCloudTpl[printCase]({ paperFormat: paperFormat, data: data });
                            return [4 /*yield*/, postFeiYinPrintTask({
                                    authData: authData,
                                    msgContent: msgContent,
                                    orderId: data.platformId,
                                    callback: function (err) {
                                        callback && callback(err, data);
                                    }
                                })];
                        case 1:
                            _a.sent();
                            queueCallback();
                            return [2 /*return*/];
                    }
                });
            }); };
        default:
            return function () { };
    }
};
