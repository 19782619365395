var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/*
 * @Author: hijack
 * @Date: 2021-07-21 14:45:00
 * @LastEditTime: 2021-07-21 14:55:27
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/container/AgreementPrivacy/index.tsx
 */
import * as React from 'react';
import marked from 'marked';
import { isElectron } from '@utils/env';
import agreementFile from './privacy.md';
import { AgreementContent, AgreementWrapper, BackButton } from './styled';
var AgreementPrivacy = /** @class */ (function (_super) {
    __extends(AgreementPrivacy, _super);
    function AgreementPrivacy() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            markdown: ''
        };
        return _this;
    }
    AgreementPrivacy.prototype.componentDidMount = function () {
        this.setState({
            markdown: marked(agreementFile)
        });
    };
    AgreementPrivacy.prototype.render = function () {
        return (React.createElement(AgreementWrapper, null,
            React.createElement(AgreementContent, { dangerouslySetInnerHTML: { __html: this.state.markdown } }),
            React.createElement(BackButton, null,
                React.createElement("a", { href: isElectron ? '#/' : '/login' }, "\u8FD4\u56DE"))));
    };
    return AgreementPrivacy;
}(React.Component));
export default AgreementPrivacy;
