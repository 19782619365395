import { merchant } from "@/models/merchant";
var arr = [
    "订单查看", "订单管理", "门店管理", "报表分析", "我的",
    "补打印", "配送处理", "策略设置", "绑定接单平台",
    "绑定运力平台", "打印设置", "账号管理", "数据权限"
];
// const menuNameArr=
// [  
//     "订单查看","订单管理","门店管理","报表分析","我的",
//     "补打印","配送处理","","绑定接单平台",
//     "绑定运力平台","打印设置","账号管理","数据权限"
// ];
export function getAuth(name, currentMENUtemp) {
    var currentMENU = (currentMENUtemp && currentMENUtemp.length !== 0) ? currentMENUtemp : JSON.parse(localStorage.getItem('defaultMenu') || '[]');
    var menuNameArr = currentMENU.map(function (item) { return item === null || item === void 0 ? void 0 : item.menuName; });
    if (name.indexOf("门店管理菜单") !== -1) {
        var str_1 = name.indexOf("子") !== -1 ? "门店管理策略设置" : "门店管理绑定接单平台绑定运力平台打印设置策略设置";
        var arr_1 = menuNameArr.filter(function (item) { return str_1.indexOf(item) !== -1; });
        return arr_1.length > 0;
    }
    else {
        return menuNameArr.join(",").indexOf(name) !== -1;
    }
}
export var buttonAuthFunc = {
// "补打印":  ["PrintAction"],
// "订单查看":["ViewDeliveryAction"],
// "配送处理":["CancelDeliveryOrderActionWAIT_GRAB","DeliveryOrderActions","SendDeliveryActionAGAIN","SendDeliveryAction","DeliveryBySelfAction"],
};
export var merchatMenuAuth = ["门店管理", "绑定运力平台", "绑定接单平台", "打印设置", "策略设置"];
export function getAuthDom(authname, dom) {
    return getAuth(authname) ? dom : null;
}
export function getMerchantAuth() {
    var currentMENU = merchant.state.DEFAULTMENU.length !== 0 ? merchant.state.DEFAULTMENU : JSON.parse(localStorage.getItem('defaultMenu') || '[]');
    var menuNameArr = currentMENU.map(function (item) { return item === null || item === void 0 ? void 0 : item.menuName; });
    return menuNameArr.filter(function (item) { return merchatMenuAuth.indexOf(item) !== -1; }).length > 0;
}
