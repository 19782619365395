var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*
 * @Author: hijack
 * @Date: 2021-06-07 16:51:46
 * @LastEditTime: 2021-06-10 14:04:03
 * @LastEditors: your name
 * @Description:
 * @FilePath: /easyex-web/app/container/Merchant/List/components/ListItem/index.tsx
 */
import React from 'react';
import moment from 'moment';
import { SItem, SItemStatus, SItmeFlag } from './styled';
import { getItem } from '@utils/localStorage';
import rightArrowIcon from '@assets/images/right-arrow.png';
import userIcon from '@assets/images/user.png';
import phoneIcon from '@assets/images/phone.png';
var ListItem = function (props) {
    var selectMerchantItem = props.selectMerchantItem, data = props.data, setSelectMerchantItem = props.setSelectMerchantItem;
    var lastChooseMerchant = getItem('lastChooseMerchant') && JSON.parse(getItem('lastChooseMerchant'));
    var handleSelectItem = function (item) {
        var param = __assign(__assign({}, lastChooseMerchant), item);
        setSelectMerchantItem(param);
    };
    return data ? data.map(function (item) { return (React.createElement(SItem, { key: item.merchantId, active: selectMerchantItem.merchantId === item.merchantId, onClick: function () { return handleSelectItem(item); } },
        React.createElement("div", { className: 'left' },
            React.createElement("div", { className: 'title' }, item.merchantName),
            React.createElement("div", { className: 'userName' },
                React.createElement("img", { alt: '', src: userIcon, className: 'icon' }),
                item.bossName, "   ",
                React.createElement("img", { alt: '', src: phoneIcon, className: 'icon' }),
                item.phone),
            React.createElement("div", { className: 'datetime' },
                "\u63D0\u4EA4\u5BA1\u6838\u65F6\u95F4\uFF1A",
                moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')),
            React.createElement("div", { className: 'datetime' },
                "\u96C6\u56E2\u7ED1\u5B9A\u65F6\u95F4\uFF1A",
                item.bandMerchantGroupTime && moment(item.bandMerchantGroupTime).format('YYYY-MM-DD HH:mm:ss')),
            lastChooseMerchant.merchantId === item.merchantId && React.createElement(SItmeFlag, null, "\u4E0A\u6B21\u9009\u62E9\u7684\u5546\u6237")),
        React.createElement("div", { className: 'right' },
            React.createElement("img", { alt: '', src: rightArrowIcon, className: 'icon' }),
            React.createElement(SItemStatus, { gray: item.auditStatus !== 2 }, item.auditStatus === 2
                ? '审核驳回'
                : item.auditStatus === 1
                    ? '审核通过'
                    : '待审核')))); }) : null;
};
export default ListItem;
