var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import { message } from 'antd';
import IntroductionImage1 from '@assets/images/introduction-1.png';
import IntroductionImage2 from '@assets/images/introduction-2.png';
import IntroductionImage3 from '@assets/images/introduction-3.png';
import { useToggle } from '@hooks';
import ajax from '@utils/ajax';
import packageJson from '../../../package.json';
import { FormInput, SMSInputWrapper, SMSPostButton } from './styled';
import { getEnv } from '@utils/env';
import { useHistory } from 'react-router-dom';
var Platform;
(function (Platform) {
    Platform["MAC"] = "MAC";
    Platform["WINDOWS"] = "WINDOWS";
})(Platform || (Platform = {}));
var packageBaseUrl = 'https://easyex-package.oss-cn-beijing.aliyuncs.com';
var latestVersion = packageJson.version;
var introductionImageList = [
    IntroductionImage1,
    IntroductionImage2,
    IntroductionImage3
];
var isMac = (function () { return navigator.platform.indexOf('Mac') > -1; })();
var isWindows = (function () { return navigator.platform.indexOf('Win') > -1; })();
var SMSInput = function (props) {
    var _a = __read(useToggle(false), 3), timing = _a[0], startTiming = _a[1], stopTiming = _a[2];
    var _b = __read(React.useState(60), 2), countdown = _b[0], setCountdown = _b[1];
    var phone = props.value;
    var handleTiming = function () {
        if (!phone) {
            return message.error('请输入手机号');
        }
        if (countdown === 60) {
            startTiming();
        }
    };
    React.useEffect(function () {
        var timer = null;
        if (timing) {
            ajax.post('/official/website/send/code', { phone: phone }).then(function () {
                timer = setInterval(function () {
                    setCountdown(function (preCountdown) {
                        if (preCountdown <= 1) {
                            stopTiming();
                            clearInterval(Number(timer));
                            return 60;
                        }
                        else {
                            return preCountdown - 1;
                        }
                    });
                }, 1000);
            });
        }
        return function () { return clearInterval(Number(timer)); };
    }, [timing]);
    return (React.createElement(SMSInputWrapper, null,
        React.createElement(FormInput, __assign({}, props)),
        React.createElement(SMSPostButton, { onClick: handleTiming }, timing ? countdown + "S" : '获取验证码')));
};
var browser = {
    versions: (function () {
        var u = navigator.userAgent, app = navigator.appVersion;
        return {
            //移动终端浏览器版本信息
            trident: u.indexOf('Trident') > -1,
            presto: u.indexOf('Presto') > -1,
            webKit: u.indexOf('AppleWebKit') > -1,
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,
            mobile: !!u.match(/AppleWebKit.*Mobile.*/),
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
            android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1,
            iPhone: u.indexOf('iPhone') > -1,
            iPad: u.indexOf('iPad') > -1,
            webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
        };
    })(),
    language: (navigator.browserLanguage || navigator.language).toLowerCase()
};
var Index = function () {
    var history = useHistory();
    useEffect(function () {
        // history.replace(location.host + '/easy-home/')
        window.location.href =
            window.location.origin +
                (!browser.versions.mobile ? '/easyhome/' : '/easyhome/#/phone');
    }, []);
    var carouseRef = React.useRef();
    var _a = __read(React.useState(false), 2), QRCodeVisible = _a[0], setQRCodeVisible = _a[1];
    var env = getEnv();
    var handleDownloadClick = function (platform) {
        if (platform === Platform.MAC) {
            return window.open(process.env.NODE_ENV === 'production'
                ? packageBaseUrl + "/release/easyex-release-" + latestVersion + ".dmg"
                : packageBaseUrl + "/inhouse/easyex-inhouse-" + latestVersion + ".dmg");
        }
        if (platform === Platform.WINDOWS) {
            return window.open(process.env.NODE_ENV === 'production'
                ? packageBaseUrl + "/release/easyex-release-" + latestVersion + ".exe"
                : packageBaseUrl + "/inhouse/easyex-inhouse-" + latestVersion + ".exe");
        }
    };
    var handleSubmit = function (values) {
        if (!values.companyName) {
            return message.error('请输入 企业名称');
        }
        if (!values.contact) {
            return message.error('请输入 联系人');
        }
        if (!values.code) {
            return message.error('请输入 验证码');
        }
        if (!values.note) {
            return message.error('请输入 需求备注');
        }
        if (!values.phone) {
            return message.error('请输入 手机号码');
        }
        ajax.post('/official/website/application/form', values).then(function () {
            message.success('信息提交成功');
            location.reload();
        });
    };
    var showQRCode = React.useCallback(function () {
        setQRCodeVisible(true);
    }, []);
    var hideQRCode = React.useCallback(function () {
        setQRCodeVisible(false);
    }, []);
    return null;
    // return (
    //   <Container>
    //     <ChromeCheck />
    //     <Header>
    //       <ProduceName>掌易 · 易送</ProduceName>
    //       <Menu>
    //         <MenuItem>
    //           <a href="/#">首页</a>
    //         </MenuItem>
    //         <MenuItem>
    //           <a href="/#introduction">产品介绍</a>
    //         </MenuItem>
    //         <MenuItem>
    //           <a href="/#apply">申请购买</a>
    //         </MenuItem>
    //         <MenuItem>
    //           <a href="/#download">软件下载</a>
    //         </MenuItem>
    //       </Menu>
    //     </Header>
    //     <DownloadContent id="download">
    //       <WelcomeText>欢迎使用 易送商家 SaaS 系统</WelcomeText>
    //       <Text>
    //         免费使用：免费扶持中小商家解决高峰配送问题，不额外收取任何费用，大商家支持功能定制
    //       </Text>
    //       <Text>
    //         一键发单：美团饿了么等平台订单一键下发配送，手动或自动发单两种模式，满足不同场景
    //       </Text>
    //       <Text>同时发单：同一订单发起多家配送，解决高峰无人接单问题</Text>
    //       <Text>自配送状态回传：支持美团自配送商家状态回传完整达标</Text>
    //       <ButtonGroup>
    //         <div
    //           className="btn"
    //           onMouseEnter={showQRCode}
    //           onMouseLeave={hideQRCode}
    //         >
    //           <CodeButton>App下载</CodeButton>
    //           {QRCodeVisible ? <QRCode env={env} /> : null}
    //         </div>
    //         {/* <DownloadButton
    //           onClick={() => {
    //             isMac ? handleDownloadClick(Platform.MAC) : null
    //             isWindows ? handleDownloadClick(Platform.WINDOWS) : null
    //           }}
    //         >
    //           桌面版下载
    //         </DownloadButton> */}
    //         <RedirectToWebButton href="/easyweb">进入网页版</RedirectToWebButton>
    //       </ButtonGroup>
    //     </DownloadContent>
    //     <IntroduceContent id="introduction">
    //       <Title>产品介绍</Title>
    //       <Content>
    //         【掌易】为本地生活商家提供私域内获客、售卖，全域订单配送的一站式解决方案，帮助商家提升经营收入、降低经营成本。
    //       </Content>
    //       <Content>
    //         易送商家 SaaS 系统是新零售时代，企业必不可缺的全渠道外送订单统一管理
    //         SaaS 平台。易送，专注于基于订单管理的技术服务，以 SaaS
    //         模式将【外送平台】、【外送商户】、【配送平台】整合，提供优秀的客户体验。
    //       </Content>
    //       <ApplyButton href="/#apply">免费试用</ApplyButton>
    //     </IntroduceContent>
    //     <CarouseWrapper>
    //       <CarousePrev onClick={() => carouseRef.current.prev()} />
    //       <Carousel ref={carouseRef} autoplay>
    //         {introductionImageList.map((url, key) => {
    //           return (
    //             <CarouselItem key={key}>
    //               <CarouselImage src={url} />
    //             </CarouselItem>
    //           )
    //         })}
    //       </Carousel>
    //       <CarouseNext onClick={() => carouseRef.current.next()} />
    //     </CarouseWrapper>
    //     <ApplyFormContent id="apply">
    //       <Title>
    //         易送，客户的外卖订单系统首选，国家认证网络安全等级保护三级!
    //       </Title>
    //       <ApplyFormWrapper>
    //         <ApplyImage src={ApplyImageUrl} />
    //         <div>
    //           <ApplyFormTitle>留下您的联系方式，获取最新报价</ApplyFormTitle>
    //           <Formik
    //             initialValues={{
    //               companyName: '',
    //               contact: '',
    //               phone: '',
    //               code: '',
    //               note: ''
    //             }}
    //             onSubmit={(values: any) => {
    //               handleSubmit(values)
    //             }}
    //           >
    //             {({ values, setFieldValue, submitForm }) => {
    //               return (
    //                 <ApplyForm>
    //                   <Row gutter={60} style={{ marginBottom: '44px' }}>
    //                     <Col span={12}>
    //                       <FormField>
    //                         <FormLabel>企业名称</FormLabel>
    //                         <FormInput
    //                           onChange={({ target: { value } }) =>
    //                             setFieldValue('companyName', value)
    //                           }
    //                           placeholder="请输入企业名称"
    //                         />
    //                       </FormField>
    //                     </Col>
    //                     <Col span={12}>
    //                       <FormField>
    //                         <FormLabel>联系人</FormLabel>
    //                         <FormInput
    //                           onChange={({ target: { value } }) =>
    //                             setFieldValue('contact', value)
    //                           }
    //                           placeholder="请输入联系人"
    //                         />
    //                       </FormField>
    //                     </Col>
    //                   </Row>
    //                   <Row gutter={60} style={{ marginBottom: '44px' }}>
    //                     <Col span={12}>
    //                       <FormField>
    //                         <FormLabel>手机号码</FormLabel>
    //                         <SMSInput
    //                           onChange={({ target: { value } }) =>
    //                             setFieldValue('phone', value)
    //                           }
    //                           placeholder="请输入手机号码"
    //                           value={values.phone}
    //                         />
    //                       </FormField>
    //                     </Col>
    //                     <Col span={12}>
    //                       <FormField>
    //                         <FormLabel>验证码</FormLabel>
    //                         <FormInput
    //                           onChange={({ target: { value } }) =>
    //                             setFieldValue('code', value)
    //                           }
    //                           placeholder="请输入验证码"
    //                         />
    //                       </FormField>
    //                     </Col>
    //                   </Row>
    //                   <Row gutter={60}>
    //                     <Col span={24}>
    //                       <FormField>
    //                         <FormLabel>需求备注</FormLabel>
    //                         <FormTextArea
    //                           onChange={({ target: { value } }) =>
    //                             setFieldValue('note', value)
    //                           }
    //                           placeholder="您有什么样的需求，希望我们的产品为您解决什么问题"
    //                         />
    //                       </FormField>
    //                     </Col>
    //                   </Row>
    //                   <ApplyFormSubmit onClick={submitForm}>
    //                     申请购买
    //                   </ApplyFormSubmit>
    //                 </ApplyForm>
    //               )
    //             }}
    //           </Formik>
    //         </div>
    //       </ApplyFormWrapper>
    //     </ApplyFormContent>
    //     <CustomService />
    //     <Footer>
    //       北京易送科技有限公司&nbsp;&nbsp;
    //       <a href="https://beian.miit.gov.cn/" target="_blanck">
    //         京ICP备19019490号
    //       </a>
    //       &nbsp;&nbsp;
    //       <a
    //         href="http://www.beian.gov.cn/portal/registerSystemInfo?COLLCC=889263189&recordcode=11010802035374"
    //         target="_blanck"
    //       >
    //         京公网安备11010802035374
    //       </a>
    //     </Footer>
    //   </Container>
    // )
};
export default Index;
