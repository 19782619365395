var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button, Icon } from 'antd';
import styled from 'styled-components';
export var AccountWrapper = styled.div.withConfig({ displayName: "AccountWrapper", componentId: "sc-13gfwiu" })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 0 16px;\n  background: #fafafa;\n"], ["\n  padding: 0 16px;\n  background: #fafafa;\n"])));
export var AccountContainer = styled.div.withConfig({ displayName: "AccountContainer", componentId: "sc-em529z" })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-sizing: border-box;\n  background: #fafafa;\n"], ["\n  box-sizing: border-box;\n  background: #fafafa;\n"])));
export var Item = styled.div.withConfig({ displayName: "Item", componentId: "sc-d2tu00" })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 40px 30px 35px;\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 40px 30px 35px;\n"])));
export var Content = styled.p.withConfig({ displayName: "Content", componentId: "sc-w4qc33" })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin: 0 86px 0 16px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin: 0 86px 0 16px;\n"])));
export var Title = styled.b.withConfig({ displayName: "Title", componentId: "sc-1wll09w" })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 16px;\n  color: #333;\n"], ["\n  font-size: 16px;\n  color: #333;\n"])));
export var Text = styled.span.withConfig({ displayName: "Text", componentId: "sc-1ozjdrr" })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 14px;\n  color: #999;\n"], ["\n  font-size: 14px;\n  color: #999;\n"])));
export var ResetPwdButton = styled(Button).withConfig({ displayName: "ResetPwdButton", componentId: "sc-998edy" })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100px;\n  height: 33px;\n  border: 1px solid #e1e1e1;\n  color: #666;\n  font-size: 14px;\n"], ["\n  width: 100px;\n  height: 33px;\n  border: 1px solid #e1e1e1;\n  color: #666;\n  font-size: 14px;\n"])));
export var ItemIcon = styled(Icon).withConfig({ displayName: "ItemIcon", componentId: "sc-25ab9x" })(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 18px;\n  color: #01cccc;\n"], ["\n  font-size: 18px;\n  color: #01cccc;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
