var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Checkbox, Input, Row, Col } from 'antd';
import { ReceiptFormWrapper, FormBox, FormTitle, FormRow } from './styled';
var ReceiptForm = function (props) {
    var titleInfo = props.titleInfo, baseInfo = props.baseInfo, signInfo = props.signInfo, serviceInfo = props.serviceInfo, cacheInfo = props.cacheInfo, updateState = props.updateState;
    var handleClickCheckbox = function (type, key, record) {
        var _a, _b;
        var tempRecord = __assign(__assign({}, record), { checked: !record.checked });
        updateState((_a = {},
            _a[type] = __assign(__assign({}, props[type]), (_b = {}, _b[key] = tempRecord, _b)),
            _a));
    };
    var handleUpdateValue = function (e, type, key, record) {
        var _a, _b;
        var tempRecord = __assign(__assign({}, record), { value: e.target.value });
        updateState((_a = {},
            _a[type] = __assign(__assign({}, props[type]), (_b = {}, _b[key] = tempRecord, _b)),
            _a));
    };
    var renderCheckBox = function (data) {
        var type = data.type, record = data.record;
        return (React.createElement(FormRow, null,
            React.createElement(Row, null, Object.keys(record).map(function (key) { return key === 'billName' ? (React.createElement(Col, { span: 24, className: "col", key: key },
                React.createElement(Checkbox, { onChange: function () { return handleClickCheckbox(type, key, record[key]); }, checked: record[key]['checked'] }, record[key]['name']),
                React.createElement(Input, { className: "custom", placeholder: "\u8BF7\u8F93\u5165\u5355\u636E\u540D\u79F0", value: record[key]['value'], onChange: function (e) { return handleUpdateValue(e, type, key, record[key]); } }))) : key === 'selfWord' ? (React.createElement(Col, { span: 24, className: "col", key: key },
                React.createElement(Checkbox, { checked: record[key]['checked'], onChange: function () { return handleClickCheckbox(type, key, record[key]); } }, record[key]['name']),
                React.createElement(Input.TextArea, { className: "custom", rows: 4, placeholder: "\u6B64\u5904\u4E00\u822C\u662F\u586B\u5199\u5546\u6237\u5730\u5740\u3001\u8054\u7CFB\u7535\u8BDD\u3001\u4E8C\u7EF4\u7801 \u4F7F\u7528\u5907\u6CE8\u8BF4\u660E\u7B49", value: record[key]['value'], onChange: function (e) { return handleUpdateValue(e, type, key, record[key]); } }))) : key !== 'goods' ? (React.createElement(Col, { span: (key === 'barCode' || key === 'connect') ? 24 : 8, className: "col", key: key, onChange: function () { return handleClickCheckbox(type, key, record[key]); } },
                React.createElement(Checkbox, { checked: record[key]['checked'] }, record[key]['name']))) : ''; }))));
    };
    var render = function (data) {
        return renderCheckBox(data);
    };
    return (React.createElement(ReceiptFormWrapper, null,
        React.createElement(FormBox, null,
            React.createElement(FormRow, null,
                React.createElement(FormTitle, null, "\u6807\u9898\u4FE1\u606F"),
                render({ type: 'titleInfo', record: titleInfo }))),
        React.createElement(FormBox, null,
            React.createElement(FormRow, null,
                React.createElement(FormTitle, null, "\u57FA\u672C\u4FE1\u606F"),
                render({ type: 'baseInfo', record: baseInfo }))),
        React.createElement(FormBox, null,
            React.createElement(FormRow, null,
                React.createElement(FormTitle, null, "\u7B7E\u6536\u4FE1\u606F"),
                render({ type: 'signInfo', record: signInfo }))),
        React.createElement(FormBox, null,
            React.createElement(FormRow, null,
                React.createElement(FormTitle, null, "\u552E\u540E\u4FE1\u606F"),
                render({ type: 'serviceInfo', record: serviceInfo }))),
        React.createElement(FormBox, null,
            React.createElement(FormRow, null,
                React.createElement(FormTitle, null, "\u5B58\u6863\u533A"),
                render({ type: 'cacheInfo', record: cacheInfo })))));
};
export default ReceiptForm;
