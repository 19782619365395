var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState, } from 'react';
import { Col, Form, Row, Select, Modal } from 'antd';
import { Formik } from 'formik';
import { SSearchOneByMore } from './styled';
var FormItem = Form.Item;
var Option = Select.Option;
var formItemLayout = {
    labelCol: {
        xl: {
            span: 0
        },
        xxl: {
            span: 0
        }
    },
    wrapperCol: {
        xl: {
            span: 24
        },
        xxl: {
            span: 24
        }
    }
};
var EXPECTATION_TIME_LIST = [60, 120, 360, 720]; // min
var SearchOneByMore = function (props, ref) {
    var _a;
    var formikRef = useRef();
    var onSubmit = props.onSubmit, storeList = props.storeList, oneByMoreData = props.oneByMoreData, createOrderStoreId = props.createOrderStoreId;
    var searchFormInitValues = {
        expectationTime: '120',
        storeId: ((_a = storeList[0]) === null || _a === void 0 ? void 0 : _a.id) || '',
    };
    var _b = __read(useState(searchFormInitValues), 2), searchValue = _b[0], setSearchValue = _b[1];
    useEffect(function () {
        var _a, _b;
        if (formikRef.current) {
            formikRef.current.setFieldValue('storeId', createOrderStoreId || ((_a = storeList[0]) === null || _a === void 0 ? void 0 : _a.id));
        }
        setSearchValue(__assign(__assign({}, searchFormInitValues), { storeId: createOrderStoreId || ((_b = storeList[0]) === null || _b === void 0 ? void 0 : _b.id) }));
    }, [createOrderStoreId]);
    useImperativeHandle(ref, function () {
        return __assign({}, searchValue);
    });
    var handleSelectConfirm = function (callback) {
        if (oneByMoreData && oneByMoreData.length > 0) {
            Modal.confirm({
                title: '确认切换？',
                content: '切换筛选条件后右侧已选中订单需重新选择',
                onOk: function () {
                    callback();
                },
                onCancel: function () {
                    console.log('Cancel');
                },
            });
        }
        else {
            callback();
        }
    };
    return (React.createElement(SSearchOneByMore, null,
        React.createElement(Formik, { innerRef: formikRef, initialValues: searchValue, onSubmit: function (values) {
                onSubmit(values);
                setSearchValue(values);
            } }, function (_a) {
            var values = _a.values, setFieldValue = _a.setFieldValue, submitForm = _a.submitForm;
            return (React.createElement(Form, __assign({}, formItemLayout),
                React.createElement(Row, { gutter: 20 },
                    React.createElement(Col, { span: 12 },
                        React.createElement(FormItem, { className: 'shopSelect' },
                            React.createElement(Select, { onChange: function (value) {
                                    handleSelectConfirm(function () {
                                        setFieldValue('storeId', value);
                                        submitForm();
                                    });
                                }, value: values.storeId, size: "large" },
                                React.createElement(Option, { key: 'all', value: '' }, "\u5168\u90E8\u95E8\u5E97"),
                                storeList && storeList.map(function (store, key) {
                                    return (React.createElement(Option, { key: key, value: store.id },
                                        store.name,
                                        " - ",
                                        store.orderCount));
                                })))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(FormItem, { className: 'timeSelect' },
                            React.createElement(Col, { span: 10 }, "\u9884\u9001\u8FBE\u65F6\u95F4"),
                            React.createElement(Col, { span: 14 },
                                React.createElement(Select, { onChange: function (value) {
                                        handleSelectConfirm(function () {
                                            setFieldValue('expectationTime', value);
                                            submitForm();
                                        });
                                    }, value: values.expectationTime, size: "large" },
                                    React.createElement(Option, { key: 'all', value: '' }, "\u5168\u90E8"),
                                    EXPECTATION_TIME_LIST.map(function (time, key) {
                                        var hour = time / 60;
                                        return (React.createElement(Option, { key: key, value: "" + time },
                                            hour,
                                            "\u5C0F\u65F6\u5185"));
                                    }))))))));
        })));
};
export default forwardRef(SearchOneByMore);
