var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import qs from 'qs';
import ajax from '@utils/ajax';
import { ORDER_PLATFORM_MAP } from '@utils/constants';
import getPlatformAuthData from '@utils/getPlatformAuthData';
import PlatformEle from './ele';
import webConfig from '../../../webConfig';
var postBindOrderPlatformData = function (_a) {
    var type = _a.type, platformAuthData = _a.platformAuthData, mode = _a.mode, storeId = _a.storeId;
    return ajax.post('/order/platform/binding', {
        type: type,
        platformAuthData: platformAuthData,
        mode: mode,
        storeId: storeId
    });
};
var BindPlatform = /** @class */ (function (_super) {
    __extends(BindPlatform, _super);
    function BindPlatform() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            type: '',
            data: null
        };
        return _this;
    }
    BindPlatform.prototype.componentDidMount = function () {
        var _this = this;
        try {
            var searchStr = this.props.location.search.slice(1);
            var _a = qs.parse(searchStr), type_1 = _a.type, data = __rest(_a, ["type"]);
            var _b = JSON.parse(data.state), storeId = _b.storeId, mode = _b.mode, from = _b.from;
            var user = JSON.parse(decodeURIComponent(searchStr)
                .split('&')
                .filter(function (item) { return item.includes('state='); })[0]
                .replace('state=', '')).user;
            if (from === 'app') {
                var redirectUrl = '';
                var host = location.host;
                var appDomain = webConfig.appDomain;
                if (host.includes('easyex-dev')) {
                    redirectUrl = appDomain.dev;
                }
                else if (host.includes('easyex-test')) {
                    redirectUrl = appDomain.test;
                }
                else if (host.includes('iyisong')) {
                    redirectUrl = appDomain.prod;
                }
                window.location.href =
                    redirectUrl + "/easy/orderplatform/bind?" + searchStr;
                return;
            }
            else if (from === 'qiwei') {
                var redirectUrl = '';
                var host = location.host;
                var qiweiDomain = webConfig.qiweiDomain;
                if (host.includes('easyex-dev')) {
                    redirectUrl = qiweiDomain.dev;
                }
                else if (host.includes('easyex-test')) {
                    redirectUrl = qiweiDomain.test;
                }
                else if (host.includes('iyisong')) {
                    redirectUrl = qiweiDomain.prod;
                }
                window.location.href =
                    redirectUrl + "/qiweiOrder/bindPlatform/bindCallbackPage/elem?" +
                        searchStr;
                return;
            }
            this.setState({ type: type_1 });
            var platformAuthData = getPlatformAuthData({ type: type_1, authData: data });
            ajax.defaults.headers['Authorization'] = user;
            ajax.defaults.headers['token'] = user;
            postBindOrderPlatformData({ type: type_1, platformAuthData: platformAuthData, mode: mode, storeId: storeId }).then(function (_a) {
                var data = _a.data;
                if (type_1 === ORDER_PLATFORM_MAP.ELEME) {
                    _this.setState({ data: data });
                }
                else {
                    message.success('接单平台绑定成功');
                }
            });
        }
        catch (error) {
            message.error('请求参数不合法');
        }
    };
    BindPlatform.prototype.renderContentWithType = function () {
        var _a = this.state, type = _a.type, data = _a.data;
        switch (type) {
            case ORDER_PLATFORM_MAP.ELEME:
                return React.createElement(PlatformEle, { data: data });
            default:
                return '未知绑定平台';
        }
    };
    BindPlatform.prototype.render = function () {
        return React.createElement("div", null, this.renderContentWithType());
    };
    return BindPlatform;
}(React.Component));
export default withRouter(BindPlatform);
