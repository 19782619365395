var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect } from 'react';
import { ReceiptTemplateWrapper, AddButton, Buttons } from './receiptTemplateStyle';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { sendPv } from '@utils/umeng';
import ReceiptPreview from '@components/ReceiptPreview';
import { FormType } from '../../../../enums/receipt';
import { Button, Modal, message } from 'antd';
import { StoreCode, CustomCode } from '../../../../enums/codes';
import { TemplateA4List } from '../../../../printer/local/templateHelperA4';
import './receiptTemplate.less';
var ReceiptTemplate = function (props) {
    var history = useHistory();
    var gotoReceiptPage = function () {
        props.resetData();
        history.push('/receipt/add');
    };
    var gotoReceiptPageA4 = function () {
        history.push('/receiptA4/add');
    };
    var gotoEditPage = function (code, data) {
        var receiptSize = data.receiptSize, receiptName = data.receiptName, storeData = data.storeData, basicData = data.basicData, menuData = data.menuData, feeData = data.feeData, customData = data.customData, customText = data.customText, customUrl = data.customUrl;
        props.setEditPageData({
            receiptSize: receiptSize,
            receiptName: receiptName,
            storeData: storeData,
            basicData: basicData,
            menuData: menuData,
            feeData: feeData,
            customData: customData,
            customText: customText,
            customUrl: customUrl
        });
        history.push("/receipt/" + code);
    };
    var delTpl = function (id) {
        Modal.confirm({
            title: '确定要删除吗?',
            onOk: function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, props.delReceiptTemplate(id)];
                            case 1:
                                _a.sent();
                                message.success('删除成功');
                                return [2 /*return*/];
                        }
                    });
                });
            }
        });
    };
    var handleEditA4 = function (code) {
        history.push("/receiptA4/" + code);
    };
    var handleDeleteA4 = function (code) {
        Modal.confirm({
            title: '确定要删除吗?',
            onOk: function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, props.delReceiptTemplateA4(code)];
                            case 1:
                                _a.sent();
                                message.success('删除成功');
                                return [2 /*return*/];
                        }
                    });
                });
            }
        });
    };
    useEffect(function () {
        props.fetchFormData();
        props.fetchReceiptList();
        props.fetchReceiptListA4();
        sendPv();
    }, []);
    var prefixCls = 'receiptTemplateListA4';
    return (React.createElement(ReceiptTemplateWrapper, null,
        React.createElement("div", { className: 'add-receipt-button' },
            React.createElement(AddButton, { onClick: gotoReceiptPage }, "+\u6DFB\u52A0\u81EA\u5B9A\u4E49\u5C0F\u7968\u6A21\u7248"),
            React.createElement(AddButton, { onClick: gotoReceiptPageA4 }, "+\u6DFB\u52A0\u81EA\u5B9A\u4E49A4\u6A21\u7248")),
        React.createElement("div", { className: "receipt-list" },
            props.list.map(function (item) {
                var receiptSize = 0;
                var receiptName = '';
                var storeData = [];
                var basicData = [];
                var menuData = [];
                var feeData = [];
                var customData = [];
                var customText = '';
                var customUrl = '';
                item.groups.forEach(function (group) {
                    switch (group.code) {
                        case FormType.ReceiptSize:
                            receiptSize = group.receiptFields.map(function (field) { return field.code; })[0];
                        case FormType.Store:
                            storeData = group.receiptFields.map(function (field) { return field.code; });
                            var targetStoreData = group.receiptFields.find(function (field) { return field.code === StoreCode.ReceiptName; });
                            receiptName = targetStoreData === null || targetStoreData === void 0 ? void 0 : targetStoreData.data.text;
                            break;
                        case FormType.Basic:
                            basicData = group.receiptFields.map(function (field) { return field.code; });
                            break;
                        case FormType.Menu:
                            menuData = group.receiptFields.map(function (field) { return field.code; });
                            break;
                        case FormType.Fee:
                            feeData = group.receiptFields.map(function (field) { return field.code; });
                            break;
                        case FormType.Custom:
                            customData = group.receiptFields.map(function (field) { return field.code; });
                            var targetTextData = group.receiptFields.find(function (field) { return field.code === CustomCode.Text; });
                            var targetImgData = group.receiptFields.find(function (field) { return field.code === CustomCode.Image; });
                            customText = targetTextData === null || targetTextData === void 0 ? void 0 : targetTextData.data.text;
                            customUrl = targetImgData === null || targetImgData === void 0 ? void 0 : targetImgData.data.imgUrl;
                    }
                });
                return (React.createElement("div", { className: "receipt-tpl", key: item.code },
                    React.createElement(ReceiptPreview, { formFieldMap: props.formFieldMap, receiptSize: receiptSize, receiptName: receiptName, storeData: storeData, basicData: basicData, menuData: menuData, feeData: feeData, customData: customData, customText: customText, customUrl: customUrl }),
                    React.createElement(Buttons, null,
                        React.createElement(Button, { className: "edit", onClick: function () {
                                return gotoEditPage(item.code, {
                                    receiptSize: receiptSize,
                                    receiptName: receiptName,
                                    storeData: storeData,
                                    basicData: basicData,
                                    menuData: menuData,
                                    feeData: feeData,
                                    customData: customData,
                                    customText: customText,
                                    customUrl: customUrl
                                });
                            } }, "\u7F16\u8F91"),
                        [1, 2, 3].includes(item.type) ? (React.createElement(Button, { disabled: true }, "\u7CFB\u7EDF\u6A21\u7248\u7981\u6B62\u5220\u9664")) : (React.createElement(Button, { onClick: function () { return delTpl(item.code); } }, "\u5220\u9664")))));
            }),
            !!props.listA4.length && (React.createElement("div", { className: "" + prefixCls }, props.listA4.map(function (record) {
                return (React.createElement("div", { className: prefixCls + "-item", key: record.code },
                    React.createElement(TemplateA4List, __assign({}, record.template, { code: record.code })),
                    React.createElement("div", { className: prefixCls + "-item-buttons" },
                        React.createElement(Button, { className: prefixCls + "-item-buttons-edit", onClick: function () { return handleEditA4(record.code); } }, "\u7F16\u8F91"),
                        React.createElement(Button, { onClick: function () { return handleDeleteA4(record.code); } }, "\u5220\u9664"))));
            }))))));
};
var mapState = function (state) { return ({
    list: state.receiptTemplate.list,
    formFieldMap: state.customReceipt.formFieldMap,
    listA4: state.receiptTemplate.listA4,
}); };
var mapDispatch = function (dispatch) { return ({
    fetchFormData: dispatch.customReceipt.fetchFormData,
    fetchReceiptList: dispatch.receiptTemplate.fetchReceiptList,
    setEditPageData: dispatch.customReceipt.setEditPageData,
    delReceiptTemplate: dispatch.receiptTemplate.delReceiptTemplate,
    resetData: dispatch.customReceipt.resetData,
    fetchReceiptListA4: dispatch.receiptTemplate.fetchReceiptListA4,
    delReceiptTemplateA4: dispatch.receiptTemplate.delReceiptTemplateA4,
}); };
export default connect(mapState, mapDispatch)(ReceiptTemplate);
