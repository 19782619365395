var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from 'react';
import { DeliveryOrderActionsinit } from '../components';
import { DeliveryContext, ActionsContext } from '../context';
import ajax from '@utils/ajax';
import { getAuth } from '@/container/AccountManagement/component';
import { useSelector } from 'react-redux';
var getDeliveryDetail = function (orderId, platformOrderId) {
    return ajax.post('/order/distri/details', { orderId: orderId, platformOrderId: platformOrderId });
};
export var CancelDeliveryOrderActioninit = function (props) {
    var order = useContext(ActionsContext);
    var selfSend = order.selfSend;
    var distriOrder = {};
    // 自配送的不处理
    if (selfSend && selfSend.code === 0)
        return null;
    // const [order, setOrder] = useState<Order>({} as Order)
    // const [distriOrder = {} as DistriOrder, setDistriOrder] = useState<
    //   DistriOrder
    // >({} as DistriOrder)
    // useEffect(() => {
    //   getDeliveryDetail(id, platformId).then(({ data }) => {
    //     setOrder(data.order)
    //     setDistriOrder(data.distriOrder)
    //   })
    // }, [])
    return (React.createElement(React.Fragment, null,
        React.createElement(DeliveryContext.Provider, { value: { order: order, distriOrder: distriOrder } },
            React.createElement(DeliveryOrderActionsinit, __assign({ isAllcanel: true, toggleVisible: function () { } }, props)))));
};
export var CancelDeliveryOrderAction = function () {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("配送处理", menu) ? React.createElement(CancelDeliveryOrderActioninit, null) : null;
};
var CancelDeliveryOrderActionWAIT_GRABinit = function (props) {
    return (React.createElement(CancelDeliveryOrderActioninit, __assign({ text: {
            content: '取消后将停止呼叫运力，请确定是否取消',
            title: '取消呼叫'
        }, cancelText: "\u518D\u60F3\u60F3" }, props)));
};
export var CancelDeliveryOrderActionWAIT_GRAB = function (props) {
    var menu = useSelector(function (state) { return state.merchant.DEFAULTMENU; });
    return getAuth("配送处理", menu) ? React.createElement(CancelDeliveryOrderActionWAIT_GRABinit, __assign({}, props)) : null;
};
