import React from 'react';
import moment from 'moment';
import { ORDER_PLATFORM_MAP, OrderPlatformIcon } from '@utils/constants';
import './index.less';
import { getSn } from '../OrderMap';
var OrderMapList = function (_a) {
    var handleReceiverClick = _a.handleReceiverClick, oneByMoreDataItem = _a.oneByMoreDataItem;
    var platform = oneByMoreDataItem.platform, 
    // platformDaySn,
    consignee = oneByMoreDataItem.consignee, consigneePhone = oneByMoreDataItem.consigneePhone, consigneeAddress = oneByMoreDataItem.consigneeAddress, receiveAddressDetail = oneByMoreDataItem.receiveAddressDetail, extensionTelephone = oneByMoreDataItem.extensionTelephone;
    var renderDeliveryTime = function (order) {
        var _a;
        if (order.platform === ORDER_PLATFORM_MAP.EBAI &&
            order.reservationStartTime) {
            return (React.createElement(React.Fragment, null,
                moment(moment(order.deliverTime).valueOf()).format('MM-DD HH:mm'),
                "\u00A0\u5230\u00A0",
                moment(moment(order.reservationStartTime).valueOf()).format('MM-DD HH:mm'),
                React.createElement("span", null, " \u9001\u8FBE")));
        }
        else if (((_a = order.selfFetch) === null || _a === void 0 ? void 0 : _a.code) === 0) {
            return React.createElement(React.Fragment, null,
                moment(moment(order.deliverTime).valueOf()).format('MM-DD HH:mm'),
                " ",
                React.createElement("span", null, "\u81EA\u53D6"));
        }
        else {
            if (order.deliverTime.includes('立即送达')) {
                return React.createElement(React.Fragment, null, "\u7ACB\u5373\u9001\u8FBE");
            }
            else {
                return React.createElement(React.Fragment, null,
                    moment(moment(order.deliverTime).valueOf()).format('MM-DD HH:mm'),
                    " ",
                    React.createElement("span", null, "\u524D\u9001\u8FBE"));
            }
        }
    };
    var prefixCls = 'orderMapList';
    return (React.createElement("div", { className: "" + prefixCls },
        React.createElement("div", { className: prefixCls + "-top" },
            React.createElement("div", { className: prefixCls + "-top-left" },
                React.createElement("img", { className: prefixCls + "-top-left-icon", src: OrderPlatformIcon[platform], alt: '' }),
                React.createElement("div", { className: prefixCls + "-top-left-sn" }, oneByMoreDataItem[getSn(platform)]),
                React.createElement("div", { className: prefixCls + "-top-left-time" }, renderDeliveryTime(oneByMoreDataItem))),
            React.createElement("div", { className: prefixCls + "-top-right", 
                // alt=''
                // src={deleteIcon}
                onClick: function () { return handleReceiverClick(oneByMoreDataItem); } })),
        React.createElement("div", { className: prefixCls + "-bottom" },
            React.createElement("div", { className: prefixCls + "-bottom-user" }, consignee),
            React.createElement("div", { className: prefixCls + "-bottom-phone" },
                consigneePhone,
                " ",
                extensionTelephone ? "\u8F6C " + extensionTelephone : ''),
            React.createElement("div", { className: prefixCls + "-bottom-address" },
                consigneeAddress,
                " ",
                receiveAddressDetail))));
};
export default OrderMapList;
