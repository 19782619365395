var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Form, Input, Radio, Select } from 'antd';
import produce from 'immer';
import ipRegex from 'ip-regex';
import { get } from 'lodash';
import { requestForMerchant } from '@utils/request';
import { getItem } from '@utils/localStorage';
import InputIP from '@components/InputIP';
import { isElectron } from '@utils/env';
import ajax from '@utils/ajax';
import { CloudPrinterPlatform } from '@utils/constants';
import { getValueFromInitialValue } from '@utils/form';
import { storeIdValidator } from '@utils/validator';
import { loadSDK } from '@utils/lodopAdapt';
import { TipsWrapper, TipsTitle, TipsText } from './styled';
var FormItem = Form.Item;
var RadioGroup = Radio.Group;
var Option = Select.Option;
var formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 }
};
var mapUsbDevicesFields = function (usbDevices) {
    usbDevices.forEach(function (d) {
        var _a = d.deviceDescriptor, iManufacturer = _a.iManufacturer, idProduct = _a.idProduct, idVendor = _a.idVendor;
        d.open();
        d.getStringDescriptor(iManufacturer, function (err, data) {
            d.manufacturer = data;
            d.getStringDescriptor(idProduct, function (err, data) {
                d.product = data;
                d.close();
            });
        });
        d.pid = idProduct;
        d.vid = idVendor;
    });
};
var BindPrinter = /** @class */ (function (_super) {
    __extends(BindPrinter, _super);
    function BindPrinter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            storeList: [],
            usbDevices: [],
            cloudPlatformList: []
        };
        _this.ipValidator = function (rule, value, callback) {
            if (ipRegex.v4({ exact: true }).test(value)) {
                return callback();
            }
            return callback('请输入合法 IP 地址');
        };
        _this.handleLoadUSB = function () {
            loadSDK(function () {
                var win = window;
                var LODOP = win.getCLodop();
                LODOP.On_Return = function (TaskID, Value) {
                    _this.setState(produce(function (draft) {
                        draft.usbDevices = JSON.parse(Value).list;
                    }));
                };
                LODOP.GET_VALUE('AO_PRINT_JSON_PrinterList', 0);
            });
        };
        return _this;
    }
    BindPrinter.prototype.renderStoreList = function () {
        return this.state.storeList.map(function (store) {
            return (React.createElement(Option, { key: store.id, value: store.id },
                store.name,
                " - ",
                store.numbering));
        });
    };
    BindPrinter.prototype.componentDidMount = function () {
        var _this = this;
        var validateFields = this.props.form.validateFields;
        var lastChooseMerchant = getItem('lastChooseMerchant') && JSON.parse(getItem('lastChooseMerchant'));
        requestForMerchant.post("/merchant/store/getAllStoreByMerchantId", {
            merchantId: lastChooseMerchant.merchantId
        }).then(function (_a) {
            var data = _a.data;
            _this.setState(produce(function (draft) {
                draft.storeList = data;
            }), function () {
                validateFields(['storeId']);
            });
        });
        ajax.get('/printer/cloud/printer/list').then(function (_a) {
            var data = _a.data;
            _this.setState(produce(function (draft) {
                draft.cloudPlatformList = data;
            }));
        });
        this.handleLoadUSB();
        // if (window.escpos) {
        //   const { escpos } = window
        //   try {
        //     let usbDevice = new escpos.USB()
        //     const usbDevices = escpos.USB.findPrinter()
        //     mapUsbDevicesFields(usbDevices)
        //     this.setState(
        //       produce(draft => {
        //         draft.usbDevices = usbDevices
        //       })
        //     )
        //   } catch (error) {}
        // }
    };
    BindPrinter.prototype.renderUSBDevices = function () {
        return this.state.usbDevices.map(function (d, key) {
            // return (
            //   <Option key={key} value={`${d.vid},${d.pid}`}>
            //     {d.manufacturer}
            //   </Option>
            // )
            return (React.createElement(Option, { key: d.name + "-" + key, value: "" + d.name }, d.name));
        });
    };
    BindPrinter.prototype.renderCloudPlatformList = function () {
        return this.state.cloudPlatformList.map(function (platform, key) {
            return (React.createElement(Option, { key: key, value: platform.type }, platform.name));
        });
    };
    BindPrinter.prototype.renderCloudPrinterFormItem = function (printerPlatform) {
        var props = this.props;
        var getFieldDecorator = props.form.getFieldDecorator;
        var getValue = getValueFromInitialValue(props.initialValue);
        switch (printerPlatform) {
            case CloudPrinterPlatform.YiLianYun:
                return (React.createElement(React.Fragment, null,
                    React.createElement(FormItem, { label: "\u7EC8\u7AEF\u673A\u53F7" }, getFieldDecorator('printerCloud.machineCode', {
                        initialValue: getValue('cloudPrinter.machineCode'),
                        rules: [
                            {
                                required: true,
                                message: '请输入终端机号'
                            }
                        ]
                    })(React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u7EC8\u7AEF\u673A\u53F7" }))),
                    React.createElement(FormItem, { label: "\u7EC8\u7AEF\u673A\u5BC6\u94A5" }, getFieldDecorator('printerCloud.msign', {
                        initialValue: getValue('cloudPrinter.msign'),
                        rules: [
                            {
                                required: true,
                                message: '请输入终端机密钥'
                            }
                        ]
                    })(React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u7EC8\u7AEF\u673A\u5BC6\u94A5" })))));
            case CloudPrinterPlatform.FeiEYun:
                return (React.createElement(React.Fragment, null,
                    React.createElement(FormItem, { label: "\u7F16\u53F7" }, getFieldDecorator('printerCloud.sn', {
                        initialValue: getValue('cloudPrinter.sn'),
                        rules: [
                            {
                                required: true,
                                message: '请输入编号'
                            }
                        ]
                    })(React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u7F16\u53F7" }))),
                    React.createElement(FormItem, { label: "\u5BC6\u94A5" }, getFieldDecorator('printerCloud.key', {
                        initialValue: getValue('cloudPrinter.key'),
                        rules: [
                            {
                                required: true,
                                message: '请输入密钥'
                            }
                        ]
                    })(React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u5BC6\u94A5" })))));
            case CloudPrinterPlatform.ZhongWuYun:
                return (React.createElement(React.Fragment, null,
                    React.createElement(FormItem, { label: "\u8BBE\u5907\u53F7" }, getFieldDecorator('printerCloud.deviceid', {
                        initialValue: getValue('cloudPrinter.deviceid'),
                        rules: [
                            {
                                required: true,
                                message: '请输入设备号'
                            }
                        ]
                    })(React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u8BBE\u5907\u53F7" }))),
                    React.createElement(FormItem, { label: "\u8BBE\u5907\u5BC6\u94A5" }, getFieldDecorator('printerCloud.devicesecret', {
                        initialValue: getValue('cloudPrinter.devicesecret'),
                        rules: [
                            {
                                required: true,
                                message: '请输入设备密钥'
                            }
                        ]
                    })(React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u8BBE\u5907\u5BC6\u94A5" })))));
            case CloudPrinterPlatform.FeiYinYun:
                return (React.createElement(React.Fragment, null,
                    React.createElement(FormItem, { label: "\u7EC8\u7AEF\u7F16\u53F7" }, getFieldDecorator('printerCloud.code', {
                        initialValue: getValue('cloudPrinter.code'),
                        rules: [
                            {
                                required: true,
                                message: '请输入终端编号'
                            }
                        ]
                    })(React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u7EC8\u7AEF\u7F16\u53F7" }))),
                    React.createElement(FormItem, { label: "\u8BBE\u5907\u5BC6\u94A5" }, getFieldDecorator('printerCloud.secret', {
                        initialValue: getValue('cloudPrinter.secret'),
                        rules: [
                            {
                                required: true,
                                message: '请输入设备密钥'
                            }
                        ]
                    })(React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u8BBE\u5907\u5BC6\u94A5" })))));
            case CloudPrinterPlatform.XinYeYun:
                return (React.createElement(React.Fragment, null,
                    React.createElement(FormItem, { label: "\u8BBE\u5907\u53F7" }, getFieldDecorator('printerCloud.sn', {
                        initialValue: getValue('cloudPrinter.sn'),
                        rules: [
                            {
                                required: true,
                                message: '请输入设备号'
                            }
                        ]
                    })(React.createElement(Input, { placeholder: "\u8BF7\u8F93\u5165\u7EC8\u7AEF\u7F16\u53F7" })))));
            default:
                return null;
        }
    };
    BindPrinter.prototype.render = function () {
        var state = this.state;
        var props = this.props;
        var _a = props.form, getFieldDecorator = _a.getFieldDecorator, getFieldValue = _a.getFieldValue;
        var getValue = getValueFromInitialValue(props.initialValue);
        return (React.createElement(Form, __assign({}, formItemLayout),
            React.createElement(FormItem, { label: "\u9009\u62E9\u95E8\u5E97" }, getFieldDecorator('storeId', {
                initialValue: getValue('store.id'),
                rules: [
                    {
                        required: true,
                        validator: storeIdValidator({ stores: state.storeList })
                    }
                ]
            })(React.createElement(Select, { placeholder: "\u8BF7\u9009\u62E9\u95E8\u5E97" }, this.renderStoreList()))),
            React.createElement(FormItem, { label: "\u6253\u5370\u673A\u540D\u79F0" }, getFieldDecorator('name', {
                initialValue: getValue('name'),
                rules: [
                    {
                        required: true,
                        message: '请输入打印机名称'
                    }
                ]
            })(React.createElement(Input, { placeholder: "\u6253\u5370\u673A\u540D\u79F0\u4E0D\u5141\u8BB8\u91CD\u590D" }))),
            isElectron ? (React.createElement(FormItem, { label: "\u6253\u5370\u673A\u7C7B\u578B" }, getFieldDecorator('type', {
                initialValue: getValue('type', 1),
                rules: [
                    {
                        required: true
                    }
                ]
            })(React.createElement(RadioGroup, null,
                React.createElement(Radio, { value: 1 }, "\u7F51\u7EDC\u6253\u5370\u673A"),
                React.createElement(Radio, { value: 2 }, "USB \u6253\u5370\u673A"),
                React.createElement(Radio, { value: 3 }, "\u4E91\u6253\u5370\u673A"))))) : (React.createElement(FormItem, { label: "\u6253\u5370\u673A\u7C7B\u578B" }, getFieldDecorator('type', {
                initialValue: getValue('type', 3),
                rules: [
                    {
                        required: true
                    }
                ]
            })(React.createElement(RadioGroup, null,
                React.createElement(Radio, { value: 2 }, "USB \u6253\u5370\u673A"),
                React.createElement(Radio, { value: 3 }, "\u4E91\u6253\u5370\u673A"),
                React.createElement(Radio, { value: 6 }, "A4\u7EB8\u6253\u5370\u673A"))))),
            getFieldValue('type') === 1 ? (React.createElement(FormItem, { label: "\u6253\u5370\u673A IP" }, getFieldDecorator('ip', {
                initialValue: getValue('ip'),
                rules: [
                    {
                        required: true,
                        validator: this.ipValidator
                    }
                ]
            })(React.createElement(InputIP, null)))) : null,
            getFieldValue('type') === 2 || getFieldValue('type') === 6 ? (React.createElement(FormItem, { label: "\u9009\u62E9\u6253\u5370\u673A" }, getFieldDecorator('usb', {
                initialValue: getValue('usb'),
                rules: [
                    {
                        required: true,
                        message: '请选择打印机'
                    }
                ]
            })(React.createElement(Select, null, this.renderUSBDevices())))) : null,
            getFieldValue('type') === 3 ? (React.createElement(React.Fragment, null,
                React.createElement(FormItem, { label: "\u4E91\u5E73\u53F0" }, getFieldDecorator('printerCloud.type', {
                    initialValue: getValue('cloudPrinter.type', get(state, 'cloudPlatformList[0].type')),
                    rules: [
                        {
                            required: true,
                            message: '请选择云平台'
                        }
                    ]
                })(React.createElement(Select, null, this.renderCloudPlatformList()))),
                this.renderCloudPrinterFormItem(getFieldValue('printerCloud.type')))) : null,
            getFieldValue('type') !== 6 && (React.createElement(FormItem, { label: "\u7EB8\u5F20\u5C3A\u5BF8" }, getFieldDecorator('paperFormat', {
                initialValue: getValue('paperFormat', 3),
                rules: [
                    {
                        required: true
                    }
                ]
            })(React.createElement(RadioGroup, null,
                React.createElement(Radio, { value: 1 }, "58mm"),
                React.createElement(Radio, { value: 3 }, "80mm"))))),
            (getFieldValue('type') === 2 || getFieldValue('type') === 6) && (React.createElement(TipsWrapper, null,
                React.createElement(TipsTitle, null, "\u6CE8\u610F"),
                React.createElement(TipsText, null,
                    "Windows\u7CFB\u7EDF\u4F7F\u7528USB\u6253\u5370\u673A\u524D\uFF0C\u8BF7\u5148",
                    React.createElement("a", { href: "https://easyex-package.oss-cn-beijing.aliyuncs.com/release/Yisong_Setup_for_Win32NT_4.127.exe", target: "_blank" }, "\u4E0B\u8F7D\u5E76\u5B89\u88C5\u6613\u9001Web\u6253\u5370\u670D\u52A1"),
                    "\u540E\uFF0C\u5237\u65B0\u9875\u9762\uFF0C\u518D\u6DFB\u52A0\u6253\u5370\u673A",
                    React.createElement("br", null),
                    "\u67E5\u770B",
                    React.createElement("a", { href: "/printHelper", target: "_blank" }, "\u5B89\u88C5\u8BF4\u660E")),
                React.createElement(TipsText, null, "macOS\u65E0\u6CD5\u4F7F\u7528USB\u6253\u5370")))));
    };
    return BindPrinter;
}(React.Component));
export default Form.create()(BindPrinter);
