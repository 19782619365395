var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var NOTIFY_BODY;
(function (NOTIFY_BODY) {
    NOTIFY_BODY["NEW_ORDER"] = "\u60A8\u6709\u65B0\u7684\u8BA2\u5355";
    NOTIFY_BODY["NEW_ORDER_WITH_AUTO"] = "\u6613\u9001\u4E3A\u60A8\u81EA\u52A8\u63A5\u5355\u4E86";
    NOTIFY_BODY["CANCEL_APPLY"] = "\u6709\u5BA2\u6237\u7533\u8BF7\u53D6\u6D88\u8BA2\u5355";
    NOTIFY_BODY["REFUND_APPLY"] = "\u6709\u5BA2\u6237\u7533\u8BF7\u9000\u6B3E";
    NOTIFY_BODY["URGE_ORDER"] = "\u6709\u5BA2\u6237\u50AC\u5355";
    NOTIFY_BODY["PRINTER_DISCONNECT"] = "\u6253\u5370\u673A\u8FDE\u63A5\u65AD\u5F00\uFF0C\u8BF7\u68C0\u67E5\u6253\u5370\u673A";
    NOTIFY_BODY["NETWORK_DISCONNECT"] = "\u7F51\u7EDC\u8FDE\u63A5\u65AD\u5F00\uFF0C\u8BF7\u68C0\u67E5\u7F51\u7EDC";
    NOTIFY_BODY["DELIVERY_ORDER_UNCONFIRMED"] = "\u6709\u914D\u9001\u5355\u672A\u63A5\u5355";
    NOTIFY_BODY["DELIVERY_FAILED"] = "\u6709\u8BA2\u5355\u914D\u9001\u5931\u8D25,\u5931\u8D25\u539F\u56E0:\u65E0\u914D\u9001\u5458\u63A5\u5355/\u627E\u4E0D\u5230\u5546\u6237\u53D6\u4EF6/\u914D\u9001\u5458\u53D6\u6D88";
})(NOTIFY_BODY || (NOTIFY_BODY = {}));
var DEFAULT_NOTIFY_TITLE = '易送';
var noop = function () { };
var notify = function (title, _a) {
    var _b = _a.onclick, onclick = _b === void 0 ? noop : _b, _c = _a.onclose, onclose = _c === void 0 ? noop : _c, opts = __rest(_a, ["onclick", "onclose"]);
    var notify;
    if (Notification.permission === 'granted') {
        notify = new Notification(title, opts);
    }
    else if (Notification.permission === 'default') {
        Notification.requestPermission().then(function (permission) {
            if (permission === 'granted') {
                notify = new Notification(title, opts);
            }
        });
    }
    if (notify) {
        notify.onclick = onclick;
        notify.onclose = onclose;
    }
};
export default notify;
export var newOrderNotify = function (_a) {
    var onclick = _a.onclick, onclose = _a.onclose, message = _a.message;
    notify(DEFAULT_NOTIFY_TITLE, {
        body: message || NOTIFY_BODY.NEW_ORDER,
        onclick: onclick,
        onclose: onclose
    });
};
export var newOrderWithAutoNotify = function (_a) {
    var onclick = _a.onclick, onclose = _a.onclose, message = _a.message;
    return notify(DEFAULT_NOTIFY_TITLE, {
        body: message || NOTIFY_BODY.NEW_ORDER_WITH_AUTO,
        onclick: onclick,
        onclose: onclose
    });
};
export var cancelOrderApplyNotify = function (_a) {
    var onclick = _a.onclick, onclose = _a.onclose, message = _a.message;
    return notify(DEFAULT_NOTIFY_TITLE, {
        body: message || NOTIFY_BODY.CANCEL_APPLY,
        onclick: onclick,
        onclose: onclose
    });
};
export var refundOrderApplyNotify = function (_a) {
    var onclick = _a.onclick, onclose = _a.onclose, message = _a.message;
    return notify(DEFAULT_NOTIFY_TITLE, {
        body: message || NOTIFY_BODY.REFUND_APPLY,
        onclick: onclick,
        onclose: onclose
    });
};
export var urgeOrderNotify = function (_a) {
    var onclick = _a.onclick, onclose = _a.onclose, message = _a.message;
    return notify(DEFAULT_NOTIFY_TITLE, {
        body: message || NOTIFY_BODY.URGE_ORDER,
        onclick: onclick,
        onclose: onclose
    });
};
export var TIME_OUT_UN_EXPRESSIONNotify = function (_a) {
    var onclick = _a.onclick, onclose = _a.onclose, message = _a.message;
    return notify(DEFAULT_NOTIFY_TITLE, {
        body: message || NOTIFY_BODY.URGE_ORDER,
        onclick: onclick,
        onclose: onclose
    });
};
export var printerDisconnectNotify = function (_a) {
    var onclick = _a.onclick, onclose = _a.onclose, message = _a.message;
    return notify(DEFAULT_NOTIFY_TITLE, {
        body: message || NOTIFY_BODY.PRINTER_DISCONNECT,
        onclick: onclick,
        onclose: onclose
    });
};
export var networkDisconnectNotify = function (_a) {
    var onclick = _a.onclick, onclose = _a.onclose, message = _a.message;
    return notify(DEFAULT_NOTIFY_TITLE, {
        body: message || NOTIFY_BODY.NETWORK_DISCONNECT,
        onclick: onclick,
        onclose: onclose
    });
};
export var deliveryOrderUnConfirmedNotify = function (_a) {
    var body = _a.body, onclick = _a.onclick, onclose = _a.onclose;
    return notify(DEFAULT_NOTIFY_TITLE, {
        body: body,
        onclick: onclick,
        onclose: onclose
    });
};
export var DeliveryFailNotify = function (_a) {
    var onclick = _a.onclick, onclose = _a.onclose, message = _a.message;
    notify(DEFAULT_NOTIFY_TITLE, {
        body: message || NOTIFY_BODY.DELIVERY_FAILED,
        onclick: onclick,
        onclose: onclose
    });
};
export var DeliveryReceviedNotify = function (_a) {
    var platform = _a.platform, orderNo = _a.orderNo, onclick = _a.onclick, onclose = _a.onclose;
    return notify(DEFAULT_NOTIFY_TITLE, {
        body: "\u3010" + platform + "\u3011\u3010" + orderNo + "\u3011\u53F7\u8BA2\u5355\u6709\u914D\u9001\u5458\u63A5\u5355\u4E86",
        onclick: onclick,
        onclose: onclose
    });
};
export var DeliveryArrivedNotify = function (_a) {
    var storeName = _a.storeName, onclick = _a.onclick, onclose = _a.onclose;
    return notify(DEFAULT_NOTIFY_TITLE, {
        body: "\u3010" + storeName + "\u3011\u5E97,\u914D\u9001\u5458\u5DF2\u5230\u5E97",
        onclick: onclick,
        onclose: onclose
    });
};
