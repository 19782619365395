var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ajax from '@utils/ajax';
export var initState = {
    titleInfo: {
        platform: {
            code: '',
            name: '接单平台',
            checked: true,
            value: '饿了么'
        },
        platformSn: {
            code: '',
            name: '平台流水号',
            checked: true,
            value: '2323'
        },
        reservationOrder: {
            code: '',
            name: '预订单标识',
            checked: true,
            value: '预订单'
        },
        storeName: {
            code: '',
            name: '店铺名称',
            checked: true,
            value: '张三店'
        },
        billName: {
            code: '',
            name: '单据名称（不能重复）',
            checked: true,
            value: '骑手联'
        }
    },
    baseInfo: {
        receiverName: {
            code: '',
            name: '收件人姓名',
            checked: true,
            value: 'duke'
        },
        receiverPhone: {
            code: '',
            name: '收件人电话',
            checked: true,
            value: '13588888888'
        },
        receiverAddress: {
            code: '',
            name: '收件人地址',
            checked: true,
            value: '海淀区中关村大厦B座 2 单元 305 室，据门店 1.5 公里'
        },
        goodsPic: {
            code: '',
            name: '商品图片',
            checked: true,
            value: 'https://img.redocn.com/photo/20131022/Redocn_2013101520393466.jpg'
        },
        goodsName: {
            code: '',
            name: '商品名称',
            checked: true,
            value: '三生三世'
        },
        goodsNum: {
            code: '',
            name: '商品数量',
            checked: true,
            value: '1'
        },
        goodsDescription: {
            code: '',
            name: '商品描述',
            checked: true,
            value: '遇见你的眉眼，如清风明月；顾盼流连，如时光搁浅，是重逢，亦如初见；你的喜悲忧乐我全都预见，三千繁花只为你一人留恋。'
        },
        orderRemarks: {
            code: '',
            name: '订单备注',
            checked: true,
            value: '送给老婆的鲜花'
        },
        buyerPhone: {
            code: '',
            name: '订购人电话',
            checked: true,
            value: '15588888888'
        },
        blessing: {
            code: '',
            name: '贺卡内容（祝福语）',
            checked: true,
            value: '天天好心情'
        },
        reservationTime: {
            code: '',
            name: '期望送单时间',
            checked: true,
            value: '立即送达'
        },
        deliveryRemarks: {
            code: '',
            name: '配送备注',
            checked: true,
            value: '踏板车接单'
        }
    },
    signInfo: {
        receiverSign: {
            code: '',
            name: '收件人签字',
            checked: true,
            value: ''
        },
        otherSign: {
            code: '',
            name: '代收人签字',
            checked: true,
            value: ''
        },
        signTime: {
            code: '',
            name: '签字时间',
            checked: true,
            value: ''
        },
        comment: {
            code: '',
            name: '收件评价',
            checked: true,
            value: ''
        }
    },
    serviceInfo: {
        connect: {
            code: '',
            name: '如果生产、配送、签收环节出现问题，可以致电门店电话：1861231231212',
            checked: true,
            value: '如果生产、配送、签收环节出现问题，可以致电门店电话：1861231231212'
        },
        barCode: {
            code: '',
            name: '显示已送订单一维码',
            checked: true,
            value: ''
        }
    },
    cacheInfo: {
        buyerPhone: {
            code: '',
            name: '订购人电话',
            checked: true,
            value: 'duke'
        },
        reservationTime: {
            code: '',
            name: '期望送达时间',
            checked: true,
            value: '立刻送达'
        },
        platform: {
            code: '',
            name: '接单平台',
            checked: true,
            value: '饿了么'
        },
        platformSn: {
            code: '',
            name: '平台流水号',
            checked: true,
            value: '2342'
        },
        receiverName: {
            code: '',
            name: '收件人姓名',
            checked: true,
            value: 'hijack'
        },
        receiverPhone: {
            code: '',
            name: '收件人电话',
            checked: true,
            value: '15588888888'
        },
        receiverAddress: {
            code: '',
            name: '收件人地址',
            checked: true,
            value: '天地林风'
        },
        storeName: {
            code: '',
            name: '门店名称',
            checked: true,
            value: '🌹礼网'
        },
        selfWord: {
            code: '',
            name: '自定义文字',
            checked: true,
            value: '我是自定义文本，最多显示到这一行'
        }
    }
};
export var customReceiptA4 = {
    state: initState,
    reducers: {
        updateState: function (state, payload) {
            return __assign(__assign({}, state), payload);
        }
    },
    effects: function (dispatch) { return ({
        fetchFormData: function (code) {
            return __awaiter(this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, ajax.get("/printer/a4/template", {
                                params: { code: code }
                            })];
                        case 1:
                            data = (_a.sent()).data;
                            dispatch.customReceiptA4.updateState(data);
                            return [2 /*return*/];
                    }
                });
            });
        }
    }); }
};
